<form [formGroup]="formGroup" class="row">

    <div class="row col-xs-12 no-padding">
        <iw-textfield #txtCli_id [isDisabled]="true" [ngClass]="getCdfClass()" class="iw-input col-xs-2 no-padding"
                      formControlName="cdfId"></iw-textfield>

        <iw-textfield #txtCliNom [isDisabled]="true" [value]="clientName"
                      class="iw-input no-padding label-name col-xs-9"></iw-textfield>

        <iw-button #cliBtn (press)="openClient()" class="iw-button flex-end col-xs-1"
                   iconClass="fas fa-link"></iw-button>
    </div>

    <div class="row col-xs-3 no-padding">
        <iw-textfield #txtROProfession [isDisabled]="true" [value]="commandeProfession"
                      class="iw-input col-xs-12 no-padding label-profession"></iw-textfield>
    </div>

    <div class="row col-xs-9 no-padding">
        <iw-textfield #txtProfessionDescription [isDisabled]="true" [value]="getFormValue('qualifDescription')"
                      class="iw-input col-xs-12 no-padding label-profession"></iw-textfield>
    </div>

</form>

<iw-tab #tabs [(selectedTab)]="selectedTab" [disabledTabs]="disabledTabList" [invalidTabs]="invalidTabList"
        [tabs]="tabList">

    <iw-tab-ppcdefixe-general #tabGeneral *ngIf="selectedTab === tabList[0]" [entityId]="cdfId" [formGroup]="formGroup"
                              [isReadonly]="!isWriteMode"></iw-tab-ppcdefixe-general>

    <iw-tab-ppcdefixe-descremarques #tabDesremarques *ngIf="selectedTab === tabList[1]" [formGroup]="formGroup"
                                    [isReadonly]="!isWriteMode"></iw-tab-ppcdefixe-descremarques>

    <iw-tab-ppcdefixe-match #tabMatching *ngIf="selectedTab === tabList[2]" [entityId]="cdfId" [formGroup]="formGroup"
                            [formId]="uuid" [isReadonly]="!isWriteMode"></iw-tab-ppcdefixe-match>

    <iw-tab-ppcdf-placement *ngIf="selectedTab === tabList[3]" [entityId]="cdfId" [formGroup]="formGroup"
                            [isReadonly]="!isWriteMode" [uuid]="uuid"></iw-tab-ppcdf-placement>

    <iw-tab-ppcdefixe-suivi #tabSuivi *ngIf="selectedTab === tabList[4]" [entityId]="cdfId"
                            [isReadonly]="!isWriteMode"></iw-tab-ppcdefixe-suivi>

</iw-tab>
