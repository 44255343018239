import {Component, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {SaisService} from '@app/sam-base/core/services/sais.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {DestroyForm} from '@app/sam-base/core/store/actions/global-form.actions';
import {Canton, ComboboxItem, IwGridColumn} from '@app/sam-base/models';
import {Sais} from '@app/sam-base/models/salary/sais';

@Component({
    templateUrl: './bareme.component.html'
})
export class BaremeComponent extends BaseStoreFormComponent<Canton> implements OnInit {
    public optionsBareme: ComboboxItem<string>[] = [];
    public selBareme = '';
    public saisList: Sais[] = [];
    public columns = this.getColumns();

    constructor(protected readonly _store: IwStoreService, protected readonly _saisService: SaisService,
                _restService: RestApiService) {
        super(_store);
    }

    public get baremes(): string {
        return this.getData('baremes') || '';
    }

    public get canton(): string {
        return this.getData('canton') || '';
    }

    public get annee(): string {
        return this.getData('annee') || '';
    }

    public ngOnInit(): void {
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this.baremes.split(',')
            .forEach((e: string) => {
                this.optionsBareme.push({
                    name: e,
                    value: e
                });
            });
        this.selBareme = this.optionsBareme[0].value;
    }

    public getBareme(): void {
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this._saisService.getSaisByBaremeCantonAndAnnee(this.canton, this.selBareme, this.annee)
            .subscribe((vals: Sais[]) => {
                this.saisList = vals;
                this._store.dispatch(new actions.SetLoading(this.uuid, false));

            });
    }

    public closeDialog() {
        this._store.dispatch(new DestroyForm(this.uuid));
    }

    private getColumns(): IwGridColumn<Sais>[] {
        return [
            {
                prop: 'annee',
                name: 'annee',
                index: 0,
                width: 70
            },
            {
                prop: 'bareme',
                name: 'bareme',
                index: 1,
                width: 70
            },
            {
                prop: 'minAnn',
                name: 'minann',
                index: 2,
                width: 70,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'maxAnn',
                name: 'maxann',
                index: 3,
                width: 70,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'minMen',
                name: 'minmen',
                index: 4,
                width: 70,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'maxMen',
                name: 'maxmen',
                index: 5,
                width: 70,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'minHor',
                name: 'minhor',
                index: 6,
                width: 70,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'maxHor',
                name: 'maxhor',
                index: 7,
                width: 70,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'taux',
                name: 'taux',
                index: 8,
                width: 70,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'tauxAth',
                name: 'tauxath',
                index: 9,
                width: 70,
                type: 'mnt',
                align: 'right'
            }];
    }
}
