<form [formGroup]="formGroup" class="row space-form">

    <iw-textfield #txtGrh_id [isDisabled]="true" [value]="grhIdName" class="iw-input col-xs-2"></iw-textfield>

    <iw-grh-combo-box #cboGrh_id (valueChange)="getFollow($event)" [grhType]="['3', 'C']" class="iw-input col-xs-9"
                      formControlName="grhId" id="cboGrh_id"></iw-grh-combo-box>

    <iw-textfield #Standardfield1 [isDisabled]="true" [value]="grhType" class="iw-input col-xs-1"></iw-textfield>

    <iw-textfield #txtMntEmp [labelAlign]="'left'" [label]="'montantEmploye' | translate" class="iw-input col-xs-4"
                  formControlName="mntemp"></iw-textfield>

    <iw-textfield #Standardfield2 [isDisabled]="true" [value]="follow" class="iw-input col-xs-2"></iw-textfield>

    <iw-textfield #txtncoeff_emp [isDisabled]="true" [labelAlign]="'left'" [label]="'coefficient' | translate"
                  class="iw-input col-xs-4" formControlName="ncoeffEmp"></iw-textfield>

    <iw-textfield #txtNcoeff [isDisabled]="true" class="iw-input col-xs-2" formControlName="ncoeff"></iw-textfield>

    <iw-textfield #txtMntCli [labelAlign]="'left'" [label]="'montantClient' | translate" class="iw-input col-xs-4"
                  formControlName="mntcli"></iw-textfield>

    <iw-textfield #tctNcoeff_cli [labelAlign]="'left'" [label]="'coefficient' | translate"
                  class="iw-input col-xs-4 col-xs-offset-2" formControlName="ncoeffCli"></iw-textfield>

    <iw-textfield #txtLibelle [labelAlign]="'left'" [label]="'libelle' | translate" class="iw-input col-xs-12"
                  formControlName="libelle"></iw-textfield>

    <iw-textarea #edtNotes [height]="55" [isDisabled]="true" [labelAlign]="'left'" [label]="'remarques' | translate"
                 class="iw-textarea col-xs-12" formControlName="notes"></iw-textarea>

    <iw-button #btnCancel (press)="destroyForm()" [text]="'annuler' | translate"
               class="iw-button col-xs-2 col-xs-offset-8"></iw-button>

    <iw-button #btnOk (press)="saveChanges()" [isDisabled]="formGroup.invalid" [text]="'enregistrer' | translate"
               class="iw-button col-xs-2" id="btnOk"></iw-button>

</form>
