import { HttpClient } from '@angular/common/http';
import {Component, OnInit, Type, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwTableComponent} from '@app/sam-base/components';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {ModalService, SearchNavigationService} from '@app/sam-base/core';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {SetLoading} from '@app/sam-base/core/store/actions/global-form.actions';
import {IwStoreService} from '@app/sam-base/core/store/iw-store.service';
import {ToastService} from '@app/sam-base/core/toast';
import {sortObjectArray} from '@app/sam-base/helpers/sort';
import {FormKeys, IwGridColumn, IwGridOptions} from '@app/sam-base/models';
import {Ppagi, Ppagiview, Ppemp, Ppper} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Component({
    templateUrl: './aginter-new.component.html'
})
export class AginterNewComponent extends BaseStoreFormComponent<Ppagi> implements OnInit {

    public readonly = false;
    public empList: Ppemp[] = [];
    public tableOptions?: IwGridOptions<Ppemp>;
    public withAgi = true;

    public type: Type<Ppemp> = Ppemp;

    @ViewChild('agiEmpTable') public empTable?: IwTableComponent<Ppemp>;

    public customColumns: IwGridColumn<Ppemp>[] = [{
        prop: 'empId', index: 0
    }, {
        prop: 'nom', index: 1
    }, {
        prop: 'prenom', index: 2
    }];

    private _baseUrl = '';
    private _salId?: string;

    constructor(store: IwStoreService, private _http: HttpClient, private _translate: TranslateService, private _modalService: ModalService, private readonly searchNavService: SearchNavigationService, private _toast: ToastService) {
        super(store);
        this._baseUrl = environment.backendURL;

        this.tableOptions = {
            data: [], columns: this.customColumns, selectionMode: 'checkbox'
        };
    }

    public ngOnInit() {
        this._http
            .get(this._baseUrl + 'period/active')
            .subscribe((ppper: Ppper) => {
                this._salId = ppper.salId;
                this.resetForm();
            });
    }


    public prepareClick() {
        this.readonly = true;

        const ageId = this.getFormValue('ageId');
        const salId = this.getFormValue('salId');

        this._http.get<Ppemp[]>(this._baseUrl + 'aginter/prepare/' + salId + '/' + ageId + '/' + this.withAgi)
            .subscribe((emps) => {
                this.empList = sortObjectArray(emps, 'nom');
                this.fillTable();
            });

    }

    public resetForm() {
        this.setFormValue('ageId', undefined);
        this.setFormValue('salId', this._salId);
        this.empList = [];
        this.readonly = false;
    }

    // eslint-disable-next-line complexity
    public async generate() {

        const options: ModalMessageOptions = {
            message: [this._translate.instant('generer') + '?'], showCancel: true, title: '', width: 200
        };

        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this._store.dispatch(new SetLoading(this.uuid, true));
            const toGenerate: string[] = [];
            const indexList = this.empTable?.selectedIndex;
            if (indexList && indexList?.length > 0) {
                for (const i of indexList) {
                    toGenerate.push(this.empList[i].empId || '');
                }
                const salId = this.getFormValue('salId');

                this.generateAgi(toGenerate, salId);
            } else {
                this._store.dispatch(new SetLoading(this.uuid, false));
            }
        } catch (_) {
            this._store.dispatch(new SetLoading(this.uuid, false));
        }
    }

    public cancel() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    protected getFormControlNames(): FormKeys<Ppagi> {
        return [['ageId', new UntypedFormControl('', [Validators.required])], ['salId', new UntypedFormControl('', [Validators.required])]];
    }

    private fillTable() {
        this.tableOptions = {
            data: this.empList, columns: this.customColumns
        };
    }

    private generateAgi(empIds: string[], salId: string) {
        empIds.filter(id => !!id)
            .forEach((id, index) => {
                this._http
                    .post<Ppagi[]>(this._baseUrl + 'aginter/calculate/' + id, {salId})
                    .pipe(catchError(() => of(undefined)))
                    .subscribe(() => {
                        this._toast.success('agi_generation_success');
                        if (index + 1 === empIds.length) {
                            this.searchNavService.navigate({entity: Ppagiview});
                            this._store.dispatch(new actions.DestroyForm(this.uuid));
                        }
                        this._store.dispatch(new SetLoading(this.uuid, false));
                    });
            });
    }
}
