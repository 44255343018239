<form [formGroup]="formGroup"
      class="row spaceForm">

    <iw-textarea #edtMisDet
                 [height]="110"
                 [isDisabled]="true"
                 [value]="getEdtMisDetValue()"
                 class="iw-textarea col-xs-12 no-padding margin-bottom-5"></iw-textarea>

    <div class="row col-xs-12 no-padding">
        <iw-ppgri-dropdown #cboTin_id
                           (valueChange)="setGriId($event)"
                           [isDisabled]="isReadonly || !isNew"
                           [labelAlign]="'left'"
                           [label]="'type' | translate"
                           [type]="0"
                           class="iw-input col-xs-10 no-padding-left"
                           formControlName="griId"></iw-ppgri-dropdown>

        <iw-textfield #txtGri_id
                      [isDisabled]="true"
                      [value]="griId"
                      class="iw-input col-xs-2 no-padding-right"></iw-textfield>
    </div>

    <div class="row col-xs-12 no-padding">
        <iw-textfield #txtQte
                      (valueChange)="calcTotal()"
                      [isDisabled]="isReadonly || fieldsDisabled"
                      [labelAlign]="'left'"
                      [label]="'nombre' | translate"
                      class="iw-input col-xs-6 no-padding-left text-align-right"
                      formControlName="qte"></iw-textfield>

        <iw-textfield #txtUnite
                      [isDisabled]="isReadonly || fieldsDisabled"
                      class="iw-input col-xs-2"
                      formControlName="unite"></iw-textfield>
    </div>

    <div class="row col-xs-12 no-padding">
        <iw-textfield #txtMntEmp
                      (controlFocus)="!$event ? setDefaultValue('mntemp') : ''"
                      (valueChange)="calcTotalEmp()"
                      [isDisabled]="isReadonly || mntEmpDisabled"
                      [labelAlign]="'left'"
                      [label]="'montantEmploye' | translate"
                      [type]="'text'"
                      class="iw-input col-xs-6 no-padding-left text-align-right"
                      formControlName="mntemp"></iw-textfield>

        <iw-textfield #txtTotEmp
                      [isDisabled]="true"
                      class="iw-input col-xs-3 text-align-right"
                      formControlName="totemp"></iw-textfield>
    </div>

    <div class="row col-xs-12 no-padding">
        <iw-textfield #txtMntCli
                      (controlFocus)="!$event ? setDefaultValue('mntcli') : ''"
                      (valueChange)="calcTotalCli()"
                      [isDisabled]="isReadonly || fieldsDisabled"
                      [labelAlign]="'left'"
                      [label]="'montantClient' | translate"
                      class="iw-input col-xs-6 no-padding-left text-align-right"
                      formControlName="mntcli"></iw-textfield>

        <iw-textfield #txtTotCli
                      [isDisabled]="true"
                      class="iw-input col-xs-3 text-align-right"
                      formControlName="totcli"></iw-textfield>
    </div>

    <iw-textfield #txtLibelle
                  [isDisabled]="isReadonly || fieldsDisabled"
                  [labelAlign]="'left'"
                  [label]="'libelle' | translate"
                  class="iw-input col-xs-12 no-padding margin-bottom-5"
                  formControlName="libelle"></iw-textfield>

    <iw-button #btnCancel
               (press)="destroyForm()"
               [text]="'annuler' | translate"
               class="iw-button col-xs-3 col-xs-offset-6"></iw-button>

    <iw-button #btnOk
               (press)="saveChanges()"
               [isDisabled]="!isValid"
               [text]="'enregistrer' | translate"
               class="iw-button col-xs-3 no-padding-right"></iw-button>

</form>
