import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Ppemp} from '@app/sam-base/models/placement';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const ppempTabsFormControls: TabFormControl<Ppemp>[] = [
    {
        formControls: [
            'empId',
            'empNo',
            'noGestion',
            'cempstatus',
            'cempstatustext',
            'nomconseiller',
            'dateCreat',
            'dateModif',
            'noavs',
            'firstRap',
            'allowemail',
            'sexe',
            'dateDelet',
            'dtAnyModif',
            'empstatus',
            'webfrstexp',
            'imis2ijmod',
            'dipl5',
            'nextSm',
            'conjnoas13',
            'webimpnote',
            'salgenfreq',
            'lweb',
            'svceMil',
            'aptId',
            'lperd1',
            'weblastexp',
            'geoLat',
            'orpquaId',
            'diplbin',
            'apt8',
            'aptbin',
            'dipl2',
            'cmAdr',
            'forcelpp',
            'lanData',
            'lppheucnt1',
            'quaId',
            'rating',
            'modCnt',
            'agiFrom',
            'geoLon',
            'ofsnr',
            'caissemal',
            'localDate',
            'websource',
            'lppsemcnt0',
            'dateEng',
            'keywords',
            'modecreat',
            'cempstatu3',
            'regions',
            'pregnant',
            'info4',
            'webFin',
            'info3',
            'webimpdate',
            'webimpdata',
            'info5',
            'primId',
            'modData',
            'apt6',
            'dipl3',
            'etatsante',
            'conjWkdeb',
            'conjWkfin',
            'acofact',
            'typehor',
            'conjCh',
            'confession',
            'info1',
            'info6',
            'apt3',
            'apt9',
            'apt1',
            'lppsemcnt1',
            'apt2',
            'info2',
            'ppempfrx',
            'profOther',
            'lrgdp',
            'photo',
            'webimpfile',
            'lexp2ij',
            'dipl4',
            'horaires',
            'apt10',
            'userId',
            'apt7',
            'lperbus',
            'dipl1',
            'empData',
            'apt5',
            'lsunet',
            'disponible',
            'textSm',
            'apt4',
            'syncflag',
            'pregtexte',
            'ijData',
            'xmlData',
            'cempstatu2',
            'empaptId',
            'userCreat',
            'userDelet',
            'userModif',
            'ppcon',
            'ppagence',
            'datedossier',
            'empKeywords',
            'professions',
            'absenses',
            'remarques']
    },
    {
        title: 'general',
        formControls: [
            'cpolitesse',
            'nom',
            'prenom',
            'adresse1',
            'adresse2',
            'npa',
            'lieu',
            'pays',
            'tel1',
            'tel2',
            'natel',
            'allowsms',
            'urgence',
            'email',
            'noavs13',
            'datenaiss',
            'ageId',
            'empintype',
            'datedossie',
            'conId',
            'typejob',
            'dateccadre',
            'firstMis',
            'lastMis',
            'misId',
            'firstAct',
            'lastAct',
            'cdfId']
    },
    {
        title: 'situation',
        formControls: [
            'etatcivil',
            'datetatciv',
            'nation',
            'origine',
            'lieunaiss',
            'permis',
            'permisno',
            'permDtde',
            'permDta',
            'permNote',
            'nomjf',
            'soumisis',
            'requerant',
            'lchomage',
            'agi',
            'caissecho',
            'enfnb',
            'enfnbcharg',
            'enfnballoc',
            'conjWork',
            'conjWkch',
            'conjFi',
            'enfConcub',
            'enfMenind',
            'conjNom',
            'conjprenom',
            'conjNaiss',
            'conjnation',
            'conjEmpl',
            'conjpermis',
            'conjpedta',
            'lallocs',
            'lconjalloc',
            'nobenalloc',
            'conjprof',
            'conjisch']
    },
    {
        title: 'studiesExperience',
        formControls: [
            'profFrom',
            'lastjob',
            'parcours',
            'apprent',
            'certificat',
            'swissdecFormation',
            'swissdecPosition',
            'swissdecConfession']
    },
    {
        title: 'matching',
        formControls: ['profession']
    },
    {
        title: 'notesValidation',
        formControls: [
            'note',
            'imgfname',
            'apprec',
            'usertag',
            'listenoir',
            'cmotifnoir']
    },
    {
        title: 'languageVehicleLicense',
        formControls: [
            'langue0',
            'langue00',
            'langue1',
            'lan1Parle',
            'lan1Ecrit',
            'lan1Lu',
            'langue2',
            'lan2Parle',
            'lan2Ecrit',
            'lan2Lu',
            'langue3',
            'lan3Parle',
            'lan3Ecrit',
            'lan3Lu',
            'langue4',
            'lan4Parle',
            'lan4Ecrit',
            'lan4Lu',
            'lanNote',
            'lpervoi',
            'lpermoto',
            'lperpl',
            'lperrem',
            'lperchan',
            'vehpernote',
            'lvoiture',
            'lmoto',
            'lvelo',
            'vehNote']
    },
    {
        title: 'souhaits',
        formControls: [
            'typejob',
            'postdesire',
            'saldesire',
            'typehortxt',
            'congdesire',
            'concuc',
            'concuctxt',
            'preavis']
    },
    {
        title: 'references',
        formControls: [
            'ppempReferences',
            'inquiry',
            'poursuites',
            'casier',
            'extrcasier']
    },
    {
        title: 'salaryPaymentLpp',
        formControls: [
            'firstMis',
            'lppstart0',
            'lppforce0',
            'lppheucnt0',
            'lppwcount',
            'lppforce1',
            'langue',
            'noteBo',
            'opMois',
            'opVital',
            'opNote',
            'lnoaco',
            'bstoemail',
            'paytype',
            'dtatype',
            'banque',
            'banqueAdr',
            'banqueClr',
            'banqueSwi',
            'banqueCpt',
            'iban',
            'salgenfreq']
    },
    {
        title: 'suivi',
        formControls: []
    },
    {
        title: 'docs',
        formControls: []
    }];
