import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Pprap} from '@app/sam-base/models/placement';
import {Ppheusaid2Input} from '@shared/widgets/iw-components/iw-hours-grid/iw-hours-grid.model';

import {PpheusaidService} from '../../../services/ppheusaid.service';
import {ReportsService} from '../../../services/reports.service';

/**
 * Tab General of parametres Generaux
 */
@Component({
    selector: 'iw-tab-rapport-heures', templateUrl: './tab-rapport-heures.component.html'
})
export class TabRapportHeuresComponent extends BasePartialFormComponent<Pprap> implements OnInit {
    public gridHoursInput?: Ppheusaid2Input;

    @Input()
    public set report(rep: Pprap | undefined) {
        this._report = rep;
    }

    public get report(): Pprap | undefined {
        return this._report;
    }

    private _report?: Pprap;

    constructor(private _ppheusaidService: PpheusaidService, private _reportsService: ReportsService) {
        super();
    }

    public ngOnInit() {
        if (this.getFormValue('rapId')) {
            this.prepareHoursInput();
        }
        this.subscribeValueChange('rapId', () => {
            this.prepareHoursInput();
        });
    }

    private prepareHoursInput() {
        if (this.report?.rapId) {
            this._ppheusaidService.getDataByRapId(this.report.rapId)
                .subscribe((elem) => {
                    this.gridHoursInput = this._reportsService.getGridHoursInput(elem);
                });
        }
    }
}
