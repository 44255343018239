<div class="row">
    <div class="col-xs-12" style="height: 300px;">
        <iw-rest-grid #ppcdeMissions (rowDoubleClick)="onRowDoubleClick($event)" [autoFormClick]="false"
                      [customColumns]="columns" [hasGridColumnMenu]="false" [queryStatements]="query"
                      [type]="type"></iw-rest-grid>
    </div>
    <div class="col-xs-12">
        <div class="flex flex-end">
            <iw-button (press)="openNewMission()" [text]="'new_mission' | translate" class="margin-top-5"></iw-button>
        </div>
    </div>