<form [formGroup]="formGroup"
      class="row">
    <iw-textfield [isDisabled]="true"
                  [ngClass]="clientClass"
                  class="iw-textfield col-xs-2 no-padding-left"
                  formControlName="cliId"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  class="iw-textfield col-xs-9 no-padding-left"
                  formControlName="nom"></iw-textfield>
    <iw-button #associationBtn
               (press)="openPpcli()"
               [isDisabled]="false"
               *iwAccessGroups="PLACEMENT_USER_ROLES"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>
</form>

<iw-tab [(selectedTab)]="selectedTab"
        [invalidTabs]="invalidTabList"
        [tabs]="tabList">

    <iw-client-tab-general *ngIf="selectedTab === tabList[0]"
                           [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"></iw-client-tab-general>

    <iw-client-tab-autre *ngIf="selectedTab === tabList[1]"
                         [entityId]="cliId"
                         [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"></iw-client-tab-autre>

    <iw-tab-contacts *ngIf="selectedTab === tabList[2]"
                     [entityId]="cliId"
                     [formGroup]="formGroup"
                     [isReadonly]="!isWriteMode"></iw-tab-contacts>

    <iw-tab-address *ngIf="selectedTab === tabList[3]"
                    [entityId]="cliId"
                    [formGroup]="formGroup"
                    [isReadonly]="!isWriteMode"></iw-tab-address>

    <iw-client-tab-notes *ngIf="selectedTab === tabList[4]"
                         [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"></iw-client-tab-notes>

    <iw-tab-suivi *ngIf="selectedTab === tabList[5]"
                  [formGroup]="formGroup"
                  [gecli]="gecli"
                  [isReadonly]="!isWriteMode">
    </iw-tab-suivi>

    <iw-client-tab-efacture *ngIf="selectedTab === tabList[6]"
                            [formGroup]="formGroup"
                            [isReadonly]="!isWriteMode"></iw-client-tab-efacture>

    <iw-tab-client-docs *ngIf="selectedTab === tabList[7]"
                       [entityId]="cliId"
                       [isReadonly]="!isWriteMode"></iw-tab-client-docs>

    <iw-client-tab-ij *ngIf="selectedTab === tabList[8]"
                      [formGroup]="formGroup"
                      [isReadonly]="!isWriteMode"></iw-client-tab-ij>
</iw-tab>
