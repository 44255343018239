import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class SaparService extends RestClient<any> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getMpaByPayType(payType: number): Observable<string> {
        return this._http.get(this._baseUrl + `sapar/${payType}/mpa`, {responseType: 'text'});
    }
}
