import {FormDescription} from '@app/sam-base/core';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Saconj} from '@sam-base/models';
import {SaconjComponent} from '../components/saconj/saconj.component';

export const SACONJ_DEFAULT_FORM: FormDescription<Saconj> = {
    form: SaconjComponent,
    state: {
        ...buildDefaultState(Saconj),
        diagModal: true,
        diagTitle: 'saconj_form_title',
        editMode: 'waiting',
        width: 800
    }
};
