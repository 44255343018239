<form [formGroup]="formGroup"
      class="row space-form">
    <div class="col-xs-10">
        <div class="row">
            <iw-enum-dropdown #drpTypePaiement
                              [inputEnum]="payTypes"
                              [isDisabled]="!isInEditMode"
                              [labelAlign]="'top'"
                              [label]="'typePaiement' | translate"
                              class="iw-input col-xs-4"
                              formControlName="payType"></iw-enum-dropdown>
            <iw-textfield
                    [isDisabled]="true"
                    [labelAlign]="'top'"
                    [label]="' '"
                    [value]="payType"
                    class="col-xs-1"></iw-textfield>
            <iw-enum-dropdown #drpTypeDTA
                              [inputEnum]="dtaTypes"
                              [isDisabled]="!isDtaTypeSelected || !isInEditMode"
                              [labelAlign]="'top'"
                              [label]="'typeDTA' | translate"
                              class="col-xs-offset-1 col-xs-4"
                              formControlName="dtaType"></iw-enum-dropdown>
        </div>
        <div class="row">
            <iw-date-picker #txtDatePmnt
                            [isDisabled]="!isInEditMode || isDtaTypeSelected"
                            [labelAlign]="'top'"
                            [label]="'date' | translate"
                            class="iw-input col-xs-4"
                            formControlName="datePmnt"></iw-date-picker>
            <iw-textfield #txtPay
                          [isDisabled]="!isInEditMode"
                          [labelAlign]="'top'"
                          [label]="'montant' | translate"
                          [type]="'number'"
                          class="col-xs-offset-2 col-xs-4"
                          formControlName="montant"></iw-textfield>
        </div>
        <div class="row">
            <iw-textfield #txtText
                          [isDisabled]="!isInEditMode"
                          [labelAlign]="'top'"
                          [label]="'texte' | translate"
                          class="iw-input col-xs-12"
                          formControlName="texte"></iw-textfield>
        </div>
        <div *ngIf="isDtaTypeSelected"
             class="row padding-top-10 padding-left-10">
            <div class="iw-label col-xs-2 margin-top-10"> {{ 'banque' | translate }}</div>
            <iw-textfield #txtBanque
                          [isDisabled]="true"
                          [noLabel]="true"
                          class="iw-label col-xs-6 margin-top-10"
                          formControlName="banque"></iw-textfield>
            <div class="col-xs-4"></div>

            <div class="iw-label col-xs-2 margin-top-10"></div>
            <iw-textfield #txtBanqueAdr
                          [isDisabled]="true"
                          [noLabel]="true"
                          class="iw-label col-xs-6 margin-top-10"
                          formControlName="banqueAdr"></iw-textfield>
            <div class="col-xs-4"></div>

            <div class="iw-label col-xs-2 margin-top-10"> {{ 'clearing' | translate }}</div>
            <iw-textfield #txtClearing
                          [isDisabled]="true"
                          [noLabel]="true"
                          class="iw-label col-xs-3 margin-top-10"
                          formControlName="banqueClr"></iw-textfield>
            <div class="col-xs-7"></div>

            <div class="iw-label col-xs-2 margin-top-10"> {{ 'swift' | translate }}</div>
            <iw-textfield #txtCompte
                          [isDisabled]="true"
                          [noLabel]="true"
                          class="iw-label col-xs-6 margin-top-10"
                          formControlName="banqueSwi"></iw-textfield>
            <div class="col-xs-4"></div>

            <div class="iw-label col-xs-2 margin-top-10"> {{ 'compte' | translate }}</div>
            <iw-textfield #txtCompte
                          [isDisabled]="true"
                          [noLabel]="true"
                          class="iw-label col-xs-6 margin-top-10"
                          formControlName="banqueCpt"></iw-textfield>
            <div class="col-xs-4"></div>

            <div class="iw-label col-xs-2 margin-top-10"> {{ 'iban' | translate }}</div>
            <iw-textfield #txtIBAN
                          [isDisabled]="true"
                          [noLabel]="true"
                          class="iw-label col-xs-6 margin-top-10"
                          formControlName="iban"></iw-textfield>
            <div class="col-xs-4"></div>
        </div>

        <!-- CHEQUE -->
        <div *ngIf="isChequeTypeSelected"
             class="row padding-top-10">
            <iw-textfield #txtNoCheque
                          [labelAlign]="'top'"
                          [label]="'noCheque' | translate"
                          class="iw-input col-xs-12"
                          formControlName="noCheque"></iw-textfield>
        </div>

        <div class="row padding-top-10">
            <iw-sapaimode-dropdown #cboMpa1
                                   [isDisabled]="!isInEditMode"
                                   [labelAlign]="'top'"
                                   [label]="'payeDepuis' | translate"
                                   [payType]="[payType]"
                                   [size]="'large'"
                                   class="iw-input col-xs-9"
                                   formControlName="mpaId"></iw-sapaimode-dropdown>
            <iw-textfield #txtPayAfterId
                          [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="' '"
                          [value]="mpaId"
                          class="iw-label col-xs-1"></iw-textfield>
        </div>
        <div class="row">
            <iw-checkbox #chkPay
                         [isDisabled]="!canEditCheckboxPay"
                         [labelAlign]="'top'"
                         [label]="'paye' | translate"
                         class="col-xs-2"
                         formControlName="paid"></iw-checkbox>
        </div>
    </div>

    <div class="col-xs-2 flex flex-v separator-left">
        <iw-textfield #txtEpId
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]=" "
                      class="iw-input padding-top-15 padding-bottom-10"
                      formControlName="epId"></iw-textfield>

        <iw-button #btnModif
                   (press)="modifyEntry()"
                   *ngIf="!isReadonly && !isWriteMode"
                   [text]="'sidemenu_edit' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>
        <iw-button #btnSave
                   (press)="saveEntry()"
                   *ngIf="isInEditMode"
                   [isDisabled]="!formGroup.valid"
                   [text]="'sidemenu_save' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnCancel
                   (press)="cancelEditionMode()"
                   *ngIf="isInEditMode"
                   [text]="'sidemenu_cancel' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnClose
                   (press)="closeDialog()"
                   [text]="'fermer' | translate"
                   class="iw-button btn-block"
                   style="margin-block-start: auto"></iw-button>
    </div>
</form>
