import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {MissionCalculationsService} from '@app/sam-base/core/services/mission-calculations.service';
import {Ppmis} from '@app/sam-base/models/placement';
import {MissionCalcCCTResponse} from '@app/sam-base/models/placement/mission-calculations-result.model';
import {TranslateService} from '@ngx-translate/core';
import {ModalMessageComponent} from '@sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@sam-base/components/modal/modal-message/modal-message.options';

import {RestEntityClient} from '@sam-base/core/rest-api/entity/rest-entity-client';
import {RestApiService} from '@sam-base/core/rest-api/rest-api.service';
import {ModalService} from '@sam-base/core/services/modal.service';
import {lastValueFrom, of} from 'rxjs';
import {catchError, take} from 'rxjs/operators';
import {HeuresSup, SalaryMode} from '../../tab-ppmis-salary-enums';

@Component({
    selector: 'iw-mis-salary-month', templateUrl: './mis-salary-month.component.html'
})
export class MisSalaryMonthComponent extends BasePartialFormComponent<Ppmis> {

    @Input()
    public set tempdataActivated(mode: boolean) {
        this._tempdatActivated = mode;
    }

    public get tempdataActivated() {
        return this._tempdatActivated;
    }

    @Output() public calculating: EventEmitter<boolean> = new EventEmitter();

    public get isBaseSalaryMode() {
        return this.getFormValue<number>('shbaseglob') !== SalaryMode.salary_mode_global;
    }

    public get isGlobalSalaryMode() {
        return this.getFormValue<number>('shbaseglob') === SalaryMode.salary_mode_global;
    }

    public get shtxmode(): boolean {
        return this.getFormValue('shtxmode') || false;
    }

    public get mntJfz(): number | undefined {
        return this.getFormValue<number>('mntJfz');
    }

    public get isJf() {
        return !!this.getFormValue<boolean>('ljfh');
    }

    public get is13() {
        return !!this.getFormValue<boolean>('l13');
    }

    public get isVac() {
        return !!this.getFormValue<boolean>('lvac');
    }

    public get isSalBaseInvalid() {
        return (!this.isReadonly && this.formGroup?.controls['salBase'].invalid);
    }

    public get salBaseTooltip() {
        return this._translate.instant('cct_min_value_error') + this.getFormValue('cctMinValue');
    }

    public salaryModeEnum = SalaryMode;
    public heursSupNames = HeuresSup;

    private _tempdatActivated = false;
    private _ppmisRestClient: RestEntityClient<Ppmis>;


    constructor(private readonly _calcService: MissionCalculationsService, private readonly _translate: TranslateService, private readonly _restClient: RestApiService, private readonly _modalService: ModalService) {
        super();
        this._ppmisRestClient = _restClient.getEntityClient(Ppmis);

    }

    public triggerReadyToCalculate() {
        if (this.isReadonly) {
            return;
        }
        this.setFormValue('isCalculating', true);
    }

    public calculate() {
        if (this.isReadonly) {
            return;
        }
        if (!this.formGroup) {
            return;
        }

        setTimeout(async () => {
            const mis: Ppmis = this.formGroup?.value;
            const res = await lastValueFrom(this.getCalculation(mis));
            if (typeof res !== 'boolean') {
                this.applyCalculations(res);
            } else {
                this.calculating.emit(false);
            }
        }, 500);
    }

    public asyncCalc(formControlName: string) {
        this.formGroup?.controls[formControlName]
            .valueChanges.pipe(take(1))
            .subscribe(() => {
                this.calculate();
            });
    }

    private getCalculation(mis: Ppmis) {
        this.calculating.emit(true);
        return this._calcService.getCalculationsWithCCT(mis)
            .pipe(catchError(err => {

                const msg = [this._translate.instant('calculation_fail_message_1'), this._translate.instant('calculation_fail_message_2'), this._translate.instant('calculation_fail_message_3'), this._translate.instant('calculation_fail_message_4')];

                const optionsCalcError: ModalMessageOptions = {
                    message: msg,
                    showCancel: false,
                    title: this._translate.instant('topmenu_salaries'),
                    confirmMessage: this._translate.instant('calculation_fail_message'),
                    okDisabled: false,
                    multiline: true
                };

                this._modalService
                    .showModal(ModalMessageComponent, optionsCalcError);

                const salMonthGroup: string[] = ['salBase', 'mnt13', 'tx13', 'lprov13', 'salGlobal', 'shbaseglob', 'salTotal'];

                const misId = this.getFormValue<string>('misId');
                if (!misId) {
                    return of(false);
                }
                this._ppmisRestClient
                    .getById(misId)
                    .subscribe(ppmis => {
                        const formGroup = this.formGroup?.controls as object;
                        Object.keys(formGroup)
                            .forEach(key => {
                                if (salMonthGroup.includes(key)) {
                                    this.setFormValue(key as keyof Ppmis, ppmis[key as keyof Ppmis]);
                                }
                            });
                    });
                this.calculating.emit(false);
                return of(false);
            }));
    }

    private applyCalculations(result: MissionCalcCCTResponse) {
        if (!this.formGroup) {
            this.calculating.emit(false);
            return;
        }

        this.applyFormControls(result);

        /** Delay pause updates for 1sec */
        setTimeout(() => {
            const base = this.getFormValue<string>('clitarif');
            this.setFormValue('clitarif', base);
            this.calculating.emit(false);
        }, 1000);
    }

    private applyFormControls(result: any) {
        for (const k of Object.keys(result)) {
            if (this._calcService.canApplyFormcontrol(k, result, this.formGroup)) {
                if (k === 'clitarifra') {
                    this._calcService.supCttCalculation.next({
                        misId: this.getFormValue('misId') ?? '', supCct: result[k]
                    });
                } else {
                    this.setFormValue(<any>k, result[k]);
                }
            }
        }
    }
}
