import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Saparncs} from '@app/sam-base/models/salary/saparncs';

import {SaparncsComponent} from '../components/saparncs/saparncs.component';

export const SAPARNCS_DEFAULT_FORM: FormDescription<Saparncs> = {
    form: SaparncsComponent, state: {
        ...buildDefaultState(Saparncs),
        diagTitle: 'saparmcs_form_title',
        width: 800,
        diagShowSideMenu: false,
        editMode: 'read'
    }
};
