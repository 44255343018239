import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppqua} from '@app/sam-base/models/placement';

import {ProfessionsConfigComponent} from '../components/professions-config/professions-config.component';

export const PPQUA_DEFAULT_FORM: FormDescription<Ppqua> = {
    form: ProfessionsConfigComponent, state: {
        ...buildDefaultState(Ppqua), diagModal: true, diagTitle: 'ppqua_form_title', width: 1200, isNew: true
    }
};
