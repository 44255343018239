<form [formGroup]="formGroup"
      class="row">
    <iw-button #createDeductionEmployee
               (press)="openCreateDed()"
               [isDisabled]="isFormDisabled"
               [text]="'+'"
               class="btn-block col-xs-1 col-xs-offset-8 flex-end padding-top-15"></iw-button>
    <iw-button #deductionEmployee
               (press)="openEmployeeDeduction()"
               [isDisabled]="isDeductionSelected || isFormDisabled"
               [text]="'employee_deduction_form' | translate"
               class="btn-block col-xs-3 flex-end padding-top-15"
               style="margin-left: auto"></iw-button>
    <iw-rest-grid #saempdedviewGrid
                  (rowDoubleClick)="onDblclickEmployeeDeduction($event)"
                  (selected)="getSelectedRow($event)"
                  [autoFormClick]="false"
                  [disableContextMenu]="isFormDisabled"
                  [hasGridColumnMenu]="false"
                  [hasSortIcon]="true"
                  [queryStatements]="query"
                  [type]="type"
                  class="col-xs-12 padding-top-15"
                  style="height: 300px"></iw-rest-grid>

</form>
