import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Ppcct} from '@app/sam-base/models/placement';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const cctConfigTabsFormControls: TabFormControl<Ppcct>[] = [{
    formControls: ['cctId',
        'cctLib',
        'dateDe',
        'dateA',
        'cctgrpId',
        'remarques',
        'cctstatus',
        'contractName',
        'contractNo',
        'lexfcgroup',
        'tempdataTrans',
        'tempdataTitle']
},
    {
        title: 'ra',
        formControls: ['raDedmod',
            'raTxEmp',
            'raTxSoc',
            'raMntEmp',
            'raMntSoc',
            'raGsCot',
            'raGsCoe',
            'raGsCof',
            'raGsCfe',
            'raGsBas']
    },
    {
        title: 'execution',
        formControls: ['exDedmod',
            'exTxEmp',
            'exTxSoc',
            'exMntEmp',
            'exMntSoc',
            'exGsCot',
            'exGsCoe',
            'exGsCof',
            'exGsCfe',
            'exGsBas']
    },
    {
        title: 'formation',
        formControls: ['fcDedmod',
            'fcTxEmp',
            'fcTxSoc',
            'fcMntEmp',
            'fcMntSoc',
            'fcGsCot',
            'fcGsCoe',
            'fcGsCof',
            'fcGsCfe',
            'fcGsBas']
    },
    {
        title: 'pgm',
        formControls: ['pgmcodesh',
            'defcodpgmh',
            'pgmcodesf',
            'defcodpgmf',
            'pgmcodexpl']
    },
    {
        title: 'autreTab',
        formControls: ['shcalcmode',
            'shsupmode',
            'txVac4',
            'txVac5',
            'tx13',
            'txJf',
            'heurepause',
            'txPause',
            'typedec',
            'jfNonPayes']
    },
    {
        title: 'remarques',
        formControls: ['remarques']
    }

];
