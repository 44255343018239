import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('sadedtxage')
export class Sadedtxage {
    @IwRestEntityId() @IwColumn() public txageId?: number;
    public annee?: string;
    public dedCode?: string;
    public assCode?: string;
    public txCode?: string;
    public agemin?: number;
    public agemax?: number;
    public agemnt?: number;
}
