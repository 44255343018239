import {Component, EventEmitter, OnInit} from '@angular/core';
import {IwGridColumn, ModalComponent, RowClickEvent} from '@app/sam-base/models';
import {
    ModalOperationDetailComponent
} from '@modules/sam-main/admin/components/operation/operation-detail-modal/modal-operation-detail.component';
import {
    ModalOperationDetailOptions
} from '@modules/sam-main/admin/components/operation/operation-detail-modal/operation-modal.options';
import {ModalOperationOptions} from '@modules/sam-main/admin/components/operation/operation-modal.options';
import {Operation} from '@modules/sam-main/admin/models/operation.model';
import {OperationService} from '@modules/sam-main/admin/services/operation-service';
import {TranslateService} from '@ngx-translate/core';
import {AgencyHandlerService, ModalService, RestApiService} from '@sam-base/core';
import {ToastService} from '@sam-base/core/toast';
import {ProfileService} from '@shared/profile/profile.service';


@Component({
    templateUrl: './modal-operation.component.html'
})
export class ModalOperationComponent implements ModalComponent<string, ModalOperationOptions>, OnInit {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public operations: Operation[] = [];
    public columns = this.getColumns();
    public loading = false;
    private _ok?: (result: string) => void;
    private _cancel?: (result?: undefined) => void;

    constructor(private _translate: TranslateService,
                private _toastService: ToastService,
                private readonly _profileService: ProfileService,
                private readonly _restService: RestApiService,
                private readonly _agency: AgencyHandlerService,
                private readonly _modalService: ModalService,
                private readonly _operationService: OperationService) {
    }

    ngOnInit(): void {
        this._operationService.gelAll().subscribe({
            next: (operations) => {
                this.operations = operations;
            }
        })
    }

    setData(data: ModalOperationOptions): Promise<void> {
        return Promise.resolve();
    }


    public registerOk(action: (result: string) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public onOperationClicked($event: RowClickEvent<Operation>) {
        if (!$event.row.id) return;
        const options: ModalOperationDetailOptions = {
            showCancel: true,
            title: this._translate.instant('operations_modal_title'),
            width: 1500,
            height: 900,
            operationId: $event.row.id
        };

        this._modalService.showModal(ModalOperationDetailComponent, options);
    }

    private getColumns(): IwGridColumn<Operation>[] {
        return [
            {
                prop: 'id',
                name: 'id',
            },
            {
                prop: 'type',
                type: 'enum',
                enumPrefix: 'operation.type'
            },
            {
                prop: 'entityDescription',
                type: 'string'
            },
            {
                prop: 'status',
                type: 'enum',
                enumPrefix: 'operation.status',
            },
            {
                prop: 'dueAt',
                type: 'dateTime',
            },
            {
                prop: 'submittedBy',
                type: 'string',
            },
            {
                prop: 'submittedAt',
                type: 'dateTime',
            },
            {
                prop: 'startedAt',
                type: 'dateTime',
            },
            {
                prop: 'endedAt',
                type: 'dateTime',
            },
        ];
    }
}
