<div class="row">
    <div class="col-xs-9">
        <div>
            <h2>{{ 'included_employees_for_declaration' | translate }}</h2>
        </div>
        <div style="height: 200px;">
            <iw-rest-grid #employeesGrid
                          (rowDoubleClick)="editEmployee($event)"
                          (selected)="setSelected($event)"
                          [autoFormClick]="false"
                          [defaultSelected]="true"
                          [defaultSortDir]="'desc'"
                          [defaultSort]="'gestion'"
                          [hasFilter]="true"
                          [hasGridColumnMenu]="false"
                          [hasSortIcon]="false"
                          [itemsPerPage]="1000"
                          [queryStatements]="query"
                          [selectMode]="'checkbox'"
                          [type]="type"
                          [virtualScroll]="true"
                          id="employeesGrid"></iw-rest-grid>
        </div>
    </div>
    <div class="col-xs-3 row flex flex-v flex-evenly">
        <div class="row">
            <div class="col-xs-12 no-padding">
                <iw-button (press)="removeEmployee()"
                           [isDisabled]="isReadonly || removeEmployeeDisabled"
                           [text]="'remove_employee' | translate"></iw-button>
            </div>
        </div>
    </div>
</div>
