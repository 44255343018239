import {EmployeeDeductionInfo} from '@app/sam-base/models/salary/employeeDeductionInfo';
import {CusCodeInfo} from './cusCodeInfo';
import {TxInfo} from './txInfo';

export class EmployeeDeductionInfoForm extends EmployeeDeductionInfo {
    public get cuscode1(): number | undefined {
        return this.cusCodeInfo?.cusCode1;
    }

    public set cuscode1(value: number | undefined) {
        if (!this.cusCodeInfo) {
            this.cusCodeInfo = new CusCodeInfo();
        }
        this.cusCodeInfo.cusCode1 = value;
    }

    public get cuscode2(): number | undefined {
        return this.cusCodeInfo?.cusCode2;
    }

    public set cuscode2(value: number | undefined) {
        if (!this.cusCodeInfo) {
            this.cusCodeInfo = new CusCodeInfo();
        }
        this.cusCodeInfo.cusCode2 = value;
    }

    public get txcode(): string | undefined {
        return this.txInfo?.txCode;
    }

    public set txcode(value: string | undefined) {
        if (!this.txInfo) {
            this.txInfo = new TxInfo();
        }
        this.txInfo.txCode = value;
    }

    public get txcode2(): string | undefined {
        return this.txInfo?.txCode2;
    }

    public set txcode2(value: string | undefined) {
        if (!this.txInfo) {
            this.txInfo = new TxInfo();
        }
        this.txInfo.txCode2 = value;
    }

    public get txmanuel(): number | undefined {
        return this.txInfo?.txManuel;
    }

    public set txmanuel(value: number | undefined) {
        if (!this.txInfo) {
            this.txInfo = new TxInfo();
        }
        this.txInfo.txManuel = value;
    }

    public get txId(): number | undefined {
        return this.txInfo?.txId;
    }

    public set txId(value: number | undefined) {
        if (!this.txInfo) {
            this.txInfo = new TxInfo();
        }
        this.txInfo.txId = value;
    }
}
