import {NgModule} from '@angular/core';

import {DynamicPrintService} from './dynamic-print.service';
import {PrintService} from './print.service';
import {ReportPrintService} from './report-print.service';

@NgModule({
    providers: [DynamicPrintService, ReportPrintService, PrintService]
})
export class PrintModule {

}
