import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Saparded} from '@app/sam-base/models/salary';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const salaryDeductionTabsFormControls: TabFormControl<Saparded>[] = [{
    formControls: ['id']
}, {
    title: 'parametres',
    formControls: ['annee', 'assCode', 'dedType', 'dedTitre', 'dedActive', 'dedShtit', 'basemode', 'dedBasaut', 'gsBasaut', 'gsBas', 'gsSal', 'gsNs', 'gsCot', 'gsCoe', 'gsCotFix', 'gsCoeFix', 'minmaxmode', 'salmin', 'salmax', 'tauxmode', 'txcot', 'txcoe', 'tauxauto', 'dedCode', 'assurance']
}, {
    title: 'tabInformations', formControls: []
}];
