<div class="row">
    <div class="col-xs-10"
         style="height: 300px;">
        <iw-rest-grid #gestionTemplateGrid
                      (selected)="getSelectedRow($event)"
                      [defaultSelected]="true"
                      [hasGridColumnMenu]="false"
                      [queryStatements]="query"
                      [selectedData]="selectedData"
                      [type]="type"></iw-rest-grid>
    </div>

    <div class="col-xs-2 flex flex-v flex-evenly">
        <iw-button (press)="createNewEntry()"
                   [isDisabled]="!isReadonly"
                   [text]="'sidemenu_new' | translate"
                   id="sidemenu_new"></iw-button>
        <iw-button (press)="modifyEntry()"
                   [isDisabled]="!isReadonly"
                   [text]="'sidemenu_edit' | translate"
                   id="sidemenu_edit"></iw-button>
        <iw-button (press)="removeEntry()"
                   [isDisabled]="!isReadonly"
                   [text]="'sidemenu_delete' | translate"
                   id="sidemenu_delete"></iw-button>
        <iw-button (press)="saveEntry()"
                   [isDisabled]="isReadonly || !currFile || formGroup.invalid"
                   [text]="'sidemenu_save' | translate"
                   id="sidemenu_save"></iw-button>
        <iw-button (press)="cancelEditionMode()"
                   [isDisabled]="isReadonly"
                   [text]="'sidemenu_cancel' | translate"
                   id="sidemenu_cancel"></iw-button>
    </div>

</div>

<form [formGroup]="formGroup">
    <div class="row">
        <iw-textfield #txtPdtId
                      [isDisabled]="isReadonly"
                      [label]="'temp_name' | translate"
                      class="iw-input col-xs-12 flex-start no-padding"
                      formControlName="name"
                      id="pdtId"></iw-textfield>

        <iw-dropdown [isDisabled]="isReadonly"
                     [options]="templateTypes"
                     class="iw-input col-xs-12 flex-start no-padding"
                     formControlName="report"></iw-dropdown>
    </div>
    <div class="row">
        <div class="col-xs-6">
            <p-fileUpload #fileUploader
                          (onClear)="fileUnselected()"
                          (onSelect)="uploadDocument($event)"
                          [accept]="'.jrxml, .jpg'"
                          [cancelLabel]="'cancel' | translate"
                          [chooseLabel]="'choose' | translate"
                          [disabled]="isReadonly"
                          [invalidFileTypeMessageSummary]="'invalidFile' | translate"
                          [showUploadButton]="false"
                          customUpload="true"
                          invalidFileTypeMessageDetail=""
                          name="myfile[]"></p-fileUpload>
        </div>
        <iw-button #downloadJrxml
                   (press)="downloadTemplate()"
                   [isDisabled]="!selectedRow.id || !selectedRow.filename"
                   [text]="'download_template' | translate"
                   class="iw-button col-xs-offset-3 col-xs-3"></iw-button>

    </div>
    <div class="row">
        <p-message *ngIf="fileNameTooLong"
                   [text]="'gestion_modeles_filename_too_long' | translate"
                   severity="warn"></p-message>

    </div>

</form>
<div class="row flex flex-hr">
    <iw-button #Closebutton1
               (press)="closeDialog()"
               [text]="'fermer' | translate"
               class="iw-button col-xs-2 end-xs"></iw-button>
</div>
