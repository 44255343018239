<form *ngIf="formGroupReady" [formGroup]="formGroup" class="row padding-top-10 salary-tarif-container">
    <iw-busy [ajustLeft]="0" [ajustTop]="0" [hasDelay]="false" [isBusy]="calculating"></iw-busy>
    <div class="col-xs-6 row">
        <iw-enum-dropdown [inputEnum]="salaryModeEnum" [isDisabled]="isReadonly" [label]="'modeFacture' | translate"
                          class="col-xs-12" formControlName="misfacmode" labelAlign="left"></iw-enum-dropdown>
        <div class="space-block space-block-4"></div>
        <div class="component-frame" style="height: 120px;">
            <iw-mis-fact-hours (calculating)="calculating = $event" *ngIf="factureMode === 1" [formGroup]="formGroup"
                               [isReadonly]="isReadonly"></iw-mis-fact-hours>
            <iw-mis-fact-month (calculating)="calculating = $event" *ngIf="factureMode === 3" [formGroup]="formGroup"
                               [isReadonly]="isReadonly"></iw-mis-fact-month>
        </div>
        <div class="space-block space-block-6"></div>
        <iw-textarea [height]="175" [isDisabled]="isReadonly" [label]="'fraisEmpCli' | translate"
                     class="iw-textarea col-xs-12" formControlName="frais"></iw-textarea>
        <div class="space-block space-block-2"></div>
        <iw-cct-client-dropdown (listUpdated)="updateCctClient()" (selectedChange)="dropdownCalcCCT()"
                                [isDisabled]="isReadonly" [label]="'cct' | translate" class="iw-input col-xs-12"
                                formControlName="cctId" id="cctDropdown" labelAlign="left"
                                size="small"></iw-cct-client-dropdown>
        <div class="row col-xs-5">
            <div #sectorLabel class="iw-label col-xs-7" style="padding-left: 0 !important;"
                 title="{{'empAge' | translate}}">{{ 'empAge' | translate }}
            </div>
            <iw-textfield #txtEmpAge [isDisabled]="true" [noLabel]="true" [value]="employeeAge"
                          class="iw-input col-xs-5"></iw-textfield>
        </div>
        <iw-button (press)="openCctForm()" [isDisabled]="(isReadonly || !cctTempEnable) && !tempdataBtnModeOn"
                   [ngClass]="tempdataBtnClass" [text]="'cctTempdata' | translate" class="col-xs"></iw-button>
        <i *ngIf="isCctInvalid" [pTooltip]="'cct_tempdata_mandatory' | translate"
           class="end-xs fas fa-exclamation-triangle init-danger"></i>
        <div class="space-block space-block-2"></div>
        <iw-textfield [isDisabled]="true" [label]="'suva' | translate" [ngClass]="{'red-text': codeCnaNotExist}"
                      [tooltipPosition]="'right'" [tooltip]="(codeCnaNotExist ? 'code_suva_not_exist' : '') | translate"
                      class="col-xs-4 no-padding-right" formControlName="codecna" labelAlign="left"
                      size="small"></iw-textfield>
        <iw-button #btnEnf (press)="onBtnCodeSuvaClick()" *ngIf="!suvaPeActive" [isDisabled]="isReadonly"
                   [tooltip]="'select_suva_code' | translate" class="iw-button col-xs-1 no-padding-left"
                   style="margin-bottom: 4px;" text="..."></iw-button>
        <iw-textfield *ngIf="suvaPeActive" [isDisabled]="true"
                      [tooltip]="'La partie d\'entreprise (code SUVA) et le genre ISCO sont dérivés de la profession'"
                      class="col-xs-8" formControlName="iscoDescriptionFr"></iw-textfield>

        <div class="space-block space-block-2"></div>
        <iw-textfield [isDisabled]="true" [label]="'pgm' | translate" [ngClass]="{'red-text': codePgmNotExist}"
                      [tooltipPosition]="'right'" [tooltip]="(codePgmNotExist ? 'code_pgm_not_exist' : '') | translate"
                      class="col-xs-4 no-padding-right" formControlName="codepgm" labelAlign="left"
                      size="small"></iw-textfield>
        <iw-button #btnEnf (press)="onBtnCodePgmClick()" [isDisabled]="isReadonly || !getFormValue('cctId')"
                   [tooltip]="'select_pgm_code' | translate" class="iw-button col-xs-1 no-padding-left"
                   style="margin-bottom: 4px;" text="..."></iw-button>
        <div class="separator-block separator-top"></div>
        <iw-textfield *ngIf="factureMode !== 1" [isDisabled]="true" class="col-xs-12"></iw-textfield>
        <iw-textfield #txtCoeff *ngIf="factureMode === 1" [isDisabled]="true" [status]="coeffStatus"
                      [value]="txtCoeffValue" class="col-xs-12"></iw-textfield>
    </div>
    <div class="col-xs-6 row">
        <iw-enum-dropdown [inputEnum]="salaryModeEnum" [isDisabled]="isReadonly || tempdataBtnModeOn"
                          [label]="'modeSalary' | translate" class="col-xs-12" formControlName="missalmode"
                          labelAlign="left"></iw-enum-dropdown>
        <div class="space-block space-block-4"></div>
        <div class="component-frame" style="height: 428px;">
            <iw-mis-salary-hours #misSal (calculating)="calculating = $event" *ngIf="salaryMode === 1"
                                 [formGroup]="formGroup" [hasJfNonPayes]="jfNonPayesEnabled"
                                 [isReadonly]="isReadonly || isIwMisSalaryDisabled"
                                 [tempdataActivated]="tempdataBtnModeOn"></iw-mis-salary-hours>
            <iw-mis-salary-month (calculating)="calculating = $event" *ngIf="salaryMode === 3" [formGroup]="formGroup"
                                 [isReadonly]="isReadonly || isIwMisSalaryDisabled"
                                 [tempdataActivated]="tempdataBtnModeOn"></iw-mis-salary-month>
        </div>
        <div class="space-block space-block-6"></div>
        <iw-textfield *ngIf="salaryMode !== 1" [isDisabled]="true" class="col-xs-12"></iw-textfield>
        <iw-textfield #txtMargeHours *ngIf="salaryMode === 1" [isDisabled]="true" [value]="txtMargeHoursValue"
                      class="col-xs-12 text-center"></iw-textfield>
    </div>
</form>
