import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Ppmis} from '@app/sam-base/models/placement';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const ppmisTabsFormControls: TabFormControl<Ppmis>[] = [
    {
        formControls: [
            'nogestion',
            'nom',
            'remarques',
            'suiId',
            'chantiers',
            'coeffMin',
            'hpars',
            'geoLon',
            'mnteqcli2',
            'misIdOri',
            'pointtype',
            'horairetxt',
            'natel',
            'hparm',
            'facadrId',
            'paucalcmod',
            'jparm',
            'evreceived',
            'nxtmotif',
            'misIdNxt',
            'misData',
            'lpointeuse',
            'avemottext',
            'lieu',
            'clitariffc',
            'tauxeq',
            'primId',
            'hparj',
            'mntnuicli2',
            'dateDelet',
            'misrating',
            'clitarh125',
            'hpausaimod',
            'pointNo',
            'misstatus',
            'vref2fac',
            'pointMode',
            'ijData',
            'modecreat',
            'avemotif',
            'userId',
            'concerne',
            'misId',
            'cliattId',
            'evnbofprt',
            'cctRa',
            'lexpfac2ij',
            'empId',
            'dateModif',
            'pointRfid',
            'cliId',
            'cctFc',
            'cctEx',
            'nbrapports',
            'secId',
            'cctNote',
            'chargesdet',
            'empdedccttxt',
            'empdedtxt',
            'horAmEnd',
            'modData',
            'workdays',
            'nbfactures',
            'grtId',
            'cantonis',
            'pointData',
            'cmnbofprt',
            'clitarifex',
            'cdeId',
            'horAmBeg',
            'txnuit',
            'codepgm',
            'quagrpId',
            'codepgm2',
            'geoLat',
            'clitarh200',
            'userDelet',
            'clitarif2',
            'horPmEnd',
            'codepgm3',
            'avenant',
            'clichaId',
            'cctData',
            'xmlData',
            'horPmBeg',
            'mntpauscli',
            'nxtmottext',
            'ccnbofprt',
            'nref2fac',
            'dtanymodif',
            'forcelpp',
            'workdates',
            'lieurdv',
            'clitarh150',
            'mistype',
            'pxrevhor',
            'factbyrap',
            'mntnuitemp',
            'cliId2',
            'jpars',
            'userCreat',
            'heusaimode',
            'heueqwtx',
            'dateCreat',
            'userModif',
            'modCnt']
    },
    {
        title: 'general',
        formControls: [
            'datecontra',
            'refinterne',
            'refclient',
            'ageId',
            'secId',
            'conId',
            'quaId',
            'qualif',
            'genretrav',
            'datedebut',
            'heuredebut',
            'dureetype',
            'duree',
            'horaire',
            'horairetyp',
            'datefin',
            'finmotif',
            'lieu1',
            'lieu2',
            'lieu3',
            'misNpa',
            'misLieu',
            'service',
            'resp',
            'tel',
            'datefinp',
            'datealert',
            'alertMotif',
            'codepgm',
            'needSummary']
    },
    {
        title: 'salaryTarif',
        formControls: [
            'misfacmode',
            'frais',
            'cctId',
            'missalmode',
            'clitarif',
            'coeff',
            'clitarifmd',
            'clitarmd6f',
            'clitarifra',
            'salBase',
            'shcalcmode',
            'shtxmode',
            'ljfh',
            'mntJf',
            'lprovJf',
            'l13',
            'mnt13',
            'tx13',
            'lprov13',
            'lvac',
            'mntVac',
            'txVac',
            'lprovVac',
            'salGlobal',
            'shbaseglob',
            'mntFrais1',
            'mntFrais2',
            'salTotal',
            'heurequipe',
            'txtheueq',
            'mnteqemp',
            'mnteqcli',
            'heurenuit',
            'lprovNuit',
            'mntnuitcli',
            'heurepause',
            'txPause',
            'mntPause',
            'hpauseauto',
            'shsupmode',
            'codecna',
            'iscoDescriptionFr',
            'codepgm',
            'mntAvsetc',
            'mntAvspgn',
            'mntCct',
            'mntJfz',
            'mntLpp',
            'txJf',
            'cctMinValue',
            'tempdataText',
            'tempdataObject',
            'jfNonPayes',
            'avs',
            'pgm',
            'laa',
            'alf',
            'lpp',
            'cu1',
            'secId',
            'isCalculating']
    },
    {
        title: 'salaryIndemnities',
        formControls: []
    },
    {
        title: 'autreTab',
        formControls: [
            'attn',
            'cliadrId',
            'ccreceived',
            'cmreceived',
            'ccfrx',
            'cmfrx',
            'modefactu',
            'chantier',
            'forcecha',
            'freezcha',
            'manycha',
            'faclintxt',
            'chantier2',
            'refclient2',
            'conId2',
            'conid2Pc',
            'usertag',
            'ccfrxpf',
            'cmfrxpf',
            'chaids']
    },
    {
        title: 'notes',
        formControls: [
            'noteemp',
            'notecli',
            'note']
    },
    {
        title: 'reports',
        formControls: []
    },
    {
        title: 'suivi',
        formControls: ['notes']
    },
    {
        title: 'docs',
        formControls: []
    },
    {
        title: 'ij',
        formControls: [
            'ijRapSm',
            'ijRapnote',
            'icc2Ijmod',
            'ccemail',
            'icm2Ijmod',
            'cmemail',
            'lexpmis2Ij']
    }];
