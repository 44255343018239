import {Component, OnDestroy, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {PpempService} from '@app/modules/sam-main/placement/services/ppemp.service';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {EntityNavigationService, IwEventHubService, PpkwService, showGridDetailsAccessRoles} from '@app/sam-base/core';
import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {IwStoreService} from '@app/sam-base/core/store';
import * as globalActions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys, Saconj, Saenf} from '@app/sam-base/models';
import {Ppemp} from '@app/sam-base/models/placement';
import {Actions, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {SaenfService} from '@sam-base/core/services/saenf.service';
import * as actions from '@sam-base/core/store/actions/global-form.actions';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {Saempview} from '@sam-base/models/salary';
import {ProfileService} from '@shared/profile/profile.service';
import moment from 'moment';
import {lastValueFrom, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

import {ppempTabsFormControls} from './employee-tabs-formcontrols';

@Component({
    templateUrl: './employee.component.html',
    styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent extends BaseTabFormComponent<Ppemp> implements OnInit, OnDestroy {
    public disabledTabList: string[] = [];
    public subscriptions = new Subject();
    // eslint-disable-next-line complexity
    public SALARY_USER_ROLES = [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN,
        SamUserRole.SAM_SALAIRE_RO,
        SamUserRole.SAM_SALAIRE_RW];

    constructor(store: IwStoreService, private readonly _translate: TranslateService,
                private readonly _events: IwEventHubService<string>, private _profileService: ProfileService,
                private readonly _entityNavService: EntityNavigationService,
                private _ppempService: PpempService, private _saenfService: SaenfService, private _actions: Actions,
                private _pkwService: PpkwService) {
        super(store);
    }

    public get empId() {
        return this.getFormValue('empId') || '';
    }

    public get employeeName() {
        if (this.getFormValue('nom') === undefined || this.getFormValue('prenom') === undefined) {
            return this._translate.instant('undefined');
        }
        return `${this.getFormValue('nom')} ${this.getFormValue('prenom')}`;
    }

    public get tabData(): Ppemp | undefined {
        return this.getFormData();
    }

    public set tabData(v: Ppemp | undefined) {
        this.mergeEntityChanges(v);
    }

    private _employeeProfession = '';

    public get employeeProfession() {
        return this._employeeProfession;
    }

    public ngOnInit() {
        if (this.getFormValue('empId') === undefined) {
            // Sets freqSal by default
            this.setFormValue('salgenfreq', 4)
            this.disabledTabList.push('Docs');
        } else {
            // Disabled for story https://samredesign.atlassian.net/browse/SAM-6870
            // This._ppempService.checkIbanValidators(
            //   This.formGroup, this.getFormValue('paytype'),
            //   This.getFormValue('dtatype'));
        }
        this.subscribeEmpId();
        this.subscribeConjoint();
        this.subscribeEnfClose();
        this.subscribeToSaveEffect();
        this.subscribeProfession();
        this.setNpaLieuValidators();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public getTabsValues(): TabFormControl<Ppemp>[] {
        return ppempTabsFormControls;
    }

    public getEmpClass() {
        let empStatus = this.getFormValue('cempstatus');
        if (empStatus !== '0' && empStatus !== '1' && empStatus !== '2' && empStatus !== '5' && empStatus !== '8' && empStatus !== '9' && empStatus !== 'C') {
            empStatus = 'd';
        }
        return 'emp-color-' + empStatus;
    }

    public checkIfNew() {
        return this.isNew;
    }

    public checkIfValid() {
        return this.isValid;
    }

    public openSaemp() {
        if (!this.empId) {
            return;
        }
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this._ppempService.updateEmp(this.empId)
            .subscribe({
                next: () => this._store.dispatch(new actions.SetLoading(this.uuid, false)),
                error: () => this._store.dispatch(new actions.SetLoading(this.uuid, false))
            });

        this._entityNavService
            .navigateToEntityForm(Saempview, this.getFormValue('empId'));
    }

    protected getValidationType() {
        return Ppemp;
    }

    protected validateFields(e: Ppemp): boolean {
        return super.validateFields(e);
    }

    protected getFormControlNames(): FormKeys<Ppemp> {
        return this.getTabsFormControls(ppempTabsFormControls);
    }

    private setNpaLieuValidators() {
        if (!this.formGroup) {
            return;
        }
        this.formGroup?.controls['npa']
            .setValidators(Validators.required);
        this.formGroup?.controls['npa']
            .setValidators(Validators.maxLength(8));
        this.formGroup?.controls['lieu']
            .setValidators(Validators.required);
        this.formGroup?.controls['npa'].updateValueAndValidity();
        this.formGroup?.controls['lieu'].updateValueAndValidity();
    }

    private subscribeEnfClose(): void {
        this._events.forType<any>('enf_closed')
            .pipe(takeUntil(this.subscriptions))
            .subscribe(() => {
                this._saenfService.getEnfsByEmpId(this.getFormValue('empId'))
                    .subscribe((list: Saenf[]) => {
                        this.setFormValue('enfnb', list.length);
                        this.setFormValue('enfnbcharg', list.filter((elem: Saenf) => elem.acharge).length);
                        this.setFormValue('enfnballoc', list.filter((elem: Saenf) => elem.allocation).length);
                    });
            });
    }

    private subscribeConjoint(): void {
        this._events.forType<any>('conjoint_closed')
            .pipe(takeUntil(this.subscriptions))
            .subscribe((e) => {
                const payload: Saconj | string = e.payload;
                if (typeof payload === 'string') {
                    this.cleanEmpWithConj();
                } else if (typeof payload === 'object' && payload.empId === this.empId) {
                    this.fillEmpWithConj(payload);
                }
            });
    }

    private subscribeEmpId(): void {
        this.subscribeValueChange<string>('empId', e => {
            if (this.getFormValue('empId') !== undefined) {
                this.disabledTabList = [];
            }
            const groupsId = showGridDetailsAccessRoles('ppemp');
            if (!this._profileService.checkUserPermission(groupsId)) {
                this.disabledTabList.push('suivi');
            }
            this.setDateDossierDefault();
            // Disabled for story https://samredesign.atlassian.net/browse/SAM-6870
            // This._ppempService.checkIbanValidators(
            //   This.formGroup, this.getFormValue('paytype'),
            //   This.getFormValue('dtatype'));
        });
    }

    private fillEmpWithConj(conj: Saconj): void {
        this.setFormValue('conjNom', conj.nom);
        this.setFormValue('conjprenom', conj.prenom);
        this.setFormValue('conjNaiss', moment(conj.datenaiss)
            .format('YYYY-MM-DD'));
        this.setFormValue('conjnoas13', conj.noavs13);
        this.setFormValue('conjWkch', conj.ltravail && conj.paystrav === 'CH');
        this.setFormValue('conjWork', conj.ltravail);
    }

    private cleanEmpWithConj() {
        this.setFormValue('conjNom', undefined);
        this.setFormValue('conjprenom', undefined);
        this.setFormValue('conjNaiss', undefined);
        this.setFormValue('conjnoas13', undefined);
        this.setFormValue('conjWkch', undefined);
        this.setFormValue('conjWork', undefined);
    }

    private setDateDossierDefault() {
        const dateDossier = this.getFormValue('datedossie');
        if (!dateDossier) {
            const defDateD = this.getFormValue('dateCreat');
            this.setFormValue('datedossie', defDateD);
        }
    }

    private subscribeToSaveEffect() {
        this._actions.pipe(takeUntil(this.subscriptions), ofType(globalActions.GLOBAL_FORM_ENTITY_SAVE_SUCCESS))
            .subscribe((e: globalActions.SaveEntitySuccess<Ppemp>) => {
                const uuid = e.uuid || '';

                if (uuid !== this.uuid) {
                    return;
                }

                const keywords = this._pkwService.store[uuid] || [];
                this.setFormValue('cempstatus', e.entity.cempstatus);
                this._pkwService.saveKeywords(this.empId || '', keywords, 'ppemp')
                    .subscribe();
            });
    }


    private subscribeProfession() {
        this.getFormControl('profession')
            ?.valueChanges
            .pipe(debounceTime(200), takeUntil(this.subscriptions))
            .subscribe((quaIds: string | undefined) => {
                if (quaIds) {
                    this.setProfessionName(quaIds);
                } else {
                    this._employeeProfession = '';
                }
            });
    }

    private async setProfessionName(quaIds: string) {
        if (!quaIds) {
            return;
        }

        try {
            this._employeeProfession = await lastValueFrom(this._ppempService.getProfessionName(quaIds)) ?? '';
        } catch {
        }
    }
}
