import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AdminLayoutEventsHandlers} from '@app/layout/events/admin-events.handler';
import {SalaryLayoutEventsHandlers} from '@app/layout/events/salary-layout-events.handler';
import {IwEventRegisterService} from '@app/sam-base/core/events';
import {SlidePanelComponent} from '@app/sam-base/layout/components/slide-panel/slide-panel.component';
import {SamLayoutCoreModule} from '@app/sam-base/layout/sam-layout-core.module';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '@shared/shared.module';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {SidebarModule} from 'primeng/sidebar';
import {TooltipModule} from 'primeng/tooltip';

import {LayoutEventsHandlers} from './events/events.handler';
import {LayoutComponent} from './layout.component';

@NgModule({
    imports: [CommonModule,
        SharedModule,
        SidebarModule,
        PanelMenuModule,
        TranslateModule,
        SharedModule,
        TooltipModule,
        SamLayoutCoreModule,
        MenubarModule],
    declarations: [LayoutComponent],
    providers: [LayoutEventsHandlers,
        SalaryLayoutEventsHandlers,
        AdminLayoutEventsHandlers],
    exports: [LayoutComponent,
        SlidePanelComponent]
})
export class LayoutModule {
    constructor(eventRegister: IwEventRegisterService) {
        eventRegister.registerEventHandlerProvider(LayoutEventsHandlers);
        eventRegister.registerEventHandlerProvider(SalaryLayoutEventsHandlers);
        eventRegister.registerEventHandlerProvider(AdminLayoutEventsHandlers);
    }
}
