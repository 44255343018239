<form [formGroup]="formGroup" class="row space-form">

    <iw-checkbox #chkLactifOnly [label]="'clientsActifsUniquement' | translate" class="iw-input col-xs-12"
                 formControlName="lactifonly"></iw-checkbox>

    <div class="col-xs-12 space-block-6"></div>

    <iw-button #btnCancel (press)="closeDialog()" [text]="'annuler' | translate"
               class="iw-button btn-block col-xs-3"></iw-button>

    <iw-button #btnCancel [text]="'aucunfiltre' | translate"
               class="iw-button btn-block col-xs-offset-2 col-xs-3"></iw-button>

    <iw-button #btnCancel [text]="'appliquer' | translate"
               class="iw-button btn-block col-xs-offset-1 col-xs-3"></iw-button>

</form>
