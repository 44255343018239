import {Component, Input, Type, ViewChild} from '@angular/core';
import {IwRestGridComponent} from '@app/sam-base/components/iw-rest-grid/iw-rest-grid.component';
import {FormHandlerService} from '@app/sam-base/core';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {EntityDelete, IwStoreService} from '@app/sam-base/core/store';
import {Ppcha} from '@app/sam-base/models/placement';

import {PlacementForm} from '../../../placement.forms';

@Component({
    selector: 'iw-tab-chantier', templateUrl: './tab-chantier.component.html'
})
export class TabChantierComponent {

    @Input() public isReadonly = true;

    @Input()
    public set entityId(v: string) {
        this.setEntityId(v);
    }

    @ViewChild('olvClicha', {static: true}) public table?: IwRestGridComponent<Ppcha>;

    public clichaId?: string;

    public query: RestQueryParam<Ppcha, string>[] = [{
        prop: 'cliId', operation: RestQueryOperation.Equals, value: '_'
    }];

    public type: Type<Ppcha> = Ppcha;

    private _entityId = '';

    constructor(private _store: IwStoreService, private readonly _formHandler: FormHandlerService<PlacementForm>) {
    }

    public setEntityId(entityId: string) {
        if (!entityId) {
            return;
        }
        this._entityId = entityId;
        this.query = [{
            prop: 'cliId', operation: RestQueryOperation.Equals, value: entityId
        }];
    }

    public getSelectedRow(event: Ppcha[]) {
        this.clichaId = (event[0] && event[0].clichaId) || '';
    }

    public deleteChantier() {
        if (this.clichaId) {
            this._store.dispatch(new EntityDelete(Ppcha, this.clichaId));
        }
    }

    public openChantiers() {
        this._formHandler
            .showFormDialog(PlacementForm.Ppclicha, {entityId: this._entityId});
    }

    public refreshTable() {
        if (this.table) {
            this.table.refresh();
        }
    }
}
