import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {BaseFormComponent} from '@sam-base/base';
import {IwRestGridComponent} from '@sam-base/components';
import {FormHandlerService, IwStoreService, PpconService} from '@sam-base/core';
import {SamUserService} from '@sam-base/core/services/sam-user.service';
import {TeamService} from '@sam-base/core/services/team.service';
import {ComboboxItem, Dictionary} from '@sam-base/models';
import {SamUser, SamUserRole} from '@sam-base/models/admin/sam-user';
import {SamUserData} from '@sam-base/models/admin/sam-user-data.model';
import {Team} from '@sam-base/models/admin/team.model';
import {IwGridColumn} from '@sam-base/models/components/grid-column.model';
import {RadioItem} from '@sam-base/models/components/radio-item.model';
import {FormKeys} from '@sam-base/models/form-keys.model';
import {Ppcon} from '@sam-base/models/placement/ppcon';
import {ListItem} from '@shared/widgets/form-components/list-box/list-item.model';
import lodash from 'lodash';
import {of, Subject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {catchError} from 'rxjs/operators';

import {PlacementForm} from '../../placement.forms';

@Component({
    templateUrl: './user-roles-config.component.html'
})
export class UserRolesConfigComponent extends BaseFormComponent<SamUserData> implements OnDestroy, OnInit {

    public isReadonly = true;
    public isLoading = false;
    public selectedRow: SamUser = {};

    public allAdvisors: Ppcon[] = [];
    public availableAdvisors: ListItem<Ppcon>[] = [];
    public actualAdvisors: ListItem<Ppcon>[] = [];
    public columns = this.getColumns();
    public delegateAssociatedGridData: SamUser[] = [];
    public delegateAssociatedGridColumns: IwGridColumn<SamUser>[] = [
        {
            prop: 'username',
            name: 'Délégué autorisé',
            index: 0
        }];
    public availableMainAdvisorsToLink: ComboboxItem<string>[] = []
    public userTypeOptions: RadioItem<string>[] = this.initializeRadioOptions();
    // ROLES
    public allRoles = Object.values(SamUserRole) as SamUserRole[];
    public availableRoles: ListItem<SamUserRole>[] = [];
    public actualRoles: ListItem<SamUserRole>[] = [];
    // TEAMS
    public allTeams: Team[] = [];
    public availableTeams: ListItem<number>[] = [];
    public actualTeams: ListItem<number>[] = [];

    @ViewChild('usersGrid', {static: true}) public usersGrid?: IwRestGridComponent<SamUser>;
    private _subs = new Subject();
    private currentUserData: SamUserData = {};
    private teamsById: Dictionary<Team[]> = {};

    constructor(private _store: IwStoreService, private _http: HttpClient, private _ppconService: PpconService,
                private readonly _formHandler: FormHandlerService<PlacementForm>,
                private readonly _samUserService: SamUserService,
                private readonly _teamService: TeamService,
                private readonly _translateService: TranslateService) {
        super();

        this.removeUnusedUsers();
    }

    private _usersList: SamUser[] = [];

    public get usersList(): SamUser[] {
        return this._usersList;
    }

    public ngOnInit(): void {
        this.getAllSamUsers();
        this.getAllTeams();
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();

    }

    public removeUnusedUsers() {
        this.isLoading = true;
        this._ppconService.getRemoveUnlicensedUsers()
            .subscribe(() => {
                this.isLoading = false;
            });
    }

    /**
     * Function to get selected row
     *
     * @param event SamUser[]
     * @returns void
     */
    public onSelectedRow(event: SamUser[]): void {
        this.clearUserConfigForm();

        this.getAllAdvisors();
        if (!event.length) {
            return;
        }

        this.selectedRow = event[0];
        this.isReadonly = true;
        this.fillUserRolesConfigForm(this.selectedRow);
    }

    public onTeamListChange(teams: ListItem<number>[]) {
        this.setFormValue('teamIds', teams.map(e => e.value));
    }

    /**
     * Function to save changes on existing or new entry
     * send the request in form-data
     *
     * @returns void
     */
    public saveEntry(): void {

        this.isLoading = true;

        const formData: SamUserData | undefined = this.getFormData();
        console.log('formData', formData);
        if (!formData) {
            return;
        }
        this._samUserService.updateSamUserData(formData).subscribe((samUserData) => {
            this.refreshGrid();
            this.fillUserRolesConfigForm(samUserData);
            this.isReadonly = true;
            this.isLoading = false;
        });
    }

    /**
     * Set fields editable
     *
     * @returns void
     */
    public modifyEntry(): void {
        if (this.selectedRow?.id) {
            this.isReadonly = false;
        }
    }

    /**
     * Cancel the edition mode
     *
     * @returns void
     */
    public cancelEditionMode(): void {
        this.isReadonly = true;
        this.clearUserForm();
        this.fillUserRolesConfigForm(this.selectedRow);

    }

    public closeDialog(): void {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public onUsersSort() {
        this._usersList = [...this._usersList].reverse();
    }

    public setSecondaryAdvisors(listitems: ListItem<Ppcon>[]) {
        const secAdvisors: Ppcon[] = listitems.map(e => e.value);
        this.setFormValue('secundaryConseillers', secAdvisors.map(e => e.conId) ?? []);
    }

    public openConseillersForm() {
        this._formHandler.showFormDialog(PlacementForm.Conseillers, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    public isUserLinkedToConseillers() {
        return !!this.getFormValue('conseiller') || !!this.getFormValue('secundaryConseillers')?.length;
    }

    public isValid(): boolean {
        return this.formGroup.valid;
    }

    /**
     * Make sure FormControlNames correspond to camelCase
     * version of the PlacementRoles enum key
     */
    protected getFormControlNames(): FormKeys<SamUserData> {
        return [
            'user',
            'roles',
            [
                'secundaryConseillers',
                new UntypedFormControl([])],
            'conseiller',
            'teamIds'];
    }

    private getAllSamUsers() {
        // UserGrid load
        this.isLoading = true;
        this._samUserService.getAllSamUsers()
            .pipe(catchError(() => of([])))
            .subscribe(res => {
                this._usersList = res.sort((a, b) => ((a.username || '') > (b.username || '')) ? 1 : -1);
                this.isLoading = false;
            });
    }

    private getAllTeams() {
        this.isLoading = true;
        this._teamService.getAllTeams()
            .pipe(catchError(() => of([])))
            .subscribe(res => {
                this.allTeams = res.sort((a, b) => ((a.name || '') > (b.name || '')) ? 1 : -1);
                // convert this.allTeams to a map teamId -> Team object
                this.teamsById = lodash.groupBy(this.allTeams, 'teamId');
                this.isLoading = false;
            });
    }

    private fillUserRolesConfigForm(user: SamUser) {
        if (!user?.id) {
            return;
        }
        this.getUserData(user.id)
            .subscribe((data: SamUserData) => {
                catchError(() => of({undefined}));
                if (!data) {
                    return;
                }
                this.currentUserData = data;
                // this.mapValuesToFormGroup(data.roles);
                this.setFormValue('conseiller', data.conseiller);
                this.getDelegatedUsers(data.conseiller || '');
                this.setFormValue('teamIds', data.teamIds);
                this.setFormValue('user', data.user);
                this.setFormValue('secundaryConseillers', data.secundaryConseillers ?? []);
                this.updateAvailableMainAdvisorsToLink();

                // copy samUser.groups to this.actualRoles
                this.actualRoles = data.roles?.map(role => ({
                    label: this._translateService.instant('sam_user_role.' + role),
                    value: role
                })) || [];
                // init availableRoles with SamRoles enum but not those that are present in actualRoles
                this.availableRoles = this.allRoles.filter(role => !this.actualRoles.some(usrRole => usrRole.value === role)).map(val => ({
                    label: this._translateService.instant('sam_user_role.' + val),
                    value: val
                }));

                this.actualTeams = data.teamIds?.map(teamId => ({
                    label: this.teamsById[teamId][0]?.name || '',
                    value: teamId || 0
                })) || [];
                this.availableTeams = this.allTeams.filter(team => !this.actualTeams.some(userTeam => userTeam.value === team.teamId)).map(val => ({
                    label: val.name || '',
                    value: val.teamId || 0
                }));

                this.actualAdvisors = data.secundaryConseillers!.map(ppconId => {
                    const ppcon = this.allAdvisors.find(ppcon => ppcon.conId === ppconId) || {};
                    return {
                        label: `${ppcon?.nom} ${ppcon?.prenom}`,
                        value: ppcon
                    }
                });
                this.availableAdvisors = this.allAdvisors.filter(ppcon => !this.actualAdvisors.some(userPpcon => userPpcon.value === ppcon))
                    .filter(ppcon => ppcon.conId !== data.conseiller).map(ppcon => {
                        return {
                            label: `${ppcon?.nom} ${ppcon?.prenom}`,
                            value: ppcon
                        }
                    });

            });

    }

    private updateAvailableMainAdvisorsToLink() {
        const currentConId = this.getFormValue('conseiller') || '';
        this.availableMainAdvisorsToLink = this.allAdvisors.filter(ppcon => !ppcon.samuserId || ppcon.conId === currentConId).map(ppcon => {
            return {
                name: `${ppcon.nom} ${ppcon.prenom}`,
                value: ppcon.conId || ''
            }
        });
    }

    private getDelegatedUsers(conId: string): void {
        if (!conId) {
            this.delegateAssociatedGridData = [];
            return;
        }

        this._http.get<SamUser[]>(environment.backendURL + 'sam-user/delegated/' + conId)
            .subscribe((users: SamUser[]) => {
                this.delegateAssociatedGridData = users;
            });
    }


    private getUserData(id: string): Observable<SamUserData> {
        return this._http.get<SamUserData>(environment.backendURL + `sam-user/${id}/data`);

    }

    private clearUserConfigForm() {
        this.selectedRow = {};
        this.clearUserForm();
        this.userTypeOptions = this.initializeRadioOptions();
    }

    private clearUserForm() {
        this.availableAdvisors = [];
        this.actualAdvisors = [];

        this.delegateAssociatedGridData = [];

        this.formGroup.reset();
    }

    private initializeRadioOptions() {

        return [
            {
                label: 'user_roles_readonly_controller',
                value: 'ro_user',
                checked: false
            },
            {
                label: 'user_roles_user_active',
                value: 'active_user',
                checked: false
            }];
    }

    private refreshGrid(): void {
        if (!this.usersGrid) {
            return;
        }
        this.usersGrid.refresh();
    }

    private getColumns(): IwGridColumn<SamUser>[] {
        return [
            {
                prop: 'username',
                name: 'sam_user',
                index: 0,
            },
            {
                prop: 'firstName',
                name: 'prenom',
                index: 1,
            },
            {
                prop: 'lastName',
                name: 'name',
                index: 2,
            }
        ];
    }

    private getAllAdvisors() {
        this._ppconService.getFullConseillerList().subscribe({
            next: (advisors) => {
                this.allAdvisors = advisors;
            }
        })
    }
}
