import { HttpClient } from '@angular/common/http';

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ClipboardService} from '@app/sam-base/core/clipboard/clipboard.service';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ToastService} from '@app/sam-base/core/toast';
import {PlacementSearchNavigation} from '@core/placement-search/placement-search.service';
import {SxNotification} from '@sam-base/models/admin/sasx5dec.model';

import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxProcessFlow} from '@sam-base/models/admin/sx-process-flow.enum';
import {SxResponseState} from '@sam-base/models/admin/sx-response-state.enum';
import {Subject} from 'rxjs';

/**
 * Tab General of Swissdec Declaration
 */
@Component({
    selector: 'iw-tab-sasx5institution-general',
    templateUrl: './tab-sasx5institution-general.component.html',
    styleUrls: ['./tab-sasx5institution-general.component.scss']
})
export class TabSasx5institutionGeneralComponent extends BasePartialFormComponent<Sasx5institution> implements OnInit, OnDestroy {


    @Input() public isNew = false;
    public sxNotifications: SxNotification[] = [];
    private subscriptions = new Subject();

    constructor(private readonly _clipboardService: ClipboardService, private readonly _restService: RestApiService,
                private readonly _toast: ToastService, private readonly _http: HttpClient,
                private readonly _gridNavigation: PlacementSearchNavigation,
                private readonly _sanitizer: DomSanitizer) {
        super();
    }

    public get formattedMessage() {
        const html: string = this.getFormValue('formattedMessage') || '';
        return this._sanitizer.bypassSecurityTrustHtml(html);
    }

    public get xsltFromatted(): boolean {
        return false;
    }

    public get entityId() {
        const id = this.getFormValue('id');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public get notifications(): SxNotification[] | undefined {
        return this.getFormValue('notifications');
    }

    public get domain(): string {
        return this.getFormValue('domain') || '';
    }

    public get isCompletionReleaseIsMissing(): boolean {
        return this.getFormValue('state') === SxResponseState.COMPLETION_RELEASE_IS_MISSING;
    }

    public get assNocai(): string {
        return this.getFormValue('assNocai') || '';
    }

    public get assNocli(): string {
        return this.getFormValue('assNocli') || '';
    }

    public get subtitle(): string {
        return `${this.domain} - ${this.assNocai} - ${this.assNocli}`;
    }

    public get xmlReceived(): string | undefined {
        return this.getFormValue('xmlReceived');
    }

    public get institutionState(): string {
        return this.getFormValue('state') || '';
    }

    public get isProcessFlowUrl(): boolean {
        return this.getFormValue('processFlow') === SxProcessFlow.COMPLETION_AND_RESULT;
    }

    public get isProcessFlowDialog(): boolean {
        return this.getFormValue('processFlow') === SxProcessFlow.DIALOG_AND_RESULT;
    }

    public get isProcessFlowResult(): boolean {
        return this.getFormValue('processFlow') === SxProcessFlow.RESULT;
    }

    public get userMessage(): string {
        return this.getFormValue('userMessage') || '';
    }

    public get completionUrl(): string {
        return this.getFormValue('completionUrl') || '';
    }

    public get login(): string {
        return this.getFormValue('login') || '';
    }

    public get password(): string {
        return this.getFormValue('password') || '';
    }

    public ngOnInit() {
        this.subscribeValueChange('userMessage', (notifications) => this.parseNotifications(notifications as string));
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    private parseNotifications(notificationsString: string) {
        if (!notificationsString) {
            return;
        }
        const notifMessagesArray = notificationsString.split(';');
        this.sxNotifications = notifMessagesArray
            .filter(msg => msg !== '')
            .map((errorMessage) => {
                const [type, level, codeString, description] = errorMessage.split('::');
                const code = +codeString;

                return {
                    type,
                    code,
                    level,
                    description
                };
            });
    }


}
