import * as SideMenuRoles from '@sam-base/core/auth/access-rules/side-menu';

export const documentation = {
    label: 'documentation',
    items: [
        {
            label: 'documentationPlacement',
            command: () => {
                // window.open(location.protocol + '//' + location.hostname + '/user-doc', '_blank');
                window.open('https://storage.googleapis.com/downloadpublic/SAM%20ULTIMATE%20module%20placement.pdf', '_blank');
            },
            accessRoles: SideMenuRoles.placementMenuRoles()
        },{
            label: 'documentationSalaires',
            command: () => {
                // window.open(location.protocol + '//' + location.hostname + '/user-doc', '_blank');
                window.open('https://storage.googleapis.com/downloadpublic/SAM%20ULTIMATE%20module%20Salaires.pdf', '_blank');
            },
            accessRoles: SideMenuRoles.salaireHeaderMenuRoles()
        },{
            label: 'documentationFactures',
            command: () => {
                // window.open(location.protocol + '//' + location.hostname + '/user-doc', '_blank');
                window.open('https://storage.googleapis.com/downloadpublic/SAM%20ULTIMATE%20module%20Facturation.pdf', '_blank');
            },
            accessRoles: SideMenuRoles.factureHeaderMenuRoles()
        }
    ]
};

export const support = {
    label: 'support_a_distance',
    items: [
        {
            label: 'interiware_support',
            command: () => {
                window.open('https://drive.google.com/file/d/13Rhk7DH5MV116ELEANy3epsKAAYb9ouv/view', '_blank');
            }
        },
    ],
};




