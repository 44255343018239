<form [formGroup]="formGroup" class="row">
    <iw-textfield #txtNom [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'nom' | translate"
                  class="iw-input col-xs-8 col-no-padding" formControlName="fidname"></iw-textfield>
    <iw-textfield #txtComplement [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'complement' | translate"
                  class="iw-input col-xs-8 col-no-padding" formControlName="fidname2"></iw-textfield>

    <iw-textfield #txtAdresse1 [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'rue' | translate"
                  class="iw-input col-xs-8 col-no-padding" formControlName="fidadr1"></iw-textfield>

    <iw-textfield #txtAdresse2 [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'boite' | translate"
                  class="iw-input col-xs-8 col-no-padding" formControlName="fidadr2"></iw-textfield>

    <iw-npa-lieu [formGroup]="formGroup" [isDisabled]="isReadonly || emptyFidName" [labelNpaAlign]="'top'"
                 [lieuFormControlName]="'fidlieu'" [npaFormControlName]="'fidnpa'" [pays]="fidpays"
                 class="iw-input col-xs-8 col-no-padding"></iw-npa-lieu>

    <iw-pays-dropdown #cboPays [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'pays' | translate"
                      class="iw-input col-xs-8 col-no-padding" formControlName="fidpays"></iw-pays-dropdown>

    <iw-textfield #noofs [isDisabled]="isReadonly || emptyFidName" [labelAlign]="'top'" [label]="'noofs' | translate"
                  class="iw-input col-xs-8 col-no-padding" formControlName="fidofsnr" maxlength="20"></iw-textfield>
</form>
