import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Saeio} from '@app/sam-base/models/salary/saeio';
import {SaeioComponent} from '../components/saeio/saeio.component';


export const SAEIO_DEFAULT_FORM: FormDescription<Saeio> = {
    form: SaeioComponent, state: {
        ...buildDefaultState(Saeio), diagTitle: 'entrees_sorties', diagModal: false, width: 650, isNew: true
    }
};

