import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {AgencyHandlerService} from '@app/sam-base/core/auth/services/agency-handler.service';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {RestEntityClient} from '@app/sam-base/core/rest-api';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {Ppcha, Ppcliadr, Ppmis} from '@app/sam-base/models/placement';
import {PpchaData} from '@shared/widgets/modal-components/ppcha/ppcha.options';
import {lastValueFrom, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Modefactu} from './tab-ppmis-autre-enums';

@Component({
    templateUrl: './tab-ppmis-autre.component.html',
    selector: 'iw-tab-ppmis-autre'
})
export class TabPpmisAutreComponent extends BasePartialFormComponent<Ppmis> implements OnInit {

    public gestion = '';

    public modefactu = Modefactu;

    public fullAddress = '';

    @Input() public isReadonly = true;

    @Input() public sent2fac = false;

    @Input() public sent2sal = false;

    @Input() public hasRapport = false;
    public chantierMultiSelectInputs?: PpchaData;
    private readonly _entityService: RestEntityClient<Ppcliadr>;

    constructor(private readonly _fornHandler: FormHandlerService<string>, _restService: RestApiService,
                private _agencyHandlerService: AgencyHandlerService) {
        super();
        this._entityService = _restService.getEntityClient(Ppcliadr);
    }

    public get hasSalFac(): boolean {
        return this.sent2fac || this.sent2sal;
    }

    public get entityId(): string | undefined {
        return this.getFormValue<string>('misId');
    }

    public get cliId(): string {
        return this.getFormValue('cliId') || '';
    }

    public async ngOnInit() {
        this.gestion = await lastValueFrom(this._agencyHandlerService.loadAgencyId());
        this.subscribeValueChange('freezcha', () => this.changeNonModifiable(), 200);
        this.subscribeValueChange('manycha', () => this.changePlusieurs(), 200);
        this.chantierMultiSelectInputs = {
            cliId: this.cliId
        };
        this.subscribeValueChange('cliId', (cliId?: string) => {
            this.fullAddress = '';
            this.chantierMultiSelectInputs = {
                cliId: cliId ?? ''
            };
        });
    }


    /** Filter to use when showing list of ppcliatt */
    public resultFilters: (e: Ppcliadr) => boolean = (e) => e.cliId === this.cliId;

    public setClientAddress() {
        const cliadrId = this.getFormValue<string>('cliadrId');
        if (!cliadrId) {
            return '';
        }

        this._entityService.getById(cliadrId)
            .pipe(catchError(() => of(undefined)))
            .subscribe((res?: Ppcliadr) => {
                if (!res) {
                    this.fullAddress = '';
                    return;
                }
                this.fullAddress = this.buildAddress(res);
            });
    }

    public isPlusieursChecked(): boolean {
        return !!this.getFormValue('manycha');
    }

    public changeNonModifiable() {
        if (!!this.getFormValue('freezcha') && !this.isReadonly) {
            this.setFormValue('manycha', false);
        }
    }

    public changePlusieurs() {
        if (!!this.getFormValue('manycha') && !this.isReadonly) {
            this.setFormValue('freezcha', false);
        }
    }

    public setChantiersValues(chantiers?: Ppcha[]) {
        if (this.isReadonly) {
            return;
        }
        const chantiersReduced = chantiers?.map(chantier => chantier.chantier ?? '');
        this.setFormValue('chantiers', chantiersReduced ? chantiersReduced.toString() : undefined);
        this.setFormValue('manycha', !!(chantiers && chantiers.length));

    }

    // eslint-disable-next-line complexity
    private buildAddress(p: Ppcliadr) {
        let fullAdresse = '';

        if (p.prefixe) {
            fullAdresse += `${p.prefixe}\n`;
        }
        if (p.nom) {
            fullAdresse += `${p.nom}\n`;
        }
        if (p.adresse1) {
            fullAdresse += `${p.adresse1}\n`;
        }
        if (p.adresse2) {
            fullAdresse += `${p.adresse2}\n`;
        }
        if (p.adresse3) {
            fullAdresse += `${p.adresse3}\n`;
        }
        if (p.npa || p.lieu) {
            fullAdresse += `${p.npa} ${p.lieu}\n`;
        }
        return fullAdresse;
    }
}
