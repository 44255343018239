<form [formGroup]="formGroup" class="row col-xs-12 no-padding">

    <div *ngIf="missionSelected" class="row col-xs-10 col-no-padding margin-bottom-0">
        <iw-textfield #txtLabelRap [isDisabled]="true" [value]="labelRap"
                      class="iw-input col-xs-7 col-no-padding"></iw-textfield>

        <iw-textfield #txtRap_id [isDisabled]="true" class="iw-input col-xs-2 col-no-padding"
                      formControlName="rapId"></iw-textfield>

        <iw-textfield #txtRapModCreat [isDisabled]="true" class="iw-input col-xs-1 col-no-padding"
                      formControlName="modecreat"></iw-textfield>

        <iw-textfield #txtIjrap_id [isDisabled]="true" class="iw-input col-xs-2 col-no-padding"
                      formControlName="ijrapId"></iw-textfield>

        <iw-textfield #txtDetailMission [isDisabled]="true" [value]="missionInfo"
                      class="iw-input col-xs-10 col-no-padding"></iw-textfield>

        <iw-textfield #txtMisIJInfo [isDisabled]="true" [ngClass]="backClass" [value]="misIjInfo"
                      class="iw-input col-xs-2 col-no-padding"></iw-textfield>

        <iw-rest-grid #gridReports (rowDoubleClick)="openRapportForm($event)" (selected)="setSelectedRow($event)"
                      [autoFormClick]="false" [customColumns]="customColumns" [defaultSelected]="true"
                      [hasGridColumnMenu]="false" [hasSortIcon]="true" [queryStatements]="query"
                      [refreshType]="refreshType" [type]="type" class="iw-grid col-xs-12 no-padding"
                      style="height: 220px"></iw-rest-grid>
    </div>

    <div *ngIf="missionSelected" class="col-xs-2 no-padding-right">
        <iw-checkbox #chkLIJAlert [label]="'alertesIj' | translate" class="iw-input"
                     formControlName="ijAlert"></iw-checkbox>

        <div class="margin-top-5"></div>
        <iw-button #btnNewRapport (press)="newRapport()" [isDisabled]="!btnNewRapportEnable"
                   [text]="'nouveau' | translate" [tooltip]="'nouveauRap' | translate"
                   class="iw-button margin-top-30"></iw-button>

        <div class="margin-top-5"></div>
        <iw-button #btnEditRapport (press)="editRapport()"
                   [isDisabled]="!btnEditRapportEnable || selectedRapportIsReadOnly" [text]="'modif' | translate"
                   [tooltip]="'modifierRap' | translate" class="iw-button margin-top-10"></iw-button>

        <div class="margin-top-5"></div>
        <iw-button #btnDelRapport (press)="deleteRapport()"
                   [isDisabled]="!btnDelRapportEnable || selectedRapportIsReadOnly" [text]="'supprimer' | translate"
                   [tooltip]="'supprimerRap' | translate" class="iw-button margin-top-10"></iw-button>
        <iw-textfield #txtLockedByUser *ngIf="selectedRapportIsReadOnly" [isDisabled]="true" [value]="lockedStatus"
                      class="iw-input col-xs-12 col-no-padding margin-top-20"></iw-textfield>
    </div>

    <div class="row col-xs-12 col-no-padding margin-top-20">
        <iw-textfield #txtSemStatus [isDisabled]="true" [ngClass]="backSemStatusColor" [value]="txtStatus"
                      class="iw-input col-xs-3 no-padding-left" textAlign="center"></iw-textfield>

        <iw-button #btnSemStatus (press)="clickActivite()" [isDisabled]="!btnSemStatusEnable"
                   [text]="'activiteSemaine' | translate" class="iw-button col-xs-2"></iw-button>

        <iw-button #btnClose (press)="clickClose()" [text]="'fermer' | translate"
                   class="iw-button col-xs-2 col-xs-offset-5 no-padding-right"></iw-button>
    </div>

</form>
