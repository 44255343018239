import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppcct} from '@app/sam-base/models/placement';

import {FcDedmod} from './tab-cct-formation-enums';

@Component({
    selector: 'iw-tab-cct-formation', templateUrl: './tab-cct-formation.component.html'
})
export class TabCctFormationComponent extends BasePartialFormComponent<Ppcct> {

    public fcDedmodOptions = FcDedmod;

    constructor() {
        super();
    }

}
