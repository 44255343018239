import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Pppar} from '@app/sam-base/models/placement';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const ppopttxtTabsFormControls: TabFormControl<Pppar>[] = [{formControls: ['dateModif', 'userId']}, {
    title: '1', formControls: ['usertext1']
}, {title: '2', formControls: ['usertext2']}, {title: '3', formControls: ['usertext3']}, {
    title: '4', formControls: ['usertext4']
}, {title: '5', formControls: ['usertext5']}, {title: '6', formControls: ['usertext6']}, {
    title: '7', formControls: ['usertext7']
}, {title: '8', formControls: ['usertext8']}, {title: '9', formControls: ['usertext9']}];
