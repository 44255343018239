import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Autoaco} from '@sam-base/models/placement/autoaco';
import {AutoacoComponent} from '../components/acompte/autoaco/autoaco.component';


export const AUTO_ACOMPTES_FORM: FormDescription<Autoaco> = {
    form: AutoacoComponent, state: {
        ...buildDefaultState(Autoaco),
        diagTitle: 'autoaco_form_title',
        diagModal: false,
        editMode: 'waiting',
        width: 1600
    }
};
