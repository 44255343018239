import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppmismdl} from '@app/sam-base/models/placement';

import {Ppmismdl2Component} from '../components/ppmismdl2/ppmismdl2.component';

export const PPMISMDL_DEFAULT_FORM: FormDescription<Ppmismdl> = {
    form: Ppmismdl2Component, state: {
        ...buildDefaultState(Ppmismdl), diagTitle: 'ppmismdl_form_title', width: 860, editMode: 'waiting'
    }
};
