export enum MissionStatus {
    nouvelle = 1, ouverte = 2, terminee = 3, archivee = 4, modele = 8,
}

export const missionOCAlerts: { [key: number]: string } = {
    1: 'close_mission_alert', 2: 'close_mission_alert', 3: 'open_mission_ouverte_alert', 4: 'open_mission_termine_alert'
};

export interface MissionSalaryFacturation {
    hasSalary?: boolean;
    hasFacturation?: boolean;
    hasRapport?: boolean;
}
