import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppsuivi} from '@app/sam-base/models/placement';

import {SuiviComponent} from '../components/suivi/suivi.component';

export const PPSUIVI_EDIT: FormDescription<Ppsuivi> = {
    form: SuiviComponent, state: {
        ...buildDefaultState(Ppsuivi), diagModal: true, width: 450, destroyOnSave: true
    }
};

export const PPSUIVI_NEW: FormDescription<Ppsuivi> = {
    form: SuiviComponent, state: {
        ...buildDefaultState(Ppsuivi), diagModal: false, width: 450, isNew: true, destroyOnSave: true
    }
};
