import {Component, EventEmitter, Input, OnDestroy, Output, Type} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {EntityNavigationService, GridProfile} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {pprapviewTabRapportsCustomProfile} from '@app/sam-base/core/grid-profile/models/grid-profile-default';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {getDeepCopy} from '@app/sam-base/helpers/objects-parser';
import {FormKeys, RowClickEvent} from '@app/sam-base/models';
import {Ppmis, Ppmisind, Ppmisrap, Pprapview} from '@app/sam-base/models/placement';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

import {CalculationsService} from '../../../services/calculations.service';

@Component({
    selector: 'iw-tab-ppmis-rapport',
    templateUrl: './tab-ppmis-rapport.component.html'
})
export class TabPpmisRapportComponent extends BaseFormComponent<Ppmis> implements OnDestroy {

    @Input() public isReadonly = true;
    @Output() public dataReloaded = new EventEmitter<boolean>();
    public dataReloadedDebouncer: Subject<boolean> = new Subject<boolean>();
    public typeR: Type<Ppmisrap> = Ppmisrap;
    public typeI: Type<Ppmisind> = Ppmisind;
    public queryR: RestQueryParam<Ppmisrap, string>[] = [];
    public queryI: RestQueryParam<Ppmisind, string>[] = [];
    public tableWidthR = '1200px';
    public tableWidthI = '900px';
    private _subscripts = new Subject();

    constructor(private readonly _calculations: CalculationsService,
                private _entityNavService: EntityNavigationService) {
        super();
        this.dataReloadedDebouncer.pipe(takeUntil(this._subscripts), debounceTime(1000))
            .subscribe((value: boolean) => this.dataReloaded.emit(value));
    }

    private _entityId?: string;

    public get entityId() {
        return this._entityId;
    }

    @Input()
    public set entityId(v: string | undefined) {
        if (v) {
            this.setEntityId(v);
        }
    }

    public ngOnDestroy() {
        this._subscripts.next(undefined);
        this._subscripts.complete();
        super.ngOnDestroy();
    }

    public openRapportForm(event: RowClickEvent<Pprapview>) {
        const gridProfile: GridProfile<Pprapview> = getDeepCopy(new GridProfile(pprapviewTabRapportsCustomProfile(<string>event.row.misId)));

        this._entityNavService.navigateToEntityForm(Pprapview, <string>event.row.rapId, gridProfile);
    }

    protected getFormControlNames(): FormKeys<Ppmis> {
        return [
            'totheu',
            'firstday',
            'totrap',
            'totjou',
            'lastday',
            'totsem',
            'nbjmis'];
    }

    private setEntityId(v?: string) {
        if (v !== this._entityId) {
            this._entityId = v;
            if (v) {
                this.updateFilters(v);
                this.loadRapportStats(v);
            }
        }
    }

    private updateFilters(misId: string) {
        this.queryR = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'misId',
                value: misId
            }];

        this.queryI = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'misId',
                value: misId
            }];
    }

    private loadRapportStats(misId: string) {
        this._calculations.calculate('MissionRapportStats', {misId})
            .subscribe(calc => {
                if (calc.success) {
                    const res = calc.result;
                    const data = {
                        firstday: res.firstday,
                        lastday: res.lastday,
                        totheu: res.totalHours,
                        totrap: res.rapCount,
                        totjou: res.totalDays,
                        totsem: res.numberWeeks,
                        nbjmis: (res.dateIntervalDays ? res.dateIntervalDays : this.daysBetweenDates(res.firstday, res.lastday)) + ' j.'
                    };
                    this.fillFormData(data);
                }
            });
    }

    /**
     * Function to calculate days beetwen two dates
     *
     * @param startDate Initial Date
     * @param endDate  Final Date
     */
    private daysBetweenDates(startDate: string, endDate: string) {
        return IwDateHelper.datesDiff(startDate, endDate, 'days') + 1;
    }
}
