import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Gecliatt} from '@app/sam-base/models/invoices/gecliatt';
import {GecliattComponent} from '../components/client/tab-contacts/gecliatt/gecliatt.component';


export const GECLIATT_NEW: FormDescription<Gecliatt> = {
    form: GecliattComponent, state: {
        ...buildDefaultState(Gecliatt), diagTitle: 'contact', isNew: true, destroyOnSave: true, editMode: 'edit'
    }
};

export const GECLIATT_EDIT: FormDescription<Gecliatt> = {
    form: GecliattComponent, state: {
        ...buildDefaultState(Gecliatt), diagTitle: 'contact', destroyOnSave: true, editMode: 'edit'
    }
};
