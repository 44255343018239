import {Component, OnInit} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core';
import {SaemsService} from '@app/sam-base/core/services/saems.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Saes} from '@app/sam-base/models/salary/saes';

@Component({
    templateUrl: './joursis.component.html'
})
export class JoursIsComponent extends BaseFormComponent<Saes> implements OnInit {
    public imois?: string;
    public iyear?: string;
    public xdatede?: Date;
    public xdatea?: Date;
    public xnbjismois?: string;
    public xtextrecap?: string;

    public readonly = true;

    constructor(private _store: IwStoreService, private readonly saemsService: SaemsService) {
        super();
    }

    public get salId(): string {
        return this.getData('salId') || '';
    }

    public get empId(): string {
        return this.getData('empId') || '';
    }

    public get canton(): string {
        return this.getData('canton') || '';
    }

    public ngOnInit() {
        this.imois = this.salId.slice(-2);
        this.iyear = this.salId.substring(0, 4);
        this.getJoursIS(this.empId, this.salId);
    }

    public getJoursIS(empId: string, annee: string) {
        if (empId && annee) {
            this.saemsService.getJoursIS(empId, annee)
                .subscribe((info: any) => {
                    this.xtextrecap = info.xtextrecap;
                    this.xdatede = info.xdatede;
                    this.xdatea = info.xdatea;
                    this.xnbjismois = info.xnbjismois;
                });
        }
    }

    public modifyEntry(): void {
        this.readonly = false;
    }

    public saveEntry(): void {
    }

    public cancelEditionMode() {
        this.readonly = true;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    protected getFormControlNames(): FormKeys<Saes> {
        return ['empId', 'salId'];
    }

    protected validateFields(e: Saes): boolean {
        return super.validateFields(e);
    }
}
