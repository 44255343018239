<form [formGroup]="formGroup" class="row signature-container">
    <div class="col-xs-12 row">
        <div class="col-xs-6">
            <div class="form-subtitle" style="font-size:13px;"> {{ 'signature' | translate }}
            </div>
            <p-fileUpload #fileUploader (uploadHandler)="uploadDocument($event, fileUploader)"
                          [accept]="'.png, .jpeg, .jpg'" [chooseLabel]="'choose' | translate" [disabled]="isReadonly"
                          [showCancelButton]="false" [uploadLabel]="'upload' | translate" customUpload="true"
                          invalidFileTypeMessageDetail="" invalidFileTypeMessageSummary="Invalid" name="myfile[]"
                          previewWidth="0"></p-fileUpload>
        </div>
        <div class="column col-xs-6 flex flex-center">
            <div class="flex-center fixed-height row">
                <div class="div col-xs-12">
                    <img (error)="removeSignature()" *ngIf="!noSignature" [src]="showSignature()"
                         [style.max-heigth]="'80px'" alt="signature" class="signature-img">
                    <div *ngIf="noSignature" class="no-signature"> {{ 'no_enterprise_signature' | translate }}
                    </div>
                </div>
                <div class="flex-center col-xs-12">
                    <iw-button (press)="removeSignature() " [isDisabled]="isReadonly || !getFormValue('signature')"
                               [text]="'removeSignature' | translate" class="padding-10 btn-block"></iw-button>
                </div>
            </div>
        </div>
    </div>
</form>
