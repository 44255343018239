import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppmis} from '@app/sam-base/models/placement';

import {MissionsComponent} from '../components';
import {MissionsSidemenuComponent} from '../components/missions/missions-sidemenu.component';

export const PPMIS_DEFAULT_FORM: FormDescription<Ppmis> = {
    form: MissionsComponent, sideMenu: MissionsSidemenuComponent, state: {
        ...buildDefaultState(Ppmis),
        diagTitle: 'ppmis_form_title',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showPrint: true, showDelete: true
        },
        useNavigation: true,
        patch: false,
        width: 1100
    }
};
