import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Pays} from '@app/sam-base/models/common';
import {Saems} from '@app/sam-base/models/salary/saems';
import {SalaryForm} from '@modules/sam-main/salary/salary.forms';
import {ModalTextAreaComponent} from '@sam-base/components';
import {FormHandlerService, RestApiService, RestEntityClient, ViewContainerRefService} from '@sam-base/core';
import {IwDateHelper} from '@sam-base/core/dates/iw-date-helper';
import {SalIdHelper} from '@sam-base/core/salId/salId.helper';
import {SaeisaService} from '@sam-base/core/services/saeisa.service';
import {Saemp} from '@sam-base/models/salary';
import {Saeisa} from '@sam-base/models/salary/saeisa';

@Component({
    selector: 'iw-tab-saems-informations',
    templateUrl: './tab-saems-informations.component.html'
})
export class TabSaemsInformationsComponent extends BasePartialFormComponent<Saems> implements OnInit {


    @Input() public saemp?: Saemp;
    public saeisain?: string;
    public saeisaout?: string;
    public saeisanbj?: number;
    private readonly _empRest: RestEntityClient<Saemp>;
    private readonly _paysRest: RestEntityClient<Pays>;

    constructor(private _viewHostRef: ViewContainerRefService, public rest: RestApiService,
                private readonly _formHandler: FormHandlerService<SalaryForm>,
                private readonly _saeisaService: SaeisaService,
                private readonly _restService: RestApiService) {
        super();
        this._empRest = _restService.getEntityClient(Saemp);
        this._paysRest = _restService.getEntityClient(Pays);
    }


    public get empId(): string {
        return this.getFormValue('empId') || '';
    }

    public get params(): string | undefined {
        return this.getFormValue('params');
    }

    public get ppparams(): string | undefined {
        return this.getFormValue('ppparams');
    }

    public get pays(): string | undefined {
        return this.saemp?.pays;
    }

    public get bareme(): string | undefined {
        const codeImp = this.extractValue(this.params, '[IMP:CD]');
        if (codeImp) {
            if (codeImp == '1') {
                return 'Non soumis'
            } else {
                const barc3 = this.extractValue(this.params, '[IMP:BARC3]');
                const txCot = this.extractValue(this.params, '[IMP:TXCOT]');
                const modeCalc = this.extractValue(this.params, '[IMP:CALC]');
                let bareme = barc3 + " / " + txCot + "% / "
                if (modeCalc == '1')
                    bareme = bareme + "S/Taux";
                else if (modeCalc == '2')
                    bareme = bareme + "Auto/M";
                else if (modeCalc == '3')
                    bareme = bareme + "Auto/H";
                else if (modeCalc == '4')
                    bareme = bareme + "Auto/M";
                else if (modeCalc == '5')
                    bareme = bareme + "Auto/M  (j. trav.)";
                else if (modeCalc == '6')
                    bareme = bareme + "Auto/M  (j. IS)";
                else if (modeCalc == '7')
                    bareme = bareme + "Swissdec annuel";
                else if (modeCalc == '8')
                    bareme = bareme + "Swissdec mensuel";
                return bareme;
            }
        } else {
            return undefined;
        }

    }

    public get dateMail(): string | undefined {
        return this.getFormValue('dateMail');
    }

    public get datePrnt(): string | undefined {
        return this.getFormValue('datePrnt');
    }

    public get dateBs(): string | undefined {
        return this.getFormValue('dateBs');
    }

    public get dateCalc(): string | undefined {
        return this.getFormValue('dateCalc');
    }

    public get datePublished(): string | undefined {
        return this.getFormValue('datePublished');
    }

    public get nbjCna(): string | undefined {
        return `${this.extractValue(this.params, '[NBJCNAMOIS]') ?? ''} / ${this.extractValue(this.params, '[NBJCNA]') ?? ''}`;
    }

    public get firstDay(): string | undefined {
        return IwDateHelper.convertDateFormatToISO8601(this.extractValue(this.ppparams, '[FIRSTDAY]'));
    }

    public get lastDay(): string | undefined {
        return IwDateHelper.convertDateFormatToISO8601(this.extractValue(this.ppparams, '[LASTDAY]'));
    }

    public get lastDay2(): string | undefined {
        return IwDateHelper.convertDateFormatToISO8601(this.extractValue(this.ppparams, '[LASTDAY2]'));
    }

    public get nbHours(): string | undefined {
        return this.extractValue(this.ppparams, '[NBH]');
    }

    public get nbDays(): string | undefined {
        return this.extractValue(this.ppparams, '[NBJ]');
    }

    public get salHor(): string | undefined {
        return this.extractValue(this.params, '[SALHOR]');
    }

    public get dateCreate(): Date {
        return IwDateHelper.getDate(this.getFormValue('dateCreat'));
    }

    public get salId(): string {
        return this.getFormValue('salId') || ''
    }


    public ngOnInit() {
        this.fetchSaeisaByEmpId(this.empId, this.salId);
        this.subscribeValueChange('empId', (empId) => {
            this.fetchSaeisaByEmpId(empId as string, this.salId);
        });
        this.subscribeValueChange('salId', (salId) => {
            this.fetchSaeisaByEmpId(this.empId, salId as string);
        });
    }

    public openParams() {
        this._viewHostRef.loadComponent({
            type: ModalTextAreaComponent,
            data: {
                value: this.params,
                onOk: (val: any) => {
                },
                state: {
                    ...this,
                    isReadOnly: true,
                },
                btnCancel: {
                    visible: false
                }
            }
        });
    }

    public openPpParams() {
        this._viewHostRef.loadComponent({
            type: ModalTextAreaComponent,
            data: {
                value: this.ppparams,
                onOk: (val: any) => {
                },
                state: {
                    ...this,
                    isReadOnly: true,
                },
                btnCancel: {
                    visible: false
                }
            }
        });
    }

    public openDecompteGS() {
        this._formHandler.showFormDialog(SalaryForm.DecompteGS, {
            empId: this.empId,
            annee: SalIdHelper.getYearOfSalId(this.salId)
        });
    }

    private fetchSaeisaByEmpId(empId: string, salId: string) {
        this._saeisaService.getSaeisaByYearAndEmpId(SalIdHelper.getYearOfSalId(salId), empId + '').subscribe(saeisa => {
            const saeisaInstance = Object.assign(new Saeisa(), saeisa);
            this.saeisain = saeisaInstance.getDinForMonth(SalIdHelper.getSalIdMonth(salId));
            this.saeisaout = saeisaInstance.getDoutForMonth(SalIdHelper.getSalIdMonth(salId));
            this.saeisanbj = saeisaInstance.getNbjisForMonth(SalIdHelper.getSalIdMonth(salId));
        })
    }

    private extractValue(params: string | undefined, key: string): string | undefined {
        const escapedKey = key.replace(/[[\]\\^$.|?*+()]/g, '\\$&');
        const regex = new RegExp(`${escapedKey}\\s*=\\s*([^;]+)\\s*;`);
        if (params) {
            const match = params.match(regex);
            return match ? match[1] : undefined;
        } else {
            return undefined;
        }
    }
}
