<form *ngIf="formGroupReady"
      [formGroup]="formGroup"
      class="row">
    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()"
                  [decimals]="2"
                  [isDisabled]="isReadonly || isGlobalSalaryMode"
                  [label]="'salaire_base' | translate"
                  class="col-xs-7"
                  formControlName="salBase"
                  labelAlign="left"
                  type="number"></iw-textfield>
    <i *ngIf="isSalBaseInvalid"
       [pTooltip]="salBaseTooltip"
       class="fas fa-exclamation-triangle init-danger"
       style="margin-left: 0px !important; align-items: center; display: flex;"></i>
    <iw-textfield [isDisabled]="true"
                  [value]="'heure' | translate"
                  class="col-xs-2"></iw-textfield>
    <div class="space-block space-block-6"></div>
    <iw-ppshcalcmode-dropdown (entitySelected)="asyncCalc('shcalcmode')"
                              [isDisabled]="true"
                              [noLabel]="true"
                              class="col-xs-12"
                              formControlName="shcalcmode"></iw-ppshcalcmode-dropdown>

    <!-- ATTENTION checkbox component comment out to be included in future version or removed forever -->
    <!-- <iw-checkbox
      class="col-xs-5"
      formControlName="shtxmode"
      [label]="'saisie_montants' | translate"
      (onSelect)="asyncCalc('shtxmode')"
      [isDisabled]="shtxmodeDisabled"
    ></iw-checkbox> -->
    <div class="space-block space-block-6"></div>
    <iw-checkbox (valueSelected)="asyncCalc('ljfh')"
                 [isDisabled]="isReadonly"
                 [label]="'jf' | translate"
                 class="col-xs-1"
                 formControlName="ljfh"></iw-checkbox>
    <div *ngIf="!mntJfz && mntJfz === 0"
         class="col-xs-2"></div>
    <iw-textfield *ngIf="mntJfz && mntJfz !== 0"
                  [isDisabled]="true"
                  class="col-xs-2"
                  formControlName="mntJfz"
                  style="padding-left: 16px; padding-right: 0px;"></iw-textfield>

    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()"
                  [decimals]="2"
                  [isDisabled]="!shtxmode"
                  class="col-xs-3"
                  formControlName="mntJf"
                  type="number"></iw-textfield>
    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()"
                  [decimals]="2"
                  [isDisabled]="isReadonly || !isJf || tempdataActivated"
                  class="col-xs-3"
                  formControlName="txJf"
                  suffix="%"
                  type="number"></iw-textfield>
    <iw-checkbox [isDisabled]="isReadonly"
                 [label]="'prov' | translate"
                 class="col-xs-1"
                 formControlName="lprovJf"></iw-checkbox>

    <iw-checkbox #jfNonPayes
                 (valueSelected)="asyncCalc('jfNonPayes')"
                 *ngIf="hasJfNonPayes"
                 [isDisabled]="isReadonly"
                 [label]="'jf_np' | translate"
                 class="iw-checkbox col-xs-2 flex-end padding-0"
                 formControlName="jfNonPayes"></iw-checkbox>
    <iw-checkbox (valueSelected)="asyncCalc('l13')"
                 [isDisabled]="isReadonly"
                 [label]="'13eme' | translate"
                 class="col-xs-3"
                 formControlName="l13"></iw-checkbox>
    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()"
                  [decimals]="2"
                  [isDisabled]="!shtxmode"
                  class="col-xs-3"
                  formControlName="mnt13"
                  type="number"></iw-textfield>
    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()"
                  [decimals]="2"
                  [isDisabled]="isReadonly || !is13 || tempdataActivated"
                  class="col-xs-3"
                  formControlName="tx13"
                  suffix="%"
                  type="number"></iw-textfield>
    <iw-checkbox [isDisabled]="isReadonly"
                 [label]="'prov' | translate"
                 class="col-xs-2"
                 formControlName="lprov13"></iw-checkbox>
    <iw-checkbox (valueSelected)="asyncCalc('lvac')"
                 [isDisabled]="isReadonly"
                 [label]="'vacances' | translate"
                 class="col-xs-3"
                 formControlName="lvac"></iw-checkbox>
    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()"
                  [decimals]="2"
                  [isDisabled]="!shtxmode"
                  class="col-xs-3"
                  formControlName="mntVac"
                  type="number"></iw-textfield>
    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()"
                  [decimals]="2"
                  [isDisabled]="isReadonly || !isVac || tempdataActivated"
                  class="col-xs-3"
                  formControlName="txVac"
                  suffix="%"
                  type="number"></iw-textfield>
    <iw-checkbox [isDisabled]="isReadonly"
                 [label]="'prov' | translate"
                 class="col-xs-2"
                 formControlName="lprovVac"></iw-checkbox>
    <div class="space-block space-block-6"></div>
    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()"
                  [isDisabled]="isReadonly || isBaseSalaryMode"
                  [label]="'salaire_global' | translate"
                  class="col-xs-6"
                  formControlName="salGlobal"
                  labelAlign="left"
                  type="number"
                  [decimals]="2"></iw-textfield>
    <iw-enum-dropdown (valueSelected)="asyncCalc('shbaseglob')"
                      [inputEnum]="salaryModeEnum"
                      [isDisabled]="isReadonly"
                      class="col-xs-6"
                      formControlName="shbaseglob"
                      label=""></iw-enum-dropdown>
    <div class="space-block space-block-6"></div>
    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()"
                  [decimals]="2"
                  [isDisabled]="isReadonly || !frais1"
                  [label]="'frais_horaire_1' | translate"
                  [size]="'small'"
                  class="col-xs-6"
                  formControlName="mntFrais1"
                  labelAlign="left"
                  type="number"></iw-textfield>
    <div class="col-xs-6"></div>
    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()"
                  [decimals]="2"
                  [isDisabled]="isReadonly || !frais2"
                  [label]="'frais_horaire_2' | translate"
                  [size]="'small'"
                  class="col-xs-6"
                  formControlName="mntFrais2"
                  labelAlign="left"
                  type="number"></iw-textfield>
    <div class="space-block space-block-6"></div>
    <iw-textfield [isDisabled]="true"
                  [label]="'salaire_total' | translate"
                  class="col-xs-6"
                  formControlName="salTotal"
                  labelAlign="left"
                  type="number"
                  [decimals]="2"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  [value]="'heure' | translate"
                  class="col-xs-2"></iw-textfield>
    <div class="separator-block separator-top"></div>
    <iw-checkbox [isDisabled]="isReadonly || !canHeurPause"
                 [label]="'heurPause' | translate"
                 class="col-xs-4 flex-bottom"
                 formControlName="heurepause"></iw-checkbox>
    <iw-textfield [isDisabled]="true"
                  class="col-xs-2 padding-0 no-label"
                  formControlName="txPause"></iw-textfield>
    <iw-textfield [decimals]="4"
                  [isDisabled]="true"
                  [label]="'paye' | translate"
                  class="col-xs-3 padding-0"
                  formControlName="mntPause"
                  type="number"></iw-textfield>
    <iw-checkbox [isDisabled]="isReadonly || !canHeurPauseAuto"
                 [label]="'heurPauseAuto' | translate"
                 class="col-xs-4"
                 formControlName="hpauseauto"></iw-checkbox>
    <iw-enum-dropdown [inputEnum]="heursSupNames"
                      [isDisabled]="isReadonly"
                      [label]="'heuresSup' | translate"
                      class="col-xs-12"
                      formControlName="shsupmode"
                      labelAlign="left"></iw-enum-dropdown>
</form>
