import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Geclisui} from '@app/sam-base/models/invoices/geclisui';
import {Observable, Subject} from 'rxjs';
import {RestClient} from '../rest-api/core';

export interface GeclisuiSubscrition {
    uuid: string;
    saved: boolean;
}


@Injectable()
export class GeclisuiService extends RestClient<any> {

    public geclisuiSub: Subject<GeclisuiSubscrition> = new Subject();

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public save(geclisui?: Geclisui): Observable<void> {
        return this._http.post<void>(this._baseUrl + `geclisui/save`, geclisui);
    }

    public delete(clisuiId: string): Observable<void> {
        return this._http.delete<void>(this._baseUrl + `geclisui/delete/${clisuiId}`);
    }
}
