import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Sadedtx} from '@app/sam-base/models/salary/sadedtx';
import {Observable} from 'rxjs';

import {RestClient} from '../rest-api/core';

@Injectable()
export class SadedtxService extends RestClient<any> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public createNewEntry(sadedtx: Sadedtx): Observable<Sadedtx> {
        return this._http.post<Sadedtx>(this._baseUrl + `sadedtx`, sadedtx);
    }

    public getByAnneeAndDedCode(annee: string, dedCode: string): Observable<Sadedtx[]> {
        return this._http.get<Sadedtx[]>(this._baseUrl + `sadedtx/${annee}/${dedCode}`);
    }

    public saveByAnneeAndTxCodeAndDedCode(annee: string, dedCode: string, txCode: string, sadedtx: Sadedtx): Observable<Sadedtx> {
        return this._http.put(this._baseUrl + `sadedtx/${annee}/${dedCode}/${txCode}`, sadedtx);
    }

    public saveById(id: number, sadedtx: Sadedtx): Observable<Sadedtx> {
        return this._http.put(this._baseUrl + `sadedtx/${id}`, sadedtx);
    }

    public deleteById(id: number | undefined): Observable<Sadedtx> {
        return this._http.delete<Sadedtx>(this._baseUrl + `sadedtx/${id}`);
    }
}
