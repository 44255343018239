import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Saemr} from '@app/sam-base/models/salary/saemr';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class SaemrService extends RestClient<Saemr> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getSaemrByEmpIdAndSalIdAndCID(empId?: string, salId?: string, cid?: string): Observable<number> {
        return this._http.get<number>(this._baseUrl + `saemr/${empId}/${salId}/${cid}`);
    }
}
