<form [formGroup]="formGroup"
      class="row space-form">
    <div class="form-host row col-xs-6 separator-right">
        <iw-enum-dropdown #cboModeFactu
                          [inputEnum]="modefactu"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'modeDeFacturation' | translate"
                          class="iw-input col-xs-12"
                          formControlName="modefactu"></iw-enum-dropdown>

        <iw-enum-dropdown #cboFacnpmode
                          [inputEnum]="facnpmode"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'nomPrenomFacturation' | translate"
                          class="iw-input col-xs-12"
                          formControlName="facnpmode"></iw-enum-dropdown>

        <iw-enum-dropdown #cboTextSupMode
                          [inputEnum]="textsupmode"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'texte_sup' | translate"
                          class="iw-input col-xs-12"
                          formControlName="textsupmode"></iw-enum-dropdown>

        <iw-enum-dropdown #cboclitarifmd
                          [inputEnum]="clitarifmd"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'facturationHeuresSup' | translate"
                          class="iw-input col-xs-10"
                          formControlName="clitarifmd"></iw-enum-dropdown>

        <iw-textfield #standardfield1
                      [isDisabled]="true"
                      class="iw-input col-xs-1 no-label"
                      formControlName="clitarifmd"></iw-textfield>

        <iw-textfield #txtclitarmd6f
                      [isDisabled]="true"
                      class="iw-input col-xs-1 no-label"
                      formControlName="clitarmd6f"></iw-textfield>

        <iw-enum-dropdown #cboHpauFacMod
                          [inputEnum]="hpaufacmodOptions"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'facturationHeuresPause' | translate"
                          class="iw-input col-xs-12"
                          formControlName="hpaufacmod"></iw-enum-dropdown>

        <iw-enum-dropdown #cbohpausaimod
                          [inputEnum]="hpausaimodOptions"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'saisieHeuresPause' | translate"
                          class="iw-input col-xs-12"
                          formControlName="hpausaimod"></iw-enum-dropdown>

        <iw-enum-dropdown #cboshsupmode
                          [inputEnum]="shsupmode"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'paiementHeuresSup' | translate"
                          class="iw-input col-xs-12"
                          formControlName="shsupmode"></iw-enum-dropdown>

        <iw-enum-dropdown #cboFormeJuri
                          [inputEnum]="formejuri"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'formeJuridique' | translate"
                          class="iw-input col-xs-12"
                          formControlName="formejuri"></iw-enum-dropdown>

        <iw-enum-dropdown #cboLangue
                          [inputEnum]="langue"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'langue' | translate"
                          class="iw-combo-box col-xs-12"
                          formControlName="langue"></iw-enum-dropdown>
    </div>
    <div class="row col-xs-6 ppcli-agency">
        <iw-textfield #txtEscompte
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'rabaisRemise' | translate"
                      class="col-xs-8"
                      formControlName="escompte"
                      stepValue="0.01"
                      type="number"></iw-textfield>

        <iw-button #btnImpXLS
                   [isDisabled]="!isReadonly"
                   [text]="'importer' | translate"
                   [width]="90"
                   class="iw-button col-xs-2"></iw-button>

        <iw-checkbox #chkCdeclilieu
                     [isDisabled]="isReadonly"
                     [label]="'parDefautLesMissionsSeDeroulentChezLeClient' | translate"
                     class="iw-input col-xs-12"
                     formControlName="cdeclilieu"></iw-checkbox>

        <iw-checkbox #chkLhhmm
                     [isDisabled]="isReadonly"
                     [label]="'hhmm' | translate"
                     class="iw-input col-xs-2"
                     formControlName="lhhmm"></iw-checkbox>

        <iw-checkbox #chkForceCha
                     [isDisabled]="isReadonly"
                     [label]="'chantierObligatoire' | translate"
                     class="iw-input col-xs-5"
                     formControlName="forcecha"></iw-checkbox>

        <iw-checkbox #chkRapnoref
                     [isDisabled]="isReadonly"
                     [label]="'saisieNoRapport' | translate"
                     class="iw-input col-xs-5"
                     formControlName="rapnoref"></iw-checkbox>

        <iw-textfield #txtHoraire
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'horaire' | translate"
                      class="iw-input col-xs-12"
                      formControlName="horaire"></iw-textfield>

        <iw-template-dropdown #cboContLocation
                              [isDisabled]="isReadonly"
                              [labelAlign]="'top'"
                              [label]="'contratDeLocationDeServices' | translate"
                              [report]="'CC'"
                              [size]="'large'"
                              class="iw-combobox col-xs-10"
                              formControlName="ccfrx"></iw-template-dropdown>

        <iw-template-dropdown #cboContMission
                              [isDisabled]="isReadonly"
                              [labelAlign]="'top'"
                              [label]="'contratDeMission' | translate"
                              [report]="'CM'"
                              [size]="'large'"
                              class="iw-combobox col-xs-10"
                              formControlName="cmfrx"></iw-template-dropdown>

        <iw-button #btnCCT
                   (press)="openCttSelection()"
                   [isDisabled]="isReadonly"
                   [text]="'CCT'"
                   [width]="80"
                   class="iw-button padding-left-5 padding-right-5"
                   id="cct-button"></iw-button>

        <iw-textarea #edtCctids
                     [height]="22"
                     [isDisabled]="isReadonly"
                     class="iw-textarea col-xs-6"
                     formControlName="cctids"></iw-textarea>

        <iw-checkbox #chkCctOblig
                     [isDisabled]="isReadonly"
                     [label]="'obligatoire' | translate"
                     class="iw-input col-xs-2"
                     formControlName="cctoblig"></iw-checkbox>
    </div>
</form>
