import {Component, Input, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {IwStoreService} from '@app/sam-base/core/store';
import {getFirstDateOfYear, getLastDateOfYear} from '@app/sam-base/helpers/date';
import {FormKeys} from '@app/sam-base/models';

import {Ppclistats} from '../../models/ppclistats.model';
import {ClientStatsService} from '../../services/client-stats.service';

@Component({
    templateUrl: './ppclistats.component.html'
})
export class PpclistatsComponent extends BaseStoreFormComponent<Ppclistats> implements OnInit {

    public get name() {
        return this.getData('name', false);
    }

    public get cliId(): string {
        return this.getData('cliId', false) || '';
    }

    @Input() public domainName = 'Fixe';

    @Input() public startDate: Date = IwDateHelper.getDate('19910302');

    @Input() public endDate: Date = IwDateHelper.getDate();

    public get startWeek() {
        return IwDateHelper.dateWeek(this.startDate);
    }

    public get endWeek() {
        return IwDateHelper.dateWeek(this.endDate);
    }

    constructor(store: IwStoreService, protected _statsService: ClientStatsService) {
        super(store);

    }

    // Set date to yesterday
    public setYesterday() {
        this.startDate = getFirstDateOfYear();
        this.endDate = IwDateHelper.getDate(IwDateHelper.dateSubtract(IwDateHelper.getDate(), 1, 'days'));
    }

    public set365Days() {
        this.startDate = IwDateHelper.getDate(IwDateHelper.dateSubtract(IwDateHelper.getDate(), 1, 'year'));
        this.endDate = IwDateHelper.getDate(IwDateHelper.dateSubtract(IwDateHelper.getDate(), 1, 'days'));
    }

    public setLastYear() {
        this.startDate = getFirstDateOfYear(1);
        this.endDate = getLastDateOfYear(1);
    }

    public setBack2Years() {
        this.startDate = getFirstDateOfYear(2);
        this.endDate = getLastDateOfYear(2);
    }

    public async calculate() {
        this._statsService.calculatePPCliStats(this.cliId, this.startDate, this.endDate)
            .subscribe((clistat: Ppclistats) => {
                this.formValue = clistat;
                this.setFormValue('firstMis', IwDateHelper.dateFormatFromString(clistat.firstMis, 'DD.MM.YYYY'));
                this.setFormValue('lastMis', IwDateHelper.dateFormatFromString(clistat.lastMis, 'DD.MM.YYYY'));
            });
    }

    public ngOnInit() {
        // This.setYesterday();
    }

    protected getFormControlNames(): FormKeys<Ppclistats> {
        return ['dateA', 'dateDe', 'nbemp', 'nbcdf', 'nbcde', 'nbplf', 'cafixeht', 'nbmis', 'nbrap', 'nbheu', 'caht', 'margebrute', 'coeff', 'coeffcustom', 'firstMis', 'lastMis'];
    }

}
