import { HttpClient } from '@angular/common/http';
import {AfterContentInit, Component, EventEmitter} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {FormKeys, ModalComponent} from '@app/sam-base/models';
import {Gecliadr} from '@app/sam-base/models/invoices/gecliadr';
import {environment} from '@root/environments/environment';

import {Gecliadropts} from './gecliadr.options';

@Component({
    templateUrl: './gecliadr.component.html'
})
export class GecliadrComponent extends BaseFormComponent<Gecliadr> implements ModalComponent<boolean, Gecliadropts>, AfterContentInit {

    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public isWriteMode = true;
    private _options = new Gecliadropts();
    private _ok?: (result: boolean) => void;
    private _cancel?: (error?: any) => void;

    constructor(private _restService: RestApiService, private _http: HttpClient) {
        super();
    }

    public get pays(): string {
        return this.getFormValue('pays') || '';
    }

    public get cliadrId(): string | undefined {
        return this._options.cliadrId;
    }

    public get clientFormUuid(): string | undefined {
        return this._options.cliFormUuid;
    }

    public get cliId(): string | undefined {
        return this._options.cliId;
    }

    public ngAfterContentInit() {
        if (!this.cliadrId) {
            this.setNewAddress();
        } else {
            this._restService.getEntityClient(Gecliadr)
                .getById(this.cliadrId)
                .subscribe((addr: Gecliadr) => {
                    this.fillFormData(addr);
                });
        }
    }

    public async setData(data: Gecliadropts): Promise<void> {
        this._options = data;
    }

    public onPressOk() {
        if (this._ok) {
            this._ok(true);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public registerOk(action: (result: boolean) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public saveChanges() {
        const cliadrId: string | undefined = this.getFormValue('cliadrId');
        const addrData = this.getFormData();
        if (cliadrId) {
            this._http.put<Gecliadr>(environment.backendURL + 'gecliadr/' + cliadrId, this.getFormData())
                .subscribe(() => this.onPressOk());
        } else {
            if (!addrData) {
                return;
            }
            this._restService.getEntityClient(Gecliadr)
                .create(addrData)
                .subscribe(() => this.onPressOk());
        }
    }

    public closeDialog() {
        this.onPressCancel();
    }

    protected getFormControlNames(): FormKeys<Gecliadr> {
        return ['adrlib', 'cliadrId', 'prefixe', 'nom', 'adresse1', 'adresse2', 'adresse3', 'npa', 'lieu', 'pays', 'cliId'];
    }

    protected getValidationType() {
        return Gecliadr;
    }

    protected validateFields(e: Gecliadr): boolean {
        return super.validateFields(e);
    }


    private setNewAddress() {
        this.setFormValue('cliId', this.cliId);
    }

}
