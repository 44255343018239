import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Saems} from '@app/sam-base/models/salary/saems';

import {FicheSalaireSideMenuComponent} from '../components/fiche-salaire/fiche-salaire-side-menu.component';
import {FicheSalaireComponent} from '../components/fiche-salaire/fiche-salaire.component';

export const FICHE_SALAIRE_DEFAULT_FORM: FormDescription<Saems> = {
    form: FicheSalaireComponent, sideMenu: FicheSalaireSideMenuComponent, state: {
        ...buildDefaultState(Saems),
        diagTitle: 'ficheSalaire',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showPrint: false, showDelete: true
        },
        editMode: 'read',
        useNavigation: true,
        width: 1200,
        patch: false
    }
};
