import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Saes} from '@app/sam-base/models/salary/saes';
import {JoursIsComponent} from '../components/fiche-salaire/tab-fichesal-fiche/joursIS/joursis.component';

export const PERIODEIS_DEFAULT_FORM: FormDescription<Saes> = {
    form: JoursIsComponent, state: {
        ...buildDefaultState(Saes), diagTitle: 'periodeIS', diagSideMenu: {
            showCancel: true, showClose: true
        }, width: 500
    }
};
