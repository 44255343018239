import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppgrt} from '@app/sam-base/models/placement';

import {
    GenretravConfigComponent
} from '../components/genretrav-config/genretrav-config.component';

export const GENRE_TRAVAIL: FormDescription<Ppgrt> = {
    form: GenretravConfigComponent, state: {
        ...buildDefaultState(Ppgrt), diagModal: true, diagTitle: 'genretrav', width: 700, isNew: true
    }
};
