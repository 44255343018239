import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Sagen} from '@app/sam-base/models/salary/sagen';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const periodTabsFormControls: TabFormControl<Sagen>[] = [
    {
        formControls: [
            'salId',
            'status']
    },
    {
        title: 'tabInformations',
        formControls: [
            'salId',
            'status',
            'notesint',
            'notesbs',
            'dateOpen',
            'dateClose',
            'dateXfer',
            'gestXfer',
            'nbsal',
            'mntnap',
            'mntpaid',
            'mntrep']
    },
    {
        title: 'notes',
        formControls: [
            'notesint',
            'notesbs']
    },
    {
        title: 'swissdec_title',
        formControls: []
    }];
