import {Injectable} from '@angular/core';

import {RestApiService, RestEntityClient} from '../../rest-api';
import {ConfigTemplate} from '../models/config-template.model';

@Injectable()
export class ConfigTemplateService<T> {

    private _entityService: RestEntityClient<ConfigTemplate<T>>;

    constructor(private readonly _restService: RestApiService) {
        this._entityService = this._restService.getEntityClient<ConfigTemplate<T>>(ConfigTemplate);
    }

    public loadConfigTemplate() {
        return this._entityService.getRefData();
    }

    public saveConfigTemplate(userId: string, title: string, template: T) {
        return this._entityService.create({template});
    }
}
