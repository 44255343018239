<form [formGroup]="formGroup"
      class="row">
    <iw-template-dropdown (selectedChanged)="changeValueDropdownSalary($event)"
                          *ngIf="isSalaire"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'bulletin_salaire' | translate"
                          [loadAtStartUp]="true"
                          [preselectedId]="defaultSalaryPrint"
                          [report]="'BS'"
                          class="iw-input col-xs-10 padding-top-15"
                          formControlName="bsfrx"></iw-template-dropdown>
    <div class="col-xs-2"></div>
    <iw-template-dropdown (selectedChanged)="changeValueDropdownSalary($event)"
                          *ngIf="isSalaire"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'bulletinDeSalaireParEMail' | translate"
                          [loadAtStartUp]="true"
                          [report]="'BS'"
                          class="iw-input col-xs-10 padding-top-15"
                          formControlName="bsFrxEml"></iw-template-dropdown>
    <div class="col-xs-2"></div>
</form>
