<div class="row">
    <iw-button (press)="declareSalary()" [text]="'declare_salary' | translate" class="btn-block"></iw-button>
    <!--  [isDisabled]="declareSalaryDisabled"-->
</div>
<div class="row">
    <iw-button (press)="getDeclarationStatus()" [isDisabled]="getStatusDisabled"
               [text]="'get_declaration_status' | translate" class="btn-block"></iw-button>
</div>
<div class="row">
    <iw-button (press)="ping()" [text]="'ping' | translate" class="btn-block"></iw-button>
</div>
<div class="row">
    <iw-button (press)="checkInteroperability()" [text]="'checkInteroperability' | translate"
               class="btn-block"></iw-button>
</div>
