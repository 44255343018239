<iw-modal-form #modal
               [(loading)]="loading"
               [btnCancel]="btnCancel"
               [btnOk]="btnOk"
               [height]="350"
               [height]="'auto'"
               [title]="'comptes'"
               [width]="700">
    <div class="row">
        <div class="row col-xs-10">
            <iw-table #table
                      (selectedChange)="loadCompte($event)"
                      [(selected)]="selectedElement"
                      [columns]="tableColumns"
                      [data]="tableData"
                      [isFilterEnable]="false"
                      [isGridColumnMenuEnable]="false"
                      [isSortIconEnable]="false"
                      class="iw-input col-xs-12"></iw-table>
        </div>
        <div class="col-xs-2">
            <iw-button (press)="editing = !editing"
                       *ngIf="!editing"
                       [isDisabled]="!selectedElement"
                       [text]="'modif' | translate"
                       class="col-xs-12"
                       id="edit"></iw-button>
            <iw-button (press)="deleteCompte()"
                       *ngIf="!editing"
                       [isDisabled]="!selectedElement"
                       [text]="'supprimer' | translate"
                       class="col-xs-12"
                       id="delete"></iw-button>
            <iw-button (press)="saveElement()"
                       *ngIf="editing"
                       [isDisabled]="!selectedElement"
                       [text]="'enregistrer' | translate"
                       class="col-xs-12"
                       id="save"></iw-button>
            <iw-button (press)="loadCompte(); editing = !editing"
                       *ngIf="editing"
                       [isDisabled]="!selectedElement"
                       [text]="'annuler' | translate"
                       class="col-xs-12"
                       id="cancel"></iw-button>
        </div>
        <div class="row col-xs-12 padding-top-10 flex-center">
            <iw-textfield-compte #txtCompte
                                 [(value)]="compte"
                                 [isDisabled]="!editing || !selectedElement || !enableCompte"
                                 [labelAlign]="'left'"
                                 [label]="'compte' | translate"
                                 class="iw-input col-xs-8"></iw-textfield-compte>
            <div class="col-xs-2 col-xs-offset-2">
                <iw-button (press)="registerCancel()"
                           [text]="'fermer' | translate"
                           class="col-xs-12"
                           id="fermer"></iw-button>
            </div>
        </div>
    </div>
</iw-modal-form>
