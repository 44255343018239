import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Sadedda} from '@app/sam-base/models/salary/sadedda';
import {Observable} from 'rxjs';

import {RestClient} from '../rest-api/core';

@Injectable()
export class SadeddaService extends RestClient<any> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public createNewEntry(sadedda: Sadedda): Observable<Sadedda> {
        return this._http.post<Sadedda>(this._baseUrl + `sadedda`, sadedda);
    }

    public saveByAnneeAndDedCodeAndTxCode(annee: string, dedCode: string, txCode: string, sadedda: Sadedda): Observable<Sadedda> {
        return this._http.put(this._baseUrl + `sadedda/${annee}/${dedCode}/${txCode}`, sadedda);
    }

    public deleteByAnneeAndDedCodeAndTxCode(annee: string, dedCode: string, txCode: string): Observable<Sadedda> {
        return this._http.delete<Sadedda>(this._baseUrl + `sadedda/${annee}/${dedCode}/${txCode}`);
    }
}
