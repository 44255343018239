<form [formGroup]="formGroup" class="row"></form>
<iw-tab [(selectedTab)]="selectedTab" [invalidTabs]="invalidTabList" [tabs]="tabList">
    <iw-tab-gepar-facturation *ngIf="selectedTab === tabList[0]" [formGroup]="formGroup"
                              [isReadonly]="!isWriteMode"></iw-tab-gepar-facturation>
    <iw-tab-gepar-compta *ngIf="selectedTab === tabList[1]" [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"></iw-tab-gepar-compta>
    <iw-tab-gepar-format *ngIf="selectedTab === tabList[2]" [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"></iw-tab-gepar-format>
    <iw-tab-gepar-autre *ngIf="selectedTab === tabList[3]" [formGroup]="formGroup"
                        [isReadonly]="!isWriteMode"></iw-tab-gepar-autre>
    <form [formGroup]="formGroup" class="row"></form>
</iw-tab>
