<form [formGroup]="formGroup" class="row">
    <div class="row space-form col-md-12">
        <div class="row col-xs-12" style="align-items: end;">
            <iw-date-picker [labelAlign]="'top'" [label]="'date' | translate" [size]="'small'"
                            [value]="geclisuiDateAction" class=" col-xs-4 padding-top-10 no-padding-right"
                            formControlName="dateAction"></iw-date-picker>
            <iw-textfield [isDisabled]=true [labelAlign]="'top'" [value]="userId"
                          class="iw-input col-xs-4 padding-left-10 padding-top-10"
                          formControlName="userId"></iw-textfield>
        </div>
        <div class="row col-xs-12" style="align-items: end;">
            <iw-textfield-action [labelAlign]="'top'" [label]="'action' | translate" [lkname]="action"
                                 [value]="geclisuiAction" class="iw-input col-xs-4 padding-top-10"
                                 formControlName="action"></iw-textfield-action>

        </div>
        <div class="row col-xs-12" style="align-items: end;">
            <iw-textarea [height]="50" [labelAlign]="'top'" [label]="'remarques' | translate" [value]="notes"
                         class="iw-input col-xs-12 padding-top-10" formControlName="notes"></iw-textarea>
        </div>

        <div class="row col-xs-12 padding-top-20" style="align-items: end;">
            <iw-checkbox (valueChange)="enableRappeler($event)" [labelAlign]="'right'" [label]="'rappeler' | translate"
                         [value]="rappelFlag" class="iw-input col-xs-3" formControlName="rappelFlag"></iw-checkbox>

            <iw-date-picker [isDisabled]="isRappelerDisabled" [value]="dateRappel"
                            class=" col-xs-4 padding-top-10 padding-left-20"
                            formControlName="dateRappel"></iw-date-picker>
        </div>
        <div class="row col-xs-12 padding-top-10" style="align-items: end;">
            <iw-checkbox [isDisabled]="isRappelerDisabled" [labelAlign]="'right'"
                         [label]="'rappeler_effectue' | translate" [value]="rappelDone" class="iw-input col-xs-4"
                         formControlName="rappelDone"></iw-checkbox>
        </div>

        <div class="row col-xs-12 padding-top-10" style="align-items: end;">
            <iw-button (press)="save()" [text]="'enregistrer' | translate"
                       class="iw-button padding-top-15 margin-right-5"></iw-button>
            <iw-button (press)="destroyForm()" [text]="'Annuler' | translate" class="iw-button"></iw-button>
        </div>
    </div>

</form>
