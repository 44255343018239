<form [formGroup]="formGroup"
      class="row">
    <iw-busy [ajustLeft]="0"
             [ajustTop]="0"
             [isBusy]="isLoading"></iw-busy>

    <div class="row space-form col-xs-10">
        <div class="col-xs-12">
            <div class="col-xs-4 col-xs-offset-1 no-padding">
                <iw-textfield #editMode
                              [isDisabled]="true"
                              [label]="'mode' | translate"
                              [noLabel]="'top'"
                              [value]="(isNew ? 'nouveau' : 'modif') | translate"
                              class="iw-input"></iw-textfield>
            </div>
        </div>
        <div class="col-xs-1"></div>
        <iw-textfield #txtprBase
                      [isDisabled]="!canmodify"
                      [labelAlign]="'top'"
                      [label]="'base' | translate"
                      class="iw-input col-xs-2 padding-top-15 text-align-right"
                      formControlName="prBase"
                      type="number"></iw-textfield>
        <iw-textfield #txtUnite
                      [isDisabled]="!canmodify"
                      [labelAlign]="'top'"
                      [label]="' '"
                      class="iw-input col-xs-1 padding-top-15"
                      formControlName="unite"></iw-textfield>
        <iw-textfield #txtTexte
                      [isDisabled]="!canmodify"
                      [labelAlign]="'top'"
                      [label]="'texteComple' | translate"
                      class="iw-input col-xs-6 padding-top-15"
                      formControlName="texte"></iw-textfield>
        <div class="col-xs-2"></div>

        <div class="iw-label col-xs-1 padding-top-15"
             style="margin-top: 15px; text-align-last: center">{{ 'X' }}
        </div>
        <iw-textfield #txtprTaux
                      [isDisabled]="!canmodify"
                      [noLabel]="true"
                      class="iw-input col-xs-2 padding-top-15 text-align-right"
                      formControlName="prTaux"
                      type="number"></iw-textfield>
        <div class="col-xs-9"></div>

        <div class="row col-xs-12 no-padding">
            <div class="iw-label col-xs-1 padding-top-15"
                 style="margin-top: 15px; padding-top: 15px; text-align-last: center">{{ '=' }}
            </div>
            <iw-textfield #txtBase
                          [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="' '"
                          class="iw-input col-xs-2 text-align-right"
                          formControlName="base"
                          type="number"></iw-textfield>
            <iw-textfield #txttAUX
                          (valueChange)="applyTauxMask($event)"
                          [decimals]="2"
                          [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'+/-'"
                          [value]="taux"
                          class="iw-input col-xs-2 text-align-right"
                          suffix="%"
                          type="number"></iw-textfield>
            <iw-textfield #txtMontant
                          [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'montant' | translate"
                          class="iw-input col-xs-2 text-align-right"
                          formControlName="montant"
                          type="number"></iw-textfield>

            <iw-dropdown #cbosal_id_cor
                         *ngIf="isCboSalIdActive"
                         [isDisabled]="!canmodify"
                         [labelAlign]="'top'"
                         [label]="'month_correction' | translate"
                         [options]="cboSalIdOptions"
                         class="iw-input col-xs-4"
                         formControlName="salIdCor"></iw-dropdown>
        </div>
        <div class="row col-xs-12 no-padding">
            <iw-textfield-compte #txtDebit
                                 [isDisabled]="!canmodify"
                                 [label]="'debit' | translate"
                                 class="iw-input col-xs-3 col-xs-offset-1 padding-top-15"
                                 formControlName="cptdebit"></iw-textfield-compte>
            <iw-textfield-compte #txtCredit
                                 [isDisabled]="!canmodify"
                                 [labelAlign]="'top'"
                                 [label]="'credit' | translate"
                                 class="iw-input col-xs-3 padding-top-15"
                                 formControlName="cptcredit"></iw-textfield-compte>
            <iw-textfield #txtParent
                          [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="' ' | translate"
                          class="iw-input col-xs-3 padding-top-15"
                          formControlName="parent"></iw-textfield>
            <div class="col-xs-4"></div>
        </div>
        <div *ngIf="isCboTableElActive"
             class="row col-xs-12 padding-top-10">
            <iw-textfield #txtTable_no
                          [isDisabled]="true"
                          [noLabel]="true"
                          [value]="bscalcversion === 'SD4' ? dedCode : tableNo"
                          class="iw-input col-xs-2 col-xs-offset-1"></iw-textfield>
            <iw-dropdown #cbotable_elem_id
                         [(value)]="tableElemId"
                         [isDisabled]="!canmodify"
                         [noLabel]="true"
                         [options]="cboTableElOptions"
                         class="iw-input col-xs-4 padding-left-5"></iw-dropdown>
            <iw-textfield #txtTable_elem_id
                          [isDisabled]="true"
                          [value]="tableElemId"
                          class="iw-input col-xs-2"></iw-textfield>
        </div>
        <div *ngIf="isCboCctIdEnabled"
             class="col-xs-12 row padding-top-10">
            <div class="iw-label col-xs-1">CCT</div>
            <iw-ctt-dropdown #drpCodeCctId
                             [(value)]="cctId"
                             [isDisabled]="false"
                             [noLabel]="true"
                             class="iw-input col-xs-4"></iw-ctt-dropdown>
            <iw-enum-dropdown #cboPaytype
                              [(value)]="cctDedtype"
                              [inputEnum]="cboCctIdDedtypeOptions"
                              [isDisabled]="false"
                              [noLabel]="true"
                              class="iw-input col-xs-4"></iw-enum-dropdown>
        </div>
        <div *ngIf="isCboIsEnabled"
             class="col-xs-12 row padding-top-10">
            <iw-canton-dropdown #cboIsCanton
                                [(value)]="isCanton"
                                [isDisabled]="false"
                                [noLabel]="true"
                                class="iw-input col-xs-3"></iw-canton-dropdown>
            <iw-enum-dropdown #cboIsBareme
                              [(value)]="isBareme"
                              [inputEnum]="cboIsBaremeOptions"
                              [isDisabled]="false"
                              [noLabel]="true"
                              class="iw-input col-xs-3"></iw-enum-dropdown>
            <iw-enum-dropdown #cboIsCalcmode
                              [(value)]="isCalcmode"
                              [inputEnum]="cboIsBaremeOptions"
                              [isDisabled]="false"
                              [noLabel]="true"
                              class="iw-input col-xs-4"></iw-enum-dropdown>
            <iw-textfield #txtIsTaux
                          [(value)]="isTaux"
                          [isDisabled]="true"
                          class="iw-input col-xs-2"></iw-textfield>
        </div>
        <div class="row col-xs-12 padding-top-10">
            <iw-textfield #txtGs_id
                          [isDisabled]="true"
                          class="iw-input col-xs-4"
                          formControlName="gsId"></iw-textfield>
            <iw-textfield #txtGsTitre
                          [isDisabled]="true"
                          [value]="sags?.titre ?? ''"
                          class="iw-input col-xs-8"></iw-textfield>
        </div>
        <div *ngIf="isNew"
             class="col-xs-12 element-salaire-grid-container">
            <iw-rest-grid #sagsGrid
                          (selected)="onRowSelect($event)"
                          [autoFormClick]="false"
                          [customColumns]="sagsColumns"
                          [defaultSelected]="true"
                          [forceSelected]="true"
                          [hasFilter]="true"
                          [hasGridColumnMenu]="false"
                          [hasSortIcon]="true"
                          [queryStatements]="queryStatements"
                          [type]="gridType"
                          class="element-salaire-grid"
                          id="sagsGrid"></iw-rest-grid>
        </div>
    </div>

    <div class="col-xs-2 flex flex-v separator-left">
        <iw-button #btnSave
                   (press)="saveEntry()"
                   [isDisabled]="!formGroup.valid"
                   [text]="'sidemenu_save' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnClose
                   (press)="closeDialog()"
                   [text]="'fermer' | translate"
                   class="iw-button btn-block"
                   style="margin-block-start: auto"></iw-button>
    </div>
</form>
