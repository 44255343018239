import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppemp} from '@app/sam-base/models/placement';

/**
 * Tab Notes of Employee - SAM-4353
 */
@Component({
    selector: 'iw-tab-ppemp-notes',
    templateUrl: './tab-ppemp-notes.component.html'
})
export class TabPpempNotesComponent extends BasePartialFormComponent<Ppemp> implements OnInit {

    constructor() {
        super();
    }

    public get entityId() {
        return this.getFormValue<string>('empId');
    }

    ngOnInit(): void {
    }

    /**
     * Function to check if listnoire is checked and is not readonly
     * Conditions to allow cmotifnoir field
     */
    public checkListNoire() {
        const listNoire = this.getFormValue('listenoir');
        if (listNoire && !this.isReadonly) {
            return false;
        }
        return true;
    }

}
