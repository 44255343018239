import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppcli} from '@app/sam-base/models/placement';

import {PpcliSideMenuComponent} from '../components/ppcli/ppcli-side-menu.component';
import {PpcliComponent} from '../components/ppcli/ppcli.component';

export const PPCLI_DEFAULT_FORM: FormDescription<Ppcli> = {
    form: PpcliComponent, sideMenu: PpcliSideMenuComponent, state: {
        ...buildDefaultState(Ppcli),
        diagTitle: 'ppcli_form_title',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showPrint: true, showDelete: true
        },
        useNavigation: true,
        patch: false,
        width: 1000
    }
};
