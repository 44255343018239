import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {EmployeeDeductionInfoForm} from '@app/sam-base/models/salary/employeeDeductionInfoForm';
import {
    EmployeeDeductionComponent
} from '../components/employee/tab-saemp-deductions/deductionEdit/employeeDeduction.component';

export const EMPLOYEE_DEDUCTION_INFO_FORM: FormDescription<EmployeeDeductionInfoForm> = {
    form: EmployeeDeductionComponent, state: {
        ...buildDefaultState(EmployeeDeductionInfoForm),
        diagTitle: 'employee_deduction_form',
        diagModal: false,
        width: 500,
        isNew: true
    }
};
