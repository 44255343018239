import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppplf} from '@app/sam-base/models/placement';

import {PlacfixeComponent} from '../components';

export const PPPLF_DEFAULT_FORM: FormDescription<Ppplf> = {
    form: PlacfixeComponent, state: {
        ...buildDefaultState(Ppplf), diagTitle: 'ppplf', diagShowSideMenu: true, diagShowStatus: true, diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showPrint: true, showDelete: true
        }, useNavigation: true, patch: false, width: 1000
    }
};

export const PPPLF_NEW_FORM: FormDescription<Ppplf> = {
    form: PlacfixeComponent, state: {
        ...buildDefaultState(Ppplf), diagTitle: 'ppplf', diagShowSideMenu: true, diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showPrint: true, showDelete: true
        }, isNew: true, width: 1000, destroyOnSave: true, editMode: 'edit'
    }
};
