import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {EmployeeSideMenuComponent} from '../components/employee/employee-side-menu.component';
import {EmployeeComponent} from '../components/employee/employee.component';

export const SAEMP_DEFAULT_FORM: FormDescription<Saempc> = {
    form: EmployeeComponent, sideMenu: EmployeeSideMenuComponent, state: {
        ...buildDefaultState(Saempc), diagTitle: 'saemp', diagShowSideMenu: true, diagShowStatus: true, diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showPrint: false, showDelete: true
        }, editMode: 'read', useNavigation: true, width: 1000, patch: false
    }
};
