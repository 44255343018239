import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Ppaco, Ppper} from '@app/sam-base/models/placement';
import {PaymentRequest} from '@modules/sam-main/placement/components/acompte/paiement/payment.model';
import {environment} from '@root/environments/environment';
import {Autoaco} from '@sam-base/models/placement/autoaco';
import {PppacoPay} from '@sam-base/models/placement/payacompte';

@Injectable()
export class PayacoService {

    public periode?: Ppper;

    constructor(private readonly httpClient: HttpClient) {
    }

    public postPayAcos(req: PaymentRequest) {
        return this.httpClient
            .post(environment.backendURL + 'acompte', req);
    }

    public getAvailablePpacos(period: Ppper) {
        return this.httpClient.get<PppacoPay[]>(environment.backendURL + 'acompte/pay/' + period?.salId);
    }

    public getAutoAcomptes(salId = '') {
        return this.httpClient.get<Autoaco[]>(environment.backendURL + 'acompte/auto/' + salId);
    }

    public getNoPayTypeAutoAcomptes(salId = '') {
        return this.httpClient.get<Autoaco[]>(environment.backendURL + 'acompte/nopaytype-auto/' + salId);
    }

    public generateAcomptes(salId = '', acos: Autoaco[]) {
        return this.httpClient.post<Ppaco[]>(environment.backendURL + 'acompte/auto/' + salId, acos);
    }
}
