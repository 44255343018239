import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {Dictionary} from '@app/sam-base/models';
import {KeycloakService} from 'keycloak-angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

type BlockType = 'invoices' | 'rapports' | 'salaries';

@Injectable({providedIn: 'root'})
export class SystemLockService extends RestClient<{}> {

    private _activeBlock: Dictionary<number> = {};

    constructor(http: HttpClient, private _keycloakService: KeycloakService) {
        super(http);
    }

    public startBlockProcess(type: BlockType): Observable<number> {
        return this.blockSystem(type)
            .pipe(map(() => {
                const id: number = <any>setInterval(() => this.blockSystem(type)
                    .subscribe((val: SystemLock) => {
                        if (val.locked) {
                            clearInterval(id);
                        }
                    }), 5000);
                this._activeBlock[type] = id;
                return id;
            }));
    }

    public removeBlockProcess(type: BlockType): boolean {
        const id = this._activeBlock[type];
        if (id) {
            clearInterval(id);
        }
        this.unblockSystem(type)
            .subscribe();
        return !!id;
    }

    public getSystemBlocked(type: BlockType): Observable<SystemLock> {
        return this.GET(undefined, 'system/blocked', type);
    }

    public isSystemBlocked(lock?: SystemLock): boolean {
        if (!lock) {
            return false;
        }
        return lock.locked && this._keycloakService.getUsername() !== lock.userLock;
    }

    private blockSystem(type: BlockType): Observable<SystemLock> {
        return this.POST({}, undefined, 'system/block', type);
    }

    private unblockSystem(type: BlockType): Observable<void> {
        return this.POST({}, undefined, 'system/unblock', type);
    }
}

export interface SystemLock {
    userLock: string;
    timeLock: Date;
    locked: boolean;
}
