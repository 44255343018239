<form [formGroup]="formGroup"
      class="row spaceForm">

    <div class="row col-xs-6 separator-right">
        <iw-date-picker #txtFirstmis
                        [isDisabled]="true"
                        [labelAlign]="'top'"
                        [label]="'1EreMission' | translate"
                        class="iw-input col-xs-4 no-padding-left"
                        formControlName="firstMis"></iw-date-picker>
        <div class="col-xs-8"></div>

        <iw-date-picker #txtLppStart0
                        [isDisabled]="isReadonly || !lppforce0"
                        [labelAlign]="'top'"
                        [label]="'calculLppDepuis' | translate"
                        class="iw-input col-xs-4 no-padding-left"
                        formControlName="lppstart0"></iw-date-picker>
        <div class="col-xs-8"></div>

        <iw-checkbox #chkLppforce0
                     *ngIf="false"
                     [isDisabled]="isReadonly"
                     [label]="'forcer' | translate"
                     class="iw-input col-xs-4 margin-top-25"
                     formControlName="lppforce0"></iw-checkbox>

        <iw-textfield #txtLppheucnt0
                      *ngIf="false"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'compteur' | translate"
                      class="iw-input col-xs-4 no-padding-right"
                      formControlName="lppheucnt0"></iw-textfield>
        <iw-dropdown #drpCodeImp
                     (valueChange)="checkValidation($event)"
                     *ngIf="showLppwcount"
                     [isDisabled]="isReadonly || !isPPAdmin"
                     [labelAlign]="'top'"
                     [label]="'nb_semaines_initiales' | translate"
                     [options]="lppwcountOptions"
                     class="iw-input col-xs-6 no-padding-left"
                     formControlName="lppwcount"></iw-dropdown>

        <iw-checkbox #chkLppforce1
                     [isDisabled]="isReadonly"
                     [label]="'force_calcul_lpp' | translate"
                     class="iw-input col-xs-6 margin-top-25"
                     formControlName="lppforce1"></iw-checkbox>

        <iw-button #btnCalcLpp540
                   *ngIf="false"
                   [text]="'recalculer' | translate"
                   class="iw-button col-xs-4"></iw-button>

        <div class="col-xs-12 separator-top"></div>

        <iw-enum-dropdown #cboIlangue
                          [inputEnum]="langue"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'langueCorrespondance' | translate"
                          class="iw-input col-xs-12 col-no-padding"
                          formControlName="langue"></iw-enum-dropdown>

        <div class="col-xs-12 separator-top"></div>

        <iw-textarea #edtNote_bo
                     [height]="160"
                     [isDisabled]="isReadonly"
                     [labelAlign]="'top'"
                     [label]="'notesBackOffice' | translate"
                     class="iw-textarea col-xs-12"
                     formControlName="noteBo"></iw-textarea>
    </div>

    <div class="row col-xs-6 no-padding-right">
        <iw-salaire-paiement [entityId]="ppempId"
                             [formGroup]="formGroup"
                             [isReadonly]="isReadonly"
                             class="col-xs-12 col-no-padding"></iw-salaire-paiement>

        <div class="separator-top col-xs-12"></div>

        <iw-textfield #txtOp_mois
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'poursuites' | translate"
                      [mask]="'99,999.99'"
                      class="iw-input col-xs-8 col-no-padding"
                      formControlName="opMois"
                      stepValue="0.01"
                      type="number"></iw-textfield>
        <div class="col-xs-4 iw-label">{{ 'parMois' | translate }}</div>

        <iw-textfield #txtOp_vital
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="' '"
                      [mask]="'99,999.99'"
                      class="iw-input col-xs-8 col-no-padding"
                      formControlName="opVital"
                      stepValue="0.01"
                      type="number"></iw-textfield>
        <div class="col-xs-4 iw-label">{{ 'minimumVital' | translate }}</div>

        <iw-textarea #edtOp_note
                     [height]="60"
                     [isDisabled]="isReadonly"
                     [labelAlign]="'left'"
                     [label]="'remarques' | translate"
                     class="iw-textarea col-xs-12"
                     formControlName="opNote"></iw-textarea>

        <div class="col-xs-12 separator-top"></div>

        <iw-dropdown #cboModeType
                     [isDisabled]="isReadonly || !salgenfreq"
                     [labelAlign]="'left'"
                     [label]="'freqSalaire' | translate"
                     [options]="modeType"
                     class="iw-input col-xs-12 col-no-padding"
                     formControlName="salgenfreq"></iw-dropdown>

        <iw-checkbox #chkLnoaco
                     [isDisabled]="isReadonly"
                     [label]="'pasDacompteAutomatique' | translate"
                     class="iw-input col-xs-6 no-padding-left"
                     formControlName="lnoaco"></iw-checkbox>

        <iw-textfield #txtAcoFact
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'facteur' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-6 no-padding-right"></iw-textfield>

        <iw-checkbox #chkBstoemail
                     [isDisabled]="isReadonly || !email"
                     [label]="'bulletinDeSalaireParEMail' | translate"
                     class="iw-input col-xs-6 no-padding-left"
                     formControlName="bstoemail"></iw-checkbox>
    </div>
</form>
