import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppoptsal} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-salary-provisions', templateUrl: './tab-salary-provisions.component.html'
})
export class TabSalaryProvisionsComponent extends BasePartialFormComponent<Ppoptsal> {

    constructor() {
        super();
    }
}
