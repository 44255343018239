import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Ppagence} from '@app/sam-base/models/placement/ppagence';
import {PeriodSideMenuComponent} from '@modules/sam-main/salary/components/periode/periode-side-menu.component';
import {AgenceConfigComponent} from '../components/agence-config/agence-config.component';

export const AGENCE_DEFAULT_FORM: FormDescription<Ppagence> = {
    form: AgenceConfigComponent,
    sideMenu: PeriodSideMenuComponent,
    state: {
        ...buildDefaultState(Ppagence),
        diagTitle: 'agence',
        diagShowSideMenu: false,
        diagShowStatus: true,
        width: 1200,
        editMode: 'read'
    }
};
