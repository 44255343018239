import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {TabSaempSearchComponent} from '../components/employee/tab-saemp-impot/tab-saemp-search.component';

export const SAEIMPTX_DEFAULT_FORM: FormDescription<Saempc> = {
    form: TabSaempSearchComponent, state: {
        ...buildDefaultState(Saempc),
        diagTitle: '\'combo_ncs\'',
        diagModal: true,
        width: 500,
        diagShowSideMenu: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: false, showPrint: false, showDelete: false
        },
        editMode: 'edit'
    }
};
