import {Ppcli, Ppcliatt, Ppemp, SmsTypes} from '@app/sam-base/models/placement';

export function clientToSmsType(clients: Ppcli[]): SmsTypes[] {
    return clients.map(e => (<SmsTypes>{
        id: e.cliId,
        ageId: e.ageId,
        nom: e.nom,
        natel: e.natel,
        tel: e.tel1,
        type: 'ppcli'
    }));
}

export function employeToSmsType(employes: Ppemp[], cdeId?: string, cliId?: string): SmsTypes[] {
    return employes.map(e => (<SmsTypes>{
        id: e.empId,
        ageId: e.ageId,
        nom: e.nom,
        natel: e.natel,
        tel: e.tel1,
        type: 'ppemp',
        cdeId,
        cliId
    }));
}

// fixme ULTIMATE : change for SAEMP the call
// export function employeToSmsType(employes: Saemp[], cdeId?: string, cliId?: string): SmsTypes[] {
//     return employes.map(e => (<SmsTypes>{
//         id: e.empId, ageId: e.ageId, nom: e.nom, natel: e.natel, tel: e.tel1, type: 'saemp', cdeId, cliId
//     }));
// }


export function contactToSmsType(contacts: Ppcliatt[], ageId: string | undefined): SmsTypes[] {
    return contacts.map(e => (<SmsTypes>{
        id: e.cliattId,
        nom: e.nom,
        natel: e.natel,
        tel: e.tel,
        type: 'ppcliatt',
        ageId: ageId
    }));
}
