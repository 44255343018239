import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BookingFile} from '@sam-base/models/accounting/booking-file.model';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class BookingService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public listBookingFiles(module: string): Observable<BookingFile[]> {
        return this._http.get<any>(this._baseUrl + `booking/${module}`);
    }

    public getBookingFileUrl(bookingFile: BookingFile): Observable<string> {
        return this._http.get(this._baseUrl + `booking/${bookingFile.module}/${bookingFile.bkFileId}/url`, {responseType: 'text'});
    }
}
