import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Sagen} from '@app/sam-base/models/salary/sagen';

@Component({
    selector: 'iw-tab-sagen-notes', templateUrl: './tab-sagen-notes.component.html'
})
export class TabSagenNotesComponent extends BasePartialFormComponent<Sagen> implements OnInit {
    public enableNotes = false;

    public get salId() {
        return this.getFormValue('salId') || '';
    }

    public ngOnInit() {
        if (this.getFormValue('status') !== 1) {
            this.enableNotes = true;
        }
    }
}
