import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Saisdef} from '@app/sam-base/models/salary/saisdef';
import {CantonISComponent} from '../components/canton-is/canton-is.component';

export const CANTON_IS: FormDescription<Saisdef> = {
    form: CantonISComponent, state: {
        ...buildDefaultState(Saisdef),
        diagTitle: 'canton_IS',
        diagShowSideMenu: false,
        diagShowStatus: true,
        width: 1200,
        editMode: 'read'
    }
};
