<form [formGroup]="formGroup"
      class="row no-padding">
    <div class="row">
        <div class="col-xs-8"
             style="height: 165px;">
            <iw-rest-grid #agenceConfigGrid
                          (selected)="getSelectedRow($event)"
                          (sortInfo)="setSortInfo($event)"
                          [autoFormClick]="false"
                          [defaultSelected]="true"
                          [hasFilter]="true"
                          [hasGridColumnMenu]="false"
                          [hasSortIcon]="true"
                          [queryStatements]="query"
                          [selectedData]="selectedData"
                          [sortDir]="sortPropDir"
                          [sortProp]="sortProp"
                          [type]="type"></iw-rest-grid>
        </div>

        <div class="row col-xs-2 padding-top-10">
            <iw-textfield #txtAge_id
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'code' | translate"
                          class="iw-input col-xs-12"
                          formControlName="ageId"
                          id="ageId"></iw-textfield>

            <iw-textfield #txtAge_prefix
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'prefixe' | translate"
                          class="iw-input col-xs-12"
                          formControlName="agePrefix"
                          id="agePrefix"></iw-textfield>

            <iw-textfield #txtAge_canton
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'canton' | translate"
                          class="iw-input col-xs-12"
                          formControlName="ageCanton"
                          id="ageCanton"></iw-textfield>
        </div>

        <div class="col-xs-2 flex flex-v">

            <iw-button (press)="createNewEntry()"
                       *ngIf="isReadonly"
                       [isDisabled]="!isReadonly"
                       [text]="'sidemenu_new' | translate"
                       class="margin-bottom-10"
                       id="new"
                       name="nouveau"></iw-button>

            <iw-button (press)="modifyEntry()"
                       *ngIf="isReadonly"
                       [isDisabled]="!isReadonly"
                       [text]="'sidemenu_edit' | translate"
                       id="modify"></iw-button>

            <iw-button (press)="saveEntry()"
                       *ngIf="!isReadonly"
                       [isDisabled]="isReadonly || !isFormValid"
                       [text]="'sidemenu_save' | translate"
                       class="margin-bottom-10"
                       id="save"></iw-button>
            <!--  This stays here if we ever want to delete entry -->
            <!-- <iw-button [text]="'sidemenu_delete' | translate"  *ngIf="isReadonly"
              [isDisabled]="!isReadonly || !selectedRow"
              (press)="removeEntry()"></iw-button> -->
            <iw-button (press)="cancelEditionMode()"
                       *ngIf="!isReadonly"
                       [text]="'sidemenu_cancel' | translate"></iw-button>
        </div>
    </div>


    <div class="row col-xs-10 no-padding">
        <iw-checkbox #chkActif
                     [isDisabled]="isReadonly"
                     [label]="'agenceActive' | translate"
                     class="iw-input col-xs-3"
                     formControlName="actif"></iw-checkbox>
        <iw-checkbox #chkBoOnly
                     [isDisabled]="isReadonly"
                     [label]="'back_office_uniquement' | translate"
                     class="iw-input col-xs-3"
                     formControlName="boonly"></iw-checkbox>
        <iw-checkbox #chkSmioActif
                     [isDisabled]="isReadonly"
                     [label]="'smio' | translate"
                     class="iw-input col-xs-3"
                     formControlName="smioactif"></iw-checkbox>

        <div class="col-xs-6 row no-padding">

            <iw-textfield #ageLib
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'libelle' | translate"
                          class="iw-input col-xs-12"
                          formControlName="ageLib"></iw-textfield>


            <iw-textfield #txtAge_adr1
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'adresse' | translate"
                          class="iw-input col-xs-12"
                          formControlName="ageAdr1"
                          id="ageAdr1"></iw-textfield>

            <iw-textfield #txtAge_adr2
                          [isDisabled]="isReadonly"
                          class="iw-input col-xs-12"
                          formControlName="ageAdr2"></iw-textfield>

            <iw-npa-lieu [formGroup]="formGroup"
                         [isDisabled]="isReadonly"
                         [labelNpa]="''"
                         [lieuFormControlName]="'ageLieu'"
                         [npaFormControlName]="'ageNpa'"
                         class="iw-input col-xs-12"></iw-npa-lieu>

        </div>

        <div class="col-xs-6 row">
            <iw-textfield #txtAge_tel
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'telephone' | translate"
                          class="iw-input col-xs-12 "
                          formControlName="ageTel"></iw-textfield>

            <iw-textfield #txtAge_fax
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'fax' | translate"
                          class="iw-input col-xs-12"
                          formControlName="ageFax"></iw-textfield>

            <iw-textfield #txtAge_email
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'email' | translate"
                          class="iw-input col-xs-12"
                          formControlName="ageEmail"
                          id="ageEmail"></iw-textfield>

        </div>

        <div class="col-xs-6 row no-padding">

            <iw-textfield #txtSMS_token
                          [(value)]="smsToken"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'smsToken' | translate"
                          class="iw-input col-xs-12"></iw-textfield>

            <iw-textfield #txtSMS_senderId
                          [(value)]="smsSenderId"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'smsSender' | translate"
                          class="iw-input col-xs-12"></iw-textfield>

        </div>
        <div class="col-xs-6 row  separator-left">
            <div class="col-xs-6">
                <div class="form-subtitle"
                     style="font-size:13px;"> {{ 'email_signature' | translate }}
                </div>
                <iw-button (press)="addEmailSignature() "
                           [isDisabled]="isReadonly"
                           [text]="(signature ? 'edit_signature' : 'addSignature') | translate"
                           class="padding-10 btn-block"></iw-button>
                <iw-button (press)="removeSignatureEmail() "
                           [isDisabled]="isReadonly || !signature"
                           [text]="'removeSignature' | translate"
                           class="padding-10 btn-block"></iw-button>

            </div>
            <div class="column col-xs-6">
                <div class="flex flex-center fixed-height">
                    <div *ngIf="!!signature"
                         class="iw-editor-wrapper">
                        <iw-editor [isDisabled]="true"
                                   [labelHeight]="'130px'"
                                   [value]="signature"></iw-editor>
                    </div>
                    <div *ngIf="!signature"
                         class="no-signature"> {{ 'no_signature' | translate }}
                    </div>
                </div>
            </div>
        </div>


        <iw-textfield #txtSMS_maxLength
                      [(value)]="smsMaxLenght"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'smsMaxLenght' | translate"
                      class="iw-input col-xs-4"></iw-textfield>

        <iw-smtpconfig-dropdown [isDisabled]="isReadonly"
                                [labelAlign]="'top'"
                                [label]="'smtp_config' | translate"
                                class="iw-input col-xs-4"
                                formControlName="smtpConfigId"></iw-smtpconfig-dropdown>
        <iw-textfield #txtBy_senderUser
                      [(value)]="ageByEmailSendUser"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'ageBySender' | translate"
                      class="iw-input col-xs-4"></iw-textfield>

        <iw-textfield #txtBy_bbc
                      [(value)]="ageByEmailBcc"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'ageByEmailBcc' | translate"
                      class="iw-input col-xs-3"></iw-textfield>

        <iw-textfield #txtBy_bci
                      [(value)]="ageByEmailBci"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'ageByEmailBci' | translate"
                      class="iw-input col-xs-3"></iw-textfield>

        <iw-textfield #txtRee_no
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'noRee' | translate"
                      [warningMessage]="isReadonly ? '' : reeValidationMessage"
                      class="iw-input col-xs-3"
                      formControlName="reeNo"></iw-textfield>
        <iw-textfield #txtAge_ofs
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'communeOfs' | translate"
                      [value]="ofsnr"
                      class="iw-input col-xs-3"></iw-textfield>
    </div>
    <div class="row col-xs-12">
        <iw-rest-grid #ageTimeGrid
                      (dataChanged)="onAgeTimeChange($event)"
                      (selected)="getAgetimeRow($event)"
                      [customColumns]="agetimeColumns"
                      [hasGridColumnMenu]="false"
                      [hasSortIcon]="false"
                      [queryStatements]="ageTimeQuery"
                      [type]="agetime"
                      class="col-xs-8 no-padding"
                      style="height: 70px;"></iw-rest-grid>

        <div class="col-xs-1 flex flex-between">
            <iw-button (press)="openNewAgetimeDialog()"
                       [isDisabled]="!selectedData || newEntryMode"
                       [tooltip]="newEntryMode ? 'agetime_save_first': 'sidemenu_new' | translate"
                       iconClass="fas fa-file"
                       style="padding-bottom: 6px"></iw-button>

            <iw-button (press)="changeAgeTime()"
                       [isDisabled]="!selectedAgetimeRow || newEntryMode"
                       [tooltip]="newEntryMode ? 'agetime_save_first': 'sidemenu_edit' | translate"
                       iconClass="fas fa-pencil-alt"
                       style="padding-bottom: 6px"></iw-button>

            <iw-button (press)="removeAgeTime()"
                       [isDisabled]="!selectedAgetimeRow || newEntryMode"
                       [tooltip]="newEntryMode ? 'agetime_save_first': 'sidemenu_delete' | translate"
                       iconClass="fas fa-trash"></iw-button>
        </div>
        <div class="col-xs-8">
            <small *ngIf="!ageTimeCount && !newEntryMode && selectedData"
                   class="iw-input col-xs-12 padding-left-0"
                   style="color: #ff0000; font-size:10px"> {{ 'agetime_required' | translate }} </small>
        </div>
    </div>
    <div class="row col-xs-2 no-padding">
        <iw-button #Closebutton
                   (press)="closeDialog()"
                   [text]="'fermer' | translate"
                   class="iw-button col-xs-12 flex-end"></iw-button>
    </div>
</form>
