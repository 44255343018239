<div class="flex flex-v flex-evenly flex-gap">
    <div class="row padding-top-25">
        <iw-textfield #s
                      [isDisabled]="true"
                      [value]="hasSal"
                      class="iw-input emphasis col-xs-6 no-padding"></iw-textfield>

        <iw-textfield #f
                      [isDisabled]="true"
                      [value]="hasFac"
                      class="iw-input emphasis col-xs-6 no-padding"></iw-textfield>
    </div>

    <iw-button #btnCalc
               (press)="calculateRaportData()"
               [text]="'calculer' | translate"
               *iwAccessGroups="rapportCalculateRoles"
               class="btn-block padding-top-25"
               id="btnCalc"></iw-button>

</div>
