import {Injectable, ViewChild} from '@angular/core';
import {IwSmartGridComponent} from '@app/sam-base/components/iw-smart-grid/iw-smart-grid.component';
import {GridProfile, IwEventOpenModalPayload} from '@app/sam-base/core';
import {ContextMenuEvent} from '@app/sam-base/core/context-menu/models';
import {FormEvents, IwEventHandler, IwEventHubService} from '@app/sam-base/core/events';
import {FormHandlerService} from '@app/sam-base/core/form-handler/form-handler.service';
import {pprapviewDefaultProfile} from '@app/sam-base/core/grid-profile/models/grid-profile-default';
import {isEntity, RestApiService} from '@app/sam-base/core/rest-api';
import {EntityDeleteService} from '@app/sam-base/core/services/entity-delete.service';
import {EntityNavigationService} from '@app/sam-base/core/services/entity-navigation.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {PpkwService} from '@app/sam-base/core/services/ppkw.service';
import {AppState, EntityDelete, getFormNavExtras, IwStoreService} from '@app/sam-base/core/store';
import {ToastService} from '@app/sam-base/core/toast';
import {clientToSmsType, employeToSmsType} from '@app/sam-base/helpers/sms-map';
import {LayoutEvents} from '@app/sam-base/layout/events/events.models';
import {AdvMatchingParams} from '@app/sam-base/models/adv-matching-params';
import {
    Ppaco,
    Ppagiview,
    Ppcde,
    Ppcdf,
    Ppcli,
    Ppclicdes,
    Ppclicdfs,
    Ppclimis,
    Ppemp,
    Ppind,
    Ppmis,
    Pprapview,
    Ppsuivi,
    Ppwxdocs,
    SmsTypes
} from '@app/sam-base/models/placement';
import {MissionContracts} from '@app/sam-base/models/placement/mission-contracts';
import {DocumentManagementService} from '@core/document-management/document-management.service';
import {CommunicationType} from '@core/mail/mail.models';
import {ParametersService} from '@core/services/parameters.service';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import {TranslateService} from '@ngx-translate/core';
import {PlacementEvent} from '@sam-base/models/placement/events.models';
import {lastValueFrom, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {PlacementSearchNavigation} from '../../../../core/placement-search/placement-search.service';
import {IwSendSMSModalComponent} from '../components/send-sms/iw-send-sms-modal/iw-send-sms-modal.component';
import {PlacementForm} from '../placement.forms';
import {MatchingService} from '../services/matching.service';
import {MissionService} from '../services/mission.service';

@Injectable({providedIn: 'root'})
export class PlacementEventsHandler {

    @ViewChild('detailgrid', {static: true}) public grid?: IwSmartGridComponent<Ppsuivi>;

    constructor(private readonly _store: IwStoreService, private readonly _events: IwEventHubService<string>,
                private readonly _docmanag: DocumentManagementService, private readonly _translate: TranslateService,
                private readonly _parameters: ParametersService, private readonly _formHandler: FormHandlerService<any>,
                private readonly _modalService: ModalService, private readonly _toastService: ToastService,
                private readonly _entityService: EntityNavigationService,
                private readonly _matchingService: MatchingService, private readonly _ppkwService: PpkwService,
                private readonly _gridNavigation: PlacementSearchNavigation,
                private readonly eventsHub: IwEventHubService<string>,
                private readonly _entityDeleteService: EntityDeleteService,
                private readonly missionService: MissionService, private readonly _restService: RestApiService) {
    }

    @IwEventHandler(PlacementEvent.CREATE_ABSCENSE)
    public openDialogPpabssai() {
        this.openDialogByName('ppabssai');
    }

    @IwEventHandler(PlacementEvent.SHOW_EMPLOYEE_PLANING)
    public openDialogPpempplan() {
        this.openDialogByName('ppempplan');
    }

    @IwEventHandler(PlacementEvent.SEND_EMAIL_CLIENT)
    public sendEmailClient(_: AppState, event: ContextMenuEvent<Ppcli>) {
        const ids: string[] = [];
        event?.selected.forEach(sel => {
            ids.push(sel.cliId || '');
        });
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.PPCLI_GENERIC,
            entityIds: ids
        });
    }

    @IwEventHandler(PlacementEvent.SEND_EMAIL_EMP)
    public sendEmailEmp(state: AppState, event: ContextMenuEvent<Ppemp>) {
        const nav = getFormNavExtras(state).sourceData;
        const ids: string[] = [];
        event?.selected.forEach(sel => {
            ids.push(sel.empId || '');
        });

        if (!nav) {
            this._modalService.showModal(MailComposerComponent, {
                communicationType: CommunicationType.PPEMP_GENERIC,
                entityIds: ids
            });
        } else {
            console.warn('Hmmm did not know how we could end up here. It supposed to send a mail but how ?')
            // this._modalService.showModal(Tt6mailComponent, {
            //     type: 'ppemp',
            //     entityIdList: ids,
            //     cdeId: nav['cdeId'],
            //     cliId: nav['cliId']
            // });
        }
    }

    @IwEventHandler(PlacementEvent.SEND_EMAIL_CONTRACT_CLIENT)
    public sendEmailContractClient(_: AppState, event: ContextMenuEvent<Ppclimis>) {
        const ids: string[] = [];
        event?.selected.forEach(sel => {
            ids.push(sel.misId || '');
        });
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.PPMIS_CL,
            entityIds: ids
        });
    }

    @IwEventHandler(PlacementEvent.SEND_EMAIL_CONTRACT_EMP)
    public sendEmailContractEmp(_: AppState, event: ContextMenuEvent<Ppemp>) {
        const ids: string[] = [];
        event?.selected.forEach(sel => {
            ids.push(sel.misId || '');
        });
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.PPMIS_CM,
            entityIds: ids
        });
    }

    @IwEventHandler(PlacementEvent.PPEMP_SEND_SMS) // eslint-disable-next-line complexity
    public async sendSmsPpempEvent(state: AppState, event: ContextMenuEvent<Ppemp>) {
        const nav = getFormNavExtras(state).sourceData;
        if (event.selected[0] && !event.selected[0].ageId) {
            event.selected[0].ageId = await lastValueFrom(this._parameters.getString('boAgeId'));
        }
        let data: SmsTypes[] = [];
        if (!nav) {
            data = employeToSmsType(event.selected);
        } else {
            data = employeToSmsType(event.selected, nav['cdeId'] ?? '', nav['cliId'] ?? '');
        }
        this._modalService.showModal(IwSendSMSModalComponent, data);
    }

    @IwEventHandler(PlacementEvent.PPMIS_EMP_SEND_SMS) // eslint-disable-next-line complexity
    public async sendSmsPpmisEmpEvent(_: AppState, event: ContextMenuEvent<Ppmis>) {
        if (!event.selected) {
            return [];
        }
        if (event.selected[0] && !event.selected[0].ageId) {
            event.selected[0].ageId = await lastValueFrom(this._parameters.getString('boAgeId'));
        }

        const empIds = event.selected.map(e => e.empId);
        const ppemps: Ppemp[] = [];

        // Iterate through each empId and fetch ppemp data sequentially
        for (let empId of empIds) {
            if (empId) {
                const ppemp = await lastValueFrom(this._restService.getEntityClient(Ppemp)
                    .getById(empId));
                ppemps.push(ppemp);
            }
        }
        let data: SmsTypes[] = [];
        data = employeToSmsType(ppemps, '', '');
        this._modalService.showModal(IwSendSMSModalComponent, data);
    }

    @IwEventHandler(PlacementEvent.PPMIS_CLI_SEND_SMS) // eslint-disable-next-line complexity
    public async sendSmsPpmisCliEvent(_: AppState, event: ContextMenuEvent<Ppmis>) {
        if (!event.selected) {
            return [];
        }

        if (event.selected[0] && !event.selected[0].ageId) {
            event.selected[0].ageId = await lastValueFrom(this._parameters.getString('boAgeId'));
        }

        const cliIds = event.selected.map(e => e.cliId);
        const ppclis: Ppcli[] = [];

        // Iterate through each cliId and fetch ppcli data sequentially
        for (let cliId of cliIds) {
            if (cliId) {
                const ppcli = await lastValueFrom(this._restService.getEntityClient(Ppcli)
                    .getById(cliId));
                ppclis.push(ppcli);
            }
        }
        let data: SmsTypes[] = [];
        data = clientToSmsType(ppclis);
        this._modalService.showModal(IwSendSMSModalComponent, data);
    }

    @IwEventHandler(PlacementEvent.PRINT_REPORT_EMP)
    public async printReportEmpEvent(_: AppState, event: ContextMenuEvent<Ppemp>) {
        this.eventsHub.emit<IwEventOpenModalPayload<Ppemp>>(FormEvents.openPrintModal, {
            report: 'ppemp',
            entityId: event.value.empId,
            entityName: event.extraData || '',
            entity: event.value
        });
    }

    @IwEventHandler(PlacementEvent.PRINT_REPORT_CLI)
    public async printReportCliEvent(_: AppState, event: ContextMenuEvent<Ppcli>) {
        this.eventsHub.emit<IwEventOpenModalPayload<Ppcli>>(FormEvents.openPrintModal, {
            report: 'ppcli',
            entityId: event.value.cliId,
            entityName: event.extraData || '',
            entity: event.value
        });
    }

    @IwEventHandler(PlacementEvent.PRINT_REPORT_MISCLI)
    public async printReportMisCliEvent(_: AppState, event: ContextMenuEvent<Ppclimis>) {
        this.eventsHub.emit<IwEventOpenModalPayload<Ppclimis>>(FormEvents.openPrintModal, {
            report: 'CC',
            entityId: event.value.misId,
            entityName: MissionContracts.mission,
            entity: event.value
        });
    }

    @IwEventHandler(PlacementEvent.PRINT_REPORT_MISEMP)
    public async printReportMisEmpEvent(_: AppState, event: ContextMenuEvent<Ppclimis>) {
        this.eventsHub.emit<IwEventOpenModalPayload<Ppclimis>>(FormEvents.openPrintModal, {
            report: 'CM',
            entityId: event.value.misId,
            entityName: MissionContracts.location,
            entity: event.value
        });
    }

    @IwEventHandler(PlacementEvent.DELETE_DOCUMENT)
    public deleteDocument(_: AppState, event: ContextMenuEvent<Ppwxdocs>) {
        if (event?.value?.wxdocId) {
            this._docmanag.deleteDocumentById(event.value.wxdocId)
                .pipe(catchError(() => of(false)))
                .subscribe(() => {
                    if (event.value.doccategory === 'EMP_PIC') {
                        this._events.emit('loadEmpProfilePicture', event.value.parentid);
                    }
                });
        }
    }

    @IwEventHandler(PlacementEvent.PPCLI_SEND_SMS)
    public async sendSmsPpcliEvent(_: AppState, event: ContextMenuEvent<Ppcli>) {
        if (event.selected[0] && !event.selected[0].ageId) {
            event.selected[0].ageId = await lastValueFrom(this._parameters.getString('boAgeId'));
        }
        const data = clientToSmsType(event.selected);
        this._modalService.showModal(IwSendSMSModalComponent, data);
    }

    @IwEventHandler(PlacementEvent.ACOMPTES)
    public openAcomptesList(_: AppState, event: ContextMenuEvent<Ppaco>) {
        const id = event.selected[0] && event.selected[0].empId;
        if (id) {
            this._gridNavigation.navigate({
                entity: Ppaco,
                baseFilters: {empId: id}
            });
        }
    }

    @IwEventHandler(PlacementEvent.PPACO_DELETE)
    public async deleteAcomptes(_: AppState, event: ContextMenuEvent<Ppaco>) {
        const eventSel = event.selected[0];
        const id = eventSel && eventSel.acoId;
        if (!id) {
            return;
        }

        const activePer = await lastValueFrom(this._parameters.getString('actSalId'));
        if (this.isInActivePeriod(activePer, eventSel)) {
            if (!(await this._entityDeleteService
                .openEntityDeleteDialog(eventSel))) {
                return;
            }
            this._store.dispatch(new EntityDelete(Ppaco, id));
        } else {
            const message = this._translate.instant('acompteErrorPeriodeAct');
            this._toastService.error(message);
        }
    }

    @IwEventHandler(PlacementEvent.INDEMNITES)
    public openIndemnitesList(_: AppState, event: ContextMenuEvent<Ppind>) {
        const id = event.selected[0] && event.selected[0].empId;
        if (id) {
            this._gridNavigation.navigate({
                entity: Ppind,
                baseFilters: {empId: id}
            });
        }
    }

    @IwEventHandler(PlacementEvent.ATTESTATION_GAIN_INTERMEDAIRE)
    public openAttestationGainIntermedaireList(_: AppState, event: ContextMenuEvent<Ppagiview>) {
        const id = event.selected[0] && event.selected[0].empId;
        if (id) {
            this._gridNavigation.navigate({
                entity: Ppagiview,
                baseFilters: {empId: id}
            });
        }
    }

    @IwEventHandler(PlacementEvent.CLIENT)
    public openClientForm(_: AppState, event: ContextMenuEvent<Pprapview>) {
        const id = event.selected[0] && event.selected[0].cliId;
        if (id) {
            this._events.emit(LayoutEvents.TOGGLE_SLIDE_PANEL);
            this._entityService.navigateToEntityForm(Ppcli, id);
        }
    }

    @IwEventHandler(PlacementEvent.EMPLOYE)
    public openEmployeForm(_: AppState, event: ContextMenuEvent<Pprapview>) {
        const id = event.selected[0] && event.selected[0].empId;
        if (id) {
            this._events.emit(LayoutEvents.TOGGLE_SLIDE_PANEL);
            this._entityService.navigateToEntityForm(Ppemp, id);
        }
    }

    @IwEventHandler(PlacementEvent.MISSION)
    public openMissionForm(_: AppState, event: ContextMenuEvent<Pprapview>) {
        const id = event.selected[0] && event.selected[0].misId;
        if (id) {
            this._events.emit(LayoutEvents.TOGGLE_SLIDE_PANEL);
            this._entityService.navigateToEntityForm(Ppclimis, id);
        }
    }

    @IwEventHandler(PlacementEvent.ACOMPTES_RAPID)
    public openAcomptesByRapIdList(_: AppState, event: ContextMenuEvent<Ppaco>) {
        const id = event.selected[0] && event.selected[0].rapId;
        if (id) {
            this._gridNavigation.navigate({
                entity: Ppaco,
                baseFilters: {rapId: id}
            });
        }
    }

    @IwEventHandler(PlacementEvent.PPCDE_LAUNCH_ADVSEARCH) // eslint-disable-next-line complexity
    public async openAdvancedSearch(_: AppState, event: ContextMenuEvent<Ppclicdes>) {
        const id = event.selected[0] && event.selected[0].cdeId;
        const profession = event.selected[0] && event.selected[0].qualif;

        if (!id) {
            return;
        }
        const ppcde = await lastValueFrom(this._restService.getEntityClient(Ppcde)
            .getById(id));

        const scheduleEvents = await lastValueFrom(this._matchingService.getScheduleEvent(id, 'ppcde'));

        if (!scheduleEvents.length) {
            this._toastService.info('no_schedule_event_info');
            return;
        }

        const scheduleEvent = scheduleEvents[0];
        const regions = ppcde.region !== '' ? ppcde.region?.split(';') : undefined;

        const ppkws = await lastValueFrom(this._ppkwService.getKeywords(id, 'ppcde'));

        const matchParams: AdvMatchingParams = {
            professions: profession ? profession : '',
            datefrom: scheduleEvent.dateStart,
            dateto: scheduleEvent.dateEnd,
            weekdays: scheduleEvent.weekday,
            hours: scheduleEvent.schedule,
            regions,
            keywords: ppkws ? ppkws : []
        };

        this._gridNavigation.navigate({
            entity: Ppemp,
            extras: {
                matchParams,
                sourceData: {
                    cdeId: id,
                    cliId: event.selected[0].cliId,
                    conId: event.selected[0].conId
                }
            }
        });
    }

    @IwEventHandler(PlacementEvent.PPCDF_LAUNCH_ADVSEARCH) // eslint-disable-next-line complexity
    public async openAdvancedSearchCdf(_: AppState, event: ContextMenuEvent<Ppclicdfs>) {
        const id = event.selected[0] && event.selected[0].cdfId;
        const profession = event.selected[0] && event.selected[0].qualif as string;

        if (!id) {
            return;
        }
        const ppcdf = await lastValueFrom(this._restService.getEntityClient(Ppcdf)
            .getById(id));

        const scheduleEvents = await lastValueFrom(this._matchingService.getScheduleEvent(id, 'ppcdf'));

        if (!scheduleEvents.length) {
            this._toastService.info('no_schedule_event_info');
            return;
        }

        const scheduleEvent = scheduleEvents[0];
        const regions = ppcdf.region !== '' ? ppcdf.region?.split(';') : undefined;

        const ppkws = await lastValueFrom(this._ppkwService.getKeywords(id, 'ppcdf'));

        const matchParams: AdvMatchingParams = {
            professions: profession ? profession : '',
            datefrom: scheduleEvent.dateStart,
            dateto: scheduleEvent.dateEnd,
            weekdays: scheduleEvent.weekday,
            hours: scheduleEvent.schedule,
            regions,
            keywords: ppkws ? ppkws : []
        };

        this._gridNavigation.navigate({
            entity: Ppemp,
            extras: {
                matchParams,
                sourceData: {
                    cdeId: id,
                    cliId: event.selected[0].cliId,
                    conId: event.selected[0].conId
                }
            }
        });
    }

    @IwEventHandler(PlacementEvent.INVOICE)
    public openRelatedInvoice(_: AppState, event: ContextMenuEvent<Pprapview>) {
        // TODO: backend implementation missing
    }

    @IwEventHandler(PlacementEvent.SALARY)
    public openRelatedSalary(_: AppState, event: ContextMenuEvent<Pprapview>) {
        // TODO: backend implementation missing
    }

    @IwEventHandler(PlacementEvent.PPINDSAID_UPDATE)
    public async openModifyIndemnite(_: AppState, event: ContextMenuEvent<Ppind>) {
        const eventSel = event.selected[0];
        const indId = event.selected[0] && event.selected[0].indId as string;
        if (!indId) {
            return;
        }

        const activePer = await lastValueFrom(this._parameters.getString('actSalId'));
        if (activePer && activePer === eventSel.salId) {
            this._formHandler.showFormDialog(PlacementForm.MisIndemniteUpdate, undefined, s => ({
                ...s,
                entityId: indId
            }));
        } else {
            const message = this._translate.instant('indErrorPeriodeAct');
            this._toastService.error(message);
        }
    }

    @IwEventHandler(PlacementEvent.PPSUI_UPDATE) // eslint-disable-next-line complexity
    public async openSuiviByEntity(_: AppState, event: ContextMenuEvent<Ppsuivi>) {
        if (event.extraData === 'ppcli') {
            this._formHandler.showFormDialog(PlacementForm.SuiviEdit, {
                cdeId: event.value.cdeId || '',
                cliId: event.value.cliId || '',
                empId: event.value.empId || '',
                misId: event.value.misId || '',
                suitype: 'C',
                action: 'PPCLIACTION'
            }, s => ({
                ...s,
                entityId: event.value.suiId || '',
                diagTitle: 'title_ppclisui'
            }));
        } else if (event.extraData === 'ppemp') {
            this._formHandler.showFormDialog(PlacementForm.SuiviEdit, {
                cdeId: event.value.cdeId || '',
                cliId: event.value.cliId || '',
                empId: event.value.empId || '',
                misId: event.value.misId || '',
                suitype: 'E',
                action: 'PPEMPACTION'
            }, s => ({
                ...s,
                entityId: event.value.suiId || '',
                diagTitle: 'title_ppclisui'
            }));
        } else if (event.extraData === 'ppclicdes') {
            this._formHandler.showFormDialog(PlacementForm.SuiviEdit, {
                cdeId: event.value.cdeId || '',
                cliId: event.value.cliId || '',
                empId: event.value.empId || '',
                misId: event.value.misId || '',
                suitype: 'T',
                action: 'CDEACTION'
            }, s => ({
                ...s,
                entityId: event.value.suiId || '',
                diagTitle: 'title_ppempsui'
            }));
        } else if (event.extraData === 'ppclimis') {
            this._formHandler.showFormDialog(PlacementForm.SuiviEdit, {
                cdeId: event.value.cdeId || '',
                cliId: event.value.cliId || '',
                empId: event.value.empId || '',
                misId: event.value.misId || '',
                suitype: 'M',
                action: 'MISACTION'
            }, s => ({
                ...s,
                entityId: event.value.suiId || '',
                diagTitle: 'title_ppmissui'
            }));
        }
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
    }

    @IwEventHandler(PlacementEvent.PPSUI_NEW) // eslint-disable-next-line complexity
    public async newSuiviByEntity(_: AppState, event: ContextMenuEvent<Ppsuivi>) {
        if (event.extraData === 'ppcli') {
            this._formHandler.showFormDialog(PlacementForm.SuiviNew, {
                cliId: event.value.cliId,
                cdeId: event.value.cdeId || '',
                empId: event.value.empId || '',
                misId: event.value.misId || '',
                suitype: 'C',
                action: 'PPCLIACTION'
            }, s => ({
                ...s,
                diagTitle: 'title_ppclisui'
            }));
        } else if (event.extraData === 'ppemp') {
            this._formHandler.showFormDialog(PlacementForm.SuiviNew, {
                empId: event.value.empId,
                cliId: event.value.cliId || '',
                cdeId: event.value.cdeId || '',
                misId: event.value.misId || '',
                suitype: 'E',
                action: 'PPEMPACTION'
            }, s => ({
                ...s,
                diagTitle: 'title_ppempsui'
            }));
        } else if (event.extraData === 'ppclicdes') {
            this._formHandler.showFormDialog(PlacementForm.SuiviNew, {
                cdeId: event.value.cdeId || '',
                cliId: event.value.cliId || '',
                empId: event.value.empId || '',
                misId: event.value.misId || '',
                suitype: 'T',
                action: 'CDEACTION'
            }, s => ({
                ...s,
                diagTitle: 'title_ppcdesui'
            }));
        } else if (event.extraData === 'ppclicdfs') {
            this._formHandler.showFormDialog(PlacementForm.SuiviNew, {
                cdfId: event.value.cdfId ?? '',
                cliId: event.value.cliId || '',
                empId: event.value.empId || '',
                misId: event.value.misId || '',
                suitype: 'T',
                action: 'CDEACTION'
            }, s => ({
                ...s,
                diagTitle: 'title_ppcdesui'
            }));
        } else if (event.extraData === 'ppclimis') {
            this._formHandler.showFormDialog(PlacementForm.SuiviNew, {
                misId: event.value.misId || '',
                cliId: event.value.cliId || '',
                empId: event.value.empId || '',
                cdeId: event.value.cdeId || '',
                suitype: 'M',
                action: 'MISACTION'
            }, s => ({
                ...s,
                diagTitle: 'title_ppmissui'
            }));
        }
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
    }

    @IwEventHandler(PlacementEvent.PPSUI_DELETE)
    public async deleteSuiviByEntity(_: AppState, event: ContextMenuEvent<Ppsuivi>) {
        if (!(await this._entityDeleteService
            .openEntityDeleteDialog(event.value))) {
            return;
        }
        this._store.dispatch(new EntityDelete(Ppsuivi, event.value.suiId || ''));
        if (this.grid) {
            await this.grid.refresh();
        }
    }

    @IwEventHandler(PlacementEvent.PPINDSAID_DELETE)
    public async deleteIndemnite(_: AppState, event: ContextMenuEvent<Ppind>) {
        const eventSel = event.selected[0];
        const id = eventSel && eventSel.indId;
        if (!id) {
            return;
        }

        const activePer = await lastValueFrom(this._parameters.getString('actSalId'));
        if (this.isInActivePeriod(activePer, eventSel)) {
            if (!(await this._entityDeleteService
                .openEntityDeleteDialog(eventSel))) {
                return;
            }
            this._store.dispatch(new EntityDelete(Ppind, id));
        } else {
            const message = this._translate.instant('indemniteErrorPeriodeAct');
            this._toastService.error(message);
        }
    }

    @IwEventHandler(PlacementEvent.PPMISS_NEW)
    public async openMission(state: AppState, event: ContextMenuEvent<Ppmis>) {
        const nav = getFormNavExtras(state).sourceData;
        if (!nav) {
            this.missionService.createMissionFromMisnew(event);
        } else if (nav && nav['cdeId']) {
            this.missionService.createMissionFromCde(nav, event);
        }
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
    }

    @IwEventHandler(PlacementEvent.PLAC_FIXE)
    public async openPlacFixe(state: AppState, event: ContextMenuEvent<Ppemp>) {
        const nav = getFormNavExtras(state).sourceData;
        if (!nav) {
            return;
        } else if (nav && nav['cdeId']) {
            const ppcdf = await lastValueFrom(this._restService
                .getEntityClient(Ppcdf)
                .getById(nav['cdeId']));
            this._formHandler
                .showFormDialog(PlacementForm.PpplfNew, {
                    empId: event.value.empId,
                    ppcdf
                });
        }
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
    }

    @IwEventHandler(PlacementEvent.PPEMP_OPEN_MISSION)
    public async openPpempMission(_: AppState, event: ContextMenuEvent<Ppemp>) {
        const id = event.selected[0] && event.selected[0].empId;
        if (id) {
            this._events.emit(LayoutEvents.TOGGLE_SLIDE_PANEL);
            this._gridNavigation.navigate({
                entity: Ppclimis,
                baseFilters: {empId: id}
            });
        }
    }

    @IwEventHandler(PlacementEvent.PPEMP_OPEN_RAPPORTS)
    public async openPpempRapports(_: AppState, event: ContextMenuEvent<Ppemp>) {
        const id = event.selected[0] && event.selected[0].empId;
        if (id) {
            this._events.emit(LayoutEvents.TOGGLE_SLIDE_PANEL);
            const gridProfile: GridProfile<Pprapview> = JSON.parse(JSON.stringify(new GridProfile(pprapviewDefaultProfile(<string>'ppemp'))));
            this._gridNavigation.navigate({
                entity: Pprapview,
                profile: gridProfile,
                baseFilters: {empId: id}
            });
        }
    }

    @IwEventHandler(PlacementEvent.PPCLI_OPEN_MISSION)
    public async openPcliMission(_: AppState, event: ContextMenuEvent<Ppcli>) {
        const id = event.selected[0] && event.selected[0].cliId;
        if (id) {
            this._events.emit(LayoutEvents.TOGGLE_SLIDE_PANEL);
            this._gridNavigation.navigate({
                entity: Ppclimis,
                baseFilters: {cliId: id}
            });
        }
    }

    @IwEventHandler(PlacementEvent.PPCLI_OPEN_PPCDE_GRID)
    public async openPpcdeGridFromPpcli(_: AppState, event: ContextMenuEvent<Ppcli>) {
        const id = event.selected[0] && event.selected[0].cliId;
        if (id) {
            this._events.emit(LayoutEvents.TOGGLE_SLIDE_PANEL);
            this._gridNavigation.navigate({
                entity: Ppclicdes,
                baseFilters: {cliId: id}
            });
        }
    }

    @IwEventHandler(PlacementEvent.PPCLI_OPEN_PPCDFS_GRID)
    public async openPpclicdfsGridFromPpcli(_: AppState, event: ContextMenuEvent<Ppcli>) {
        const id = event.selected[0] && event.selected[0].cliId;
        if (id) {
            this._events.emit(LayoutEvents.TOGGLE_SLIDE_PANEL);
            this._gridNavigation.navigate({
                entity: Ppclicdfs,
                baseFilters: {cliId: id}
            });
        }
    }

    @IwEventHandler(PlacementEvent.PPMIS_OPEN_RAPPORTS)
    public async openPpmisRapports(_: AppState, event: ContextMenuEvent<Ppmis>) {
        const id = event.selected[0] && event.selected[0].misId;
        if (id) {
            this._events.emit(LayoutEvents.TOGGLE_SLIDE_PANEL);
            const gridProfile: GridProfile<Pprapview> = JSON.parse(JSON.stringify(new GridProfile(pprapviewDefaultProfile(<string>'ppmis'))));
            this._gridNavigation.navigate({
                entity: Pprapview,
                profile: gridProfile,
                baseFilters: {misId: id},
            });
        }
    }

    @IwEventHandler(PlacementEvent.PPCLI_OPEN_RAPPORTS)
    public async openPcliRapports(_: AppState, event: ContextMenuEvent<Ppcli>) {
        const id = event.selected[0] && event.selected[0].cliId;
        if (id) {
            this._events.emit(LayoutEvents.TOGGLE_SLIDE_PANEL);
            const gridProfile: GridProfile<Pprapview> = JSON.parse(JSON.stringify(new GridProfile(pprapviewDefaultProfile(<string>'ppcli'))));
            this._gridNavigation.navigate({
                entity: Pprapview,
                profile: gridProfile,
                baseFilters: {cliId: id}
            });
        }
    }

    private openDialogByName(name: string) {
        this._events.emit(LayoutEvents.TOGGLE_SLIDE_PANEL);
        this._formHandler.showFormDialog(PlacementForm.Client);
    }

    private isInActivePeriod(activePer: string, eventSel: unknown) {
        if (isEntity(Ppaco, eventSel) || isEntity(Ppind, eventSel)) {
            return activePer && activePer === eventSel.salId;
        }
        return false;
    }
}
