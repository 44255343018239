import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Ppcliatt} from '@sam-base/models/placement';
import {Observable} from 'rxjs';

import {environment} from '../../../../../environments/environment';

@Injectable()
export class ClientService {
    constructor(public _http: HttpClient) {
    }

    public updateGecli(cliId: string): Observable<string> {

        return this._http.put(environment.backendURL + `gecli/status/${cliId}/updategecli`, {}, {responseType: 'text'});
    }

    /**
     * Calls the backend to delete the selected cliadr and cascade those changes
     * to ppcli and ppmis, if the deleted cliadr is the prefered one and no other
     * cliadr exists for the client the values on ppcli and ppmis will be blank,
     * on the other hand if the client has a secundary address that cliadr will
     * be the new default address for the client.
     *
     * @method deleteCliadrAndCascadeChanges
     * @param cliadrId the id of the cliadr to be deleted
     *
     */
    public deleteCliadrAndCascadeChanges(cliadrId: string): Observable<void> {
        return this._http.get<void>(environment.backendURL + `ppcliadr/delete/${cliadrId}`);
    }

    public getPpcliAtt(cliId: string, onlyActive = true): Observable<Ppcliatt[]> {
        return this._http.get <Ppcliatt[]>(
            environment.backendURL + `gecli/${cliId}/contacts?onlyActive=${onlyActive}`);
    }
}
