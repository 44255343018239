import { HttpClient } from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {MessageFormRefreshService} from '@app/sam-base/components/subjects/message-service-subject.service';
import {FormHandlerService, RestQueryOperation, RestQueryParam} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {ToastService} from '@app/sam-base/core/toast';
import {FormKeys, RowClickEvent} from '@app/sam-base/models';
import {IwGridColumn} from '@app/sam-base/models/components/grid-column.model';

import {AdminForm} from '@modules/sam-main/admin/admin.forms';
import {SwissdecService} from '@modules/sam-main/admin/services/swissdec-service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {Sasx5dec} from '@sam-base/models/admin/sasx5dec.model';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxStatus} from '@sam-base/models/admin/sx-status.enum';
import {Subject, takeUntil} from 'rxjs';

@Component({
    templateUrl: './swissdec-table.component.html'
})
export class SwissdecTableComponent extends BaseStoreFormComponent<Sasx5dec> implements OnInit, OnDestroy {
    public libelle?: string;


    public columns = this.getMismdlColumns();
    public isLoading = false;

    public tableData: Sasx5dec[] = [];
    public query: RestQueryParam<Sasx5dec, string>[] = [];
    protected readonly Sasx5institution = Sasx5institution;
    private subscriptions = new Subject();
    private selectedDeclarations: Sasx5dec[] = [];

    constructor(store: IwStoreService, _restService: RestApiService, private readonly _http: HttpClient,
                private _translate: TranslateService, private _toastService: ToastService,
                private readonly _formHandler: FormHandlerService<AdminForm>,
                private readonly messageService: MessageFormRefreshService,
                private readonly swissdecService: SwissdecService) {
        super(store);
    }

    public ngOnInit() {
        this.getSwissdecDeclarations();
        this.messageService.getMessage()
            .pipe(takeUntil(this.subscriptions))
            .subscribe(message => {
                if (message && 'sasx5dec.table' === message) {
                    this.getSwissdecDeclarations();
                }
            });
        this.initDeclarationQuery();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }


    public openCreate() {
        this.createNewDeclaration();
    }

    public onRowDoubleClick(event: RowClickEvent<Sasx5dec>) {
        this._formHandler.showFormDialog(AdminForm.SwissdecDeclaration, event, s => ({
            ...s,
            entityId: event.row.id || '',
            diagModal: true,
            diagTitle: 'title_declaration_form',
            width: 900,
            height: 900
        }));
    }

    public getSwissdecDeclarations() {
        this.swissdecService.getSasx5decAvailable()
            .subscribe((data: Sasx5dec[]) => {
                this.tableData = data;
            });
    }

    public createMock() {
        return this._http.post<Sasx5dec>(environment.backendURL + 'swissdec/createMockForSwissDecSalaryDeclaration', {})
            .subscribe(sasx5dec => this.getSwissdecDeclarations());
    }

    // eslint-disable-next-line complexity
    public onMergeClick() {
        // If selected declaration is less than 2 then return
        if (!this.selectedDeclarations || this.selectedDeclarations.length < 2) {
            return;
        }
        // Check if the selected declarations are all of status INIT
        // If not, notify the user with a toast
        const decInInitStatus = this.selectedDeclarations
            .filter(sasx5dec => sasx5dec.status === SxStatus.INIT);
        if (decInInitStatus.length !== this.selectedDeclarations.length) {
            this._toastService.warning('swissdec.merge.warning.status');
            return;
        }

        // Check if the selected declarations are all the same type
        // If not, notify the user with a toast
        const types = this.selectedDeclarations.map(sasx5dec => sasx5dec.sxType);
        const uniqueTypes = [...new Set(types)];
        if (uniqueTypes.length > 1) {
            this._toastService.warning('swissdec.merge.warning.type');
            return;
        }

        // Check if the selected declarations are all in the same year
        // If not, notify the user with a toast
        const years = this.selectedDeclarations.map(sasx5dec => sasx5dec.annee);
        const uniqueYears = [...new Set(years)];
        if (uniqueYears.length > 1) {
            this._toastService.warning('swissdec.merge.warning.year');
            return;
        }

        // Check if the selected declarations are all different gestion
        // If not, notify the user with a toast
        const gestions = this.selectedDeclarations.map(sasx5dec => sasx5dec.gestion);
        const uniqueGestions = [...new Set(gestions)];
        if (uniqueGestions.length !== this.selectedDeclarations.length) {
            this._toastService.warning('swissdec.merge.warning.gestion');
            return;
        }

        this.swissdecService
            .mergeDeclarations(this.selectedDeclarations.map(sasx5dec => sasx5dec.id || ''))
            .subscribe(dec => {
            });

    }

    public onSelectedChange($event: Sasx5dec[]) {
        this.selectedDeclarations = $event;
    }

    protected getFormControlNames(): FormKeys<Sasx5dec> {
        return ['id',
            'status',
            'sxType',
            'annee',
            'salId',
            'sxMode',
            'sxJobkey',
            'sxDeclid',
            'declrespid',
            'xmlReceived',
            'xmlSent'];
    }

    private initDeclarationQuery() {
        this.query = [{
            prop: 'status',
            operation: RestQueryOperation.NotIn,
            value: 'MERGED'
        }];
    }

    private getMismdlColumns(): IwGridColumn<Sasx5dec>[] {
        // TODO continue once I understood how this used compared to @IwColumn
        return [{
            prop: 'salId',
            name: this._translate.instant('salId'),
            index: 0,
            width: 25
        },
            {
                prop: 'gestion',
                name: this._translate.instant('gestion'),
                index: 1
            },
            {
                prop: 'sxType',
                name: this._translate.instant('sxType'),
                index: 2
            },
            {
                prop: 'sxMonthlyType',
                name: this._translate.instant('sxMonthlyType'),
                index: 3
            },
            {
                prop: 'sxYearlyType',
                name: this._translate.instant('sxYearlyType'),
                index: 4
            },
            {
                prop: 'status',
                name: this._translate.instant('status'),
                index: 5
            },
            {
                prop: 'sxJobkey',
                name: this._translate.instant('sxJobKey'),
                index: 6
            }];
    }

    private createNewDeclaration() {
        this._formHandler.showFormDialog(AdminForm.SwissdecNew, undefined, s => ({
            ...s,
            diagModal: false,
            diagTitle: 'title_swissdec_new_declaration_form',
            width: 300,
            height: 400
        }));
    }
}
