<form [formGroup]="formGroup"
      class="row">
    <div class="row">
        <div class="row space-form col-xs-12">
            <iw-textfield #ctnSelEmp
                          [label]="'description' | translate"
                          class="iw-input col-xs-12"
                          formControlName="libelle"></iw-textfield>
            <iw-textfield (valueChange)="calcTotal($event)"
                          [labelAlign]="'top'"
                          [label]="'nqte' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-2 padding-top-5 text-align-right"
                          formControlName="nqte"
                          type="number"></iw-textfield>
            <iw-textfield [labelAlign]="'top'"
                          [label]="'unite' | translate"
                          [maxlength]="4"
                          [size]="'small'"
                          class="iw-input col-xs-1 padding-top-5"
                          formControlName="unite"></iw-textfield>
            <iw-textfield (valueChange)="calcTotal($event)"
                          [labelAlign]="'top'"
                          [label]="'prix' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-2 padding-top-5 text-align-right"
                          formControlName="prixUnite"
                          type="number"></iw-textfield>
            <iw-textfield [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'montant' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-2 padding-top-5 text-align-right"
                          formControlName="montant"
                          type="number"></iw-textfield>
            <iw-textfield [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="' ' | translate"
                          [size]="'small'"
                          [value]="tvaInclus"
                          class="iw-input col-xs-2 padding-top-5"></iw-textfield>
            <iw-checkbox #chk05
                         (valueChange)="calcTotal($event)"
                         [labelAlign]="'top'"
                         [label]="'5_cents' | translate"
                         class="iw-input col-xs-2 center-checkbox"></iw-checkbox>

            <iw-textfield [labelAlign]="'top'"
                          [label]="'rapport' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-3 padding-top-5"
                          formControlName="custom1"></iw-textfield>
            <iw-textfield [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'se' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-1 padding-top-5"
                          formControlName="custom2"></iw-textfield>
            <iw-textfield [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'mission' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-3 padding-top-5"
                          formControlName="custom3"></iw-textfield>
            <iw-textfield [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'chantier' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-2 padding-top-5"
                          formControlName="custom4"></iw-textfield>
            <iw-textfield [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'tag' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-2 padding-top-5"
                          formControlName="custom5"></iw-textfield>

            <iw-secteur-dropdown #cboSalMode
                                 [label]="'sector' | translate"
                                 class="col-xs-3 padding-bottom-5"
                                 formControlName="secId"
                                 labelAlign="top"></iw-secteur-dropdown>
            <iw-conseiller-dropdown #cboCon_id
                                    [labelAlign]="'top'"
                                    [label]="'conseiller' | translate"
                                    class="iw-combobox col-xs-3"
                                    formControlName="conId"></iw-conseiller-dropdown>
            <iw-conseiller-dropdown #cboCon_id
                                    [labelAlign]="'top'"
                                    [label]="'conseiller2' | translate"
                                    class="iw-combobox col-xs-3"
                                    formControlName="conId2"></iw-conseiller-dropdown>
            <iw-textfield [labelAlign]="'top'"
                          [label]="'%' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-2"
                          formControlName="conId2Pc"></iw-textfield>

            <iw-textfield-compte [forceSelection]="true"
                                 [labelAlign]="'top'"
                                 [label]="'compte' | translate"
                                 [size]="'small'"
                                 class="iw-input col-xs-3 padding-top-5"
                                 formControlName="compte"></iw-textfield-compte>
            <div class="col-xs-9"></div>
        </div>
        <div class="row space-form  col-xs-12 bottom-xs between-xs">
            <div class="col-xs-8">
                <iw-checkbox #chk05
                             [(value)]="canTexte"
                             [labelAlign]="'top'"
                             [label]="'texte_complementaire' | translate"
                             class="iw-input col-xs-12"></iw-checkbox>

                <iw-textarea #edtAdresse
                             [height]="200"
                             [isDisabled]="!canTexte"
                             class="iw-textarea col-xs-6 padding-top-10"
                             formControlName="texte"></iw-textarea>
            </div>
            <div class="row bottom-xs around-xs end-xs col-xs">
                <iw-button #btnClose
                           (press)="closeDialog()"
                           [text]="'Annuler' | translate"
                           class="iw-button"></iw-button>
                <iw-button #btnClose
                           (press)="saveChanges()"
                           [isDisabled]="!isValid"
                           [text]="'OK'"
                           class="iw-button"></iw-button>
            </div>
        </div>
    </div>


</form>
