<form [formGroup]="formGroup" class="row"></form>

<iw-tab [(selectedTab)]="selectedTab" [invalidTabs]="invalidTabList" [tabs]="tabList">
    <iw-tab-sapar-info-enterprise *ngIf="selectedTab === tabList[0]" [formGroup]="formGroup"
                                  [isReadonly]="!isWriteMode"></iw-tab-sapar-info-enterprise>
    <iw-tab-sapar-general *ngIf="selectedTab === tabList[1]" [formGroup]="formGroup"
                          [isReadonly]="!isWriteMode"></iw-tab-sapar-general>
    <iw-tab-sapar-genres-de-salaire *ngIf="selectedTab === tabList[2]" [formGroup]="formGroup"
                                    [isReadonly]="!isWriteMode" [uuid]="uuid"></iw-tab-sapar-genres-de-salaire>
    <iw-tab-sapar-interface *ngIf="selectedTab === tabList[3]" [formGroup]="formGroup"
                            [isReadonly]="!isWriteMode"></iw-tab-sapar-interface>
    <iw-tab-sapar-formats *ngIf="selectedTab === tabList[4]" [formGroup]="formGroup"
                          [isReadonly]="!isWriteMode"></iw-tab-sapar-formats>
    <iw-tab-sapar-paiment *ngIf="selectedTab === tabList[5]" [formGroup]="formGroup"
                          [isReadonly]="!isWriteMode"></iw-tab-sapar-paiment>
    <iw-tab-sapar-horaires *ngIf="selectedTab === tabList[6]" [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"></iw-tab-sapar-horaires>
    <iw-tab-sapar-options *ngIf="selectedTab === tabList[7]" [formGroup]="formGroup"
                          [isReadonly]="!isWriteMode"></iw-tab-sapar-options>
    <iw-tab-sapar-texts *ngIf="selectedTab === tabList[8]" [formGroup]="formGroup"
                        [isReadonly]="!isWriteMode"></iw-tab-sapar-texts>

    <iw-tab-sapar-fiduciaire *ngIf="selectedTab === tabList[9]" [formGroup]="formGroup"
                             [isReadonly]="!isWriteMode"></iw-tab-sapar-fiduciaire>

    <iw-tab-sapar-swissdec *ngIf="selectedTab === tabList[10]" [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"></iw-tab-sapar-swissdec>

    <form [formGroup]="formGroup" class="row"></form>
</iw-tab>
