import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Ppagi} from '@app/sam-base/models/placement';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const aginterTabsFormControls: TabFormControl<Ppagi>[] = [{
    formControls: ['lactnogo']
}, {
    title: '1-4',
    formControls: ['agiId', 'empId', 'dateagi', 'salId', 'profession', 'jwithraps', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7', 'h8', 'h9', 'h10', 'h11', 'h12', 'h13', 'h14', 'h15', 'h16', 'h17', 'h18', 'h19', 'h20', 'h21', 'h22', 'h23', 'h24', 'h25', 'h26', 'h27', 'h28', 'h29', 'h30', 'h31', 'cdureehebd', 'cdurhebdn', 'ldureehebd']
}, {
    title: '5-7', formControls: ['cconvcoll', 'cplusdheu', 'crefusplus', 'lconvcoll', 'lplusdheu']
}, {
    title: '8-10',
    formControls: ['indEq', 'indMan', 'indOth', 'salH', 'lforceavs', 'nbsalh', 'salavs', 'salhormode', 'salavsforc', 'salM', 'totheu', 'totheuman', 'totheurap', 'indemnites']
}, {
    title: '11-14',
    formControls: ['lsoumislpp', 'cnolppwhy', 'caisselpp', 'caissecomp', 'lallocdem', 'lallocoui', 'allenfnb', 'allenfpx', 'allfornb', 'allforpx', 'allnb', 'allpx']
}, {
    title: '15-18',
    formControls: ['lactgoond', 'dactgoond', 'cactnogoq', 'cactnogow', 'cactnogom', 'revactind', 'lactgoon', 'cactnogop']
}, {
    title: 'remarquesAutre', formControls: ['remarques', 'caissecho', 'ageId']
}];
