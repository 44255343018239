export enum Ded_Code_AVS {
    NonSoumis = 1, Soumis = 2, Rentier = 3
}

export enum Ded_Code_AC {
    NonSoumis = 1, Soumis = 2
}

export enum Ded_Code_IMP {
    NonSoumis = 1, Soumis = 2
}

export enum Ded_Code_LAA {
    NonAssure = 1, AP = 2, AP2 = 3, AP3 = 4
}

export enum Ded_Code_LPP {
    NonSoumis = 1, Soumis = 2, SoumisSansDeduction = 3, SoumisAvecDeduction = 4
}

export enum Ded_Code_IJM {
    NonSoumis = 1, Soumis = 2, SoumisSansDeduction = 3, SoumisSelonMission = 4
}

export enum Other {
    NonSoumis = 1, Soumis = 2, SoumisSansDeduction = 3
}

