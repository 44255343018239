// eslint-disable-next-line max-classes-per-file
import {AfterContentInit, Component, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core';
import {FormKeys} from '@app/sam-base/models';
import {Sapar} from '@app/sam-base/models/salary';
import {Sancsmot} from '@app/sam-base/models/salary/sancsmot';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';
import {CsMot, CsMot1201} from './dialog-avancee.enum';

@Component({
    selector: 'iw-dialog-avancee', templateUrl: './dialog-avancee.component.html'
})
export class DialogAvanceeComponent extends BaseStoreFormComponent<Sancsmot> implements OnInit, AfterContentInit {
    public sapar?: Sapar;
    public checkboxValues?: DialogAvanceeComponent.CheckboxValues;

    public csMotOptions = CsMot;
    public csMot1201Options = CsMot1201;

    public csActive = false;
    public csActive3 = false;

    constructor(private readonly parameterService: ParametersService, private readonly _translate: TranslateService, public readonly store: IwStoreService) {
        super(store);
        this.checkboxValues = new DialogAvanceeComponent.CheckboxValues();
    }

    public get currentYear() {
        return this.sapar !== undefined ? this.sapar.annee : '';
    }

    public get selectedEmp(): Sancsmot | undefined {
        return this.getData('csEmpId');
    }

    private get checkboxControlNames(): Array<keyof Sancsmot> {
        return ['csMot1', 'csMot2', 'csMot3', 'csMot4', 'csMot5', 'csMot6', 'csMot7', 'csMot8', 'csMot9', 'csMot10', 'csMot110', 'csMot120'];
    }

    public ngAfterContentInit(): void {
        setTimeout(() => this.fillCheckboxes(this.checkboxControlNames), 100);
    }

    public ngOnInit(): void {
        this.parameterService
            .getCurrentSapar()
            .pipe(take(1))
            .subscribe((resp) => (this.sapar = resp));

        this.setFormValue('csEmpid', this.selectedEmp);
    }

    public changeCs120Options(event: string) {
        if (!event) {
            this.csActive = false;
            this.csActive3 = false;
        } else if (event === 'prealableSepare') {
            this.csActive = true;
            this.csActive3 = true;
        } else {
            this.csActive = true;
            this.csActive3 = false;
        }
    }

    public csMot120Disabled(): boolean | undefined {
        return this.isReadonly || !(this.getFormValue('csMot120') === '1');
    }

    public customValue(id: keyof Sancsmot, event: boolean): void {
        if (event) {
            this.setFormValue(id, '1');
        } else {
            this.setFormValue(id, '0');
        }
    }

    public fillCheckboxes(controls: Array<keyof Sancsmot>) {
        controls.forEach((control) => {
            const formValue = this.getFormValue(control);

            if (formValue === 1) {
                this.checkboxValues?.setCheckboxValues(control.toString(), true);
            } else {
                this.checkboxValues?.setCheckboxValues(control.toString(), false);
            }
        });
    }

    public getCheckboxValue(id: string) {
        return this.checkboxValues?.getCheckboxValue(id);
    }

    protected getFormControlNames(): FormKeys<Sancsmot> {
        return ['csEmpid', 'csMot1', 'csMot11', 'csMot12', 'csMot2', 'csMot3', 'csMot31', 'csMot32', 'csMot4', 'csMot41', 'csMot42', 'csMot5', 'csMot51', 'csMot52', 'csMot53', 'csMot6', 'csMot61', 'csMot7', 'csMot71', 'csMot8', 'csMot81', 'csMot9', 'csMot10', 'csYear', 'csMot110', 'csMot111', 'csMot112', 'csMot120', 'csMot1201', 'csMot1201Rate', 'csMot1201CState'];
    }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DialogAvanceeComponent {
    export class CheckboxValues {
        public csMot1 = false;
        public csMot2 = false;
        public csMot3 = false;
        public csMot4 = false;
        public csMot5 = false;
        public csMot6 = false;
        public csMot7 = false;
        public csMot8 = false;
        public csMot9 = false;
        public csMot10 = false;
        public csMot110 = false;
        public csMot120 = false;

        // eslint-disable-next-line complexity
        public getCheckboxValue(key: string) {
            if (key === 'csMot1') {
                return this.csMot1;
            }
            if (key === 'csMot2') {
                return this.csMot2;
            }
            if (key === 'csMot3') {
                return this.csMot3;
            }
            if (key === 'csMot4') {
                return this.csMot4;
            }
            if (key === 'csMot5') {
                return this.csMot5;
            }
            if (key === 'csMot6') {
                return this.csMot6;
            }
            if (key === 'csMot7') {
                return this.csMot7;
            }
            if (key === 'csMot8') {
                return this.csMot8;
            }
            if (key === 'csMot9') {
                return this.csMot9;
            }
            if (key === 'csMot10') {
                return this.csMot10;
            }
            if (key === 'csMot110') {
                return this.csMot110;
            }
            if (key === 'csMot120') {
                return this.csMot120;
            }
        }

        // eslint-disable-next-line complexity
        public setCheckboxValues(key: string, value: boolean) {
            if (key === 'csMot1') {
                this.csMot1 = value;
            }
            if (key === 'csMot2') {
                this.csMot2 = value;
            }
            if (key === 'csMot3') {
                this.csMot3 = value;
            }
            if (key === 'csMot4') {
                this.csMot4 = value;
            }
            if (key === 'csMot5') {
                this.csMot5 = value;
            }
            if (key === 'csMot6') {
                this.csMot6 = value;
            }
            if (key === 'csMot7') {
                this.csMot7 = value;
            }
            if (key === 'csMot8') {
                this.csMot8 = value;
            }
            if (key === 'csMot9') {
                this.csMot9 = value;
            }
            if (key === 'csMot10') {
                this.csMot10 = value;
            }
            if (key === 'csMot110') {
                this.csMot110 = value;
            }
            if (key === 'csMot120') {
                this.csMot120 = value;
            }
        }
    }
}

