import {Component, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {EntityCreate, EntityUpdate, IwEventHubService, IwStoreService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient, RestQueryParam} from '@app/sam-base/core/rest-api';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {ContactNcs} from '@app/sam-base/models/salary/contact-ncs.model';

@Component({templateUrl: './contact-ncs.component.html'})
export class ContactNCSComponent extends BaseStoreFormComponent<ContactNcs> implements OnInit {

    public query: RestQueryParam<ContactNcs, string>[] = [];
    private readonly _restContactNCSClient: RestEntityClient<ContactNcs>;

    constructor(store: IwStoreService, private _restService: RestApiService, private readonly _events: IwEventHubService<string>) {
        super(store);

        this._restContactNCSClient = this._restService.getEntityClient(ContactNcs);
    }

    public get id(): string {
        return this.getData('id', true) || '';
    }

    public get entryMode(): 'add' | 'edit' {
        return this.getData('entryMode', true) || 'edit';
    }

    public get contactNCS(): ContactNcs | undefined {
        return this.getData('contactNCS', true) || undefined;
    }

    public ngOnInit() {
        if (this.entryMode === 'edit' && !!this.contactNCS) {
            this.fillFormData(this.contactNCS);
        }
    }


    public save() {
        const formData = this.getFormData();
        const updatedData = this._restContactNCSClient
            .construct({...{id: this.contactNCS?.id}, ...formData});

        if (this.entryMode === 'add') {
            this._store.dispatch(new EntityCreate(updatedData));
        } else {
            this._store.dispatch(new EntityUpdate(updatedData));
        }

        this._events.emit('contactNCS_updated');
        this.destroyForm();
    }

    public destroyForm() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    protected getFormControlNames(): FormKeys<ContactNcs> {
        return ['department', 'address', 'npa', 'lieu', 'phone', 'fullName'];
    }

}
