<div [formGroup]="formGroup"
     class="row">
    <div class="row col-xs-14">
        <div class="row space-form col-xs-12">
            <iw-dropdown
                    (valueChange)="loadLookupTable($event)"
                    [(value)]="selectedLookupType"
                    [labelAlign]="'left'"
                    [label]="'Type de lookups'"
                    [options]="lookupOptions"
                    class="col-xs-10 padding-top-15"></iw-dropdown>

            <iw-table #lookupGrid
                      (selectedChange)="selectedChanged($event)"
                      [columns]="tableColumns"
                      [data]="tableData"
                      [defaultSelected]="true"
                      [isFilterEnable]="false"
                      [isGridColumnMenuEnable]="false"
                      [isSortIconEnable]="false"
                      class="col-xs-10 padding-top-15"
                      style="height: 180px"></iw-table>
            <div class="space-form col-xs-2">
                <iw-button (press)="newEntry()"
                           [isDisabled]="isEditing || isNew"
                           [text]="'Nouveau' | translate"
                           class="iw-button padding-top-10"></iw-button>

                <iw-button #btnModif
                           (press)="editEntry()"
                           *ngIf="!isEditing"
                           [isDisabled]="!isSelected"
                           [text]="'sidemenu_edit' | translate"
                           class="iw-button padding-top-10"></iw-button>
                <iw-button (press)="saveEntry()"
                           *ngIf="isEditing"
                           [isDisabled]="!formGroup.valid"
                           [text]="'enregistrer' | translate"
                           class="iw-button padding-top-10"></iw-button>

                <iw-button (press)="deleteEntry()"
                           *ngIf="!isEditing"
                           [isDisabled]="!isSelected"
                           [text]="'Supprimer'"
                           class="iw-button padding-top-10"></iw-button>

                <iw-button #btnCancel
                           (press)="cancelEditionMode()"
                           *ngIf="isEditing"
                           [text]="'sidemenu_cancel' | translate"
                           class="iw-button btn-block padding-bottom-10"></iw-button>

                <iw-button (press)="closeDialog()"
                           [text]="'fermer' | translate"
                           class="iw-button padding-top-10"></iw-button>


            </div>
        </div>
        <div class="row space-form col-xs-12">
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'libelle' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-8 padding-top-5"
                          formControlName="cvalue"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'cid' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-2 padding-top-5"
                          formControlName="cid"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'left'"
                          [label]="'combo_francais'"
                          [size]="'small'"
                          class="iw-input col-xs-10 padding-top-5"
                          formControlName="cvalue1"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'left'"
                          [label]="'english'"
                          [size]="'small'"
                          class="iw-input col-xs-10 padding-top-5"
                          formControlName="cvalue2"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'left'"
                          [label]="'allemand' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-10 padding-top-5"
                          formControlName="cvalue3"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'left'"
                          [label]="'italien' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-10 padding-top-5"
                          formControlName="cvalue4"></iw-textfield>
        </div>
    </div>
</div>