import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppemp} from '@app/sam-base/models/placement';

import {EmployeeComponent} from '../components';
import {EmployeSideMenuComponent} from '../components/employee/employe-side-menu.component';

export const PPEMP_DEFAULT_FORM: FormDescription<Ppemp> = {
    form: EmployeeComponent, sideMenu: EmployeSideMenuComponent, state: {
        ...buildDefaultState(Ppemp),
        diagTitle: 'ppemp_form_title',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showPrint: true, showDelete: true
        },
        useNavigation: true,
        patch: false,
        width: 1000
    }
};
