import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {IwStoreService} from '@app/sam-base/core/store';
import * as globalActions from '@app/sam-base/core/store/actions/global-form.actions';
import {ComboboxItem, FormKeys, IwGridColumn} from '@app/sam-base/models';
import {Ppaco, Ppemp, Ppper} from '@app/sam-base/models/placement';
import {ParametersService} from '@core/services/parameters.service';


import {Actions, ofType} from '@ngrx/effects';
import {EntityNavigationService, HeaderToolbarService} from '@sam-base/core';
import {SaesService} from '@sam-base/core/services/saes.service';
import {SapaimodeService} from '@sam-base/core/services/sapaimode.service';
import {Sapaimode} from '@sam-base/models/salary/sapaimode';
import {Subject} from 'rxjs/internal/Subject';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {EntitiesToUpdateEnum, EntityUpdateService} from '../../../services/entity-update.service';
import {PayacoService} from '../../../services/payaco.service';
import {Acopaytype} from '../acompte-enums';


type AcompteInfo = { label: string, amount: number };

@Component({
    templateUrl: './ppacosaid.component.html'
})
export class PpacosaidComponent extends BaseStoreFormComponent<Ppaco> implements OnInit, OnDestroy {

    public entityClient = Ppemp;
    @Input() public entityId = '';
    public empDtaType?: number;
    public dtaDetail?: string;
    public currentAcopaytype = 1;
    public acopaytype = Acopaytype;
    public cboMpa: ComboboxItem<string>[] = [];
    public entityModifiable = false;
    public amoutAllowed = 0;
    public firstAcoMnt = 0;
    public acompteInfo: AcompteInfo[] = [];
    public acompteTableColumns: IwGridColumn<AcompteInfo>[] = [
        {
            name: 'libelle',
            prop: 'label'
        },
        {
            name: 'montant',
            prop: 'amount',
            type: 'mnt',
            align: 'right'
        }]
    private subscriptions = new Subject();
    private readonly _ppempRest: RestEntityClient<Ppemp>;

    constructor(store: IwStoreService, _restService: RestApiService, public readonly _store: IwStoreService,
                private readonly _parameters: ParametersService, private _actions: Actions,
                private _entityUpdateService: EntityUpdateService, private readonly _payacoService: PayacoService,
                private readonly _headerService: HeaderToolbarService,
                private readonly _entityNavService: EntityNavigationService,
                private readonly _saesService: SaesService,
                private _sapaimodeService: SapaimodeService) {
        super(store);
        this._ppempRest = _restService.getEntityClient(Ppemp);
        this.subscribeValueChange('acodate', (acodate) => {
            const paymentDate = this.getFormValue('datePmnt');
            const acopaid = this.getFormValue('acopaid');
            if (this.isNew && acodate && acopaid) {
                this.setFormValue('datePmnt', acodate);
            }
        });

    }

    public get empId(): string | undefined {
        return this.getData('empId');
    }

    public get readonly(): boolean {
        return !this.isWriteMode;
    }

    public get editDisabled(): boolean {
        return !this.isNew && this.paid;
    }

    public get paid(): boolean {
        return this.getFormValue('acopaid') || false;
    }

    public ngOnInit() {
        this._parameters.getString('actSalId')
            .subscribe((e) => this.setFormValue('salId', e));

        this.subscribeValueChange<string>('empId', (id) => {
            if (!id) {
                return;
            }
            this.getEdtDetailDta(id);

            // load acompte info in order to list a basic grid with information about the existing states of money for this emp
            this.loadAcompteInfo(id, this.getFormValue('salId'));
        });

        if (this.isNew) {
            /*if (this.acopaytype)
                this.setFormValue('acopaytype', this.acopaytype);
            else
                this.setFormValue('acopaytype', Acopaytype.especes);*/
            this.setFormValue('acotype', 2);
            this.setFormValue('sent2sal', 'false');
        }

        if (this.empId) {
            this.setFormValue('empId', this.empId);
        }

        this.subscribeValueChange('acopaytype', (type: Acopaytype | undefined) => {
            if (!type) {
                return;
            }
            this.currentAcopaytype = type;
            this.setPayeDepuisOptions(type);
            this.applyPayTypeValidator(type);
        });

        this.subscribeValueChange('acopaid', acopaid => {
            let mpaIdControl = this.getFormControl('mpaId');
            if (this.isNew && acopaid) {
                const acodate = this.getFormValue('acodate');
                this.setFormValue('datePmnt', acodate);
            }
            if (acopaid) {
                mpaIdControl?.setValidators(Validators.required);
            } else {
                this.setFormValue('datePmnt', undefined);
                mpaIdControl?.removeValidators(Validators.required);
            }
            mpaIdControl?.updateValueAndValidity();
        })


        if (this.getFormValue('acoId')) {
            this.canUpdateAcompte(this.getFormValue('acoId'));
        }
        this.subscribeValueChange<string>('acoId', (id) => {
            this.canUpdateAcompte(id);
            this.firstAcoMnt = this.getFormValue('montant');
        });


        this.subscribeToReadModeEffect();
    }

    public mntNotNew(): boolean {
        return this.amoutAllowed >= 0 && !this.isNew && this.isWriteMode;
    };

    public onEmpIdChange(empId: string) {
        this._headerService.getActivePeriod()
            .pipe(mergeMap((per: Ppper) => this.handlePeriod(per)))
            .subscribe((autoacos) => {
                this.amoutAllowed = autoacos.filter((autoaco) => autoaco.empId === empId)[0]?.mnt60dispo || 0;
                this.amoutAllowed = this.isNew ? this.amoutAllowed : (this.amoutAllowed = 0 || !this.amoutAllowed ? 0 : this.amoutAllowed + Number(this.firstAcoMnt));
                this.setFormValue('amoutAllowed', this.amoutAllowed);
            });
    }

    public getEmpId() {
        return this.getFormValue('empId');
    }

    public notNewIsBigger(): boolean {
        return Number(this.amoutAllowed) + Number(this.firstAcoMnt) > this.getFormValue('montant');
    }

    public openEmp() {
        this._entityNavService
            .navigateToEntityForm(Ppemp, this.getFormValue('empId'));
    }

    public getEdtDetailDta(empId: string) {
        this._ppempRest.getById(empId)
            .subscribe((ppemp: Ppemp) => {
                if (!ppemp) {
                    return;
                }
                switch (ppemp.dtatype) {
                    case 1:
                        this.getBankInfo(ppemp);
                        break;
                    case 2:
                        this.getBankCpt(ppemp);
                        break;
                    case 5:
                        this.getIBAN(ppemp);
                        break;
                    default:
                        this.dtaDetail = 'def';
                }
                this.setFormValue('acopaytype', ppemp.paytype);
                this.setFormValue('banque', ppemp.banque);
                this.setFormValue('banqueAdr', ppemp.banqueAdr);
                this.setFormValue('banqueClr', ppemp.banqueClr);
                this.setFormValue('banqueCpt', ppemp.banqueCpt);
                this.setFormValue('banqueSwi', ppemp.banqueSwi);
                this.setFormValue('iban', ppemp.iban);
                this.setFormValue('acodtatype', ppemp.dtatype);
            });
    }

    // eslint-disable-next-line complexity
    public onAcoPayChanges(val: number) {
        if (this.readonly) {
            return;
        }
        if (val) {
            switch (val) {
                case Acopaytype.virement_dta:
                    this.setFormValue('acopaid', false);
                    this.setFormValue('nocheque', '');
                    this.setFormValue('mpaId', 'MPA');
                    break;
                default:
                    this.setFormValue('mpaId', '');
                    this.setFormValue('acopaid', true);
                    this.setFormValue('nocheque', '');
            }
        } else {
            this.setFormValue('mpaId', '');
        }
    }

    protected getValidationType() {
        return Ppaco;
    }

    protected getFormControlNames(): FormKeys<Ppaco> {
        return [
            'acoId',
            'ageId',
            'acodate',
            'montant',
            'acopaytype',
            'acopaid',
            'mpaId',
            'nocheque',
            'lnoaco',
            'libelle',
            'misId',
            'empId',
            'salId',
            'acotype',
            'banque',
            'banqueAdr',
            'banqueClr',
            'banqueCpt',
            'banqueSwi',
            'iban',
            'acodtatype',
            'sent2sal',
            'amoutAllowed',
            'datePmnt'];
    }

    protected validateReadonlyStatus(e: Ppaco) {
        return !this.entityModifiable && !!e.acoId;
    }

    private subscribeToReadModeEffect() {
        this._actions.pipe(takeUntil(this.subscriptions), ofType(globalActions.GLOBAL_FORM_SET_VALID))
            .subscribe(e => {
                const uuid = e['uuid'] as string || '';

                if (uuid !== this.uuid) {
                    return;
                }

                this.onEmpIdChange(this.getEmpId());
            });
    }

    private handlePeriod(per: Ppper) {
        return this._payacoService.getNoPayTypeAutoAcomptes(per.salId);
    }

    private getBankInfo(ppemp: Ppemp) {
        let detail = '';
        detail += ppemp.banqueClr + '/' + ppemp.banqueCpt + '\n';
        detail += ppemp.banque + '\n';
        detail += ppemp.banqueAdr + '\n';
        this.dtaDetail = detail;
    }

    private getBankCpt(ppemp: Ppemp) {
        let detail = '';
        detail += 'CCP: ' + ppemp.banqueCpt + '\n';
        this.dtaDetail = detail;
    }

    private getIBAN(ppemp: Ppemp) {
        let detail = '';
        detail += 'IBAN: ' + ppemp.iban;
        this.dtaDetail = detail;
    }

    private setPayeDepuisOptions(val?: number) {
        switch (val) {
            case 2:
                this.getMpaIds(2);
                break;
            case 3:
                //this.cboMpa = {};
                break;
            case 4:
                this.getMpaIds(4);
                break;
            case 1:
            default:
                this.getMpaIds(1);
                break;
        }
    }

    private canUpdateAcompte(acoId?: string) {
        if (!acoId) {
            return;
        }
        this._entityUpdateService.canUpdateEntity(EntitiesToUpdateEnum.PPACO, acoId)
            .subscribe((flag: boolean) => {
                this.entityModifiable = flag;
                this.triggerValidation();
            });
    }

    private getMpaIds(payType: Acopaytype) {
        this._sapaimodeService.getSapaimodeData(payType).subscribe((sapaimodeList: Sapaimode[]) => {
            this.cboMpa.length = 0;
            sapaimodeList.forEach((sapaimode: Sapaimode) => {
                const mpaId = sapaimode.mpaId;
                const libelle = sapaimode.titre;

                if (mpaId !== undefined && libelle !== undefined) {
                    this.cboMpa.push({
                        name: libelle,
                        value: mpaId.toString()
                    });
                }
            });
        });
    }

    private loadAcompteInfo(empId: string, salId: string) {
        this._saesService.getAcompteInfo(empId, salId).subscribe((data) => {
            this.acompteInfo = data;
        })
    }

    private applyPayTypeValidator(type: Acopaytype | undefined) {
        if (!type) return;
        let noChequeControl = this.getFormControl('nocheque');
        switch (type) {
            case Acopaytype.cheque:
                noChequeControl?.setValidators([Validators.required]);
                break;
            case Acopaytype.autre:
            case Acopaytype.especes:
            case Acopaytype.virement_dta:
                noChequeControl?.clearValidators();
        }
        noChequeControl?.updateValueAndValidity();
    }
}
