<form [formGroup]="formGroup"
      class="row">
    <iw-textfield #txtMis_id
                  [isDisabled]="true"
                  [label]="'missionNo' | translate"
                  [ngClass]="getMisClass()"
                  class="iw-input col-xs-1 no-padding"
                  formControlName="misId"
                  id="misId"></iw-textfield>

    <iw-textfield #misStatus
                  [isDisabled]="true"
                  [ngClass]="getMisClass()"
                  [value]="getMisStatusLabel()"
                  class="iw-input col-xs-1 no-padding flex-end"></iw-textfield>

    <div class="col-xs-1 row no-padding no-margin flex-end">
        <iw-textfield #s
                      [isDisabled]="true"
                      [value]="hasSal"
                      class="iw-input emphasis col-xs-6 no-padding"></iw-textfield>

        <iw-textfield #f
                      [isDisabled]="true"
                      [value]="hasFac"
                      class="iw-input emphasis col-xs-6 no-padding"></iw-textfield>
    </div>


    <div class="col-xs-2 row no-margin flex-end padding-right-0">
        <iw-textfield #commande
                      [isDisabled]="true"
                      [label]="'commandeNo' | translate"
                      class="iw-input col-xs-9 no-padding"
                      formControlName="cdeId"></iw-textfield>
    </div>

    <iw-button #cdeBtn
               (press)="openCommande()"
               [isDisabled]="!this.getFormValue('cdeId')"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>


    <iw-textfield #xy
                  [isDisabled]="true"
                  class="iw-input col-xs-1 no-padding flex-end"
                  formControlName="nogestion"></iw-textfield>

    <div class="col-xs-3 row no-padding no-margin flex-end">
        <ng-container *ngIf="showCreatedMode()">
            <iw-textfield #misOriginMode
                          [isDisabled]="true"
                          class="iw-input col-xs-1 no-padding flex-end"
                          formControlName="modecreat"></iw-textfield>

            <iw-textfield #originLabel
                          [isDisabled]="true"
                          [value]="getOriginalMissionLabel()"
                          class="iw-input col-xs-9 no-padding flex-end"></iw-textfield>

            <iw-button #originMisButton
                       (press)="openOriginalMission()"
                       class="iw-button col-xs-2 flex-end"
                       iconClass="fas fa-link"></iw-button>
        </ng-container>

    </div>

    <iw-textfield #txtMisIJInfo
                  [isDisabled]="true"
                  [ngClass]="getMisIjClass() ? 'mis-ij-info-color-true': 'mis-ij-info-color-false'"
                  [value]="getMisIJInfo()"
                  class="iw-input col-xs-2 flex-end"></iw-textfield>

</form>

<form [formGroup]="formGroup"
      class="row">

    <iw-entity-textfield #cliName
                         [allowSearch]="false"
                         [entity]="'ppcli'"
                         [inputClass]="'highlight'"
                         [label]="'client' | translate"
                         [name]="['nom', 'prenom']"
                         [showIdField]="true"
                         class="iw-input col-xs-5 no-padding flex-end"
                         formControlName="cliId"></iw-entity-textfield>

    <iw-button #cliBtn
               (press)="openClient()"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>

    <iw-entity-textfield #empName
                         [allowSearch]="false"
                         [entity]="'ppemp'"
                         [inputClass]="'highlight'"
                         [label]="'employe' | translate"
                         [name]="['nom', 'prenom']"
                         [showIdField]="true"
                         class="iw-input col-xs-5 no-padding flex-end"
                         formControlName="empId"></iw-entity-textfield>


    <iw-button #empBtn
               (press)="openEmp()"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>

</form>

<iw-tab [(selectedTab)]="selectedTab"
        [disabledTabs]="disabledTabList"
        [invalidTabs]="invalidTabList"
        [tabs]="tabList">
    <iw-tab-ppmis-general *ngIf="selectedTab === tabList[0]"
                          [employeeAge]="employeeAge"
                          [formGroup]="formGroup"
                          [hasRapport]="hasRapport"
                          [isReadonly]="!isWriteMode"
                          [sent2fac]="sent2Fac"
                          [sent2sal]="sent2Sal"
                          [suvaPeActive]="suvaPeActive"
                          [uuid]="uuid"></iw-tab-ppmis-general>

    <iw-ppmis-salary-tarif (updatePgmOptions)="setCodePgmOptions($event)"
                           *ngIf="selectedTab === tabList[1]"
                           [employeeAge]="employeeAge"
                           [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode || hasRapport"
                           [pgmOptions]="optionsCodePgm"
                           [suvaPeActive]="suvaPeActive"></iw-ppmis-salary-tarif>

    <iw-ppmis-sal-ind (salIndChanged)="setSnF()"
                      *ngIf="selectedTab === tabList[2]"
                      [formDirty]="formDirty"
                      [formGroup]="formGroup"
                      [formValue]="getFormData()"
                      [hasRapport]="hasRapport"
                      [isReadonly]="!isWriteMode"
                      [sent2fac]="sent2Fac"
                      [sent2sal]="sent2Sal"></iw-ppmis-sal-ind>

    <iw-tab-ppmis-autre *ngIf="selectedTab === tabList[3]"
                        [formGroup]="formGroup"
                        [hasRapport]="hasRapport"
                        [isReadonly]="!isWriteMode"
                        [sent2fac]="sent2Fac"
                        [sent2sal]="sent2Sal"></iw-tab-ppmis-autre>

    <iw-tab-ppmis-notes *ngIf="selectedTab === tabList[4]"
                        [formGroup]="formGroup"
                        [isReadonly]="!isWriteMode"></iw-tab-ppmis-notes>

    <iw-tab-ppmis-rapport (dataReloaded)="setSnF()"
                          *ngIf="selectedTab === tabList[5]"
                          [entityId]="misId"
                          [isReadonly]="!isWriteMode || hasRapport"></iw-tab-ppmis-rapport>

    <iw-tab-ppmis-suivi *ngIf="selectedTab === tabList[6]"
                        [formGroup]="formGroup"
                        [isReadonly]="!isWriteMode"></iw-tab-ppmis-suivi>

    <iw-tab-ppmis-docs *ngIf="selectedTab === tabList[7]"
                       [misId]="misId"></iw-tab-ppmis-docs>

    <ng-container *ngIf="interijobActive$">
        <iw-tab-ppmis-ij *ngIf="selectedTab === tabList[8]"
                         [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"></iw-tab-ppmis-ij>
    </ng-container>

</iw-tab>

<form [formGroup]="formGroup"
      class="row">
    <iw-textarea #edtNotes
                 [height]="40"
                 [isDisabled]="!isWriteMode"
                 class="iw-textarea col-xs-12"
                 formControlName="remarques"></iw-textarea>
</form>
