import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {PanelMenuModule} from 'primeng/panelmenu';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TooltipModule} from 'primeng/tooltip';

import {SamComponentsModule} from '../components';
import {ConfigService} from '../core/services/config.service';
import {
    DashboardToggleComponent,
    HeaderComponent,
    HeaderToolbarComponent,
    MenuComponent,
    MenuSlideComponent,
    SideMenuLayoutComponent,
    SlidePanelComponent
} from './components';

@NgModule({
    imports: [CommonModule,
        FormsModule,
        SamComponentsModule,
        TooltipModule,
        PanelMenuModule,
        TranslateModule,
        TieredMenuModule,
        ScrollPanelModule,
        MegaMenuModule,
        MenuModule],
    declarations: [DashboardToggleComponent,
        HeaderComponent,
        HeaderToolbarComponent,
        MenuComponent,
        MenuSlideComponent,
        SideMenuLayoutComponent,
        SlidePanelComponent],
    exports: [DashboardToggleComponent,
        HeaderComponent,
        HeaderToolbarComponent,
        MenuComponent,
        MenuSlideComponent,
        SideMenuLayoutComponent,
        SlidePanelComponent],
    providers: [ConfigService]
})
export class SamLayoutCoreModule {
}
