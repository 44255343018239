import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('js3view')
export class Js3view {
    public empId?: string;
    @IwRestEntityId() @IwColumn() public gsId?: string;
    public titre?: string;
    public annee?: string;
    public mntcumul?: number;
    public mnt1?: number;
    public mnt2?: number;
    public mnt3?: number;
    public mnt4?: number;
    public mnt5?: number;
    public mnt6?: number;
    public mnt7?: number;
    public mnt8?: number;
    public mnt9?: number;
    public mnt10?: number;
    public mnt11?: number;
    public mnt12?: number;
    public cs06Type?: string;
    public sd4Statan?: string;
    public sd4IsType?: string;
}
