import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Sarep} from '@app/sam-base/models/salary/sarep';
import {RepartitionComponent} from '../components/definitionSalaire/repartitionAnalytique/repartition.component';

export const SAREP_DEFAULT_FORM: FormDescription<Sarep> = {
    form: RepartitionComponent, state: {
        ...buildDefaultState(Sarep), diagTitle: 'repartitionAnalytique', diagModal: false, width: 900, isNew: true
    }
};
