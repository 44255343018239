<form [formGroup]="formGroup"
      class="row">
    <div class="row col-md-12">

        <div class="row col-xs-12"
             style="align-items: end;">
            <iw-dropdown [isDisabled]="readonlyGsProvision"
                         [labelAlign]="'top'"
                         [label]="'gsProvisions' | translate"
                         [options]="gsProvisionOptions"
                         class="iw-input col-xs-8 padding-top-10"
                         formControlName="gsId"></iw-dropdown>
            <div class="col-xs-4 padding-top-10">
                <iw-button #new
                           (press)="calcule()"
                           [isDisabled]="readonlyCalcule"
                           [text]="'calculer' | translate"
                           class="iw-button"
                           style="margin-block-start: auto"></iw-button>

            </div>

            <div class="col-xs-4 col-xs-offset-8 padding-top-10">
                <iw-button (press)="effacer()"
                           [isDisabled]="readonlyEffacer"
                           [text]="'effacer' | translate"
                           class="iw-button margin-top-10"
                           style="margin-block-start: auto; margin-top: 10px;"></iw-button>
            </div>
        </div>

        <div class="row col-md-8 padding-top-10"
             style="align-items: end;">
            <iw-textfield [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'genre_de_salaire_paiment' | translate"
                          [value]="genreDeSalaire"
                          class="iw-input col-md-8"></iw-textfield>

        </div>

        <div class="row col-xs-12"
             style="align-items: end;">
            <iw-textfield [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'solde' | translate"
                          [value]="soldeValue"
                          class="iw-input col-xs-4 padding-top-10 no-padding-right text-align-right"
                          type="number"></iw-textfield>
            <iw-textfield (valueChange)="updatePayerValue($event)"
                          [isDisabled]="readonlyPayer"
                          [labelAlign]="'top'"
                          [label]="'payer' | translate"
                          [value]="payerValue"
                          class="iw-input col-xs-4 padding-top-10 no-padding-right text-align-right"
                          type="number"></iw-textfield>
            <div class="col-xs-4 padding-bottom-2">
                <iw-button #delete
                           (press)="save()"
                           [isDisabled]=""
                           [text]="'enregistrer' | translate"
                           class="iw-button"
                           style="margin-block-start: auto"></iw-button>

            </div>
            <div class="col-xs-4 col-xs-offset-8 padding-top-10">
                <iw-button #delete
                           (press)="closeDialog()"
                           [isDisabled]=""
                           [text]="'annuler' | translate"
                           class="iw-button margin-top-10"
                           style="margin-block-start: auto"></iw-button>
            </div>
        </div>

        <div class="row col-xs-12 padding-top-10"
             style="align-items: end;">
            <iw-checkbox [isDisabled]="true"
                         [label]="'montant_negatif' | translate"
                         [value]=""
                         class="iw-input col-xs-8"></iw-checkbox>
        </div>

    </div>
</form>
