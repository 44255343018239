import {IwRestEntity, IwRestEntityId} from 'src/app/sam-base/core/rest-api';

@IwRestEntity('glcentre')
export class Glcentre {
    @IwRestEntityId() public centre?: string;
    public parent?: string;
    public cenType?: number;
    public titre?: number;
    public titreFull?: number;
    public actif?: number;
    public userId?: number;
}
