<form [formGroup]="formGroup"
      class="row">
    <div class="col-xs-7">

        <iw-sags-dropdown [labelAlign]="'left'"
                          [label]="'sagsvacprv' | translate"
                          class="iw-input col-xs-6"
                          formControlName="sagsvacprv"></iw-sags-dropdown>


        <iw-sags-dropdown [labelAlign]="'left'"
                          [label]="'sagsvacppr' | translate"
                          class="iw-input col-xs-6"
                          formControlName="sagsvacppr"></iw-sags-dropdown>

    </div>

    <div class="col-xs-8 separator-top no-padding"></div>

    <div class="col-xs-7">

        <iw-sags-dropdown [labelAlign]="'left'"
                          [label]="'sags13Prv' | translate"
                          class="iw-input col-xs-6"
                          formControlName="sags13prv"></iw-sags-dropdown>


        <iw-sags-dropdown [labelAlign]="'left'"
                          [label]="'sags13Ppr' | translate"
                          class="iw-input col-xs-2"
                          formControlName="sags13ppr"></iw-sags-dropdown>
    </div>

    <div class="col-xs-8 separator-top no-padding"></div>

    <div class="col-xs-7">

        <iw-sags-dropdown
                [labelAlign]="'left'"
                [label]="'sagsjfhprv' | translate"
                class="iw-input col-xs-2"
                formControlName="sagsjfhprv"></iw-sags-dropdown>


        <iw-sags-dropdown
                [labelAlign]="'left'"
                [label]="'sagsjfhppr' | translate"
                class="iw-input col-xs-2"
                formControlName="sagsjfhppr"></iw-sags-dropdown>
    </div>

    <div class="col-xs-8 separator-top no-padding"></div>

    <div class="col-xs-7">

        <iw-sags-dropdown
                [labelAlign]="'left'"
                [label]="'sagsnuiprv' | translate"
                class="iw-input col-xs-2"
                formControlName="sagsnuiprv"></iw-sags-dropdown>


        <iw-sags-dropdown
                [labelAlign]="'left'"
                [label]="'sagsnuippr' | translate"
                class="iw-input col-xs-2"
                formControlName="sagsnuippr"></iw-sags-dropdown>
    </div>

</form>  