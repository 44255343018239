import {Component, Input, ViewChild} from '@angular/core';
import {DocumentEntityRef} from '@app/sam-base/models/placement/document-entity-ref';
import {
    DocumentTypeManagementComponent
} from '@modules/sam-main/placement/components/employee/tab-ppemp-docs/document-type-management/document-type-management.component';
import {IwEventHubService} from '@sam-base/core';
import {Ppwxdocs} from '@sam-base/models/placement';

@Component({
    selector: 'iw-tab-ppemp-docs',
    templateUrl: 'tab-ppemp-docs.component.html'
})
export class TabPpempDocsComponent {

    public entityRef: DocumentEntityRef = DocumentEntityRef.Employe;

    @Input() public isReadonly = true;
    @ViewChild('docTypeGrid') public docTypeGrid?: DocumentTypeManagementComponent;

    constructor(private readonly _events: IwEventHubService<string>) {
    }

    private _entityId = '';

    public get entityId() {
        return this._entityId;
    }

    @Input()
    public set entityId(v: string) {
        this._entityId = v;
    }

    public onUploadedDoc(doc: Ppwxdocs) {
        if (doc.doccategory === 'EMP_PIC') {
            this._events.emit('loadEmpProfilePicture', this._entityId);
        }
    }

    onRefreshGrid() {
        setTimeout(() => {
            this.docTypeGrid?.onValidateDocuments();
        }, 500);
    }
}
