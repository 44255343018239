import {FormDescription} from '@app/sam-base/core';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Saempop} from '@app/sam-base/models/salary/saempop';
import {SaempopComponent} from '../components/saempop/saempop.component';

export const SAEMPOP_DEFAULT_FORM: FormDescription<Saempop> = {
    form: SaempopComponent, state: {
        ...buildDefaultState(Saempop), diagModal: true, diagTitle: 'saempop_form_title', editMode: 'waiting', width: 800
    }
};
