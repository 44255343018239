import {Component} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys} from '@app/sam-base/models';
import {Ppaco, Ppemp} from '@app/sam-base/models/placement';

import {Aacotype, Acopaytype} from './acompte-enums';

@Component({
    templateUrl: './acompte.component.html'
})
export class AcompteComponent extends BaseStoreFormComponent<Ppaco> {

    public entityClient = Ppemp;

    public acopaytype = Acopaytype;

    public aacotype = Aacotype;

    constructor(store: IwStoreService) {
        super(store);
    }

    public isReportType() {
        return this.entityValue && this.entityValue.acotype === 1 || false;
    }

    protected getFormControlNames(): FormKeys<Ppaco> {
        return ['session', 'acotype', 'acoId', 'rapId', 'rapNo', 'empId', 'ageId', 'ageId', 'acopaytype', 'acopaid', 'acodate', 'nocheque', 'montant', 'datePmnt', 'mpaId', 'dtaId', 'libelle', 'lnoaco', 'banqueClr', 'salId', 'banqueSwi', 'banqueCpt', 'iban'];
    }
}
