import {Injectable} from '@angular/core';
import {RestApiService} from '@app/sam-base/core/rest-api';

import {
    CalculationRequests, CalculationResponses, CalculationTypes
} from '../calculations/calculations';

@Injectable()
export class CalculationsService {
    public constructor(private readonly _restClient: RestApiService) {
    }

    public calculate<TKey extends Extract<keyof CalculationTypes, string>, TReq extends CalculationRequests[TKey], TRes extends CalculationResponses[TKey], >(type: TKey, params: TReq) {
        return this._restClient.calculationClient.calculateType <CalculationRequests, TKey, TRes>(type, params);
    }
}
