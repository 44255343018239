import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppgri} from '@app/sam-base/models/placement';

import {GenresIndemnitesComponent} from '../components/genres-indemnites/genres-indemnites.component';

export const PPGRI_DEFAULT_FORM: FormDescription<Ppgri> = {
    form: GenresIndemnitesComponent, state: {
        ...buildDefaultState(Ppgri), diagModal: true, diagTitle: 'ppgri_form_title', width: 600, isNew: true
    }
};
