<form [formGroup]="formGroup" class="row"></form>

<iw-tab [(selectedTab)]="selectedTab" [invalidTabs]="invalidTabList" [tabs]="tabList">
    <iw-tab-saparded-parameters *ngIf="selectedTab === tabList[0]" [formGroup]="formGroup" [isNew]="checkIfNew()"
                                [isReadonly]="!isWriteMode"></iw-tab-saparded-parameters>

    <iw-tab-saparded-informations *ngIf="selectedTab === tabList[1]" [formGroup]="formGroup"
                                  [isReadonly]="!isWriteMode"></iw-tab-saparded-informations>

    <form [formGroup]="formGroup" class="row"></form>
</iw-tab>
