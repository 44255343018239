<form [formGroup]="formGroup" class="row space-form">

    <iw-form-header [value]="name" class="col-xs-12"></iw-form-header>

    <iw-date-picker #txtDate_de [(value)]="startDate" [labelAlign]="'left'" [label]="'semaines' | translate"
                    class="iw-input col-xs-6" labelSize="short"></iw-date-picker>

    <iw-textfield #Standardfield3 [isDisabled]="true" [value]="startWeek" class="iw-input col-xs-1"></iw-textfield>

    <iw-date-picker #txtDate_a [(value)]="endDate" class="iw-input col-xs-4"></iw-date-picker>

    <iw-textfield #txtNoWk [isDisabled]="true" [value]="endWeek" class="iw-input col-xs-1"></iw-textfield>

    <div class="col-xs-12 space-block-4"></div>

    <div class="row col-xs-12">
        <div class="row col-xs-6">
            <iw-button #Standardbutton1 (press)="setYesterday()" [text]="'ytd' | translate"
                       class="iw-button col-xs btn-block"></iw-button>

            <iw-button #btn365 (press)="set365Days()" [text]="'365' | translate"
                       class="iw-button col-xs btn-block"></iw-button>

            <iw-button #Standardbutton2 (press)="setLastYear()" [text]="'y1' | translate"
                       class="iw-button col-xs btn-block"></iw-button>

            <iw-button #Standardbutton3 (press)="setBack2Years()" [text]="'y2' | translate"
                       class="iw-button col-xs btn-block"></iw-button>
        </div>

        <div class="row col-xs-6 end-xs">
            <iw-button #btnCalc (press)="calculate()" [text]="'calculer' | translate"
                       class="iw-button col-lg"></iw-button>
        </div>
    </div>

    <div class="col-xs-12 space-block-6"></div>

    <!-- ######## Tempo column ########### -->
    <div class="row col-xs-6 space-form">

        <iw-form-header [value]="'tempo' | translate" class="col-xs-12"></iw-form-header>

        <iw-textfield #txtNbemp [decimals]="0" [isDisabled]="true" [labelAlign]="'left'"
                      [label]="'employes' | translate" class="iw-input col-xs-12" formControlName="nbemp"
                      type="number"></iw-textfield>

        <iw-textfield #txtNbcde [decimals]="0" [isDisabled]="true" [labelAlign]="'left'"
                      [label]="'commandes' | translate" class="iw-input col-xs-12" formControlName="nbcde"
                      type="number"></iw-textfield>

        <iw-textfield #txtNbmis [decimals]="0" [isDisabled]="true" [labelAlign]="'left'"
                      [label]="'missions' | translate" class="iw-input col-xs-12" formControlName="nbmis"
                      type="number"></iw-textfield>

        <iw-textfield #txtNbrap [decimals]="0" [isDisabled]="true" [labelAlign]="'left'"
                      [label]="'rapports' | translate" class="iw-input col-xs-12" formControlName="nbrap"
                      type="number"></iw-textfield>

        <iw-textfield #txtNbheu [decimals]="2" [isDisabled]="true" [labelAlign]="'left'" [label]="'heures' | translate"
                      class="iw-input col-xs-12" formControlName="nbheu" type="number"></iw-textfield>

        <iw-textfield #txtCaHT [decimals]="2" [isDisabled]="true" [labelAlign]="'left'" [label]="'approxHt' | translate"
                      class="iw-input col-xs-12" formControlName="caht" type="number"></iw-textfield>

        <iw-textfield #txtMargeBrute [decimals]="2" [isDisabled]="true" [labelAlign]="'left'"
                      [label]="'margeBrute' | translate" class="iw-input col-xs-12" formControlName="margebrute"
                      type="number"></iw-textfield>

        <iw-textfield #txtCoeff [decimals]="3" [isDisabled]="true" [labelAlign]="'left'"
                      [label]="'coefficient' | translate" class="iw-input col-xs-12" formControlName="coeff"
                      type="number"></iw-textfield>

        <iw-textfield #txtCoeffcustom [decimals]="3" [isDisabled]="true" [labelAlign]="'left'"
                      [label]="'coeffJf' | translate" class="iw-input col-xs-12" formControlName="coeffcustom"
                      type="number"></iw-textfield>
    </div>

    <!-- ######## Domain Column ########### -->
    <div class="col-xs-6">
        <div class="row space-form">
            <iw-form-header [value]="domainName" class="col-xs-12"></iw-form-header>

            <iw-textfield #txtNbcdf [decimals]="0" [isDisabled]="true" [labelAlign]="'left'"
                          [label]="'commandes' | translate" class="iw-input col-xs-12" formControlName="nbcdf"
                          type="number"></iw-textfield>

            <iw-textfield #txtNbplf [decimals]="0" [isDisabled]="true" [labelAlign]="'left'"
                          [label]="'placements' | translate" class="iw-input col-xs-12" formControlName="nbplf"
                          type="number"></iw-textfield>

            <iw-textfield #txtCafixeht [decimals]="2" [isDisabled]="true" [labelAlign]="'left'"
                          [label]="'caHt' | translate" class="iw-input col-xs-12" formControlName="cafixeht"
                          type="number"></iw-textfield>
        </div>
    </div>

    <div class="col-xs-12 space-block-2"></div>

    <div class="row bottom-xs">
        <div class="col-xs-6 clean-row">
            <div class="row space-form no-margin">
                <iw-textfield #txtFirst_mis [isDisabled]="true" [labelAlign]="'left'"
                              [label]="'1EreMission' | translate" class="iw-input col-xs-12"

                              formControlName="firstMis"></iw-textfield>

                <iw-textfield #txtLast_mis [isDisabled]="true" [labelAlign]="'left'" [label]="'dernMission' | translate"
                              class="iw-input col-xs-12"

                              formControlName="lastMis"></iw-textfield>
            </div>
        </div>

        <div class="col-xs-6 end-xs">
            <iw-button #Closebutton1 (press)="destroyForm()" [text]="'fermer' | translate"
                       class="iw-button col-xs-12"></iw-button>
        </div>
    </div>

</form>
