import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Sacompta} from '@sam-base/models/salary/sacompta';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class SacomptaService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public init(salId: string): Observable<Sacompta> {
        return this._http.get<any>(this._baseUrl + `sacompta/init/${salId}`);
    }

    public comptabiliser(sacompta: Sacompta): Observable<Sacompta> {
        return this._http.post<any>(this._baseUrl + `sacompta/transfer`, sacompta);
    }

    public decomptabiliser(sacompta: Sacompta): Observable<Sacompta> {
        return this._http.post<any>(this._baseUrl + `sacompta/cancel`, sacompta);
    }
}
