import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {EntityNavigationService, IwStoreService, ModalService, ViewContainerRefService} from '@app/sam-base/core';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {Ppaco, Ppmis, Pprap} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {Gefachdr} from '@sam-base/models/invoices/gefachdr';
import {Saemsview} from '@sam-base/models/salary/saemsview';
import {ModalInputComponent} from '@shared/widgets/modal-components/modal-input/modal-input.component';
import {
    ModalRapportInvoiceComponent
} from '@shared/widgets/modal-components/modal-rapport-invoice/modal-rapport-invoice.component';
import {
    ModalRapportDataOptions
} from '@shared/widgets/modal-components/modal-rapport-invoice/modal-rapport-invoice.options';
import {
    ModalRapportSalaryComponent
} from '@shared/widgets/modal-components/modal-rapport-salary/modal-rapport-salary.component';
import {lastValueFrom, Subscription} from 'rxjs';

import {ReportsService} from '../../../services/reports.service';

/**
 * Tab General of parametres Generaux
 */
@Component({
    selector: 'iw-tab-rapport-recap',
    templateUrl: './tab-rapport-recap.component.html'
})
export class TabRapportRecapComponent extends BasePartialFormComponent<Pprap> {

    @Input() public mission: Ppmis = new Ppmis();

    @Input() public acompte: Ppaco = new Ppaco();

    @Input() public uuid?: string;

    public subscription?: Subscription;
    public SALARY_USER_ROLES = [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN,
        SamUserRole.SAM_SALAIRE_RO,
        SamUserRole.SAM_SALAIRE_RW];
    public INVOICE_USER_ROLES = [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_FACTURE_RW,
        SamUserRole.SAM_FACTURE_RO];

    constructor(private _viewHostRef: ViewContainerRefService, private _reportsService: ReportsService,
                private readonly _translate: TranslateService, private _store: IwStoreService,
                private readonly _modalService: ModalService,
                private readonly _entityNavService: EntityNavigationService) {
        super();
    }

    public get totalMntemp(): number {
        const mntheuemp = this.getFormValue<number>('mntheuemp') ?? 0;
        const mntindemp = this.getFormValue<number>('mntindemp') ?? 0;
        return mntheuemp + mntindemp;
    }

    public get totalMntcli(): number {
        const mntheucli = this.getFormValue<number>('mntheucli') ?? 0;
        const mntindcli = this.getFormValue<number>('mntindcli') ?? 0;
        return mntheucli + mntindcli;
    }

    public get canOpenInvoicePreview() {
        return this.getFormValue('sent2Fac');
    }

    public get canOpenSalaryPreview() {
        return this.getFormValue('sent2Sal');
    }

    public setNewChantier() {
        this._viewHostRef.loadComponent({
            type: ModalInputComponent,
            data: {
                value: this.getFormValue('chantier') ?? '',
                title: 'chantier',
                inputName: 'chantier',
                onOk: (val: string) => this.setChantier(val)
            }
        });
    }

    public openSalaryPreview(): void {
        let ficheSalId = this.getFormValue('empId') + "_" + this.getFormValue('salId');
        if (ficheSalId) {
            this._entityNavService.navigateToEntityForm(Saemsview, ficheSalId, undefined, 'read');
        }
    }

    public openInvoicePreview(): void {
        this._entityNavService.navigateToEntityForm(Gefachdr, this.getFormValue('facId') || '', undefined, 'read');
    }

    private async openRapportModal(elem: any, type: string) {
        const options: ModalRapportDataOptions = {
            showCancel: true,
            title: type === 'i' ? this._translate.instant('invoices_form_title') : this._translate.instant('salaire'),
            width: 1100,
            height: 800,
            obj: elem
        };
        this._store.dispatch(new actions.SetLoading(this.uuid || '', false));
        if (type === 'i') {
            await this._modalService.showModal(ModalRapportInvoiceComponent, options);
        } else {
            await this._modalService.showModal(ModalRapportSalaryComponent, options);
        }
    }

    private async setChantier(value: string) {
        if (!this.uuid || !value || value === '') {
            return;
        }
        this.setFormValue('chantier', value);
        await lastValueFrom(this._reportsService.setReportChantier(this.getFormValue('rapId') ?? '', value));
    }

}
