import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppoptsal} from '@app/sam-base/models/placement';

import {SalaryParametersComponent} from '../components/salary/salary-parameters/salary-parameters.component';

export const SALARY_PARAMS_DEFAULT_FORM: FormDescription<Ppoptsal> = {
    form: SalaryParametersComponent, state: {
        ...buildDefaultState(Ppoptsal),
        diagTitle: 'salary_param_form_title',
        diagModal: true,
        width: 1000,
        editMode: 'edit',
        destroyOnSave: true
    }
};
