import {Component, Type} from '@angular/core';
import {PplppService} from '@app/modules/sam-main/placement/services/pplpp.service';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwEventHubService} from '@app/sam-base/core/events';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {Ppemp, Pplpp} from '@app/sam-base/models/placement';

@Component({
    templateUrl: './ppheusais.component.html'
})
export class PpheusaisComponent extends BaseFormComponent<Pplpp> {

    public get selected(): Pplpp | undefined {
        return this.getData('selected');
    }

    public get empNom(): string | undefined {
        return this.getData('empNom');
    }

    public get empId(): string | undefined {
        return this.getData('empId');
    }

    public get datelundi(): string | undefined {
        return this.getData('datelundi');
    }

    public get semstatus(): number | undefined {
        return this.selected?.semstatus;
    }

    public set semstatus(n: number | undefined) {
        if (this.selected) {
            this.selected.semstatus = n;
        }
        this._semstatus = n;
    }

    public entityEmploye = Ppemp;

    public checkboxes = [{label: 'rendu', value: '0', checked: false}, {
        label: 'travail', value: '1', checked: false
    }, {label: 'maladie', value: '2', checked: false}, {
        label: 'accident', value: '3', checked: false
    }, {label: 'militaire', value: '4', checked: false}, {label: 'pastravaille', value: '5', checked: false}];
    public entity?: Pplpp;
    public type: Type<Pplpp> = Pplpp;
    private _semstatus?: number;

    constructor(_restService: RestApiService, private readonly pplppService: PplppService, protected readonly _store: IwStoreService, private readonly _events: IwEventHubService<string>) {
        super();
    }

    /** Emit event DESTROY FORM */
    public destroyForm() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    /** Emit Save EVENT */
    // eslint-disable-next-line complexity
    public saveChanges() {
        if (this.selected && this.selected.empId && this.datelundi) {
            this.pplppService.UpdateStatus(this.selected.empId, this.datelundi, (this.selected.semstatus || 0))
                .subscribe((_) => {
                    if (!this.selected) {
                        return;
                    }
                    this._events.emit('semstatus', this.selected.semstatus);
                    this.destroyForm();
                });
        } else if (this.empId && this.datelundi) {
            this.pplppService.createPplpp(this.empId, this.datelundi, (this._semstatus || 0))
                .subscribe(() => {
                    this._events.emit('semstatus', this._semstatus);
                    this.destroyForm();
                });
        }
    }

}
