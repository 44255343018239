<form [formGroup]="formGroup"
      class="row space-form">

    <iw-entity-textfield #ctnSelEmp
                         [allowSearch]="true"
                         [entity]="ppemp"
                         [filter]="filterPpemp"
                         [isDisabled]="readonly"
                         [labelAlign]="'left'"
                         [label]="'employe' | translate"
                         [name]="['nom', 'prenom']"
                         [showIdField]="false"
                         class="iw-input col-xs-10"
                         formControlName="empId"
                         id="ctnSelEmp"></iw-entity-textfield>

    <iw-button #empBtn
               (press)="openEmp()"
               [isDisabled]="false"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>

    <iw-ppgri-dropdown #cboGri_id
                       (selectedChanged)="setRemarques($event)"
                       [isDisabled]="readonly"
                       [labelAlign]="'left'"
                       [label]="'genre' | translate"
                       [type]="1"
                       class="iw-input col-xs-10"
                       formControlName="griId"
                       id="cboGri_id"></iw-ppgri-dropdown>

    <iw-textfield #txtGri_id
                  [isDisabled]="true"
                  [value]="griId"
                  class="iw-input col-xs-2"></iw-textfield>

    <iw-date-picker #txtDate
                    [isDisabled]="readonly"
                    [labelAlign]="'left'"
                    [label]="'date' | translate"
                    class="iw-input col-xs-4"
                    formControlName="indDate"
                    id="txtDate"></iw-date-picker>

    <div class="col-xs-8"></div>


    <iw-date-picker #txtDate_de
                    [isDisabled]="readonly"
                    [labelAlign]="'left'"
                    [label]="'periode' | translate"
                    class="iw-input col-xs-4"
                    formControlName="dateDe"
                    id="txtDate_de"></iw-date-picker>

    <iw-date-picker #txtDate_a
                    [isDisabled]="readonly"
                    class="iw-input col-xs-4"
                    formControlName="dateA"
                    id="txtDate_a"></iw-date-picker>


    <iw-textarea #edtMisDet
                 [(value)]="message"
                 [height]="99"
                 [isDisabled]="true"
                 [labelAlign]="'left'"
                 [label]="'mission' | translate"
                 class="iw-textarea col-xs-10"></iw-textarea>

    <div class="col-xs-2">

        <div class="row space-form">
            <iw-button #btnLkupMis
                       (press)="searchMis()"
                       [iconClass]="'fas fa-search'"
                       [isDisabled]="readonly"
                       class="iw-button col-xs-6"></iw-button>

            <iw-button #btnResetMis
                       (press)="resetEdtMisDet()"
                       [isDisabled]="readonly"
                       [text]="'0' | translate"
                       class="iw-button col-xs-4"></iw-button>

            <iw-textfield #txtMis_id
                          [isDisabled]="true"
                          class="iw-input col-xs-10"
                          formControlName="misId"></iw-textfield>
        </div>

    </div>

    <iw-ppcct-dropdown #cboCct_id
                       [isDisabled]="!!(getFormValue('misId')) || readonly"
                       [labelAlign]="'left'"
                       [label]="'cct' | translate"
                       class="iw-input col-xs-10"
                       formControlName="cctId"></iw-ppcct-dropdown>

    <iw-ppjf-dropdown #cboJf_id
                      [isDisabled]="readonly || !isPpjfEnable"
                      [labelAlign]="'left'"
                      [label]="'jourFerie' | translate"
                      class="iw-input col-xs-4"
                      formControlName="jfId"></iw-ppjf-dropdown>

    <div class="col-xs-8"></div>

    <iw-textfield #txtQte
                  [isDisabled]="readonly"
                  [labelAlign]="'left'"
                  [label]="'nombre' | translate"
                  class="iw-input col-xs-4"
                  formControlName="qte"
                  stepValue="0.01"
                  type="number"></iw-textfield>
    <div class="field-label col-xs-3">{{ 'ppcli' | translate }}</div>
    <div class="col-xs-5"></div>


    <iw-textfield #txtMntEmp
                  [isDisabled]="readonly"
                  [labelAlign]="'left'"
                  [label]="'montant' | translate"
                  class="iw-input col-xs-4"
                  formControlName="mntemp"
                  id="txtMntEmp"
                  stepValue="0.01"
                  type="number"></iw-textfield>
    <iw-textfield #txtMntCli
                  [decimals]="2"
                  [isDisabled]="readonly || !isGriType()"
                  [labelAlign]="'top'"
                  class="iw-input col-xs-4"
                  formControlName="mntcli"
                  type="number"></iw-textfield>

    <div class="col-xs-4"></div>

    <iw-textfield #txtTotEmp
                  [isDisabled]="true"
                  [labelAlign]="'left'"
                  [label]="'total' | translate"
                  class="iw-input col-xs-4"
                  formControlName="totemp"
                  stepValue="0.01"
                  type="number"></iw-textfield>

    <iw-textfield #txtTotCli
                  [isDisabled]="true"
                  class="iw-input col-xs-4"
                  formControlName="totcli"
                  stepValue="0.01"
                  type="number"></iw-textfield>

    <iw-textfield #txtRemarque
                  [isDisabled]="readonly || isPpjfEnable"
                  [labelAlign]="'left'"
                  [label]="'remarque' | translate"
                  class="iw-input col-xs-8"
                  formControlName="libelle"
                  id="txtRemarque"></iw-textfield>
</form>
