<form [formGroup]="formGroup" class="row">

    <iw-textfield #txtTx_vac_4 [isDisabled]="isReadonly" [labelAlign]="'left'"
                  [label]="'tauxVacances4Semaines' | translate" [minValue]="525" [size]="'large'"
                  class="iw-input col-xs-6 margin-top-15" formControlName="txVac4" type="number"></iw-textfield>
    <div class="iw-single-label col-xs-1 margin-top-15">%</div>
    <div class="col-xs-5"></div>

    <iw-textfield #txtTx_vac_5 [isDisabled]="isReadonly" [labelAlign]="'left'"
                  [label]="'tauxVacances5Semaines' | translate" [size]="'large'" class="iw-input col-xs-6"
                  formControlName="txVac5" type="number"></iw-textfield>
    <div class="iw-single-label col-xs-1">%</div>
    <div class="col-xs-5"></div>

    <iw-checkbox #Standardlogic3 [isDisabled]="isReadonly" [label]="'13EmeSalaireAutorise' | translate"
                 class="iw-input col-xs-3" formControlName="l13H"></iw-checkbox>
    <iw-textfield #txtTx_13 [isDisabled]="isReadonly || !is13" class="iw-input col-xs-3" formControlName="tx13"
                  type="number"></iw-textfield>
    <div class="iw-single-label col-xs-1">%</div>
    <div class="col-xs-5"></div>

    <iw-checkbox #Standardlogic1 [isDisabled]="isReadonly" [label]="'jfHoraireAutorise' | translate"
                 class="iw-input col-xs-3" formControlName="ljfh"></iw-checkbox>
    <iw-textfield #txtTx_JF [isDisabled]="isReadonly || !isjf" class="iw-input col-xs-3" formControlName="txJf"
                  type="number"></iw-textfield>
    <div class="iw-single-label col-xs-1">%</div>
    <div class="col-xs-5"></div>

    <iw-checkbox #chkLfraish1 [isDisabled]="isReadonly" [label]="'fraisHoraires1Autorises' | translate"
                 class="iw-input col-xs-6" formControlName="lfraish1"></iw-checkbox>
    <div class="col-xs-6"></div>
    <iw-checkbox #chkLfraish2 [isDisabled]="isReadonly" [label]="'fraisHoraires2Autorises' | translate"
                 class="iw-input col-xs-6" formControlName="lfraish2"></iw-checkbox>
    <div class="col-xs-6"></div>

    <iw-checkbox #chkLheunuit [isDisabled]="isReadonly" [label]="'hDeNuitAutorisees' | translate"
                 class="iw-input col-xs-3" formControlName="lheunuit"></iw-checkbox>
    <iw-textfield #txtTxHeuNuit [isDisabled]="isReadonly || !islheunuit" class="iw-input col-xs-3 padding-left-0"
                  formControlName="txheunuit" type="number"></iw-textfield>
    <div class="col-xs-6"></div>

    <iw-enum-dropdown #cboshbaseglob [inputEnum]="calcSalaire" [isDisabled]="isReadonly" [labelAlign]="'top'"
                      [label]="'calculDuSalaire' | translate" class="iw-input iw-input col-xs-6 margin-top-15"
                      formControlName="shbaseglob"></iw-enum-dropdown>
    <div class="col-xs-6"></div>

    <iw-ppshcalcmode-combo-box #cboShcalcmode [isDisabled]="isReadonly" [labelAlign]="'top'"
                               [label]="'decompositionDuSalaire' | translate" class="iw-input col-xs-6 margin-top-15"
                               formControlName="shcalcmode"></iw-ppshcalcmode-combo-box>

</form>
