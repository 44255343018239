import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {ComboboxItem, ModalComponent, TT6Ges} from '@app/sam-base/models';
import {AdminAction} from '@modules/sam-main/admin/components/ultimate-admin/admin-action.model';
import {
    ModalUltimateAdminOptions
} from '@modules/sam-main/admin/components/ultimate-admin/ultimate-admin-modal.options';
import {UltimateAdminService} from '@modules/sam-main/admin/services/ultimate-admin-service';
import {TranslateService} from '@ngx-translate/core';
import {AgencyHandlerService, RestApiService} from '@sam-base/core';
import {ToastService} from '@sam-base/core/toast';
import {ProfileService} from '@shared/profile/profile.service';
import {TreeNode} from 'primeng/api';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {
    ModalInvoiceCountOptions
} from 'src/app/shared/widgets/modal-components/modal-invoice-count/modal-invoice-count.options';


@Component({
    templateUrl: './modal-ultimate-admin.component.html'
})
export class ModalUltimateAdminComponent implements ModalComponent<void, ModalUltimateAdminOptions>, OnInit, OnDestroy {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public agencies: ComboboxItem<string>[] = [];
    public views: ComboboxItem<string>[] = [];
    public actions: ComboboxItem<AdminAction>[] = [];
    public display = true;
    public loading = false;
    public _options!: ModalInvoiceCountOptions;
    public selectedView?: string;
    public tenant?: string;
    public subTenant?: string;
    public logs: string[] = [];
    public action?: AdminAction;
    public instances: any = [];
    public jsonTree: TreeNode[] = [];
    protected _subscription = new Subject();
    private _ok?: (result?: void) => void;
    private _cancel?: (result?: undefined) => void;

    constructor(private _translate: TranslateService,
                private _toastService: ToastService,
                private readonly _profileService: ProfileService,
                private readonly _restService: RestApiService,
                private readonly _agency: AgencyHandlerService,
                private readonly _ultimateAdminService: UltimateAdminService) {
    }

    public get logsAsString(): string {
        return this.logs?.join('\n');
    }

    public get title() {
        return this._options.title;
    }

    public get width() {
        return this._options.width;
    }

    public get height() {
        if (this._options.counts && this._options.counts.length > 4) {
            return 150 + (this._options.counts.length * 40);
        }
        return this._options.height;
    }

    public get okColor() {
        return this._options.okColor === 'white' ? 'ok' : '';
    }

    ngOnDestroy(): void {
        this._subscription.next(undefined);
        this._subscription.complete();
    }

    public registerOk(action: (result: void) => void): void {
        this._ok = action;
    }

    public ngOnInit() {
        this._ultimateAdminService.availableView().subscribe(views => {
            this.views.push({
                name: '----',
                value: ''
            });
            views.forEach(view => this.views.push({
                name: view,
                value: view
            }));
        })
        this._ultimateAdminService.listActions().subscribe(actions => {
            actions.forEach(action => this.actions.push({
                name: action,
                value: action
            }));
        })

        this.initStreamLog();

        this._profileService.getTenant().then((tenant) => this.tenant = tenant);
        this._profileService.loadProfile()
            .subscribe(p => {
                this._agency.filterMigratedGestions(p.gestions)
                    .subscribe(filteredGestions => {
                        this._restService.getEntityClient(TT6Ges)
                            .getRefData()
                            .subscribe((gestions: TT6Ges[]) => {
                                this.agencies.push({
                                    name: '----',
                                    value: ''
                                });
                                gestions
                                    .filter(ges => filteredGestions.includes(ges.nogestion ?? ''))
                                    .forEach(gestion => this.agencies.push({
                                            name: gestion.nogestion || '',
                                            value: gestion.nogestion || ''
                                        })
                                    )

                            });
                    });
            });

    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
        this.display = false;
    }

    public close() {
        if (this._ok) {
            this._ok();
        }
        this.pressOk.emit();
        this.display = false;
    }

    public async setData(data: ModalInvoiceCountOptions): Promise<void> {
        this._options = data;
    }

    public registerCancel(action: (result?: string[]) => void): void {
        this._cancel = action;
    }

    indexSingleView() {
        if (!this.tenant || !this.subTenant || !this.selectedView) {
            return;
        }
        // this.loading = true;
        this._ultimateAdminService.indexSingleView(this.tenant, this.subTenant, this.selectedView).subscribe(() => {
            this._toastService.success('index_single_view_ok');
        }, error => this._toastService.error('index_single_view_error'), () => this.loading = false);
    }

    onClickClear() {
        this.logs = [];
    }

    public indexAll() {
        if (!this.tenant || !this.subTenant) {
            return;
        }
        // this.loading = true;
        this._ultimateAdminService.indexAll(this.tenant, this.subTenant).subscribe(() => {
            this._toastService.success('index_all_view_ok');
        }, error => this._toastService.error('index_all_view_error'), () => this.loading = false);

    }

    public migrateProfilePicture() {
        if (!this.tenant || !this.subTenant) {
            return;
        }
        // this.loading = true;
        this._ultimateAdminService.profilePictureMigrationV1(this.tenant, this.subTenant).subscribe(() => {
            this._toastService.success('profile_picture_migration_ok');
        }, error => this._toastService.error('profile_picture_migration_error'), () => this.loading = false);
    }

    public dataMigrationToUltimate() {
        if (!this.tenant || !this.subTenant) {
            return;
        }
        // this.loading = true;
        this._ultimateAdminService.dataMigrationToUltimate(this.tenant, this.subTenant).subscribe(() => {
            this._toastService.success('dataMigrationToUltimate_ok');
        }, error => this._toastService.error('dataMigrationToUltimate_error'), () => this.loading = false);
    }

    public executeAction() {
        if (!this.tenant || !this.subTenant || !this.action) {
            return;
        }
        this._ultimateAdminService.executeAction(this.action, this.tenant, this.subTenant).subscribe(() => {
            this._toastService.success('admin_action_execute_ok');
        }, error => this._toastService.error('admin_action_execute_error'), () => this.loading = false);
    }

    public getInstances() {
        this._ultimateAdminService.applicationInstances().subscribe((instances) => {
            this.instances = instances
            this.jsonTree = this.transformToTreeNodes(this.instances, 'root');
        });
    }

    transformToTreeNodes(data: any, label: string): TreeNode[] {
        if (data === null || data === undefined) {
            return [
                {
                    label: `${label}: null`,
                    leaf: true
                }];
        }

        if (typeof data !== 'object') {
            return [
                {
                    label: `${label}: ${data}`,
                    leaf: true
                }];
        }

        return Object.keys(data).map(key => {
            const value = data[key];
            if (typeof value === 'object') {
                return {
                    label: key,
                    children: this.transformToTreeNodes(value, key)
                };
            } else {
                return {
                    label: `${key}: ${value}`,
                    leaf: true
                };
            }
        });
    }

    private initStreamLog() {
        this._ultimateAdminService.streamlog()
            .pipe(takeUntil(this._subscription))
            .subscribe({
                next: (data: string) => {
                    console.log('data', data);
                    this.logs.push(data);
                },
                error: (error) => {
                    this._toastService.error('SSE error:', error);
                    this.loading = false;
                },
                complete: () => {
                    this.loading = false;
                }
            })
    }
}
