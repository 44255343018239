<iw-tab [(selectedTab)]="selectedTab" [tabs]="tabList">

    <iw-tab-salary-genresal *ngIf="selectedTab === tabList[0]" [formGroup]="formGroup"></iw-tab-salary-genresal>

    <iw-tab-salary-deductions *ngIf="selectedTab === tabList[1]" [formGroup]="formGroup"></iw-tab-salary-deductions>

    <iw-tab-salary-provisions *ngIf="selectedTab === tabList[2]" [formGroup]="formGroup"></iw-tab-salary-provisions>

    <iw-tab-salary-autre *ngIf="selectedTab === tabList[3]" [formGroup]="formGroup"></iw-tab-salary-autre>
</iw-tab>

<div class="row col-xs-12 flex-end margin-top-70">
    <iw-button #btnCancel (press)="destroyForm()" [text]="'annuler' | translate" class="iw-button col-xs-3"></iw-button>

    <iw-button #btnOk (press)="saveChanges()" [text]="'ok' | translate" class="iw-button col-xs-3"></iw-button>
</div>
