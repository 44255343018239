<form [formGroup]="formGroup" class="row">
    <div class="col-xs-6">

        <iw-enum-dropdown [inputEnum]="optionsCodeCna" [label]="'suva' | translate" [size]="'large'" class="col-xs-6"
                          formControlName="cnamode" labelAlign="left"></iw-enum-dropdown>
        <iw-enum-dropdown [inputEnum]="optionsCodePgm" [label]="'pgm' | translate" [size]="'large'" class="col-xs-6"
                          formControlName="pgmmode" labelAlign="left"></iw-enum-dropdown>

        <iw-enum-dropdown #cboImpotMode [inputEnum]="optionsCodeCna" [labelAlign]="'left'"
                          [label]="'modeImpot' | translate" [size]="'large'" class="col-xs-6"
                          formControlName="impotmode"></iw-enum-dropdown>

        <iw-enum-dropdown #cboIscantmode [inputEnum]="modeCantonImpot" [labelAlign]="'left'"
                          [label]="'modeCantonImpot' | translate" [size]="'large'" class="col-xs-6"
                          formControlName="iscantmode"></iw-enum-dropdown>

        <iw-enum-dropdown #cboLppMode [inputEnum]="optionsCodeCna" [labelAlign]="'left'" [label]="'modeLpp' | translate"
                          [size]="'large'" class="col-xs-6" formControlName="lppmode"></iw-enum-dropdown>

        <iw-enum-dropdown #cboAlfmode [inputEnum]="optionsCodeCna" [labelAlign]="'left'" [label]="'modeAlf' | translate"
                          [size]="'large'" class="col-xs-6" formControlName="alfmode"></iw-enum-dropdown>

        <iw-textfield #txtSatblreq [labelAlign]="'left'" [label]="'codeReq' | translate" [size]="'large'"
                      class="iw-input col-xs-4" formControlName="satblreq"></iw-textfield>

        <iw-checkbox #chkCctlsinsa [label]="'cctLsDansSa' | translate" class="iw-input col-xs-6"
                     formControlName="cctlsinsa"></iw-checkbox>

    </div>

    <div class="row col-xs-12">

        <div class="form-label col-xs-4 no-label"></div>
        <div class="form-label col-xs-2 no-label">{{ 'parDefaut' | translate }}</div>
        <div class="form-label col-xs-3 no-label">{{ 'hommes' | translate }}</div>
        <div class="form-label col-xs-3 no-label">{{ 'femmes' | translate }}</div>

        <div class="form-label col-xs-4">{{ getLabel('1') }} (1)</div>
        <iw-checkbox #Standardlogic2 [label]="'soumis' | translate" class="iw-input col-xs-2"
                     formControlName="saded1def"></iw-checkbox>
        <iw-textfield #Standardfield1 class="iw-input col-xs-3" formControlName="satblded1h"></iw-textfield>
        <iw-textfield #Standardfield2 class="iw-input col-xs-3" formControlName="satblded1f"></iw-textfield>


        <div class="form-label col-xs-4">{{ getLabel('2') }} (2)</div>
        <iw-checkbox #Standardlogic3 [label]="'soumis' | translate" class="iw-input col-xs-2"
                     formControlName="saded2def"></iw-checkbox>
        <iw-textfield #Standardfield3 class="iw-input col-xs-3" formControlName="satblded2h"></iw-textfield>
        <iw-textfield #Standardfield4 class="iw-input col-xs-3" formControlName="satblded2f"></iw-textfield>

        <div class="form-label col-xs-4">{{ getLabel('3') }} (3)</div>
        <iw-checkbox #Standardlogic4 [label]="'soumis' | translate" class="iw-input col-xs-2"
                     formControlName="saded3def"></iw-checkbox>
        <iw-textfield #Standardfield5 class="iw-input col-xs-3" formControlName="satblded3h"></iw-textfield>
        <iw-textfield #Standardfield6 class="iw-input col-xs-3" formControlName="satblded3f"></iw-textfield>

        <div class="form-label col-xs-4">{{ getLabel('4') }} (4)</div>
        <iw-checkbox #Standardlogic5 [label]="'soumis' | translate" class="iw-input col-xs-2"
                     formControlName="saded4def"></iw-checkbox>
        <iw-textfield #Standardfield7 class="iw-input col-xs-3" formControlName="satblded4h"></iw-textfield>
        <iw-textfield #Standardfield8 class="iw-input col-xs-3" formControlName="satblded4f"></iw-textfield>
    </div>

</form>