import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppaco} from '@app/sam-base/models/placement';
import {PpacosaidComponent} from '../components/acompte/ppacosaid/ppacosaid.component';

export const PPACO_DEFAULT_FORM: FormDescription<Ppaco> = {
    form: PpacosaidComponent,
    state: {
        ...buildDefaultState(Ppaco),
        diagTitle: 'acompte_form_title',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true,
            showClose: true,
            showEdit: true,
            showList: true,
            showPrint: false,
            showDelete: true
        },
        useNavigation: true,
        patch: false,
        width: 750,
        entityType: Ppaco
    }
};
