import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Agetime} from '@app/sam-base/models/salary/agetime';
import {
    AgetimeComponent
} from '@modules/sam-main/placement/components/agence-config/agetime-config/agetime-config.component';

export const AGETIME_FORM: FormDescription<Agetime> = {
    form: AgetimeComponent,
    state: {
        ...buildDefaultState(Agetime),
        diagTitle: '',
        diagModal: true,
        width: 500
    }
};
