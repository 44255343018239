<form [formGroup]="formGroup" class="row">
    <div #edtPgmcodeshLabel class="iw-label col-xs-3 flex-end"
         title="{{'codesPgmH' | translate}}">{{ 'codesPgmH' | translate }}
    </div>
    <iw-textfield #edtPgmcodesh (valueChange)="setUpperCase('pgmcodesh', $event) " [isDisabled]="isReadonly"
                  class="iw-input col-xs-7 no-padding-right flex-end" formControlName="pgmcodesh"></iw-textfield>
    <iw-textfield #txtDefcodpgmh (valueChange)="setUpperCase('defcodpgmh', $event) " [isDisabled]="isReadonly"
                  [labelAlign]="'top'" [label]="'defaut' | translate" class="iw-input col-xs-2 no-padding-left"
                  formControlName="defcodpgmh"></iw-textfield>
    <div #edtPgmcodesfLabel class="iw-label col-xs-3 flex-end"
         title="{{'codesPgmF' | translate}}">{{ 'codesPgmF' | translate }}
    </div>
    <iw-textfield #edtPgmcodesf (valueChange)="setUpperCase('pgmcodesf', $event) " [isDisabled]="isReadonly"
                  class="iw-input col-xs-7 no-padding-right no-padding-top flex-end"
                  formControlName="pgmcodesf"></iw-textfield>
    <iw-textfield #txtDefcodpgmf (valueChange)="setUpperCase('defcodpgmf', $event) " [isDisabled]="isReadonly"
                  class="iw-input col-xs-2 no-padding-left no-padding-top" formControlName="defcodpgmf"></iw-textfield>
    <div #edtPgmcodexplLabel class="iw-label col-xs-3"
         title="{{'explication' | translate}}">{{ 'explication' | translate }}
    </div>
    <iw-textarea #edtPgmcodexpl [height]="72" [isDisabled]="isReadonly" class="iw-textarea col-xs-9"
                 formControlName="pgmcodexpl"
                 style="padding-left: 0.5rem; padding-right: 0.5rem; padding-top: 10px"></iw-textarea>
</form>
