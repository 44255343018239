<form [formGroup]="formGroup"
      class="row spaceForm col-no-padding">
    <iw-enum-dropdown #cboPaytype
                      [inputEnum]="paysType"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'paiement' | translate"
                      class="iw-input col-xs-12 col-no-padding"
                      formControlName="paytype"></iw-enum-dropdown>

    <iw-enum-dropdown #cboDtaType
                      [inputEnum]="dtaType"
                      [isDisabled]="!checkDtaTypeEnabled()"
                      [labelAlign]="'left'"
                      [label]="'dtaOpae' | translate"
                      class="iw-input col-xs-12 col-no-padding"
                      formControlName="dtatype"></iw-enum-dropdown>

    <iw-textfield-bank #txtGenretrav
                       (selected)="onSelected($event)"
                       [isDisabled]="!checkFieldsEnabled([3], [1,5])"
                       [labelAlign]="'left'"
                       [label]="'banque' | translate"
                       class="iw-input col-xs-10 no-padding-left"
                       formControlName="banque"></iw-textfield-bank>
    <iw-button #btnLkupClr
               (press)="clearingsClick()"
               [isDisabled]="!checkFieldsEnabled([3], [1,5])"
               [text]="'TEXT' | translate"
               class="iw-button col-xs-2 no-padding-right"
               id="bank-button"></iw-button>

    <iw-textfield #txtBanque_adr
                  [isDisabled]="!checkFieldsEnabled([3], [1,5])"
                  [labelAlign]="'left'"
                  [label]="' '"
                  class="iw-input col-xs-12 col-no-padding"
                  formControlName="banqueAdr"></iw-textfield>

    <iw-textfield #txtBanque_clr
                  [isDisabled]="!checkFieldsEnabled([3], [1,5])"
                  [labelAlign]="'left'"
                  [label]="'clearing' | translate"
                  class="iw-input col-xs-12 col-no-padding"
                  formControlName="banqueClr"></iw-textfield>

    <iw-textfield #txtBanque_swi
                  [isDisabled]="!checkFieldsEnabled([3], [5])"
                  [labelAlign]="'left'"
                  [label]="'swift' | translate"
                  class="iw-input col-xs-12 col-no-padding"
                  formControlName="banqueSwi"></iw-textfield>

    <iw-textfield #txtIBAN
                  [isDisabled]="!checkFieldsEnabled([3], [5])"
                  [labelAlign]="'left'"
                  [label]="'iban' | translate"
                  [warningMessage]="'ibanWarning' | translate"
                  class="iw-input col-xs-12 col-no-padding"
                  formControlName="iban"
                  mask="SS00 AAAA AAAA AAAA AAAA AAAA AAAA AAAA AA"></iw-textfield>

    <iw-textfield #txtBanque_cpt
                  [isDisabled]="!checkFieldsEnabled([3], [1,2,5])"
                  [labelAlign]="'left'"
                  [label]="'compte' | translate"
                  class="iw-input col-xs-10 no-padding-left"
                  formControlName="banqueCpt"></iw-textfield>

    <iw-button #btnExtractFromIban
               *ngIf="false"
               [isDisabled]="!checkFieldsEnabled([3], [5])"
               [text]="'>>'"
               class="iw-button col-xs-2 no-padding-right"></iw-button>
</form>
