<form [formGroup]="formGroup" class="row">
    <div class="row col-xs-10 padding-0">
        <div class="row col-xs-6 padding-0">
            <iw-textfield #txtNom [isDisabled]="(hasConj && readonly)" [labelAlign]="'top'" [label]="'nom' | translate"
                          class="iw-input col-xs-6" formControlName="nom"></iw-textfield>
            <iw-textfield #txtPrenom [isDisabled]="(hasConj && readonly)" [labelAlign]="'top'"
                          [label]="'prenom' | translate" class="iw-input col-xs-6"
                          formControlName="prenom"></iw-textfield>
            <iw-date-picker #txtDateNaiss [isDisabled]="(hasConj && readonly)" [labelAlign]="'top'"
                            [label]="'naissance' | translate" class="iw-input col-xs-6"
                            formControlName="datenaiss"></iw-date-picker>
            <iw-textfield #txtAge [isDisabled]="(hasConj && readonly)" [isReadOnly]="true" [labelAlign]="'top'"
                          [label]="' '" [value]="age" class="iw-input col-xs-6"></iw-textfield>
            <iw-textfield #txtNoavs13 [isDisabled]="(hasConj && readonly)" [labelAlign]="'top'"
                          [label]="'noavs13' | translate" class="iw-input col-xs-6" formControlName="noavs13"
                          mask="000.0000.0000.00"></iw-textfield>
        </div>
        <div class="row col-xs-6 padding-0">
            <iw-checkbox #chkadrother [isDisabled]="(hasConj && readonly)" [label]="'autreAdresse' | translate"
                         class="iw-input col-xs-12" formControlName="adrother"></iw-checkbox>
            <iw-textfield #txtAdresse1 [isDisabled]="(hasConj && readonly) || editAutreAdresseDisabled"
                          [labelAlign]="'top'" [label]="'adresse' | translate" class="iw-input col-xs-12"
                          formControlName="adresse1"></iw-textfield>
            <iw-textfield #txtAdresse2 [isDisabled]="(hasConj && readonly) || editAutreAdresseDisabled"
                          class="iw-input col-xs-12" formControlName="adresse2"></iw-textfield>
            <iw-npa-textfield #txtNpa (relatedLieu)="this.setFormValue('lieu', $event)"
                              [isDisabled]="(hasConj && readonly) || editAutreAdresseDisabled"
                              class="iw-input col-xs-2 no-padding-right" formControlName="npa"></iw-npa-textfield>
            <iw-textfield #txtLieu [isDisabled]="(hasConj && readonly) || editAutreAdresseDisabled"
                          class="iw-input col-xs-8" formControlName="lieu"></iw-textfield>
            <iw-pays-dropdown #cboPays [isDisabled]="(hasConj && readonly) || editAutreAdresseDisabled"
                              class="iw-input col-xs-12" formControlName="pays"></iw-pays-dropdown>
        </div>
        <iw-checkbox #chkLtravail [isDisabled]="hasConj && readonly" [label]="'leConjointTravaille' | translate"
                     class="iw-input col-xs-12" formControlName="ltravail"></iw-checkbox>
        <div class="row col-xs-6 padding-0">
            <iw-date-picker #txtTravdebut [isDisabled]="editConjointTravDisabled" [labelAlign]="'top'"
                            [label]="'debut' | translate" class="iw-input col-xs-6"
                            formControlName="travdebut"></iw-date-picker>
            <iw-date-picker #txttravfin [isDisabled]="editConjointTravDisabled" [labelAlign]="'top'"
                            [label]="'fin' | translate" class="iw-input col-xs-6"
                            formControlName="travfin"></iw-date-picker>
            <iw-enum-dropdown #cbotravtype [inputEnum]="employmenttype" [isDisabled]="editConjointTravDisabled"
                              [label]="'typeDemploi' | translate" [size]="'medium'" class="iw-input col-xs-12"
                              formControlName="travtype"></iw-enum-dropdown>
            <iw-enum-dropdown #cborevenutype [inputEnum]="revenuetype" [isDisabled]="editConjointTravDisabled"
                              [label]="'typeDeRevenu' | translate" [size]="'medium'" class="iw-input col-xs-12"
                              formControlName="revenutype"></iw-enum-dropdown>
        </div>
        <div class="row col-xs-6 padding-0">
            <iw-canton-dropdown #cboCantonTrav [isDisabled]="editConjointCantonDisabled" [labelAlign]="'top'"
                                [label]="'cantonTravail' | translate" class="iw-input col-xs-12"
                                formControlName="cantontrav"></iw-canton-dropdown>
            <iw-pays-dropdown #cboPaysTrav (selectedChanged)="changeValidators($event)"
                              [isDisabled]="(hasConj && readonly) || editConjointTravDisabled" [labelAlign]="'top'"
                              [label]="'paysTravail' | translate" class="iw-input col-xs-12"
                              formControlName="paystrav"></iw-pays-dropdown>
        </div>
    </div>
    <div class="col-xs-2 flex flex-v flex-between">
        <div class="flex flex-v flex-between" style="height: 50px;">
            <iw-button #btnModif (press)="save()" [isDisabled]="(hasConj && canModify) || !isValid"
                       [text]="'sidemenu_save' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>
            <iw-button #btnModif (press)="modify()" [isDisabled]="editDisabled" [text]="'sidemenu_edit' | translate"
                       class="iw-button btn-block padding-bottom-10"></iw-button>
            <iw-button #btnDel (press)="delete()" [isDisabled]="!hasConj" [text]="'sidemenu_delete' | translate"
                       class="iw-button btn-block"></iw-button>
        </div>
        <iw-button #btnClose (press)="destroyForm()" [text]="'fermer' | translate"
                   class="iw-button btn-block"></iw-button>
    </div>
</form>
