<form [formGroup]="formGroup"
      class="row">
    <iw-table #table
              (selectedChange)="loadVersion($event)"
              [columns]="tableColumns"
              [data]="tableData"
              [isFilterEnable]="false"
              [isGridColumnMenuEnable]="false"
              [isSortIconEnable]="false"
              class="iw-input col-xs-12"></iw-table>
</form>
