<div *iwAccessGroups="commandeRoles" class="flex flex-v flex-evenly">
    <div class="row margin-top-20">
        <iw-button #btnOpenClose (press)="changeCdeStatus()" [text]="'oF' | translate"
                   [tooltip]="'open_close_cde' | translate" class="btn-block" id="btnOpenClose"></iw-button>
    </div>
    <!--div class="row">
      <iw-button #btnCopy
        id="btnCopy"
        class="btn-block"
        iconClass="fas fa-copy"
        [tooltip]="'copy_cde' | translate"
        (press)="copyCde()"></iw-button>
    </div-->
</div>
