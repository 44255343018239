<form [formGroup]="formGroup" class="row">
    <div class="row col-xs-12">
        <div class="row col-xs-9">
            <iw-checkbox (valueChange)="customValue('csMot1', $event)" [isDisabled]="isReadonly"
                         [label]="'combo_ncs_avancee_dialog_csmot1' | translate" [value]="getCheckboxValue('csMot1')"
                         class="iw-input col-xs-12 no-padding-left"></iw-checkbox>

            <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_canton' | translate"
                          class="iw-input col-xs-6 padding-bottom-20" formControlName="csMot11"></iw-textfield>

            <iw-date-picker [isDisabled]="isReadonly" [labelAlign]="'left'"
                            [label]="'combo_ncs_avancee_dialog_date' | translate" class="col-xs-6 padding-bottom-20"
                            formControlName="csMot12"></iw-date-picker>

            <iw-checkbox (valueChange)="customValue('csMot2', $event)" [isDisabled]="isReadonly"
                         [label]="'combo_ncs_avancee_dialog_csmot2' | translate" [value]="getCheckboxValue('csMot2')"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-20"></iw-checkbox>

            <iw-checkbox (valueChange)="customValue('csMot3', $event)" [isDisabled]="isReadonly"
                         [label]="'combo_ncs_avancee_dialog_csmot3' | translate" [value]="getCheckboxValue('csMot3')"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"></iw-checkbox>

            <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_canton' | translate"
                          class="iw-input col-xs-6 padding-bottom-20" formControlName="csMot31"></iw-textfield>

            <iw-date-picker [isDisabled]="isReadonly" [labelAlign]="'left'"
                            [label]="'combo_ncs_avancee_dialog_date' | translate" class="col-xs-6 padding-bottom-20"
                            formControlName="csMot32"></iw-date-picker>

            <iw-checkbox (valueChange)="customValue('csMot4', $event)" [isDisabled]="isReadonly"
                         [label]="'combo_ncs_avancee_dialog_csmot4' | translate" [value]="getCheckboxValue('csMot4')"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"></iw-checkbox>

            <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_canton' | translate"
                          class="iw-input col-xs-6 padding-bottom-20" formControlName="csMot41"></iw-textfield>

            <iw-date-picker [isDisabled]="isReadonly" [labelAlign]="'left'"
                            [label]="'combo_ncs_avancee_dialog_date' | translate" class="col-xs-6 padding-bottom-20"
                            formControlName="csMot42"></iw-date-picker>

            <iw-checkbox (valueChange)="customValue('csMot5', $event)" [isDisabled]="isReadonly"
                         [label]="'combo_ncs_avancee_dialog_csmot5' | translate" [value]="getCheckboxValue('csMot5')"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"></iw-checkbox>

            <iw-enum-dropdown #cboFormeJuri [inputEnum]="csMotOptions" [isDisabled]="isReadonly"
                              [label]="'' | translate" class="col-xs-12 no-padding-left padding-bottom-5"
                              formControlName="csMot51"></iw-enum-dropdown>

            <iw-enum-dropdown #cboFormeJuri [inputEnum]="csMotOptions" [isDisabled]="isReadonly"
                              [label]="'' | translate" class="col-xs-12 no-padding-left padding-bottom-5"
                              formControlName="csMot52"></iw-enum-dropdown>

            <iw-enum-dropdown [inputEnum]="csMotOptions" [isDisabled]="isReadonly" [label]="'' | translate"
                              class="col-xs-12 no-padding-left padding-bottom-5"
                              formControlName="csMot53"></iw-enum-dropdown>

            <iw-checkbox (valueChange)="customValue('csMot6', $event)" [isDisabled]="isReadonly"
                         [label]="'combo_ncs_avancee_dialog_csmot6' | translate" [value]="getCheckboxValue('csMot6')"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"></iw-checkbox>

            <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_csmot61' | translate"
                          class="iw-input col-xs-9 padding padding-bottom-20" formControlName="csMot61"></iw-textfield>

            <iw-checkbox (valueChange)="customValue('csMot7', $event)" [isDisabled]="isReadonly"
                         [label]="'combo_ncs_avancee_dialog_csmot7' | translate" [value]="getCheckboxValue('csMot7')"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"></iw-checkbox>

            <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_montant' | translate"
                          class="iw-input col-xs-9 padding-bottom-20" formControlName="csMot71"></iw-textfield>

            <iw-checkbox (valueChange)="customValue('csMot8', $event)" [isDisabled]="isReadonly"
                         [label]="'combo_ncs_avancee_dialog_csmot8' | translate" [value]="getCheckboxValue('csMot8')"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"></iw-checkbox>

            <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_montant' | translate"
                          class="iw-input col-xs-9 padding-bottom-20" formControlName="csMot81"></iw-textfield>

            <iw-checkbox (valueChange)="customValue('csMot9', $event)" [isDisabled]="isReadonly"
                         [label]="'combo_ncs_avancee_dialog_csmot9' | translate" [value]="getCheckboxValue('csMot9')"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-20"></iw-checkbox>

            <iw-checkbox (valueChange)="customValue('csMot10', $event)" [isDisabled]="isReadonly"
                         [label]="'combo_ncs_avancee_dialog_csmot10' | translate" [value]="getCheckboxValue('csMot10')"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-20"></iw-checkbox>
        </div>
        <div class="row col-xs-3 no-padding-left">
            <iw-textfield [isDisabled]="true" [value]="currentYear"
                          class="iw-input col-xs-12 no-padding-right flex-start"
                          formControlName="csYear"></iw-textfield>
        </div>
        <div class="row col-xs-9">
            <iw-checkbox (valueChange)="customValue('csMot110', $event)" [isDisabled]="isReadonly"
                         [label]="'combo_ncs_avancee_dialog_csmot110' | translate"
                         [value]="getCheckboxValue('csMot110')"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"></iw-checkbox>

            <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_canton' | translate"
                          class="iw-input col-xs-6 padding-bottom-20" formControlName="csMot111"></iw-textfield>

            <iw-date-picker [isDisabled]="isReadonly" [labelAlign]="'left'"
                            [label]="'combo_ncs_avancee_dialog_date' | translate" class="col-xs-6 padding-bottom-20"
                            formControlName="csMot112"></iw-date-picker>
        </div>

        <div class="row col-xs-9">
            <iw-checkbox (valueChange)="customValue('csMot120', $event)" [isDisabled]="isReadonly"
                         [label]="'combo_ncs_avancee_dialog_csmot120' | translate"
                         [value]="getCheckboxValue('csMot120')"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"></iw-checkbox>
            <iw-enum-dropdown #cboFormeJuri (valueChange)="changeCs120Options($event)" [inputEnum]="csMot1201Options"
                              [isDisabled]="csMot120Disabled()" [label]="'' | translate"
                              class="col-xs-12 no-padding-left padding-bottom-5 padding-top-5"
                              formControlName="csMot1201"></iw-enum-dropdown>
            <iw-textfield *ngIf="csActive" [isDisabled]="csMot120Disabled()" [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_percent' | translate"
                          class="iw-input col-xs-6 padding-bottom-20" formControlName="csMot1201Rate"></iw-textfield>
            <iw-textfield *ngIf="csActive3" [isDisabled]="csMot120Disabled()" [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_canton' | translate"
                          class="iw-input col-xs-6 padding-bottom-20" formControlName="csMot1201CState"></iw-textfield>
        </div>
    </div>
</form>
