<form [formGroup]="formGroup" class="row space-form">

    <iw-entity-textfield #txtCli [allowSearch]="true" [entity]="entityClient" [label]="'client' | translate"
                         [name]="['nom', 'prenom']" [showIdField]="false" class="iw-input col-xs-9"
                         formControlName="cliId"></iw-entity-textfield>

    <iw-conseiller-dropdown #cboCon_id [isDisabled]="!isWriteMode" [label]="'conseiller' | translate"
                            class="iw-combobox col-xs-9 padding-bottom-10" formControlName="conId"
                            id="cbo-conid"></iw-conseiller-dropdown>

    <div class="col-xs-3"></div>

    <iw-button #btnOk (press)="saveChanges()" [isDisabled]="formGroup.invalid" [text]="'ok' | translate"
               class="iw-button col-xs-2"></iw-button>

    <iw-button #Standardbutton2 (press)="destroyForm()" [text]="'annuler' | translate"
               class="iw-button col-xs-2"></iw-button>

</form>
