<form [formGroup]="formGroup"
      class="row space-form">
    <div class="row no-padding">

        <iw-agency-combo-box #agiAge_id
                             [isDisabled]="readonly"
                             [labelAlign]="'top'"
                             [label]="'agence' | translate"
                             class="iw-input col-xs-4"
                             formControlName="ageId"></iw-agency-combo-box>

        <iw-button (press)="prepareClick()"
                   [text]="'preparer' | translate"
                   class="iw-button col-xs-4 col-xs-offset-4 flex-end"></iw-button>

        <iw-periode-dropdown [isDisabled]="readonly"
                             [labelAlign]="'top'"
                             [label]="'periode' | translate"
                             class="iw-input col-xs-4"
                             formControlName="salId"></iw-periode-dropdown>

        <iw-button (press)="resetForm()"
                   [text]="'effacer' | translate"
                   class="iw-button col-xs-4 col-xs-offset-4 flex-end"></iw-button>

        <iw-checkbox #chkempHasAgi
                     [(value)]="withAgi"
                     [isDisabled]="readonly"
                     [label]="'employe_with_agi' | translate"
                     class="iw-input col-xs-4 margin-top-10"></iw-checkbox>

    </div>

    <div class="row"
         style="height: 400px; margin-top: 10px;
    padding: 0 0.5rem !important; width: 100%;">
        <iw-table #agiEmpTable
                  [isFilterEnable]="false"
                  [isGridColumnMenuEnable]="false"
                  [isSortIconEnable]="false"
                  [options]="tableOptions"
                  [virtualScroll]="true"
                  style="width: 100%; height: 100%;"></iw-table>
    </div>


    <div class="row col-xs-12 padding-top-10">
        <iw-button (press)="cancel()"
                   [text]="'annuler' | translate"
                   class="col-xs-offset-8 col-xs-2"></iw-button>
        <iw-button (press)="generate()"
                   [text]="'generer' | translate"
                   class="col-xs-2"></iw-button>
    </div>

</form>
