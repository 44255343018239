import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ClipboardService} from '@app/sam-base/core/clipboard/clipboard.service';
import {RestApiService, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {ToastService} from '@app/sam-base/core/toast';
import {PlacementSearchNavigation} from '@core/placement-search/placement-search.service';

import {Sasx5dec} from '@sam-base/models/admin/sasx5dec.model';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {Saparass} from '@sam-base/models/placement/saparass';
import {Subject} from 'rxjs';

/**
 * Tab General of Employee - SAM-4268
 */
@Component({
    selector: 'iw-tab-sasx5dec-assurances',
    templateUrl: './tab-sasx5dec-assurances.component.html'
})
export class TabSasx5decAssurancesComponent extends BasePartialFormComponent<Sasx5dec> implements OnInit, OnDestroy {


    @Input() public isNew = false;
    public query: RestQueryParam<Saparass, string>[] = [{
        operation: RestQueryOperation.Equals,
        prop: 'annee',
        value: ''
    },
        {
            operation: RestQueryOperation.Equals,
            prop: 'assSdcxml',
            value: 'true'
        }];
    public type = Saparass;
    private subscriptions = new Subject();

    constructor(private readonly _clipboardService: ClipboardService, private readonly _restService: RestApiService,
                private readonly _toastService: ToastService,
                private readonly _gridNavigation: PlacementSearchNavigation) {
        super();
    }

    public get entityId() {
        const id = this.getFormValue('id');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public get annee() {
        return this.getFormValue('annee');
    }

    public ngOnInit() {
        this.initAssuranceQuery();
        // this.initAssuranceQueryWithYear(this.getFormValue('annee') || '');
        this.subscribeValueChange('annee', annee => this.initAssuranceQuery());
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public convertSaparassToInstitution(saparass: Saparass): Sasx5institution {
        const institution: Sasx5institution = new Sasx5institution();
        institution.domain = saparass.dedCode;
        institution.institutionCode = saparass.assCode;
        institution.institutionIdref = saparass.assIdref;
        institution.assNocli = saparass.assNocli;
        institution.assNocai = saparass.assNocai;
        institution.assNocta = saparass.assNocta;
        institution.assNom = saparass.assNom;
        institution.incompleteDeclaration = saparass.incompleteDeclaration;
        return institution;
    }

    public selectAssuranceChange(selectedAssurances: Saparass[]) {
        this.setFormValue('institutions', selectedAssurances.map(this.convertSaparassToInstitution));
    }

    private initAssuranceQueryWithYear(annee: string) {
        const rqpAnnee = this.query
            .find(rqp => rqp.operation === RestQueryOperation.Equals && rqp.prop === 'annee');
        if (rqpAnnee) {
            rqpAnnee.value = annee;
        }
    }

    private initAssuranceQuery() {
        this.query = [{
            operation: RestQueryOperation.Equals,
            prop: 'annee',
            value: this.getFormValue('annee') || ''
        },
            {
                operation: RestQueryOperation.Equals,
                prop: 'assSdcxml',
                value: 'true'
            }];
    }
}
