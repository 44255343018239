import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base/base-partial-form.component';
import {Ppcct} from '@app/sam-base/models/placement';

import {ExDedmod} from './tab-cct-execution-enums';

@Component({
    selector: 'iw-tab-cct-execution', templateUrl: './tab-cct-execution.component.html'
})
export class TabCctExecutionComponent extends BasePartialFormComponent<Ppcct> {

    public exDedmodOptions = ExDedmod;

    constructor() {
        super();
    }

}
