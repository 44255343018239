import { HttpClient } from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {SagenService} from '@app/sam-base/core/services/sagen.service';
import {Saems} from '@app/sam-base/models/salary/saems';
import {environment} from '@root/environments/environment';

@Component({
    selector: 'iw-tab-saems-notes', templateUrl: './tab-saems-notes.component.html'
})
export class TabSaemsNotesComponent extends BasePartialFormComponent<Saems> implements OnInit {

    public notesPer?: string;
    private _baseUrl = ' ';

    constructor(private readonly _http: HttpClient, private _sagenService: SagenService) {
        super();
        this._baseUrl = environment.backendURL;
    }

    public ngOnInit() {
        this.getNotesfromSagen(this.getFormValue('salId') || '');
    }

    private getNotesfromSagen(salId: string) {
        return this._sagenService.getSagenBySalId(salId)
            .subscribe((note) => {
                this.notesPer = note.notesint;
            });
    }

}
