import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ClipboardService} from '@app/sam-base/core/clipboard/clipboard.service';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {ToastService} from '@app/sam-base/core/toast';
import {getAgeByDateOfBirth} from '@app/sam-base/helpers/date';
import {Npa} from '@app/sam-base/models/common';
import {Ppcdf, Ppcli, Ppcon, Ppemp, Ppplf} from '@app/sam-base/models/placement';
import {PlacementSearchNavigation} from '@core/placement-search/placement-search.service';
import {PpconService} from '@sam-base/core';
import {lastValueFrom, of, Subject} from 'rxjs';
import {catchError, map, switchMap, takeUntil} from 'rxjs/operators';

import {AvsNumberService} from '../../../services/avs-number.service';
import {Cpolitesse, TypeEmploi} from './tab-ppemp-general-enums';

/**
 * Tab General of Employee - SAM-4268
 */
@Component({
    selector: 'iw-tab-ppemp-general',
    templateUrl: './tab-ppemp-general.component.html'
})
export class TabPpempGeneralComponent extends BasePartialFormComponent<Ppemp> implements OnInit, OnDestroy {


    @Input() public isNew = false;


    public ppparLagedefinedbycon = false;
    public avsMask = '000.0000.0000.00';
    public cpolitesse = Cpolitesse;
    public typeEmploi = TypeEmploi;
    public age = '';
    public cliNom = '';
    public oldCdf = '';
    public cdfIdChange = new EventEmitter<string>();
    private readonly _npaRestClient: RestEntityClient<Npa>;
    private subscriptions = new Subject();

    constructor(private readonly _clipboardService: ClipboardService, private readonly _restService: RestApiService,
                private readonly _avsService: AvsNumberService, private readonly _toastService: ToastService,
                private readonly _gridNavigation: PlacementSearchNavigation, private _ppconService: PpconService) {
        super();

        this._npaRestClient = _restService.getEntityClient(Npa);

        this.cdfIdChange.pipe(takeUntil(this.subscriptions), switchMap(cdfid => this._restService.getEntityClient(Ppcdf)
            .getById(cdfid)), switchMap(cdf => {
            if (cdf.cliId) {
                return this._restService.getEntityClient(Ppcli)
                    .getById(cdf.cliId);
            }
            throw Error();
        }), map(ppcli => ppcli.nom || ''), catchError(() => of('')))
            .subscribe(nom => this.cliNom = nom || '');
    }

    public get pays(): string {
        return this.getFormValue('pays') || '';
    }

    public get entityId() {
        const id = this.getFormValue('empId');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public ngOnInit() {
        if (this.isNew) {
            this._ppconService.fillMainConseiller(this.getFormControl('conId'));
        }
        this.subscribeValueChange('conId', (conId: string | undefined) => {
            if (conId !== '' && conId) {
                this._restService
                    .getEntityClient(Ppcon)
                    .getById(conId)
                    .subscribe((ppconInfo: Ppcon) => {
                        this.setFormValue('ageId', ppconInfo.ageId);
                    });
            }
        });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    /**
     * Function to get lieu by npa
     */
    // public async getLieuByNpa() {
    //     try {
    //         const npa: string = this.getFormValue<string>('npa') || '';
    //         if (this.canGenerateNpa(npa)) {
    //             const lieu: Npa = await lastValueFrom(this._npaRestClient.getById(npa));
    //             this.setFormValue('lieu', lieu.lieu);
    //         }
    //     } catch (error) {
    //         this._toastService.error('npa_error');
    //     }
    //     this.setNpaLieuValidators();
    // }

    /**
     * Function to check if cdfValue change
     */
    public cdfIdChangeCheck() {
        const newCdf = this.getFormValue('cdfId');
        if (newCdf && newCdf !== this.oldCdf) {
            this.oldCdf = '' + newCdf;
            this.cdfIdChange.emit(this.oldCdf);
        }
    }

    /**
     * Function to open placement fixe grid with employee in context
     */
    public openPlacefixGrid() {
        if (this.entityId) {
            this._gridNavigation.navigate({
                entity: Ppplf,
                baseFilters: {ctaEmpId: this.entityId}
            });
        }
    }

    public checkIfNew(): boolean {
        return this.isNew;
    }

    /**
     * Function do copy value to clipboard
     *
     * @param value value to be copied
     */
    // eslint-disable-next-line complexity
    public addToClipboard(key: string) {
        let copiedText = '';
        if (key === 'email' || key === 'noavs13') {
            const value = this.getFormValue(key);
            copiedText = typeof value === 'string' ? value : '';
        }
        if (key === 'noavs13') {
            const value = this.getMaskedAvs(this.getFormValue(key));
            copiedText = typeof value === 'string' ? value : '';
        }
        if (key === 'adresse') {
            const nom = this.getFormValue('nom');
            const prenom = this.getFormValue('prenom');
            const adresse1 = this.getFormValue('adresse1');
            const adresse2 = this.getFormValue('adresse2');
            const npa = this.getFormValue('npa');
            const lieu = this.getFormValue('lieu');
            const pays = this.getFormValue('pays');

            let value = '';

            if (nom) {
                value += nom + ' ';
            }
            if (prenom) {
                value += prenom + '\n';
            }
            if (adresse1) {
                value += adresse1 + '\n';
            }
            if (adresse2) {
                value += adresse2 + '\n';
            }
            if (npa) {
                value += npa + ' ';
            }
            if (lieu) {
                value += lieu + '\n';
            }
            if (pays) {
                value += pays + '\n';
            }

            copiedText = typeof value === 'string' ? value : '';
        }
        this._clipboardService.addToClipboard(copiedText);
    }

    public async checkAVSNumber() {
        // eslint-disable-next-line complexity
        setTimeout(async () => {
            const avs = this.getAvsNumber();
            if (!avs || avs.toString().length !== 13) {
                this.setAvsValidators(false);
                return;
            }

            const empId: string = this.getFormValue('empId') ?? '';
            const res = await lastValueFrom(this._avsService.validateAVSForPpempId(avs, empId));
            this.onFormChange();
            if (res.result) {
                this.setFormValue('noavs13', res.data);
                this.setAvsValidators(true);
            } else if (res.error) {
                this._toastService.error(res.error);
                this.setAvsValidators(false);
            }
        }, 500);
    }

    public setAvsValidators(valid: boolean): void {
        if (valid) {
            this.formGroup?.controls['noavs13'].clearValidators();
        } else {
            // Workaround to set the control invalid
            this.formGroup?.controls['noavs13']
                .setValidators(Validators.pattern(/^INVALID$/));
        }
        this.formGroup?.controls['noavs13'].updateValueAndValidity();
    }

    // ---------------------------------------------------

    /**
     * Function to check if natel is empty
     *
     * @returns boolean
     */
    public checkNatel(): boolean {
        const nat = this.getFormValue('natel');
        if (nat) {
            return true;
        }
        return false;
    }

    /**
     * Function to calculate age with current date and date of birthday
     */
    public ageFromDateOfBirthday(): number | string {
        const dateOfBirth = this.getFormValue<string>('datenaiss') || '';
        const age = getAgeByDateOfBirth(dateOfBirth);
        if (!isNaN(age)) {
            return getAgeByDateOfBirth(dateOfBirth);
        }
        return '';
    }

    // private setNpaLieuValidators() {
    //     if (!this.formGroup) {
    //         return;
    //     }
    //     this.formGroup?.controls['npa']
    //         .setValidators(Validators.required);
    //     this.formGroup?.controls['npa']
    //         .setValidators(Validators.maxLength(8));
    //     this.formGroup?.controls['lieu']
    //         .setValidators(Validators.required);
    //     this.formGroup?.controls['npa'].updateValueAndValidity();
    //     this.formGroup?.controls['lieu'].updateValueAndValidity();
    // }

    private getAvsNumber() {
        return this.getFormValue<string>('noavs13');
    }

    private canGenerateNpa(npa: string) {
        return npa.length === 4 && !this.getFormValue('lieu') && !this.isReadonly;
    }

    private getMaskedAvs(avsNumber?: string) {
        if (avsNumber && avsNumber.length === 13) {
            let pattern = this.avsMask;
            pattern = pattern.replaceAll('0', '#');
            let i = 0;
            return pattern.replace(/#/g, () => avsNumber[i++]);
        }
        return avsNumber;
    }
}
