<form [formGroup]="formGroup" class="row space-form">

    <iw-ppgri-dropdown #cboGri_id [labelAlign]="'left'" [label]="'type' | translate" [type]="0"
                       class="iw-input col-xs-10" formControlName="griId" id="cboGri_id"></iw-ppgri-dropdown>


    <iw-textfield #txtGri_id [isDisabled]="true" [value]="gri" class="iw-input col-xs-2"
                  formControlName="griId"></iw-textfield>


    <iw-textfield #txtQte [isDisabled]="true" [labelAlign]="'left'" [label]="'nombre' | translate"
                  class="iw-input col-xs-8" formControlName="qte"></iw-textfield>


    <iw-textfield #txtUnite class="iw-input col-xs-4" formControlName="unite"></iw-textfield>


    <iw-textfield #txtMntEmp [labelAlign]="'left'" [label]="'montantEmploye' | translate" class="iw-input col-xs-8"
                  formControlName="mntemp" stepValue="0.01" type="number"></iw-textfield>


    <iw-textfield #txtMntCli [labelAlign]="'left'" [label]="'montantClient' | translate" class="iw-input col-xs-8"
                  formControlName="mntcli" stepValue="0.01" type="number"></iw-textfield>

    <iw-textfield #txtLibelle [labelAlign]="'left'" [label]="'libelle' | translate" class="iw-input col-xs-10"
                  formControlName="libelle" id="txtLibelle"></iw-textfield>
    <iw-enum-dropdown #cboSaisieMode [inputEnum]="saisie" [labelAlign]="'left'" [label]="'saisie' | translate"
                      class="iw-input col-xs-10" formControlName="saisiemode"></iw-enum-dropdown>

    <iw-textarea #edtNotes [height]="55" [labelAlign]="'left'" [label]="'remarques' | translate"
                 class="iw-textarea col-xs-10" formControlName="notes"></iw-textarea>

    <div class="col-xs-3 col-xs-offset-9 flex flex-v flex-between">
        <iw-button #btnOk (press)="saveIndemnity()" [isDisabled]="!isValid" [text]="'enregistrer' | translate"
                   class="iw-button btn-block" id="btnOk" style="margin-bottom:3px"></iw-button>
        <iw-button #btnCancel (press)="destroyForm()" [text]="'annuler' | translate"
                   class="iw-button btn-block"></iw-button>
    </div>

</form>
