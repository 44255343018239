import {HttpClient} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';

import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {IwStoreService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {DestroyForm} from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {IwGridColumn} from '@app/sam-base/models';
import {FormKeys} from '@app/sam-base/models/form-keys.model';
import {Saisdef} from '@app/sam-base/models/salary/saisdef';
import {ParametersService} from '@core/services/parameters.service';
import {Actions} from '@ngrx/effects';
import {environment} from '@root/environments/environment';
import {salaryMgtRoles} from '@sam-base/core/auth/access-rules/facture-roles';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {catchError, of} from 'rxjs';


@Component({
    templateUrl: './canton-is.component.html'
})
export class CantonISComponent extends BaseStoreFormComponent<Saisdef> implements OnInit {
    public type = Saisdef;
    public selectedRow?: Saisdef;
    public readonly = true;
    public newEntryMode = false;
    public isSaving = false;
    public columns: IwGridColumn<Saisdef>[] = this.getColumns();
    public query: RestQueryParam<Saisdef, string>[] = [];

    @ViewChild('cantonISGrid', {static: true}) public cantonISGrid?: IwRestGridComponent<Saisdef>;
    public SALAIRE_MGT_ROLES: SamUserRole[] = salaryMgtRoles()
    private readonly _baseUrl: string;
    private readonly _restSaisdefClient: RestEntityClient<Saisdef>;

    constructor(_store: IwStoreService, private _httpClient: HttpClient, private _toastService: ToastService,
                private _restService: RestApiService,
                private readonly _actions: Actions<actions.GlobalFormActions<Saisdef>>,
                private _parametersService: ParametersService) {
        super(_store);
        this._baseUrl = environment.backendURL;
        this._restSaisdefClient = _restService.getEntityClient(Saisdef);
    }

    public ngOnInit() {
        this.query = [
            {
                prop: 'annee',
                operation: RestQueryOperation.Equals,
                value: this._parametersService.activeYear
            }];
        this.getFormControl('isnordre')
            ?.setValidators([Validators.required]);
    }

    public createNewEntry() {
        this.readonly = false;
        this.selectedRow = {};
        this.formGroup.reset();
        this.newEntryMode = true;
    }

    public modifyEntry() {
        this.readonly = false;
        this.newEntryMode = false;
    }

    public saveEntry() {
        const currentYear = this._parametersService.activeYear;
        const formData = this.getFormData();
        const updatedData = this._restSaisdefClient.construct({
            ...this.selectedRow, ...formData,
            annee: currentYear
        });
        this.readonly = true;
        this.clearForm();

        if (!updatedData || !updatedData.canton || !currentYear) {
            this.handleError('need_to_fill_id');
            return;
        }

        this._httpClient
            .post(this._baseUrl + `saisdef/save`, updatedData)
            .pipe(catchError(() => {
                this.handleError('need_to_fill_id');
                return of(undefined);
            }))
            .subscribe(() => {
                this.cantonISGrid?.refresh();
            });
    }

    public removeEntry() {
        const annee = this._parametersService.activeYear;
        const canton = this.getFormValue('canton');

        if (!annee || !canton) {
            this.handleError('need_to_fill_id');
            return;
        }

        this._httpClient
            .delete(this._baseUrl + `saisdef/delete/${canton}/${annee}`)
            .pipe(catchError(() => {
                this.handleError('need_to_fill_id');
                return of(undefined);
            }))
            .subscribe(() => this.cantonISGrid?.refresh());
    }

    public cancelEditionMode() {
        this.readonly = true;
        this.newEntryMode = false;
        this.fillFormData(this.selectedRow ?? {});
    }

    public getSelectedRow(event: Saisdef[]) {
        this.readonly = true;

        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }

        this.selectedRow = event[0];
        this.fillFormData(event[0]);
    }

    public closeDialog() {
        this._store.dispatch(new DestroyForm(this.uuid));
    }

    /* Columns to show in the table */
    public getColumns(): IwGridColumn<Saisdef>[] {
        return [
            {
                prop: 'canton',
                name: 'canton',
                index: 0
            },
            {
                prop: 'isnordre',
                name: 'no_dpi',
                index: 1
            },
            {
                prop: 'isnbheures',
                name: 'nb_heures',
                index: 2
            },
            {
                prop: 'annee',
                name: 'annee',
                index: 3
            },
            {
                prop: 'subnum',
                name: 'subnum',
                index: 4
            }];
    }

    protected getFormControlNames(): FormKeys<Saisdef> {
        return [
            'isSdsdis',
            'isSdsxml',
            'canton',
            'isnordre',
            'iscommper',
            'isnbheures',
            'isnbjours',
            'subnum',
            'entcpt'];
    }

    private handleError(errorMessage: string): void {
        this._toastService.error(errorMessage);
        this.newEntryMode = false;
    }
}
