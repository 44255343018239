import {FormDescription} from '@app/sam-base/core';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Saenf} from '@app/sam-base/models/common';
import {SaenfComponent} from '../components/saenf/saenf.component';

export const SAENF_DEFAULT_FORM: FormDescription<Saenf> = {
    form: SaenfComponent,
    state: {
        ...buildDefaultState(Saenf),
        diagModal: true,
        diagTitle: 'saenf_form_title',
        editMode: 'waiting',
        width: 800
    }
};
