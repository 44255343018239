import {Component, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwEventHubService} from '@app/sam-base/core';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {isRestEntity, isRestType, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {EntityNavigationService} from '@app/sam-base/core/services/entity-navigation.service';
import {PpconService} from '@app/sam-base/core/services/ppcon.service';
import * as entityActions from '@app/sam-base/core/store/actions/entity.actions';
import {IwGridColumn, RowClickEvent} from '@app/sam-base/models';
import {Ppcde, Ppclimis, Ppcon, Ppmis} from '@app/sam-base/models/placement';
import {Ppclimiscde} from '@app/sam-base/models/placement/ppclimiscde';
import {Actions, ofType} from '@ngrx/effects';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PlacementForm} from '../../../placement.forms';

@Component({
    selector: 'iw-tab-ppcde-missions', templateUrl: './tab-ppcde-missions.component.html'
})
export class TabPpcdeMissionsComponent extends BasePartialFormComponent<Ppcde> implements OnInit, OnDestroy {

    @Input()
    public set entityId(v: string) {
        this.setEntityId(v);
    }

    @Input() public isReadonly = true;

    public type: Type<Ppclimiscde> = Ppclimiscde;

    public columns = this.getMisColumns();

    public query: RestQueryParam<Ppmis, string>[] = [];
    private subscriptions = new Subject();

    constructor(private readonly _formHandler: FormHandlerService<PlacementForm>, private readonly _entityNavService: EntityNavigationService, private _ppconService: PpconService, private readonly _events: IwEventHubService<string>, private _updates: Actions) {
        super();
    }

    public ngOnInit() {
        this.subscribeCommandeClose();
        this.subscribeDeleteSuccess();
    }

    public async setEntityId(entityId: string) {
        if (!entityId) {
            return;
        }
        const cons: Ppcon[] = await this._ppconService.getLoggedUserConseillers();
        this.query = [{
            prop: 'cdeId', operation: RestQueryOperation.Equals, value: entityId
        }, {
            operation: RestQueryOperation.In, prop: 'conId', value: cons.map(con => con.conId)
                .filter(c => !!c) as string[]
        }];
    }

    public onRowDoubleClick(event: RowClickEvent<Ppmis>) {
        const misId = (event && event.row && event.row.misId);
        if (!misId) {
            return;
        }
        this._entityNavService.navigateToEntityForm(Ppclimis, misId);
    }

    public openNewMission() {
        this._formHandler
            .showFormDialog(PlacementForm.Misnewdef, {
                conId: this.getFormValue('conId'), cliId: this.getFormValue('cliId'), cdeId: this.getFormValue('cdeId')
            });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    private subscribeCommandeClose(): void {
        this._events.forType<string>('commande_closed')
            .pipe(takeUntil(this.subscriptions))
            .subscribe((e) => {
                this.setEntityId(e.payload || '');
            });
    }

    private subscribeDeleteSuccess(): void {
        this._updates.pipe(ofType(entityActions.ENTITY_DELETE_SUCCESS))
            .pipe(takeUntil(this.subscriptions))
            .subscribe((action: entityActions.EntityDeleteSuccess<Ppmis>) => {
                const instance = isRestType(action.entityType) ? new action.entityType() : action.entityType;
                if (isRestEntity(instance) && instance.$entity === 'ppmis') {
                    this.setEntityId(instance.cdeId || '');
                }
            });
    }

    private getMisColumns(): IwGridColumn<Ppmis>[] {
        return [{
            prop: 'misId', name: 'No', index: 0
        }, {
            prop: 'empId', name: 'Employé', index: 1
        }, {
            prop: 'datedebut', name: 'Début', type: 'date', index: 2
        }, {
            prop: 'datefin', name: 'Fin', type: 'date', index: 3
        }];
    }

}
