import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Gesaexe} from '@app/sam-base/models/invoices/gesaexe';
import {GesaexeComponent} from '../components/selec-exercice/selec-exercice.component';

export const GESAEXE_DEFAULT_FORM: FormDescription<Gesaexe> = {
    form: GesaexeComponent, state: {
        ...buildDefaultState(Gesaexe), diagTitle: 'gesaexe_form_title', diagModal: false, width: 550, isNew: true
    }
};
