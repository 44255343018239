import {TabFormControl} from '@app/sam-base/core';
import {Gefachdr} from '@app/sam-base/models/invoices/gefachdr';

export const factureNCTabsFormControls: TabFormControl<Gefachdr>[] = [
    {
        formControls: [
            'facNo',
            'ageId',
            'modified',
            'facOrnc',
            'dateFac',
            'cptaRef',
            'creating']
    },
    {
        title: 'general',
        formControls: [
            'dateFac',
            'datePublished',
            'nbOfPublished',
            'dateCpta',
            'nbOfMail',
            'dateMail',
            'dateEch',
            'paiCondId',
            'datePay',
            'payId',
            'salId',
            'facOrnc',
            'mntTtc',
            'mntRecu',
            'mntEsc',
            'mntPaiSpec',
            'noRappel',
            'dateRpl',
            'nRef',
            'vRef',
            'chantier',
            'chantier2',
            'concerne',
            'mntBrut',
            'mntRabais',
            'tvaPeriode',
            'mntTvaCpta',
            'mntHt',
            'tvaInclus',
            'mntTtc',
            'mntRecu',
            'mntEsc',
            'mntPaiSpec',
            'mntTva',
            'tvaCode',
            'tvaTaux',
            'solde']
    },
    {
        title: 'detail',
        formControls: [
            'facType',
            'cptaRef',
            'faceleList']
    },
    {
        title: 'adresse',
        formControls: [
            'attn',
            'cliAdrId',
            'bvrMpaId',
            'noRefBvr',
            'nonFactors',
            'bnoFactors',
            'mutFactors',
            'facType',
            'parent',
            'facStatus',
            'modeCreat',
            'facId',
            'modified',
            'parent2',
            'ybActif',
            'ybDtSend',
            'ybStatus']
    },
    {
        title: 'qr',
        formControls: [
            'ageId',
            'cliId',
            'facId',
            'facNo']
    }];
