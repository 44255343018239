<div class="iw-header-toolbar">
    <iw-badge #ijrapports
              (clicked)="openIjReports()"
              *ngIf="showIJ && (interijobActive$ | async)"
              [color]="ijBadgeColor"
              [tooltip]="'ijReportToImp' | translate"
              [value]="ijTimesheetsNr | async"
              class="padding-right-5"></iw-badge>
    <iw-button (press)="selectGestion()"
               *ngIf="showChangeGestion"
               [tooltip]="'ouvrir_gestion' | translate"
               iconClass="fas fa-folder-open"
               id="open_gestion"
               width="25px"></iw-button>
    <iw-textfield *ngIf="showChangeGestion"
                  [value]="gestion"
                  isReadOnly="true"></iw-textfield>
    <iw-textfield *ngIf="showPeriodPlacement"
                  [value]="getCurrentDate"
                  isReadOnly="true"></iw-textfield>
    <iw-button *ngIf="showPeriodSalaire"
               [iconClass]="sagenOpen ? 'fas fa-lock-open' : 'fas fa-lock'"
               [iconPos]="'right'"
               [tooltip]="(sagenOpen ? 'pp_periode.salaire_status.open' : 'pp_periode.salaire_status.close') | translate"
               text="S"></iw-button>
    <iw-button *ngIf="showPeriodFacture"
               [iconClass]="isGeperActive ? 'fas fa-lock-open' : 'fas fa-lock'"
               [iconPos]="'right'"
               [tooltip]="(isGeperActive ? 'pp_periode.facture_status.open' : 'pp_periode.facture_status.close') | translate"
               text="F"></iw-button>
    <iw-textfield *ngIf="showPeriodSalaire"
                  [ngClass]="{'periode-open': selectedSagenOpen, 'periode-close': !selectedSagenOpen}"
                  [value]="getCurrentYearSalaire"
                  isReadOnly="true"></iw-textfield>
    <iw-help-menu #helpMenu
                  *ngIf="showHelp"
                  [menuItems]="helpMenuItems"
                  [version]="version"
                  class=""></iw-help-menu>

    <iw-dropdown (valueSelected)="changeLanguage($event)"
                 *ngIf="showLanguage"
                 [options]="languageOptions"
                 [value]="selectedLang"
                 class="padding-right-25"
                 width="60px">
    </iw-dropdown>


    <iw-button (press)="logout()"
               *ngIf="showLogout"
               iconClass="fas fa-sign-out-alt"
               name="logout"
               width="25px"></iw-button>
</div>
