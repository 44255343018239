import {Component, Input, Type, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwTableComponent} from '@app/sam-base/components/iw-table/iw-table.component';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {ComboboxItem} from '@app/sam-base/models/components/combobox-item.model';
import {Ppcli, Ppcliadr} from '@app/sam-base/models/placement';
import {PpcliadrComponent} from '@modules/sam-main/placement/components/ppcliadr/ppcliadr.component';

import {Ppcliadropts} from '@modules/sam-main/placement/components/ppcliadr/ppcliadr.options';
import {ClientService} from '../../../services/client.service';

@Component({
    selector: 'iw-tab-address', templateUrl: './tab-address.component.html', styleUrls: ['./tab-address.component.scss']
})
export class TabAddressComponent extends BasePartialFormComponent<Ppcli> {

    public type: Type<Ppcliadr> = Ppcliadr;

    public fullAdresse = '';

    @Input() public isReadonly = true;

    @Input() public uuid?: string;
    @ViewChild('ppcliadrGrid', {static: true}) public addrGrid?: IwTableComponent<Ppcliadr>;
    public cliadrId?: string;
    public ccadrId?: string;
    public query: RestQueryParam<Ppcliadr, string>[] = [];
    public ccadrIdOpts: ComboboxItem<string>[] = [];
    /**
     * Var to see if row is selected
     */
    public isSelected = false;
    public isLoading = false;
    public selectedRowData?: Ppcliadr;

    constructor(private readonly _modalService: ModalService, private readonly _clientService: ClientService) {
        super();
    }

    private _entityId?: string;

    public get entityId() {
        return this._entityId;
    }

    @Input()
    public set entityId(v: string | undefined) {
        this.setEntityId(v);
        this.filterById(v);
    }

    public get selectedData() {
        if (!this.selectedRowData) {
            return undefined;
        }
        return {name: 'cliadrId', value: this.selectedRowData.cliadrId};
    }

    /**
     * Function to select a row
     *
     * @param event - Ppcliadr[]
     */
    public onSelectedRow(event: Ppcliadr[]) {
        if (!event.length) {
            this.fullAdresse = '';
            this.isSelected = false;
            return;
        }
        this.isSelected = true;
        this.selectedRowData = event[0];
        this.fullAdresse = this.buildAdresse(event[0]);
        this.cliadrId = (event[0] && event[0].cliadrId) || '';
        this.setFormValue('cliadrId', this.cliadrId);
    }

    /**
     * Function to build full addresse
     *
     * @param p - Ppcliadr
     */
    // eslint-disable-next-line complexity
    public buildAdresse(p: Ppcliadr): string {
        if (!p) {
            return '';
        }

        let fullAdresse = '';

        if (p.prefixe) {
            fullAdresse += `${p.prefixe}\n`;
        }
        if (p.nom) {
            fullAdresse += `${p.nom}\n`;
        }
        if (p.adresse1) {
            fullAdresse += `${p.adresse1}\n`;
        }
        if (p.adresse2) {
            fullAdresse += `${p.adresse2}\n`;
        }
        if (p.adresse3) {
            fullAdresse += `${p.adresse3}\n`;
        }
        if (p.npa || p.lieu) {
            fullAdresse += `${p.npa} ${p.lieu}\n`;
        }

        return fullAdresse;
    }

    /**
     * Function to open ppcliadr form to add new addresse
     */
    public async openAddresseClient() {
        const options: Ppcliadropts = {
            cliadrId: '', cliId: this.entityId, cliFormUuid: this.uuid
        };

        try {
            await this._modalService.showModal(PpcliadrComponent, options);
            if (this.addrGrid) {
                this.addrGrid.refresh();
            }
        } catch (error) {
        }
    }

    /**
     * Function to open ppcliadr form to edit one addresse
     */
    public async editAddresseClient() {
        const options: Ppcliadropts = {
            cliadrId: this.cliadrId, cliId: this.entityId, cliFormUuid: this.uuid
        };

        try {
            await this._modalService.showModal(PpcliadrComponent, options);
            if (this.addrGrid) {
                this.addrGrid.refresh();
            }
        } catch (error) {
        }
    }

    /**
     * Function to delete adresse
     */
    public removeAddresseClient() {
        if (this.cliadrId) {
            this.isLoading = true;
            this._clientService.deleteCliadrAndCascadeChanges(this.cliadrId)
                .subscribe({
                    next: () => {
                        this.addrGrid?.refresh();
                        this.isLoading = false;
                    },

                    error: () => {
                        this.isLoading = false;
                    }
                });
        }
    }

    public setDropdownOptions(addresses: Ppcliadr[]) {
        this.ccadrIdOpts = addresses.map((cliadr: Ppcliadr) => ({
            name: cliadr.adrlib ?? '', value: cliadr.cliadrId ?? ''
        }));
    }

    public setDefaultAddress() {
        if (this.selectedRowData) {
            this.setFormValue('cliadrId', this.selectedRowData.cliadrId);
            this.setFormValue('prefixe', this.selectedRowData.prefixe);
            this.setFormValue('nom', this.selectedRowData.nom);
            this.setFormValue('adresse1', this.selectedRowData.adresse1);
            this.setFormValue('adresse2', this.selectedRowData.adresse2);
            this.setFormValue('adresse3', this.selectedRowData.adresse3);
            this.setFormValue('npa', this.selectedRowData.npa);
            this.setFormValue('lieu', this.selectedRowData.lieu);
        }
    }

    private setEntityId(v?: string) {
        if (v && this._entityId !== v) {
            this._entityId = v;
        }
    }

    private filterById(cliId?: string) {
        this.query = [{
            operation: RestQueryOperation.Equals, prop: 'cliId', value: cliId || '_'
        }];
    }
}
