<div class="row">
    <div class="col-xs-10" style="height: 200px;">
        <iw-rest-grid #ppcctConfigGrid (selected)="getSelectedRow($event)" (sortInfo)="setSortInfo($event)"
                      [autoFormClick]="false" [defaultSelected]="true" [forceSelected]="true" [hasFilter]="true"
                      [hasGridColumnMenu]="false" [hasSortIcon]="true" [itemsPerPage]="1000" [queryStatements]="query"
                      [selectedData]="selectedData" [showGridDetails]="false" [sortDir]="sortPropDir"
                      [sortProp]="sortProp" [type]="type"></iw-rest-grid>
    </div>

    <div class="col-xs-2 flex flex-v flex-evenly">
        <!--  This stays here if we ever want to add a new entry -->
        <iw-button (press)="createNewEntry()" [isDisabled]="!readonly" [text]="'sidemenu_new' | translate"
                   id="sidemenu_new"></iw-button>
        <iw-button (press)="modifyEntry()" *ngIf="readonly"
                   [isDisabled]="!readonly || !selectedRow || getFormValue('cctstatus') === '0'"
                   [text]="'sidemenu_edit' | translate" id="sidemenu_edit"></iw-button>
        <iw-button (press)="saveEntry()" *ngIf="!readonly" [isDisabled]="!formGroup.valid"
                   [text]="'sidemenu_save' | translate" id="sidemenu_save"></iw-button>
        <!--  This stays here if we ever want to delete entry -->
        <iw-button (press)="removeEntry()" *ngIf="readonly" [isDisabled]="!readonly || !selectedRow"
                   [text]="'sidemenu_delete' | translate" id="sidemenu_delete"></iw-button>
        <iw-button (press)="cancelEditionMode()" *ngIf="!readonly" [text]="'sidemenu_cancel' | translate"
                   id="sidemenu_cancel"></iw-button>
        <iw-button #btnOpenClose (press)="openCloseCCT()" [isDisabled]="!readonly" [text]="'oF' | translate"
                   [tooltip]="'activerDesactiverCCT' | translate" id="btnOpenClose"></iw-button>
        <iw-button #btnMajCli (press)="''" [isDisabled]="true" [text]="'majClient' | translate" id="MajCli"></iw-button>
        <iw-button #btnCopy (press)="copyCCT()" [isDisabled]="!selectedRow" [tooltip]="'copy_cct' | translate"
                   class="btn-block" iconClass="fas fa-copy" id="copy"></iw-button>
    </div>

</div>

<form [formGroup]="formGroup" class="row no-padding">
    <div class="row col-xs-10 no-padding">
        <iw-textfield #TxtSearchterm [(value)]="searchTerm" [isDisabled]="readonly" [labelAlign]="'top'"
                      [label]="'searchByTermCct' | translate" class="iw-input col-xs-5"></iw-textfield>

        <iw-enum-dropdown #cboLanguageSelect [inputEnum]="cctLanguageOptions" [isDisabled]="readonly"
                          [labelAlign]="'top'" [label]="'langue' | translate" class="col-xs-3"
                          formControlName="tempdataTrans"></iw-enum-dropdown>

        <iw-button (press)="searchByTerm()" [iconClass]="'fa fa-search'" [isDisabled]="tempdataSearchDisabled"
                   class="flex-end col-xs-1 no-padding-left"></iw-button>

        <div class="col-xs-12"></div>

        <iw-textfield #TxtcontractNo [isDisabled]="true" [labelAlign]="'top'" [label]="'contractNo' | translate"
                      class="iw-input col-xs-3" formControlName="contractNo"></iw-textfield>

        <iw-textfield #TxtcontractNo [isDisabled]="true" [labelAlign]="'top'" [label]="'tempdata_title' | translate"
                      class="iw-input col-xs-9" formControlName="tempdataTitle"></iw-textfield>
        <iw-textfield #txtCct_id [isDisabled]="!newEntryMode" [labelAlign]="'top'" [label]="'code' | translate"
                      [ngClass]="getCctClass()" class="iw-input col-xs-2" formControlName="cctId"
                      id="cctId"></iw-textfield>
        <iw-textfield #txtCct_lib [isDisabled]="readonly" [labelAlign]="'top'" [label]="'description' | translate"
                      class="iw-input col-xs-10" formControlName="cctLib" id="cctLib"></iw-textfield>
        <iw-date-picker #txtDateDebut [isDisabled]="readonly" [labelAlign]="'top'" [label]="'depuis' | translate"
                        [showWeekDay]="false" [showWeekNumber]="false" class="iw-input col-xs-2 no-padding-right"
                        formControlName="dateDe"></iw-date-picker>
        <iw-date-picker #txtDateDebut [isDisabled]="readonly" [labelAlign]="'top'" [label]="'jusqua' | translate"
                        [showWeekDay]="false" [showWeekNumber]="false" class="iw-input col-xs-2"
                        formControlName="dateA"></iw-date-picker>
        <iw-checkbox [isDisabled]="readonly" [label]="'grouperFc_execution' | translate"
                     class="iw-input col-xs-5 margin-top-25" formControlName="lexfcgroup"></iw-checkbox>
        <iw-cctgroup-dropdown #cboCctgrp_id [isDisabled]="readonly" [labelAlign]="'top'"
                              [label]="'groupCct' | translate" class="iw-combobox col-xs-8"
                              formControlName="cctgrpId"></iw-cctgroup-dropdown>
        <iw-tab #tabs [(selectedTab)]="selectedTab" [invalidTabs]="invalidTabList" [tabs]="tabList"
                class="col-xs-12 padding-top-20">
            <iw-tab-ra #tabRa *ngIf="selectedTab === tabList[0]" [formGroup]="formGroup" [isReadonly]="readonly"
                       id="tabRa"></iw-tab-ra>
            <iw-tab-cct-execution #tabExecution *ngIf="selectedTab === tabList[1]" [formGroup]="formGroup"
                                  [isReadonly]="readonly" id="tabExecution"></iw-tab-cct-execution>
            <iw-tab-cct-formation #tabFormation *ngIf="selectedTab === tabList[2]" [formGroup]="formGroup"
                                  [isReadonly]="readonly || isLexfcgroupChecked"
                                  id="tabFormation"></iw-tab-cct-formation>
            <iw-tab-pgm #tabPgm *ngIf="selectedTab === tabList[3]" [formGroup]="formGroup" [isReadonly]="readonly"
                        id="tabPgm"></iw-tab-pgm>
            <iw-tab-cct-autre #tabExecution *ngIf="selectedTab === tabList[4]" [formGroup]="formGroup"
                              [isReadonly]="readonly" id="tabExecution"></iw-tab-cct-autre>
            <iw-tab-ctt-remarques #tabMission *ngIf="selectedTab === tabList[5]" [formGroup]="formGroup"
                                  [isReadonly]="readonly" id="tabMission"></iw-tab-ctt-remarques>

        </iw-tab>
    </div>
    <div class="row col-xs-2 no-padding">
        <iw-button #Closebutton (press)="destroyForm()" [text]="'fermer' | translate"
                   class="iw-button col-xs-12 flex-end"></iw-button>
    </div>
</form>
