import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Template} from '@sam-base/models/placement';

import {GestionModelesComponent} from '../components/gestion-modeles/gestion-modeles.component';

export const GESTION_MODELES: FormDescription<Template> = {
    form: GestionModelesComponent,
    state: {
        ...buildDefaultState(Template),
        diagModal: true,
        diagTitle: 'documents_temp',
        width: 800,
        isNew: true
    }
};
