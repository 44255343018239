import { HttpClient } from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {RestEntityClient} from '@app/sam-base/core/rest-api';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {ToastService} from '@app/sam-base/core/toast/toast.service';
import {ComboboxItem, Ijrapsaisiemode} from '@app/sam-base/models';
import {Ppcli, Ppemp, Ppmis} from '@app/sam-base/models/placement';
import {MissionContracts} from '@app/sam-base/models/placement/mission-contracts';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {lastValueFrom} from 'rxjs';

import {MissionInterijobService} from '../../../services/mission-interijob.service';
import {PublishDropdownOptions} from './MissionContractIJEnums';

export interface IjData {
    IJAPIMODE: string;
    IJHTTPS: string;
    IJAUTHENTICATIONMODE: string;
    IJRAP: string;
    IJPT: string;
    IJSOCPWD: string;
    IJURL: string;
    WEBRAP: string;
    WEBCDEEXP: string;
    IJCDEEXP: string;
    IJMIS: string;
    IJSOCNAME: string;
    IJHEUTYPES: string;
    IJSIG: string;
    WEBCDEIMP: string;
    IJCDEIMP: string;
    IJFACEXP: string;
}

@Component({
    selector: 'iw-tab-ppmis-ij', templateUrl: './tab-ppmis-ij.component.html'
})
export class TabPpmisIjComponent extends BasePartialFormComponent<Ppmis> implements OnInit {

    public ijrapsaisiemode = Ijrapsaisiemode;
    public ijSigOn = false;
    public ccPdfFname = '';
    public cmPdfFname = '';

    public icm2Ijmod?: number;
    public icc2Ijmod?: number;

    public publishDropdownOptions: ComboboxItem<number>[] = [{
        name: this._translate.instant('combo_aucune'), value: PublishDropdownOptions.Aucune
    }, {
        name: this._translate.instant('combo_pdf'), value: PublishDropdownOptions.Pdf
    }];

    private readonly _ppcliRest: RestEntityClient<Ppcli>;
    private readonly _ppempRest: RestEntityClient<Ppemp>;

    public get contratLocationDisabled(): boolean {
        return this.getFormValue('icc2Ijmod') === PublishDropdownOptions.Aucune;
    }

    public get contratMissionDisabled(): boolean {
        return this.getFormValue('icm2Ijmod') === PublishDropdownOptions.Aucune;
    }

    constructor(private _parametersService: ParametersService, private _translate: TranslateService, private _http: HttpClient, private _toastService: ToastService, private readonly _modalService: ModalService, _restService: RestApiService, private _missionIjService: MissionInterijobService) {
        super();
        this._ppcliRest = _restService.getEntityClient(Ppcli);
        this._ppempRest = _restService.getEntityClient(Ppemp);
    }

    public ngOnInit() {
        this.icm2Ijmod = this.getFormValue('icm2Ijmod');
        this.icc2Ijmod = this.getFormValue('icc2Ijmod');

        if (this.getFormValue('missalmode') === undefined) {
            this.setFormValue('missalmode', 1);
        }
        this.subscribeValueChange('lexpmis2Ij', () => this.checkMisIj());
        this._parametersService.getDataText<IjData>('ijData')
            .subscribe((data) => {
                this.addPublishDropdownOptions(data);
            });
    }

    public async checkMisIj() {
        if (!this.getFormValue('lexpmis2Ij')) {
            const options: ModalMessageOptions = {
                message: [this._translate.instant('delete_mission_ij')],
                showCancel: false,
                title: this._translate.instant('mission')
            };
            await this._modalService.showModal(ModalMessageComponent, options);
        }
    }

    public lexpmis2IjEnabled() {
        return this.getFormValue('missalmode') === 1 && (this.getFormValue('misstatus') === 1 || this.getFormValue('misstatus') === 2);
    }

    public canEditCCEmail() {
        return (this.getFormValue('lexpmis2Ij') && Number(this.getFormValue('icc2Ijmod')) > 2);
    }

    public canEditCMEmail() {
        return (this.getFormValue('lexpmis2Ij') && Number(this.getFormValue('icm2Ijmod')) > 2);
    }

    public publish(pcwhat: 'CC' | 'CM') {
        const misId: string | undefined = this.getFormValue('misId');
        if (!misId) {
            return;
        }

        this._missionIjService.publishMission(misId, this.getIjDocumentType(pcwhat), this.getIjMode(pcwhat))
            .subscribe((published: boolean) => {
                if (published) {
                    this._toastService.success('mission_publish_success');
                } else {
                    this._toastService.error('mission_publish_error');
                }
            });
    }

    public unpublish(pcwhat: 'CC' | 'CM') {
        this._http.delete(environment.backendURL + 'mission/' + this.getFormValue('misId') + '/' + 'contract/' + pcwhat)
            .subscribe(() => this._toastService.success('contract_depublication_success'), error => this._toastService.error('contract_depublication_error'));
    }

    // eslint-disable-next-line complexity
    public async setEmail(event: number, pcwhat: 'CC' | 'CM') {
        if (this.isReadonly) {
            return;
        }
        if (event === 3 && pcwhat === 'CC' && !this.getFormValue('ccemail')) {
            try {
                await this.setEmailModal(this._translate.instant('resumeClientEmail'));
                const cliId: string | undefined = this.getFormValue('cliId');
                if (!cliId) {
                    return;
                }
                const cli = await lastValueFrom(this._ppcliRest.getById(cliId));
                this.setFormValue('ccemail', cli.email);
            } catch (error) {
            }
        }
        if (event === 3 && pcwhat === 'CM' && !this.getFormValue('cmemail')) {
            try {
                await this.setEmailModal(this._translate.instant('resumeEmployeEmail'));
                const empId: string | undefined = this.getFormValue('empId');
                if (!empId) {
                    return;
                }
                const emp = await lastValueFrom(this._ppempRest.getById(empId));
                this.setFormValue('cmemail', emp.email);
            } catch (error) {
            }
        }
    }

    public async setEmailModal(message: string) {
        const options: ModalMessageOptions = {
            message: [],
            showCancel: false,
            title: this._translate.instant('alerte'),
            confirmMessage: message,
            alertsMessage: this._translate.instant('alertsmsg'),
            okDisabled: false
        };
        await this._modalService.showModal(ModalMessageComponent, options);
    }

    private addPublishDropdownOptions(data: IjData) {
        if (data.IJSIG === '1' && data.IJSIG) {
            this.ijSigOn = true;
            this.publishDropdownOptions.push({
                name: this._translate.instant('combo_pdfDemandeSignature'),
                value: PublishDropdownOptions.PdfWithSignature
            });
        } else {
            this.ijSigOn = false;
        }

        this.setFormValue('icm2Ijmod', this.icm2Ijmod);
        this.setFormValue('icc2Ijmod', this.icc2Ijmod);
    }

    private getIjMode(pcwhat: 'CC' | 'CM'): number {
        if (pcwhat === 'CC') {
            return this.getFormValue('icc2Ijmod') ?? PublishDropdownOptions.Aucune;
        }
        return this.getFormValue('icm2Ijmod') ?? PublishDropdownOptions.Aucune;
    }

    private getIjDocumentType(pcwhat: 'CC' | 'CM'): MissionContracts {
        if (pcwhat === 'CC') {
            return MissionContracts.location;
        }
        return MissionContracts.mission;
    }

}
