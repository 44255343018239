<form [formGroup]="formGroup"
      class="row">
    <iw-textfield #txtUser_id
                  [isDisabled]="true"
                  [label]="'attest' | translate"
                  class="iw-input col-xs-2 padding-left-0"
                  formControlName="acaId"></iw-textfield>
    <iw-textfield #txtUser_id
                  [(value)]="empName"
                  [isDisabled]="true"
                  [label]="'employe' | translate"
                  class="iw-input col-xs-4 padding-right-0"></iw-textfield>
    <iw-textfield #txtUser_id
                  [isDisabled]="true"
                  [label]="'' | translate"
                  class="iw-input col-xs-1 no-label padding-left-0"
                  formControlName="empId"></iw-textfield>
    <iw-button #empBtn
               (press)="openEmp()"
               [isDisabled]="false"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>
    <iw-date-picker #txtDatenaiss
                    [isDisabled]="true"
                    [labelAlign]="'top'"
                    [label]="'date' | translate"
                    class="iw-input col-xs-2 col-no-padding"
                    formControlName="dateaca"></iw-date-picker>
    <iw-month-picker #txtDatenaiss
                     [isDisabled]="!isWriteMode"
                     [labelAlign]="'top'"
                     [label]="'date' | translate"
                     class="iw-input col-xs-2 col-no-padding"
                     formControlName="salIdLim"></iw-month-picker>

    <iw-button (press)="printAttestation()"
               [iconClass]="'fas fa-print'"
               class="btn-block col-xs-1 flex-end"></iw-button>

</form>

<iw-tab [(selectedTab)]="selectedTab"
        [invalidTabs]="invalidTabList"
        [tabs]="tabList">

    <iw-tab-annes *ngIf="selectedTab === tabList[0]"
                  [cac2]="cac2"
                  [cac4]="cac4"
                  [isReadonly]="!isWriteMode"></iw-tab-annes>

    <iw-tab-ppacattest-periodes *ngIf="selectedTab === tabList[1]"
                                [cac3]="cac3"
                                [isReadonly]="!isWriteMode"></iw-tab-ppacattest-periodes>


    <iw-tab-historiquemission *ngIf="selectedTab === tabList[4]"
                              [cuhismis]="cuhismis"
                              [formGroup]="formGroup"
                              [isReadonly]="!isWriteMode"></iw-tab-historiquemission>

    <iw-tab-derniere-mission *ngIf="selectedTab === tabList[2]"
                             [isReadonly]="!isWriteMode"
                             [mis]="lastMis"></iw-tab-derniere-mission>

    <iw-tab-premiere-mission *ngIf="selectedTab === tabList[3]"
                             [isReadonly]="!isWriteMode"
                             [mis]="firstMis"></iw-tab-premiere-mission>
</iw-tab>
