<form [formGroup]="formGroup"
      class="row">

    <iw-template-dropdown #cboContMission
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'contratDeMission' | translate"
                          [report]="'CM'"
                          [size]="'large'"
                          class="iw-input iw-input col-xs-8 margin-top-10"
                          formControlName="contractmis"></iw-template-dropdown>
    <div class="col-xs-4"></div>

    <iw-template-dropdown #cboAveContMission
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'avenantContractDeMission' | translate"
                          [report]="'AVCM'"
                          [size]="'large'"
                          class="iw-input iw-input col-xs-8 margin-top-10"
                          formControlName="avecontractmis"></iw-template-dropdown>
    <div class="col-xs-4"></div>

    <iw-template-dropdown #cboContLocation
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'contratLocation' | translate"
                          [report]="'CC'"
                          [size]="'large'"
                          class="iw-input iw-input col-xs-8 margin-top-10"
                          formControlName="contractloc"></iw-template-dropdown>
    <div class="col-xs-4"></div>

    <iw-template-dropdown #cboAveContLocation
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'avenantContractDeLocation' | translate"
                          [report]="'AVCC'"
                          [size]="'large'"
                          class="iw-input iw-input col-xs-8 margin-top-10"
                          formControlName="avecontractloc"></iw-template-dropdown>
    <div class="col-xs-4"></div>

    <iw-template-dropdown #cboPlaFixe
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'placement_fixe' | translate"
                          [report]="'PF'"
                          [size]="'large'"
                          class="iw-input iw-input col-xs-8 margin-top-10"
                          formControlName="placefix"></iw-template-dropdown>
    <div class="col-xs-4"></div>

    <iw-template-dropdown #cboDossierCandi
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'dossierCandidat' | translate"
                          [report]="'DC'"
                          [size]="'large'"
                          class="iw-input iw-input col-xs-8 margin-top-10"
                          formControlName="dossiercan"></iw-template-dropdown>
    <div class="col-xs-4"></div>

</form>
