import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppoptsal} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';

import {CodeCna, CodePgm, ModeCantonImpot} from '../../salary.model';

@Component({
    selector: 'iw-tab-salary-deductions', templateUrl: './tab-salary-deductions.component.html'
})
export class TabSalaryDeductionsComponent extends BasePartialFormComponent<Ppoptsal> {

    public optionsCodeCna = CodeCna;
    public optionsCodePgm = CodePgm;
    public modeCantonImpot = ModeCantonImpot;

    constructor(private readonly _translate: TranslateService) {
        super();
    }

    public getLabel(val: string): string | undefined {
        const active = `cu${val}Active` as keyof Ppoptsal;
        const titree = `cu${val}Titre` as keyof Ppoptsal;
        return this.getFormValue(active) ? this.getFormValue(titree) : this._translate.instant('deduction');
    }
}
