import {Component} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys} from '@app/sam-base/models';
import {Sapar, Saparded} from '@app/sam-base/models/salary';
import {salaryDeductionTabsFormControls} from './salaryDeduction-tabs-formcontrols';

@Component({
    templateUrl: './salaryDeduction.component.html'
})
export class SalaryDeductionComponent extends BaseTabFormComponent<Saparded> {
    public sapar?: Sapar;

    constructor(private store: IwStoreService) {
        super(store);
    }

    public get tabData(): Saparded | undefined {
        return this.getFormData();
    }

    public set tabData(v: Saparded | undefined) {
        this.mergeEntityChanges(v);
    }

    public checkIfNew() {
        return this.isNew;
    }

    public getTabsValues(): TabFormControl<Saparded>[] {
        return salaryDeductionTabsFormControls;
    }

    protected getValidationType() {
        return Saparded;
    }

    protected validateFields(e: Saparded): boolean {
        return super.validateFields(e);
    }

    protected getFormControlNames(): FormKeys<Saparded> {
        return this.getTabsFormControls(salaryDeductionTabsFormControls);
    }
}
