<div class="flex flex-v flex-evenly">
    <iw-textfield #txtUser_id [isDisabled]="true" [noLabel]="true" [value]="sags?.userId"
                  class="iw-textfield col-xs-12 padding-top-20 padding-bottom-5"></iw-textfield>
    <iw-textfield #txtDate_creat [isDisabled]="true" [noLabel]="true" [value]="dateCreat"
                  class="iw-textfield col-xs-12 padding-bottom-5"></iw-textfield>
    <iw-textfield #txtDate_modif [isDisabled]="true" [noLabel]="true" [value]="dateModif"
                  class="iw-textfield col-xs-12 padding-bottom-20"></iw-textfield>
    <iw-textfield #txtSaparAnnee [isDisabled]="true" [noLabel]="true" [value]="sapar?.annee"
                  class="iw-textfield col-xs-12 padding-bottom-20"></iw-textfield>
</div>
