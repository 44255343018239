import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@root/environments/environment';
import {Observable} from 'rxjs';
import {RappelsModel} from './../../models/rappels.model';

@Injectable()
export class RappelsService {

    constructor(private _http: HttpClient) {
    }

    public getRappelsTable(facId: string): Observable<RappelsModel[]> {
        return this._http.get<RappelsModel[]>(environment.backendURL + 'gerappelview/' + facId);
    }
}
