<form [formGroup]="formGroup" class="row">
    <div class="row col-xs-12">

        <div class="row col-xs-4 col-no-padding">
            <iw-multiautocomplete-qualif #txtProfession [doubleClickable]="true" [isDisabled]="isReadonly"
                                         [labelAlign]="'top'" [label]="'professionQualification' | translate"
                                         class="iw-input col-xs-12 col-no-padding" formControlName="qualif"
                                         id="qualif"></iw-multiautocomplete-qualif>
            <iw-textarea #qualifDescription [height]="72" [isDisabled]="isReadonly" class="iw-textarea col-xs-12"
                         formControlName="qualifDescription" id="qualifDescription"></iw-textarea>
        </div>

        <iw-textfield #edtRegions [isDisabled]="true" [labelAlign]="'top'" [label]="'regions' | translate"
                      class="iw-input col-xs-3" formControlName="region"></iw-textfield>

        <iw-button #btnRegions (press)="openRegionsSelection()" [isDisabled]="isReadonly" [text]="'TEXT' | translate"
                   class="iw-button col-xs-1 without-label no-padding"></iw-button>


        <iw-ppkw-matching #kwMatching [formId]="formId" [isReadonly]="false" [isReadonly]="isReadonly || !entityId"
                          [label]="'mots_clefs' | translate" [parentId]="entityId" [type]="'ppcde'"
                          class="col-xs-4"></iw-ppkw-matching>


    </div>

    <div class="row col-xs-8">
        <iw-textarea #needSummaryTxt [allowMaximize]="false" [height]="120" [isDisabled]="isReadonly"
                     [label]="'schedule_event_change_ppmis' | translate" class="iw-textarea col-xs-12"
                     formControlName="needSummary" style="padding: 0 0.5rem"></iw-textarea>

        <iw-button #btnCreatNeed (press)="openScheduleEventComp()" [isDisabled]="isReadonly"
                   [text]="'schedule_event_change_ppmis_btn' | translate" class="iw-button margin-top-5"
                   style="padding: 0 0.5rem"></iw-button>
    </div>

    <div class="col-xs-4" style="padding-top: 24px">
        <iw-button (press)="navigateToPpempAdvSearch()" [text]="'launch_adv_search_btn' | translate" [width]="160"
                   class="col-xs-5"></iw-button>
    </div>

</form>
