import {IwRestEntity, IwRestEntityId} from '@sam-base/core';


export class JcptaErr {
    errText?: string;
    empId?: string;
}

export class JcptaResult {

    // jcptaList?: Jcpta[];
    jcptaErr?: JcptaErr[];
    // jcptaRep?: JcptaRep[];
    // jcptagList?: Jcptag[];
    // jcptaRepgList?: JcptaRepg = [];

    nLinNo?: number;
    nEcrNo?: number;
    nChkMnt?: number;
    nbErrors?: number;
}

// fake rest entity
@IwRestEntity('gecompta')
export class Sacompta {
    @IwRestEntityId() salId?: string;
    cptagrpemp?: boolean;
    cptagrpgs?: boolean;
    cptaan?: boolean;
    cptaanobli?: boolean;
    cptatxfcoe?: boolean;
    cptatxfpay?: boolean;
    cptatxfchq?: boolean;
    nbEcrSal?: number;
    nbEcrPay?: number;
    nbErreurs?: number;
    compteNap?: string;
    jcptaResult?: JcptaResult;
}

