import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {
    SalaryCountRequest,
    SalaryCountResponse,
    SalaryGenerateRequest,
    SalaryInitRequest,
    SalaryProcess,
    SalaryProcessDetails
} from '@app/sam-base/models/placement';
import {Observable} from 'rxjs';

@Injectable()
export class SalaryProcessService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getSalaryCount(req: SalaryCountRequest): Observable<SalaryCountResponse[]> {
        return this.POST<SalaryCountResponse[]>(req, undefined, 'salary-process/count');
    }

    public listSalaryProcess(): Observable<SalaryProcess[]> {
        return this.GET<SalaryProcess[]>(undefined, 'salary-process');
    }

    public getSalaryProcessDetails(processId: number): Observable<SalaryProcessDetails> {
        return this.GET<SalaryProcessDetails>(undefined, `salary-process/${processId}`);
    }

    public initSalaryProcess(req: SalaryInitRequest): Observable<SalaryProcessDetails> {
        return this.POST<SalaryProcessDetails>(req, undefined, 'salary-process');
    }

    public generateSalary(req: SalaryGenerateRequest): Observable<SalaryProcessDetails> {
        return this.POST<SalaryProcessDetails>(req, undefined, `salary-process/${req.salaryProcessId}/generate`);
    }

    public calculateSalary(req: SalaryGenerateRequest): Observable<SalaryProcessDetails> {
        return this.POST<SalaryProcessDetails>(req, undefined, `salary-process/${req.salaryProcessId}/calculate`);
    }

    public cancelGenerationSalary(req: SalaryGenerateRequest): Observable<SalaryProcessDetails> {
        return this.POST<SalaryProcessDetails>(req, undefined, `salary-process/${req.salaryProcessId}/cancel-generation`);
    }

    /**
     * Delete init data
     *
     * @param salaryProcessId
     */
    public delete(salaryProcessId: number): Observable<void> {
        return this.DELETE(undefined, `/salary-process/${salaryProcessId}`);
    }

    public reassign(salaryProcessId: number): Observable<void> {
        return this.PUT(undefined, undefined, `/salary-process/${salaryProcessId}/reassign`);
    }
}
