<form [formGroup]="formGroup" autocomplete="no" class="row spaceForm">

    <div class="form-host row col-xs-12">
        <iw-textfield-lookups #txtPrefixe [isDisabled]="isReadonly" [labelAlign]="'left'"
                              [label]="'prefixe' | translate" [lookupName]="'PREFIXE'"
                              class="iw-input  col-xs-12 col-no-padding"
                              formControlName="prefixe"></iw-textfield-lookups>

        <iw-textfield #txtNom [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'nom' | translate"
                      [warningMessage]="'errorClientFieldSize' | translate" class="iw-input col-xs-12 col-no-padding"
                      formControlName="nom" id="textNom"></iw-textfield>

        <iw-textfield #txtAdresse1 [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'rueEtNumero' | translate"
                      class="iw-input col-xs-12 col-no-padding" formControlName="adresse1"></iw-textfield>

        <iw-textfield #txtAdresse2 [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'adresse' | translate"
                      class="iw-input col-xs-12 col-no-padding" formControlName="adresse2"></iw-textfield>

        <iw-textfield #txtAdresse3 [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="' '"
                      class="iw-input col-xs-12 col-no-padding" formControlName="adresse3"></iw-textfield>

        <iw-npa-lieu [formGroup]="formGroup" [isDisabled]="isReadonly" [labelNpaAlign]="'left'"
                     [lieuFormControlName]="'lieu'" [npaFormControlName]="'npa'" [pays]="pays"
                     class="iw-input col-xs-12 col-no-padding"></iw-npa-lieu>


        <iw-enum-dropdown #cboPays [inputEnum]="countriesAlpha2List" [isDisabled]="isReadonly" [labelAlign]="'left'"
                          [label]="'pays' | translate" class="iw-input col-xs-11 col-no-padding"
                          formControlName="pays"></iw-enum-dropdown>

        <iw-button #btnCopyAdr2Clipboard (press)="addToClipboard('adresse')" [tooltip]="'copierDans' | translate"
                   class="iw-button col-xs-1" iconClass="fas fa-copy"></iw-button>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-phone-textfield #txtTel1 [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'telephone' | translate"
                            class="iw-input col-xs-6 col-no-padding" formControlName="tel1"></iw-phone-textfield>

        <div class="col-xs-3"></div>

        <iw-phone-textfield #txtNatel [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'natel' | translate"
                            class="iw-input col-xs-6 col-no-padding" formControlName="natel"></iw-phone-textfield>

        <div class="col-xs-3"></div>

        <iw-textfield #txtFax [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'fax' | translate"
                      class="iw-input col-xs-6 col-no-padding" formControlName="fax"></iw-textfield>

        <div class="col-xs-3"></div>

        <iw-email-textfield #txtEmail [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'eMail' | translate"
                            class="iw-input col-xs-6 col-no-padding" formControlName="email"
                            id="textEmail"></iw-email-textfield>

        <iw-button #btnCopy2ClipBoard (press)="addToClipboard('email')" [tooltip]="'copierDans' | translate"
                   class="iw-button col-xs-1 col-no-padding" iconClass="fas fa-copy"></iw-button>

        <iw-textfield #txtWeb [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'web' | translate"
                      class="iw-input col-xs-6 col-no-padding" formControlName="www"></iw-textfield>

        <div class="col-xs-6"></div>

        <iw-textfield #txtWeb [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'tvaNoIde' | translate"
                      [mask]="'000.000.000'" class="iw-input col-xs-6 col-no-padding"
                      formControlName="tvaNoIde"></iw-textfield>

        <div class="col-xs-6"></div>

        <iw-button #btnCat (press)="openCategories()" [isDisabled]="isReadonly" [text]="'Categories..'" [width]="100"
                   class="iw-button padding-right-20"></iw-button>

        <iw-textarea #edtCctids [height]="22" [isDisabled]="isReadonly" class="iw-textarea col-xs-5"
                     formControlName="categids"></iw-textarea>
    </div>


</form>
