import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestApiService, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {Saenf} from '@app/sam-base/models/common';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class SaenfService extends RestClient<any> {

    public saenfData = new Subject<Saenf[]>();

    constructor(private _restService: RestApiService, public readonly _http: HttpClient) {
        super(_http);
    }

    public getEnfsByEmpId(empId: string): Observable<Saenf[]> {
        return this.GET<Saenf[]>(undefined, `/saenf/emp/${empId}`);
    }

    public updateCounts(empId: string): Observable<void> {
        return this.PUT<void>(undefined, undefined, `/saenf/empUpdate/${empId}`);
    }

    public updateSaenf(empId: string) {
        this.getSaenf(empId)
            .subscribe(res => this.saenfData.next(res));
    }

    private getSaenf(empId: string) {

        const saenfQuery: RestQueryParam<Saenf, string>[] = [{
            operation: RestQueryOperation.Equals,
            prop: 'empId',
            value: empId
        }];
        return this._restService.getEntityQuery(Saenf, ...saenfQuery)
            .scroll();
    }
}
