import { HttpClient } from '@angular/common/http';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base';
import {MessageFormRefreshService} from '@app/sam-base/components/subjects/message-service-subject.service';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {LoadEntity, SetLoading} from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxProcessFlow} from '@sam-base/models/admin/sx-process-flow.enum';
import {Subject, takeUntil} from 'rxjs';

@Component({
    templateUrl: './sasx5institution-side-menu.component.html'
})
export class Sasx5institutionSideMenuComponent extends BaseSideFormComponent<Sasx5institution> implements OnInit, AfterViewInit, OnDestroy {

    public institution?: Sasx5institution;
    public gettingResult = false;
    private subscriptions = new Subject();
    private loading = false;

    constructor(private readonly _store: IwStoreService,
                private readonly _toastService: ToastService, private readonly _modalService: ModalService,
                private readonly _translate: TranslateService, private readonly _http: HttpClient,
                private readonly _toast: ToastService, private readonly messageService: MessageFormRefreshService,
                _restService: RestApiService) {
        super();
    }

    /**
     * getResultDisabled will return true when the application is either in the loading state,
     * fetching a result or the processFlow of the institution is not in either RESULT or COMPLETION_AND_RESULT state.
     * In other scenarios, it will return false.
     */
    public get getResultDisabled(): boolean {
        return this.loading || this.gettingResult || [SxProcessFlow.RESULT,
            SxProcessFlow.COMPLETION_AND_RESULT]
            .indexOf(this.institution?.processFlow || SxProcessFlow.UNKNOWN) === -1;
    }

    public ngOnInit() {
        this.messageService.getMessage()
            .pipe(takeUntil(this.subscriptions))
            .subscribe(message => {
                if (message && 'institution.refresh' === message) {
                    this._store.dispatch(new LoadEntity(this.uuid));
                }
            });

        this._store.globalForm(this.uuid)
            .isLoading
            .pipe(takeUntil(this.subscriptions))
            .subscribe(loading => {
                this.loading = loading;
            });


    }

    public ngOnDestroy(): void {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public ngAfterViewInit(): void {
        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data: any) => {
                this.institution = data;
            });
    }

    public getResultFromDeclareSalary() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        this.gettingResult = true;
        return this._http.get<any>(// eslint-disable-next-line max-len
            environment.backendURL + `swissdec/institutions/${this.institution?.id}/get-result-from-declare-salary`)
            .subscribe(inst => {
                this._toast.success('get_result_from_declare_salary_success');
                this._store.dispatch(new SetLoading(this.uuid, false));
                this._store.dispatch(new LoadEntity(this.uuid));
                this.gettingResult = false;
            }, (err) => {
                this._toast.error('get_result_from_declare_salary_error');
                this._store.dispatch(new SetLoading(this.uuid, false));
                this.gettingResult = false;
            });
    }


}
