import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {Pppar} from '@app/sam-base/models/placement';

/**
 * Tab General of parametres Generaux
 */
@Component({
    selector: 'iw-tab-pppar-rating', templateUrl: './tab-pppar-rating.component.html'
})
export class TabPpparRatingComponent extends BasePartialFormComponent<Pppar> {

    constructor(_restService: RestApiService) {
        super();
    }
}
