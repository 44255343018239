<form [formGroup]="formGroup"
      class="row">

    <iw-textfield [isDisabled]="true"
                  [noLabel]="true"
                  [value]="empnom"
                  class="iw-textfield col-xs-8"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  [noLabel]="true"
                  class="iw-textfield col-xs-2"
                  formControlName="empId"></iw-textfield>
    <iw-button #empBtn
               (press)="openEmp()"
               [isDisabled]="false"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>


    <iw-textfield [isDisabled]="true"
                  [label]="'annee' | translate"
                  [value]="annee"
                  class="iw-textfield col-xs-2"></iw-textfield>
    <iw-dropdown #drpPeriod
                 [isDisabled]="isWriteMode"
                 [labelAlign]="'top'"
                 [label]="'combo_periode_salaire' | translate"
                 [ngClass]="periodeCssClass"
                 [options]="salIdcbo"
                 class="iw-input col-xs-2"
                 formControlName="salId"></iw-dropdown>
    <iw-textfield [isDisabled]="true"
                  [label]="'status' | translate"
                  [ngClass]="ficheStatusCssClass"
                  [value]="saemsEnumStatus | translate"
                  class="iw-textfield col-xs-2 text-align-right"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  [label]="'netPayer' | translate"
                  [value]="txtNetPayer"
                  class="iw-textfield col-xs-2 text-align-right"
                  type="number"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  [label]="'total_paye' | translate"
                  [ngClass]="getTotalPayerClass()"
                  [value]="txtTotalPayer"
                  class="iw-textfield col-xs-2 text-align-right"
                  type="number"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  [label]="'areporter' | translate"
                  [ngClass]="getReporterClass()"
                  [value]="txtReporter"
                  class="iw-textfield col-xs-2 text-align-right"
                  type="number"></iw-textfield>
</form>

<iw-tab [(selectedTab)]="selectedTab"
        [invalidTabs]="invalidTabList"
        [tabs]="tabList">
    <iw-tab-fichesal-fiche *ngIf="selectedTab === tabList[0]"
                           [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"
                           [saemp]="saemp"
                           [sagen]="sagen"
                           [uuid]="uuid"></iw-tab-fichesal-fiche>

    <iw-tab-saep-paiment *ngIf="selectedTab === tabList[1]"
                         [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"
                         [saemp]="saemp"
                         [uuid]="uuid"></iw-tab-saep-paiment>

    <iw-tab-saems-informations *ngIf="selectedTab === tabList[2]"
                               [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"
                               [saemp]="saemp"></iw-tab-saems-informations>

    <iw-tab-saems-analytique *ngIf="selectedTab === tabList[3]"
                             [formGroup]="formGroup"
                             [isReadonly]="!isWriteMode"></iw-tab-saems-analytique>

    <iw-tab-saems-notes *ngIf="selectedTab === tabList[4]"
                        [formGroup]="formGroup"
                        [isReadonly]="!isWriteMode"></iw-tab-saems-notes>

    <iw-tab-saems-missions *ngIf="selectedTab === tabList[5]"
                           [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"></iw-tab-saems-missions>

    <iw-tab-saems-ema *ngIf="selectedTab === tabList[6]"
                      [formGroup]="formGroup"
                      [isReadonly]="!isWriteMode"></iw-tab-saems-ema>
</iw-tab>






