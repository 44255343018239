import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {Ppagence, Pppar} from '@app/sam-base/models/placement';
import {Langue} from '@sam-base/models/common/langue.enum';

/**
 * Tab General of parametres Generaux
 */
@Component({
    selector: 'iw-tab-pppar-general',
    templateUrl: './tab-pppar-general.component.html'
})
export class TabPpparGeneralComponent extends BasePartialFormComponent<Pppar> {

    public langue = Langue;
    public valuesmmode?: string;
    public agePrefix?: string;
    public entityService: RestEntityClient<Pppar>;

    constructor(_restService: RestApiService) {
        super();
        this.entityService = _restService.getEntityClient(Pppar);
    }

    public get entityId() {
        const id = this.getFormValue('ageId');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public get valueageId() {
        return this.getFormValue('ageId');
    }

    public get valueboAgeId() {
        return this.getFormValue('boAgeId');
    }

    public pppgmmodeChange() {
        this.valuesmmode = this.getFormValue('smmode');
    }

    public ppagenceChange(event: Ppagence | undefined) {
        if (event) {
            this.setFormValue('agePrefix', event.agePrefix);
        }
    }

}
