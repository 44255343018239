<form [formGroup]="formGroup"
      class="row">
    <iw-textarea #txtRepana
                 [height]="240"
                 [isDisabled]="true"
                 [label]="'repart_analityque' | translate"
                 [value]="txtRepAna"
                 class="iw-textarea col-xs-12 padding-top-15"></iw-textarea>

    <iw-button (press)="copyRepana()"
               [text]="'previoussaems' | translate"
               class="col-xs-2 flex-start no-padding"></iw-button>
</form>
