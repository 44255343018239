<form [formGroup]="formGroup"
      class="row space-form">
    <iw-textfield #txtNomprenom
                  [label]="'employe' | translate"
                  class="iw-input col-lg-9"
                  formControlName="nomprenom"
                  labelAlign="top"></iw-textfield>
    <iw-button #btnCreate
               [text]="'creer' | translate"
               class="iw-button col-lg-2"></iw-button>
    <iw-date-picker #txtDate
                    [label]="'date' | translate"
                    [yearFrom]="1980"
                    [yearTo]="2050"
                    class="iw-input col-lg-4"
                    formControlName="facDate"
                    labelAlign="top"></iw-date-picker>
    <iw-textfield #txtFacMnt
                  [label]="'montantHt' | translate"
                  class="iw-input col-lg-3"
                  formControlName="facMnt"
                  labelAlign="top"></iw-textfield>
    <iw-textarea #edtConcerne
                 [height]="55"
                 [label]="'concerne' | translate"
                 class="iw-textarea col-lg-9"
                 formControlName="facConcerne"
                 labelAlign="top"></iw-textarea>
    <iw-textarea #edtTexte
                 [height]="110"
                 [label]="'texte' | translate"
                 class="iw-textarea col-lg-9"
                 formControlName="facTexte"
                 labelAlign="top"></iw-textarea>
    <iw-button #btnCancel
               (press)="closeDialog()"
               [text]="'fermer' | translate"
               class="iw-button col-lg-2"></iw-button>
</form>
