import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Pppar} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-ppopttxt-page9', templateUrl: './tab-ppopttxt-page9.component.html'
})
export class IwTabPpopttxtPage9Component extends BasePartialFormComponent<Pppar> {

    @Input() public isReadonly = true;

    constructor() {
        super();
    }
}
