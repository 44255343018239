import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AvsNumber} from '@modules/sam-main/placement/models/avs-number.model';
import {environment} from '@root/environments/environment';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';


@Injectable()
export class AvsNumberService {
    private cache = new Map<string, Observable<boolean>>();

    constructor(private _http: HttpClient) {
    }

    // fixme ULTIMATE : check if we should not merge other avsnumberservice into this one
    public validateAVSForPpempId(avs: string, empId: string): Observable<AvsNumber> {
        const url = `/api/validate/avs`;
        const data = {
            avs, empId
        };
        return this._http.post<AvsNumber>(url, data);
    }

    public validateAVS(avs: string): Observable<boolean> {
        const url = `saemp/avs/validate`;
        const data = {avs};

        // Check if the value is in the cache
        if (this.cache.has(avs)) {
            return this.cache.get(avs) as Observable<boolean>;
        }

        // If not in cache, make the HTTP request
        const request = this._http.get<boolean>(environment.backendURL + url, {params: data})
            .pipe(tap(result => {
                // Store the result in the cache
                this.cache.set(avs, of(result));
            }));

        // Store the request Observable in the cache
        this.cache.set(avs, request);

        return request;
    }
}
