import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Sapar} from '@app/sam-base/models/salary/sapar';

@Component({
    selector: 'iw-tab-sapar-general', templateUrl: './tab-sapar-general.component.html'
})
export class TabSaparGeneralComponent extends BasePartialFormComponent<Sapar> {
    constructor() {
        super();
    }
}
