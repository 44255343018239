/* eslint-disable max-len */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {EmployeeDeductionService} from '@app/sam-base/core/services/employee-deduction-service';
import {SadedtxService} from '@app/sam-base/core/services/sadedtx.service';
import {SaempService} from '@app/sam-base/core/services/saemp.service';
import {SaisdefService} from '@app/sam-base/core/services/saisdef.service';
import {SapardedService} from '@app/sam-base/core/services/saparded.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {IwStoreService} from '@app/sam-base/core/store/iw-store.service';
import {ComboboxItem} from '@app/sam-base/models/components/combobox-item.model';
import {FormKeys} from '@app/sam-base/models/form-keys.model';
import {CusCodeInfo} from '@app/sam-base/models/salary/cusCodeInfo';
import {EmployeeDeductionInfo} from '@app/sam-base/models/salary/employeeDeductionInfo';
import {EmployeeDeductionInfoForm} from '@app/sam-base/models/salary/employeeDeductionInfoForm';
import {Sadedtx} from '@app/sam-base/models/salary/sadedtx';
import {TxInfo} from '@app/sam-base/models/salary/txInfo';
import {ModCalcEnum} from '@modules/sam-main/salary/components/employee/tab-saemp-impot/saemp-impot.enums';
import {TranslateService} from '@ngx-translate/core';
import {trim} from 'lodash';
import {Subject} from 'rxjs';
import {DedCodeLPP} from './cus-code-enum';
import {
    Ded_Code_AC,
    Ded_Code_AVS,
    Ded_Code_IJM,
    Ded_Code_IMP,
    Ded_Code_LAA,
    Ded_Code_LPP,
    Other
} from './soumis-code-enum';

export class EmployeeDeductionData {
    public empId?: string;
    public dedCode?: string;
    public annee?: string;
    public soumisCode?: number;
    public codeTaux?: string;
    public codeTaux2?: string;
    public edId?: string;
    public cuscode1?: number;
    public cuscode2?: number;
    public txId?: number;
    public txCode?: string;
    public txCode2?: string;
    public txManuel?: number;
    public parentUUID?: string;
    public readOnly?: boolean;
}

@Component({templateUrl: './employeeDeductionInfo.component.html'})
export class EmployeeDeductionComponent extends BaseFormComponent<EmployeeDeductionInfoForm> implements OnInit, OnDestroy {

    public txCodeOptions: ComboboxItem<number | undefined>[] = [];
    public cusCode1Options: ComboboxItem<number>[] = [];
    public soumisCodeOptions: ComboboxItem<number>[] = [];
    public txCode2Options: ComboboxItem<string>[] = [];
    public employeeDeductionInfo: EmployeeDeductionInfo = new EmployeeDeductionInfo();
    public dedCodetitre = '';
    public tauxAuto = '';
    public txtBarc3 = '';
    public remAVS = '';
    public soumisCodeEnabled = false;
    public enableCusCode1 = false;
    public isLPPHidden = this.dedCode === 'LPP';
    public enableTxCode2 = !(this.dedCode === 'IMP');
    public isTxManuelDisabled = false;
    public lppHorValue = false;
    public isReadOnly = true;
    private subscriptions = new Subject();

    constructor(private _store: IwStoreService, private readonly _sadedtxService: SadedtxService,
                private readonly _sapardedService: SapardedService, private readonly _saisdefService: SaisdefService,
                private readonly _employeeDeductionService: EmployeeDeductionService,
                private readonly _translate: TranslateService, private readonly _saempService: SaempService) {
        super();
    }

    public get isModeVisible(): boolean {
        return [
            'IMP',
            'LPP'].indexOf(this.getFormValue('deductionCode')) !== -1;
    }

    public get isImpotDedCode(): boolean {
        return this.getFormValue('deductionCode') === 'IMP';
    }

    public get isRemarqueVisible(): boolean {
        return [
            'AVS',
            'AC',
            'AC1',
            'AC2'].indexOf(this.getFormValue('deductionCode')) !== -1;
    }

    public get enableTxCode(): boolean {
        return (this.soumis !== 1 && [
            'AVS',
            'AC',
            'AC1',
            'AC2'].indexOf(this.getFormValue('deductionCode')) === -1);
    }

    public get isCantonVisible(): boolean {
        return ['IMP'].indexOf(this.getFormValue('deductionCode')) !== -1;
    }

    public get isCodeTauxVisible(): boolean {
        return [
            'AVS',
            'AC',
            'AC1',
            'AC2',
            'IMP',
            'CCT'].indexOf(this.getFormValue('deductionCode')) === -1;
    }

    public get isCCT(): boolean {
        return 'CCT' === this.getFormValue('deductionCode');
    }

    public get isBaremVisible(): boolean {
        return ['IMP'].indexOf(this.getFormValue('deductionCode')) !== -1;
    }

    public get isManualCheckedAndOtherThanNonSoumis(): boolean {
        return this.getFormValue('txmanuel') as boolean && this.soumisCode !== 1;
    }

    public get isReadOnlyOrDisabledEditDedCode(): boolean {
        return this.isReadOnly || [
            'AC1',
            'AC2',
            'IMP'].indexOf(this.getFormValue('deductionCode')) !== -1;
    }

    public get empId(): string {
        return this.getData('empId', true) || '';
    }

    public get dedCode(): string {
        return this.getData('dedCode', true) || '';
    }

    public get annee(): string {
        return this.getData('annee', true) || '';
    }

    public get codeTaux(): string {
        return this.getData('codeTaux', true) || '';
    }

    public get soumisCode(): number {
        return this.getFormValue('soumisCode') || 0;
    }

    public get soumis(): number {
        return this.getData('soumisCode', true) || 0;
    }

    public get cuscode1Data(): number {
        return this.getData('cuscode1', true) || 0;
    }

    public get cuscode2Data(): number {
        return this.getData('cuscode2', true) || 0;
    }

    public get txCodeData(): number {
        return this.getData('txCode', true) || 0;
    }

    public get txIdData(): number {
        return this.getData('txId', true) || 0;
    }

    public get txCode2Data(): number {
        return this.getData('txCode2', true) || 0;
    }

    public get txManuelData(): number {
        return this.getData('txManuel', true) || 0;
    }

    public get parentUUID(): string {
        return this.getData('parentUUID') || '';
    }

    public get edId(): string {
        return this.getData('edId') || '';
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public ngOnInit() {

        this.isReadOnly = this.getData('readOnly') as boolean;
        if (this.dedCode === 'LPP') {
            this.setLppHor();
        }
        this.setFormValue('soumisCode', this.soumis);
        this.setFormValue('deductionCode', this.dedCode);
        this.setFormValue('cuscode1', this.cuscode1Data);
        this.setFormValue('cuscode2', this.cuscode2Data);
        this.setFormValue('txId', this.txIdData);
        this.setFormValue('txcode', this.txCodeData);
        this.setFormValue('txcode2', this.txCode2Data);
        this.setFormValue('txmanuel', this.txManuelData);
        this.setCusCode1();
        this.setSoumisCodeOptions();
        this.getSadedTxInfoByAnneeAndDedCode();
        this.getTauxInfoByDedCode();
        this.getDedCodeTitreByDedCode();
        this.enableCusCode1 = (this.dedCode === 'LPP' || this.dedCode === 'IMP') && this.soumisCode === 2;
        this.getRemAVS();
        this.onUpdateCanton();
        this.subscribeValueChange('txcode', this.onUpdateCanton.bind(this));
        this.subscribeValueChange('txcode2', this.updatetxtBarc3.bind(this));
        this.subscribeValueChange('cuscode1', this.updatetxtBarc3.bind(this));
        this.subscribeValueChange('cuscode2', this.updatetxtBarc3.bind(this));
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public onUpdateCanton() {
        const canton = this.getFormValue('txcode');
        if (canton) {
            this.getIsBaremesByCanton(canton);
        }
        this.updatetxtBarc3();
    }

    public updatetxtBarc3() {
        const barem = this.getFormValue('txcode2');
        const baremDesc = this.txCode2Options.find(element => element.value === barem)?.name || '';
        this.txtBarc3 = 'IS' + trim(this.getFormValue('txcode')) + ':' + baremDesc + (this.getFormValue('cuscode2') === 1 ? 'Y' : 'N') + ':' + this.getFormValue('cuscode1');
    }

    public enableSoumisCode(event: any) {
        this.soumisCodeEnabled = event;
    }

    public setMode(event: any) {
        if (event === true) {
            this.cusCode1Options = [];
            this.cusCode1Options.push({
                name: 'Pas de coordination',
                value: DedCodeLPP.PasDeCoordination
            }, {
                name: 'Coord. annuelle',
                value: DedCodeLPP.CoordAnnuelle
            }, {
                name: 'Coord. mensuelle',
                value: DedCodeLPP.CoordMensuelle
            }, {
                name: 'Coord. horaire',
                value: DedCodeLPP.CoordHoraire
            }, {
                name: 'Coord. heure LPP',
                value: DedCodeLPP.CoordHeureLPP
            });
            this.setFormValue('cuscode1', DedCodeLPP.CoordHeureLPP);
        }
    }

    public setSoumisCodeValue(event: any) {
        if (this.dedCode === 'LPP' || this.dedCode === 'IMP') {
            if (event.value.name === 'Soumis') {
                this.enableCusCode1 = false;
            } else {
                this.enableCusCode1 = true;
            }
        }
        this.employeeDeductionInfo.soumisCode = event.value.value;
    }

    public saveEmployeeDeductionInfo() {
        this.employeeDeductionInfo.edId = this.edId;
        this.employeeDeductionInfo.deductionCode = this.dedCode;
        this.employeeDeductionInfo.soumisCode = this.soumisCode;
        // This.employeeDeductionInfo.txInfo = this.txInfo;
        this.employeeDeductionInfo.txInfo = new TxInfo();
        this.employeeDeductionInfo.txInfo.txManuel = this.getFormValue('txmanuel')
        this.employeeDeductionInfo.txInfo.txCode = this.getFormValue('txcode');
        this.employeeDeductionInfo.txInfo.txCode2 = this.getFormValue('txcode2');
        this.employeeDeductionInfo.txInfo.txManuel = this.getFormValue('txmanuel');
        if (this.isCodeTauxVisible) {
            this.employeeDeductionInfo.txInfo.txId = this.getFormValue('txId');
        }
        this.employeeDeductionInfo.cusCodeInfo = new CusCodeInfo();
        this.employeeDeductionInfo.cusCodeInfo.cusCode1 = this.getFormValue('cuscode1');
        this.employeeDeductionInfo.cusCodeInfo.cusCode2 = this.getFormValue('cuscode2');

        this._employeeDeductionService.updateEmployeeDeductionInfo(this.empId, this.employeeDeductionInfo)
            .subscribe((info) => {
                this._employeeDeductionService.sampededSaveSub.next({
                    uuid: this.parentUUID,
                    saved: true
                });
            });
        if (this.remAVS !== '') {
            this._saempService.saveSaempRemAVS(this.empId, this.remAVS)
                .subscribe();
        }
        this.closeDialog();
    }

    protected getData(key: keyof EmployeeDeductionData, warn = true): any {
        return super.getData(key, warn)
    }

    protected getFormControlNames(): FormKeys<EmployeeDeductionInfoForm> {
        return [
            'soumisCode',
            'deductionCode',
            'cuscode1',
            'cuscode2',
            'txcode',
            'txId',
            'txcode2',
            'txmanuel'];
    }

    private getRemAVS() {
        this._saempService.getRemAVSByEmpId(this.empId)
            .subscribe((data: string) => {
                this.remAVS = data;
            });
    }

    private getSadedTxInfoByAnneeAndDedCode() {
        this._sadedtxService.getByAnneeAndDedCode(this.annee, this.dedCode)
            .subscribe((data: Sadedtx[]) => {
                this.txCodeOptions.push({
                    name: this._translate.instant('combo_no_option'),
                    value: undefined
                });
                data.forEach((val) => {
                    this.txCodeOptions.push({
                        name: val.txCode + ' ' + val.titre,
                        value: val.id || 0
                    });
                });
            });
    }

    private getTauxInfoByDedCode() {
        this._sapardedService.getTauxAutoByDedCode(this.dedCode)
            .subscribe((data: string) => [this.tauxAuto = data]);
    }

    private getDedCodeTitreByDedCode() {
        this._sapardedService.getDedCodeTitreByDedCode(this.dedCode)
            .subscribe((data: string) => [this.dedCodetitre = data]);
    }

    private getIsBaremesByCanton(txCode: string) {
        this._saisdefService.getIsBaremesByCanton(txCode)
            .subscribe((data: string) => {
                if (data) {
                    const dataInfo = data.split(',');
                    let i = 1;
                    dataInfo.forEach((element) => {
                        this.txCode2Options.push({
                            name: element,
                            value: element
                        });
                        i++;
                    });
                }
                this.updatetxtBarc3();
            });
    }

    private setCusCode1() {
        if (this.dedCode === 'IMP') {
            this.cusCode1Options.push({
                name: ' ',
                value: 0
            }, {
                name: this._translate.instant('selonTaux'),
                value: ModCalcEnum.selonTaux
            }, {
                name: this._translate.instant('autoMensuel'),
                value: ModCalcEnum.autoMensuel
            }, {
                name: this._translate.instant('autoHorarire'),
                value: ModCalcEnum.autoHorarire
            }, {
                name: this._translate.instant('autoAnnuel'),
                value: ModCalcEnum.autoAnnuel
            }, {
                name: this._translate.instant('autoMoisJour'),
                value: ModCalcEnum.autoMoisJour
            }, {
                name: this._translate.instant('autoMoisNbJour'),
                value: ModCalcEnum.autoMoisNbJour
            }, {
                name: this._translate.instant('swissdecAnnuel'),
                value: ModCalcEnum.swissdecAnnuel
            }, {
                name: this._translate.instant('swissdecMensuel'),
                value: ModCalcEnum.swissdecMensuel
            });
        } else if (this.dedCode === 'LPP') {
            this.cusCode1Options.push({
                name: 'Pas de coordination',
                value: DedCodeLPP.PasDeCoordination
            }, {
                name: 'Coord. annuelle',
                value: DedCodeLPP.CoordAnnuelle
            }, {
                name: 'Coord. mensuelle',
                value: DedCodeLPP.CoordMensuelle
            }, {
                name: 'Coord. horaire',
                value: DedCodeLPP.CoordHoraire
            }, {
                name: 'Coord. heure LPP',
                value: DedCodeLPP.CoordHeureLPP
            });
        } else {
            this.cusCode1Options.push({
                name: '0 Non-dèfini',
                value: 1
            });
        }
    }

    private setLppHor() {
        this._saempService.getLppHorByEmpId(this.empId)
            .subscribe((data) => {
                this.lppHorValue = data;
            });
    }

    // eslint-disable-next-line complexity
    private setSoumisCodeOptions() {
        switch (this.dedCode) {
            case 'AVS': {
                this.setSoumisCodeOptionsAVS();
                break;
            }
            case 'AC': {
                this.setSoumisCodeOptionsAC();
                break;
            }
            case 'IMP': {
                this.setSoumisCodeOptionsIMP();
                break;
            }
            case 'LAA': {
                this.setSoumisCodeOptionsLAA();
                break;
            }
            case 'LPP': {
                this.setSoumisCodeOptionsLPP();
                break;
            }
            case 'IJM': {
                this.setSoumisCodeOptionsIJM();
                break;
            }
            default: {
                this.setSoumisCodeOptionsDefault();
            }
        }
    }

    private setSoumisCodeOptionsAVS() {
        this.soumisCodeOptions.push({
            name: 'Non soumis',
            value: Ded_Code_AVS.NonSoumis
        }, {
            name: 'Soumis',
            value: Ded_Code_AVS.Soumis
        }, {
            name: 'Rentier',
            value: Ded_Code_AVS.Rentier
        });
    }

    private setSoumisCodeOptionsAC() {
        this.soumisCodeOptions.push({
            name: 'Non soumis',
            value: Ded_Code_AC.NonSoumis
        }, {
            name: 'Soumis',
            value: Ded_Code_AC.Soumis
        });
    }

    private setSoumisCodeOptionsIMP() {
        this.soumisCodeOptions.push({
            name: 'Non soumis',
            value: Ded_Code_IMP.NonSoumis
        }, {
            name: 'Soumis',
            value: Ded_Code_IMP.Soumis
        });
    }

    private setSoumisCodeOptionsLAA() {
        this.soumisCodeOptions.push({
            name: 'Non assuré',
            value: Ded_Code_LAA.NonAssure
        }, {
            name: 'AP et ANP',
            value: Ded_Code_LAA.AP
        }, {
            name: 'AP et ANP (sans déduction)',
            value: Ded_Code_LAA.AP2
        }, {
            name: 'AP uniquement',
            value: Ded_Code_LAA.AP3
        });
    }

    private setSoumisCodeOptionsLPP() {
        this.soumisCodeOptions.push({
            name: 'Non soumis',
            value: Ded_Code_LPP.NonSoumis
        }, {
            name: 'Soumis',
            value: Ded_Code_LPP.Soumis
        }, {
            name: 'Soumis sans déduction',
            value: Ded_Code_LPP.SoumisSansDeduction
        }, {
            name: 'Soumis avec déduction fixe',
            value: Ded_Code_LPP.SoumisAvecDeduction
        });
    }

    private setSoumisCodeOptionsIJM() {
        this.soumisCodeOptions.push({
            name: 'Non soumis',
            value: Ded_Code_IJM.NonSoumis
        }, {
            name: 'Soumis',
            value: Ded_Code_IJM.Soumis
        }, {
            name: 'Soumis sans déduction',
            value: Ded_Code_IJM.SoumisSansDeduction
        }, {
            name: 'Soumis selon mission',
            value: Ded_Code_IJM.SoumisSelonMission
        });
    }

    private setSoumisCodeOptionsDefault() {
        this.soumisCodeOptions.push({
            name: 'Non soumis',
            value: Other.NonSoumis
        }, {
            name: 'Soumis',
            value: Other.Soumis
        }, {
            name: 'Soumis sans déduction',
            value: Other.SoumisSansDeduction
        });
    }
}
