export class SalIdHelper {

    public static getYearOfSalId(salId: string): string {
        return salId.substring(0, 4);
    }

    public static getFirstDayOfSalId(salId: string): Date | undefined {
        if (!salId) {
            return undefined;
        }
        // year case
        if (salId.length == 4) {
            return new Date(Number(salId.substring(0, 4)), 0, 1);
        }
        // month case
        return new Date(Number(salId.substring(0, 4)), Number(salId.substring(4).trim()) - 1, 1);
    }

    public static getLastDayOfSalId(salId: string): Date | undefined {
        if (!salId) {
            return undefined;
        }
        // year case
        if (salId.length == 4) {
            return new Date(Number(salId.substring(0, 4)), 11, 31);
        }
        // month case
        let debutMois = new Date(Number(salId.substring(0, 4)), Number(salId.substring(4).trim()) - 1, 1);
        return new Date(debutMois.getFullYear(), debutMois.getMonth() + 1, 0);
    }

    public static getPreviousPeriodOf(salId: string): string | undefined {
        let firstDayOfSalId = this.getFirstDayOfSalId(salId);
        if (!firstDayOfSalId) return undefined;
        firstDayOfSalId.setMonth(firstDayOfSalId.getMonth() - 1);
        return this.getSalIdByDate(firstDayOfSalId);
    }

    public static getSalIdByDate(date: Date): string {
        const year = String(date.getFullYear());
        const month = String(date.getMonth() + 1);
        return year + (month.length === 1 ? ' ' + month : month);
    }

    public static getSalIdMonth(salId: string): number | undefined {
        let firstDayOfSalid = this.getFirstDayOfSalId(salId);
        if (!firstDayOfSalid) return undefined;
        return firstDayOfSalid.getMonth() + 1;
    }

    public static getFirstDayOfYearFromSalId(salId: string): Date {
        const year = this.getYearOfSalId(salId);
        return new Date(Number(year), 0, 1);
    }

    createSalId(year: number, month: number): string {
        const monthStr = month <= 9 ? (" " + month) : ("" + month);
        return year + monthStr;
    }
}