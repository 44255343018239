import {Injectable} from '@angular/core';
import {Params, Router} from '@angular/router';
import {DialogStateService} from '@sam-base/core';

@Injectable()
export class IwDecompteNavService {

    constructor(private readonly _router: Router,
                private readonly _dialogStateService: DialogStateService) {
    }

    /**
     * Preview a decompte
     *
     * @param name
     * @param params
     * @memberof IwDecompteNavService
     */
    public openPreview(name: string, params?: Params) {
        this._dialogStateService.removeFocus();
        return this._router.navigate([
            {
                outlets: {
                    topPanel: [
                        'decompte',
                        'preview',
                        name]
                }
            }], {
            queryParams: params
        });
    }

    /**
     * Preview a decompte
     *
     * @param name
     * @param empId
     * @memberof IwDecompteNavService
     */
    public openPreviewWithEmp(name: string, empId: string) {
        return this._router.navigate(
            [
                {
                    outlets: {
                        topPanel: [
                            'decompte',
                            'preview',
                            name,
                            empId],
                    },
                }],
        );
    }
}
