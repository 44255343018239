import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppper} from '@app/sam-base/models/placement';

import {PeriodManagementComponent} from '../components/period-management/period-management.component';

export const PERIOD_DEFAULT_FORM: FormDescription<Ppper> = {
    form: PeriodManagementComponent, state: {
        ...buildDefaultState(Ppper), diagModal: false, diagTitle: 'ppper_form_title', width: 400, isNew: true
    }
};
