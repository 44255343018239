import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {PaymentComponent} from '@modules/sam-main/placement/components/acompte/paiement/payment.component';
import {PaymentForm} from '@sam-base/models/placement/paymentForm';


export const PAYMENT_FORM: FormDescription<PaymentForm> = {
    form: PaymentComponent,
    state: {
        ...buildDefaultState(PaymentForm),
        diagTitle: 'payaco_form_title',
        diagModal: false,
        editMode: 'waiting',
        width: 1600
    }
};
