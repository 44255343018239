import {Component, OnInit} from '@angular/core';
import {IwStoreService} from '@app/sam-base/core/store';
import {LoadLicenseOptions} from '@app/sam-base/core/store/actions/license.actions';
import {ParametersService} from '@core/services/parameters.service';
import {EntityDetailProviderService} from '@sam-base/core';
import {LoadLookups} from '@sam-base/core/store/actions/lookups.actions';

import {PLACEMENT_ENTITY_DETAIL} from './placement/placement-forms.list';
import {SALARY_ENTITY_DETAIL} from './salary/salary-forms.list';

@Component({templateUrl: './sam-main.component.html'})
export class SamMainComponent implements OnInit {
    constructor(private readonly _store: IwStoreService, private _detailProvider: EntityDetailProviderService,
                private _paramsService: ParametersService) {
    }

    public ngOnInit() {
        this._store.dispatch(new LoadLookups());
        this._store.dispatch(new LoadLicenseOptions());

        this._paramsService.InitializeParameters()
            .subscribe(success => {
                if (!success[0]) {
                    console.error('Error loading PPPAR');
                }
                if (!success[1]) {
                    console.error('Error loading SAPAR');
                }

            });

        for (const entityDetails of PLACEMENT_ENTITY_DETAIL) {
            this._detailProvider.setDetailComponent(entityDetails);
        }
        // fixme ULTIMATE use same system as the one above
        for (const e of SALARY_ENTITY_DETAIL) {
            this._detailProvider.setDetailComponentSalary(e.entity, e.component);
        }
    }
}
