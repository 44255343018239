import {Bvr} from '@sam-base/models/invoices/bvrLoad';

export class EncaissementAutomatic {
    public mpaId?: string;
    public compteDebiteur?: string;
    public tauxEscompte1?: number;
    public tauxEscompte2?: number;
    public splitByDate?: boolean;
    public allowManyClient?: boolean;
    public bvrList?: Bvr[];
    public partial?: boolean;
    public fileName?: string;
}
