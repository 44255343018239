export class Payment {
    public id?: string;
    public empId?: string;
    public prenom?: string;
    public nom?: string;
    public montant = 0;
    public mode?: string;
    public fullName?: string;
}

