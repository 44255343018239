import {Component, OnInit} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys} from '@app/sam-base/models';
import {Pppar} from '@app/sam-base/models/placement';

import {parametresGenerauxTabsFormControls} from './parametresGeneraux-tabs-formcontrols';

@Component({
    templateUrl: './parametresGeneraux.component.html'
})
export class ParametresGenerauxComponent extends BaseTabFormComponent<Pppar> implements OnInit {

    public get tabData(): Pppar | undefined {
        return this.getFormData();
    }

    public set tabData(v: Pppar | undefined) {
        this.mergeEntityChanges(v);
    }

    constructor(store: IwStoreService) {
        super(store);
    }

    public ngOnInit() {
        this.setFormValue('rapverhor', '2');
    }

    public getTabsValues(): TabFormControl<Pppar>[] {
        return parametresGenerauxTabsFormControls;
    }

    protected getFormControlNames(): FormKeys<Pppar> {
        return this.getTabsFormControls(parametresGenerauxTabsFormControls);
    }
}
