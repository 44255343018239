import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppagi} from '@app/sam-base/models/placement';

import {AginterNewComponent} from '../components/aginter/aginter-new/aginter-new.component';
import {AginterSidemenuComponent} from '../components/aginter/aginter-sidemenu.component';
import {AginterComponent} from '../components/aginter/aginter.component';

export const PPAGI_DEFAULT_FORM: FormDescription<Ppagi> = {
    form: AginterComponent, sideMenu: AginterSidemenuComponent, state: {
        ...buildDefaultState(Ppagi),
        diagTitle: 'agi_form_title',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showDelete: true
        },
        useNavigation: true,
        patch: false,
        width: 1000
    }
};

export const PPAGI_CREATE_FORM: FormDescription<Ppagi> = {
    form: AginterNewComponent, state: {
        ...buildDefaultState(Ppagi), diagTitle: 'aginew_form_title', width: 600, isNew: true, patch: false
    }
};

