import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Sags, SagsAud} from '@app/sam-base/models/salary';
import {Sagsc} from '@app/sam-base/models/salary/sagsc';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {SagscptOptions, SearchSagscTable} from '../components/genre-salaire/sagscpt/sagscpt.model';

@Injectable()
export class SagsService {

    constructor(private _http: HttpClient) {
    }

    public getSagscptOptions(mode: number, gsId: string): Observable<SagscptOptions> {
        return this._http.get<SagscptOptions>(`${environment.backendURL}sags/gscpt/${mode}/${gsId}`);
    }

    public getSagscptTableData(data: SagscptOptions): Observable<SearchSagscTable> {
        return this._http.post<SearchSagscTable>(`${environment.backendURL}sags/gscpt/data`, data);
    }

    public saveSagsc(sagsc: Sagsc): Observable<Sagsc> {
        return this._http.put<Sagsc>(`${environment.backendURL}sags/c`, sagsc);
    }

    public getProvisions(empId: string): Observable<string> {
        return this._http.get(`${environment.backendURL}sags/${empId}/informations`, {responseType: 'text'});
    }

    public getSagsInfo(): Observable<string[]> {
        return this._http.get<string[]>(`${environment.backendURL}sags/info`);
    }

    public getSagsPaiPrvByGsId(gsId: string): Observable<string> {
        return this._http.get<string>(`${environment.backendURL}sags/paiPrv/${gsId}`);
    }

    public getSagsByVersion(gsId: string, version: number): Observable<Sags> {
        return this._http.get<Sags>(`${environment.backendURL}sags/${gsId}/versions/${version}`);
    }

    public getAllSagsVersions(gsId: string): Observable<SagsAud[]> {
        return this._http.get<SagsAud[]>(`${environment.backendURL}sags/${gsId}/versions`);
    }

}
