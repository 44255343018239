import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Sags, SagsAud} from '@app/sam-base/models/salary';

import {
    GenreSalaireVersionComponent
} from '@modules/sam-main/salary/components/genre-salaire/genre-salaire-version.component';
import {GenreSalaireSideMenuComponent} from '../components/genre-salaire/genre-salaire-side-menu.component';
import {GenreSalaireComponent} from '../components/genre-salaire/genre-salaire.component';

export const SAGS_DEFAULT_FORM: FormDescription<Sags> = {
    form: GenreSalaireComponent, sideMenu: GenreSalaireSideMenuComponent, state: {
        ...buildDefaultState(Sags), diagTitle: 'sags', diagShowSideMenu: true, diagShowStatus: true, diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showPrint: false, showDelete: true
        }, editMode: 'read', useNavigation: true, width: 800, patch: false
    }
};
export const SAGS_VERSION_FORM: FormDescription<SagsAud> = {
    form: GenreSalaireVersionComponent, sideMenu: GenreSalaireSideMenuComponent, state: {
        ...buildDefaultState(SagsAud),
        diagTitle: 'sags_version',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: false, showList: false, showPrint: false, showDelete: false
        },
        editMode: 'read',
        useNavigation: false,
        width: 600,
        patch: false
    }
};
