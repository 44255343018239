import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Pprap} from '@app/sam-base/models/placement';

import {RapportsComponent} from '../components/rapports/rapports.component';

export const RAPPORTS_DEFAULT_FORM: FormDescription<Pprap> = {
    form: RapportsComponent, state: {
        ...buildDefaultState(Pprap), diagTitle: 'pprap_form_title', width: 1600, isNew: true
    }
};
