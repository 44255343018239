import {Component, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {
    BulkEntitySelectComponent
} from '@modules/sam-main/admin/components/bulk/bulk-entity-select/bulk-entity-select.component';
import {OperationType} from '@modules/sam-main/admin/models/operation.model';
import {EsQueryStatement, Term} from '@sam-base/core';
import {Gefacliview} from '@sam-base/models/invoices/gefacliview';

@Component({
    selector: 'iw-bulk-entity-select-invoices',
    templateUrl: './bulk-entity-select.component.html',
})
export class BulkEntitySelectInvoicesComponent extends BulkEntitySelectComponent<Gefacliview> implements OnInit, OnDestroy {
    public entity: Type<Gefacliview> = <any>Gefacliview;

    @Input()
    public operationType?: OperationType;

    public ngOnInit() {
        this.buildQueryStatements();
        this.setCustomColumns();
    }

    private buildQueryStatements() {
        const list: EsQueryStatement<Gefacliview>[] = [];
        list.push(EsQueryStatement.fromTerm(<Term<Gefacliview>>{type: 'gefacliview'}, 'filter'));
        if (this.operationType === OperationType.PUBLISH_INVOICE) {
            list.push(EsQueryStatement.fromTerm(<Term<Gefacliview>>{published: 'false'}, 'must'));
        }
        if (this.operationType === OperationType.EMAIL_INVOICE) {
            list.push(EsQueryStatement.fromTerm(<Term<Gefacliview>>{published: 'true'}, 'must'));
            list.push(EsQueryStatement.fromTerm(<Term<Gefacliview>>{facByEmail: 'true'}, 'must'));
        }
        this.statements = list;
    }

    private setCustomColumns() {
        this.columns = [
            {
                name: 'facNo',
                prop: 'facNo',
            },
            {
                name: 'fact_type',
                prop: 'facType',
                type: 'string'
            },
            {
                prop: 'facId',
                name: 'facId',
                type: 'string'
            },
            {
                prop: 'facornc',
                name: 'facornc',
                type: 'string',
            },
            {
                name: 'cliNomFactureNC',
                prop: 'nom',
                type: 'string'
            },
            {
                name: 'date_facture',
                prop: 'dateFac',
                type: 'date',
            },
            {
                name: 'agence',
                prop: 'ageId',
                type: 'string',
            },
            {
                name: 'published',
                prop: 'published',
                type: 'boolean',
            },
        ];
    }
}
