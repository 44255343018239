import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Gecli} from '@app/sam-base/models/invoices';
import {ClientSideMenuComponent} from '../components/client/client-side-menu.component';
import {ClientComponent} from '../components/client/client.component';

export const GECLI_DEFAULT_FORM: FormDescription<Gecli> = {
    form: ClientComponent, sideMenu: ClientSideMenuComponent, state: {
        ...buildDefaultState(Gecli), diagTitle: 'gecli', diagShowSideMenu: true, diagShowStatus: true, diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showPrint: true, showDelete: true
        }, editMode: 'read', useNavigation: true, width: 1000, patch: false
    }
};
