<form [formGroup]="formGroup" class="row padding-left-10">
    <iw-textfield #txtNom [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'nom' | translate"
                  class="iw-input col-xs-8 col-no-padding" formControlName="entname"></iw-textfield>

    <iw-textfield #txtComplement [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'departement' | translate"
                  class="iw-input col-xs-8 col-no-padding" formControlName="entdep"></iw-textfield>

    <iw-textfield #txtAdresse1 [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'adrSociete' | translate"
                  class="iw-input col-xs-8 col-no-padding" formControlName="entadr1"></iw-textfield>

    <iw-textfield #txtAdresse2 [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'adrPostale' | translate"
                  class="iw-input col-xs-8 col-no-padding" formControlName="entadr2"></iw-textfield>

    <iw-textfield #txtAdresse3 [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'supAdr' | translate"
                  class="iw-input col-xs-8 col-no-padding" formControlName="entadr3"></iw-textfield>

    <iw-textfield #txtAdresse3 [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'regionProvince' | translate"
                  class="iw-input col-xs-8 col-no-padding" formControlName="entreg"></iw-textfield>

    <iw-textfield #txtAdresse3 [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'boite' | translate"
                  class="iw-input col-xs-8 col-no-padding" formControlName="entcp"></iw-textfield>

    <iw-npa-lieu [formGroup]="formGroup" [isDisabled]="isReadonly" [labelNpaAlign]="'top'"
                 [lieuFormControlName]="'entlieu'" [npaFormControlName]="'entnpa'" [pays]="pays"
                 class="iw-input col-xs-8 col-no-padding"></iw-npa-lieu>

    <iw-pays-dropdown #cboPays [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'pays' | translate"
                      class="iw-input col-xs-8 col-no-padding" formControlName="entpays"></iw-pays-dropdown>

    <iw-textfield #noofs [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'noofs' | translate"
                  class="iw-input col-xs-8 col-no-padding" formControlName="entofsnr" maxlength="20"></iw-textfield>
</form>
