import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {
    UserTeamsConfigComponent
} from '@modules/sam-main/placement/components/user-teams-config/user-teams-config.component';
import {Team} from '@sam-base/models/admin/team.model';

export const USER_TEAMS_CONFIG: FormDescription<Team> = {
    form: UserTeamsConfigComponent,
    state: {
        ...buildDefaultState(Team),
        diagModal: true,
        diagTitle: 'user_teams',
        width: 1000
    }
};
