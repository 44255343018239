import {AfterViewInit, Component} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {SortInfo} from '@app/sam-base/components';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {EntityCreate, EntityDelete, EntityUpdate, IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Ppjf} from '@app/sam-base/models/placement';

@Component({
    templateUrl: './calendar-jours-feries-config.component.html'
})
export class CalendarJoursFeriesComponent extends BaseFormComponent<Ppjf> implements AfterViewInit {

    public type = Ppjf;
    public isReadonly = true;
    public newEntryMode = false;

    public jfId = '';
    public selectedRow?: Ppjf;
    public isSaving = false;

    public get selectedData() {
        if (!this.selectedRow) {
            return undefined;
        }
        return {name: 'jfId', value: this.selectedRow.jfId};
    }

    public sortProp?: keyof Ppjf;
    public sortPropDir?: 'asc' | 'desc';

    /** Dummy query created to bypass filter that requires some sort of params */
    public query: RestQueryParam<Ppjf, string>[] = [{
        prop: 'jfId', operation: RestQueryOperation.NotEquals, value: '_'
    }];

    private readonly _restPpjfClient: RestEntityClient<Ppjf>;

    constructor(rest: RestApiService, private _store: IwStoreService) {
        super();
        this._restPpjfClient = rest.getEntityClient(Ppjf);
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            this.setSortByJfdate();
        }, 0);
    }

    /**
     * Function to get selected row
     *
     * @param event Ppjf[]
     * @returns void
     */
    public getSelectedRow(event: Ppjf[]) {
        this.isReadonly = true;

        if (this.isSaving) {
            // Prevents clearing of the selectedRow
            this.isSaving = false;
            return;
        }

        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }

        this.jfId = (event[0] && event[0].jfId) || '';
        this.selectedRow = event[0];
        this.fillFormData(<any>event[0]);
    }

    /**
     * Function to create new Ppjf entry
     *
     * @returns void
     */
    public createNewEntry() {
        this.selectedRow = undefined;
        this.isReadonly = false;
        this.formGroup.reset();
        this.newEntryMode = true;
    }

    /**
     * Function to save changes on existing or new entry
     *
     * @returns void
     */
    public saveEntry() {
        const formData = this.getFormData();

        const updatedData = this._restPpjfClient
            .construct({...this.selectedRow, ...formData});
        this.isReadonly = true;
        this.clearForm();

        this.isSaving = true;

        if (this.newEntryMode) {
            this.setSortByJfdate();
            this._store.dispatch(new EntityCreate(updatedData));
            this.newEntryMode = false;

            return;
        }
        this._store.dispatch(new EntityUpdate(updatedData));
        this.newEntryMode = false;
    }

    /**
     * Set fields editable
     *
     * @returns void
     */
    public modifyEntry() {
        if (this.selectedRow && this.selectedRow.jfId) {
            this.isReadonly = false;
            this.newEntryMode = false;
        }
    }

    /**
     * Function to remove entry
     *
     * @returns void
     */
    public removeEntry() {
        if (this.selectedRow && this.selectedRow.jfId) {
            this._store.dispatch(new EntityDelete(Ppjf, this.jfId));
        }
    }

    /**
     * Cancel the edition mode
     *
     * @returns void
     */
    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.isReadonly = true;
        this.newEntryMode = false;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public setSortInfo(sortInfo: SortInfo<Ppjf>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    protected getValidationType() {
        return Ppjf;
    }

    protected getFormControlNames(): FormKeys<Ppjf> {
        return ['jfdate', 'jflibelle'];
    }

    private setSortByJfdate() {
        // Force the update of the string reference
        this.sortProp = 'jfdate';
        this.sortPropDir = 'desc';
    }
}
