import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Sadedtx} from '@app/sam-base/models/salary/sadedtx';
import {SadedtxComponent} from '../components/taux/sadedtx.component';

export const SADEDTX_DEFAULT_FORM: FormDescription<Sadedtx> = {
    form: SadedtxComponent, state: {
        ...buildDefaultState(Sadedtx), diagTitle: 'sadedtx_form_title', diagModal: false, width: 650, isNew: true
    }
};
