import {Injectable} from '@angular/core';
import {FormHandlerService, IwEventHandler, IwEventHubService, ModalService} from '@app/sam-base/core';
import {LayoutAdminNavigationEvent, LayoutEvents} from '@app/sam-base/layout/events/events.models';
import {AdminForm} from '@modules/sam-main/admin/admin.forms';
import {BulkManagerComponent} from '@modules/sam-main/admin/components/bulk/bulk-manager.component';
import {ModalOperationComponent} from '@modules/sam-main/admin/components/operation/modal-operation.component';
import {ModalOperationOptions} from '@modules/sam-main/admin/components/operation/operation-modal.options';
import {
    ModalUltimateAdminComponent
} from '@modules/sam-main/admin/components/ultimate-admin/modal-ultimate-admin.component';
import {
    ModalUltimateAdminOptions
} from '@modules/sam-main/admin/components/ultimate-admin/ultimate-admin-modal.options';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AdminLayoutEventsHandlers {

    constructor(private readonly _formHandler: FormHandlerService<AdminForm>,
                private readonly eventsHub: IwEventHubService<string>,
                private readonly _modalService: ModalService,
                private readonly _translate: TranslateService) {
    }

    @IwEventHandler(LayoutAdminNavigationEvent.SWISSDEC)
    public swissdec() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(AdminForm.SwissdecList, undefined, s => ({
            ...s,
            diagModal: false,
            diagTitle: 'title_swissdec_declaration_form'
        }));
    }

    @IwEventHandler(LayoutAdminNavigationEvent.KUSERS)
    public keycloakUsers() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(AdminForm.SamUserAdminList, undefined, s => ({
            ...s,
            diagModal: true,
            diagTitle: 'title_kusers_form'
        }));
    }

    @IwEventHandler(LayoutAdminNavigationEvent.ULTIMATE_ADMIN)
    public ultimateAdmin() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        const options: ModalUltimateAdminOptions = {
            showCancel: true,
            title: this._translate.instant('ultimate_admin_modal'),
            width: 1500,
            height: 900,
        };

        this._modalService.showModal(ModalUltimateAdminComponent, options);
    }

    @IwEventHandler(LayoutAdminNavigationEvent.OPERATIONS)
    public operations() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        const options: ModalOperationOptions = {
            showCancel: true,
            title: this._translate.instant('operations_modal_title'),
            width: 1500,
            height: 900,
        };

        this._modalService.showModal(ModalOperationComponent, options);
    }

    @IwEventHandler(LayoutAdminNavigationEvent.BULK_MANAGER)
    public bulkManager() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._modalService.showModal(BulkManagerComponent, {});
    }
}
