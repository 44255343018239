<form [formGroup]="formGroup"
      class="row">

    <div class="col-xs-12">
        <iw-uploader #fileUpload
                     (fileSelect)="uploadDocument($event)"
                     class="iw-button btn-block"></iw-uploader>
    </div>
    <div class="row middle-xs col-xs-12 padding-top-10">
        <iw-gepaimode-dropdown #cboBvrMpaId
                               [gePaytypes]="['ENTREE', 'ENTREE_ATTRIBUTION']"
                               [isDisabled]="!isWriteMode"
                               [labelAlign]="'top'"
                               [label]="'mode' | translate"
                               [onlyQr]="true"
                               class="iw-input col-xs-2"
                               formControlName="mpaId"></iw-gepaimode-dropdown>
        <iw-textfield #txtCptDeb
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'compte_debiteur' | translate"
                      class="iw-input col-xs-2"
                      formControlName="compteDebiteur"></iw-textfield>
        <iw-textfield #tauxEscompte1
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'escompte_rate_1' | translate"
                      class="iw-input col-xs-1"
                      formControlName="tauxEscompte1"
                      type="number"></iw-textfield>
        <iw-textfield #tauxEscompte2
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'escompte_rate_2' | translate"
                      class="iw-input col-xs-1"
                      formControlName="tauxEscompte2"
                      type="number"></iw-textfield>

        <iw-checkbox #splitByDate
                     [labelAlign]="'top'"
                     [label]="'split_by_date' | translate"
                     class="iw-input col-xs-2"
                     formControlName="splitByDate"></iw-checkbox>
        <iw-checkbox #allowManyClient
                     [isDisabled]="true"
                     [labelAlign]="'top'"
                     [label]="'allow_many_client' | translate"
                     class="iw-input col-xs-2"
                     formControlName="allowManyClient"></iw-checkbox>
        <iw-checkbox #partial
                     [labelAlign]="'top'"
                     [label]="'partial' | translate"
                     class="iw-input col-xs-1"
                     formControlName="partial"></iw-checkbox>

    </div>

    <iw-table (rowClicked)="onRowClicked($event)"
              (selectedChange)="onSelectBvrList($event)"
              [(selected)]="selectedLines"
              [columns]="columns"
              [data]="cbvrList"
              [isFilterEnable]="false"
              [isGridColumnMenuEnable]="false"
              [isSortIconEnable]="false"
              [rawTableMode]="true"
              [selectionMode]="'checkbox'"
              class="col-xs-12 no-padding"
              style="height: 300px"></iw-table>
    <div *ngIf="selectedBvr"
         class="row">

        <p-message *ngIf="selectedBvr.warning"
                   [text]="selectedBvr.warning | translate"
                   class="col-xs-12"
                   severity="warn"></p-message>
        <p-message *ngIf="selectedBvr.error"
                   [text]="selectedBvr.error | translate"
                   class="col-xs-12"
                   severity="error"></p-message>
    </div>

    <iw-table (rowDoubleClick)="openEncaissement($event)"
              *ngIf="encaissements?.length > 0"
              [columns]="columnsEncaissement"
              [data]="encaissements"
              [isFilterEnable]="false"
              [isGridColumnMenuEnable]="false"
              [isSortIconEnable]="false"
              [rawTableMode]="true"
              [selectionMode]="'checkbox'"
              class="col-xs-12 no-padding"
              style="height: 300px"></iw-table>

</form>