import {NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';

import {SamMainComponent} from './modules/sam-main/sam-main.component';

export interface AppWindow extends Window {
    appReady?: boolean;
}

const ROUTES: Route[] = [{
    path: '', component: SamMainComponent
}, {
    path: 'dashboard', outlet: 'topPanel', loadChildren: () => import('./modules/sam-dashboard/sam-dashboard.module')
        .then(e => e.SamDashboardModule)
}, {
    path: 'search', outlet: 'topPanel', loadChildren: () => import('./modules/sam-search/sam-search.module')
        .then(e => e.SamSearchModule)
}, {
    path: 'select',
    outlet: 'globalPanel',
    loadChildren: () => import('./modules/sam-search/entity-select/entity-select.module')
        .then(e => e.EntitySelectModule)
}, {
    path: 'kibana', outlet: 'topPanel', loadChildren: () => import('./modules/sam-kibana/sam-kibana.module')
        .then(e => e.SamKibanaModule)
}, {
    path: 'decompte', outlet: 'topPanel', loadChildren: () => import('./modules/sam-decompte/sam-decompte.module')
        .then(e => e.IwSamDecompteModule)
}];

@NgModule({
    imports: [RouterModule.forRoot(ROUTES, {
        onSameUrlNavigation: 'reload', enableTracing: false
    })], exports: [RouterModule]
})
export class AppRoutingModule {
}
