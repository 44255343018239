<form [formGroup]="formGroup" class="row">
    <div class="form-label col-xs-4 no-padding">
        <div class="col-xs-12 flex flex-center no-padding-right">
            <hr class="header-line">
            <div class="header-line-color">{{ 'execution' | translate }}</div>
            <hr class="header-line">
        </div>
        <div class="row">
            <iw-enum-dropdown #cboEx_dedmod [inputEnum]="exDedmodOptions" [isDisabled]="isReadonly" [labelAlign]="'top'"
                              [label]="'mode' | translate" class="iw-input col-xs-12"
                              formControlName="exDedmod"></iw-enum-dropdown>
        </div>
    </div>

    <div class="form-label col-xs-4 no-padding">
        <div class="col-xs-12 flex flex-center no-padding">
            <hr class="header-line">
            <div class="header-line-color">{{ '[%]' }}</div>
            <hr class="header-line">
        </div>
        <div class="row">
            <iw-textfield #cboEx_tx_emp [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'employe' | translate"
                          class="iw-input col-xs-6 no-padding-right" formControlName="exTxEmp"
                          type="number"></iw-textfield>

            <iw-textfield #txtEx_tx_soc [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'employeur' | translate"
                          class="iw-input col-xs-6 no-padding-left" formControlName="exTxSoc"
                          type="number"></iw-textfield>
        </div>
    </div>

    <div class="form-label col-xs-4 no-padding">
        <div class="col-xs-12 flex flex-center no-padding-left">
            <hr class="header-line">
            <div class="header-line-color">{{ 'frs_heure' | translate }}</div>
            <hr class="header-line">
        </div>
        <div class="row">
            <iw-textfield #txtEx_mnt_emp [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'employe' | translate"
                          class="iw-input col-xs-6 no-padding-right" formControlName="exMntEmp"
                          type="number"></iw-textfield>

            <iw-textfield #txtEx_mnt_soc [isDisabled]="isReadonly" [labelAlign]="'top'"
                          [label]="'employeur' | translate" class="iw-input col-xs-6 no-padding-left"
                          formControlName="exMntSoc" type="number"></iw-textfield>
        </div>
    </div>

    <div class="row col-xs-12 no-padding">
        <div #cboRa_gs_cotLabel class="iw-label col-xs-3"
             title="{{'deductionEmploye' | translate}}">{{ 'deductionEmploye' | translate }}
        </div>
        <iw-sags-dropdown #cboEx_gs_cot [isDisabled]="isReadonly" [noLabel]="true" [types]="['P']"
                          class="iw-input col-xs-9" formControlName="exGsCot" id="exGsCot"></iw-sags-dropdown>
        <div #cboRa_gs_coeLabel class="iw-label col-xs-3"
             title="{{'deductionEmployeur' | translate}}">{{ 'deductionEmployeur' | translate }}
        </div>
        <iw-sags-dropdown #cboEx_gs_coe [isDisabled]="isReadonly" [noLabel]="true" [types]="['Q']"
                          class="iw-input col-xs-9" formControlName="exGsCoe" id="exGsCoe"></iw-sags-dropdown>
        <div #cboRa_gs_cofLabel class="iw-label col-xs-3"
             title="{{'correctionEmploye' | translate}}">{{ 'correctionEmploye' | translate }}
        </div>
        <iw-sags-dropdown #cboex_gs_cof [isDisabled]="isReadonly" [noLabel]="true" [types]="['2', 'M']"
                          class="iw-input col-xs-9" formControlName="exGsCof"></iw-sags-dropdown>
        <div #cboRa_gs_cfeLabel class="iw-label col-xs-3"
             title="{{'correctionEmployeur' | translate}}">{{ 'correctionEmployeur' | translate }}
        </div>
        <iw-sags-dropdown #cboex_gs_cfe [isDisabled]="isReadonly" [noLabel]="true" [types]="['9', 'N']"
                          class="iw-input col-xs-9" formControlName="exGsCfe"></iw-sags-dropdown>
        <div #cboRa_gs_basLabel class="iw-label col-xs-3" title="{{'base' | translate}}">{{ 'base' | translate }}
        </div>
        <iw-sags-dropdown #cboEx_gs_bas [isDisabled]="isReadonly" [noLabel]="true" [types]="['0']"
                          class="iw-input col-xs-9" formControlName="exGsBas"></iw-sags-dropdown>
    </div>

</form>