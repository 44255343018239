import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Gecli} from '@app/sam-base/models/invoices';

@Component({
    selector: 'iw-client-tab-ij', templateUrl: './tab-client-ij.component.html'
})
export class TabClientIjComponent extends BasePartialFormComponent<Gecli> implements OnInit {

    @Input() public isReadonly = true;

    @Input() public entityId?: string;

    @Input() public isNew = false;

    public isChecked = false;

    public ngOnInit(): void {
        if (this.getFormValue('ijFacmail') === 'true') {
            this.isChecked = true;
        }
    }

    public onChangeChckBox(event: boolean) {
        this.isChecked = event;
        if (this.isChecked) {
            this.setFormValue('ijFacmail', 'true');
        } else {
            this.setFormValue('ijFacmail', '');
        }
    }

}

