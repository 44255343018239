import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppind} from '@app/sam-base/models/placement';

import {PpindsaidComponent} from '../components/ppindsaid/ppindsaid.component';

export const PPIND_DEFAULT_FORM: FormDescription<Ppind> = {
    form: PpindsaidComponent, state: {
        ...buildDefaultState(Ppind),
        diagTitle: 'ppind_form_title',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showPrint: false, showDelete: true
        },
        useNavigation: true,
        patch: false,
        width: 850
    }
};
