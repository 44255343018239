import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store/models';
import {Saenf} from '@app/sam-base/models/common';

import {PpempenfComponent} from '../components/ppempenf/ppempenf.component';

export const PPEMPENF_DEFAULT_FORM: FormDescription<Saenf> = {
    form: PpempenfComponent, state: {
        ...buildDefaultState(Saenf), diagTitle: 'ppempenf_form_title', diagModal: true, width: 500, editMode: 'waiting'
    }
};
