<form [formGroup]="formGroup" class="row">
    <div class="col-xs-11 col-no-padding" style="height: 200px">
        <iw-rest-grid #ppcliattGrid (selected)="getSelectedRow($event)" [autoFormClick]="false"
                      [customColumns]="columns" [defaultSelected]="true" [hasGridColumnMenu]="false"
                      [queryStatements]="query" [type]="type"></iw-rest-grid>
    </div>
    <div class="col-xs-1 grid-buttons">
        <iw-button #btnAddAtt (press)="newContactClient()" [isDisabled]="isReadonly"
                   [tooltip]="'contactNewClient' | translate" iconClass="fas fa-file"
                   style="padding-bottom: 6px"></iw-button>

        <iw-button #btnModAtt (press)="editContactClient()" [isDisabled]="isReadonly || !cliattId"
                   [tooltip]="'contactEdit' | translate" iconClass="fas fa-pencil-alt"></iw-button>

        <iw-button #btnDelAtt (press)="removeContactClient()" [isDisabled]="isReadonly || !cliattId"
                   [tooltip]="'contactDelete' | translate" iconClass="fas fa-trash"></iw-button>

        <iw-button #btnDefContact [isDisabled]="isReadonly || !cliattId" [text]="'D'"
                   [tooltip]="'parDefaut' | translate" class="iw-button" style="width: 24px"></iw-button>
    </div>

    <iw-textarea #edtAttInfo [height]="110" [isDisabled]="true" [labelAlign]="'top'" [label]="'contact' | translate"
                 [value]="contact" class="iw-textarea col-xs-11"></iw-textarea>

    <iw-phone-textfield #txtTel [isDisabled]="true" [labelAlign]="'top'" [label]="'telephone' | translate"
                        class="iw-input col-xs-5 no-padding" formControlName="tel"></iw-phone-textfield>

    <div class="col-xs-1"></div>

    <iw-textfield #txtFax [isDisabled]="true" [labelAlign]="'top'" [label]="'fax' | translate"
                  class="iw-input col-xs-5 no-padding" formControlName="fax"></iw-textfield>

    <iw-phone-textfield #txtNatel [isDisabled]="true" [labelAlign]="'top'" [label]="'telephonePortable' | translate"
                        class="iw-input col-xs-5 no-padding" formControlName="natel"></iw-phone-textfield>

    <div class="iw-input col-xs-1"></div>

    <iw-email-textfield #txtEmail [isDisabled]="true" [labelAlign]="'top'" [label]="'eMail' | translate"
                        class="iw-input col-xs-7 no-padding" formControlName="email"></iw-email-textfield>

    <iw-button #btnCopy2ClipBoard (press)="addToClipboard()" [tooltip]="'copierDans' | translate"
               iconClass="fas fa-copy" style="padding-top: 24px"></iw-button>

    <iw-button #btnSendMail2Contact (press)="sendEmail()" iconClass="fas fa-comment-dots"
               style="padding-top: 24px; margin-left: 10px"></iw-button>

</form>
