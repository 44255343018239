<form [formGroup]="formGroup" class="row">

    <div class="row col-xs-8">
        <iw-textfield-lookups [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'langueMaternelle' | translate"
                              [lookupName]="'LANGUE'" class="iw-input col-xs-7 no-padding-left"
                              formControlName="langue0"></iw-textfield-lookups>

        <iw-textfield-lookups [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'langueMaternelle2' | translate"
                              [lookupName]="'LANGUE'" class="iw-input col-xs-7 no-padding-left"
                              formControlName="langue00"></iw-textfield-lookups>

        <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'autresLangue' | translate"
                      class="iw-input col-xs-6 no-padding-left" formControlName="langue1"></iw-textfield>

        <iw-textfield [decimals]="0" [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'parle' | translate"
                      [maxValue]="MAX_VALUE" [minValue]="MIN_VALUE" [stepValue]="1" [type]="'number'" class="col-xs-2"
                      formControlName="lan1Parle"></iw-textfield>
        <iw-textfield [decimals]="0" [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'ecrit' | translate"
                      [maxValue]="MAX_VALUE" [minValue]="MIN_VALUE" [stepValue]="1" [type]="'number'" class="col-xs-2"
                      formControlName="lan1Ecrit"></iw-textfield>
        <iw-textfield [decimals]="0" [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'lu' | translate"
                      [maxValue]="MAX_VALUE" [minValue]="MIN_VALUE" [stepValue]="1" [type]="'number'" class="col-xs-2"
                      formControlName="lan1Lu"></iw-textfield>

        <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'top'" class="iw-input col-xs-6 no-padding-left"
                      formControlName="langue2"></iw-textfield>

        <iw-textfield [decimals]="0" [isDisabled]="isReadonly" [maxValue]="MAX_VALUE" [minValue]="MIN_VALUE"
                      [stepValue]="1" [type]="'number'" class="col-xs-2" formControlName="lan2Parle"></iw-textfield>
        <iw-textfield [decimals]="0" [isDisabled]="isReadonly" [maxValue]="MAX_VALUE" [minValue]="MIN_VALUE"
                      [stepValue]="1" [type]="'number'" class="col-xs-2" formControlName="lan2Ecrit"></iw-textfield>
        <iw-textfield [decimals]="0" [isDisabled]="isReadonly" [maxValue]="MAX_VALUE" [minValue]="MIN_VALUE"
                      [stepValue]="1" [type]="'number'" class="col-xs-2" formControlName="lan2Lu"></iw-textfield>

        <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'top'" class="iw-input col-xs-6 no-padding-left"
                      formControlName="langue3"></iw-textfield>

        <iw-textfield [decimals]="0" [isDisabled]="isReadonly" [maxValue]="MAX_VALUE" [minValue]="MIN_VALUE"
                      [stepValue]="1" [type]="'number'" class="col-xs-2" formControlName="lan3Parle"></iw-textfield>
        <iw-textfield [decimals]="0" [isDisabled]="isReadonly" [maxValue]="MAX_VALUE" [minValue]="MIN_VALUE"
                      [stepValue]="1" [type]="'number'" class="col-xs-2" formControlName="lan3Ecrit"></iw-textfield>
        <iw-textfield [decimals]="0" [isDisabled]="isReadonly" [maxValue]="MAX_VALUE" [minValue]="MIN_VALUE"
                      [stepValue]="1" [type]="'number'" class="col-xs-2" formControlName="lan3Lu"></iw-textfield>

        <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'top'" class="iw-input col-xs-6 no-padding-left"
                      formControlName="langue4"></iw-textfield>

        <iw-textfield [decimals]="0" [isDisabled]="isReadonly" [maxValue]="MAX_VALUE" [minValue]="MIN_VALUE"
                      [stepValue]="1" [type]="'number'" class="col-xs-2" formControlName="lan4Parle"></iw-textfield>
        <iw-textfield [decimals]="0" [isDisabled]="isReadonly" [maxValue]="MAX_VALUE" [minValue]="MIN_VALUE"
                      [stepValue]="1" [type]="'number'" class="col-xs-2" formControlName="lan4Ecrit"></iw-textfield>
        <iw-textfield [decimals]="0" [isDisabled]="isReadonly" [maxValue]="MAX_VALUE" [minValue]="MIN_VALUE"
                      [stepValue]="1" [type]="'number'" class="col-xs-2" formControlName="lan4Lu"></iw-textfield>

        <iw-textarea [height]="'110px'" [isDisabled]="isReadonly" class="iw-textarea"
                     formControlName="lanNote"></iw-textarea>

    </div>

    <div class="col-xs-4">

        <div class="form-subtitle"> {{ 'driver_license' | translate }}</div>

        <iw-checkbox [isDisabled]="isReadonly" [label]="'voiture' | translate" class="iw-input"
                     formControlName="lpervoi"></iw-checkbox>
        <iw-checkbox [isDisabled]="isReadonly" [label]="'moto' | translate" class="iw-input"
                     formControlName="lpermoto"></iw-checkbox>
        <iw-checkbox [isDisabled]="isReadonly" [label]="'poidslourd' | translate" class="iw-input"
                     formControlName="lperpl"></iw-checkbox>
        <iw-checkbox [isDisabled]="isReadonly" [label]="'remorque' | translate" class="iw-input"
                     formControlName="lperrem"></iw-checkbox>
        <iw-checkbox [isDisabled]="isReadonly" [label]="'machineDeChantier' | translate" class="iw-input"
                     formControlName="lperchan"></iw-checkbox>

        <iw-textarea [height]="'70px'" [isDisabled]="isReadonly" class="iw-textarea"
                     formControlName="vehpernote"></iw-textarea>

        <div class="form-subtitle"> {{ 'vehicle' | translate }}</div>

        <iw-checkbox [isDisabled]="isReadonly" [label]="'voiture' | translate" class="iw-input"
                     formControlName="lvoiture"></iw-checkbox>
        <iw-checkbox [isDisabled]="isReadonly" [label]="'moto' | translate" class="iw-input"
                     formControlName="lmoto"></iw-checkbox>
        <iw-checkbox [isDisabled]="isReadonly" [label]="'velo' | translate" class="iw-input"
                     formControlName="lvelo"></iw-checkbox>

        <iw-textarea [height]="'70px'" [isDisabled]="isReadonly" class="iw-textarea"
                     formControlName="vehNote"></iw-textarea>

    </div>

</form>
