import {Component, Input, Type, ViewChild} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components/iw-rest-grid/iw-rest-grid.component';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {EsQueryStatement, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';

import {SuiviService} from '@app/sam-base/core/services/suivi.service';
import {EntityDelete, IwStoreService} from '@app/sam-base/core/store';
import {FormKeys, IwGridColumn} from '@app/sam-base/models';
import {Ppsuivi, Ppsuiviview} from '@app/sam-base/models/placement';
import {PrintService} from '@core/print/print.service';
import {TranslateService} from '@ngx-translate/core';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {PlacementForm} from '../../../placement.forms';

import {
    employeeMgtAccessRoles
} from '@app/sam-base/core/auth/access-rules/employee-form';

@Component({
    selector: 'iw-tab-ppemp-suivi', templateUrl: './tab-ppemp-suivi.component.html'
})
export class TabPpempSuiviComponent extends BaseFormComponent<Ppsuivi> {

    @Input()
    public set entityId(v: string | undefined) {
        if (v) {
            this.setEntityId(v);
            this.updateFilters(v);
        }
    }

    public employeeMgtAccessRoles: SamUserRole[] = employeeMgtAccessRoles();

    public get entityId() {
        return this._entityId;
    }

    public get suiId() {
        return this.getFormValue('suiId') || '';
    }

    @Input() public isReadonly = true;

    public query: RestQueryParam<Ppsuiviview, string | boolean>[] = [{
        operation: RestQueryOperation.Equals, prop: 'empId', value: '_'
    }];
    public columns = this.getCliSuiviColumns();

    public type: Type<Ppsuiviview> = Ppsuiviview;
    public refreshType: Type<Ppsuivi> = Ppsuivi;

    public tableWidth = '1200px';

    public suiviDescription = '';

    private _entityId?: string;
    private _selected?: Ppsuiviview;

    @ViewChild('olvClisui', {static: true}) private _table?: IwRestGridComponent<Ppsuiviview>;

    constructor(private readonly _formHandler: FormHandlerService<PlacementForm>, private readonly _store: IwStoreService, private readonly _printService: PrintService, private readonly _translate: TranslateService, private _suiviService: SuiviService) {
        super();
    }

    public clearSortDir() {
        if (this._table) {
            this._table.defaultSort = undefined;
            this._table.defaultSortDir = 'asc';
        }
    }

    public addSuiviEmployee() {
        this._formHandler.showFormDialog(PlacementForm.SuiviNew, {
            empId: this._entityId, suitype: 'E', action: 'PPEMPACTION'
        }, s => ({...s, diagTitle: 'title_ppempsui'}));
    }

    public editSuiviEmployee() {
        const id = this.suiId;
        if (!id) {
            return;
        }

        this._formHandler.showFormDialog(PlacementForm.SuiviEdit, {
            empId: this._entityId, suitype: 'E', action: 'PPEMPACTION'
        }, s => ({...s, entityId: id, diagTitle: 'title_ppempsui'}));
    }

    public deleteSuiviEmployee() {
        const id = this.suiId;
        if (!id) {
            return;
        }

        this._store.dispatch(new EntityDelete(Ppsuivi, id));
    }

    public printService() {
        if (!this._table) {
            return;
        }
        const profile = this._table.loadGridProfile();

        this._printService.print<Ppsuivi>({
            entity: Ppsuivi,
            extraStatements: [EsQueryStatement
                .fromMatch({empId: {query: this._entityId || ''}}, 'filter')],
            format: 'pdf',
            group: profile,
            parameters: {}
        });
    }

    public getSelectedRow(event: Ppsuiviview[]) {
        this._selected = (event && event[0]) || undefined;
        if (this._selected) {
            this.setFormValue('suiId', this._selected.suiId);
            this.setFormValue('notes', this._selected.notes);
            this.setSuiviDescription(this._selected);
        } else {
            this.setFormValue('suiId', '');
            this.setFormValue('notes', '');
            this.suiviDescription = '';
        }
    }

    protected getFormControlNames(): FormKeys<Ppsuiviview> {
        return ['suiId', 'contactnom', 'contactprenom', 'userId', 'notes'];
    }

    // eslint-disable-next-line complexity
    private setSuiviDescription(suivi: Ppsuiviview) {
        this.suiviDescription = this._suiviService.getSuiviDescription(suivi);
    }

    private setEntityId(v: string) {
        if (v !== this._entityId) {
            this._entityId = v;
            this.updateFilters(v);
        }
    }

    private async updateFilters(empId: string) {
        this.query = [{
            operation: RestQueryOperation.Equals, prop: 'empId', value: empId
        }];
    }

    private getCliSuiviColumns(): IwGridColumn<Ppsuiviview>[] {
        return [{
            prop: 'dateaction', name: 'date', type: 'date', index: 0
        }, {
            prop: 'nomconseille', name: 'conseiller', index: 1
        }, {
            prop: 'action', index: 2
        }, {
            prop: 'nomemploye', name: 'employe', index: 3
        }, {
            prop: 'nomclient', name: 'client', index: 4
        }, {
            prop: 'clientcontact', name: 'contact', index: 5
        }];
    }
}
