import {Component, EventEmitter, Input, Output, Type} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseFormComponent} from '@app/sam-base/base';
import {ClipboardService} from '@app/sam-base/core/clipboard/clipboard.service';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {EntityDelete, IwStoreService} from '@app/sam-base/core/store';
import {contactToSmsType} from '@app/sam-base/helpers/sms-map';
import {FormKeys} from '@app/sam-base/models';
import {Ppcliatt} from '@app/sam-base/models/placement';
import {CommunicationType} from '@core/mail/mail.models';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import {clientCommunicationAccessRoles} from '@sam-base/core/auth/access-rules/client-form';
import {IwDateHelper} from '@sam-base/core/dates/iw-date-helper';
import {lastValueFrom} from 'rxjs';

import {PlacementForm} from '../../../placement.forms';
import {IwSendSMSModalComponent} from '../../send-sms/iw-send-sms-modal/iw-send-sms-modal.component';
import {TabContacts} from './tab-contacts.model';

@Component({
    selector: 'iw-tab-contacts',
    templateUrl: './tab-contacts.component.html',
    styleUrls: ['./tab-contacts.component.scss']
})
export class TabContactsComponent extends BaseFormComponent<TabContacts> {

    public type: Type<Ppcliatt> = Ppcliatt;
    public buttonEntityComRoles: string[] = [];

    @Input() public isReadonly = true;
    @Input() public ageId?: string;
    @Input() public defaultCliAttId?: string;
    @Output() public defaultContactChanged = new EventEmitter<string>();
    public cliattId?: string;
    public contact = '';
    public query: RestQueryParam<Ppcliatt, string>[] = [];
    private readonly _entityService: RestEntityClient<Ppcliatt>;

    constructor(private readonly _clipboardService: ClipboardService, private readonly _store: IwStoreService,
                private readonly _modalService: ModalService,
                private readonly _formHandler: FormHandlerService<PlacementForm>, restService: RestApiService) {
        super();
        this._entityService = restService.getEntityClient(Ppcliatt);
        this.buttonEntityComRoles = clientCommunicationAccessRoles();
    }

    private _entityId?: string;

    public get entityId() {
        return this._entityId;
    }

    @Input()
    public set entityId(v: string | undefined) {
        this.setEntityId(v);
        this.filterById(v);
    }

    public get isDefaultContact(): boolean {
        return this.cliattId === this.defaultCliAttId;
    }

    public newContactClient() {
        if (this.entityId) {
            this._formHandler.showFormDialog(PlacementForm.ContactNew, {cliId: this.entityId});
        }
    }

    public editContactClient() {
        if (this.entityId && this.cliattId) {
            const entityId = this.cliattId;
            this._formHandler.showFormDialog(PlacementForm.ContactEdit, {cliId: this.entityId}, s => ({
                ...s,
                entityId
            }));
        }
    }

    public removeContactClient() {
        if (this.cliattId) {
            this._store.dispatch(new EntityDelete(Ppcliatt, this.cliattId));
        }
    }

    public async sendSms() {
        if (this.cliattId) {
            const entity = await lastValueFrom(this._entityService.getById(this.cliattId))
                .then(e => contactToSmsType([e], this.ageId));
            this._modalService.showModal(IwSendSMSModalComponent, entity);
        }
    }

    public sendEmail() {
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.PPCLIATT_GENERIC,
            entityIds: [this.cliattId || '']
        });
    }

    public addToClipboard() {
        const email: string = this.getFormValue('email') || '';
        this._clipboardService.addToClipboard(email);
    }

    public getSelectedRow(event: Ppcliatt[]) {
        if (!event || !event.length) {
            return;
        }
        this.cliattId = (event[0] && event[0].cliattId) || '';
        this.contact = this.builContact(event[0]);
        this.fillFormData(<any>event[0]);
    }

    // eslint-disable-next-line complexity
    public builContact(c: Ppcliatt): string {
        if (!c) {
            return '';
        }

        let contact = '';

        if (c.nom || c.prenom) {
            contact += `${c.nom || ''} ${c.prenom || ''}\n`;
        }
        if (c.dept) {
            contact += `${c.dept}\n`;
        }
        if (c.tel) {
            contact += `Tel: ${c.tel}\n`;
        }
        if (c.fax) {
            contact += `Fax: ${c.fax}\n`;
        }
        if (c.email) {
            contact += `Email: ${c.email}\n`;
        }
        if (c.dateanniv) {
            contact += `Anniversaire: ${IwDateHelper.dateFormatFromString(c.dateanniv, 'DD.MM.YYYY')}\n`;
        }
        if (c.remarques) {
            contact += ` ${c.remarques}`;
        }

        return contact;
    }

    public setActiveContactAsDefault() {
        this.defaultContactChanged.emit(this.cliattId);
    }

    protected getFormControlNames(): FormKeys<TabContacts> {
        return [
            'tel',
            'fax',
            [
                'natel',
                new UntypedFormControl('', [Validators.pattern(/^\+(?:[0-9]{1,3} ?)[0-9]{6,14}$/)])],
            'email'];
    }

    private setEntityId(v?: string) {
        if (v && this._entityId !== v) {
            this._entityId = v;
        }
    }

    private filterById(cliId?: string) {
        this.query = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'cliId',
                value: cliId || '_'
            }];
    }
}
