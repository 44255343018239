<form [formGroup]="formGroup" class="row">

    <iw-date-picker #txtDate_cde [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'dateCde' | translate"
                    class="iw-input col-xs-2" formControlName="dateCde">
        >
    </iw-date-picker>

    <iw-textfield #txtRefInterne [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'notreRef' | translate"
                  class="iw-input col-xs-4" formControlName="refinterne"></iw-textfield>

    <iw-textfield #txtRefClient [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'referenceClient' | translate"
                  class="iw-input col-xs-4" formControlName="refclient"></iw-textfield>

    <iw-textfield #txtNogestion [isDisabled]="true" [labelAlign]="'top'" [label]="'noGestion' | translate"
                  class="iw-input col-xs-2" formControlName="nogestion"></iw-textfield>

    <div class="row col-xs-12 separator-top"></div>

    <div class="row col-xs-6 separator-right no-padding">

        <div class="row col-xs-12 padding-bottom-0 flex-center">
            <iw-agency-combo-box #cboAge_id [isDisabled]="true" [labelAlign]="'top'" [label]="'agence' | translate"
                                 class="iw-combobox col-xs-4" formControlName="ageId"></iw-agency-combo-box>

            <iw-sector-combo-box #cboSec_id [isDisabled]="isReadonly" [labelAlign]="'top'"
                                 [label]="'nomsecteur' | translate" class="iw-combobox col-xs-4"
                                 formControlName="secId"></iw-sector-combo-box>

            <iw-conseiller-dropdown #cboCon_id [isDisabled]="isReadonly" [labelAlign]="'top'"
                                    [label]="'conseiller' | translate" class="iw-combobox col-xs-4"
                                    formControlName="conId"></iw-conseiller-dropdown>
        </div>

        <div class="row col-xs-12 separator-top no-padding">

            <iw-textfield #txtLieu1 [isDisabled]="isReadonly" [labelAlign]="'top'"
                          [label]="'lieuDeLaMission' | translate" class="iw-input col-xs-11"
                          formControlName="lieu1"></iw-textfield>

            <iw-button #btnCopyAdr (press)="copyAddressByClient()" [isDisabled]="isReadonly"
                       [tooltip]="'copierAdrClient' | translate" class="iw-input col-xs-1 no-padding without-label"
                       iconClass="fas fa-copy"></iw-button>

            <iw-textfield #txtLieu2 [isDisabled]="isReadonly" class="iw-input col-xs-12"
                          formControlName="lieu2"></iw-textfield>

            <iw-textfield #txtLieu3 [isDisabled]="isReadonly" class="iw-input col-xs-12"
                          formControlName="lieu3"></iw-textfield>

            <iw-textfield #txtLieu3 [isDisabled]="isReadonly" class="iw-input col-xs-4 no-padding-right padding-top-5"
                          formControlName="misNpa"></iw-textfield>

            <iw-textfield #txtmis_Lieu [isDisabled]="isReadonly" class="iw-input col-xs-8 no-padding-left padding-top-5"
                          formControlName="misLieu"></iw-textfield>
        </div>
    </div>
    <div class="row col-xs-6 no-padding">
        <iw-lookups-dropdown #cboDurType [isDisabled]="isReadonly" [labelAlign]="'top'"
                             [label]="'dureePrevue' | translate" [lkname]="'MISDURTYPE'" class="iw-combobox col-xs-4"
                             formControlName="dureetype"></iw-lookups-dropdown>

        <iw-textfield-lookups #txtDuree [isDisabled]="isReadonly" [lookupName]="'DUREEMISSION'"
                              class="iw-input col-xs-8 flex-end" formControlName="duree"></iw-textfield-lookups>
        <div class="row col-xs-12 separator-top"></div>

        <iw-textfield #txtService [isDisabled]="isReadonly" [labelAlign]="'top'"
                      [label]="'serviceDepartement' | translate" class="iw-input col-xs-12"
                      formControlName="service"></iw-textfield>

        <iw-textfield-cliatt #txtResp (cliAttChanged)="setAtt($event)" [cliId]="cliId" [isDisabled]="isReadonly"
                             [labelAlign]="'top'" [label]="'responsableContact' | translate" class="iw-input col-xs-12"
                             formControlName="resp"></iw-textfield-cliatt>

        <iw-textfield #txtTel [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'telephone' | translate"
                      class="iw-input col-xs-12 padding-top-5" formControlName="tel"></iw-textfield>

        <iw-textfield #txtFax [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'fax' | translate"
                      class="iw-input col-xs-12" formControlName="fax"></iw-textfield>

        <iw-textfield #txtNatel [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'natel' | translate"
                      [placeholder]="'+xxxXXXXXXXXX'" class="iw-input col-xs-12" formControlName="natel"></iw-textfield>

        <iw-email-textfield #txtEmail [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'email' | translate"
                            class="iw-input col-xs-12" formControlName="email"></iw-email-textfield>

    </div>
    <div class="row col-xs-12 separator-top">
        <div class="iw-form-group row col-xs-4">
            <iw-textfield #txtNb_mis [decimals]="0" [isDisabled]="true" [label]="'nomDmissions' | translate"
                          [stepValue]="1" class="iw-input col-xs-6 no-padding-right no-padding-left flex"
                          formControlName="nbMis" style="align-self: flex-end" type="number"></iw-textfield>

            <iw-textfield #txtNb_emp [decimals]="0" [isDisabled]="isReadonly" [label]="'nomDemployes' | translate"
                          [stepValue]="1" class="iw-input col-xs-6 flex-end no-padding-left flex"
                          formControlName="nbEmp" style="align-self: flex-end" type="number"></iw-textfield>
        </div>

        <iw-textfield-lookups #txtMotif_sat [isDisabled]="isReadonly" [labelAlign]="'top'"
                              [label]="'motifSiNonSatisfaite' | translate" [lookupName]="'MOTIFCDENS'"
                              class="iw-input col-xs-6 no-padding-right"
                              formControlName="motifSat"></iw-textfield-lookups>

    </div>
</form>
