import {IwColumn} from '@app/sam-base/core/column/decorators/iw-column.decorator';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('gepaihdr')
export class Gepaihdr {
    @IwRestEntityId() @IwColumn({name: 'id', index: 0}) public payId?: string;
    public datePay?: string;
    public mpaId?: string;
    public cliId?: string;
    public bvrId?: string;
    public payStatus?: string;
    public origine?: string;
    public montant?: number;
    public frais?: number;
    public solde?: number;
    public tvaPeriode?: string;
    public cptaRef?: string;
    public cptaMode?: string;
    public userId?: string;
}
