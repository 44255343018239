import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwTextFieldComponent} from '@app/sam-base/components/iw-textfield/iw-textfield.component';
import {ERROR} from '@app/sam-base/core/logger/logger';
import {MissionCalculationsService} from '@app/sam-base/core/services/mission-calculations.service';
import {Ppmis} from '@app/sam-base/models/placement';
import {
    MissionCalcApplyCoeffResponse, MissionCalcCCTSup, MissionCalcTarifResponse, SalaryDeduction
} from '@app/sam-base/models/placement/mission-calculations-result.model';
import {TranslateService} from '@ngx-translate/core';
import {ModalMessageComponent} from '@sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@sam-base/components/modal/modal-message/modal-message.options';
import {ModalService} from '@sam-base/core';
import {StoreEvents} from '@sam-base/core/events/actions/store.actions';

import {IwEventHubService} from '@sam-base/core/events/core/iw-event-hub.service';
import {RestEntityClient} from '@sam-base/core/rest-api/entity/rest-entity-client';
import {RestApiService} from '@sam-base/core/rest-api/rest-api.service';
import {lastValueFrom, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {FacHeurMode} from '../../tab-ppmis-salary-enums';


@Component({
    selector: 'iw-mis-fact-hours', templateUrl: './mis-fac-hours.component.html'
})
export class MisFactureHoursComponent extends BasePartialFormComponent<Ppmis> implements OnInit, AfterViewInit, OnDestroy {

    public get cctId() {
        return this.getFormValue<string>('cctId');
    }

    public get clitarifmd() {
        return this.getFormValue<number>('clitarifmd') || 0;
    }

    public get showClitarifMod() {
        return this.clitarifmd >= 6 && this.clitarifmd <= 11;
    }

    public get calculerDisabled() {
        return this.isReadonly || !this.cctId || !this.txtSupCCT;
    }


    public cliSupModeEnum = FacHeurMode;

    @ViewChild('txtSupCCT') public txtSupCCT?: IwTextFieldComponent;

    @ViewChild('txtTarifTotal') public txtTarifTotal?: IwTextFieldComponent;

    @Output() public calculating: EventEmitter<boolean> = new EventEmitter();

    public isSaise = false;

    public isInvEnable = true;

    private subscriptions = new Subject();

    private previousValue = 0;
    private isFirstTime = true;
    private _ppmisRestClient: RestEntityClient<Ppmis>;

    constructor(private readonly _calcService: MissionCalculationsService, private readonly _actions: IwEventHubService<string>, private readonly _restClient: RestApiService, private readonly _translate: TranslateService, private readonly _modalService: ModalService) {
        super();
        this._ppmisRestClient = _restClient.getEntityClient(Ppmis);

    }

    public ngOnInit() {
        this.subscribeCalcTotal();
        this._actions.forType<StoreEvents>(StoreEvents.FormEntityStoreCancelEdit)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(() => this.setTarifTotal(this.previousValue));
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            this.subscribeCalculateCCTSup();
            this.initForm();
            this.subscribeValueChange('misId', () => this.initForm());
            this.subscribeValueChange('clitarifra', () => this.initialCalc());
        }, 500);
    }


    public onCalcCCT() {
        if (this.isReadonly) {
            return;
        }
        if (!this.cctId) {
            return;
        }
        if (!this.txtSupCCT) {
            return;
        }
        const val = Number(this.txtSupCCT.getValue()) || 0;
        this.setFormValue('clitarifra', val);
        this.initialCalc();
    }

    public onTarifTotalBlur() {
        if (!this.isSaise) {
            return;
        }
        this.calculateTotal();
    }

    public triggerReadyToCalculate() {
        if (this.isReadonly) {
            return;
        }
        this.setFormValue('isCalculating', true);
    }


    public calculateTotal() {
        if (this.isReadonly) {
            return;
        }
        if (!this.formGroup) {
            return;
        }


        setTimeout(() => {
            this.getCalculationTotal()
                .subscribe(res => {
                    if (typeof res !== 'boolean') {
                        this.applyCalculationTotal(res);
                        this.initialCalc();
                    } else {
                        this.calculating.emit(false);
                    }
                });
        }, 500);
    }

    public calculateCoeff() {
        if (this.isReadonly) {
            return;
        }
        if (!this.formGroup) {
            return;
        }

        setTimeout(async () => {
            const res = await lastValueFrom(this.getCalculationCoeff());
            if (typeof res !== 'boolean') {
                this.applyCalculationTotal(res);
                this.initialCalc();
            } else {
                this.calculating.emit(false);
            }
        }, 500);
    }

    protected setSupCCT(val?: string | number, changeCliTarif = true) {
        if (this.txtSupCCT) {
            const value = typeof val === 'number' ? '' + val : val;
            this.txtSupCCT.setValue(value);
        }
        if (this.getFormValue('clitarifra') !== 0 && changeCliTarif) {
            this.setFormValue('clitarifra', val);
        }
    }

    protected setTarifTotal(val?: string | number) {
        if (this.txtTarifTotal) {
            const value = typeof val === 'number' ? '' + val : val;
            this.txtTarifTotal.value = value;
        }
    }

    private initForm() {
        this.isFirstTime = true;
        this.initialCalc();
        this.calculateCCTSup();
    }

    // eslint-disable-next-line complexity
    private calculateCCTSup() {
        const salGlobal: number | undefined = this.getFormValue('salGlobal');
        const cctRa: boolean | undefined = this.getFormValue('cctRa');
        const cctEx: boolean | undefined = this.getFormValue('cctEx');
        const cctFc: boolean | undefined = this.getFormValue('cctFc');
        const cctId: string | undefined = this.getFormValue('cctId');

        if (salGlobal !== undefined && cctRa !== undefined && cctEx !== undefined && cctFc !== undefined && cctId !== undefined) {
            const data: MissionCalcCCTSup = {
                salGlobal, cctRa, cctEx, cctFc, cctId
            };
            this._calcService.calculateCCTSup(data)
                .subscribe(value => this.setSupCCT(value, false));
        }
    }

    private subscribeCalculateCCTSup() {
        this._calcService.supCttCalculation
            .pipe(takeUntil(this.subscriptions))
            .subscribe(value => {
                if (value.misId === this.getFormValue('misId')) {
                    this.setSupCCT(value.supCct, false);
                }
            });
    }

    private subscribeCalcTotal() {
        this.subscribeValueChange('misId', () => this.initialCalc(), 200);
    }

    private initialCalc() {
        setTimeout(() => {
            this.getCalculationTotal()
                .subscribe(val => {
                    if (typeof val !== 'boolean') {
                        if (this.isFirstTime) {
                            this.previousValue = val.clitariftotal;
                            this.isFirstTime = false;
                        }
                        this.setFormValue('clitarif', val.clitarif);
                        this.setTarifTotal(val.clitariftotal);
                        this.updateCoeffFromTarif();
                    } else {
                        this.calculating.emit(false);
                    }
                });
        }, 0);
    }

    // eslint-disable-next-line complexity
    private updateCoeffFromTarif() {
        if (!this.formGroup) {
            return;
        }
        const total = Number(this.getFormValue<string>('salTotal')) || 0;
        const tarif = Number(this.getFormValue<string>('clitarif')) || 0;
        if (!total || !tarif) {
            this.calculating.emit(false);
            return;
        }

        setTimeout(() => {
            this._calcService.calculateStats(this.formGroup?.value)
                .pipe(catchError(err => {
                    ERROR('Error during salary calculations', [err]);
                    return of(false);
                }))
                .subscribe(e => {
                    if (typeof e === 'boolean') {
                        this.calculating.emit(false);
                        return;
                    }

                    this.setFormValue('coeff', e.coeff);
                    this.calculating.emit(false);
                });
        }, 0);
    }

    private getCalculationTotal() {
        this.calculating.emit(true);
        if (!this.txtTarifTotal) {
            return of(false);
        }

        const value: number = Number(this.getFormValue<string>('clitarif')) || 0;
        const cct: number = Number(this.getFormValue<string>('clitarifra')) || 0;
        const total: number = Number(this.txtTarifTotal.getValue()) || 0;
        return this._calcService
            .calculateInvoice(value, cct, total, this.getAllDeductionFields(), this.isSaise)
            .pipe(catchError(err => {

                const msg = [this._translate.instant('calculation_fail_message_1'), this._translate.instant('calculation_fail_message_2'), this._translate.instant('calculation_fail_message_3'), this._translate.instant('calculation_fail_message_4')];

                const optionsCalcError: ModalMessageOptions = {
                    message: msg,
                    showCancel: false,
                    title: this._translate.instant('topmenu_salaries'),
                    confirmMessage: this._translate.instant('calculation_fail_message'),
                    okDisabled: false,
                    multiline: true
                };

                this._modalService
                    .showModal(ModalMessageComponent, optionsCalcError);

                const invHoursGroup: string[] = ['clitarif', 'coeff', 'clitarifra', 'clitarifmd', 'clitarmd6f'];
                const misId = this.getFormValue<string>('misId');
                if (!misId) {
                    return of(false);
                }
                this._ppmisRestClient
                    .getById(misId)
                    .subscribe(ppmis => {
                        const formGroup = this.formGroup?.controls as object;
                        Object.keys(formGroup)
                            .forEach(key => {
                                if (invHoursGroup.includes(key)) {
                                    this.setFormValue(key as keyof Ppmis, ppmis[key as keyof Ppmis]);
                                }
                            });
                    });
                return of(false);
            }));
    }

    // eslint-disable-next-line complexity
    private getAllDeductionFields(): SalaryDeduction {
        return {
            ageId: this.getFormValue('ageId') || '',
            empId: this.getFormValue('empId') || '',
            codepgm: this.getFormValue('codepgm') || '',
            codecna: this.getFormValue('codecna') || '',
            cctRa: this.getFormValue('cctRa') || false,
            cctEx: this.getFormValue('cctEx') || false,
            cctFc: this.getFormValue('cctFc') || false,
            mntFrais1: this.getFormValue('mntFrais1') || 0,
            mntFrais2: this.getFormValue('mntFrais2') || 0,
            cct: this.getFormValue('cctId') || '',
            misId: this.getFormValue('misId') || '',
            salGlobal: this.getFormValue('salGlobal') || 0,
            missalmode: this.getFormValue('missalmode') || 1
        };
    }

    private getCalculationCoeff() {
        this.calculating.emit(true);
        const salTotal: number = Number(this.getFormValue<string>('salTotal')) || 0;
        const cct: number = Number(this.getFormValue<string>('clitarifra')) || 0;
        const coeff: number = Number(this.getFormValue<string>('coeff')) || 0;
        return this._calcService
            .calculateCoeff(salTotal, cct, coeff, this.getAllDeductionFields())
            .pipe(catchError(err => {
                if (new RegExp('.*schedule_event.*not found').test(err.error.message)) {
                    this._calcService.warnMessagesBuffer.next('required_fields_calcs');
                }
                ERROR('Error during coeff calculations', [err]);
                return of(false);
            }));
    }

    private applyCalculationTotal(res: MissionCalcTarifResponse | MissionCalcApplyCoeffResponse) {
        this.applyCalculations(res);
        /** Delay pause updates for 1sec */
        setTimeout(() => {
            this.updateCoeffFromTarif();
        }, 1000);
    }

    private applyCalculations(result: MissionCalcTarifResponse | MissionCalcApplyCoeffResponse) {
        if (!this.formGroup) {
            return;
        }

        this.applyFormControls(result);
    }

    private applyFormControls(result: any) {
        for (const k of Object.keys(result)) {
            if (this._calcService.canApplyFormcontrol(k, result, this.formGroup)) {
                if (k === 'clitarifra') {
                    this._calcService.supCttCalculation.next({
                        misId: this.getFormValue('misId') ?? '', supCct: result[k]
                    });
                } else {
                    this.setFormValue(<any>k, result[k]);
                }
            }
        }
    }
}
