<form [formGroup]="formGroup"
      class="row space-form col-xs-12">
    <div class="row space-form col-xs-8">
        <iw-rest-grid #gesaexeTable
                      (selected)="getSelectedRow($event)"
                      [customColumns]="columns"
                      [defaultSelected]="true"
                      [defaultSelected]="true"
                      [defaultSortDir]="'desc'"
                      [defaultSort]="'cannee'"
                      [forceSelected]="true"
                      [hasGridColumnMenu]="false"
                      [type]="type"
                      class="col-xs-12 padding-top-5"
                      style="height: 400px"></iw-rest-grid>
    </div>

    <div class="col-xs-4 flex flex-v separator-left">
        <iw-button #btnSelectionner
                   (press)="selectionnerEntry()"
                   *ngIf="readonly"
                   [isDisabled]="!readonly"
                   [text]="'sidemenu_select' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnNew
                   (press)="createNewEntry()"
                   *ngIf="readonly"
                   [isDisabled]="!readonly"
                   [text]="'sidemenu_new' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnNew
                   (press)="bsYearByMail()"
                   *ngIf="readonly"
                   [isDisabled]="!readonly"
                   [text]="'bsYearByMail' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnClose
                   (press)="closeDialog()"
                   [text]="'fermer' | translate"
                   class="iw-button btn-block"
                   style="margin-block-start: auto"></iw-button>
    </div>
</form>
