import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('geclisui')
export class Geclisui {
    @IwRestEntityId() public cliSuiId?: string;
    public cliId?: string;
    @IwColumn({name: 'date', index: 1}) public dateAction?: Date;
    @IwColumn({name: 'titre_suivi_b', index: 2}) public action?: string;
    public notes?: string;
    public rappelFlag?: boolean;
    @IwColumn({name: 'rappel', index: 3}) public dateRappel?: Date;
    public rappelDone?: boolean;
    public rapSuiId?: string;
    @IwColumn({name: 'utilisateur', index: 0}) public userId?: string;
    public modeCreat?: string;
    public origine?: string;
}
