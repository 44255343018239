<iw-busy [ajustLeft]="181" [ajustTop]="47" [isBusy]="isLoading"></iw-busy>
<form [formGroup]="formGroup" class="row" style="padding: 5px">
    <div class="col-xs-4" style="height: 350px">
        <iw-rest-grid #ppcliadrGrid (dataChanged)="setDropdownOptions($event)" (selected)="onSelectedRow($event)"
                      [defaultSelected]="true" [queryStatements]="query" [selectedData]="selectedData"
                      [type]="type"></iw-rest-grid>
    </div>

    <div class="col-xs-8">
        <div class="row">
            <div class="col-xs-12 col-no-padding">
                <iw-textarea #edtAdresse [height]="200" [isDisabled]="true" [value]="fullAdresse"
                             class="iw-textarea"></iw-textarea>
            </div>

            <div class="row" style="margin-top: 10px;">
                <iw-button #btnDefAdresse (press)="setDefaultAddress()" [isDisabled]="isReadonly || !isSelected"
                           [text]="'parDefaut' | translate" [tooltip]="'adressePrincipale' | translate"
                           class="iw-button col-xs-4 no-padding"></iw-button>

                <iw-textfield #txtAdr_id [(value)]="cliadrId" [isDisabled]="true"
                              class="iw-input col-xs-4"></iw-textfield>

                <div class="buttons-box col-xs-4 no-padding" style="display: flex;">
                    <iw-button #btnAddAdr (press)="openAddresseClient()" [isDisabled]="isReadonly"
                               [tooltip]="'adresseNewClient' | translate" iconClass="fas fa-file"></iw-button>

                    <iw-button #btnModAdr (press)="editAddresseClient()" [isDisabled]="isReadonly || !isSelected"
                               [tooltip]="'adresseEdit' | translate" iconClass="fas fa-pencil-alt"></iw-button>

                    <iw-button #btnDelAdr (press)="removeAddresseClient()" [isDisabled]="isReadonly || !isSelected"
                               [tooltip]="'adresseDelete' | translate" iconClass="fas fa-trash"></iw-button>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 62px">
            <iw-dropdown #cboCcadr_id [isDisabled]="isReadonly" [labelAlign]="'top'"
                         [label]="'adressePourLesContrats' | translate" [options]="ccadrIdOpts"
                         class="iw-input col-xs-11 no-padding" formControlName="ccadrId"
                         style="align-self: flex-end"></iw-dropdown>
        </div>
    </div>

</form>
