import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppcha} from '@app/sam-base/models/placement';

import {PpclichagrdComponent} from '../components';

export const PPCLICHA_DEFAULT_FORM: FormDescription<Ppcha> = {
    form: PpclichagrdComponent, state: {
        ...buildDefaultState(Ppcha),
        diagTitle: 'chantiers',
        isNew: true,
        width: 800,
        destroyOnSave: true,
        editMode: 'edit'
    }
};
