import {IwColumn} from '@sam-base/core';

export class Autoaco {

    @IwColumn() public empId?: string;
    public nom?: string;
    public prenom?: string;
    public mntrapnotpaid?: number;
    public mntmisnotpaid?: number;
    public mntindnotpaid?: number;
    public mntacopaid?: number;
    public ageId?: string;
    public mnt100dispo?: number;
    public mnt60dispo?: number;
    public paytype?: number;
    public paytypeinfo?: string;
    public dtatype?: number;
    public banque?: string;
    public banqueAdr?: string;
    public banqueCrl?: string;
    public banqueCpt?: string;
    public banqueSwi?: string;
    public iban?: string;
}

