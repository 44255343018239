<form [formGroup]="formGroup"
      class="row">
    <div class="row col-xs-12 margin-bottom-10">
        <iw-textfield #txtpayid
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'encaissement_no' | translate"
                      [ngClass]="status"
                      class="iw-input col-xs-3"
                      formControlName="payId"></iw-textfield>
        <div class="col-xs-1"></div>
        <iw-textfield #txtBvrId
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'bvr' | translate"
                      class="iw-input col-xs-3"
                      formControlName="bvrId"></iw-textfield>
        <div class="col-xs-1"></div>
        <iw-textfield #txtcptRef
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'ecriture_no' | translate"
                      class="iw-input col-xs-3"
                      formControlName="cptaRef"></iw-textfield>
        <div class="col-xs-1"></div>
    </div>
    <div class="row col-xs-12 margin-bottom-10">
        <iw-gepaimode-dropdown #cboBvrMpaId
                               [gePaytypes]="['ENTREE', 'ENTREE_ATTRIBUTION']"
                               [isDisabled]="!isWriteMode"
                               [labelAlign]="'top'"
                               [label]="'mode' | translate"
                               [onlyLbvr]="false"
                               class="iw-input col-xs-4"
                               formControlName="mpaId"></iw-gepaimode-dropdown>

        <iw-date-picker [isDisabled]="!isWriteMode"
                        [labelAlign]="'top'"
                        [label]="'date' | translate"
                        class="iw-input col-xs-4"
                        formControlName="datePay"></iw-date-picker>
    </div>
    <div class="row col-xs-12 margin-bottom-10">
        <iw-entity-textfield [allowSearch]="true"
                             [entity]="clientType"
                             [isDisabled]="!isWriteMode"
                             [labelAlign]="'top'"
                             [label]="'client' | translate"
                             [name]="['nom']"
                             class="iw-input col-xs-10"
                             formControlName="cliId"></iw-entity-textfield>
    </div>

    <div class="row col-xs-12 margin-bottom-10">
        <iw-textfield [isDisabled]="!isWriteMode"
                      [labelAlign]="'top'"
                      [label]="'montant' | translate"
                      class="iw-input col-xs-3 text-align-right"
                      formControlName="montant"
                      type="number"></iw-textfield>

        <iw-textfield [isDisabled]="!isWriteMode"
                      [labelAlign]="'top'"
                      [label]="'frais' | translate"
                      class="iw-input col-xs-3 text-align-right"
                      formControlName="frais"
                      type="number"></iw-textfield>

        <iw-button (press)="deleteSolde(payId)"
                   [isDisabled]="solde === 0 || !isWriteMode"
                   [text]="'S'"
                   class="iw-button padding-left-10"
                   style="padding-top: 24px"></iw-button>

        <iw-textfield [isDisabled]="!isWriteMode"
                      [labelAlign]="'top'"
                      [label]="'solde' | translate"
                      class="iw-input col-xs-3 text-align-right"
                      formControlName="solde"
                      type="number"></iw-textfield>
    </div>

    <div class="col-xs-12"
         style="height: 350px">
        <iw-smart-grid #gepaieleGrid
                       (rowDoubleClick)="openFacture($event)"
                       (selected)="onSelected($event)"
                       [autoFormClick]="false"
                       [customColumns]="columnsGepaieleView"
                       [hasGridColumnMenu]="false"
                       [hasSortIcon]="false"
                       [queryStatements]="query"
                       [type]="gepaieleView"
                       class="col-xs-8 no-padding"
                       selectMode="single"
                       style="height: 70px"></iw-smart-grid>
    </div>
</form>
