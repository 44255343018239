<form [formGroup]="formGroup" class="row">
    <iw-enum-dropdown #cboRaplibmode [inputEnum]="raplibmode" [labelAlign]="'left'" [label]="'raplibmode' | translate"
                      [size]="'large'" class="col-xs-7" formControlName="raplibmode"></iw-enum-dropdown>


    <iw-textfield #txtLibWeek [labelAlign]="'left'" [label]="'texteSemaine' | translate" [size]="'large'"
                  class="iw-input col-xs-7 margin-top-15" formControlName="libweek"></iw-textfield>


    <iw-textfield #txtLibhequip [labelAlign]="'left'" [label]="'texteHEquipe' | translate" [size]="'large'"
                  class="iw-input col-xs-7 margin-top-15" formControlName="libhequip"></iw-textfield>


    <iw-textfield #txtLibhequix [labelAlign]="'left'" [label]="'texteHEquipeX' | translate" [size]="'large'"
                  class="iw-input col-xs-7 margin-top-15" formControlName="libhequix"></iw-textfield>

    <iw-textfield #txtLibhnuit [labelAlign]="'left'" [label]="'texteHNuit' | translate" [size]="'large'"
                  class="iw-input col-xs-7 margin-top-15" formControlName="libhnuit"></iw-textfield>


    <iw-textfield #Standardfield1 [labelAlign]="'left'" [label]="'texteHPause' | translate" [size]="'large'"
                  class="iw-input col-xs-7 margin-top-15" formControlName="libhpause"></iw-textfield>
</form>  