import {HttpClient} from '@angular/common/http';
import {Component, Type, ViewChild} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwSmartGridComponent} from '@app/sam-base/components/iw-smart-grid/iw-smart-grid.component';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {IwEventHubService} from '@app/sam-base/core/events';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {IwGridColumn} from '@app/sam-base/models';
import {Ppper} from '@app/sam-base/models/placement';
import {PeriodeService} from '@modules/sam-main/placement/services/periode.service';
import {TranslateService} from '@ngx-translate/core';
import {CalculatePeriodResponse, LockedEntityData} from '@sam-base/models/placement/calculate-period-response';
import {isArray} from 'lodash';

/** Component misses the implementation of caculations
 * To be integrated with SAM-4815
 */
@Component({
    templateUrl: 'period-management.component.html',
    styleUrls: ['period-management.component.scss']
})
export class PeriodManagementComponent extends BaseFormComponent<Ppper> {

    public type: Type<Ppper> = Ppper;
    public selectedPeriod: Ppper = {};
    public activePer?: Ppper;

    public columns = this.getPperColumns();

    public query: RestQueryParam<Ppper, string>[] = [
        {
            prop: 'salId',
            operation: RestQueryOperation.NotEquals,
            value: '_'
        }];
    @ViewChild('perGrid', {static: true}) public perGrid?: IwSmartGridComponent<Ppper>;

    constructor(private readonly _translate: TranslateService, private _http: HttpClient,
                private _store: IwStoreService, private readonly _modalService: ModalService,
                private readonly _toast: ToastService, private readonly _events: IwEventHubService<string>,
                private readonly _periodeService: PeriodeService) {
        super();
        this.setActivePeriod();
    }

    public get canCalculatePeriod() {
        return !!this.selectedPeriod.salId && this.selectedPeriod.status === 0;
    }

    public get periodCloseDisabled() {
        return this.selectedPeriod.status !== 0 || this.selectedPeriod.salId === this.activePer?.salId;
    }

    public getSelectedRow(event: Ppper[]) {
        if (!event.length) {
            this.selectedPeriod = {};
            return;
        }

        this.selectedPeriod = event[0];
    }

    public createNewPeriod() {
        this._periodeService.create()
            .subscribe(() => this.refreshGrid());
    }

    public activatePeriod() {
        if (this.selectedPeriod) {
            const period = this.selectedPeriod.salId ?? '';
            this._periodeService.activate(period)
                .subscribe(() => {
                    this.setActivePeriod();
                    this.refreshGrid();
                });
        }
    }

    public openPeriod() {
        if (this.selectedPeriod) {
            const period = this.selectedPeriod.salId ?? '';
            this._periodeService.open(period)
                .subscribe(() => this.refreshGrid());
        }
    }

    public closePeriod() {
        if (this.selectedPeriod) {
            const messages: string[] = [];
            const period = this.selectedPeriod.salId ?? '';
            this._store.dispatch(new actions.SetLoading(this.uuid, true));
            this._periodeService.close(period)
                .subscribe({
                    next: (value: Ppper | Ppper[]) => {
                        if (isArray(<Ppper>value)) {
                            for (const element of Object.values(value)) {
                                messages.push(`${element.empId} - ${element.empNom} ->\
                  ${element.texte}`);
                            }
                            this._toast.error('error_cant_close_period');
                            this.showModal(messages);
                        }
                        this.refreshGrid();
                        this._store.dispatch(new actions.SetLoading(this.uuid, false));
                    },
                    error: () => {
                        this._store.dispatch(new actions.SetLoading(this.uuid, false));
                    }
                });
        }
    }

    public async showModal(messages: string[]) {
        const options: ModalMessageOptions = {
            message: messages,
            showCancel: true,
            alertsMessage: this._translate.instant('cant_close_period'),
            width: 700,
            title: this._translate.instant('fail_close_period'),
            okDisabled: false
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
        } catch (error) {
        }
    }

    public async calculate() {
        if (this.selectedPeriod) {
            const options: ModalMessageOptions = {
                message: [],
                showCancel: true,
                title: this._translate.instant('calculate_period_warn_title'),
                confirmMessage: this._translate
                    .instant('calculate_period_warn_confirm'),
                okDisabled: false
            };
            try {
                await this._modalService.showModal(ModalMessageComponent, options);
                this.calculatePeriod();
            } catch (error) {
            }
        }
    }


    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    // eslint-disable-next-line complexity
    private calculatePeriod() {
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        const period = this.selectedPeriod.salId ?? '';
        this._periodeService.calculate(period)
            .subscribe(// eslint-disable-next-line complexity
                (result: CalculatePeriodResponse) => {
                    if (result.result) {
                        this._toast.success('ppper_message_success_calculate');
                    } else {
                        const entities: LockedEntityData[] = result.lockData?.lockedEntities ?? [];
                        let str = this._translate.instant('ppper_operation_blocked') + ':\n';

                        const usedBy = this._translate.instant('ppper_used_by') + '\n';
                        for (const entity of entities) {
                            let ownersList = '';
                            const ownersVar = entity.owners;
                            for (let y = 0; y < ownersVar.length; y++) {
                                if (y !== 0) {
                                    ownersList += ', ';
                                }
                                ownersList += ownersVar[y];
                            }
                            str += '- ' + entity.entityName + ', ' + usedBy + ' ' + ownersList + '.' + '';
                        }
                        this._toast.error(str);
                    }
                    this.refreshGrid();
                    this._store.dispatch(new actions.SetLoading(this.uuid, false));
                }, (_) => {
                    this._store.dispatch(new actions.SetLoading(this.uuid, false));
                });
    }

    private setActivePeriod() {
        this._periodeService.getActivePeriod()
            .subscribe((per: Ppper) => {
                this.getSelectedRow([per]);

                this.activePer = per;
                this._events.emit('active_period', {
                    period: per
                });
            });
    }

    private refreshGrid() {
        if (!this.perGrid) {
            return;
        }
        this.perGrid.refresh();
    }

    private getPperColumns(): IwGridColumn<Ppper>[] {
        return [
            {
                prop: 'texte',
                name: this._translate.instant('period_column'),
                width: 100,
                type: 'activePer'
            }];
    }
}
