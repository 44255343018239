<iw-dialog #dialog
           [bodyPadding]="'10'"
           [cls]="'iw-modal'"
           [height]="height"
           [modal]="true"
           [resizable]="false"
           [title]="title | translate"
           [visible]="display"
           [width]="width">
    <div class="container">
        <div class="row">
            <iw-table #grdSalary
                      (selectedChange)="selectCountOption($event)"
                      [columns]="columns"
                      [data]="counts"
                      [isFilterEnable]="true"
                      [isGridColumnMenuEnable]="false"
                      [isSortIconEnable]="false"
                      [selectionMode]="'checkbox'"
                      class="col-xs-12 no-padding"
                      style="height: 33vh;"></iw-table>
        </div>
        <div class="row">
            <iw-entity-textfield #cboEmp
                                 (valueChange)="onSelectClient($event)"
                                 [allowSearch]="true"
                                 [entity]="entityPpemp"
                                 [filter]="filterPpemp"
                                 [isDisabled]="false"
                                 [labelAlign]="'left'"
                                 [label]="'employee' | translate"
                                 [name]="['nom', 'prenom']"
                                 [showIdField]="false"
                                 [value]="empIdInput"
                                 class="iw-input col-xs-12 medium"></iw-entity-textfield>

        </div>
        <div class="row between-xs">
            <div class="col-xs-2">
                <iw-button (press)="onPressCancel()"
                           [ngClass]="okColor"
                           [text]='"Cancel"'
                           id="cancel-create"></iw-button>
            </div>
            <div class="col-xs-2">
                <iw-button (press)="onPressOk()"
                           [isDisabled]="!atLeastOneSelected"
                           [ngClass]="okColor"
                           [text]="this._options.force ? ('forcer' | translate) : ('init' | translate)"></iw-button>
            </div>
        </div>

    </div>
</iw-dialog>
