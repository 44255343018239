import {SamUserRole} from '@sam-base/models/admin/sam-user';

/**
 * Defines the roles that have access to the mission
 * sidemenu buttons
 */
export function missionRoles(): string[] {
    return [
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}
