import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Saems} from '@app/sam-base/models/salary/saems';
import {TranslateService} from '@ngx-translate/core';
import {EntityNavigationService} from '@sam-base/core';
import {SarepService} from '@sam-base/core/services/sarep.service';
import {IwGridColumn, RowClickEvent} from '@sam-base/models';
import {Ppclimis, Ppind, Pprapview} from '@sam-base/models/placement';
import {FicheSalIndemnityView} from '@sam-base/models/salary/fiche-sal-indemnity-view';
import {FicheSalMissionView} from '@sam-base/models/salary/fiche-sal-mission-view';
import {FicheSalRapportView} from '@sam-base/models/salary/fiche-sal-rapport-view';

@Component({
    selector: 'iw-tab-saems-missions',
    templateUrl: './tab-saems-missions.component.html'
})
export class TabSaemsMissionsComponent extends BasePartialFormComponent<Saems> implements OnInit {

    public columns = this.getMissionColumns();
    public linkedMissions: FicheSalMissionView[] = [];
    public columnsrap = this.getRapportColumns();
    public linkedRapports: FicheSalRapportView[] = [];
    public columnsind = this.getIndemnityColumns();
    public linkedIndemnities: FicheSalIndemnityView[] = [];
    protected readonly top = top;

    constructor(private readonly _sarepService: SarepService, private readonly _translate: TranslateService,
                private readonly _entityNavService: EntityNavigationService) {
        super();
    }

    // generate getter for those two
    public get empId(): string {
        return this.getFormValue('empId') as string;
    }

    public get salId(): string {
        return this.getFormValue('salId') as string;
    }

    public ngOnInit() {
        console.log('TabSaemsMissionComponent onInit');
        this.loadRelatedMissions();
        this.subscribeValueChange('empId', empId => {
            this.loadRelatedMissions();
        })
        this.loadRelatedRapports();
        this.subscribeValueChange('empId', empId => {
            this.loadRelatedRapports();
        })
        this.loadRelatedIndemnities();
        this.subscribeValueChange('empId', empId => {
            this.loadRelatedIndemnities();
        })
    }

    openMission($event: RowClickEvent<FicheSalMissionView>) {
        const misId = $event.row.misId;
        if (!misId) return;
        this._entityNavService
            .navigateToEntityForm(Ppclimis, misId);
    }

    openRapport($event: RowClickEvent<FicheSalRapportView>) {
        const rapId = $event.row.rapId;
        if (!rapId) return;
        this._entityNavService
            .navigateToEntityForm(Pprapview, rapId);
    }

    openIndemnity($event: RowClickEvent<FicheSalIndemnityView>) {
        const indId = $event.row.indId;
        if (!indId) return;
        this._entityNavService
            .navigateToEntityForm(Ppind, indId);
    }

    private loadRelatedMissions() {
        this._sarepService.getMissionInfoForFicheSal(this.empId, this.salId).subscribe(missions => {
            this.linkedMissions = missions;
        })
    }

    private loadRelatedRapports() {
        this._sarepService.getRapportInfoForFicheSal(this.empId, this.salId).subscribe(rapports => {
            this.linkedRapports = rapports;
        })
    }

    private getMissionColumns(): IwGridColumn<FicheSalMissionView>[] {
        return [
            {
                prop: 'misId',
                name: this._translate.instant('id'),
                index: 0
            },
            {
                prop: 'cliNom',
                name: this._translate.instant('client'),
                index: 1
            },
            {
                prop: 'nbHeures',
                name: this._translate.instant('nbheures'),
                index: 2,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'nbJours',
                name: this._translate.instant('nbjours'),
                index: 3
            },
            {
                prop: 'qualif',
                name: this._translate.instant('qualification'),
                index: 4
            },
            {
                prop: 'cctId',
                name: this._translate.instant('cct'),
                index: 5
            },
            {
                prop: 'dateStart',
                name: this._translate.instant('date_debut'),
                index: 6
            },
            {
                prop: 'salTotal',
                name: this._translate.instant('salaire_total'),
                index: 7,
                type: 'mnt',
                align: 'right'
            }];
    }

    private getRapportColumns(): IwGridColumn<FicheSalRapportView>[] {
        return [
            {
                prop: 'rapId',
                name: this._translate.instant('id'),
                index: 0
            },
            {
                prop: 'rapNo',
                name: this._translate.instant('rapNo'),
                index: 1
            },
            {
                prop: 'dateLundi',
                name: this._translate.instant('datelundi'),
                index: 2,
                type: 'date'
            },
            {
                prop: 'weekNumber',
                name: this._translate.instant('semaine'),
                index: 3
            },
            {
                prop: 'nbHeuTot',
                name: this._translate.instant('nbheures'),
                index: 4,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'nbJours',
                name: this._translate.instant('nbjours'),
                index: 5
            },
            {
                prop: 'chantier',
                name: this._translate.instant('chantier'),
                index: 6
            },
            {
                prop: 'misId',
                name: this._translate.instant('mission'),
                index: 7
            },
            {
                prop: 'facNo',
                name: this._translate.instant('facture'),
                index: 8
            }];
    }

    private getIndemnityColumns(): IwGridColumn<FicheSalIndemnityView>[] {
        return [
            {
                prop: 'indId',
                name: this._translate.instant('id'),
                index: 0
            },
            {
                prop: 'indDate',
                name: this._translate.instant('date'),
                index: 1,
                type: 'date'
            },
            {
                prop: 'qte',
                name: this._translate.instant('qte'),
                index: 2,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mntEmp',
                name: this._translate.instant('montant'),
                index: 3,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'totEmp',
                name: this._translate.instant('total'),
                index: 4,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'misId',
                name: this._translate.instant('mission'),
                index: 5
            },
            {
                prop: 'libelle',
                name: this._translate.instant('libelle'),
                index: 6
            },
            {
                prop: 'griId',
                name: this._translate.instant('genre'),
                index: 7
            }];
    }

    private loadRelatedIndemnities() {
        this._sarepService.getindemnityInfoForFicheSal(this.empId, this.salId).subscribe(indemnities => {
            this.linkedIndemnities = indemnities;
        })
    }
}
