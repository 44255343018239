<form [formGroup]="formGroup" class="row no-padding">
    <iw-checkbox #chkplRecalcPaicli [isDisabled]="isReadonly" [label]="'plRecalcPaicli' | translate"
                 class="iw-input col-xs-12" formControlName="plRecalcPaicli"></iw-checkbox>
    <iw-checkbox #chkplDelZeroPaicli [isDisabled]="isReadonly" [label]="'plDelZeroPaicli' | translate"
                 class="iw-input col-xs-12" formControlName="plDelZeroPaicli"></iw-checkbox>

    <div class="row margin-top-10 col-xs-12 flex-end">
        <iw-button (press)="calculate()" [isDisabled]="!isCalculerBtnEnable()" [text]="'calculer' | translate"
                   class=" col-xs-3"></iw-button>
        <iw-button (press)="close()" [text]="'fermer' | translate" class="flex-end col-xs-3"></iw-button>
    </div>
</form>