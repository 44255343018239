import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EmpPlanningService} from '@app/modules/sam-main/placement/services/emp-planning.service';
import {EntityService} from '@app/modules/sam-main/placement/services/entity.service';
import {InterijobService} from '@app/modules/sam-main/placement/services/interijob.service';
import {LocalProfileService} from '@app/modules/sam-main/placement/services/local-profile.service';
import {MatchingService} from '@app/modules/sam-main/placement/services/matching.service';
import {MissionService} from '@app/modules/sam-main/placement/services/mission.service';
import {PayacoService} from '@app/modules/sam-main/placement/services/payaco.service';
import {PpacattestService} from '@app/modules/sam-main/placement/services/ppacattest.service';
import {PpheusaidService} from '@app/modules/sam-main/placement/services/ppheusaid.service';
import {PpplfService} from '@app/modules/sam-main/placement/services/ppplf.service';
import {ReportsService} from '@app/modules/sam-main/placement/services/reports.service';
import {SearchNavigationService} from '@app/sam-base/core';
import {AuthModule} from '@app/sam-base/core/auth/auth.module';
import {ClipboardService} from '@app/sam-base/core/clipboard/clipboard.service';
import {ConfigTemplateService} from '@app/sam-base/core/config-template/services/config-template.service';
import {DomModule} from '@app/sam-base/core/dom';
import {EventsModule} from '@app/sam-base/core/events';
import {FormHandlerService} from '@app/sam-base/core/form-handler/form-handler.service';
import {GridProfileService} from '@app/sam-base/core/grid-profile/grid-profile.service';
import {HotkeyModule} from '@app/sam-base/core/hotkey/hotkey.module';
import {IwReportsModule} from '@app/sam-base/core/reports';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {IwRestApiModule} from '@app/sam-base/core/rest-api/rest-api.module';
import {DashboardNavigationService} from '@app/sam-base/core/services/dashboard-navigation.service';
import {DialogStateService} from '@app/sam-base/core/services/dialog-state.service';
import {EntityDeleteService} from '@app/sam-base/core/services/entity-delete.service';
import {EntityDetailProviderService} from '@app/sam-base/core/services/entity-detail.service';
import {EntityNavigationService} from '@app/sam-base/core/services/entity-navigation.service';
import {EntitySelectService} from '@app/sam-base/core/services/entity-select.service';
import {EsAdvancedMatchService} from '@app/sam-base/core/services/es-advanced-match.service';
import {GlobalSettingsService} from '@app/sam-base/core/services/global-settings.service';
import {HeaderToolbarService} from '@app/sam-base/core/services/header-toolbar.service';
import {IjTimesheetsService} from '@app/sam-base/core/services/ij-timesheets.service';
import {MatchingHelpers} from '@app/sam-base/core/services/matching-helpers.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {PpconService} from '@app/sam-base/core/services/ppcon.service';
import {PpkwService} from '@app/sam-base/core/services/ppkw.service';
import {PrintReportService} from '@app/sam-base/core/services/print-report.service';
import {SideMenuStateService} from '@app/sam-base/core/services/side-menu-state.service';
import {ViewContainerRefService} from '@app/sam-base/core/services/view-container-ref.service';
import {effects, IwStoreService} from '@app/sam-base/core/store';
import {IwActionService} from '@app/sam-base/core/store/iw-action.service';
import {ToastService} from '@app/sam-base/core/toast';
import {ToastHostComponent} from '@app/sam-base/core/toast/toast-host.component';
import {UuidService} from '@app/sam-base/core/uuid/uuid.service';
import {IwValidationsModule} from '@app/sam-base/core/validations/validations.module';
import {COMMON_ENTITIES} from '@app/sam-base/models/common';
import {KibanaService} from '@core/services/kibana.service';
import {ParametersService} from '@core/services/parameters.service';
import {PeriodeService} from '@modules/sam-main/placement/services/periode.service';
import {SalaryLocalProfileService} from '@modules/sam-main/salary/services/salary-local-profile.service';
import {EffectsModule} from '@ngrx/effects';
import {TranslateModule} from '@ngx-translate/core';
import {AvsNumberService} from '@sam-base/core/services/avs-number.service';
import {BookingService} from '@sam-base/core/services/booking.service';
import {ConfigService} from '@sam-base/core/services/config.service';
import {EmployeeDeductionService} from '@sam-base/core/services/employee-deduction-service';
import {EncaissementService} from '@sam-base/core/services/encaissement.service';
import {FactureService} from '@sam-base/core/services/facture.service';
import {FofouService} from '@sam-base/core/services/fofou.service';
import {GecliService} from '@sam-base/core/services/gecli.service';
import {GeclisuiService} from '@sam-base/core/services/geclisui.service';
import {GecomptaService} from '@sam-base/core/services/gecompta.service';
import {GepaicliService} from '@sam-base/core/services/gepaicli.service';
import {GepaicondService} from '@sam-base/core/services/gepaicond.service';
import {GepaieleService} from '@sam-base/core/services/gepaiele.service';
import {GepaihdrService} from '@sam-base/core/services/gepaihdr.service';
import {GepaimodeService} from '@sam-base/core/services/gepaimode.service';
import {GeperService} from '@sam-base/core/services/geper.service';
import {GesaexeService} from '@sam-base/core/services/gesaexe.service';
import {LookupsService} from '@sam-base/core/services/lookups.service';
import {NpaService} from '@sam-base/core/services/npa.service';
import {QRService} from '@sam-base/core/services/payInformation.service';
import {PpgriService} from '@sam-base/core/services/ppgri.service';
import {RappelsService} from '@sam-base/core/services/rappel.service';
import {SacomptaService} from '@sam-base/core/services/sacompta.service';
import {SaconjService} from '@sam-base/core/services/saconj.service';
import {SadeddaService} from '@sam-base/core/services/sadedda.service';
import {SadedtxService} from '@sam-base/core/services/sadedtx.service';
import {SadsService} from '@sam-base/core/services/sads.service';
import {SaeioService} from '@sam-base/core/services/saeio.service';
import {SaeisaService} from '@sam-base/core/services/saeisa.service';
import {SaempService} from '@sam-base/core/services/saemp.service';
import {SaepService} from '@sam-base/core/services/saep.service';
import {SagenService} from '@sam-base/core/services/sagen.service';
import {SaisService} from '@sam-base/core/services/sais.service';
import {SaisdefService} from '@sam-base/core/services/saisdef.service';
import {SamUserService} from '@sam-base/core/services/sam-user.service';
import {SapaimodeService} from '@sam-base/core/services/sapaimode.service';
import {SaparService} from '@sam-base/core/services/sapar.service';
import {SaparassService} from '@sam-base/core/services/saparass.service';
import {SapardedService} from '@sam-base/core/services/saparded.service';
import {SuiviService} from '@sam-base/core/services/suivi.service';
import {TeamService} from '@sam-base/core/services/team.service';
import {UtilsService} from '@sam-base/core/services/utils.service';
import {ToastCustomComponent} from '@sam-base/core/toast/toast-custom/toast-custom.component';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {DocumentManagementService} from './document-management/document-management.service';
import {MailService} from './mail/mail.service';
import {PlacementSearchNavigation} from './placement-search/placement-search.service';
import {PrintModule} from './print/print.module';
import {IwDecompteNavService} from './services/iw-decompte-nav.service';
import {SmsCallService} from './sms/sms-call.service';

@NgModule({
    imports: [
        CommonModule,
        AuthModule,
        EventsModule,
        TranslateModule,
        IwRestApiModule,
        IwValidationsModule,
        PrintModule,
        DomModule,
        HotkeyModule,
        IwReportsModule,
        EffectsModule.forRoot(effects),
        ToastModule],
    declarations: [
        ToastHostComponent,
        ToastCustomComponent],
    providers: [
        AvsNumberService,
        ClipboardService,
        ConfigService,
        ConfigTemplateService,
        DashboardNavigationService,
        DialogStateService,
        DocumentManagementService,
        EmpPlanningService,
        EmployeeDeductionService,
        EncaissementService,
        EntityDeleteService,
        EntityDetailProviderService,
        EntityNavigationService,
        EntitySelectService,
        EntityService,
        EsAdvancedMatchService,
        FactureService,
        FofouService,
        FormHandlerService,
        GecliService,
        GeclisuiService,
        GecomptaService,
        BookingService,
        GepaicliService,
        GepaicondService,
        GepaieleService,
        GepaihdrService,
        GepaimodeService,
        GeperService,
        GesaexeService,
        GlobalSettingsService,
        GridProfileService,
        HeaderToolbarService,
        IjTimesheetsService,
        InterijobService,
        IwActionService,
        IwDecompteNavService,
        IwStoreService,
        KibanaService,
        LocalProfileService,
        LookupsService,
        SalaryLocalProfileService,
        MailService,
        MatchingHelpers,
        MatchingService,
        MessageService,
        MissionService,
        ModalService,
        NpaService,
        ParametersService,
        PayacoService,
        PeriodeService,
        PlacementSearchNavigation,
        PpacattestService,
        PpconService,
        PpgriService,
        PpheusaidService,
        PpkwService,
        PpplfService,
        PrintReportService,
        QRService,
        RappelsService,
        ReportsService,
        SaconjService,
        SadeddaService,
        SadedtxService,
        SadsService,
        SaeioService,
        SaeisaService,
        SaempService,
        SamUserService,
        TeamService,
        SaepService,
        SagenService,
        SacomptaService,
        SaisService,
        SaisdefService,
        SapaimodeService,
        SaparService,
        SaparassService,
        SapardedService,
        SearchNavigationService,
        SideMenuStateService,
        SmsCallService,
        SuiviService,
        ToastService,
        UtilsService,
        UuidService,
        ViewContainerRefService],
    exports: [
        IwRestApiModule,
        IwValidationsModule,
        ToastHostComponent]
})
export class CoreModule {
    constructor(public readonly rest: RestApiService, public readonly _paramsService: ParametersService) {
        rest.registerEntityTypes(...COMMON_ENTITIES);
    }
}
