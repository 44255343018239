import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwEventHubService, IwStoreService} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {SaenfService} from '@app/sam-base/core/services/saenf.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {getAgeByDateOfBirth} from '@app/sam-base/helpers/date';
import {FormKeys} from '@app/sam-base/models';
import {Saenf} from '@app/sam-base/models/common';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


enum Sexe {
    masculin = '1', feminim = '2',
}

@Component({templateUrl: './saenf.component.html'})
export class SaenfComponent extends BaseStoreFormComponent<Saenf> implements OnInit, OnDestroy {
    public entity = Saenf;

    public sexe = Sexe;

    public query: RestQueryParam<Saenf, any>[] = [];
    public alfBegin = '';
    public age = 0;
    public dateNow = IwDateHelper.getDateNow();
    private subscriptions = new Subject();

    constructor(store: IwStoreService, private _saenfService: SaenfService,
                private readonly _events: IwEventHubService<string>) {
        super(store);
        this.isNewChanged
            .pipe(takeUntil(this.subscriptions))
            .subscribe((e: boolean) => this.onIsNewChanged(e));
        this.formValueChange
            .pipe(takeUntil(this.subscriptions))
            .subscribe(() => this.calcAge());
    }

    public get hasACharge(): boolean {
        return !!this.getFormValue('acharge');
    }

    public get empId(): string {
        return this.getData('empId', true) || '';
    }

    public get empNom() {
        return this.getData('nom') || '';
    }

    public onClose() {
        if (this.empId) {
            this._saenfService.updateSaenf(this.empId);
            this._events.emit('enf_closed');
        }
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public ngOnInit() {
        const empId = this.empId;
        this.query = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'empId',
                value: empId
            }];
        this.subscribeValueChange<string>('allocation', e => {
            if (e) {
                this.alfBegin = IwDateHelper.dateIsoString(IwDateHelper.addToDate(1, 'month', IwDateHelper.startOf('month', this.getFormValue('datenaiss'))));
            } else {
                this.alfBegin = '';
                this.setFormValue('alfEnd', undefined);
                this.setFormValue('alfMnt', undefined);
            }
            this.setFormValue('alfBegin', this.alfBegin);
        });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    protected getFormControlNames(): FormKeys<Saenf> {
        return [
            'nom',
            'prenom',
            'datenaiss',
            'acharge',
            'allocation',
            'empId',
            'noavs13',
            'alfEnd',
            'alfBegin',
            'alfMnt',
            'sexe',
            [
                'alfuniqMnt',
                new UntypedFormControl(undefined, Validators.maxLength(10))]];
    }

    protected onIsNewChanged(isNew: boolean) {
        if (this.empId && isNew) {
            this.setFormValue('sexe', '1');
            this.setFormValue('empId', this.empId);
            this.setFormValue('acharge', true);
            this.updateEmpNom();
        }
    }

    protected validateReadonlyStatus(e: Saenf) {
        return (!e.enfId || e.enfId.length === 0) && !this.isNew;
    }

    protected getValidationType() {
        return Saenf;
    }

    protected validateFields(e: Saenf): boolean {
        return super.validateFields(e);
    }

    private updateEmpNom() {
        const nom = this.getFormValue('nom');
        if (!nom && this.empNom && this.isNew) {
            this.setFormValue('nom', this.empNom);
        }
    }

    private calcAge() {
        const birth = <string | null>this.getFormValue('datenaiss');

        if (birth) {
            this.age = getAgeByDateOfBirth(birth);
        } else {
            this.age = 0;
        }
    }

}
