import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {MissionCalculationsService} from '@app/sam-base/core/services/mission-calculations.service';
import {ToastService} from '@app/sam-base/core/toast';
import {Ppmis} from '@app/sam-base/models/placement';
import {MissionCalcCCTResponse} from '@app/sam-base/models/placement/mission-calculations-result.model';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {ModalMessageComponent} from '@sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@sam-base/components/modal/modal-message/modal-message.options';
import {ModalService, RestApiService, RestEntityClient} from '@sam-base/core';
import {forkJoin, lastValueFrom, of} from 'rxjs';
import {catchError, take} from 'rxjs/operators';
import {HeuresSup, SalaryMode} from '../../tab-ppmis-salary-enums';

@Component({
    templateUrl: './mis-salary-hours.component.html', selector: 'iw-mis-salary-hours'
})
export class MisSalaryHoursComponent extends BasePartialFormComponent<Ppmis> implements OnInit {
    public frais1?: boolean;
    public frais2?: boolean;

    @Input()
    public set tempdataActivated(mode: boolean) {
        this._tempdatActivated = mode;
    }

    public get tempdataActivated() {
        return this._tempdatActivated;
    }

    @Input() public hasJfNonPayes = false;

    public get shtxmode(): boolean {
        return this.getFormValue('shtxmode') || false;
    }

    @Output() public calculating: EventEmitter<boolean> = new EventEmitter();

    public get isBaseSalaryMode() {
        return this.getFormValue<number>('shbaseglob') !== SalaryMode.salary_mode_global;
    }

    public get isGlobalSalaryMode() {
        return this.getFormValue<number>('shbaseglob') === SalaryMode.salary_mode_global;
    }

    public get mntJfz(): number | undefined {
        return this.getFormValue<number>('mntJfz');
    }

    public get isJf() {
        return !!this.getFormValue<boolean>('ljfh');
    }

    public get is13() {
        return !!this.getFormValue<boolean>('l13');
    }

    public get isVac() {
        return !!this.getFormValue<boolean>('lvac');
    }

    public get isHEquipe() {
        return !!this.getFormValue<boolean>('heurequipe');
    }

    public get isHNuit() {
        return !!this.getFormValue<boolean>('heurenuit');
    }

    public get isSalBaseInvalid() {
        return (!this.isReadonly && this.formGroup?.controls['salBase'].invalid);
    }

    public get salBaseTooltip() {
        return this._translate.instant('cct_min_value_error') + this.getFormValue('cctMinValue');
    }

    public salaryModeEnum = SalaryMode;

    public heursSupNames = HeuresSup;

    public canHeurPauseAuto = true;
    public canHeurPause = true;

    private _tempdatActivated = false;
    private _ppmisRestClient: RestEntityClient<Ppmis>;

    constructor(private readonly _calcService: MissionCalculationsService, private readonly _toastService: ToastService, private readonly _translate: TranslateService, private readonly _parametersService: ParametersService, private readonly _restClient: RestApiService, private readonly _modalService: ModalService) {
        super();
        this._ppmisRestClient = _restClient.getEntityClient(Ppmis);
    }

    public ngOnInit(): void {
        forkJoin([this._parametersService.getParameterValue('lfraish1'), this._parametersService.getParameterValue('lfraish2')])
            .subscribe((data: any) => {
                this.frais1 = (data[0] ?? true) as boolean;
                this.frais2 = (data[1] ?? true) as boolean;
            });
    }

    public triggerReadyToCalculate() {
        if (this.isReadonly) {
            return;
        }
        this.setFormValue('isCalculating', true);
    }

    public calculate() {
        setTimeout(async () => {
            if (!this.formGroup) {
                return;
            }
            const mis: Ppmis = this.formGroup.value;
            const res = await lastValueFrom(this.getCalculation(mis));
            if (typeof res !== 'boolean') {
                this.applyCalculations(res);
            }
        }, 500);
    }

    public asyncCalc(formControlName: string) {
        this.formGroup?.controls[formControlName]
            .valueChanges.pipe(take(1))
            .subscribe(() => {
                this.calculate();
            });
    }

    private getCalculation(mis: Ppmis) {
        this.calculating.emit(true);
        return this._calcService.getCalculationsWithCCT(mis)
            .pipe(catchError(err => {

                const msg = [this._translate.instant('calculation_fail_message_1'), this._translate.instant('calculation_fail_message_2'), this._translate.instant('calculation_fail_message_3'), this._translate.instant('calculation_fail_message_4')];

                const optionsCalcError: ModalMessageOptions = {
                    message: msg,
                    showCancel: false,
                    title: this._translate.instant('topmenu_salaries'),
                    confirmMessage: this._translate.instant('calculation_fail_message'),
                    okDisabled: false,
                    multiline: true
                };

                this._modalService
                    .showModal(ModalMessageComponent, optionsCalcError);

                const salBaseGroup: string[] = ['salBase', 'shcalcmode', 'ljfh', 'mntJfz', 'mntJf', 'txJf', 'lprovJf', 'jfNonPayes', 'l13', 'mnt13', 'tx13', 'lprov13', 'lvac', 'mntVac', 'txVac', 'lprovVac', 'salGlobal', 'shbaseglob', 'mntFrais1', 'mntFrais2', 'salTotal', 'heurepause', 'txPause', 'mntPause', 'hpauseauto', 'shsupmode'];

                const misId = this.getFormValue<string>('misId');
                if (!misId) {
                    return of(false);
                }
                this._ppmisRestClient
                    .getById(misId)
                    .subscribe(ppmis => {
                        const formGroup = this.formGroup?.controls as object;
                        Object.keys(formGroup)
                            .forEach(key => {
                                if (salBaseGroup.includes(key)) {
                                    this.setFormValue(key as keyof Ppmis, ppmis[key as keyof Ppmis]);
                                }
                            });
                    });
                this.calculating.emit(false);
                return of(false);
            }));
    }

    private applyCalculations(result: MissionCalcCCTResponse) {
        if (!this.formGroup) {
            this.calculating.emit(false);
            return;
        }

        this.applyFormControls(result);
        this.calculating.emit(false);
    }

    private applyFormControls(result: any) {
        for (const k of Object.keys(result)) {
            if (this._calcService.canApplyFormcontrol(k, result, this.formGroup)) {
                if (k === 'clitarifra') {
                    this._calcService.supCttCalculation.next({
                        misId: this.getFormValue('misId') ?? '', supCct: result[k]
                    });
                } else {
                    this.setFormValue(<any>k, result[k]);
                }
            }
        }
    }
}
