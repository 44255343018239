<form [formGroup]="formGroup" class="row">
    <div class="row space-form col-xs-12">
        <iw-enum-dropdown [inputEnum]="emaType" [labelAlign]="'top'" [label]="'type' | translate"
                          class="iw-input col-xs-6" formControlName="emiType"></iw-enum-dropdown>
        <div class="col-xs-6"></div>
        <iw-date-picker *ngIf="isEmiTypeSelected()" [labelAlign]="'top'" [label]="'valable_date' | translate"
                        class="col-xs-2" formControlName="emaDate"></iw-date-picker>
        <iw-enum-dropdown *ngIf="isValueSelected('1')" [inputEnum]="motifsEntree" [labelAlign]="'top'"
                          [label]="'motifs_entree' | translate" class="iw-input col-xs-6"
                          formControlName="emaCode"></iw-enum-dropdown>
        <iw-enum-dropdown *ngIf="isValueSelected('2')" [inputEnum]="motifsMutation" [labelAlign]="'top'"
                          [label]="'motifs_mutation' | translate" class="iw-input col-xs-6"
                          formControlName="emaCode"></iw-enum-dropdown>
        <iw-enum-dropdown *ngIf="isValueSelected('3')" [inputEnum]="motifsSortie" [labelAlign]="'top'"
                          [label]="'motifs_sortie' | translate" class="iw-input col-xs-6"
                          formControlName="emaCode"></iw-enum-dropdown>
        <iw-textfield [decimals]="2" [labelAlign]="'top'" [label]="'base_lpp_y' | translate" [type]="'number'"
                      class="iw-input col-xs-6" formControlName="baseLppY"></iw-textfield>

    </div>
</form>
