import {Injectable} from '@angular/core';
import {DialogStateService, SearchNavigationService, SearchOptions} from '@app/sam-base/core';
import {NavigationExtras} from '@app/sam-base/models/nav-extras.model';

/**
 * Service to navigate to search grids.
 *
 * Like SearchNavigationService but with params specific for placement
 */
@Injectable()
export class PlacementSearchNavigation {

    constructor(private readonly _nav: SearchNavigationService,
                private readonly _dialogStateService: DialogStateService) {
    }

    public navigate<T>(options: SearchOptions<T, NavigationExtras<T>>) {
        this._dialogStateService.removeFocus();
        this._nav.navigate(options);
    }
}
