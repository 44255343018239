<form [formGroup]="formGroup" class="row space-form">
    <iw-textarea #edtClient (valueChange)="updateWebData('client', $event)" [isDisabled]="isReadonly"
                 [labelAlign]="'top'" [label]="'client' | translate " [value]="client"
                 class="iw-textarea col-xs-12"></iw-textarea>

    <iw-textarea #edtMission (valueChange)="updateWebData('mission', $event)" [isDisabled]="isReadonly"
                 [labelAlign]="'top'" [label]="'missionResponsabilites' | translate" [value]="mission"
                 class="iw-textarea col-xs-12"></iw-textarea>

    <iw-textarea #edtProfil (valueChange)="updateWebData('profil', $event)" [isDisabled]="isReadonly"
                 [labelAlign]="'top'" [label]="'profil' | translate" [value]="profil"
                 class="iw-textarea col-xs-12"></iw-textarea>

    <iw-textarea #edtDivers (valueChange)="updateWebData('divers', $event)" [isDisabled]="isReadonly"
                 [labelAlign]="'top'" [label]="'divers' | translate" [value]="divers"
                 class="iw-textarea col-xs-12"></iw-textarea>

    <div class="row col-xs-12 separator-top padding-top-10">
        <iw-textarea #edtRmarquesInternes [height]="60" [isDisabled]="isReadonly" [labelAlign]="'top'"
                     [label]="'remarquesInternes' | translate" class="iw-textarea col-xs-12 padding-top-10"
                     formControlName="note"></iw-textarea>
    </div>

</form>
