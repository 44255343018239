import {Injectable} from '@angular/core';
import {Ppsuiviview} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';

import {IwDateHelper} from '../dates/iw-date-helper';

@Injectable()
export class SuiviService {

    constructor(private _translate: TranslateService) {
    }

    // eslint-disable-next-line complexity
    public getSuiviDescription(suivi: Ppsuiviview) {
        return `
      ${this._translate.instant('date')}: ${IwDateHelper.dateFormatFromString(suivi.dateaction, 'DD.MM.YYYY')}
      ${this._translate.instant('conseiller')}: ${suivi.nomconseille ?? ''}
      ${this._translate.instant('action')}: ${suivi.action ?? ''}
      ${this._translate.instant('employe')}: ${suivi.nomemploye ?? ''}
      ${this._translate.instant('client')}: ${suivi.nomclient ?? ''}
      ${this._translate.instant('contact')}:\
 ${suivi.contactprenom ?? ''} ${suivi.contactnom ?? ''} ${suivi.clientcontact ?? ''}
      ${this._translate.instant('remarques')}: ${suivi.notes ?? ''}
    `;
    }
}
