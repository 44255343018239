import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ClipboardService} from '@app/sam-base/core';

@Component({
    selector: 'iw-tab-sasx-xml',
    templateUrl: './tab-sasx-xml.component.html',
    styleUrls: ['./tab-sasx-xml.component.scss']
})
export class TabSasxXmlComponent extends BasePartialFormComponent<string> implements OnInit, OnDestroy {

    constructor(private readonly _clipboardService: ClipboardService) {
        super();
    }

    private _xmlContent = '';

    public get xmlContent() {
        return this._xmlContent;
    }

    @Input()
    public set xmlContent(content: string) {
        this._xmlContent = content;
    }

    public ngOnInit() {
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
    }

    public addToClipboard(xmlContent: string) {
        this._clipboardService.addToClipboard(xmlContent);
    }
}
