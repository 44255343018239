import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base/base-side-form.component';
import {FormEditMode, IwStoreService, ModalService} from '@app/sam-base/core';
import {SetLoading} from '@app/sam-base/core/store/actions/global-form.actions';
import {Saems} from '@app/sam-base/models/salary/saems';
import {CommunicationType} from '@core/mail/mail.models';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import {
    salaryComRoles, salaryPrintRoles, salaryRWtRoles
} from '@sam-base/core/auth/access-rules/facture-roles';
import {SagenService} from '@sam-base/core/services/sagen.service';
import * as actions from '@sam-base/core/store/actions/global-form.actions';
import {Sagen} from '@sam-base/models/salary/sagen';
import {Subject, takeUntil} from 'rxjs';
import {SalaryService} from 'src/app/modules/sam-main/salary/services/salary-service';

@Component({
    templateUrl: './fiche-salaire-side-menu.component.html'
})
export class FicheSalaireSideMenuComponent extends BaseSideFormComponent<Saems> implements OnInit, AfterViewInit, OnDestroy {

    public saems?: Saems;
    public sagen?: Sagen;
    public periodIsOpen: boolean = false;
    public disabled = false;
    private subscriptions = new Subject();
    private editMode?: FormEditMode;

    constructor(private readonly _store: IwStoreService, private readonly _calculateService: SalaryService,
                private readonly _sagenService: SagenService, private _modalService: ModalService) {
        super();
    }

    public SALAIRE_COMMUNICATION_ROLES: string[] = salaryComRoles();
    public SALAIRE_PRINT_ROLES: string[] = salaryPrintRoles();
    public SALAIRE_RW_ROLES: string[] = salaryRWtRoles();

    public get canCalculate(): boolean {
        return this.editMode === 'read' && this.saems?.published === false && this.periodIsOpen;
    }

    public get canDownload(): boolean {
        return this.saems?.calculated === true;
    }

    public get canPublish(): boolean {
        return this.saems?.paid === true && this.saems?.published === false
    }

    public get canSendByMail(): boolean {
        return this.saems?.published === true
    }

    public ngOnInit(): void {
        this._store.globalForm(this.uuid)
            .mode
            .pipe(takeUntil(this.subscriptions))
            .subscribe((mode) => {
                this.disabled = mode !== 'edit';
                this.editMode = mode;
            });
    }

    public checkPeriodStatus(): void {
        const salId = this.saems?.salId;
        if (salId) {
            this._sagenService.getSagenBySalId(salId).subscribe(sagen => {
                this.sagen = sagen;
                this.periodIsOpen = this.sagen?.status === 1;
            });
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public ngAfterViewInit(): void {
        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data: any) => {
                this.saems = data;
                this.checkPeriodStatus();
            });
    }

    public calculate(): void {
        if (this.saems?.id) {
            this._store.dispatch(new SetLoading(this.uuid, true));
            this._calculateService.calculate(this.saems.id).subscribe(
                {
                    next: value => {
                        this._store.dispatch(new SetLoading(this.uuid, false));
                        this._store.dispatch(new actions.LoadEntity(this.uuid));
                    },
                    error: error => {
                        this._store.dispatch(new SetLoading(this.uuid, false));
                        console.error(error);
                    }
                }
            );
        }
    }

    public download() {
        if (this.saems?.id) {
            this._store.dispatch(new SetLoading(this.uuid, true));
            this._calculateService.download([this.saems.id]).subscribe({
                next: value => {
                    this._store.dispatch(new SetLoading(this.uuid, false));
                },
                error: error => {
                    this._store.dispatch(new SetLoading(this.uuid, false));
                    console.error(error);
                }
            });
        }

    }

    public publish() {
        if (this.saems?.id) {
            this._store.dispatch(new SetLoading(this.uuid, true));
            this._calculateService.publish(this.saems.id).subscribe({
                next: value => {
                    this._store.dispatch(new SetLoading(this.uuid, false));
                    this._store.dispatch(new actions.LoadEntity(this.uuid));
                },
                error: error => {
                    this._store.dispatch(new SetLoading(this.uuid, false));
                    console.error(error);
                }
            });
        }
    }

    public sendByMail() {
        const id = this.saems?.id || '';
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.SAEMS_BS,
            entityIds: [id]
        });

        /*
        if (this.saems?.id) {
            this._store.dispatch(new actions.SetLoading(this.uuid, true));
            this._calculateService.sendByMail(this.saems!.id!).subscribe({
                next: value => {
                    this._store.dispatch(new actions.SetLoading(this.uuid, false));
                    this._store.dispatch(new actions.LoadEntity(this.uuid));
                },
                error: error => {
                    this._store.dispatch(new actions.SetLoading(this.uuid, false));
                    console.error(error);
                }
            });
        }*/
    }
}
