import {Component, OnInit} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {DialogStateService, IwEventHubService, IwStoreService, TabFormControl} from '@app/sam-base/core';
import {SagenService} from '@app/sam-base/core/services/sagen.service';

import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ComboboxItem, FormKeys} from '@app/sam-base/models';
import {Sagen} from '@app/sam-base/models/salary/sagen';
import {Sapar} from '@app/sam-base/models/salary/sapar';
import {ParametersService} from '@core/services/parameters.service';
import {SalaryPeriodeService} from '@modules/sam-main/salary/services/salary-periode.service';
import {TranslateService} from '@ngx-translate/core';
import {salaireParamGenerauxAdminRoles} from '@sam-base/core/auth/access-rules/config-menu';
import * as ConfigRoles from '@sam-base/core/auth/access-rules/config-menu';
import {accountingRoles} from '@sam-base/core/auth/access-rules/facture-roles';
import {LayoutSalaryNavigationEvent, LayoutSubNavigationEvent} from '@sam-base/layout';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {periodTabsFormControls} from './periode-tabs-formcontrols';

@Component({
    selector: 'iw-sagen-periode',
    templateUrl: './periode.component.html'
})
export class PeriodeComponent extends BaseTabFormComponent<Sagen> implements OnInit {
    public sapar?: Sapar;
    public sagen?: any;
    public optionPeriode: ComboboxItem<string>[] = [];
    private periodes: Sagen[] = [];

    public SALARY_ACCOUNTING_ROLES: SamUserRole[] = ConfigRoles.salaireParamGenerauxAdminRoles();

    constructor(store: IwStoreService, private _parametersService: ParametersService,
                private _sagenService: SagenService, private _translate: TranslateService,
                private readonly _salaryPeriodeService: SalaryPeriodeService,
                private readonly _eventHub: IwEventHubService<string>,
                private readonly _dialogStateService: DialogStateService) {
        super(store);
    }

    public get tabData(): Sagen | undefined {
        return this.getFormData();
    }

    public set tabData(v: Sagen | undefined) {
        this.mergeEntityChanges(v);
    }

    public get periodeStatusLibelle(): string {
        return this.getPeriodeStatusLibelle();
    }

    public getPeriodeStatusLibelle(): string {
        switch (this.getFormValue('status') || '') {
            case 1:
                return this._translate.instant('ouverte');
            case 2:
                return this._translate.instant('fermee');
            case 3:
                return this._translate.instant('comptabilisee');
            default:
                return '?';
        }
    }

    public ngOnInit() {
        this._parametersService.getCurrentSapar()
            .subscribe((sapar) => {
                this.sapar = sapar;
                if (sapar.annee) {
                    this.getAllValidSalId(sapar.annee);
                }
            });
        this.subscribeValueChange('salId', (value) => {
            this.sagen = this.periodes.find((periode) => periode.salId === value);
            this._sagenService.setCurrentSalId(value as string);
            this.fillFormData(this.sagen);
            console.log(this.sagen.status);

        });

        this._sagenService.status$.subscribe(status => {
            // Update sagenstatus as we do not reload
            this.sagen.status = status;
            this.updateStatusLibelle(status);
        });
    }

    public updateStatusLibelle(status: number) {
        this.setFormValue('status', status);
        // Trigger Angular change detection for the status label
        this.sagen = {
            ...this.sagen,
            status
        };
    }

    public getTabsValues(): TabFormControl<Sagen>[] {
        return periodTabsFormControls;
    }

    public getAllValidSalId(annee: string) {
        this._sagenService.getSagenDataArray(annee)
            .subscribe((periodes) => {
                this.periodes = periodes;
                this.optionPeriode = this._salaryPeriodeService.getPeriodesForYear(annee);
            });
        this._sagenService.getSelectedSagen().subscribe(sagen => {
            this.sagen = sagen;
            this.fillFormData(this.sagen);
        });
    }

    public close(): void {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public openFicheSal() {
        this._eventHub.emit(LayoutSalaryNavigationEvent.FICHE_SALAIRE, {
            filters: {'salId': this.sagen?.salId}
        });
        this._dialogStateService.removeFocus();
    }

    public openPaiement() {
        this._eventHub.emit(LayoutSalaryNavigationEvent.PAIEMENT, {
            filters: {'salId': this.sagen?.salId}
        });
        this._dialogStateService.removeFocus();
    }

    public openJournaux() {
        this._eventHub.emit(LayoutSubNavigationEvent.JOURNAUX, {salId: this.sagen?.salId});
        this._dialogStateService.removeFocus();
    }

    protected getValidationType() {
        return Sagen;
    }

    protected getFormControlNames(): FormKeys<Sagen> {
        return this.getTabsFormControls(periodTabsFormControls);
    }
}
