import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('gerplview')
export class GerplView {
    @IwRestEntityId()
    public rpsId?: string;

    public facId?: string;
    public cliId?: string;
    @IwColumn({index: 2})
    public cliNom?: string;
    @IwColumn({index: 3})
    public ctype?: string;
    @IwColumn({index: 1})
    public facNo?: string;
    @IwColumn({
        index: 4,
        type: 'date'
    })
    public dateFac?: string;
    @IwColumn({
        index: 5,
        type: 'date'
    })
    public dateEch?: string;
    @IwColumn({
        index: 6,
        type: 'number'
    })
    public nbjretard?: number;
    @IwColumn({
        index: 7,
        type: 'mnt'
    })
    public montant?: number;
    @IwColumn({
        index: 8,
        type: 'mnt'
    })
    public solde?: number;
    public norappel?: number;
    public status?: number;
    public dateRpl?: string;
    public datePrint?: string;
    public dateMail?: string;

}
