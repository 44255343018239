import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {EntityNavigationService} from '@app/sam-base/core';
import {RestApiService, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {CommandeService} from '@app/sam-base/core/services/commande.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {Ppcdf, Ppmis, Ppsuivi} from '@app/sam-base/models/placement';
import {ProfileService} from '@shared/profile/profile.service';
import {Actions, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {commandRoles} from '@sam-base/core/auth/access-rules/commande-form';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    templateUrl: './cdefixe-side-menu.component.html'
})
export class CdefixeSideMenuComponent extends BaseSideFormComponent<Ppcdf> implements OnInit, OnDestroy {
    public userId?: string;
    public commandeRoles: string[] = [];

    private ppcde: Ppcdf = new Ppcdf();

    private subscription = new Subject();

    constructor(private readonly _store: IwStoreService, private readonly _modalService: ModalService, private _translate: TranslateService, private readonly _restService: RestApiService, private readonly _cdeService: CommandeService, private _entityNavService: EntityNavigationService, private _profileService: ProfileService, private _actions: Actions) {
        super();
    }

    public ngOnInit() {
        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscription))
            .subscribe((data: any) => {
                this.ppcde = data;
                this.commandeRoles = commandRoles();

            });
        this._profileService.loadProfile()
            .subscribe(userProfile => this.userId = userProfile.userId);
    }

    public ngOnDestroy() {
        this.subscription.next(undefined);
        this.subscription.complete();
    }

    public changeCdeStatus() {
        this.showModal(this.ppcde);
    }

    // eslint-disable-next-line complexity
    public async showModal(cde: Ppcdf | undefined) {
        if (!cde) {
            return;
        }
        const options: ModalMessageOptions = {
            message: [`${cde.cdfstatus === 2 ? this._translate.instant('activate_commande') : this._translate.instant('desactivate_commande')} `],
            showCancel: true,
            title: this._translate.instant('ppcdetempo_form_title')
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            if (cde.cdfstatus === 2) {
                let miscnt: Ppmis[] = [];
                let suicnt: Ppsuivi[] = [];
                if (this.ppcde.cdfId) {
                    const query: RestQueryParam<Ppsuivi, string> = {
                        prop: 'cdeId', operation: RestQueryOperation.Equals, value: this.ppcde.cdfId
                    };
                    this._restService.getEntityQuery(Ppsuivi, query)
                        .scroll()
                        .subscribe((el) => {
                            suicnt = el;
                        });
                    this._restService.getEntityQuery(Ppmis, query)
                        .scroll()
                        .subscribe((el) => {
                            miscnt = el;
                        });
                }
                cde.cdfstatus = (suicnt.length > 0 && miscnt.length === 0) ? 0 : 1;
                cde.dateModif = new Date().toISOString();
                cde.userId = this.userId;
                this.saveCdfStatus(cde);
            } else {
                cde.cdfstatus = 2;
                cde.dateModif = new Date().toISOString();
                cde.userId = this.userId;
                this.saveCdfStatus(cde);
            }
        } catch (error) {
        }
    }

    public async copyCde(): Promise<void> {
        const options: ModalMessageOptions = {
            message: [this._translate.instant('copierCommande')],
            showCancel: false,
            title: this._translate.instant('commande')
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this._cdeService.copyCommandeCdf(this.ppcde.cdfId || '')
                .subscribe((newCde: Ppcdf) => {
                    this._entityNavService
                        .navigateToEntityForm(Ppcdf, newCde.cdfId || '');
                    this._store.dispatch(new actions.DestroyForm(this.uuid));
                });
        } catch (_) {
        }
    }

    public saveCdfStatus(cde: Ppcdf) {
        const subscription = this._actions.pipe(ofType(actions.GLOBAL_FORM_ENTITY_SAVE_SUCCESS))
            .subscribe(e => {

                const uuid = e['uuid'] as string || '';

                if (uuid !== this.uuid) {
                    return;
                }

                this._store.dispatch(new actions.LoadEntity(this.uuid));
                subscription.unsubscribe();
            });

        this._store.dispatch(new actions.UpdateEntity<Ppcdf>(this.uuid, cde, true));
        this._store.dispatch(new actions.SaveEntity(this.uuid));
    }

}
