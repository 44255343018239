<form [formGroup]="formGroup"
      class="row">

    <div class="row col-xs-6 separator-right no-padding">
        <iw-textfield-contact #txtAttn
                              [cliId]="cliId"
                              [isDisabled]="!cliId || isReadonly"
                              [labelAlign]="'top'"
                              [label]="'contratALattentionDe' | translate"
                              class="iw-input col-xs-12"
                              formControlName="attn"></iw-textfield-contact>

        <iw-cliadr-dropdown #cboCliadr_id
                            (valueChange)="setClientAddress()"
                            [isDisabled]="isReadonly"
                            [labelAlign]="'top'"
                            [label]="'adresse' | translate"
                            [resultFilters]="resultFilters"
                            class="iw-input col-xs-12"
                            formControlName="cliadrId"></iw-cliadr-dropdown>

        <iw-textarea #edtAdresse
                     [height]="100"
                     [isDisabled]="true"
                     [value]="fullAddress"
                     class="iw-textarea col-xs-12"
                     style="padding: 0 7px"></iw-textarea>

        <div class="row col-xs-12 separator-top"></div>

        <iw-checkbox #chkCcreceived
                     [isDisabled]="isReadonly"
                     [label]="'contratLocRetourne' | translate"
                     class="iw-input col-xs-6"
                     formControlName="ccreceived"></iw-checkbox>


        <iw-checkbox #chkCmreceived
                     [isDisabled]="isReadonly"
                     [label]="'contratMisRetourne' | translate"
                     class="iw-input col-xs-6"
                     formControlName="cmreceived"></iw-checkbox>

        <div class="row col-xs-12">
            <iw-template-dropdown #cboContLocation
                                  [isDisabled]="isReadonly"
                                  [labelAlign]="'top'"
                                  [label]="'contratLocation' | translate"
                                  [report]="'CC'"
                                  [size]="'large'"
                                  class="iw-combobox col-xs-10"
                                  formControlName="ccfrx"></iw-template-dropdown>
            <iw-button (press)="setFormValue('ccfrx', undefined)"
                       [isDisabled]="isReadonly"
                       [text]="'raz' | translate"
                       class="iw-button col-xs-2 flex-end"></iw-button>

            <iw-template-dropdown #cboContMission
                                  [isDisabled]="isReadonly"
                                  [labelAlign]="'top'"
                                  [label]="'contratDeMission' | translate"
                                  [report]="'CM'"
                                  [size]="'large'"
                                  class="iw-combobox col-xs-10"
                                  formControlName="cmfrx"></iw-template-dropdown>
            <iw-button (press)="setFormValue('cmfrx', undefined)"
                       [isDisabled]="isReadonly"
                       [text]="'raz' | translate"
                       class="iw-button col-xs-2 flex-end"></iw-button>

        </div>
    </div>

    <div class="row col-xs-6 no-padding">

        <iw-enum-dropdown #cboModeFactu
                          [inputEnum]="modefactu"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'facturation' | translate"
                          class="iw-input col-xs-12"
                          formControlName="modefactu"></iw-enum-dropdown>


        <iw-textfield-chantiers #txtChantier
                                [cliId]="cliId"
                                [isDisabled]="isReadonly"
                                [labelAlign]="'top'"
                                [label]="'codeDeFacturationChantierParDefaut' | translate"
                                class="iw-input col-xs-12"
                                formControlName="chantier"></iw-textfield-chantiers>

        <iw-checkbox #chkForceCha
                     [isDisabled]="isReadonly"
                     [label]="'obligatoire' | translate"
                     class="iw-input col-xs-4"
                     formControlName="forcecha"></iw-checkbox>


        <iw-checkbox #chkFreezCha
                     [isDisabled]="isReadonly"
                     [label]="'nonModifiable' | translate"
                     class="iw-input col-xs-4"
                     formControlName="freezcha"></iw-checkbox>

        <!-- manycha check -->
        <iw-checkbox #chkManyCha
                     [isDisabled]="isReadonly"
                     [label]="'plusieurs' | translate"
                     class="iw-input col-xs-4"
                     formControlName="manycha"></iw-checkbox>

        <iw-multiautocomplete-chantiers #ctnchamultiselect
                                        (selectedValueChanged)="setChantiersValues($event)"
                                        [doubleClickable]="true"
                                        [isDisabled]="isReadonly || !isPlusieursChecked()"
                                        [labelAlign]="'top'"
                                        [label]="'tousLesCfChantiers' | translate"
                                        [multiSelectInputs]="chantierMultiSelectInputs"
                                        [multiple]="true"
                                        class="iw-input col-xs-12"
                                        formControlName="chaids"></iw-multiautocomplete-chantiers>

        <iw-textarea #edtFaclintxt
                     [height]="20"
                     [isDisabled]="isReadonly"
                     [labelAlign]="'top'"
                     [label]="'textsupFactureParDefaut' | translate"
                     class="iw-textarea col-xs-12"
                     formControlName="faclintxt"
                     style="padding: 0 7px"></iw-textarea>

        <iw-textfield #txtChantier2
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'ccClient2' | translate"
                      class="iw-input col-xs-12"
                      formControlName="chantier2"></iw-textfield>

        <iw-textarea #edtRefclient2
                     [height]="45"
                     [isDisabled]="isReadonly"
                     [labelAlign]="'top'"
                     [label]="'referenceClientFacturationMensuelle' | translate"
                     class="iw-textarea col-xs-12"
                     formControlName="refclient2"
                     style="padding: 0 7px"></iw-textarea>

    </div>

    <div class="row col-xs-12 margin-top-10 separator-top">

        <iw-conseiller-dropdown #cboCon_id_2
                                [isDisabled]="isReadonly || hasRapport"
                                [labelAlign]="'top'"
                                [label]="'2EmeConseiller' | translate"
                                class="iw-combobox col-xs-3"
                                formControlName="conId2"></iw-conseiller-dropdown>

        <iw-textfield #txtConid2_pc
                      [decimals]="0"
                      [isDisabled]="isReadonly || hasRapport"
                      [label]="'percentage_symbol' | translate"
                      [pattern]="'[0-9]*'"
                      [stepValue]="1"
                      class="iw-input col-xs-2"
                      formControlName="conid2Pc"
                      type="number"></iw-textfield>


        <iw-textfield-lookups #txtUserTag
                              [isDisabled]="isReadonly"
                              [labelAlign]="'top'"
                              [label]="'tag' | translate"
                              [lookupName]="'MISTAG'"
                              class="iw-input col-xs-4"
                              formControlName="usertag"></iw-textfield-lookups>

    </div>

</form>
