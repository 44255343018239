<form [formGroup]="formGroup" class="row">
    <div class="col-xs-5 row">

        <iw-textfield #txtLast_mis_id [isDisabled]="true" [labelAlign]="'top'" [label]="'missionNo' | translate"
                      [value]="mis?.misId" class="iw-input col-xs-8"></iw-textfield>
        <iw-button #btnMis (press)="openMission()" [text]="'mission' | translate"
                   class="iw-button col-xs-4 no-label"></iw-button>
        <iw-textfield #Standardfield1 [isDisabled]="true" [labelAlign]="'top'" [label]="'debut' | translate"
                      [value]="mis?.firstday" class="iw-input col-xs-6"></iw-textfield>
        <iw-textfield #Standardfield2 [isDisabled]="true" [labelAlign]="'top'" [label]="'client' | translate"
                      [value]="cliName" class="iw-input col-xs-12"></iw-textfield>
        <iw-textfield #Standardfield3 [isDisabled]="true" [labelAlign]="'top'"
                      [label]="'qualificationGenreDeTravail' | translate" [value]="mis?.qualif"
                      class="iw-input col-xs-12"></iw-textfield>
        <iw-textfield #Standardfield3 [isDisabled]="true" [labelAlign]="'top'" [value]="mis?.genretrav"
                      class="iw-input col-xs-12"></iw-textfield>

    </div>
    <div class="col-xs-7 row" style="display:flex;">

        <iw-textfield [decimals]="2" [isDisabled]="true" [label]="'salaire_base' | translate" [value]="mis?.salBase"
                      class="col-xs-6" labelAlign="left" type="number"></iw-textfield>
        <div class="col-xs-6"></div>
        <iw-checkbox [isDisabled]="true" [label]="'jf' | translate" [value]="mis?.ljfh" class="col-xs-3"></iw-checkbox>
        <iw-textfield [decimals]="2" [isDisabled]="true" [value]="mis?.mntJf" class="col-xs-3"
                      style="padding-left: 14px;" type="number"></iw-textfield>
        <iw-textfield [decimals]="2" [isDisabled]="true" [value]="mis?.txJf" class="col-xs-3" suffix="%"
                      type="number"></iw-textfield>
        <iw-checkbox [isDisabled]="true" [label]="'prov' | translate" [value]="mis?.lprovJf"
                     class="col-xs-3"></iw-checkbox>
        <iw-checkbox [isDisabled]="true" [label]="'13eme' | translate" [value]="mis?.l13"
                     class="col-xs-3"></iw-checkbox>
        <iw-textfield [decimals]="2" [isDisabled]="true" [value]="mis?.mnt13" class="col-xs-3"
                      style="padding-left: 14px;" type="number"></iw-textfield>
        <iw-textfield [decimals]="2" [isDisabled]="true" [value]="mis?.tx13" class="col-xs-3" suffix="%"
                      type="number"></iw-textfield>
        <iw-checkbox [isDisabled]="true" [label]="'prov' | translate" [value]="mis?.lprov13"
                     class="col-xs-3"></iw-checkbox>

        <iw-checkbox [isDisabled]="true" [label]="'vacances' | translate" [value]="mis?.lvac"
                     class="col-xs-3"></iw-checkbox>
        <iw-textfield [decimals]="2" [isDisabled]="true" [value]="mis?.mntVac" class="col-xs-3"
                      style="padding-left: 14px;" type="number"></iw-textfield>
        <iw-textfield [decimals]="2" [isDisabled]="true" [value]="mis?.txVac" class="col-xs-3" suffix="%"
                      type="number"></iw-textfield>

        <iw-textfield [decimals]="2" [isDisabled]="true" [label]="'salaire_global' | translate" [value]="mis?.salGlobal"
                      class="col-xs-6" labelAlign="left" type="number"></iw-textfield>
        <div class="space-block space-block-6"></div>
        <iw-textfield [decimals]="2" [isDisabled]="true" [label]="'frais_horaire_1' | translate"
                      [value]="mis?.mntFrais1" class="col-xs-6" labelAlign="left" type="number"></iw-textfield>
        <div class="col-xs-6"></div>
        <iw-textfield [decimals]="2" [isDisabled]="true" [label]="'frais_horaire_2' | translate"
                      [value]="mis?.mntFrais2" class="col-xs-6" labelAlign="left" type="number"></iw-textfield>
        <div class="space-block space-block-6"></div>
        <iw-textfield [decimals]="2" [isDisabled]="true" [label]="'salaire_total' | translate" [value]="mis?.salTotal"
                      class="col-xs-6" labelAlign="left" type="number"></iw-textfield>

    </div>
    <iw-textfield #Standardfield3 [isDisabled]="true" [labelAlign]="'top'" [label]="'cct' | translate" [value]="cct"
                  class="iw-input col-xs-8"></iw-textfield>

</form>
  