import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base/base-partial-form.component';
import {ClipboardService} from '@app/sam-base/core/clipboard/clipboard.service';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {Npa} from '@app/sam-base/models/common/npa';
import {Ppagence} from '@app/sam-base/models/placement';
import {PpconService} from '@sam-base/core';
import {ToastService} from '@sam-base/core/toast';
import {
    LookupsSelectionComponent
} from '@shared/widgets/modal-components/lookups-selection/lookups-selection.component';
import {lastValueFrom} from 'rxjs';

import {CountriesAlpha2List, Kchkrating} from './tab-general-enums';
import {TabGeneral} from './tab-general.model';

@Component({
    selector: 'iw-tab-general',
    templateUrl: './tab-general.component.html'
})
export class TabGeneralComponent extends BasePartialFormComponent<TabGeneral> implements OnInit {

    @Input() public isReadonly = true;

    @Input() public entityId?: string;

    @Input() public isNew = false;

    public countriesAlpha2List = CountriesAlpha2List;
    public kchkrating = Kchkrating;

    private readonly _npaRestClient: RestEntityClient<Npa>;

    constructor(private readonly _clipboardService: ClipboardService, private readonly _modalService: ModalService,
                private readonly _toastService: ToastService, restService: RestApiService,
                private _ppconService: PpconService) {
        super();
        this._npaRestClient = restService.getEntityClient(Npa);
    }

    public ngOnInit() {
        if (this.isNew) {
            this._ppconService.fillMainConseiller(this.getFormControl('conId'));
        }
    }

    public async openRegionsSelection() {
        try {
            const regValue: string = this.getFormValue('regions') || '';
            const regList: string[] = regValue.split(';')
                .filter((e) => e !== '');
            const res = await this._modalService.showModal(LookupsSelectionComponent, {
                list: regList,
                lkupname: 'REGION'
            });
            this.setFormValue('regions', res);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    }

    public addToClipboard(value: string) {
        let copiedText = '';
        if (value === 'email') {
            const email: string | undefined = this.getFormValue('email');
            if (email) {
                copiedText = email;
            } else {
                return;
            }
        } else if (value === 'adresse') {
            copiedText = this.buildAddress();
            if (copiedText === '') {
                return;
            }
        }
        this._clipboardService.addToClipboard(copiedText);
    }

    public async getLieuByNpa() {
        try {
            const npa: string | undefined = this.getFormValue<string>('npa') || '';
            if (this.canGenerateNpa(npa)) {
                const lieu: Npa = await lastValueFrom(this._npaRestClient.getById(npa));
                this.setFormValue('lieu', lieu.lieu);
            }
        } catch (error) {
            this._toastService.error('npa_error');
        }
    }

    public agenceChange(event: Ppagence | undefined) {
        if (event) {
            if (!this.isReadonly) {
                this.setFormValue('ageId', event.ageId);
            }
        }
    }

    private canGenerateNpa(npa: string) {
        return npa.length === 4 && !this.getFormValue('lieu') && !this.isReadonly;
    }

    // eslint-disable-next-line complexity
    private buildAddress() {
        let address = '';

        /* eslint-disable max-len */
        if (this.getFormValue('prefixe')) {
            address += this.getFormValue('prefixe') + '\n';
        }
        if (this.getFormValue('nom')) {
            address += this.getFormValue('nom') + '\n';
        }
        if (this.getFormValue('adresse1')) {
            address += this.getFormValue('adresse1') + '\n';
        }
        if (this.getFormValue('adresse2')) {
            address += this.getFormValue('adresse2') + '\n';
        }
        if (this.getFormValue('adresse3')) {
            address += this.getFormValue('adresse3') + '\n';
        }
        if (this.getFormValue('npa')) {
            address += this.getFormValue('npa') + '\n';
        }
        if (this.getFormValue('lieu')) {
            address += this.getFormValue('lieu');
        }

        return address;
    }
}
