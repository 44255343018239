<div [formGroup]="formGroup" class="row">
    <div class="row col-xs-11">
        <iw-rest-grid #geclisuiGrid (selected)="selectedItem($event)" [autoFormClick]="false"
                      [hasGridColumnMenu]="false" [hasSortIcon]="true" [queryStatements]="query" [type]="type"
                      class="col-xs-11 padding-top-15" style="height: 300px"></iw-rest-grid>
    </div>
    <div class="col-xs-1 grid-buttons padding-top-15">
        <iw-button (press)="add()" [tooltip]="'sidemenu_new' | translate" iconClass="fas fa-file"
                   style="padding-bottom: 6px"></iw-button>

        <iw-button (press)="edit()" [isDisabled]="disableEditDelete" [tooltip]="'sidemenu_edit' | translate"
                   iconClass="fas fa-pencil-alt"></iw-button>

        <iw-button (press)="delete()" [isDisabled]="disableEditDelete" [tooltip]="'sidemenu_delete' | translate"
                   iconClass="fas fa-trash"></iw-button>
    </div>

</div>
