import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('gepaiele')
export class Gepaiele {
    @IwRestEntityId() public payeleId?: string;
    public payCliId?: string;
    public payId?: string;
    @IwColumn({
        name: 'client',
        index: 0
    }) public cliId?: string;
    public facId?: string;
    @IwColumn({
        name: 'facNo',
        index: 1
    }) public facNo?: string;
    @IwColumn({
        name: 'lettrage',
        index: 4
    }) public lettrage?: string;
    @IwColumn({
        name: 'origine',
        index: 5
    }) public origine?: string;
    public payCliId2?: string;
    @IwColumn({
        name: 'montant',
        index: 2,
        align: 'right',
        type: 'mnt'
    }) public montant?: number;
    public mpaId?: string;
    @IwColumn({
        name: 'compte',
        index: 3
    }) public compte?: string;
    public userId?: string;
}
