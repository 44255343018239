<div [formGroup]="formGroup"
     class="row">
    <div class="row">
        <div class="row space-form col-xs-12">
            <iw-rest-grid #gepaimodeGrid
                          (selected)="getSelectedRow($event)"
                          [autoFormClick]="false"
                          [hasGridColumnMenu]="false"
                          [hasSortIcon]="true"
                          [queryStatements]="query"
                          [type]="type"
                          class="col-xs-10 padding-top-15"
                          style="height: 300px"></iw-rest-grid>
            <div class="space-form col-xs-2">
                <iw-button (press)="newEntry()"
                           [isDisabled]="isEditing || isNew"
                           [text]="'Nouveau' | translate"
                           class="iw-button padding-top-10"></iw-button>

                <iw-button #btnModif
                           (press)="modifyGepaimode()"
                           *ngIf="!isEditing"
                           [isDisabled]="!isSelected"
                           [text]="'sidemenu_edit' | translate"
                           class="iw-button padding-top-10"></iw-button>
                <iw-button (press)="saveEntry()"
                           *ngIf="isEditing"
                           [isDisabled]="!formGroup.valid"
                           [text]="'enregistrer' | translate"
                           class="iw-button padding-top-10"></iw-button>

                <iw-button (press)="deleteEntry()"
                           *ngIf="!isEditing"
                           [isDisabled]="!isSelected"
                           [text]="'Supprimer'"
                           class="iw-button padding-top-10"></iw-button>

                <iw-button #btnCancel
                           (press)="cancelEditionMode()"
                           *ngIf="isEditing"
                           [text]="'sidemenu_cancel' | translate"
                           class="iw-button btn-block padding-bottom-10"></iw-button>

                <iw-button (press)="closeDialog()"
                           [text]="'fermer' | translate"
                           class="iw-button padding-top-10"></iw-button>


            </div>
        </div>
        <div class="row space-form col-xs-12">
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'code' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-2 padding-top-5"
                          formControlName="mpaId"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'titre' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-6 padding-top-5"
                          formControlName="titre"></iw-textfield>
            <iw-textfield-compte [isDisabled]="!isEditing"
                                 [labelAlign]="'top'"
                                 [label]="'compte' | translate"
                                 [size]="'small'"
                                 class="iw-input col-xs-10 padding-top-5"
                                 formControlName="compte"></iw-textfield-compte>

            <iw-enum-dropdown [inputEnum]="payType"
                              [isDisabled]="!isEditing"
                              [labelAlign]="'top'"
                              [label]="'type' | translate"
                              [size]="'small'"
                              class="iw-input col-xs-6 padding-top-5"
                              formControlName="paytype"></iw-enum-dropdown>
            <iw-checkbox [isDisabled]="!isEditing"
                         [label]="'tva' | translate"
                         class="iw-input col-xs-4 checkbox-padding-top checkbox-padding-left"
                         formControlName="ltva"></iw-checkbox>
            <iw-checkbox [isDisabled]="!isEditing"
                         [labelAlign]="'top'"
                         [label]="'bvr' | translate"
                         class="iw-input col-xs-3 checkbox-padding-top"
                         formControlName="lbvr"></iw-checkbox>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'banque' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-3 padding-top-5"
                          formControlName="bvrnobnk"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'client' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-3 padding-top-5"
                          formControlName="bvrnocli"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'fichier' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-3 padding-top-5"
                          formControlName="bvrfileext"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'refConstruction' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-12 padding-top-5"
                          formControlName="bvrrefdef"></iw-textfield>
            <iw-checkbox [isDisabled]="!isEditing"
                         [labelAlign]="'top'"
                         [label]="'qr' | translate"
                         class="iw-input col-md-2 checkbox-padding-top"
                         formControlName="lqr"></iw-checkbox>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'qrIban' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-10 padding-top-5"
                          formControlName="qriban"></iw-textfield>
        </div>
    </div>


</div>
