import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base/base-partial-form.component';

import {Icc2ijmod, Ijrapsm, Pointmode, Pointtype} from './tab-pointeuse-enums';
import {TabPointeuse} from './tab-pointeuse.model';

@Component({
    selector: 'iw-tab-pointeuse', templateUrl: './tab-pointeuse.component.html'
})
export class TabPointeuseComponent extends BasePartialFormComponent<TabPointeuse> {
    @Input() public isReadonly = true;

    public pointtype = Pointtype;
    public pointMode = Pointmode;
    public ijRapSm = Ijrapsm;
    public icc2ijmod = Icc2ijmod;

    constructor() {
        super();
    }
}
