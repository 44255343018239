import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Gepaimode} from '@app/sam-base/models/invoices/gepaimode';
import {GepaimodeComponent} from '../components/gepaimode/gepaimode.component';

export const GEPAIMODE: FormDescription<Gepaimode> = {
    form: GepaimodeComponent, state: {
        ...buildDefaultState(Gepaimode),
        diagTitle: 'mode_paiement',
        diagShowSideMenu: false,
        diagShowStatus: true,
        width: 700
    }
};
