import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppcct} from '@app/sam-base/models/placement';

import {CctConfigComponent} from '../components/cct-config/cct-config.component';

export const PPCCT_DEFAULT_FORM: FormDescription<Ppcct> = {
    form: CctConfigComponent, state: {
        ...buildDefaultState(Ppcct),
        diagTitle: 'cct',
        diagShowSideMenu: false,
        diagShowStatus: false,
        patch: false,
        width: 1000,
        isNew: false,
        editMode: 'read',
        diagModal: true
    }
};
