import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppcct} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-pgm', templateUrl: './tab-pgm.component.html'
})
export class TabPgmComponent extends BasePartialFormComponent<Ppcct> implements OnInit {

    constructor() {
        super();
    }

    public ngOnInit() {

    }

    public setUpperCase(formName: keyof Ppcct, value: string) {
        this.setFormValue(formName, value.toUpperCase());
    }
}
