<form [formGroup]="formGroup"
      class="row">
    <div class="row col-xs-12"
         style="margin-block-end: auto">
        <iw-textarea #txtnotes
                     [height]="120"
                     [isDisabled]="isReadonly"
                     [label]="'notes' | translate"
                     class="iw-textarea col-xs-12 padding-top-15"
                     formControlName="notes"></iw-textarea>
        <div class="col-xs-1"></div>

        <div *ngIf="sapar?.libuserdat"
             style="display: flex; width: 100%">
            <div class="iw-label col-xs-2 padding-top-15 flex-end">
                {{ sapar?.libuserdat }}
            </div>
            <iw-textfield #txtuserdate1
                          [isDisabled]="isReadonly"
                          [noLabel]="true"
                          class="iw-textfield col-xs-6 padding-top-15"
                          formControlName="userdate1"></iw-textfield>
            <div class="col-xs-3"></div>
        </div>

        <div *ngIf="sapar?.libuserda2"
             style="display: flex; width: 100%">
            <div class="iw-label col-xs-2 padding-top-15 flex-end">
                {{ sapar?.libuserda2 }}
            </div>
            <iw-textfield #txtuserdate2
                          [isDisabled]="isReadonly"
                          [noLabel]="true"
                          class="iw-textfield col-xs-6 padding-top-15"
                          formControlName="userdate2"></iw-textfield>
            <div class="col-xs-3"></div>
        </div>

        <div *ngIf="sapar?.libuserda3"
             style="display: flex; width: 100%">
            <div class="iw-label col-xs-2 padding-top-15 flex-end">
                {{ sapar?.libuserda3 }}
            </div>
            <iw-textfield #txtuserdate3
                          [isDisabled]="isReadonly"
                          [noLabel]="true"
                          class="iw-textfield col-xs-6 padding-top-15"
                          formControlName="userdate3"></iw-textfield>
            <div class="col-xs-3"></div>
        </div>

        <div *ngIf="sapar?.libusercha"
             style="display: flex; width: 100%">
            <div class="iw-label col-xs-2 padding-top-15 flex-end">
                {{ sapar?.libusercha }}
            </div>
            <iw-textfield #txtuserchamp1
                          [isDisabled]="isReadonly"
                          [noLabel]="true"
                          class="iw-textfield col-xs-6 padding-top-15"
                          formControlName="userchamp1"></iw-textfield>
            <div class="col-xs-3"></div>
        </div>

        <div *ngIf="sapar?.libuserch2"
             style="display: flex; width: 100%">
            <div class="iw-label col-xs-2 padding-top-15 flex-end">
                {{ sapar?.libuserch2 }}
            </div>
            <iw-textfield #txtuserchamp2
                          [isDisabled]="isReadonly"
                          [noLabel]="true"
                          class="iw-textfield col-xs-6 padding-top-15"
                          formControlName="userchamp2"></iw-textfield>
            <div class="col-xs-3"></div>
        </div>

        <div *ngIf="sapar?.libuserch3"
             style="display: flex; width: 100%">
            <div class="iw-label col-xs-2 padding-top-15 flex-end">
                {{ sapar?.libuserch3 }}
            </div>
            <iw-textfield #txtuserchamp3
                          [isDisabled]="isReadonly"
                          [noLabel]="true"
                          class="iw-textfield col-xs-6 padding-top-15"
                          formControlName="userchamp3"></iw-textfield>
            <div class="col-xs-3"></div>
        </div>
    </div>
</form>
