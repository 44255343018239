import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {GepaihdrCalc} from '@app/sam-base/models/invoices/gepaihdrCalc';
import {
    EncaissementCaculateBtnFormComponent
} from '@modules/sam-main/salary/components/encaissement/encaissement-calculatebtn-form/encaissement-calculatebtn-form.component';

export const PAIEMENT_CALCULATEBTN: FormDescription<GepaihdrCalc> = {
    form: EncaissementCaculateBtnFormComponent, state: {
        ...buildDefaultState(GepaihdrCalc),
        diagTitle: 'recalculer_paiement',
        diagShowSideMenu: false,
        diagShowStatus: true,
        width: 400,
        editMode: 'read'
    }
};
