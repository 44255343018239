import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Pppar} from '@app/sam-base/models/placement';

export const parametresGenerauxTabsFormControls: TabFormControl<Pppar>[] = [{
    formControls: ['aptId',
        'coeffMin',
        'ccfrx',
        'modCnt',
        'lieuuc',
        'ppXmldata',
        'rapverhor',
        'dateModif',
        'cmfinfrx',
        'lanstrict',
        'clitarifmd',
        'yearArc',
        'cdedefhor',
        'deftypejob',
        'hpausaimod',
        'shsupcalmd',
        'smioIdI',
        'lshbglob',
        'allosal0',
        'acoacolibs',
        'cmfrx',
        'atttravfrx',
        'lprvhnudef',
        'evafrx',
        'rapprtfrx',
        'mbexclgs',
        'rapnoref',
        'ppempfrx',
        'lprvjfhdef',
        'ijData',
        'misfacmode',
        'hmultisai',
        'adrescli',
        'cliremtpl',
        'lheupause',
        'matchflist',
        'cdedefdur',
        'cmreceived',
        'aptcdeobli',
        'smioimpid',
        'usertext2',
        'usertext6',
        'cdedefcen',
        'faceleord',
        'lprovhnuit',
        'acomntsafe',
        'aptcdfobli',
        'cdetempo',
        'userCreat',
        'agi2Frx',
        'smioimpdat',
        'empremtpl',
        'lprovvac',
        'grtstrict',
        'ccreceived',
        'cdedeflieu',
        'lprv13def',
        'cmavfrx',
        'ppcdffrx',
        'quamode',
        'lcctoblig',
        'agifrx',
        'cdeoblig',
        'smioDtE',
        'boAgeId',
        'userModif',
        'pccharges',
        'lieu',
        'lprov13',
        'ccavfrx',
        'lclitarcct',
        'ppcdefrx',
        'webioData',
        'ppclifrx',
        'indmntsafe',
        'smioIdE',
        'misremtpl',
        'mbexclcpt',
        'userDelet',
        'usertext7',
        'mbfocgcpt',
        'maxlevlan',
        'maxlevinf',
        'lprvvacdef',
        'cdfremtpl',
        'maxlevrat',
        'coefcalcmd',
        'lij',
        'shclear',
        'shautofin',
        'aptoblig',
        'lcct',
        'usertext3',
        'userId',
        'actSalId',
        'suistrict',
        'defpaytype',
        'lsCctId',
        'shautoclos',
        'indmntsafr',
        'missalmode',
        'cmrem1',
        'ppData',
        'acoraplibs',
        'usertext4',
        'usertext9',
        'acoquifrx',
        'smioDtI',
        'usertext1',
        'plffrx',
        'lppnbwim',
        'cderemtpl',
        'lwebio',
        'acosemlibs',
        'usertext8',
        'usertext5',
        'lnoacorap',
        'acoinrap',
        'lnoacoemp',
        'modData',
        'suiprivate',
        'ccrem1',
        'lprovjfh',
        'quastrict']
},
    {
        title: 'general',
        formControls: ['caissecomp',
            'langue',
            'smmode',
            'ageId',
            'agePrefix',
            'entityMultiWindow']
    },
    {
        title: 'orderMissionReport',
        formControls: ['misarcnbj',
            'autocloseMission',
            'forceUseCctTempData']
    },
    {
        title: 'employe',
        formControls: ['nomempuc',
            'prenomempu']
    },
    {
        title: 'client',
        formControls: ['nomcliuc',
            'modefactu',
            'cliadrmode',
            'allotarif0']
    },
    {
        title: 'salary1',
        formControls: ['txVac4',
            'txVac5',
            'l13H',
            'tx13',
            'ljfh',
            'txJf',
            'lfraish1',
            'lfraish2',
            'lheunuit',
            'txheunuit',
            'shbaseglob',
            'shcalcmode']
    },
    {
        title: 'salary2',
        formControls: ['cnacodes',
            'defcodcna',
            'cnacodexpl',
            'pgmcodesh',
            'defcodpgmh',
            'pgmcodesf',
            'defcodpgmf',
            'pgmcodexpl',
            'caisselpp']
    },
    {
        title: 'formats',
        formControls: ['contractmis',
            'avecontractmis',
            'contractloc',
            'avecontractloc',
            'placefix',
            'dossiercan']
    },
    {
        title: 'enterprise_signature',
        formControls: ['signature']
    },
    {
        title: 'rating',
        formControls: ['good',
            'medium',
            'bad']
    }];
