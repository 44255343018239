import { HttpClient } from '@angular/common/http';

import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {IwStoreService, ModalService} from '@app/sam-base/core';
import {ClipboardService} from '@app/sam-base/core/clipboard/clipboard.service';
import {isRestEntity, RestApiService, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import * as actions from '@app/sam-base/core/store/actions/entity.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {RowClickEvent} from '@app/sam-base/models';
import {PlacementSearchNavigation} from '@core/placement-search/placement-search.service';
import {
    EmployeeDeclarationEditComponent
} from '@modules/sam-main/admin/components/swissdec/tab-sasx5dec-employees/employee-declaration-edit/employee-declaration-edit.component';
import {Actions, ofType} from '@ngrx/effects';
import {environment} from '@root/environments/environment';
import {EmployeeDeclaration} from '@sam-base/models/admin/employee-declaration.model';

import {Sasx5dec} from '@sam-base/models/admin/sasx5dec.model';
import {SxStatus} from '@sam-base/models/admin/sx-status.enum';
import {Subject} from 'rxjs';

/**
 * Tab General of Employee - SAM-4268
 */
@Component({
    selector: 'iw-tab-sasx5dec-employees',
    templateUrl: './tab-sasx5dec-employees.component.html'
})
export class TabSasx5decEmployeesComponent extends BasePartialFormComponent<Sasx5dec> implements OnInit, OnDestroy {

    @ViewChild('employeesGrid', {static: true}) public employeesGrid?: IwRestGridComponent<EmployeeDeclaration>;

    @Input() public isNew = false;
    public query: RestQueryParam<EmployeeDeclaration, string>[] = [];
    public type = EmployeeDeclaration;
    private _selectedEmployees: EmployeeDeclaration[] = [];
    private _removeEmployeeProcessing = false;
    private subscriptions = new Subject();

    constructor(private readonly _store: IwStoreService, private readonly _clipboardService: ClipboardService,
                private readonly _restService: RestApiService, private readonly _toastService: ToastService,
                private readonly _toast: ToastService, private readonly _http: HttpClient,
                private readonly _modalService: ModalService,
                private readonly _gridNavigation: PlacementSearchNavigation, private readonly _updates: Actions) {
        super();
    }

    public get removeEmployeeDisabled(): boolean {
        return this._removeEmployeeProcessing || this._selectedEmployees?.length === 0
            // eslint-disable-next-line max-len
            || [SxStatus.INIT,
                SxStatus.INIT_ERROR].indexOf(this.getFormValue('status') || SxStatus.INIT) === -1;
    }

    public get entityId() {
        const id = this.getFormValue('id');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public ngOnInit() {
        this.initEmployeeQueryWithDeclarationId(this.getFormValue('id') || '');
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public removeEmployee() {
        this._removeEmployeeProcessing = true;
        return this._http.put<EmployeeDeclaration[]>(environment.backendURL + `swissdec/sasx5dec/${this.entityId}/remove-employees`, this._selectedEmployees.map(emp => emp.id))
            .subscribe(sasx5dec => {
                this._removeEmployeeProcessing = false;
                this._selectedEmployees = [];
                this.refreshGrid();
                this._toast.success('declaration_update_success');
            }, (err) => {
                this._toast.error('declaration_update_error');
                this._removeEmployeeProcessing = false;
            });

    }

    public async editEmployee(doc?: RowClickEvent<EmployeeDeclaration>) {
        const employeeDeclarationId = doc?.row?.id;
        if (!employeeDeclarationId || this.isReadonly) {
            return;
        }
        try {
            const editedEmp: EmployeeDeclaration = await this._modalService.showModal(EmployeeDeclarationEditComponent, {
                employeeDeclarationId,
                readonly: this.isReadonly,
                year: (this.getFormValue<string>('annee'))
            });
            this.saveEmployeeDeclaration(employeeDeclarationId, editedEmp);
        } catch (error) {
        }
    }

    public setSelected($event: EmployeeDeclaration[]) {
        this._selectedEmployees = $event;
    }

    private initEmployeeQueryWithDeclarationId(declarationId: string) {
        this.query = [{
            prop: 'declarationId',
            operation: RestQueryOperation.Equals,
            value: declarationId
        },
            {
                prop: 'isSelected',
                operation: RestQueryOperation.Equals,
                value: 'true'
            }];
    }

    private saveEmployeeDeclaration(empId: string, employee: EmployeeDeclaration) {
        this.subscribeToSaveEffect(empId);
        this._store.dispatch(new actions.EntityUpdate(employee));
    }

    private subscribeToSaveEffect(selectedId: string) {
        const sub = this._updates.pipe(ofType(actions.ENTITY_UPDATE_SUCCESS))
            .subscribe((action: actions.EntityUpdateSuccess<EmployeeDeclaration>) => {
                if (this.isEmployeeDeclarationUpdated(selectedId, action.entity)) {
                    this.employeesGrid?.refresh();
                }
                sub.unsubscribe();
            });
    }

    private isEmployeeDeclarationUpdated(selectedId: string, employee: EmployeeDeclaration): boolean {
        return isRestEntity(employee) && employee.id === selectedId;
    }

    private refreshGrid(): void {
        if (!this.employeesGrid) {
            return;
        }
        this.employeesGrid.refresh();
    }
}
