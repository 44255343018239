import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ModalRappelsOptions} from '@app/sam-base/components/modal/modal-rappels/modal-rappels.component';
import {ModalRappelsComponent} from '@app/sam-base/components/modal/modal-rappels/modal-rappels.options';
import {ModalService} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {RappelsService} from '@app/sam-base/core/services/rappel.service';
import {Gltvadef} from '@app/sam-base/models/accounting/gltvadef';
import {Gefachdr} from '@app/sam-base/models/invoices/gefachdr';
import {TranslateService} from '@ngx-translate/core';
import {Gepaicond} from '@sam-base/models/invoices/gepaicond';
import {Subject} from 'rxjs';


@Component({
    selector: 'iw-tab-facture-entete',
    templateUrl: './tab-facture-entete.component.html'
})
export class TabFactureEnteteComponent extends BasePartialFormComponent<Gefachdr> implements OnInit {
    public nbJPaiementVisible = false;
    public nbJRetardVisible = false;
    public txtRabaisValue = '';
    public txtTauxEscValue = '';
    public calcSoldeValue = 0;
    public tvaTauxVal = 0;
    public payId?: string;
    public nbJRetard: number = 0;
    public txtRappels = "";
    public hasRappels: boolean = true;
    public nbJPaiement: number = 0;
    private subscription = new Subject();

    constructor(private _rappelsService: RappelsService, private _modalService: ModalService,
                private _translateService: TranslateService) {
        super();
    }

    public get dateFac(): string {
        return this.getFormValue('dateFac') || '';
    }

    public get dateEch(): string {
        return this.getFormValue('dateEch') || '';
    }

    public get datePay(): string {
        return this.getFormValue('datePay') || '';
    }

    public get datePublished(): string | undefined {
        return this.getFormValue('datePublished');
    }

    public ngOnInit() {
        this.refreshValues();
        this.subscribeValueChange('facId', (facId: string | undefined) => {
            this.refreshValues();
        });

        // this.messageService.getMessage()
        //   .pipe(takeUntil(this.subscription)).subscribe(message => {
        //   if (message && 'facture-nc' === message) {
        //     this.refreshValues(this.getFormValue('facId'));
        //   }
        // });
        // this.subscribeValueChange('facId', (id: string | undefined) => {
        //   this.refreshValues(id);
        // });
        // this.subscribeValueChange('mntTtc', (valueMntttc?: number) => {
        //   this.refreshGfrach(valueMntttc);
        // });
        // this.calcSolde();
        //this.subscribeValueChange('paiCondId', paiCondId


    }

    // private refreshGfrach(valueMntttc: number | undefined) {
    //   const gefachdrFactors = new GefachdrFactors();
    //   gefachdrFactors.mntttc = valueMntttc;
    //   gefachdrFactors.dateFac = this.getFormValue('dateFac');
    //   gefachdrFactors.dateEch = this.getFormValue('dateEch');
    //   gefachdrFactors.facId = this.getFormValue('facId');
    //   this._events.emit('enteteValues', {
    //     gefachdrFactors,
    //   });
    // }

    //
    public changeTva(event: Gltvadef): void {
        if (event) {
            this.tvaTauxVal = event.tvaTaux ?? 0;
            this.setFormValue('tvaTaux', event.tvaTaux);
        }
    }

    //
    // public get cptaref(): boolean {
    //   return !!this.getFormValue('cptaRef') && this.isReadonly;
    // }
    //
    // public get nbJPaiement() {
    //   if (!this.getFormValue('datePay') || !this.getFormValue('dateFac')) {
    //    return '0 j.';
    //   } else {
    //     return IwDateHelper.datesDiff(this.getFormValue('dateFac') ?? '',
    //     this.getFormValue('datePay') ?? '', 'days') + ' j.';
    //   }
    // }
    //
    // public get hasRappels(): boolean {
    //   return !!this.txtRappels;
    // }
    //
    // public get nbJRetard() {
    //   return IwDateHelper.datesDiff(this.getFormValue('dateEch') ?? '',
    //   IwDateHelper.dateIso(), 'days');
    // }
    //
    // public calcSolde() {
    //   this.calcSoldeValue = Number(this.getFormValue('mntTtc')) -
    //         Number(this.getFormValue('mntRecu')) -
    //         Number(this.getFormValue('mntEsc')) -
    //         Number(this.getFormValue('mntPaiSpec'));
    // }
    //
    // public get txtRappels() {
    //   if (!this.getFormValue('dateRpl')) {
    //     return '';
    //   }
    //   return this.getFormValue('dateRpl') ?
    //     '# ' + this.getFormValue('noRappel') + ' / ' +
    //     IwDateHelper.dateFormatFromString(this.getFormValue('dateRpl'), 'DD.MM.yyyy') : '';
    // }
    //
    // public dateCptaEnabled(): boolean {
    //   return !!(this.getFormValue('cptaRef') &&
    //     (this.getFormValue('facType') === 'PT' ||
    //     ('-M-N-C-I-').includes(this.getFormValue('modeCreat') ?? '')));
    // }
    //
    // public txtRabais(): void {
    //   if (this.getFormValue('mntBrut') !== 0) {
    //     this.txtRabaisValue = Math.round(100 * (Number(this.getFormValue('mntRabais')) ?? 0) /
    //                       (Number(this.getFormValue('mntBrut')) ?? 0) * 1000) / 1000 + '%';
    //   }
    // }
    //
    // public txtTauxEsc(): void {
    //   if (this.getFormValue('mntTtc') !== 0) {
    //     this.txtTauxEscValue = Math.round(100 * (Number(this.getFormValue('mntEsc')) ?? 0) /
    //                       (Number(this.getFormValue('mntTtc')) ?? 0) * 1000) / 1000 + '%';
    //   }
    // }
    //
    // public txtRabaisDisabled(): boolean {
    //   return !!this.isReadonly || !!this.getFormValue('cptaRef');
    // }

    public openRappels(): void {
        this._rappelsService.getRappelsTable(this.getFormValue('facId')!)
            .subscribe((rappels) => {
                const options: ModalRappelsOptions = {
                    content: rappels,
                    okDisabled: false,
                    showCancel: false,
                    title: this._translateService.instant('rappels'),
                    width: 500
                };
                this._modalService.showModal(ModalRappelsComponent, options);
            });
    }

    public onGepaicondSelected(gepaicond: Gepaicond) {
        if (!gepaicond || !gepaicond.nbjours) {
            return;
        }
        let dateEch = IwDateHelper.dateIsoString(IwDateHelper.addToDate(gepaicond.nbjours, 'day', this.getFormValue('dateFac')));
        this.setFormValue('dateEch', dateEch);
        this.refreshValues();
    }

    //
    private refreshValues() {
        //   this.factureId = id;
        this.setNbJPaiementVisible();
        this.setNbJRetardVisible();

        this.nbJRetard = IwDateHelper.datesDiff(this.dateEch, IwDateHelper.dateIso(), 'days');
        // update nbJPaiement
        {
            if (this.datePay && this.dateFac) {
                this.nbJPaiement = IwDateHelper.datesDiff(this.dateFac, this.datePay ?? '', 'days');
            } else {
                this.nbJPaiement = 0;
            }
        }

        //   this.txtRabais();
        //   this.txtTauxEsc();
        //   this.calcSolde();
        //   this.tvaTauxVal = this.getFormValue('tvaTaux') ?? 0;
        //   this.payId = this.getFormValue('payId');
    }

    private setNbJPaiementVisible(): void {
        this.nbJPaiementVisible = (this.getFormValue('facOrnc') !== 'N' && ((Number(this.getFormValue('mntTtc'))) - Number(this.getFormValue('mntRecu')) - Number(this.getFormValue('mntEsc')) - Number(this.getFormValue('mntPaiSpec'))) === 0);
    };

    private setNbJRetardVisible(): void {
        this.nbJRetardVisible = (this.getFormValue('facOrnc') !== 'N' && ((Number(this.getFormValue('mntTtc'))) - Number(this.getFormValue('mntRecu')) - Number(this.getFormValue('mntEsc')) - Number(this.getFormValue('mntPaiSpec'))) !== 0);
    };
}
