import {AfterViewInit, Component, OnInit, Type, ViewChild} from '@angular/core';
import {SalaryForm} from '@app/modules/sam-main/salary/salary.forms';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {FormHandlerService, IwStoreService, ModalService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {SadsService} from '@app/sam-base/core/services/sads.service';
import {SarepService} from '@app/sam-base/core/services/sarep.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormState} from '@app/sam-base/core/store/reducers/form.reducer';
import {ComboboxItem, FormKeys, IwGridColumn} from '@app/sam-base/models';
import {Sads, Sadsview} from '@app/sam-base/models/salary';
import {TranslateService} from '@ngx-translate/core';
import {ModalMessageComponent} from '@sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@sam-base/components/modal/modal-message/modal-message.options';
import {MonthValue} from '@shared/widgets/iw-components/iw-months-picker/iw-months-picker.component';

@Component({templateUrl: './sads.component.html'})
export class SadsComponent extends BaseFormComponent<Sads> implements OnInit, AfterViewInit {

    @ViewChild('sadsGrid') public sadsGrid?: IwRestGridComponent<Sadsview>;
    public tableWidth = '250px';
    public type: Type<Sadsview> = Sadsview;
    public query: RestQueryParam<Sadsview, string | number>[] = [];
    public sadsColumns = this.getSadsColumns();
    public sads?: Sads;
    public sortProp?: keyof Sadsview = 'gsId';
    public sortPropDir?: 'asc' | 'desc' = 'asc';
    public months?: MonthValue[];
    public formState: FormState | undefined;
    public selectedRow?: Sadsview;
    public readonly = true;
    public isSaving = false;
    public canModify = false;
    public newEntryMode = false;
    public enable = false;
    public enableFacteurs = false;
    public enableArrondir = false;
    public checkValue1 = false;
    public checkValue2 = false;
    public freqOptions: ComboboxItem<number>[] = [
        {
            name: this._translate.instant('combo_mensuel'),
            value: 1
        },
        {
            name: this._translate.instant('selonMois'),
            value: 2
        }];
    public arrondirOptions: ComboboxItem<number>[] = [
        {
            name: this._translate.instant('5cts'),
            value: 1
        },
        {
            name: this._translate.instant('franc'),
            value: 2
        },
        {
            name: this._translate.instant('francSup'),
            value: 3
        }];
    private readonly _restSadsClient: RestEntityClient<Sads>;

    constructor(private _store: IwStoreService, rest: RestApiService, private _translate: TranslateService,
                private readonly sadsService: SadsService, private _sarepService: SarepService,
                private readonly _modalService: ModalService,
                private readonly _formHandler: FormHandlerService<SalaryForm>) {
        super();
        this._restSadsClient = rest.getEntityClient(Sads);
    }

    public get empId(): string {
        return this.getData('empId', true) || '';
    }

    public get ifactsal(): string {
        return this.getData('ifactsal', true) || '';
    }

    public get ifactsalfu(): string {
        return this.getData('ifactsalfu', true) || '';
    }

    public ngOnInit() {
        this.setQuery(this.empId);
        const fieldNames = [
            'base',
            'ifact',
            'ifactfull',
            'ifact2',
            'ifact2full',
            'nbmois',
            'ldivimois',
            'lfacteurs',
            'iroundmode',];
        fieldNames.forEach(fld => this.subscribeFieldChange(fld));

        this.subscribeValueChange('base', e => {
            const formData = this.getFormData();
            const updatedData = this._restSadsClient.construct({
                ...this.selectedRow, ...formData
            });
            this.setFormValue('montant', this.calcMontant(updatedData));
        });
    }

    public subscribeFieldChange(fld: any) {
        this.subscribeValueChange(fld, e => {
            const formData = this.getFormData();
            const updatedData = this._restSadsClient.construct({
                ...this.selectedRow, ...formData
            });
            this.setFormValue('montant', this.calcMontant(updatedData));
        });
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            if (this.sadsGrid) {
                this.sadsGrid.sortProp = 'gsId';
                this.sadsGrid.sortDir = 'asc';
            }
        }, 0);
    }

    public enableFields(event: any) {
        this.enable = event?.value.value === 2;
    }

    public enableCkr1(event: boolean) {
        this.checkValue1 = event;
        this.checkValues(this.checkValue1, this.checkValue2);
    }

    public enableCkr2(event: boolean) {
        this.checkValue2 = event;
        this.checkValues(this.checkValue1, this.checkValue2);
    }

    // eslint-disable-next-line complexity
    public checkValues(checkValue1: boolean, checkValue2: boolean) {
        if (checkValue1 && checkValue2) {
            this.enableArrondir = true;
            this.enableFacteurs = true;
        }
        if (checkValue1 && !checkValue2) {
            this.enableArrondir = true;
            this.enableFacteurs = false;
        }
        if (!checkValue1 && checkValue2) {
            this.enableArrondir = true;
            this.enableFacteurs = true;
        }
        if (!checkValue1 && !checkValue2) {
            this.enableArrondir = false;
            this.enableFacteurs = false;
        }
    }

    public setMonths(event: any) {
        this.months = event;
        if (this.months?.length !== 0) {
            this.setFormValue('cwhen', '-' + this.months?.join('-') + '-');
        } else {
            this.setFormValue('cwhen', '-');
        }
        this.onFormChange();
    }

    public getSelectedRow(event: Sadsview[]) {
        this.readonly = true;
        if (this.isSaving) {
            this.isSaving = false;
            return;
        }
        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }
        this.selectedRow = event[0];
        this._restSadsClient
            .getById(this.selectedRow.dsId || '')
            .subscribe((res: Sads) => {
                this.selectedRow = res;
                this.fillFormData(res);
                this.enable = this.getFormValue('frequence') === 2;
                this.enableArrondir = this.getFormValue('ldivimois');
                this.enableFacteurs = this.getFormValue('lfacteurs');
                this.getMonth();
            });
    }

    public openRepartition() {
        this._formHandler.showFormDialog(SalaryForm.Repartition, {
            key: 'C' + this.getFormValue('dsId'),
            ifactsal: this.ifactsal,
            ifactsalfu: this.ifactsalfu
        });
    }

    public createNewEntry() {
        this.selectedRow = {};
        this.setMonths([]);
        this.readonly = false;
        this.newEntryMode = true;
        this.clearForm();
    }

    public modifyEntry(): void {
        if (this.selectedRow) {
            this.readonly = false;
            this.newEntryMode = false;
        }
    }

    public async deleteEntry() {
        if (this.selectedRow) {
            const options: ModalMessageOptions = {
                message: ['sads_delete_confirm_message'],
                showCancel: true,
                title: this._translate.instant('supprimer'),
                okDisabled: false
            };
            try {
                await this._modalService.showModal(ModalMessageComponent, options);
                this.sadsService.deleteByDsId(this.selectedRow?.dsId!).subscribe(() => {
                    this.sadsGrid?.refresh();
                });
                return true;
            } catch (error) {
                return false;
            }
        }
    }

    public saveEntry() {
        this.setValues();
        const formData = this.getFormData();
        const updatedData = this._restSadsClient.construct({
            ...this.selectedRow, ...formData
        });
        updatedData.montant = this.calcMontant(updatedData);
        if (this.newEntryMode) {
            this.sadsService.createNewEntry(updatedData)
                .subscribe(() => {
                    this.sadsGrid?.refresh();
                });
            this.newEntryMode = false;
        } else {
            this.sadsService
                .saveByDsId(this.getFormValue('dsId'), updatedData)
                .subscribe(() => {
                    this.sadsGrid?.refresh();
                });
        }
    }

    public calcMontant(updatedData: Sads): number {
        let f1: number = 1.0;
        let f2: number = 1.0;
        let f3: number = 1.0;
        let montant: number = 0;
        if (updatedData.lfacteurs) {
            if (updatedData.ifactfull !== 0 && updatedData.ifact && updatedData.ifactfull) {
                f1 = updatedData.ifact / updatedData.ifactfull
            }
            if (updatedData.ifact2full !== 0 && updatedData.ifact2 && updatedData.ifact2full) {
                f2 = updatedData.ifact2 / updatedData.ifact2full;
            }
        }
        if (updatedData.ldivimois && updatedData.nbmois && updatedData.nbmois > 0) {
            f3 = 1 / updatedData.nbmois;
        }

        let f = (f1 * f2 * f3 !== 0) ? f1 * f2 * f3 : 1.0;
        if (f !== 1.0 && updatedData.base) {
            switch (updatedData.iroundmode) {
                case 1:
                    montant = this.decRound(updatedData.base * f, 0.05);
                    break;
                case 2:
                    montant = this.decRound(updatedData.base * f, 1.0);
                    break;
                default:
                    let nTmp: number = updatedData.base * f;
                    if (Math.round(nTmp * 100.0) / 100.0 === Math.floor(nTmp)) {
                        montant = Math.floor(nTmp);
                    } else {
                        montant = Math.floor(nTmp) + 1;
                    }
                    break;
            }
        } else {
            if (updatedData.base) {
                montant = updatedData.base;
            }
        }
        return montant;
    }

    // eslint-disable-next-line complexity
    public setValues() {
        if (!this.getFormValue('frequence')) {
            this.setFormValue('frequence', 1);
        }
        if (!this.getFormValue('iroundmode')) {
            this.setFormValue('iroundmode', 1);
        }
        if (!this.getFormValue('gsId')) {
            this.setFormValue('gsId', '100');
        }
        if (!this.getFormValue('nbmois')) {
            this.setFormValue('nbmois', 12);
        }
        if (!this.getFormValue('empId')) {
            this.setFormValue('empId', this.empId);
        }
    }

    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.readonly = true;
        this.newEntryMode = false;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public getMonth() {
        this.months = this.getFormValue('cwhen')
            ?.split('-')
            .filter((elem: string) => elem !== '')
            .map((elem: string) => Number(elem));
    }

    protected getValidationType() {
        return Sads;
    }

    protected getFormControlNames(): FormKeys<Sads> {
        return [
            'gsId',
            'frequence',
            'debut',
            'fin',
            'nbmois',
            'texte',
            'cptdebit',
            'cptcredit',
            'base',
            'ldivimois',
            'lfacteurs',
            'ifact',
            'ifactfull',
            'ifact2',
            'ifact2full',
            'montant',
            'iroundmode',
            'cwhen',
            'empId',
            'dsId'];
    }

    protected validateFields(e: Sads): boolean {
        return super.validateFields(e);
    }

    private decRound(value: number, increment: number): number {
        return Math.round(value / increment) * increment;
    }

    private setQuery(empId: string) {
        this.query = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'empId',
                value: empId
            }];
    }

    /* Columns to show in  table */
    private getSadsColumns(): IwGridColumn<Sadsview>[] {
        return [
            {
                prop: 'gsId',
                name: 'gs',
                index: 0
            },
            {
                prop: 'titre',
                name: 'titre',
                index: 1
            },
            {
                prop: 'montant',
                name: 'montant',
                index: 2,
                decimals: 2
            }];
    }
}
