<div class="row period-management">
    <div class="row"
         style="width: 100%">
        <div class="col-xs-8"
             style="height: 500px;">
            <iw-rest-grid #geperGrid
                          (selected)="getSelectedRow($event)"
                          [customColumns]="columns"
                          [defaultSelected]="true"
                          [defaultSortDir]="'desc'"
                          [defaultSort]="'date'"
                          [forceSelected]="true"
                          [hasGridColumnMenu]="false"
                          [hasSortIcon]="false"
                          [queryStatements]="query"
                          [type]="type"></iw-rest-grid>
        </div>

        <div class="col-xs-4 grid-buttons">
            <iw-button (press)="createNewPeriod()"
                       [text]="'period_button_new' | translate"
                       class="iw-button"
                       id="newPeriode"></iw-button>
            <iw-button (press)="closePeriod()"
                       *ngIf="selectedPeriod.status !== 1"
                       [isDisabled]="periodCloseDisabled"
                       [text]="'period_button_close' | translate"
                       class="iw-button"
                       id="closePeriod"></iw-button>
            <iw-button (press)="openPeriod()"
                       *ngIf="selectedPeriod.status === 1"
                       [text]="'period_button_open' | translate"
                       class="iw-button"
                       id="openPeriod"></iw-button>
            <iw-button (press)="publish()"
                       *iwAccessGroups="FACTURE_PRINT_ROLE"
                       [isDisabled]="!canCalculatePeriod"
                       [text]="'Publish' | translate"
                       class="iw-button"
                       id="publish"></iw-button>
            <iw-button (press)="comptabilisation()"
                       *iwAccessGroups="ACCOUNTING_ROLES"
                       [isDisabled]="!canCalculatePeriod"
                       [text]="'Comptabilisation'"
                       class="iw-button"
                       id="calculatePeriod"></iw-button>
            <iw-button (press)="closeDialog()"
                       [text]="'annuler' | translate"
                       class="iw-button"
                       id="closeDialog"></iw-button>
        </div>
    </div>

</div>
