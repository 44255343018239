import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppkw} from '@app/sam-base/models/placement';

import {KeywordConfigComponent} from '../components/keyword-config/keyword-config.component';

export const PPKW_DEFAULT_FORM: FormDescription<Ppkw> = {
    form: KeywordConfigComponent, state: {
        ...buildDefaultState(Ppkw), diagModal: true, diagTitle: 'ppekw_form_title', width: 700, isNew: true
    }
};
