import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {ModesFacturation} from '@app/sam-base/models/parameters-enums';
import {Pppar} from '@app/sam-base/models/placement';

import {calcLPP} from '../pppar-enums';

/**
 * Tab General of parametres Generaux
 */
@Component({
    selector: 'iw-tab-pppar-salaire2',
    templateUrl: './tab-pppar-salaire2.component.html',
    styleUrls: ['./tab-pppar-salaire2.component.scss']
})
export class TabPpparSalaire2Component extends BasePartialFormComponent<Pppar> {

    public modeFact = ModesFacturation;

    public get entityId() {
        const id = this.getFormValue('ageId');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public entityService: RestEntityClient<Pppar>;
    public calcLPP = calcLPP;

    constructor(_restService: RestApiService) {
        super();
        this.entityService = _restService.getEntityClient(Pppar);
    }

}
