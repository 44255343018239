import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class GedtaService {

    constructor(private _http: HttpClient) {
    }

    public cancelGedta(dtaId: string): Observable<void> {
        return this._http.delete<void>(`${environment.backendURL}gedta/cancel/${dtaId}`);
    }

    public getUrlForDocument(dtaId: string): Observable<string> {
        return this._http.get(`${environment.backendURL}gedta/file/${dtaId}`, {responseType: 'text'});
    }
}
