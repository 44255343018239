<iw-dialog #dialog
           (beforeHide)="onClose()"
           [bodyPadding]="'10'"
           [closable]="true"
           [cls]="'iw-modal'"
           [draggable]="true"
           [height]="'80vh'"
           [modal]="false"
           [resizable]="false"
           [showHeader]="true"
           [title]="'layout_menu_operations' | translate"
           [visible]="true"
           [width]="'60vw'">
    <iw-busy [ajustLeft]="0"
             [ajustTop]="0"
             [isBusy]="loading"></iw-busy>
    <iw-operation-detail [operationDetail]="operationDetail"></iw-operation-detail>
</iw-dialog>
