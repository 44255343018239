import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {
    BookingFileListComponent
} from '@modules/sam-main/salary/components/booking-file-list/booking-file-list.component';
import {BookingFile} from '@sam-base/models/accounting/booking-file.model';

export const BOOKING_FILE_LIST_FORM: FormDescription<BookingFile> = {
    form: BookingFileListComponent,
    state: {
        ...buildDefaultState(BookingFile),
        diagTitle: 'title_booking_files',
        width: 1200,
        editMode: 'read'
    }
};
