<form [formGroup]="formGroup" class="row">

    <div class="form-label col-xs-4 no-padding">
        <div class="col-xs-12 flex flex-center no-padding-right">
            <hr class="header-line">
            <div class="header-line-color">{{ 'formation' | translate }}</div>
            <hr class="header-line">
        </div>
        <div class="row">
            <iw-enum-dropdown #cboFc_dedmod [inputEnum]="fcDedmodOptions" [isDisabled]="isReadonly" [labelAlign]="'top'"
                              [label]="'mode' | translate" class="iw-input col-xs-12"
                              formControlName="fcDedmod"></iw-enum-dropdown>

        </div>
    </div>
    <div class="form-label col-xs-4 no-padding">
        <div class="col-xs-12 flex flex-center no-padding">
            <hr class="header-line">
            <div class="header-line-color">{{ '[%]' | translate }}</div>
            <hr class="header-line">
        </div>
        <div class="row">
            <iw-textfield #cboFc_tx_emp [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'employe' | translate"
                          class="iw-input col-xs-6" formControlName="fcTxEmp" type="number"></iw-textfield>

            <iw-textfield #txtFc_tx_soc [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'employeur' | translate"
                          class="iw-input col-xs-6" formControlName="fcTxSoc" type="number"></iw-textfield>

        </div>
    </div>
    <div class="form-label col-xs-4 no-padding">
        <div class="col-xs-12 flex flex-center no-padding-left">
            <hr class="header-line">
            <div class="header-line-color">{{ 'frs_heure' | translate }}</div>
            <hr class="header-line">
        </div>
        <div class="row">
            <iw-textfield #txtFc_mnt_emp [isDisabled]="true" [labelAlign]="'top'" [label]="'employe' | translate"
                          class="iw-input col-xs-6" formControlName="fcMntEmp" type="number"></iw-textfield>

            <iw-textfield #txtFc_mnt_soc [isDisabled]="true" [labelAlign]="'top'" [label]="'employeur' | translate"
                          class="iw-input col-xs-6" formControlName="fcMntSoc" type="number"></iw-textfield>

        </div>
    </div>

    <div class="row col-xs-12 no-padding">

        <div #cboFc_gs_cotLabel class="iw-label col-xs-3"
             title="{{'deductionEmploye' | translate}}">{{ 'deductionEmploye' | translate }}
        </div>
        <iw-sags-dropdown #cboFc_gs_cot [isDisabled]="isReadonly" [noLabel]="true" [types]="['P']"
                          class="iw-input col-xs-9" formControlName="fcGsCot"></iw-sags-dropdown>

        <div #cboFc_gs_coeLabel class="iw-label col-xs-3"
             title="{{'deductionEmployeur' | translate}}">{{ 'deductionEmployeur' | translate }}
        </div>
        <iw-sags-dropdown #cboFc_gs_coe [isDisabled]="isReadonly" [noLabel]="true" [types]="['Q']"
                          class="iw-input col-xs-9" formControlName="fcGsCoe"></iw-sags-dropdown>

        <div #cbofc_gs_cofLabel class="iw-label col-xs-3"
             title="{{'correctionEmploye' | translate}}">{{ 'correctionEmploye' | translate }}
        </div>
        <iw-sags-dropdown #cbofc_gs_cof [isDisabled]="isReadonly" [noLabel]="true" [types]="['2', 'M']"
                          class="iw-input col-xs-9" formControlName="fcGsCof"></iw-sags-dropdown>

        <div #cbofc_gs_cfeLabel class="iw-label col-xs-3"
             title="{{'correctionEmployeur' | translate}}">{{ 'correctionEmployeur' | translate }}
        </div>
        <iw-sags-dropdown #cbofc_gs_cfe [isDisabled]="isReadonly" [noLabel]="true" [types]="['9', 'N']"
                          class="iw-input col-xs-9" formControlName="fcGsCfe"></iw-sags-dropdown>

        <div #cbofc_gs_cfeLabel class="iw-label col-xs-3" title="{{'base' | translate}}">{{ 'base' | translate }}
        </div>
        <iw-sags-dropdown #cboFc_gs_bas [isDisabled]="isReadonly" [noLabel]="true" [types]="['0']"
                          class="iw-input col-xs-9" formControlName="fcGsBas"></iw-sags-dropdown>

    </div>
</form>
