import {Observable} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {RestParams} from '@sam-base/core';

@Injectable()
export class InterijobService extends RestClient<{}> {

    constructor(http: HttpClient) {
        super(http);
    }

    public checkIjParams(password: string, username: string, endpoint: string): Observable<string> {
        const params: RestParams = {password, username, endpoint};
        return this.POST(params, undefined, `interijob/checkConfig`);
    }
}
