import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('gepaicli')
export class Gepaicli {
    @IwRestEntityId() public payCliId?: string;
    public payId?: string;
    public cliId?: string;
    public payStatus?: string;
    public origine?: string;
    public montant?: number;
    public solde?: number;
    public cptDeb?: string;
    public userId?: string;
}
