import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Sadedda} from '@app/sam-base/models/salary/sadedda';
import {SadeddaComponent} from '../components/sadedda/sadedda.component';

export const SADEDDA_DEFAULT_FORM: FormDescription<Sadedda> = {
    form: SadeddaComponent, state: {
        ...buildDefaultState(Sadedda), diagTitle: 'sadedda_form_title', diagModal: false, width: 650, isNew: true
    }
};
