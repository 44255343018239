import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MapsService} from '@app/sam-base/core';
import {IwEventRegisterService} from '@app/sam-base/core/events';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {CctService} from '@app/sam-base/core/services/cct.service';
import {CommandeService} from '@app/sam-base/core/services/commande.service';
import {MissionCalculationsService} from '@app/sam-base/core/services/mission-calculations.service';
import {ENTITIES} from '@app/sam-base/models/placement';
import {GestionModelesService} from '@modules/sam-main/placement/components/gestion-modeles/gestion-modeles.service';
import {SalaryProcessService} from '@modules/sam-main/placement/services/salary-process.service';
import {SalaryService} from '@modules/sam-main/salary/services/salary-service';
import {ComponentStore} from '@shared/services/form-dialog-host.service';
import {SharedModule} from '@shared/shared.module';
import {FileUploadModule} from 'primeng/fileupload';
import {MessageModule} from 'primeng/message';
import {TableModule} from 'primeng/table';
import {TooltipModule} from 'primeng/tooltip';

import {PlacementEventsHandler} from './events';
import {PLACEMENT_FORMS} from './placement-forms-collection';
import {PLACEMENT_COMPONENT_LIST, PLACEMENT_COMPONENTS, PLACEMENT_FORMS_ENTRY_COMPONENTS} from './placement-forms.list';
import {PlacementForm} from './placement.forms';
import {AvsNumberService} from './services/avs-number.service';
import {CalculationsService} from './services/calculations.service';
import {ClientStatsService} from './services/client-stats.service';
import {ClientService} from './services/client.service';
import {EntityUpdateService} from './services/entity-update.service';
import {InvoiceService} from './services/invoice.service';
import {LocalProfileService} from './services/local-profile.service';
import {MissionInterijobService} from './services/mission-interijob.service';
import {MissionMisIhService} from './services/mission-misih.service';
import {PpempService} from './services/ppemp.service';
import {PpheusaidService} from './services/ppheusaid.service';
import {PplppService} from './services/pplpp.service';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        FileUploadModule,
        TooltipModule,
        TableModule,
        MessageModule,
    ],
    declarations: [
        ...PLACEMENT_COMPONENT_LIST,
        ...PLACEMENT_FORMS_ENTRY_COMPONENTS],
    providers: [
        ClientStatsService,
        AvsNumberService,
        MissionCalculationsService,
        CalculationsService,
        PplppService,
        MissionMisIhService,
        MissionInterijobService,
        PpheusaidService,
        InvoiceService,
        SalaryProcessService,
        PpempService,
        MapsService,
        SalaryService,
        CommandeService,
        EntityUpdateService,
        CctService,
        GestionModelesService,
        ClientService]
})
export class PlacementModule {
    constructor(_formHandlerService: FormHandlerService<PlacementForm>,
                _restApiService: RestApiService, _eventsHandlers: IwEventRegisterService,
                _localProfile: LocalProfileService) {
        _formHandlerService.registerComponents(<ComponentStore><unknown>PLACEMENT_COMPONENTS);
        _formHandlerService.registerForms(PLACEMENT_FORMS);
        _restApiService.registerEntityTypes(...ENTITIES);
        _eventsHandlers.registerEventHandlerProvider(PlacementEventsHandler);

        _localProfile.setLocalProfiles();
    }
}
