<form [formGroup]="formGroup"
      class="row">

    <div class="col-xs-9 separator-right">
        <div class="row col-xs-12 no-padding no-margin">
            <iw-textfield #txtDateLundi
                          [isDisabled]="true"
                          [labelAlign]="'left'"
                          [label]="'date' | translate"
                          [size]="'small'"
                          [textAlign]="'center'"
                          [value]="datelundiFormatted"
                          class="col-xs-4 no-padding"></iw-textfield>
            <iw-textfield #txtSemaine
                          [(value)]="weekNumber"
                          [isDisabled]="true"
                          [textAlign]="'center'"
                          class="col-xs-2 no-padding"></iw-textfield>
            <iw-checkbox #chkFinMission
                         [(value)]="finmission"
                         [label]="'fin_mission' | translate"
                         class="col-xs-6"></iw-checkbox>
        </div>
        <div class="row col-xs-12 no-padding no-margin">
            <iw-textfield #txtRap_no
                          [(value)]="rapNo"
                          [isDisabled]="!this.rapnoref"
                          [labelAlign]="'left'"
                          [label]="'rapport_no' | translate"
                          [size]="'small'"
                          [textAlign]="'center'"
                          class="col-xs-6 no-padding"></iw-textfield>
            <iw-textfield-lookups #txtFinMotif
                                  [(value)]="finmotif"
                                  [isDisabled]="!finmission"
                                  [labelAlign]="'left'"
                                  [label]="'motifFin' | translate"
                                  [lookupName]="'FINMISSION'"
                                  class="col-xs-6"></iw-textfield-lookups>
        </div>
        <div class="row col-xs-12 no-padding">
            <iw-checkbox
                #chkHpauseAuto
                (valueChange)="onHpauseAutoChange($event)"
                [(value)]="hpauseauto"
                [isDisabled]="!heurepause"
                [label]="'heure_pause' | translate"
                class="col-xs-6 padding-left-100"
            ></iw-checkbox>
            <iw-textfield #txtHpauSaiMod
                          *ngIf="heurepause"
                          [isDisabled]="true"
                          [value]="'ajoutee' | translate"
                          class="col-xs-2"></iw-textfield>
            <iw-checkbox
                #chkForceFacHeurePause
                (valueChange)="onForceFacHeurePauseChange($event)"
                [(value)]="forceFacHeurePause"
                [isDisabled]="!heurepause"
                [label]="'force_fac_heure_pause' | translate"
                class="col-xs-12 padding-left-100"
            ></iw-checkbox>

        </div>
        <iw-hours-grid (addHourEvent)="addHourToMission($event)"
                       (ppheuSaidOutput)="setGridOutput($event)"
                       [canAddHours]="true"
                       [hpauseAutoEnable]="hpauseauto"
                       [ppheuSaidInput]="gridHoursInput"></iw-hours-grid>

        <div class="col-xs-12 row no-padding no-margin">
            <iw-table #grdInd
                      (selectedChange)="setSelectedRow($event)"
                      [columns]="columns"
                      [data]="indemnites"
                      [isFilterEnable]="false"
                      [isGridColumnMenuEnable]="false"
                      [isSortIconEnable]="false"
                      class="col-xs-11 no-padding"
                      style="height: 150px"></iw-table>

            <div class="col-xs-1">
                <iw-button #btnInd
                           (press)="newIndemnite()"
                           [tooltip]="'new_ind' | translate"
                           class="iw-button col-xs-12"
                           iconClass="fas fa-file"></iw-button>
                <iw-button #btnModInd
                           (press)="editIndemnite()"
                           [isDisabled]="disabledBtnModDelInd"
                           [tooltip]="'edit_ind' | translate"
                           class="iw-button col-xs-12"
                           iconClass="fas fa-pencil-alt"></iw-button>
                <iw-button #btnDelInd
                           (press)="deleteIndemnite()"
                           [isDisabled]="disabledBtnModDelInd"
                           [tooltip]="'del_ind' | translate"
                           class="iw-button col-xs-12"
                           iconClass="fas fa-trash"></iw-button>
            </div>
        </div>
        <div class="col-xs-12 row no-padding">
            <iw-textfield #txtLibTotInd
                          [isDisabled]="true"
                          [textAlign]="'center'"
                          [value]="'totalInd' | translate"
                          class="iw-input col-xs-3 no-padding"></iw-textfield>
            <iw-textfield #txtTotIndEmp
                          [isDisabled]="true"
                          [textAlign]="'center'"
                          [value]="totEmpValue"
                          class="iw-input col-xs-4 no-padding"></iw-textfield>
            <iw-textfield #txtTotIndCli
                          [isDisabled]="true"
                          [textAlign]="'center'"
                          [value]="totCliValue"
                          class="iw-input col-xs-4 no-padding"></iw-textfield>
        </div>

        <div class="row col-xs-12 no-padding">
            <iw-button (press)="addNewChantier()"
                       class="flex-end margin-right-5"
                       iconClass="fas fa-plus-circle"
                       id="create-entity"></iw-button>
            <iw-textfield-chantiers #txtChantier
                                    [cliId]="cliId"
                                    [isDisabled]="freezCha"
                                    [labelAlign]="'top'"
                                    [label]="'code_fac' | translate"
                                    class="iw-input col-xs-4 no-padding"
                                    formControlName="chantier"></iw-textfield-chantiers>
            <iw-textfield #edtFacLinTxt
                          [(value)]="faclintxt"
                          [labelAlign]="'top'"
                          [label]="'texte_sup' | translate"
                          class="col-xs-7 no-padding"></iw-textfield>
        </div>

    </div>

    <div class="row col-xs-3">
        <div class="col-xs-12">
            <div class="row col-xs-12 no-padding">
                <iw-textfield #txtRap_id
                              [isDisabled]="true"
                              [tooltip]="'rapId' | translate"
                              [value]="rapId"
                              class="col-xs-5 no-padding"></iw-textfield>
                <iw-textfield #txtSal_id
                              [isDisabled]="true"
                              [tooltip]="'periode' | translate"
                              [value]="salId"
                              class="col-xs-4 no-padding"></iw-textfield>
                <iw-button #btnNote
                           (press)="rapportNote()"
                           [text]="'note' | translate"
                           class="iw-button col-xs-3 no-padding"></iw-button>
            </div>
            <iw-textfield #txtNomEmploye
                          [isDisabled]="true"
                          [tooltip]="'empId' | translate"
                          [value]="empName"
                          class="col-xs-12 no-padding"></iw-textfield>
            <iw-textfield #txtNomClient
                          [isDisabled]="true"
                          [tooltip]="'nomclient' | translate"
                          [value]="clientName"
                          class="col-xs-12 no-padding"></iw-textfield>
            <div class="row col-xs-12 no-margin no-padding">
                <iw-textfield #txtMis_id
                              [isDisabled]="true"
                              [tooltip]="'missionNo' | translate"
                              [value]="misId"
                              class="col-xs-5 no-padding"></iw-textfield>
                <iw-textfield #txtQualif
                              [isDisabled]="true"
                              [tooltip]="'qualif' | translate"
                              [value]="qualif"
                              class="col-xs-7 no-padding"></iw-textfield>
            </div>
            <iw-textfield #txtRefclient
                          [isDisabled]="true"
                          [tooltip]="'ref_client' | translate"
                          [value]="refclient"
                          class="col-xs-12 no-padding"></iw-textfield>
            <div class="col-xs-12 margin-top-5 title-grid">{{ 'frais' | translate }}</div>
            <iw-textarea #edtFrais
                         [height]="80"
                         [isDisabled]="true"
                         [value]="frais"
                         class="iw-textarea col-xs-12"></iw-textarea>
            <div class="col-xs-12 title-grid">{{ 'remarques_emp' | translate }}</div>
            <iw-textarea #edtNoteEmp
                         [height]="80"
                         [isDisabled]="true"
                         [value]="noteemp"
                         class="iw-textarea col-xs-12"></iw-textarea>
            <div class="col-xs-12 title-grid">{{ 'remarques_cli' | translate }}</div>
            <iw-textarea #edtNoteCli
                         [height]="80"
                         [isDisabled]="true"
                         [value]="notecli"
                         class="iw-textarea col-xs-12"></iw-textarea>
            <div class="col-xs-12 title-grid">{{ 'remarques_interne' | translate }}</div>
            <iw-textarea #edtNote
                         [height]="80"
                         [isDisabled]="true"
                         [value]="note"
                         class="iw-textarea col-xs-12"></iw-textarea>
            <div class="row col-xs-12 no-padding margin-top-5">
                <iw-textarea #edtInfo
                             [height]="130"
                             [isDisabled]="true"
                             [value]="edtValue"
                             class="iw-textarea font-lucida col-xs-12"></iw-textarea>
            </div>
            <div class="col-xs-12 row no-padding margin-top-20">
                <iw-button #raz
                           (press)="destroyForm()"
                           [text]="'annuler' | translate"
                           class="iw-button col-xs-6"></iw-button>
                <iw-button #ok
                           (press)="saveDataClick()"
                           [isDisabled]="!isValid"
                           [text]="'enregistrer' | translate"
                           class="iw-button col-xs-6"></iw-button>
            </div>
        </div>
    </div>

</form>
