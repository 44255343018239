import {Component} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core';
import {FormKeys} from '@app/sam-base/models';
import {Ppofa} from '@app/sam-base/models/placement';

import {FacturationPause, Mode, ModeChantier, OrdreDesElements} from '../invoices.model';

@Component({
    templateUrl: './invoices-parameters.component.html'
})
export class InvoicesParametersComponent extends BaseStoreFormComponent<Ppofa> {

    public mode = Mode;
    public facPause = FacturationPause;
    public ordreElems = OrdreDesElements;
    public modeChantier = ModeChantier;

    constructor(store: IwStoreService) {
        super(store);
    }

    public checkTextDisabled() {
        const mode = this.getFormValue('fixgenmode');
        return mode !== 2;
    }

    protected getFormControlNames(): FormKeys<Ppofa> {
        return ['libconc', 'libheu', 'elernd05', 'libunite', 'libsupcct', 'faceleord', 'textplfix', 'libheuequi', 'libheuequx', 'libheunuit', 'libheupaus', 'hpaufacmod', 'chant2Conc', 'defchamode', 'fixgenmode', 'libconcfix'];
    }
}
