import {Component, Input, OnInit, Type, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components/iw-rest-grid/iw-rest-grid.component';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {EsQueryStatement, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';

import {SuiviService} from '@app/sam-base/core/services/suivi.service';
import {EntityDelete, IwStoreService} from '@app/sam-base/core/store';
import {IwGridColumn} from '@app/sam-base/models';
import {Ppsuivi, Ppsuiviview} from '@app/sam-base/models/placement';
import {PrintService} from '@core/print/print.service';
import {TranslateService} from '@ngx-translate/core';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {PlacementForm} from '../../../placement.forms';
import {
    employeeMgtAccessRoles
} from '@app/sam-base/core/auth/access-rules/employee-form';

@Component({
    selector: 'iw-tab-ppmis-suivi', templateUrl: './tab-ppmis-suivi.component.html'
})
export class TabPpmisSuiviComponent extends BasePartialFormComponent<Ppsuivi> implements OnInit {

    @Input() public isReadonly = true;

    public query: RestQueryParam<Ppsuiviview, string>[] = [];
    public columns = this.getMisSuiviColumns();

    public employeeMgtAccessRoles: SamUserRole[] = employeeMgtAccessRoles();

    public type: Type<Ppsuiviview> = Ppsuiviview;
    public refreshType: Type<Ppsuivi> = Ppsuivi;

    public tableWidth = '1200px';

    public suiviDescription = '';

    public get entityId() {
        return this.getFormValue<string>('misId') || '';
    }

    public get suiId() {
        return this.getFormValue<string>('suiId') || '';
    }

    public get cliId() {
        return this.getFormValue<string>('cliId') || '';
    }

    public get empId() {
        return this.getFormValue<string>('empId') || '';
    }

    private _selected?: Ppsuiviview;
    @ViewChild('olvClisui', {static: true}) private _table?: IwRestGridComponent<Ppsuiviview>;

    constructor(private readonly _formHandler: FormHandlerService<PlacementForm>, private readonly _store: IwStoreService, private readonly _printService: PrintService, private readonly _translate: TranslateService, private _suiviService: SuiviService) {
        super();
    }

    public ngOnInit() {
        this.updateFilters(this.entityId);
        this.subscribeValueChange('misId', () => this.updateFilters(this.entityId));
    }

    public clearSortDir() {
        if (this._table) {
            this._table.defaultSort = undefined;
            this._table.defaultSortDir = 'asc';
        }
    }

    /**
     * Function to add new suivi for mission
     */
    public addSuiviMission() {
        this._formHandler.showFormDialog(PlacementForm.SuiviNew, {
            misId: this.entityId, cliId: this.cliId, empId: this.empId, suitype: 'M', action: 'MISACTION'
        }, s => ({...s, diagTitle: 'title_ppmissui'}));
    }

    /**
     * Function to edit one suivi in mission
     */
    public editSuiviMission() {
        const id = this.suiId;
        if (!id) {
            return;
        }
        this._formHandler.showFormDialog(PlacementForm.SuiviEdit, {
            misId: this.entityId, cliId: this.cliId, empId: this.empId, suitype: 'M', action: 'MISACTION'
        }, s => ({...s, entityId: id, diagTitle: 'title_ppmissui'}));
    }

    /**
     * Function to delete suivi in mission
     */
    public deleteSuiviMission() {
        const id = this.suiId;
        if (!id) {
            return;
        }

        this._store.dispatch(new EntityDelete(Ppsuivi, id));
    }

    public printService() {
        if (!this._table) {
            return;
        }
        const profile = this._table.loadGridProfile();

        this._printService.print<Ppsuivi>({
            entity: Ppsuivi,
            extraStatements: [EsQueryStatement
                .fromMatch({misId: {query: this.entityId || ''}}, 'filter')],
            format: 'pdf',
            group: profile,
            parameters: {}
        });
    }

    public getSelectedRow(event: Ppsuiviview[]) {
        this._selected = (event && event[0]) || undefined;
        if (this._selected) {
            this.setFormValue('suiId', this._selected.suiId);
            this.setFormValue('notes', this._selected.notes);
            this.setSuiviDescription(this._selected);
        } else {
            this.setFormValue('suiId', '');
            this.setFormValue('notes', '');
            this.suiviDescription = '';
        }
    }

    // eslint-disable-next-line complexity
    private setSuiviDescription(suivi: Ppsuiviview) {
        this.suiviDescription = this._suiviService.getSuiviDescription(suivi);
    }

    private async updateFilters(misId: string) {
        this.query = [{
            operation: RestQueryOperation.Equals, prop: 'misId', value: misId
        }];
    }

    private getMisSuiviColumns(): IwGridColumn<Ppsuiviview>[] {
        return [{
            prop: 'dateaction', name: 'date', type: 'date', index: 0
        }, {
            prop: 'nomconseille', name: 'conseiller', index: 1
        }, {
            prop: 'action', index: 2
        }, {
            prop: 'nomemploye', name: 'employe', index: 3
        }, {
            prop: 'nomclient', name: 'client', index: 4
        }, {
            prop: 'clientcontact', name: 'contact', index: 5
        }];
    }
}
