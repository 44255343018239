<iw-modal-form (pressCancel)="onPressCancel()" (pressOk)="onPressOk()" [height]="300" [title]="'tauxIS' | translate"
               [width]="500">
    <form [formGroup]="formGroup" class="row">
        <div class="row col-xs-12">
            <div class="row col-xs-12">
                <iw-textfield [isDisabled]="true"
                              class="iw-input col-xs-6 padding-bottom-20 padding-top-10 no-padding-left"
                              formControlName="cantontrav"></iw-textfield>

                <iw-textfield [isDisabled]="true"
                              class="iw-input col-xs-6 padding-bottom-20 padding-top-10 no-padding-left"
                              formControlName="baremImp"></iw-textfield>
            </div>
            <div class="row col-xs-12 no-padding-left no-padding-right">
                <div class="row col-xs-6">
                    <iw-textfield [labelAlign]="'top'" [label]="'montantSaeimp' | translate"
                                  class="iw-input col-xs-12 padding-bottom-20 no-padding-left"
                                  formControlName="salannimp"></iw-textfield>
                </div>
                <div class="row col-xs-6">

                    <iw-dropdown (valueSelected)="calculateTaux($event)" [label]="'  '"
                                 [options]="tauxImpotLookUpOptions" class="col-xs-12 no-padding-left"></iw-dropdown>
                </div>
            </div>
            <div class="row col-xs-12 no-padding-left">
                <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'tauxSaeimp' | translate"
                              class="iw-input col-xs-6 padding-bottom-10 no-padding-right"
                              formControlName="tauxImp"></iw-textfield>
            </div>
        </div>
    </form>
</iw-modal-form>
