export class BookingFile {
    public bkFileId?: number;
    public libelle?: string;
    public date?: string;
    public dateDe?: string;
    public dateA?: string;
    public module?: string;
    public extourne?: boolean;
    public nbEcr?: number;
}

export enum BookingFileMenuEvent {
    DOWNLOAD = 'booking_file#donwnload',
}