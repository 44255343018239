<form [formGroup]="formGroup"
      class="row">
    <iw-textfield #txtUser_id
                  [isDisabled]="true"
                  [label]="'attest' | translate"
                  class="iw-input col-xs-2 padding-left-0"
                  formControlName="agiId"></iw-textfield>
    <iw-textfield #txtUser_id
                  [(value)]="empName"
                  [isDisabled]="true"
                  [label]="'employe' | translate"
                  class="iw-input col-xs-4 padding-right-0"></iw-textfield>
    <iw-textfield #txtUser_id
                  [isDisabled]="true"
                  [label]="'' | translate"
                  class="iw-input col-xs-1 no-label padding-left-0"
                  formControlName="empId"></iw-textfield>
    <iw-button #empBtn
               (press)="openEmp()"
               [isDisabled]="false"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>
    <iw-date-picker #txtDatenaiss
                    [isDisabled]="!isWriteMode"
                    [labelAlign]="'top'"
                    [label]="'date' | translate"
                    class="iw-input col-xs-2 col-no-padding"
                    formControlName="dateagi"></iw-date-picker>
    <iw-textfield #txtUser_id
                  [(value)]="mois"
                  [isDisabled]="true"
                  [label]="'mois' | translate"
                  class="iw-input col-xs-2"></iw-textfield>
</form>

<iw-tab [(selectedTab)]="selectedTab"
        [invalidTabs]="invalidTabList"
        [tabs]="tabList">
    <iw-tab-14-aginter *ngIf="selectedTab === tabList[0]"
                       [formGroup]="formGroup"
                       [isReadonly]="!isWriteMode"></iw-tab-14-aginter>

    <iw-tab-57-aginter *ngIf="selectedTab === tabList[1]"
                       [formGroup]="formGroup"
                       [isReadonly]="!isWriteMode"></iw-tab-57-aginter>

    <iw-tab-810-aginter *ngIf="selectedTab === tabList[2]"
                        [formGroup]="formGroup"
                        [isReadonly]="!isWriteMode"></iw-tab-810-aginter>

    <iw-tab-1114-aginter *ngIf="selectedTab === tabList[3]"
                         [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"></iw-tab-1114-aginter>

    <iw-tab-1518-aginter *ngIf="selectedTab === tabList[4]"
                         [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"></iw-tab-1518-aginter>

    <iw-tab-remarques-aginter *ngIf="selectedTab === tabList[5]"
                              [formGroup]="formGroup"
                              [isReadonly]="!isWriteMode"></iw-tab-remarques-aginter>

    <!-- <iw-tab-ppemp-situation
        *ngIf="selectedTab === tabList[1]"
        [isReadonly]="!isWriteMode"
        [formGroup]="formGroup"
      ></iw-tab-ppemp-situation> -->
</iw-tab>
