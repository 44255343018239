export enum CorType {
    dpi = 1, aci = 2,
}

export enum Bareme {
    A0 = 'A0',
    A1 = 'A1',
    A2 = 'A2',
    A3 = 'A3',
    A4 = 'A4',
    B0 = 'B0',
    B1 = 'B1',
    B2 = 'B2',
    B3 = 'B3',
    B4 = 'B4',
    B5 = 'B5',
    D = 'D',
}

export enum AemaCodes {
    entre = '1', mutation = '2', sortie = '3', entre_sortie = '4'
}
