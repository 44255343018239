import {
    Ppheusaid2Output
} from '@shared/widgets/iw-components/iw-hours-grid/iw-hours-grid.model';

/**
 * Method to check format hours in hours grid
 * True if hours is in hours and minutes (1/100)
 * False if hours is in hours (1/60)
 *
 * @param val Output from hours grid
 */
export function checkHoursFormat(val?: Ppheusaid2Output) {
    return val?.timeformat === '1/100';
}
