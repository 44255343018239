export enum CorrModA {
    'selon_coeff' = 1,
    'avs_pure' = 2,
    'avs_exonere' = 3,
    'ac1_pure' = 4,
    'ac2_pure' = 5,
    'req' = 6,
    'alfa' = 7,
    'base_taux_is' = 8,
    'jour_is' = 9
}

export enum CorrModF {
    'is' = 1, 'pgm' = 2, 'cct' = 3, 'laa' = 4
}

export enum CsType {
    'no_coeff' = 1,
    'cot_avs' = 2,
    'cot_prevo' = 3,
    'prime_aanp' = 4,
    'alloc_famil' = 5,
    'indemnites_transport' = 6,
    'indemnties_journ' = 7,
    'pourboires' = 8,
    'prestation_nature' = 9,
    'cadeaus_ancie' = 10,
    'alloc_mariage' = 11,
    'pres_ass' = 12,
    'i_define' = 13,
    'j_define' = 14,
    'maladie_salarie' = 15,
    'l_define' = 16,
    'rachat_prev' = 17,
    'n_define' = 18,
    'representation' = 19,
    'frais_auto' = 20,
    'frais_voyage' = 21,
    'autres_frais' = 22,
}

export enum DebitSplit {
    'aucun' = 1, 'cat_1' = 2, 'cat_2' = 3, 'cat_3' = 4, 'canton_trav' = 5, 'cct' = 6, 'canton_tax' = 7, 'is' = 8
}

export enum BasishMode {
    'ajouter' = 1, 'no_ajouter' = 2
}
