import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Gepaihdr} from '@app/sam-base/models/invoices/gepaihdr';
import {Saep} from '@app/sam-base/models/salary/saep';
import {
    EncaissementAttributionManyCliComponent
} from '../components/encaissement/encaissement-attribution-many-cli.component';
import {EncaissementAttributionComponent} from '../components/encaissement/encaissement-attribution.component';
import {FichePaiementComponent} from '../components/fiche-salaire/tab-paiment/fichePaiement/fichePaiement.component';

export const FICHEPAIEMENT_DEFAULT_FORM: FormDescription<Saep> = {
    form: FichePaiementComponent,
    state: {
        ...buildDefaultState(Saep),
        diagTitle: 'paiement',
        diagModal: false,
        width: 700,
        isNew: true
    }
};

export const FICHEPAIEMENT_ATTRIBUITION_FORM: FormDescription<Gepaihdr> = {
    form: EncaissementAttributionComponent,
    state: {
        ...buildDefaultState(Gepaihdr),
        diagTitle: 'paiement_attribution',
        diagModal: true,
        width: 1000
    }
};

export const FICHEPAIEMENT_ATTRIBUITION_MANY_CLI_FORM: FormDescription<Gepaihdr> = {
    form: EncaissementAttributionManyCliComponent,
    state: {
        ...buildDefaultState(Gepaihdr),
        diagTitle: 'paiement_attribution_many_cli',
        diagModal: true,
        width: 1000
    }
};
