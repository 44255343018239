import {
    SearchEntityDateOperator,
    SearchEntityDateTimeOperator,
    SearchEntityNumberOperator,
    SearchEntityStatusOperator,
    SearchEntityStringOperator,
    SearchExactMatch
} from '@sam-base/core';
import {IwGridFilter} from '@sam-base/models';
import {isObject} from 'lodash';

export class FilterService<T> {
    applyFilters(data: T[], filters: IwGridFilter<T>[]): T[] {
        return data.filter(item =>
            filters.every(filter => this.applyFilter(item, filter))
        );
    }

    private getValueFromItem(item: T, filter: IwGridFilter<T>): any {
        const prop: Extract<keyof T, string | number> = filter.column.prop!;
        if (isObject(item) && prop && prop in item) {
            return item[prop as keyof T];
        }
        return undefined;
    }

    private applyFilter(item: T, filter: IwGridFilter<T>): boolean {
        const value = this.getValueFromItem(item, filter);
        const filterValue = filter.value;

        switch (filter.column.type) {
            case 'string':
            case 'phonenumber':
            case 'keyword':
                return this.applyStringFilter(value, filterValue, filter.operator as SearchEntityStringOperator);
            case 'number':
            case 'mnt':
                return this.applyNumberFilter(value, filterValue, filter.operator as SearchEntityNumberOperator);
            case 'date':
            case 'dateTime':
            case 'dateDebut':
                return this.applyDateFilter(value, filterValue, filter.operator as SearchEntityDateOperator | SearchEntityDateTimeOperator);
            case 'status':
            case 'cliStatus':
            case 'empStatus':
            case 'comStatus':
            case 'misStatus':
            case 'rapMisStatus':
            case 'misStatusRap':
                return this.applyStatusFilter(value, filterValue, filter.operator as SearchEntityStatusOperator);
            case 'boolean':
                return this.applyBooleanFilter(value, filterValue);
            case 'enum':
            case 'cdeType':
                return this.applyEnumFilter(value, filterValue, filter.operator as SearchExactMatch);
            case 'translate':
            case 'docTranslate':
                return this.applyTranslateFilter(value, filterValue, filter.operator as SearchEntityStringOperator);
            case 'weekday':
                return this.applyWeekdayFilter(value, filterValue);
            case 'timeslot':
                return this.applyTimeslotFilter(value, filterValue);
            default:
                console.warn(`Filter not implemented for type: ${filter.column.type}`);
                return true;
        }
    }

    private applyStringFilter(value: string, filterValue: string,
                              operator: SearchEntityStringOperator | SearchExactMatch): boolean {
        switch (operator) {
            case SearchEntityStringOperator.Like:
                return value.toLowerCase().includes(filterValue.toLowerCase());
            case SearchEntityStringOperator.NotLike:
                return !value.toLowerCase().includes(filterValue.toLowerCase());
            case SearchExactMatch.Equals:
                return value.toLowerCase() === filterValue.toLowerCase();
            case SearchExactMatch.Or:
                const values = filterValue.split(',').map(v => v.trim().toLowerCase());
                return values.includes(value.toLowerCase());
            default:
                return true;
        }
    }

    private applyNumberFilter(value: number, filterValue: number | [number, number],
                              operator: SearchEntityNumberOperator): boolean {
        switch (operator) {
            case SearchEntityNumberOperator.GreaterThan:
                return value > (filterValue as number);
            case SearchEntityNumberOperator.LessThan:
                return value < (filterValue as number);
            case SearchEntityNumberOperator.EqualsTo:
                return value === filterValue;
            case SearchEntityNumberOperator.Between:
                const [min, max] = filterValue as [number, number];
                return value >= min && value <= max;
            case SearchEntityNumberOperator.NotBetween:
                const [notMin, notMax] = filterValue as [number, number];
                return value < notMin || value > notMax;
            default:
                return true;
        }
    }

    private applyDateFilter(value: string, filterValue: string | [string, string],
                            operator: SearchEntityDateOperator | SearchEntityDateTimeOperator): boolean {
        const dateValue = new Date(value);

        if (!this.isValidDate(dateValue)) {
            console.warn(`Invalid date value: ${value}`);
            return false;
        }

        switch (operator) {
            case SearchEntityDateOperator.Before:
                return dateValue < new Date(filterValue as string);
            case SearchEntityDateOperator.After:
                return dateValue > new Date(filterValue as string);
            case SearchEntityDateOperator.On:
            case SearchEntityDateTimeOperator.On:
                const onDate = new Date(filterValue as string);
                return this.isSameDay(dateValue, onDate);
            case SearchEntityDateTimeOperator.Between:
                const [start, end] = (filterValue as [string, string]).map(d => new Date(d));
                return dateValue >= start && dateValue <= end;
            case SearchEntityDateTimeOperator.NotBetween:
                const [notStart, notEnd] = (filterValue as [string, string]).map(d => new Date(d));
                return dateValue < notStart || dateValue > notEnd;
            case SearchEntityDateTimeOperator.NotOn:
                const notOnDate = new Date(filterValue as string);
                return !this.isSameDay(dateValue, notOnDate);
            default:
                return true;
        }
    }

    private isValidDate(date: Date): boolean {
        return !isNaN(date.getTime());
    }

    private isSameDay(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    private applyStatusFilter(value: any, filterValue: any, operator: SearchEntityStatusOperator): boolean {
        switch (operator) {
            case SearchEntityStatusOperator.NotInclude:
                return !filterValue.includes(value);
            default:
                return filterValue.includes(value);
        }
    }

    private applyBooleanFilter(value: boolean, filterValue: boolean): boolean {
        return Boolean(value) === Boolean(filterValue);
    }

    private applyEnumFilter(value: string, filterValue: string, operator: SearchExactMatch): boolean {
        switch (operator) {
            case SearchExactMatch.Equals:
                return value === filterValue;
            case SearchExactMatch.Or:
                const values = filterValue.split(',').map(v => v.trim());
                return values.includes(value);
            default:
                return true;
        }
    }

    private applyTranslateFilter(value: string, filterValue: string, operator: SearchEntityStringOperator): boolean {
        // Assuming translated values are strings, we can use the string filter logic
        return this.applyStringFilter(value, filterValue, operator);
    }

    private applyWeekdayFilter(value: number, filterValue: number | number[]): boolean {
        if (Array.isArray(filterValue)) {
            return filterValue.includes(value);
        }
        return value === filterValue;
    }

    private applyTimeslotFilter(value: string, filterValue: string | string[]): boolean {
        if (Array.isArray(filterValue)) {
            return filterValue.includes(value);
        }
        return value === filterValue;
    }

}