import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppoptsal} from '@app/sam-base/models/placement';

import {RapLibMode} from '../../salary.model';

@Component({
    selector: 'iw-tab-salary-autre', templateUrl: './tab-salary-autre.component.html'
})
export class TabSalaryAutreComponent extends BasePartialFormComponent<Ppoptsal> {

    public raplibmode = RapLibMode;

    constructor() {
        super();
    }
}
