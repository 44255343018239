<div class="flex flex-v"
     id="periodeSideMenu">
    <iw-button #btnOuvrir
               (press)="openPeriod()"
               *iwAccessGroups="SALARY_PERIOD_ADMIN_ROLES"
               [isDisabled]="isPeriodOpen"
               [text]="'ouvrir' | translate"
               class="iw-button btn-block padding-bottom-10"></iw-button>
    <iw-button #btnCloture
               (press)="closePeriode()"
               *iwAccessGroups="SALARY_PERIOD_ADMIN_ROLES"
               [isDisabled]="!isPeriodOpen"
               [text]="'cloture' | translate"
               class="iw-button btn-block padding-bottom-10"></iw-button>
    <iw-button #btnComptabilite
               (press)="openComptabilite()"
               [isDisabled]="isPeriodOpen"
               *iwAccessGroups="ACCOUNTING_ROLES"
               [text]="'comptabilite' | translate"
               class="iw-button btn-block padding-bottom-10"
               style="margin-bottom: 30px;"></iw-button>

    <iw-button #btnGenerer
               (press)="openGenerate()"
               [isDisabled]="!isPeriodOpen"
               *iwAccessGroups="SALARY_ADMIN_ROLES"
               [text]="'generer' | translate"
               class="iw-button btn-block padding-bottom-10"></iw-button>
    <iw-button #btnCalculer
               (press)="onCalculate()"
               [isDisabled]="!isPeriodOpen"
               *iwAccessGroups="SALARY_ADMIN_ROLES"
               [text]="'calculer' | translate"
               class="iw-button btn-block padding-bottom-10"></iw-button>
    <iw-button #btnCalculerForce
               (press)="onCalculateForce()"
               [isDisabled]="!isPeriodOpen"
               *iwAccessGroups="SALARY_ADMIN_ROLES"
               [text]="'calculer_force' | translate"
               class="iw-button btn-block padding-bottom-10"></iw-button>
    <iw-button #btnPublish
               (press)="openPublish()"
               [text]="'Publish' | translate"
               *iwAccessGroups="SALARY_ADMIN_ROLES"
               class="iw-button btn-block padding-bottom-10"></iw-button>
    <iw-button #btnPublish
               (press)="openForcePublish()"
               [text]="'publish_force' | translate"
               *iwAccessGroups="SALARY_ADMIN_ROLES"
               class="iw-button btn-block padding-bottom-10"></iw-button>
    <iw-button #btnMail
               (press)="sendByEmail()"
               [text]="'send_email' | translate"
               *iwAccessGroups="SALARY_COMMUNICATION_ROLES"
               class="iw-button btn-block padding-bottom-10"
               style="margin-bottom: 30px;"></iw-button>


    <!--
    <iw-button #btnProvisions
               [isDisabled]="true"
               [text]="'provisions' | translate"
               class="iw-button btn-block padding-bottom-10"></iw-button>
    -->
</div>
