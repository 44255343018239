import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppcon} from '@app/sam-base/models/placement';

import {PpconComponent} from '../components/conseillers/ppcon.component';

export const PPCON_DEFAULT_FORM: FormDescription<Ppcon> = {
    form: PpconComponent, state: {
        ...buildDefaultState(Ppcon),
        diagTitle: 'conseillers',
        editMode: 'read',
        useNavigation: false,
        patch: false,
        width: 1100,
        isNew: true
    }
};
