<form [formGroup]="formGroup" class="row">
    <div class="row space-form col-xs-9">
        <iw-rest-grid #saeioConfigGrid (selected)="getSelectedRow($event)" [autoFormClick]="false"
                      [defaultSelected]="true" [hasGridColumnMenu]="false" [hasSortIcon]="false"
                      [innerWidth]="tableWidth" [isDisabled]="!readonly" [queryStatements]="query"
                      [sortDir]="sortPropDir" [sortProp]="sortProp" [type]="type" class="col-xs-12"
                      style="height: 300px"></iw-rest-grid>

        <iw-date-picker #txtDateIn [isDisabled]="readonly" [labelAlign]="'top'" [label]="'entree' | translate"
                        [minDate]="minDateDateIn" class="iw-input col-xs-4 padding-top-10"
                        formControlName="dateIn"></iw-date-picker>
        <div class="col-xs-1"></div>
        <iw-date-picker #txtDateOut [isDisabled]="readonly" [labelAlign]="'top'" [label]="'sortie' | translate"
                        [minDate]="minDateDateOut" class="iw-input col-xs-4 padding-top-10"
                        formControlName="dateOut"></iw-date-picker>
        <div class="col-xs-3"></div>

        <iw-dropdown #txtMotif [isDisabled]="readonly && dateOut" [labelAlign]="'top'"
                     [label]="'motifSortie' | translate" [options]="motifOptions"
                     class="iw-input col-xs-6 padding-top-10" formControlName="motif"></iw-dropdown>
    </div>

    <div class="col-xs-3 flex flex-v separator-left">
        <iw-button #btnNew (press)="createNewEntry()" *ngIf="readonly" [isDisabled]="!readonly || !isNewButtonEnable"
                   [text]="'sidemenu_new' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnModif (press)="modifyEntry()" *ngIf="readonly"
                   [isDisabled]="!readonly || !selectedRow || !canEdit" [text]="'sidemenu_edit' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnSave (press)="saveEntry()" *ngIf="!readonly" [isDisabled]="!formGroup.valid"
                   [text]="'sidemenu_save' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnDel (press)="removeEntry()" [isDisabled]="!canEdit || !selectedRow"
                   [text]="'sidemenu_delete' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>
        <iw-button #btnCancel (press)="cancelEditionMode()" *ngIf="!readonly" [text]="'sidemenu_cancel' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnClose (press)="closeDialog()" [text]="'fermer' | translate" class="iw-button btn-block"
                   style="margin-block-start: auto"></iw-button>
    </div>
</form>
