import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Gepaicond} from '@app/sam-base/models/invoices/gepaicond';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class GepaicondService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getAll(): Observable<Gepaicond[]> {
        return this._http.get<Gepaicond[]>(this._baseUrl + `gepaicond/getAll`);
    }

    public save(gepaicond?: Gepaicond): Observable<void> {
        return this._http.post<void>(this._baseUrl + `gepaicond/save`, gepaicond);
    }

    public delete(paicondId?: string): Observable<void> {
        return this._http.delete<void>(this._baseUrl + `gepaicond/delete/${paicondId}`);
    }

}
