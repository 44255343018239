<form [formGroup]="formGroup"
      class="row">

    <iw-sacs06def-dropdown #cs06type1
                           [isDisabled]="isReadonly || !enableCsctype"
                           [labelAlign]="'left'"
                           [label]="'ncs' | translate"
                           [types]="[]"
                           class="iw-input col-xs-12 padding-bottom-5"
                           formControlName="cs06Type"
                           id="cs06type1"></iw-sacs06def-dropdown>

    <iw-sacs06def-dropdown #cs06type2
                           [isDisabled]="isReadonly || !enableCsctype"
                           [labelAlign]="'left'"
                           [label]="'ncs2' | translate"
                           [types]="['MNT_15']"
                           class="iw-input col-xs-12 padding-bottom-5"
                           formControlName="cs06Type2"
                           id="cs06type2"></iw-sacs06def-dropdown>

    <iw-enum-dropdown #droitcaf
                      [inputEnum]="droitCaf"
                      [isDisabled]="isReadonly || !enableCsctype"
                      [labelAlign]="'left'"
                      [label]="'caf' | translate"
                      class="iw-input col-xs-12 padding-bottom-20"
                      formControlName="sdsCaf"></iw-enum-dropdown>

    <div class="row col-xs-12 separator-top no-padding no-margin">
        <div class="row col-xs-6 separator-right no-padding flex-center no-margin">
            <div class="iw-label"
                 title="'debit' | translate">{{ 'debit' | translate }}
            </div>
        </div>
        <div class="row col-xs-6 no-padding flex-center no-margin">
            <div class="iw-label"
                 title="'credit' | translate">{{ 'credit' | translate }}
            </div>
        </div>
    </div>
    <div class="row col-xs-6 separator-right separator-top no-padding no-margin">
        <div class="iw-label col-xs-4 padding-top-5"
             title="'compte' | translate">{{ 'compte' | translate }}
        </div>
        <iw-textfield-compte #txtCompteDebit
                             [isDisabled]="isReadonly || !enableDebitCredit"
                             class="iw-input col-xs-8 padding-top-5"
                             formControlName="cptdebit"></iw-textfield-compte>
        <div class="iw-label col-xs-4 padding-top-5"
             title="'repartition' | translate">{{ 'repartition' | translate }}
        </div>
        <iw-enum-dropdown #debitSplit
                          [inputEnum]="debitCreditSplitOptions"
                          [isDisabled]="isReadonly || !enableDebitCredit"
                          class="iw-input col-xs-8 padding-bottom-5 padding-top-5"
                          formControlName="debitsplit"></iw-enum-dropdown>
        <iw-button (press)="openGscpt(1)"
                   [isDisabled]="isReadonly || !enableSplitDebit"
                   class="col-xs-8 col-xs-offset-4"
                   id="btnSplitDebit"
                   text="..."></iw-button>
        <iw-checkbox #detdebit
                     [isDisabled]="isReadonly || !enableDebitCredit"
                     [label]="'detail' | translate"
                     class="iw-input col-xs-8 col-xs-offset-4 padding-top-5"
                     formControlName="detdebit"></iw-checkbox>
    </div>
    <div class="row col-xs-6 no-padding separator-top no-margin flex-center">
        <iw-textfield-compte #txtCompteCredit
                             [isDisabled]="isReadonly || !enableDebitCredit"
                             class="iw-input col-xs-8 padding-top-5"
                             formControlName="cptcredit"></iw-textfield-compte>
        <iw-enum-dropdown #creditSplit
                          [inputEnum]="debitCreditSplitOptions"
                          [isDisabled]="isReadonly || !enableDebitCredit"
                          [size]="'small'"
                          class="iw-input col-xs-8 padding-bottom-5 padding-top-5"
                          formControlName="creditsplit"></iw-enum-dropdown>
        <iw-button (press)="openGscpt(2)"
                   [isDisabled]="isReadonly || !enableSplitCredit"
                   class="col-xs-8"
                   id="btnSplitCredit"
                   text="..."></iw-button>
        <iw-checkbox #detcredit
                     [isDisabled]="isReadonly || !enableDebitCredit"
                     [label]="'detail' | translate"
                     class="iw-input col-xs-8 padding-top-5"
                     formControlName="detcredit"></iw-checkbox>
    </div>
    <div class="row col-xs-12 no-padding separator-top flex-center">
        <iw-checkbox #analytique
                     [isDisabled]="isReadonly || !enableDebitCredit"
                     [label]="'repart_analityque' | translate"
                     class="iw-input col-xs-4 padding-top-10"
                     formControlName="analytique"></iw-checkbox>
    </div>
    <div class="row col-xs-12 no-padding separator-top">
        <iw-enum-dropdown #debitSplit
                          [inputEnum]="basishModeOptions"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'base_is_horaire' | translate"
                          class="iw-input col-xs-12 padding-top-10"
                          formControlName="basishmode"></iw-enum-dropdown>
    </div>

</form>
