<form [formGroup]="formGroup"
      class="row">
    <iw-enum-dropdown #cboPaytype
                      [inputEnum]="paysType"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'paiement' | translate"
                      class="iw-input col-xs-12"
                      formControlName="paytype"></iw-enum-dropdown>

    <iw-enum-dropdown #cboDtaType
                      [inputEnum]="dtaType"
                      [isDisabled]="!checkDtaTypeEnabled()"
                      [labelAlign]="'left'"
                      [label]="'dtaOpae' | translate"
                      class="iw-input col-xs-12 padding-top-10"
                      formControlName="dtatype"></iw-enum-dropdown>

    <iw-textfield-bank #txtBank
                       (selected)="onSelected($event)"
                       [isDisabled]="!checkFieldsEnabled([3], [1])"
                       [labelAlign]="'left'"
                       [label]="'banque' | translate"
                       class="iw-input col-xs-10 padding-top-10"
                       formControlName="banque"></iw-textfield-bank>

    <iw-button #btnLkupClr
               (press)="clearingsClick()"
               [isDisabled]="!checkFieldsEnabled([3], [1])"
               [text]="'TEXT' | translate"
               class="iw-button col-xs-1 padding-top-10"
               id="bank-button"></iw-button>

    <iw-textfield #txtBanque_adr
                  [isDisabled]="!checkFieldsEnabled([3], [1])"
                  [labelAlign]="'left'"
                  [label]="' '"
                  class="iw-input col-xs-12 padding-top-10"
                  formControlName="banqueAdr"></iw-textfield>

    <iw-textfield #txtBanque_clr
                  [isDisabled]="!checkFieldsEnabled([3], [1])"
                  [labelAlign]="'left'"
                  [label]="'clearing' | translate"
                  class="iw-input col-xs-12 padding-top-10"
                  formControlName="banqueClr"></iw-textfield>

    <iw-textfield #txtBanque_swi
                  [isDisabled]="!checkFieldsEnabled([3], [5])"
                  [labelAlign]="'left'"
                  [label]="'swift' | translate"
                  class="iw-input col-xs-12 padding-top-10"
                  formControlName="banqueSwi"></iw-textfield>

    <iw-textfield #txtIBAN
                  [isDisabled]="!checkFieldsEnabled([3], [5])"
                  [labelAlign]="'left'"
                  [label]="'iban' | translate"
                  [warningMessage]="'ibanWarning' | translate"
                  class="iw-input col-xs-12 padding-top-10"
                  formControlName="iban"
                  mask="SS00 AAAA AAAA AAAA AAAA AAAA AAAA AAAA AA"></iw-textfield>

    <iw-textfield #txtBanque_cpt
                  [isDisabled]="!checkFieldsEnabled([3], [1,2])"
                  [labelAlign]="'left'"
                  [label]="'compte' | translate"
                  class="iw-input col-xs-12 padding-top-10"
                  formControlName="banqueCpt"></iw-textfield>

    <iw-checkbox [label]="'paiement_bloque' | translate"
                 class="iw-checkbox col-xs-offset-2 padding-top-10"
                 formControlName="paybloque"></iw-checkbox>

    <!--  FIELDS FOR PAIEMENT AFTER DEATH -->

    <iw-checkbox [isDisabled]="isReadonly"
                 [label]="'paiement_tiers_suite_deces' | translate"
                 class="iw-checkbox padding-top-10 col-xs-6"
                 formControlName="payadoth"></iw-checkbox>

    <ng-container *ngIf="payadoth">
        <iw-dropdown [isDisabled]="isReadonly"
                     [labelAlign]="'left'"
                     [label]="'depuis' | translate"
                     [options]="paiementSalIdOptions"
                     class="iw-input col-xs-12 padding-top-10"
                     formControlName="payfromoth"></iw-dropdown>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'nom' | translate"
                      class="iw-input col-xs-12 padding-top-10"
                      formControlName="paynomeoth"></iw-textfield>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'prenom' | translate"
                      class="iw-input col-xs-12 padding-top-10"
                      formControlName="payprenomoth"></iw-textfield>

        <iw-textfield #txtAdresse1
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'adresse' | translate"
                      class="iw-input col-xs-12 padding-top-10"
                      formControlName="payadroth"></iw-textfield>
        <iw-npa-lieu [formGroup]="formGroup"
                     [isDisabled]="isReadonly"
                     [lieuFormControlName]="'paylieuoth'"
                     [npaFormControlName]="'paynpaoth'"
                     [pays]="paypaysoth"
                     class="iw-input col-xs-12 padding-top-10"></iw-npa-lieu>


        <iw-pays-dropdown [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'pays' | translate"
                          class="iw-input col-xs-9 padding-top-10"
                          formControlName="paypaysoth"></iw-pays-dropdown>
        <button (click)="debug()">DEBUG</button>
    </ng-container>

</form>
