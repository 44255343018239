import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Gecompta} from '@sam-base/models/invoices/gecompta';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class GecomptaService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public init(salId: string): Observable<Gecompta> {
        return this._http.get<any>(this._baseUrl + `gecompta/init/${salId}`);
    }

    // public prepare(gecompta: Gecompta): Observable<Gecompta> {
    //     return this._http.post<Gecompta>(this._baseUrl + `gecompta/prepare`, gecompta);
    // }

    public transfert(gecompta: Gecompta): Observable<any> {
        return this._http.post<any>(this._baseUrl + `gecompta/transfer`, gecompta);
    }

    public cancel(gecompta: Gecompta): Observable<any> {
        return this._http.post<any>(this._baseUrl + `gecompta/cancel`, gecompta);
    }
}
