import {Component, Input, Type} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';

import {SalaryForm} from '@app/modules/sam-main/salary/salary.forms';
import {BaseFormComponent} from '@app/sam-base/base';
import {ClipboardService} from '@app/sam-base/core/clipboard/clipboard.service';

import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {EntityDelete, IwStoreService} from '@app/sam-base/core/store';
import {FormKeys, IwGridColumn} from '@app/sam-base/models';

import {Gecliatt} from '@app/sam-base/models/invoices/gecliatt';
import {CommunicationType} from '@core/mail/mail.models';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import {TabContacts} from './tab-contacts.model';

@Component({
    selector: 'iw-tab-contacts',
    templateUrl: './tab-contacts.component.html'
})
export class TabContactsComponent extends BaseFormComponent<TabContacts> {

    public type: Type<Gecliatt> = Gecliatt;

    @Input() public isReadonly = true;
    public cliattId?: string;
    public contact = '';
    public query: RestQueryParam<Gecliatt, string>[] = [];
    public columns = this.getColumns();
    private readonly _entityService: RestEntityClient<Gecliatt>;

    constructor(private readonly _clipboardService: ClipboardService, private readonly _store: IwStoreService,
                private readonly _modalService: ModalService,
                private readonly _formHandler: FormHandlerService<SalaryForm>, restService: RestApiService) {
        super();
        this._entityService = restService.getEntityClient(Gecliatt);
    }

    private _entityId?: string;

    public get entityId() {
        return this._entityId;
    }

    @Input()
    public set entityId(v: string | undefined) {
        this.setEntityId(v);
        this.filterById(v);
    }

    public newContactClient() {
        if (this.entityId) {
            this._formHandler.showFormDialog(SalaryForm.ContactNew, {cliId: this.entityId});
        }
    }

    public editContactClient() {
        if (this.entityId && this.cliattId) {
            const entityId = this.cliattId;
            this._formHandler.showFormDialog(SalaryForm.ContactEdit, {cliId: this.entityId}, s => ({
                ...s,
                entityId
            }));
        }
    }

    public removeContactClient() {
        if (this.cliattId) {
            this._store.dispatch(new EntityDelete(Gecliatt, this.cliattId));
        }
    }

    public sendEmail() {
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.GECLIATT_GENERIC,
            entityIds: [this.cliattId ?? ''],
        });
    }

    public addToClipboard() {
        const email: string = this.getFormValue('email') || '';
        this._clipboardService.addToClipboard(email);
    }

    public getSelectedRow(event: Gecliatt[]) {
        if (!event || !event.length) {
            return;
        }
        this.cliattId = (event[0] && event[0].cliattId) || '';
        this.contact = this.builContact(event[0]);
        this.fillFormData(<any>event[0]);
    }

    // eslint-disable-next-line complexity
    public builContact(c: Gecliatt): string {
        if (!c) {
            return '';
        }

        let contact = '';

        if (c.nom || c.prenom) {
            contact += `${c.nom || ''} ${c.prenom || ''}\n`;
        }
        if (c.dept) {
            contact += `${c.dept}\n`;
        }
        if (c.tel) {
            contact += `Tel: ${c.tel}\n`;
        }
        if (c.fax) {
            contact += `Fax: ${c.fax}\n`;
        }
        if (c.email) {
            contact += `Email: ${c.email}\n`;
        }
        if (c.dateanniv) {
            contact += `Anniversaire: ${IwDateHelper.dateFormatFromString(c.dateanniv, 'DD.MM.YYYY')}\n`;
        }
        if (c.remarques) {
            contact += ` ${c.remarques}`;
        }

        return contact;
    }

    protected getFormControlNames(): FormKeys<TabContacts> {
        return [
            'tel',
            'fax',
            [
                'natel',
                new UntypedFormControl('', [Validators.pattern(/^\+(?:[0-9]{1,3} ?)[0-9]{6,14}$/)])],
            'email'];
    }

    private setEntityId(v?: string) {
        if (v && this._entityId !== v) {
            this._entityId = v;
        }
    }

    private filterById(cliId?: string) {
        this.query = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'cliId',
                value: cliId || '_'
            }];
    }

    private getColumns(): IwGridColumn<Gecliatt>[] {
        return [
            {
                prop: 'nomcomplet',
                index: 0
            },
            {
                prop: 'dept',
                name: 'departement',
                index: 1
            },
            {
                prop: 'fonction',
                name: 'fonction',
                index: 2
            }];
    }
}
