import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('saempded')
export class Saempded {
    @IwRestEntityId() public edId?: string;
    public empId?: string;
    public dedCode?: string;
    public soumiscode?: number;
    public txCode?: string;
    public txCode2?: string;
    public txManuel?: number;
    public cuscode1?: number;
    public cuscode2?: number;
    public cuscode3?: number;
    public taux1?: number;
    public taux2?: number;
    public edData?: string;
}
