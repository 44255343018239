<form [formGroup]="formGroup" class="row">
    <div class="row space-form col-xs-12">
        <iw-sasd4emitype-dropdown #tax_source [isDisabled]="!isNew || isReadonly" [labelAlign]="'top'"
                                  [label]="'type' | translate" class="iw-input col-xs-5" formControlName="emiType"
                                  id="tax_source"></iw-sasd4emitype-dropdown>
        <iw-dropdown [isDisabled]="!isNew || isReadonly" [labelAlign]="'top'" [label]="'mois_correction' | translate"
                     [options]="optionSalIdCor" class="iw-input col-xs-3" formControlName="salIdCor"></iw-dropdown>
        <iw-enum-dropdown [inputEnum]="corType" [isDisabled]="!isNew || isReadonly" [labelAlign]="'top'"
                          [label]="'dpi_aci' | translate" class="iw-input col-xs-2"
                          formControlName="corType"></iw-enum-dropdown>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'' | translate"
                      class="iw-input col-xs-2 padding-top-10 no-label" formControlName="salId"></iw-textfield>
        <div class="col-xs-offset-8 col-xs-2 emiNumber">EMI#</div>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'' | translate"
                      class="iw-input col-xs-2 padding-top-10" formControlName="emiId"></iw-textfield>
    </div>
    <div class="col-xs-12 flex flex-center no-padding margin-top-10 margin-left-5 margin-bottom-10">
        <hr class="header-line col-xs-1">
        <div class="header-line-color" style="line-height: 1.5em;">{{ 'ancien' | translate }}
        </div>
        <hr class="header-line">
    </div>
    <div class="row space-form col-xs-12">
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'salIdCor' | translate"
                      class="iw-input col-xs-2 padding-top-10 no-label" formControlName="salIdCor"></iw-textfield>
        <div class="col-xs-10"></div>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'' | translate"
                      class="iw-input col-xs-1 padding-top-10 no-label" formControlName="cantonOld"></iw-textfield>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'' | translate"
                      class="iw-input col-xs-1 padding-top-10 no-label" formControlName="barc3Old"></iw-textfield>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'' | translate"
                      class="iw-input col-xs-2 padding-top-10 no-label" formControlName="mntsalOld"></iw-textfield>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'deduction' | translate"
                      class="iw-input col-xs-2" formControlName="mntdedOld"></iw-textfield>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'taux' | translate" class="iw-input col-xs-2"
                      formControlName="tauxOld"></iw-textfield>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'' | translate"
                      class="iw-input col-xs-2 padding-top-10 no-label" formControlName="esparOld"></iw-textfield>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'' | translate"
                      class="iw-input col-xs-2 padding-top-10 no-label" formControlName="cat1Old"></iw-textfield>
    </div>
    <div class="col-xs-12 flex flex-center no-padding margin-top-10 margin-left-5 margin-bottom-10">
        <hr class="header-line col-xs-1">
        <div class="header-line-color" style="line-height: 1.5em;">{{ 'nouveau' | translate }}
        </div>
        <hr class="header-line">
    </div>
    <div class="row space-form col-xs-12">
        <iw-canton-dropdown #drpCodeImp (valueChange)="changeCanton($event)" [isDisabled]="isReadonly || !cantonEnabled"
                            [labelAlign]="'top'" [label]="'canton' | translate" class="iw-input col-xs-3"
                            formControlName="cantonimp"></iw-canton-dropdown>
        <iw-dropdown (valueChange)="changeBareme($event)" [isDisabled]="isReadonly" [labelAlign]="'top'"
                     [label]="'' | translate" [options]="baremes" [value]="baremeVal"
                     class="iw-input col-xs-2 no-label"></iw-dropdown>
        <iw-checkbox #chkActif (valueChange)="changeAthee($event)" [isDisabled]="isReadonly || !atheeEnabled"
                     [label]="'athee' | translate" [value]="athee"
                     class="iw-input col-xs-2 padding-top-25 margin-top-10"></iw-checkbox>
        <iw-agency-combo-box #comboAgence (valueChange)="motifChange()" [isDisabled]="isReadonly || !agenceEnabled"
                             [labelAlign]="'top'" [label]="'agence' | translate" class="iw-input col-xs-3"
                             formControlName="categorie1"></iw-agency-combo-box>

        <!--      <iw-dropdown-->
        <!--            #txtMotif-->
        <!--            class="iw-input col-xs-3"-->
        <!--            [label]="'motifSortie' | translate"-->
        <!--            [labelAlign]="'top'"-->
        <!--            formControlName="categorie1"-->
        <!--            [options]="motifOptions"-->
        <!--            [isDisabled]="fieldDisabled('1359')"-->
        <!--            (valueChange)="motifChange()"-->
        <!--        ></iw-dropdown>-->
        <iw-textfield [(value)]="categorie1Txt" [isDisabled]="true" [labelAlign]="'top'" [label]="'' | translate"
                      class="iw-input col-xs-2 padding-top-10 no-label"></iw-textfield>
        <iw-sags-dropdown #dropSags [isDisabled]="isReadonly || !genreSalaireEnabled" [labelAlign]="'top'"
                          [label]="'genreSalaire' | translate" [types]="['1']" class="iw-input col-xs-6"
                          formControlName="gsId"></iw-sags-dropdown>
        <div class="col-xs-6"></div>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'' | translate"
                      class="iw-input col-xs-1 padding-top-10 no-label" formControlName="cantonimp"></iw-textfield>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'' | translate"
                      class="iw-input col-xs-1 padding-top-10 no-label" formControlName="barc3"></iw-textfield>
        <iw-textfield [isDisabled]="isReadonly || !mntSalEnabled" [labelAlign]="'top'" [label]="' ' | translate"
                      class="iw-input col-xs-2" formControlName="mntSal"></iw-textfield>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'deduction' | translate"
                      class="iw-input col-xs-2" formControlName="mntDed"></iw-textfield>
        <iw-textfield [isDisabled]="isReadonly || !tauxEnabled" [labelAlign]="'top'" [label]="'taux' | translate"
                      class="iw-input col-xs-2" formControlName="taux"></iw-textfield>
        <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'' | translate"
                      class="iw-input col-xs-3 padding-top-10 no-label" formControlName="esparent"></iw-textfield>
    </div>
    <div class="col-xs-12 flex flex-center no-padding margin-top-10 margin-left-5 margin-bottom-10">
        <hr class="header-line col-xs-1">
        <hr class="header-line">
    </div>
    <div class="row col-xs-12">
        <iw-checkbox #chkPayAfterLeave (valueChange)="onPayAfterLeaveChange($event)" [isDisabled]="isReadonly"
                     [label]="'payAfterLeave' | translate" class="iw-input col-xs-6 margin-top-10"
                     formControlName="payAfterLeave"></iw-checkbox>
        <div class="col-xs-8"></div>
        <iw-enum-dropdown [inputEnum]="aemaCode" [isDisabled]="isReadonly || !emaCodeEnabled" [labelAlign]="'top'"
                          [label]="'ema_code' | translate" class="iw-input col-xs-6"
                          formControlName="emaCode"></iw-enum-dropdown>
        <div class="col-xs-8"></div>
        <iw-textfield [isDisabled]="true" [label]="'motif' | translate" [tooltipPosition]="'right'" [value]="motif"
                      class="iw-input col-xs-10 no-padding-right" labelAlign="top" size="small"></iw-textfield>
        <iw-button #btnEnf (press)="onBtnCodeEmaReasonClick()" [isDisabled]="isReadonly || !emaReasonEnabled"
                   [tooltip]="'motif' | translate" class="iw-button col-xs-1 padding-top-25 no-padding-left"
                   style="margin-bottom: 4px;" text="..."></iw-button>
        <iw-date-picker [isDisabled]="!isWriteMode || payAfterLeave" [labelAlign]="'top'" [label]="'date' | translate"
                        [maxDate]="maxEmaDate" class="col-xs-2" formControlName="emaDate"></iw-date-picker>
    </div>
</form>
