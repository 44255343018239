<iw-modal-form [btnCancel]="false" [btnOk]="false" [height]="450" [title]="''" [width]="700">
    <form [formGroup]="formGroup">
        <div class="row col-xs-12">
            <div class="col-xs-12">

                <div class="row space-form">

                    <iw-textfield #txtAdrlib [isDisabled]="!isWriteMode" [labelAlign]="'left'"
                                  [label]="'description' | translate" class="iw-input col-xs-8"
                                  formControlName="adrlib"></iw-textfield>

                    <iw-textfield #txtCliAdr_id [isDisabled]="true" class="iw-input col-xs-4"
                                  formControlName="cliadrId"></iw-textfield>

                    <iw-textfield #txtPrefixe [isDisabled]="!isWriteMode" [labelAlign]="'left'"
                                  [label]="'prefixe' | translate" class="iw-input col-xs-12"
                                  formControlName="prefixe"></iw-textfield>

                    <iw-textfield #txtNom [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'nom' | translate"
                                  class="iw-input col-xs-12" formControlName="nom"></iw-textfield>

                    <iw-textfield #txtAdresse1 [isDisabled]="!isWriteMode" [labelAlign]="'left'"
                                  [label]="'adresse' | translate" class="iw-input col-xs-12"
                                  formControlName="adresse1"></iw-textfield>

                    <iw-textfield #txtAdresse2 [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="' '"
                                  class="iw-input col-xs-12" formControlName="adresse2"></iw-textfield>

                    <iw-textfield #txtAdresse3 [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="' '"
                                  class="iw-input col-xs-12" formControlName="adresse3"></iw-textfield>

                    <iw-npa-lieu [formGroup]="formGroup" [isDisabled]="!isWriteMode" [labelNpaAlign]="'left'"
                                 [lieuFormControlName]="'lieu'" [npaFormControlName]="'npa'" [pays]="pays"
                                 class="iw-input col-xs-12"></iw-npa-lieu>

                    <iw-pays-dropdown #cboPays [isDisabled]="!isWriteMode" [labelAlign]="'left'"
                                      [label]="'pays' | translate" [width]="'100%'"
                                      class="iw-input col-xs-12 col-no-padding"
                                      formControlName="pays"></iw-pays-dropdown>
                </div>
            </div>
        </div>

        <div class="row between-xs col-xs-12">
            <div class="row col-xs-2">
                <iw-button #Closebutton1 (press)="closeDialog()" [text]="'annuler' | translate"
                           class="iw-button btn-block"></iw-button>
            </div>
            <div class="row col-xs-2">
                <iw-button #btnOk (press)="saveChanges()" [text]="'enregistrer' | translate"
                           class="iw-button btn-block"></iw-button>
            </div>
        </div>

    </form>
</iw-modal-form>
