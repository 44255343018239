import {Component, Input, OnInit, Type, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {FormHandlerService} from '@app/sam-base/core';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {GeclisuiService, GeclisuiSubscrition} from '@app/sam-base/core/services/geclisui.service';
import {UuidService} from '@app/sam-base/core/uuid/uuid.service';
import {Gecli} from '@app/sam-base/models/invoices';
import {Geclisui} from '@app/sam-base/models/invoices/geclisui';
import {Subject, takeUntil} from 'rxjs';
import {SalaryForm} from '../../../salary.forms';

@Component({
    selector: 'iw-tab-suivi', templateUrl: './tab-client-suivi.component.html'
})
export class TabClientSuiviComponent extends BasePartialFormComponent<Gecli> implements OnInit {

    @ViewChild('geclisuiGrid') public geclisuiGrid?: IwRestGridComponent<Geclisui>;
    public type: Type<Geclisui> = Geclisui;

    public query: RestQueryParam<Geclisui, string>[] = [];
    public geclisui?: Geclisui;
    public uuid = '';
    public subscritions = new Subject();
    public disableEditDelete = true;
    private _cliId = '';

    public constructor(private readonly _formHandler: FormHandlerService<SalaryForm>, private readonly _uuidService: UuidService, private readonly _geclisuiService: GeclisuiService) {
        super();
    }

    private _gecli?: Gecli;

    public get gecli() {
        return this._gecli;
    }

    @Input()
    public set gecli(v: Gecli | undefined) {
        this.setGecli(v);
    }

    public ngOnInit(): void {
        if (this._gecli?.cliId) {
            this._cliId = this._gecli.cliId;
        }
        this.setQuery();
        this.uuid = this._uuidService.generateString();

        this._geclisuiService.geclisuiSub.pipe(takeUntil(this.subscritions))
            .subscribe((data: GeclisuiSubscrition) => {
                if (this.uuid === data.uuid && data.saved) {
                    this.geclisuiGrid?.refresh();
                }
            });
    }

    public selectedItem(event: any) {
        if (event.length > 0) {
            this.geclisui = event[0];
            this.disableEditDelete = false;
        }
    }

    public add() {
        this._formHandler.showFormDialog(SalaryForm.Geclisuivi, {
            gecli: this._gecli, parentUUID: this.uuid
        });
    }

    public edit() {
        this._formHandler.showFormDialog(SalaryForm.Geclisuivi, {
            geclisui: this.geclisui, parentUUID: this.uuid
        });
    }

    public delete() {
        if (this.geclisui?.cliSuiId) {
            this._geclisuiService.delete(this.geclisui.cliSuiId)
                .subscribe({
                    next: () => {
                        this._geclisuiService.geclisuiSub.next({
                            uuid: this.uuid || '', saved: true
                        });
                    }
                });
        }
    }

    private setGecli(v?: Gecli) {
        if (v && this._gecli !== v) {
            this._gecli = v;
        }
    }

    private setQuery() {
        this.query = [{
            operation: RestQueryOperation.Equals, prop: 'cliId', value: this._cliId
        }];
    }

}
