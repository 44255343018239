import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormControl, ValidatorFn, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent, SortInfo} from '@app/sam-base/components';
import {
    AgencyHandlerService,
    EntityDelete,
    ERROR,
    FormHandlerService,
    IwEventHubService,
    IwStoreService,
    ModalService
} from '@app/sam-base/core';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {DestroyForm} from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {ComboboxItem, FormKeys, IwGridColumn, Npa} from '@app/sam-base/models';
import {AgeData, AgeDataOutput} from '@app/sam-base/models/agence-data.model';
import {Ppagence} from '@app/sam-base/models/placement/ppagence';
import {Agetime} from '@app/sam-base/models/salary/agetime';
import {SignatureComponent} from '@modules/sam-main/placement/components/signature/signature.component';
import {PlacementForm} from '@modules/sam-main/placement/placement.forms';
import {TranslateService} from '@ngx-translate/core';
import {FileUpload} from 'primeng/fileupload';
import {lastValueFrom, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

function minArrayLength(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value || control.value.length < min) {
            return {minArrayLength: {valid: false}};
        }
        // eslint-disable-next-line no-null/no-null
        return null;
    };
}

@Component({
    templateUrl: './agence-config.component.html',
    styleUrls: ['./agence-config.component.scss']
})
export class AgenceConfigComponent extends BaseFormComponent<Ppagence> implements OnInit, OnDestroy {

    public selectedAgetimeRow: Agetime | undefined;
    public type = Ppagence;
    public agetime = Agetime;
    public agetimeColumns = this.getAgetimeColumns();
    public isReadonly = true;
    public isReadonlyAgetime = true;
    public newEntryMode = false;
    public ageId = '';
    public isSaving = false;
    public cboFact2gsOptions: ComboboxItem<string>[] = [];
    public ofsnr = '';
    public smsToken: string | undefined;
    public smsSenderId: string | undefined;
    public smsMaxLenght: string | undefined;
    public ageByEmailId: string | undefined;
    public ageByEmailSendUser: string | undefined;
    public ageByEmailBcc: string | undefined;
    public ageByEmailBci: string | undefined;
    public noSignature = true;
    public ageTimeCount = 0;
    /** Dummy query created to bypass filter that requires some sort of params */
    public query: RestQueryParam<Ppagence, string>[] = [
        {
            prop: 'ageId',
            operation: RestQueryOperation.NotEquals,
            value: '_'
        }];
    public sortProp?: keyof Ppagence;
    public sortPropDir?: 'asc' | 'desc';
    public reeValidationMessage = '';
    @ViewChild('fileUploader') private fileUploader?: FileUpload;
    @ViewChild('agenceConfigGrid') private agenceConfigGrid?: IwRestGridComponent<Ppagence>;
    @ViewChild('ageTimeGrid') private agetimeTable?: IwRestGridComponent<Agetime>;
    private readonly _restPpagenceClient: RestEntityClient<Ppagence>;
    private _subscripts = new Subject();

    constructor(private _restService: RestApiService, private _store: IwStoreService,
                private _translate: TranslateService, private _toastService: ToastService,
                private _modalService: ModalService,
                private _agencyHandlerService: AgencyHandlerService, private sanitizer: DomSanitizer,
                private _formHandler: FormHandlerService<PlacementForm>,
                private readonly _events: IwEventHubService<string>) {
        super();
        this._restPpagenceClient = this._restService.getEntityClient(Ppagence);
        this.cboFact2gsOptions = [
            {
                name: this._translate.instant('facteurGS'),
                value: '1'
            },
            {
                name: this._translate.instant('facteurAppliquer'),
                value: '2'
            }];
    }

    public get signature() {
        return this.getFormValue('signature');
    }


    public get selectedData() {
        if (!this._selectedRow) {
            return undefined;
        }
        return {
            name: 'ageId',
            value: this._selectedRow.ageId
        };
    }

    public _selectedRow?: Ppagence;

    public set selectedRow(v: Ppagence | undefined) {
        this._selectedRow = v;
        this.setAgetimeQuery();
    }

    public ageTimeQuery: RestQueryParam<Agetime, string>[] = [
        {
            prop: 'ageId',
            operation: RestQueryOperation.Equals,
            value: this._selectedRow?.ageId || ''
        }];

    public get isFormValid(): boolean {
        return this.validateFields();
    }

    public ngOnInit() {
        this.subscribeAgeId();
        this.subscribeValueChange('ageId', (ageId) => {
            this.ofsnr = '';
            if (!ageId) {
                return;
            }
            this.showSignature();
            this.fileUploader?.clear();
            this.setCommuneOfs();
            this.setAgeTimeValidators();
        });

        this._events.forType('agetime_updated')
            .pipe(takeUntil(this._subscripts))
            .subscribe((e) => {
                this.agetimeTable?.refresh();
            });

        this.setCommuneOfs();
        this.setFormValidation()
    }

    public ngOnDestroy() {
        this._subscripts.next(undefined);
        this._subscripts.complete();
        super.ngOnDestroy();
    }

    /**
     * Function to get selected row
     *
     * @param event Ppagence[]
     * @returns void
     */
    public getSelectedRow(event: Ppagence[]) {
        this.isReadonly = true;

        if (this.isSaving) {
            // Prevents clearing of the selectedRow
            this.isSaving = false;
            return;
        }

        if (!event.length) {
            this.clearForm();
            this.clearAgeData();
            this.selectedRow = undefined;
            return;
        }

        this.ageId = (event[0] && event[0].ageId) || '';
        this.selectedRow = event[0];
        this.fillFormData(<any>event[0]);
    }

    public getAgetimeRow(event: Agetime[]) {
        if (!event.length) {
            this.selectedAgetimeRow = undefined;
            return;
        }
        this.selectedAgetimeRow = event[0];
    }

    /**
     * Function to create new ppagence entry
     *
     * @returns void
     */
    public createNewEntry() {
        this._selectedRow = {};
        this.isReadonly = false;
        this.formGroup.reset();
        this.ofsnr = '';
        this.newEntryMode = true;
        this.fileUploader?.clear();
        this.clearAgeData();
        this.setAgetimeQuery();
    }

    /**
     * Function to save changes on existing or new entry
     *
     * @returns void
     */
    public async saveEntry() {
        const formData = this.getFormData();
        const updatedData = this._restPpagenceClient
            .construct({...this._selectedRow, ...formData});
        this.isReadonly = true;
        this.clearForm();

        if (!updatedData || !updatedData.ageId) {
            this._toastService.error('need_to_fill_id');
            this.newEntryMode = false;
            return;
        }

        this.isSaving = true;
        if (this.newEntryMode) {
            this._restPpagenceClient.getById(updatedData.ageId)
                .pipe(catchError(err => of(err)))
                .subscribe(async res => {
                    if (res['status'] === 404) {
                        this.setSortByModif();
                        // This updateData can be used to save all ageData in the future
                        // UpdatedData.ageData = (
                        //   Await lastValueFrom(this.convertAgeData(updatedData.ageId)
                        //    )).ageData;
                        updatedData.ageData = '';
                        this._agencyHandlerService.savePpagence(updatedData)
                            .subscribe(() => {
                                this.agenceConfigGrid?.refresh();
                            });
                    } else {
                        this._toastService
                            .error(this._translate.instant('id_already_exits'));
                    }
                });
            this.newEntryMode = false;
            return;
        }
        updatedData.ageData = (
            await lastValueFrom(this.convertAgeData(updatedData.ageId))).ageData;
        this._restPpagenceClient.update(updatedData)
            .subscribe(() => {
                this.agenceConfigGrid?.refresh();
            });

        this.newEntryMode = false;
    }

    /**
     * Set fields editable
     *
     * @returns void
     */
    public modifyEntry() {
        if (this._selectedRow && this._selectedRow.ageId) {
            this.isReadonly = false;
            this.newEntryMode = false;
        }
    }

    /**
     * Function to remove entry
     *
     * @returns void
     */
    public removeEntry() {
        if (this._selectedRow && this._selectedRow.ageId) {
            this._store.dispatch(new EntityDelete(Ppagence, this.ageId));
        }
    }

    /**
     * Cancel the edition mode
     *
     * @returns void
     */
    public cancelEditionMode() {
        if (this.newEntryMode) {
            this._selectedRow = undefined;
        }
        this.fillFormData(this._selectedRow ?? {});
        this.isReadonly = true;
        this.newEntryMode = false;
        this.fileUploader?.clear();
    }

    public closeDialog() {
        this._store.dispatch(new DestroyForm(this.uuid));
    }

    public openNewAgetimeDialog() {
        this._formHandler.showFormDialog(PlacementForm.Agetime, {
            ageId: this.ageId,
            entryMode: 'add'
        });
    }

    public changeAgeTime() {
        this._formHandler.showFormDialog(PlacementForm.Agetime, {
            ageId: this.ageId,
            entryMode: 'edit',
            agetime: {ageId: this.ageId, ...this.selectedAgetimeRow}
        });
    }

    public removeAgeTime() {
        if (this.selectedAgetimeRow?.agetId && this.uuid) {
            this._store.dispatch(new EntityDelete(Agetime, this.selectedAgetimeRow?.agetId));
        }
        this.selectedAgetimeRow = undefined;
        this.agetimeTable?.refresh();
    }

    public onAgeTimeChange(event: Agetime[]) {
        this.ageTimeCount = event.length;
        this.setFormValue('ageTimes', event);
    }

    public validateFields(): boolean {
        return this.formGroup.valid;
    }

    public setSortInfo(sortInfo: SortInfo<Ppagence>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    public showSignature() {
        const img = this.getFormValue('signature');
        if (!img) {
            return '';
        }
        this.noSignature = false;
        return this.sanitizer
            .bypassSecurityTrustUrl('' + img);
    }

    public async addEmailSignature() {
        const sigText = await this._modalService.showModal(SignatureComponent, this.getFormValue('signature'));
        this.setFormValue('signature', sigText);
        this.noSignature = true;
    }

    public removeSignatureEmail() {
        this.setFormValue('signature', undefined);
    }

    protected getValidationType() {
        return Ppagence;
    }

    protected getFormControlNames(): FormKeys<Ppagence> {
        return [
            'ageId',
            'ageLib',
            'agePrefix',
            'ageCanton',
            'actif',
            'boonly',
            'smioactif',
            'ageAdr1',
            'ageAdr2',
            'ageNpa',
            'ageLieu',
            'ageTel',
            'ageFax',
            [
                'ageEmail',
                new UntypedFormControl('', [Validators.email])],
            'reeNo',
            'hParS',
            'lecParS',
            'ageOfsnr',
            'signature',
            'ageTimes',
            'smtpConfigId'];
    }

    private getAgetimeColumns(): IwGridColumn<Agetime>[] {
        return [
            {
                prop: 'hparsem',
                type: 'number',
                name: this._translate.instant('h_sem'),
                index: 0
            },
            {
                prop: 'lecparsem',
                type: 'number',
                name: this._translate.instant('leçonsSem'),
                index: 1
            }];
    }

    private setAgetimeQuery() {
        this.ageTimeQuery = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'ageId',
                value: this._selectedRow?.ageId || '_'
            }];
    }

    private setSortByModif() {
        // Force the update of the string reference
        this.sortProp = 'dateTrack';
        this.sortPropDir = 'desc';
    }

    private subscribeAgeId() {
        this.subscribeValueChange('ageId', (id?: string) => {
            if (!id) {
                return;
            }
            if (!this.newEntryMode) {
                this.getAgeData(id);
            }
        });
    }

    private getAgeData(id: string) {
        this._agencyHandlerService.getDataText<AgeData>('ageData', id)
            .subscribe(data => {
                this.smsToken = data.SMSTOKEN;
                this.smsSenderId = data.SMSSENDERID;
                this.smsMaxLenght = data.SMSMAXLENGTH;
                this.ageByEmailId = data.MAILCONF?.agebyemail_mm_id;
                this.ageByEmailSendUser = data.MAILCONF?.agebyemailsenduser;
                this.ageByEmailBcc = data.MAILCONF?.agebyemailbcc;
                this.ageByEmailBci = data.MAILCONF?.agebyemailbci;
            });
    }

    private convertAgeData(id: string) {
        return this._agencyHandlerService.convertAgeData<AgeDataOutput>('ageData', id, {
            SMSTOKEN: this.smsToken,
            SMSSENDERID: this.smsSenderId,
            SMSMAXLENGTH: this.smsMaxLenght,
            MAILCONF: {
                agebyemail_mm_id: this.ageByEmailId,
                agebyemailsenduser: this.ageByEmailSendUser,
                agebyemailbcc: this.ageByEmailBcc,
                agebyemailbci: this.ageByEmailBci
            }
        });
    }

    private clearAgeData() {
        this.smsToken = '';
        this.smsSenderId = '';
        this.smsMaxLenght = '';
        this.ageByEmailId = '';
        this.ageByEmailSendUser = '';
        this.ageByEmailBcc = '';
        this.ageByEmailBci = '';
    }

    private setCommuneOfs() {
        const npa: string | undefined = this.getFormValue('ageNpa');
        this.getNpaInfo(npa);
    }

    private getNpaInfo(npa?: string) {
        if (npa) {
            this._restService
                .getEntityClient(Npa)
                .getById(npa)
                .subscribe({
                    next: (npaData) => this.setHabOfsnr(npaData),
                    error: () => ERROR('The npa doesn\'t exist')
                });
        }
    }

    private setHabOfsnr(npa?: Npa) {
        if (npa) {
            const ofsnr = npa.ofsnr?.toString() ?? '';
            this.setFormValue('ageOfsnr', ofsnr);
            this.ofsnr = 'CH' + ' \\ ' + npa.canton + ' \\ ' + ofsnr;
        } else {
            this.ofsnr = '';
        }
    }

    private setAgeTimeValidators() {
        const ageTimesControl = this.getFormControl('ageTimes');
        if (!this.newEntryMode) {
            ageTimesControl?.setValidators(minArrayLength(1));
        } else {
            ageTimesControl?.clearValidators();
        }
        ageTimesControl?.updateValueAndValidity();
    }

    private setFormValidation() {
        this.getFormControl('reeNo')?.setValidators([
            Validators.pattern('^[A-Z][0-9]{8}$'),
            Validators.required]);
        this.reeValidationMessage = this._translate.instant('no_ree_wrong');
    }

}
