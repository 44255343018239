import {HttpClient} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ClipboardService} from '@app/sam-base/core/clipboard/clipboard.service';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {ERROR} from '@app/sam-base/core/logger/logger';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {AvsNumberService} from '@app/sam-base/core/services/avs-number.service';
import {SaemcService} from '@app/sam-base/core/services/saemc.service';

import {SaempService} from '@app/sam-base/core/services/saemp.service';
import {Npa} from '@app/sam-base/models';
import {Saemc} from '@app/sam-base/models/salary/saemc';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {Sapar} from '@app/sam-base/models/salary/sapar';
import {TranslateService} from '@ngx-translate/core';
import {Langue} from '@sam-base/models/common/langue.enum';
import {KeycloakService} from 'keycloak-angular';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

enum Cpolitesse {
    monsieur = '1', madame = '2', mademoiselle = '3',
}

// Async validator function for AVS Number
export function noAvs13AsyncValidator(avsService: AvsNumberService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        const value = control.value;
        // If no value, then it's valid (because it's not required)
        if (!value) {
            // eslint-disable-next-line no-null/no-null
            return of(null);
        }

        return avsService.validateAVS(value)
            .pipe(map(res => {
                    if (res) {
                        // eslint-disable-next-line no-null/no-null
                        return null; // Value is valid, no error
                    } else {
                        return {noavs13: true}; // Value is invalid, return an error
                    }
                }), // eslint-disable-next-line no-null/no-null
                catchError(() => of(null)));
    };
}

@Component({
    selector: 'iw-tab-saemp-donnees-base',
    templateUrl: './tab-saemp-donnees-base.component.html'
})
export class TabSaempDonneesBaseComponent extends BasePartialFormComponent<Saempc> implements OnInit {
    public habOfsnr = '';
    public langues = Langue;
    public paysText = this.getFormValue('pays');
    public dateNow = IwDateHelper.getDateNow();
    public cpolitesse = Cpolitesse;
    private _saemc?: Saemc;
    private readonly NPA_LENGTH_LIMIT = 4;

    constructor(private readonly _restService: RestApiService, private readonly _clipboardService: ClipboardService,
                private readonly _translate: TranslateService, private readonly _saemcService: SaemcService,
                private readonly _saempService: SaempService, private readonly _keycloakService: KeycloakService,
                private readonly _avsService: AvsNumberService, private readonly _http: HttpClient) {
        super();
    }

    private _sapar?: Sapar;

    @Input()
    public set sapar(sapar: Sapar) {
        this._sapar = sapar;
        this.setSaemc(sapar);
    }

    public get age(): string {
        const date: string | undefined = this.getFormValue('datenaiss');
        if (!date) {
            return '';
        }
        const years = IwDateHelper.getYearsFromDate(date);
        const months = IwDateHelper.getMonthsFromDate(date) - years * 12;
        return (years.toString() + ' ' + this._translate.instant('years_lower') + ' & ' + months.toString() + ' ' + this._translate.instant('month_lower'));
    }

    public get isNoAvs13Invalid(): boolean | undefined {
        return this.getFormControl('noavs13')?.invalid;
    }

    public get isDateInActive(): boolean {
        return !this.getFormValue('dateInfir');
    }

    public get inactive(): string {
        if (this._saemc?.inactif) {
            return this._translate.instant('inactive_in') + 'PLACEHOLDER';
        }
        return '';
    }

    public get hasEmail() {
        if (!this.getFormValue('email')) {
            this.setFormValue('bstoemail', false);
        }
        return !!this.getFormValue('email');
    }

    public get pays(): string {
        return this.getFormValue('pays') || '';
    }

    public ngOnInit() {
        this.subscribeValueChange('pays', (pays: string | undefined) => {
            if (pays === 'CH') {
                const cantonHab = this.getFormValue('cantonhab');
                if (cantonHab === 'EX') {
                    this.setFormValue('cantonhab', '');
                }
            } else {
                this.setFormValue('cantonhab', 'EX');
            }
            this.updateHabOfSnrFromPays(pays || '');
        });
        this.subscribeValueChange('npa', (npaText: string | undefined) => {
            if (npaText && !this.isReadonly) {
                if (npaText.length >= this.NPA_LENGTH_LIMIT) {
                    this.getNpaInfo(this.getFormControl('pays')?.value, npaText);
                }
            }
        });
        this.searchNpa(this.getFormValue('pays') ?? '');
        this.subscribeValueChange('empId', () => {
            this.setSaemc(this._sapar);
            const pays: string = this.getFormValue('pays') || '';
            this.updateHabOfSnrFromPays(pays);
        });
        this.getFormControl('noavs13')
            ?.setAsyncValidators(noAvs13AsyncValidator(this._avsService));
        this.subscribeValueChange('email', (email: string | undefined) => {
            let isValid: boolean;
            isValid = false;
            if (email != null) {
                isValid = this.isValidEmail(email);
            }
            this.setFormValue('bstoemail', isValid);
        });
    }

    /**
     * Function do copy value to clipboard
     *
     * @param value value to be copied
     */
    public addToClipboard(key: string) {
        let copiedText = '';
        if (key === 'email' || key === 'noavs13') {
            const value = this.getFormValue(key);
            copiedText = typeof value === 'string' ? value : '';
        }
        this._clipboardService.addToClipboard(copiedText);
    }

    public recalculateDeductions() {
        if (!this.isReadonly) {
            const empId: string | undefined = this.getFormValue('empId');
            const datenaiss: string | undefined = this.getFormValue('datenaiss');
            const politiesse: string | undefined = this.getFormValue('cpolitesse');
            if (empId && datenaiss && politiesse) {
                this._saempService
                    .updateDeductions(empId, datenaiss, politiesse)
                    .subscribe((res: any) => {
                        this.applyFormControls(res);
                    });
            }
        }
    }

    public applyFormControls(result: any) {
        for (const k of Object.keys(result)) {
            this.setFormValue(<any>k, result[k]);
        }
    }

    private isValidEmail(email: string): boolean {
        // Simple email validation regex
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    private updateHabOfSnrFromPays(pays: string) {
        if (pays === 'CH') {
            this.searchNpa(pays);
        } else {
            this.habOfsnr = (pays ?? '') + ' \\ EX';
            // SO-713 : reset ofsnr when pays is not CH
            this.setFormValue('ofsnr', 0);
        }
    }

    private searchNpa(pays: string) {
        const npa: string | undefined = this.getFormValue('npa');
        this.getNpaInfo(pays, npa);
    }

    private getNpaInfo(pays: string, npa?: string) {
        if (npa) {
            this._restService
                .getEntityClient(Npa)
                .getById(npa)
                .subscribe({
                    next: (npaData) => {
                        this.setFormValue('ofsnr', npaData.ofsnr);
                        this.setHabOfsnr(pays, npaData);
                    },
                    error: () => ERROR('The npa doesn\'t exist')
                });
        }
    }

    private setHabOfsnr(pays: string, npa?: Npa) {
        if (npa) {
            this.habOfsnr = pays + ' \\ ' + npa.canton + ' \\ ' + this.getOfsnr(npa);
        } else {
            this.habOfsnr = '';
        }
    }

    private getOfsnr(npa: Npa): string {
        const ofsnr: number | undefined = this.getFormValue('ofsnr');
        return ofsnr && ofsnr !== 0 ? ofsnr.toString() : npa.ofsnr?.toString() ?? '';
    }

    private setSaemc(sapar?: Sapar) {
        const empId: string | undefined = this.getFormValue('empId');
        if (empId && sapar?.annee) {
            this._saemcService
                .findSaemcByEmpIdAndAnee(empId, sapar.annee)
                .subscribe((saemc) => (this._saemc = saemc));
        }
    }
}
