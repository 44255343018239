<div *iwAccessGroups="commandeRoles" class="flex flex-v flex-evenly">
    <div class="row margin-top-20">
        <iw-button #btnOpenClose (press)="changeCdeStatus()" [text]="'oF' | translate"
                   [tooltip]="'open_close_cde' | translate" class="btn-block" id="btnOpenClose"></iw-button>
    </div>
    <div class="row">
        <iw-button #btnCopy (press)="copyCde()" [tooltip]="'copy_cde' | translate" class="btn-block"
                   iconClass="fas fa-copy" id="btnCopy"></iw-button>
    </div>
</div>
