import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AbstractControl, ValidatorFn, Validators} from '@angular/forms';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {EntityNavigationService, IwEventHubService} from '@app/sam-base/core';
import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {SaenfService} from '@app/sam-base/core/services/saenf.service';
import {SatblService} from '@app/sam-base/core/services/satbl.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {ComboboxItem, FormKeys, Saconj, Saenf} from '@app/sam-base/models';
import {Saempview, Sapar} from '@app/sam-base/models/salary';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {godRoles} from '@sam-base/core/auth/access-rules/side-menu';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {Ppemp} from '@sam-base/models/placement';
import {DtaType} from '@sam-base/models/salary/payment-type-enum';
import moment from 'moment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {employeeTabsFormControls} from './employee-tabs-formcontrols';
import {ImpotEnum} from './tab-saemp-impot/saemp-impot.enums';
import {IndemnitesEnum} from './tab-saemp-indemnites/saemp-indemnites-enum';

export function notZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value;
        // eslint-disable-next-line no-null/no-null
        return value !== 0 ? null : {notZero: true};
    };
}


export function italianTaxIdValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        // eslint-disable-next-line max-len
        const regex = new RegExp('^[A-Z]{6}[0-9]{2}[A|B|C|D|E|H|L|M|P|R|S|T][0-9]{2}[A-Z][0-9]{3}[A-Z]$');
        const valid = regex.test(control.value);
        // eslint-disable-next-line no-null/no-null
        return valid ? null : {invalidItalianTaxId: {value: control.value}};
    };
}

@Component({
    templateUrl: './employee.component.html'
})
export class EmployeeComponent extends BaseTabFormComponent<Saempc> implements OnInit {

    private static readonly ITALY = 'IT';
    private static readonly G_PERMIS = 'G';
    private static readonly ITALY_2023_CROSSBORDER_LAW_CANTON_CODES = [
        'GR',
        'VS',
        'TI'];
    private static readonly ITALY_CROSSBORDER_EFFECTIVE_DATE_THRESHOLD = moment('2023-07-17', 'YYYY-MM-DD');
    public sapar?: Sapar;
    public subscriptions = new Subject();
    /*public optionTableMj1: ComboboxItem<string | undefined>[] = [];
    public optionTableMj2: ComboboxItem<string | undefined>[] = [];
    public optionTableMj3: ComboboxItem<string | undefined>[] = [];
    public optionTableMj4: ComboboxItem<string | undefined>[] = [];*/
    public disabledTabList: string[] = [];
    protected readonly godRoles = godRoles;
    private _empStatus?: string;

    public PLACEMENT_USER_ROLES = [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW];

    constructor(store: IwStoreService, private readonly _events: IwEventHubService<string>,
                private _restService: RestApiService, private _parametersService: ParametersService,
                private readonly _entityNavService: EntityNavigationService,
                private _saenfService: SaenfService, private _http: HttpClient, private _translate: TranslateService,
                private _satblService: SatblService) {
        super(store);
    }

    public get tabData(): Saempc | undefined {
        return this.getFormData();
    }

    public set tabData(v: Saempc | undefined) {
        this.mergeEntityChanges(v);
    }

    public get empId() {
        return this.getFormValue('empId') || '';
    }

    public get employeeName() {
        const nom = this.getFormValue('nom') ?? '';
        const prenom = this.getFormValue('prenom') ?? '';
        return nom + ' ' + prenom;
    }

    public ngOnInit() {
        const empId = this.getFormValue('empId');
        if (empId === undefined) {
            this.disabledTabList.push('deductions');
        }
        this.subscribeValueChange('empId', (id: string | undefined) => {
            if (id) {
                this.disabledTabList = [];
                this._restService
                    .getEntityClient(Saempview)
                    // .getById(id + '_' + this.sapar?.annee)
                    .getById(id)
                    .subscribe((saempview) => {
                        const actif = saempview.activeYears?.includes(this.sapar!.annee!)
                        if (actif) {
                            this._empStatus = 'saemp_active';
                        } else {
                            this._empStatus = 'saemp_inactive';
                        }

                    });
            } else {
                this.disabledTabList.push('deductions');
            }
        });
        this._parametersService
            .getCurrentSapar()
            .subscribe((sapar) => (this.sapar = sapar));
        this.subscribeConjointClose();
        this.subscribeEnfClose();
        //this.getSatblOptions('60', 0, this.optionTableMj1);
        //this.getSatblOptions('61', 0, this.optionTableMj2);
        //this.getSatblOptions('62', 0, this.optionTableMj3);
        //this.getSatblOptions('63', 0, this.optionTableMj4);
        this.subscribeValueChange('dtatype', (value?: number) => this.setDtaTypeValidators(value));
        this.subscribeValueChange('codeImp', (value?: number) => {
            this.setCodeImpot(value);
            this.updateFormOnEmployeePaysItaly();
        });
        //this.subscribeValueChange('codeMj1', (value?: number) => this.setTableMj1Validator(value));
        this.updateFormOnEmployeePays(this.getFormValue('pays'));
        this.subscribeValueChange('pays', (pays) => {
            this.updateFormOnEmployeePays(pays as string);
        });
        this.subscribeValueChange('permDtde', () => this.updateFormOnEmployeePaysItaly());
        this.subscribeValueChange('cantonimp', () => this.updateFormOnEmployeePaysItaly());
    }

    public getTabsValues(): TabFormControl<Saempc>[] {
        return employeeTabsFormControls;
    }

    public getEmpStatusClass() {
        return this._empStatus ? 'emp-status-' + this._empStatus : '';
    }

    public openEmp() {
        this._entityNavService
            .navigateToEntityForm(Ppemp, this.getFormValue('empId'));
    }

    /*public debug() {
        console.log('this.formGroup', this.formGroup);
        Object.keys(this.formGroup.controls).forEach(key => {
            const control = this.formGroup.controls[key];
            if (control.invalid) {
                console.log(`Invalid control: ${key}`);
            }
        });
    }*/

    protected getValidationType() {
        return Saempc;
    }

    protected getFormControlNames(): FormKeys<Saempc> {
        return this.getTabsFormControls(employeeTabsFormControls);
    }

    private updateFormOnEmployeePays(pays: string | undefined) {
        const sdsMFormControl = this.getFormControl('sdsM');
        if (pays !== 'CH') {
            sdsMFormControl?.setValidators([Validators.required]);
        } else {
            sdsMFormControl?.clearValidators();
        }
        sdsMFormControl?.updateValueAndValidity();

        this.updateFormOnEmployeePaysItaly();
    }

    private subscribeEnfClose(): void {
        this._events
            .forType<any>('enf_closed')
            .pipe(takeUntil(this.subscriptions))
            .subscribe(() => {
                this._saenfService.getEnfsByEmpId(this.getFormValue('empId'))
                    .subscribe((list: Saenf[]) => {
                        this.setFormValue('enfnb', list.length);
                        this.setFormValue('enfnbcharg', list.filter((elem: Saenf) => elem.acharge).length);
                        this.setFormValue('enfnballoc', list.filter((elem: Saenf) => elem.allocation).length);
                    });
            });
    }

    private subscribeConjointClose(): void {
        this._events
            .forType<any>('conjoint_closed')
            .pipe(takeUntil(this.subscriptions))
            .subscribe((e) => {
                const payload: Saconj | string = e.payload;
                if (typeof payload === 'string') {
                    this.cleanEmpWithConj();
                } else if (typeof payload === 'object' && payload.empId === this.empId) {
                    this.fillEmpWithConj(payload);
                }
            });
    }

    private fillEmpWithConj(conj: Saconj): void {
        this.setFormValue('conjNom', conj.nom);
        this.setFormValue('conjprenom', conj.prenom);
        this.setFormValue('conjNaiss', moment(conj.datenaiss)
            .format('YYYY-MM-DD'));
        this.setFormValue('conjnoas13', conj.noavs13);
        this.setFormValue('conjWkch', conj.ltravail && conj.paystrav === 'CH');
        this.setFormValue('conjWork', conj.ltravail);
    }

    private cleanEmpWithConj() {
        this.setFormValue('conjNom', undefined);
        this.setFormValue('conjprenom', undefined);
        this.setFormValue('conjNaiss', undefined);
        this.setFormValue('conjnoas13', undefined);
        this.setFormValue('conjWkch', undefined);
        this.setFormValue('conjWork', undefined);
    }

    private getSatblOptions(tableNo: string, recType: number, optionType: ComboboxItem<string | undefined>[]) {
        return this._satblService.getSatblOptions(tableNo, recType)
            .subscribe((res) => {
                optionType.push({
                    name: this._translate.instant('combo_no_option'),
                    value: undefined
                });
                res.forEach(satbl => optionType.push({
                    name: `${satbl.elemId} | ${satbl.titre} | ${satbl.nvalue1} | ${satbl.nvalue2}`,
                    value: satbl.elemId
                }));
            });
    }

    private setDtaTypeValidators(value?: number) {
        if (value === DtaType.banque) {
            this.setBanqueValidator();
        } else if (value === DtaType.iban) {
            this.setIbanValidator();
        }
        this.updateDtaValueAndValidity();
    }

    private setCodeImpot(value?: number) {
        this.formGroup?.controls['codeImp'].setValidators(notZeroValidator());
        if (value === ImpotEnum.soumis) {
            this.setImpotValidatorsForSoumis();
        } else if (value === ImpotEnum.nonSoumis) {
            this.setImpotValidatorsForNonSoumis();
        } else {
            this.clearImpotValidators();
        }
        this.updateImpotValueAndValidity();
    }

    private setTableMj1Validator(value?: number) {
        if (value === IndemnitesEnum.Soumis || value === IndemnitesEnum.SoumisPRV) {
            this.formGroup?.controls['tableMj1'].setValidators(Validators.required);
        } else {
            this.formGroup?.controls['tableMj1'].clearValidators();
        }
        this.formGroup?.controls['tableMj1'].updateValueAndValidity();
    }

    private updateDtaValueAndValidity() {
        this.formGroup?.controls['banque'].updateValueAndValidity();
        this.formGroup?.controls['iban'].updateValueAndValidity();
    }

    private setIbanValidator() {
        this.formGroup?.controls['iban'].setValidators(Validators.required);
        this.formGroup?.controls['banque'].clearValidators();
    }

    private setBanqueValidator() {
        this.formGroup?.controls['banque'].setValidators(Validators.required);
        this.formGroup?.controls['iban'].clearValidators();
    }

    private updateImpotValueAndValidity() {
        this.formGroup?.controls['modcalcimp'].updateValueAndValidity();
        this.formGroup?.controls['cantontrav'].updateValueAndValidity();
        this.formGroup?.controls['baremImp'].updateValueAndValidity();
    }

    private clearImpotValidators() {
        this.setFormValue('modcalcimp', 0);
        this.formGroup?.controls['modcalcimp'].clearValidators();
        this.formGroup?.controls['baremImp'].clearValidators();
    }

    private setImpotValidatorsForSoumis() {
        this.formGroup?.controls['modcalcimp'].setValidators(Validators.min(1));
        this.formGroup?.controls['cantontrav'].setValidators(Validators.required);
        this.formGroup?.controls['baremImp'].setValidators(Validators.minLength(2));
    }

    private setImpotValidatorsForNonSoumis() {
        this.formGroup?.controls['modcalcimp'].clearValidators();
        this.formGroup?.controls['cantontrav'].setValidators(Validators.required);
        this.formGroup?.controls['baremImp'].clearValidators();
    }

    /**
     * SO-836 : L'accord entre la Suisse et l'Italie sur l'imposition des travailleurs frontaliers
     */
    // eslint-disable-next-line complexity
    private updateFormOnEmployeePaysItaly() {
        const placeOfBirthFormControl = this.getFormControl('lieunaiss');
        const taxIdFormControl = this.getFormControl('taxId');

        if (this.getFormValue('pays') !== EmployeeComponent.ITALY) {
            this.clearValidatorsAndUpdate([
                placeOfBirthFormControl,
                taxIdFormControl]);
            return;
        }

        const permis = this.getFormValue('permis');
        const codeImp = this.getFormValue('codeImp');
        let dateEntreeEnVigeur;

        if (codeImp === ImpotEnum.soumis && permis === EmployeeComponent.G_PERMIS) {
            placeOfBirthFormControl?.setValidators([Validators.required]);
            dateEntreeEnVigeur = this.getFormValue('permDtde');
        } else if (codeImp === ImpotEnum.soumis && permis !== EmployeeComponent.G_PERMIS) {
            placeOfBirthFormControl?.clearValidators();
            dateEntreeEnVigeur = this.getFormValue('dateInfir');
        }

        const hasPassedEffectiveDate = this.hasPassedItalyCrossBorder2023LawEffectiveDate(dateEntreeEnVigeur);
        const cantonImp = this.getFormValue('cantonimp') || '';

        if (hasPassedEffectiveDate && EmployeeComponent.ITALY_2023_CROSSBORDER_LAW_CANTON_CODES.indexOf(cantonImp) !== -1) {
            taxIdFormControl?.setValidators([
                Validators.required,
                italianTaxIdValidator()]);
        } else {
            taxIdFormControl?.clearValidators();
        }

        this.updateValueAndValidity([
            taxIdFormControl,
            placeOfBirthFormControl]);
    }

    private clearValidatorsAndUpdate(controls?: (AbstractControl | undefined)[]) {
        controls?.forEach(control => {
            control?.clearValidators();
            control?.updateValueAndValidity();
        });
    }

    private updateValueAndValidity(controls?: (AbstractControl | undefined)[]) {
        controls?.forEach(control => {
            control?.updateValueAndValidity();
        });
    }

    private hasPassedItalyCrossBorder2023LawEffectiveDate(dateEntreeEnVigeur: any) {
        const dateEntreeEnVigeurMoment = moment(dateEntreeEnVigeur, 'YYYY-MM-DD');
        return dateEntreeEnVigeurMoment
            .isAfter(EmployeeComponent.ITALY_CROSSBORDER_EFFECTIVE_DATE_THRESHOLD);
    }
}
