import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppjf} from '@app/sam-base/models/placement';

import {PpjfcalcComponent} from '../components/ppjfcalc/ppjfcalc.component';

export const PPJF_DEFAULT_FORM: FormDescription<Ppjf> = {
    form: PpjfcalcComponent, state: {
        ...buildDefaultState(Ppjf), diagTitle: 'ppjf_form_title', isNew: true, patch: false, width: 400
    }
};
