export enum SalaryTarifMode {
    salary_tarif_mode_hour = 1, salary_tarif_mode_month = 3,
}

/*
 * H. supp. sur Global,H. supp. sur Base,Marge constante,
 * Selon taux charges secteur,Coeff. constant,Autre coeff. (tarif),
 * Autre coeff. (salaire),Autre coeff. (salaire base),Autre coeff. (suppl.) ,
 * Autre coeff. (salaire base+13e) */
export enum FacHeurMode {
    h_supp_global = 1,
    h_supp_base = 2,
    marge_constant = 3,
    selon_taux_secteur = 4,
    coeff_constant = 5,
    autre_coeff_tarif = 6,
    autre_coeff_salaire = 7,
    autre_coeff_salaire_base = 8,
    autre_coeff_suppl = 9,
    autre_coeff_b13 = 10,
    autre_coeff_b13jf = 11,
}

export enum SalaryMode {
    salary_mode_base = 2, salary_mode_global = 1,
}

// eslint-disable-next-line max-len
// Base,B+V,B+13,B+V+13,B+JF,B+JF+V,B+JF+13,Global,CCTLS (13e s/base),CCTLS (13e selon mission)
export enum HeuresSup {
    base = 1, bv = 2, b13 = 3, bv13 = 4, bjf = 5, bjfv = 6, bjf13 = 7, global = 8, cctls13nb = 9, cctls13 = 10,
}
