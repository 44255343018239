<form [formGroup]="formGroup"
      autocomplete="no"
      class="row spaceForm">

    <div class="row col-xs-8 separator-right">
        <iw-date-picker [isDisabled]="isReadonly"
                        [labelAlign]="'left'"
                        [label]="'date_facture' | translate"
                        [size]="'small'"
                        class="col-xs-6 padding-top-5"
                        formControlName="dateFac"></iw-date-picker>

        <iw-textfield [isDisabled]="true"
                        [labelAlign]="'left'"
                        [label]="'' | translate"
                        [value]="datePublished | iwDatetime"
                        class="col-xs-4 padding-top-5"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'' | translate"
                      class="iw-input col-xs-2 padding-top-5"
                      formControlName="nbOfPublished"></iw-textfield>

        <iw-date-picker [isDisabled]="true"
                        [labelAlign]="'left'"
                        [label]="'' | translate"
                        class="col-xs-4 col-xs-offset-6 padding-top-5"
                        formControlName="dateMail"></iw-date-picker>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'' | translate"
                      class="iw-input col-xs-2 padding-top-5"
                      formControlName="nbOfMail"></iw-textfield>

        <iw-date-picker [isDisabled]="isReadonly"
                        [labelAlign]="'left'"
                        [label]="'date_cpta' | translate"
                        [size]="'small'"
                        class="col-xs-6 padding-top-5"
                        formControlName="dateCpta"></iw-date-picker>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'' | translate"
                      class="iw-input col-xs-3 padding-top-5"
                      formControlName="salId"></iw-textfield>

        <iw-date-picker [isDisabled]="true"
                        [labelAlign]="'left'"
                        [label]="'date_ech' | translate"
                        [size]="'small'"
                        class="col-xs-6 padding-top-5"
                        formControlName="dateEch"></iw-date-picker>

        <iw-gepaicond-dropdown (selectedChanged)="onGepaicondSelected($event)"
                               [isDisabled]="isReadonly"
                               [labelAlign]="'left'"
                               [label]="'' | translate"
                               [noLabel]="true"
                               class="iw-input col-xs-6 padding-top-5"
                               formControlName="paiCondId"></iw-gepaicond-dropdown>

        <iw-date-picker [isDisabled]="true"
                        [labelAlign]="'left'"
                        [label]="'date_pay' | translate"
                        [size]="'small'"
                        class="col-xs-6 padding-top-5"
                        formControlName="datePay"></iw-date-picker>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'' | translate"
                      class="iw-input col-xs-3 padding-top-5"
                      formControlName="payId"></iw-textfield>

        <iw-textfield *ngIf="nbJPaiementVisible && nbJPaiement > 0"
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'' | translate"
                      [value]="nbJPaiement + 'j.'"
                      class="iw-input col-xs-3 padding-top-5"></iw-textfield>

        <iw-textfield *ngIf="nbJRetardVisible"
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'' | translate"
                      [ngClass]="{'red-retard' : nbJRetard > 60}"
                      [value]="nbJRetard + 'j.'"
                      class="iw-input col-xs-3 padding-top-5"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'rappels' | translate"
                      [size]="'small'"
                      [value]="txtRappels"
                      class="iw-input col-xs-6 padding-top-5"></iw-textfield>

        <iw-button (press)="openRappels()"
                   [isDisabled]="!hasRappels"
                   [text]="'rappels' | translate"
                   class="iw-button button-rappels"></iw-button>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'nRef' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-8 padding-top-5"
                      formControlName="nRef"></iw-textfield>
        <div class="col-xs-4"></div>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'vRef' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-8 padding-top-5"
                      formControlName="vRef"></iw-textfield>
        <div class="col-xs-4"></div>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'chantier' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-6 padding-top-5"
                      formControlName="chantier"></iw-textfield>
        <div class="col-xs-4"></div>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'chantier2' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-6 padding-top-5"
                      formControlName="chantier2"></iw-textfield>

        <iw-button (press)="''"
                   [text]="'calc/rpl' | translate"
                   class="iw-button button-rappels"></iw-button>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-textarea [height]="110"
                     [isDisabled]="isReadonly"
                     [labelAlign]="'top'"
                     [label]="'concerne' | translate"
                     class="iw-textarea col-xs-12"
                     formControlName="concerne"></iw-textarea>

    </div>
    <div class="row col-xs-4 fit-content">

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'mntbrut' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-12 padding-top-5 text-align-right"
                      formControlName="mntBrut"
                      type="number"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'txtRabais' | translate"
                      [size]="'small'"
                      [value]="txtRabaisValue ?? ''"
                      class="iw-input col-xs-8 padding-top-5 text-align-right"></iw-textfield>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-4 padding-top-5 text-align-right"
                      formControlName="mntRabais"
                      type="number"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'mntht' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-12 padding-top-5 text-align-right"
                      formControlName="mntHt"
                      type="number"></iw-textfield>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-gltvadef-dropdown (selectedChanged)="changeTva($event)"
                              [isDisabled]="isReadonly"
                              [labelAlign]="'left'"
                              [label]="'gltvadef' | translate"
                              [size]="'small'"
                              class="iw-input col-xs-9 padding-top-5 text-align-right"
                              formControlName="tvaCode"></iw-gltvadef-dropdown>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'' | translate"
                      [size]="'small'"
                      [value]="(tvaTauxVal | number : '1.2-2') + '%' "
                      class="iw-input col-xs-3 padding-top-5 text-align-right"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'mnttva' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-12 padding-top-5 text-align-right"
                      formControlName="mntTva"
                      type="number"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'tvacompta' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-8 padding-top-5 text-align-right"
                      formControlName="tvaPeriode"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-4 padding-top-5 text-align-right"
                      formControlName="mntTvaCpta"
                      type="number"></iw-textfield>

        <iw-checkbox [isDisabled]="isReadonly"
                     [label]="'tva_inclus' | translate"
                     class="iw-checkbox col-xs-12 padding-top-5 text-align-right"
                     formControlName="tvaInclus"></iw-checkbox>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'mntttc_total' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-12 padding-top-5 text-align-right"
                      formControlName="mntTtc"
                      type="number"></iw-textfield>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'mntRecu' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-12 padding-top-5 text-align-right"
                      formControlName="mntRecu"
                      type="number"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'txttauxesc' | translate"
                      [size]="'small'"
                      [value]="txtTauxEscValue ?? ''"
                      class="iw-input col-xs-8 padding-top-5 text-align-right"
                      type="number"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-4 padding-top-5 text-align-right"
                      formControlName="mntEsc"
                      type="number"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'mntpaispec' | translate"
                      [size]="'small'"
                      class="iw-input col-xs-12 padding-top-5 text-align-right"
                      formControlName="mntPaiSpec"
                      type="number"></iw-textfield>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'solde' | translate"
                      [ngClass]="{'yellow-solde' : calcSoldeValue !== 0}"
                      [size]="'small'"
                      class="iw-input col-xs-12 padding-top-5 margin-right-5 text-align-right"
                      formControlName="solde"
                      type="number"></iw-textfield>

        <div class="col-xs-12"></div>
    </div>

</form>
