import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Gepaicond} from '@app/sam-base/models/invoices/gepaicond';
import {GepaicondComponent} from '../components/gepaicond/gepaicond.component';

export const GEPAICOND: FormDescription<Gepaicond> = {
    form: GepaicondComponent, state: {
        ...buildDefaultState(Gepaicond),
        diagTitle: 'condition_paiment',
        diagShowSideMenu: false,
        diagShowStatus: true,
        width: 700
    }
};
