import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Salary} from '@app/sam-base/models/placement';

import {SalaryComponent} from '../components/salary/salary.component';

export const SALARY_DEFAULT_FORM: FormDescription<Salary> = {
    form: SalaryComponent,
    state: {
        ...buildDefaultState(Salary),
        diagTitle: 'salary_form_title',
        diagModal: false,
        editMode: 'waiting',
        width: 1600
    }
};
