export enum CorType {
    dpi = '1', aci = '2',
}

export enum MotifsEntree {
    entre_entreprise = '1', debut_travail = '2', autre = '3'
}

export enum MotifsMutation {
    mod_salaire = '1', taux_occupation = '2', plan_prev = '3', adresse_domicile = '4', autre = '5'
}

export enum MotifsSortie {
    sortie_entrep = '1', retraite = '2', debut_trav = '3', autre = '4',
}

export enum EmaType {
    entre = '1', mutation = '2', sortie = '3',
}
