<form [formGroup]="formGroup" class="row" style="height:770px;display:block;">

    <!-- Week Navigation -->
    <iw-week-navigation (chosenDate)="onSearch($event)"></iw-week-navigation>

    <!-- Main grid with Missions -->
    <div class="row col-xs-12 col-no-padding">
        <iw-button #btnMis (press)="openMission()" [isDisabled]="!rowSelected" [text]="'mission' | translate"
                   [tooltip]="'mission_sel' | translate" class="iw-button col-xs-4"></iw-button>

        <iw-button #btnEmp (press)="openEmploye()" [isDisabled]="!rowSelected" [text]="'employe' | translate"
                   [tooltip]="'employe_sel' | translate" class="iw-button col-xs-4"></iw-button>

        <iw-button #btnCli (press)="openClient()" [isDisabled]="!rowSelected" [text]="'client' | translate"
                   [tooltip]="'client_sel' | translate" class="iw-button col-xs-4"></iw-button>
    </div>

    <div class="col-xs-12">
        <div class="col-xs-12 title-grid">{{ 'missions_actives' | translate }}</div>
        <div class="col-xs-12 col-no-padding" style="height: 350px; margin-top: -15px">
            <iw-smart-grid #grdMissions (selected)="getSelectedRow($event)" [autoFormClick]="false"
                           [customColumns]="columns" [hasGridColumnMenu]="false" [innerWidth]="tableWidth"
                           [queryStatements]="statmsMissions" [selectMode]="'single'" [showGridDetails]="false"
                           [sortDir]="'desc'" [sortProp]="'misstatustext'" [type]="type"
                           class="iw-input col-xs-12"></iw-smart-grid>
        </div>
    </div>

    <div class="col-xs-12 margin-top-30" style="height: 300px;">
        <iw-list-rapports (destroy)="destroyForm()" [date]="date" [missionSelected]="selected"
                          [rapportsFormUuid]="uuid"></iw-list-rapports>
    </div>
</form>
