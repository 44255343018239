export enum CsMot {
    ncs_avancee_dialog_combo_option_1 = 'blockedOptions',
    ncs_avancee_dialog_combo_option_2 = 'unquotedOptions',
    ncs_avancee_dialog_combo_option_3 = 'deferredBenefitsStaffShares',
    ncs_avancee_dialog_combo_option_4 = 'fictitiousStaffShare',
}

export enum CsMot1201 {
    ncs_avancee_dialog_combo_1201_option_1 = 'joursEffectifs',
    ncs_avancee_dialog_combo_1201_option_2 = 'joursForfaitaires',
    ncs_avancee_dialog_combo_1201_option_3 = 'prealableSepare',
}
