export class Saeisa {
    empId?: string;
    annee?: string;
    cantonimp?: string;
    nbepisodes?: number;
    nbjis?: number;
    soumis?: number;
    deduit?: number;
    allbars?: string;
    nbjis0?: number;
    nbjis1?: number;
    nbjis2?: number;
    nbjis3?: number;
    nbjis4?: number;
    nbjis5?: number;
    nbjis6?: number;
    nbjis7?: number;
    nbjis8?: number;
    nbjis9?: number;
    nbjis10?: number;
    nbjis11?: number;
    nbjis12?: number;
    din0?: string;
    dout0?: string;
    din1?: string;
    dout1?: string;
    din2?: string;
    dout2?: string;
    din3?: string;
    dout3?: string;
    din4?: string;
    dout4?: string;
    din5?: string;
    dout5?: string;
    din6?: string;
    dout6?: string;
    din7?: string;
    dout7?: string;
    din8?: string;
    dout8?: string;
    din9?: string;
    dout9?: string;
    din10?: string;
    dout10?: string;
    din11?: string;
    dout11?: string;
    din12?: string;
    dout12?: string;
    barc5_1?: string;
    barc5_2?: string;
    barc5_3?: string;
    barc5_4?: string;
    barc5_5?: string;
    barc5_6?: string;
    barc5_7?: string;
    barc5_8?: string;
    barc5_9?: string;
    barc5_10?: string;
    barc5_11?: string;
    barc5_12?: string;
    af?: string;
    eisaData?: string;

    getDoutForMonth(month: number | undefined): string | undefined {
        switch (month) {
            case 1:
                return this.dout1;
            case 2:
                return this.dout2;
            case 3:
                return this.dout3;
            case 4:
                return this.dout4;
            case 5:
                return this.dout5;
            case 6:
                return this.dout6;
            case 7:
                return this.dout7;
            case 8:
                return this.dout8;
            case 9:
                return this.dout9;
            case 10:
                return this.dout10;
            case 11:
                return this.dout11;
            case 12:
                return this.dout12;
            default:
                return;
        }
    }

    getDinForMonth(month: number | undefined): string | undefined {
        switch (month) {
            case 1:
                return this.din1;
            case 2:
                return this.din2;
            case 3:
                return this.din3;
            case 4:
                return this.din4;
            case 5:
                return this.din5;
            case 6:
                return this.din6;
            case 7:
                return this.din7;
            case 8:
                return this.din8;
            case 9:
                return this.din9;
            case 10:
                return this.din10;
            case 11:
                return this.din11;
            case 12:
                return this.din12;
            default:
                return;
        }
    }

    getNbjisForMonth(month: number | undefined): number | undefined {
        switch (month) {
            case 1:
                return this.nbjis1;
            case 2:
                return this.nbjis2;
            case 3:
                return this.nbjis3;
            case 4:
                return this.nbjis4;
            case 5:
                return this.nbjis5;
            case 6:
                return this.nbjis6;
            case 7:
                return this.nbjis7;
            case 8:
                return this.nbjis8;
            case 9:
                return this.nbjis9;
            case 10:
                return this.nbjis10;
            case 11:
                return this.nbjis11;
            case 12:
                return this.nbjis12;
            default:
                return;
        }
    }
}
