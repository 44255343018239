import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@root/environments/environment';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';

import {AvsNumber} from '../models/avs-number.model';

@Injectable()
export class AvsNumberService {
    private cache = new Map<string, Observable<boolean>>();

    constructor(private _http: HttpClient) {
    }

    public validateAVSForPpempId(avs: string, empId: string): Observable<AvsNumber> {
        const url = `/api/validate/avs`;
        const data = {
            avs, empId
        };
        return this._http.post<AvsNumber>(url, data);
    }

    // fixme ULTIMATE : below is version from operations. Will maybe need to sync both methodes, but in the back it is done differently
    public validateAVS(avs: string): Observable<boolean> {
        const url = `saemp/avs/validate`;
        const data = {avs};

        // Check if the value is in the cache
        if (this.cache.has(avs)) {
            return this.cache.get(avs) as Observable<boolean>;
        }

        // If not in cache, make the HTTP request
        const request = this._http.get<boolean>(environment.backendURL + url, {params: data})
            .pipe(tap(result => {
                // Store the result in the cache
                this.cache.set(avs, of(result));
            }));

        // Store the request Observable in the cache
        this.cache.set(avs, request);

        return request;
    }

}
