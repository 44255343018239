<iw-table-edit [entity]="entity" [formId]="uuid" [height]="'150px'" [query]="query"
               [saveValid]="isValid"></iw-table-edit>
<div class="row">
    <div class="col-xs-10">
        <form [formGroup]="formGroup" class="row">
            <iw-enum-dropdown [inputEnum]="sexe" [isDisabled]="!isWriteMode" [labelAlign]="'top'"
                              [label]="'sexe' | translate" class="iw-input col-xs-12 padding-top-5"
                              formControlName="sexe"></iw-enum-dropdown>
            <iw-textfield #txtNom [isDisabled]="!isWriteMode" [labelAlign]="'top'" [label]="'nom' | translate"
                          class="iw-input col-xs-6" formControlName="nom"></iw-textfield>
            <iw-textfield #txtPrenom [isDisabled]="!isWriteMode" [labelAlign]="'top'" [label]="'prenom' | translate"
                          class="iw-input col-xs-6" formControlName="prenom"></iw-textfield>
            <iw-date-picker #txtDateNaiss [isDisabled]="!isWriteMode" [labelAlign]="'top'"
                            [label]="'naissance' | translate" [maxDate]="dateNow" class="iw-input col-xs-6"
                            formControlName="datenaiss"></iw-date-picker>
            <iw-textfield #txtAge [isDisabled]="true" [label]="' '" [value]="age + ' ans'"
                          class="iw-input col-xs-3"></iw-textfield>
            <iw-textfield #txtNoavs13 [isDisabled]="!isWriteMode" [labelAlign]="'top'" [label]="'noAvs13' | translate"
                          [mask]="'000.0000.0000.00'" class="iw-input col-xs-6"
                          formControlName="noavs13"></iw-textfield>

            <iw-checkbox #chkAcharge [isDisabled]="!isWriteMode" [label]="'aCharge' | translate"
                         class="iw-input col-xs-8 padding-top-10" formControlName="acharge"></iw-checkbox>
            <iw-checkbox #chkAllocation [isDisabled]="!isWriteMode" [label]="'allocation' | translate"
                         class="iw-input col-xs-8 padding-top-10" formControlName="allocation"></iw-checkbox>
            <div class="col-xs-4"></div>
            <iw-date-picker #txtDateNaiss [isDisabled]="!isWriteMode || !hasACharge" [labelAlign]="'top'"
                            [label]="'alfBegin' | translate" [value]="alfBegin"
                            class="iw-input col-xs-4"></iw-date-picker>
            <iw-date-picker #txtDateNaiss [isDisabled]="!isWriteMode || !hasACharge" [labelAlign]="'top'"
                            [label]="'alfEnd' | translate" class="iw-input col-xs-4"
                            formControlName="alfEnd"></iw-date-picker>
            <iw-textfield #txtPrenom [isDisabled]="!isWriteMode || !hasACharge" [labelAlign]="'top'"
                          [label]="'alfMnt' | translate" class="iw-input col-xs-4"
                          formControlName="alfMnt"></iw-textfield>
            <iw-textfield [decimals]="2" [isDisabled]="!isWriteMode" [labelAlign]="'top'"
                          [label]="'alfuniq' | translate" class="iw-input col-xs-4" formControlName="alfuniqMnt"
                          type="number"></iw-textfield>
        </form>
    </div>
    <div class="col-xs-2 flex flex-v flex-end">
        <iw-button (press)="onClose()" [text]="'fermer' | translate" class="btn-block"></iw-button>
    </div>
</div>
