import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store/models';
import {ScheduleEvent} from '@app/sam-base/models/placement';

import {
    ScheduleEventComponent
} from '../components/schedule-event/schedule-event.component';

export const SCHEDULE_EVENT_DEFAULT_FORM: FormDescription<ScheduleEvent> = {
    form: ScheduleEventComponent, state: {
        ...buildDefaultState(ScheduleEvent),
        diagTitle: 'schedule_event_form_title',
        diagModal: true,
        width: 1000,
        editMode: 'waiting'
    }
};
