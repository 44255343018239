import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Pprap} from '@app/sam-base/models/placement';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const rapportTabsFormControls: TabFormControl<Pprap>[] = [{
    formControls: ['rapId', 'salId', 'modecreat', 'empId', 'datelundi', 'cliId', 'rapNo', 'session', 'misId']
}, {
    title: 'recap',
    formControls: ['nbheutot', 'nbheuequip', 'nbheuequix', 'mntheuemp', 'mntheucli', 'mntindemp', 'mntindcli', 'chantier', 'finmission', 'finmotif', 'lnoaco', 'sent2Fac', 'facNo', 'facDate', 'facId', 'faclintxt', 'sent2Sal']
}, {
    title: 'heures', formControls: ['heusaimode']
}, {
    title: 'indemnites', formControls: []
}, {
    title: 'notes', formControls: ['note', 'ijrapId', 'ijNote']
}];
