export class Ppclistats {
    public dateA?: string;
    public dateDe?: string;
    public nbemp?: string;
    public nbcdf?: string;
    public nbcde?: string;
    public nbplf?: string;
    public cafixeht?: string;
    public nbmis?: string;
    public nbrap?: string;
    public nbheu?: string;
    public caht?: string;
    public margebrute?: string;
    public coeff?: string;
    public coeffcustom?: string;
    public firstMis?: Date;
    public lastMis?: Date;
}
