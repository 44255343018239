import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('sadedtx')
export class Sadedtx {
    @IwRestEntityId() public id?: number;
    @IwColumn({name: 'annee', index: 3}) public annee?: string;
    public dedCode?: string;
    @IwColumn({name: 'assCode', index: 2}) public assCode?: string;
    @IwColumn({name: 'code', index: 0}) public txCode?: string;
    @IwColumn({name: 'titre', index: 1}) public titre?: string;
    public txcot?: number;
    public txcotH?: number;
    public txcotF?: number;
    public txcoe?: number;
    public txcoeH?: number;
    public txcoeF?: number;
    public salmin?: number;
    public salmax?: number;
    public agemin?: number;
    public agemax?: number;
    public agemnt?: number;
    public txNs?: number;
    public txActive?: number;
    public titre1?: string;
    public titre2?: string;
    public titre3?: string;
    public titre4?: string;
    public titre5?: string;
    public remarque?: string;
}
