import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Gepaicli} from '@app/sam-base/models/invoices/gepaicli';
import {environment} from '@root/environments/environment';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class GepaicliService extends RestClient<Gepaicli> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getGepaicli(payId: string): Observable<Gepaicli> {
        return this._http.get<Gepaicli>(environment.backendURL + `gepaicli/payId/${payId}`, undefined);
    };
}
