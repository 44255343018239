import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Lookups} from '@sam-base/models';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class LookupsService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getAll(): Observable<Lookups[]> {
        return this._http.get<Lookups[]>(this._baseUrl + `lookups/getAll`);
    }

    public getLookupsByName(lkupname?: string): Observable<Lookups[]> {
        return this._http.get<Lookups[]>(this._baseUrl + `lookups/getLookupsByName/${lkupname}`);
    }

    public getLookupsNames(): Observable<string[]> {
        return this._http.get<string[]>(this._baseUrl + `lookups/getLookupsNames`);
    }

    public save(lookups?: Lookups): Observable<void> {
        return this._http.post<void>(this._baseUrl + `lookups/save`, lookups);
    }

    public delete(lkupname?: string, cvalue?: string): Observable<void> {
        return this._http.delete<void>(this._baseUrl + `lookups/delete/${lkupname}/${cvalue}`);
    }

}
