import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {UuidService} from '@app/sam-base/core/uuid/uuid.service';
import {Sapar} from '@app/sam-base/models/salary/sapar';
import {environment} from '@root/environments/environment';
import {Template} from '@sam-base/models/placement';
import {Subject} from 'rxjs/internal/Subject';

@Component({
    selector: 'iw-tab-sapar-formats',
    templateUrl: './tab-sapar-formats.component.html'
})
export class TabSaparFormatsComponent extends BasePartialFormComponent<Sapar> implements OnInit, OnDestroy {
    public defaultInvoicePrint?: number = 3;
    public defaultSalaryPrint?: number = 7;
    public salPrintTemplate = new Template();
    public invoicePrintTemplate = new Template();
    public isSalaire = true;
    public subscriptions = new Subject();
    protected readonly _baseUrl: string;
    private uuid = this._uuidService.generateString();

    constructor(private readonly _http: HttpClient, private _store: IwStoreService,
                private readonly _uuidService: UuidService, private readonly _toastService: ToastService) {
        super();
        this._baseUrl = environment.backendURL;
    }

    public ngOnInit(): void {
        this._http.get<string>(this._baseUrl + 'gepar/invoicetemplate')
            .subscribe((tempId: string) => {
                this.defaultInvoicePrint = +tempId;
            });
        this._http.get<string>(this._baseUrl + 'sapar/salarytemplate')
            .subscribe((tempId: string) => {
                this.defaultSalaryPrint = +tempId;
            });
    }

    public ngOnDestroy(): void {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public changeValueDropdownSalary(val: Template) {
        this.salPrintTemplate = val;
    }

    public changeValueDropdownInvoice(val: Template) {
        this.invoicePrintTemplate = val;
    }

    public saveChanges() {
        const data: DefaultTempalte = <DefaultTempalte>{
            defaultSalaryPrintTemplate: this.salPrintTemplate.id
        };
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this._http.post<string>(this._baseUrl + 'template/default', data)
            .subscribe({
                next: () => {
                    this.uuid = 'dede';
                    this._store.dispatch(new actions.SetLoading(this.uuid, false));
                },
                error: (error: HttpErrorResponse) => {
                    this._toastService.warning('error_updating_default_templatesg');
                    this._store.dispatch(new actions.SetLoading(this.uuid, false));
                }
            });
    }

}

interface DefaultTempalte {
    defaultInvoicePrintTemplate: number;
    defaultSalaryPrintTemplate: number;
}
