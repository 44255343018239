import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {IwEventHubService} from '@app/sam-base/core/events';
import {StoreEvents} from '@app/sam-base/core/events/actions/store.actions';
import {mapError} from '@app/sam-base/core/logger';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {EntityNavigationService} from '@app/sam-base/core/services/entity-navigation.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {IwStoreService} from '@app/sam-base/core/store';
import * as globalFormActions from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {FormKeys} from '@app/sam-base/models';
import {Ppagence, Ppcde, Ppcli, Ppsecteur} from '@app/sam-base/models/placement';

import {Ppclicdes} from '@app/sam-base/models/placement/ppclicdes';
import {TranslateService} from '@ngx-translate/core';
import {PpconService} from '@sam-base/core';
import {lastValueFrom, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    templateUrl: './cdenew.component.html'
})
export class CdeNewComponent extends BaseStoreFormComponent<Ppcde> implements OnInit, OnDestroy {
    public entityClient = Ppcli;

    private _restSec: RestEntityClient<Ppsecteur>;
    private _restAge: RestEntityClient<Ppagence>;
    private _restPpcde: RestEntityClient<Ppcde>;
    private _value: Ppcde | undefined;
    private _subscripts = new Subject();

    constructor(store: IwStoreService, private _entityNavService: EntityNavigationService, private _translate: TranslateService, private _toastService: ToastService, private _restService: RestApiService, private readonly _modalService: ModalService, private readonly _events: IwEventHubService<StoreEvents>, private _ppconService: PpconService) {
        super(store);
        this._restSec = _restService.getEntityClient(Ppsecteur);
        this._restAge = _restService.getEntityClient(Ppagence);
        this._restPpcde = _restService.getEntityClient(Ppcde);
    }

    public ngOnInit() {
        this._ppconService.fillMainConseiller(this.getFormControl('conId'));
        this._store.globalForm<Ppcde>(this.uuid)
            .entity
            .pipe(takeUntil(this._subscripts))
            .subscribe(e => this._value = e);
    }

    public ngOnDestroy() {
        this._subscripts.next(undefined);
        this._subscripts.complete();
        super.ngOnDestroy();
    }

    public saveChanges() {
        this._restService.getEntityClient(Ppcli)
            .getById(this.getFormValue('cliId'))
            .subscribe((cli: Ppcli) => {
                if (!cli) {
                    return;
                }
                if (cli.clistatus && cli.clistatus > 1) {
                    this.showModal(cli.nom);
                } else {
                    this.saveCde();
                }
            });
    }

    public async showModal(cliNom: string | undefined) {
        const options: ModalMessageOptions = {
            message: [`${this._translate.instant('le_client')} [${cliNom}]
        ${this._translate.instant('nest_actif')}`],
            showCancel: false,
            title: this._translate.instant('ppcdetempo_form_title')
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this.saveCde();
        } catch (error) {
        }
    }

    protected getFormControlNames(): FormKeys<Ppcde> {
        return ['cdeId', ['cliId', new UntypedFormControl('', [Validators.required])], ['conId', new UntypedFormControl('', [Validators.required])]];
    }

    // eslint-disable-next-line complexity
    private async saveCde() {
        const ppcde: Ppcde | undefined = this._value;
        if (!ppcde) {
            return;
        }
        ppcde.qualif = '';
        if (!ppcde.datedebut) {
            ppcde.datedebut = new Date().toISOString();
        }
        if (!ppcde.secId) {
            const sec = await lastValueFrom(this._restSec.getFirst());
            ppcde.secId = (sec && sec.secId) || '';
        }
        if (!ppcde.ageId) {
            const age = await lastValueFrom(this._restAge.getFirst());
            ppcde.ageId = (age && age.ageId) || '';
        }

        this._store.dispatch(new globalFormActions.SetLoading(this.uuid, true));
        const toSave = this._restPpcde.construct(ppcde);
        this._restPpcde.create(toSave)
            .pipe(mapError('Error creating PPCDE'))
            .subscribe(cde => {
                this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
                if (!cde) {
                    const message = this._translate.instant('cdenew_error');
                    this._toastService.error(message);
                    return;
                }

                this._entityNavService
                    .navigateToEntityForm(Ppclicdes, cde.cdeId || '', undefined, 'edit');
                this._events.emit(StoreEvents.EntitySaved, {$entity: 'ppcde', ...cde});
                this.destroyForm();
            });
    }
}
