import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {
    Sasx5decCreationModeComponent
} from '@modules/sam-main/admin/components/swissdec/sasx5dec-creation-mode/sasx5dec-creation-mode.component';
import {
    Sasx5decCreationMode
} from '@modules/sam-main/admin/components/swissdec/sasx5dec-creation-mode/sasx5dec-creation-mode.model';
import {Sasx5dec} from '@sam-base/models/admin/sasx5dec.model';

export const SASX5DEC_CREATE_DEFAULT_FORM: FormDescription<Sasx5dec> = {
    form: Sasx5decCreationModeComponent,
    state: {
        ...buildDefaultState(Sasx5decCreationMode),
        diagModal: false,
        editMode: 'waiting',
        width: 1600
    }
};

