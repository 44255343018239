<div [class.open]="open" class="iw-layout">
    <iw-layout-header [menuEntries]="menuEntries" [version]="appVersion" class="header"></iw-layout-header>
    <div class="body">
        <iw-slide-panel [entrySide]="'top'" [toggleEvent]="SlidePanelEvent" name="entity-panel">
            <ng-content select="[topPanel]"></ng-content>
        </iw-slide-panel>
        <ng-content select="[body]"></ng-content>
    </div>
    <iw-dashboard-toggle [isOpen]="open"
                         [menuEntries]="sideMenuItems" class="toggle"></iw-dashboard-toggle>
</div>
