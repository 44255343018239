import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {mapError} from '@app/sam-base/core/logger';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {Ppmisih} from '@app/sam-base/models/placement';
import {Observable} from 'rxjs';

@Injectable()
export class MissionMisIhService extends RestClient<{}> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public createOrUpdate(misId: string, grhId: string): Observable<Ppmisih | undefined> {
        return this.POST<Ppmisih>({}, undefined, `mission/${misId}/${grhId}`)
            .pipe(mapError('Error updating PPMISIH'));
    }
}
