import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Sads} from '@app/sam-base/models/salary';
import {Observable} from 'rxjs';

import {RestClient} from '../rest-api/core';

@Injectable()
export class SadsService extends RestClient<any> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public createNewEntry(sads: Sads): Observable<Sads> {
        return this._http.post<Sads>(this._baseUrl + `sads`, sads);
    }

    public saveByDsId(dsId: string, sads: Sads): Observable<Sads> {
        return this._http.put(this._baseUrl + `sads/${dsId}`, sads);
    }

    public deleteByDsId(dsId: string): Observable<any> {
        return this._http.delete(this._baseUrl + `sads/${dsId}`);
    }
}
