<form [formGroup]="formGroup" class="row">

    <iw-textarea #edtNoteEmp [height]="80" [isDisabled]="isReadonly" [labelAlign]="'top'"
                 [label]="'remarquesContratDeMission' | translate" class="iw-textarea col-xs-10"
                 formControlName="noteemp"></iw-textarea>

    <iw-textarea #edtNoteCli [height]="80" [isDisabled]="isReadonly" [labelAlign]="'top'"
                 [label]="'remarquesContratDeLocationDeServices' | translate" class="iw-textarea col-xs-10"
                 formControlName="notecli"></iw-textarea>

    <iw-textarea #edtNote [height]="110" [isDisabled]="isReadonly" [labelAlign]="'top'"
                 [label]="'remarquesInternesMission' | translate" class="iw-textarea col-xs-10"
                 formControlName="note"></iw-textarea>
</form>
