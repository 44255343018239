import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store/models';
import {Pplpp} from '@app/sam-base/models/placement';

import {PpheusaisComponent} from '../components/rapports/list-rapports/ppheusais/ppheusais.component';

export const PPHEUSAIS_DEFAULT_FORM: FormDescription<Pplpp> = {
    form: PpheusaisComponent, state: {
        ...buildDefaultState(Pplpp), diagTitle: 'activiteSemaine', diagModal: true, width: 500, editMode: 'waiting'
    }
};
