import { HttpClient } from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {
    CctService,
    DEBUG,
    ERROR,
    IwEventHubService,
    RestEntityClient,
    showGridDetailsAccessRoles
} from '@app/sam-base/core';
import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {RestQueryOperation} from '@app/sam-base/core/rest-api';
import {RestQueryParam} from '@app/sam-base/core/rest-api/models';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {EntityNavigationService} from '@app/sam-base/core/services/entity-navigation.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {getDeepCopy} from '@app/sam-base/helpers/objects-parser';
import {ComboboxItem, FormKeys} from '@app/sam-base/models';
import {Ppcde, Ppcli, Ppclimis, Ppemp, Ppmis, Ppqua, Ppsecteur, ScheduleEvent} from '@app/sam-base/models/placement';

import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {NotZeroValidator} from '@sam-base/core/validations/not-zero.validators';
import {ProfileService} from '@shared/profile/profile.service';
import {lastValueFrom, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {calcMissionEmployeeAge} from '../../helpers/missions.helper';
import {EntitiesToUpdateEnum, EntityUpdateService} from '../../services/entity-update.service';
import {MatchingService} from '../../services/matching.service';
import {MissionSalaryFacturation, MissionStatus} from './mission-enums';
import {ppmisTabsFormControls} from './mission-tabs-formcontrols';


function strToCbp(lst: string[]): ComboboxItem<string>[] {
    return lst.map(e => (<ComboboxItem<string>>{
        value: e,
        name: e
    }));
}

@Component({
    templateUrl: './missions.component.html'
})
export class MissionsComponent extends BaseTabFormComponent<Ppmis> implements OnInit, OnDestroy {

    public sent2Sal = false;
    public sent2Fac = false;
    public hasSal: 'S' | '' = '';
    public hasFac: 'F' | '' = '';
    public hasRapport = false;
    public interijobActive$ = false;
    public disabledTabList: string[] = [];
    public formDirty = false;
    public employeeData?: Ppemp;
    public employeeAge?: string;
    public optionsCodePgm: ComboboxItem<string>[] = [];
    public suvaPeActive = false;
    private misStatusEnum = MissionStatus;
    private tabValues?: TabFormControl<Ppmis>[];
    private subscriptions = new Subject();
    private entityModifiable = false;
    private suvaPeActivationDate?: Date;
    private missionStartDate?: Date;
    private readonly _ppsecRest: RestEntityClient<Ppsecteur>;

    constructor(store: IwStoreService, private readonly _entityNavService: EntityNavigationService,
                private _translate: TranslateService, private _restService: RestApiService,
                private _matchingService: MatchingService, private readonly _cctService: CctService,
                private _http: HttpClient, private readonly _events: IwEventHubService<string>,
                private _entityUpdateService: EntityUpdateService, private _profileService: ProfileService,
                private readonly _parametersService: ParametersService) {
        super(store);
        this.tabValues = getDeepCopy(ppmisTabsFormControls);
        this.setNeedSummaryValidators();
        this.setAutoriserUnTarifAZeroValidator();
        this._ppsecRest = _restService.getEntityClient(Ppsecteur);
    }

    public get misId() {
        return this.getFormValue('misId') || '';
    }

    public get isCreating(): boolean {
        return this.getFormData()?.creating ?? false;
    }

    public get cliId() {
        return this.getFormValue('cliId') || '';
    }

    public get empId() {
        if (!this.entityValue) {
            return;
        }
        return this.entityValue.empId;
    }

    public get tabData(): Ppmis | undefined {
        return this.getFormData();
    }

    public set tabData(v: Ppmis | undefined) {
        this.mergeEntityChanges(v);
    }

    public get salFacStatus() {
        return this.sent2Sal && this.sent2Fac;
    }

    public get hasSalFac(): boolean {
        return this.sent2Fac || this.sent2Sal;
    }

    public ngOnInit() {
        if (this.getFormValue('misId')) {
            this.canUpdateMission(this.getFormValue('misId'));
        }
        this.subscribeValueChange<string>('misId', e => {
            if (!e) {
                this.disabledTabList = [];
                return;
            }
            const groupsId = showGridDetailsAccessRoles('ppclimis');
            if (!this._profileService.checkUserPermission(groupsId)) {
                this.disabledTabList.push('suivi');
            }
            this.resetSalFacStatus();
            this.setSnF();
            this.updateScheduleEvent(e);
            this._events.emit('mission_cliId', this.getFormValue('cliId'));
            this.canUpdateMission(e);
            this.setCodePgmOptions(false);

        });
        this.subscribeValueChange<string>('needSummary', e => this.onNeedSummaryChange(e));
        this._store.getLicenseOption('interijobwebaccess')
            .pipe(takeUntil(this.subscriptions))
            .subscribe((active: boolean) => {
                this.interijobActive$ = active;
                this.showHideInterijob(active);
            });

        this.subscribeValueChange('empId', () => this.setAgeEmp());

        this.checkFormDirty();

        this._matchingService.scheduleEventData
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                if (e.uuid === this.uuid) {
                    this.missionStartDate = e && e.startDate ? new Date(e.startDate) : undefined;
                    this.setFormValue('needSummary', e.data);
                    this.calcCnaCode('scheduleEventData');
                }
            });


        // SUVA
        this._parametersService.getParameterValue('suvaPeActivationDate')
            .subscribe(suvaPeActivationDate => {
                if (suvaPeActivationDate) {
                    this.suvaPeActivationDate = suvaPeActivationDate ? new Date(suvaPeActivationDate as string) : undefined;
                    this.subscribeValueChange('secId', () => this.calcCnaCode('secId'));
                    this.subscribeValueChange('quaId', () => this.calcCnaCode('quaId'));
                } else {
                    this.subscribeValueChange('secId', () => {
                        this.calcCnaCodeFromSecteur();
                    });
                }
            });

    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public openClient() {
        this._entityNavService
            .navigateToEntityForm(Ppcli, this.getFormValue('cliId'));
    }

    public openEmp() {
        this._entityNavService
            .navigateToEntityForm(Ppemp, this.getFormValue('empId'));
    }

    public getMisStatusLabel(): string {
        if (this.getFormValue('misstatus')) {
            let misStatus = this.getFormValue('misstatus');
            if (misStatus < 1) {
                misStatus = 1;
            }
            return this._translate.instant(this.misStatusEnum[misStatus]);
        } else {
            return '';
        }
    }

    // eslint-disable-next-line complexity
    public getMisClass() {
        let misstatus = this.getFormValue('misstatus');
        const ccnbofprt = this.getFormValue('ccnbofprt');
        const cmnbofprt = this.getFormValue('cmnbofprt');

        if (misstatus <= 1) {
            if (ccnbofprt === 0 || cmnbofprt === 0) {
                misstatus = '1-2';
            } else {
                misstatus = '1';
            }
        } else if (misstatus === 2) {
            misstatus = '2';
        } else if (misstatus === 3) {
            misstatus = '3';
        } else if (misstatus === 8) {
            misstatus = '8';
        } else {
            misstatus = '4';
        }
        return 'mis-color-' + misstatus;
    }

    public showCreatedMode(): boolean {
        return this.getFormValue('modecreat') > 2;
    }

    public getOriginalMissionLabel(): string {
        /* eslint-disable max-len */
        switch (this.getFormValue('modecreat')) {
            case '3':
                return this._translate.instant('copie') + ': ' + this.getFormValue('misIdOri');
            case '4':
                return this._translate.instant('avenant') + ': ' + this.getFormValue('misIdOri');
            case '5':
                return this._translate.instant('modeleM') + ': ' + this.getFormValue('misIdOri');
            default:
                return '';
        }
    }

    public getMisIJInfo() {
        if (this.getFormValue('lexpmis2Ij') && this.interijobActive$) {
            return 'InteriJob/' + this.getFormValue('ijRapSm');
        } else {
            return 'Local';
        }
    }

    public getMisIjClass() {
        return this.getFormValue('lexpmis2Ij') && this.interijobActive$;
    }

    public openOriginalMission() {
        this._entityNavService
            .navigateToEntityForm(Ppclimis, this.getFormValue('misIdOri'));
    }

    public getTabsValues(): TabFormControl<Ppmis>[] {
        if (!this.tabValues) {
            return ppmisTabsFormControls;
        }
        return this.tabValues;
    }

    public setSnF() {

        const misId = this.getFormValue('misId');

        this._http.get(environment.backendURL + `mission/salary-facturation/${misId}`)
            .subscribe((data: MissionSalaryFacturation) => {
                this.hasSal = data.hasSalary ? 'S' : '';
                this.hasFac = data.hasFacturation ? 'F' : '';
                this.hasRapport = data.hasRapport || false;
                this.reloadSalaryStatus();
            });
    }

    public openCommande() {
        this._entityNavService
            .navigateToEntityForm(Ppcde, this.getFormValue('cdeId'));
    }

    // eslint-disable-next-line complexity
    public async setAgeEmp() {
        if (this.empId) {
            this.employeeData = await lastValueFrom(this._restService.getEntityClient(Ppemp)
                .getById(this.empId));
        }
        let datenaiss;

        if (this.employeeData) {
            datenaiss = this.employeeData.datenaiss;
        }
        const datedebut = this.getFormValue('datedebut');
        const datefin = this.getFormValue('datefin');

        let ageEmp = '';
        if (datenaiss) {
            ageEmp += calcMissionEmployeeAge(datenaiss) + ' ';
        }
        if (datedebut) {
            ageEmp += 'dm: ' + calcMissionEmployeeAge(datenaiss, datedebut) + ' ';
        }
        if (datefin) {
            ageEmp += 'fm: ' + calcMissionEmployeeAge(datenaiss, datefin);
        }
        this.employeeAge = ageEmp;
    }

    public setCodePgmOptions(setControlValue: boolean) {
        const cctId: string | undefined = this.getFormValue('cctId');
        const empId: string | undefined = this.getFormValue('empId');
        if (!cctId || !empId) {
            return;
        }
        this._cctService.getCodePgmOptions(cctId, empId)
            .pipe(catchError(err => {
                ERROR('Error during salary calculations', [err]);
                return of(false);
            }))
            // eslint-disable-next-line complexity
            .subscribe((options) => {
                if (typeof options !== 'boolean') {
                    this.optionsCodePgm = strToCbp(options.options.split(','));
                    if (setControlValue || this.isCreating) {
                        this.setFormValue('codepgm', options.default);
                    }
                }
            });
    }

    public debug() {
        console.log('this.formGroup', this.formGroup);
        Object.keys(this.formGroup.controls).forEach(key => {
            const control = this.formGroup.controls[key];
            if (control.invalid) {
                console.log(`Invalid control: ${key}`);
            }
        });
    }

    /**
     * Try to update summary if it is empty
     *
     * @protected
     * @param [summary]
     * @returns
     * @memberof MissionsComponent
     */
    protected onNeedSummaryChange(summary?: string) {
        if (summary) {
            return;
        }
        const misId: string | undefined = this.getFormValue('misId');
        if (!misId) {
            return;
        }
        this.updateScheduleEvent(misId);
    }

    protected updateScheduleEvent(e?: string) {
        const schedEvQuery: RestQueryParam<ScheduleEvent, any>[] = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'parentType',
                value: 'ppmis'
            },
            {
                operation: RestQueryOperation.Equals,
                prop: 'parentId',
                value: e
            }];
        this._restService.getEntityQuery(ScheduleEvent, ...schedEvQuery)
            .scroll()
            .subscribe((elem) => {
                this.missionStartDate = elem[0] && elem[0].dateStart ? new Date(elem[0].dateStart) : undefined;
                this._matchingService.updateNeedSummary(elem[0], this.uuid);
            });
    }

    protected getValidationType() {
        return Ppmis;
    }

    protected validateFields(e: Ppmis): boolean {
        return super.validateFields(e);
    }

    protected getFormControlNames(): FormKeys<Ppmis> {
        return this.getTabsFormControls(ppmisTabsFormControls);
    }

    protected validateReadonlyStatus(e?: Ppmis) {
        if (!e) {
            return true;
        }
        const status = e.misstatus || 0;
        return [
            3,
            4].includes(status) || !this.entityModifiable;
    }

    private async reloadSalaryStatus() {
        this.sent2Sal = this.hasSal === 'S';
        this.sent2Fac = this.hasFac === 'F';
    }

    private resetSalFacStatus() {
        this.sent2Sal = true;
        this.sent2Fac = true;
    }

    private showHideInterijob(active: boolean) {
        const controls = this.tabValues ?? getDeepCopy(ppmisTabsFormControls);

        controls.forEach((control: TabFormControl<Ppmis>) => {
            if (control.title === 'ij') {
                control.show = active;
            }
        });

        this.refreshTabList();
    }

    private checkFormDirty() {
        this.dirtyChanged
            .pipe(takeUntil(this.subscriptions))
            .subscribe(dirty => {
                this.formDirty = dirty;
            });
    }

    private setNeedSummaryValidators() {
        this.formGroup.controls['needSummary'].setValidators(Validators.required);
        this.formGroup.controls['needSummary'].updateValueAndValidity();
    }


    // SUVA

    private canUpdateMission(misId?: string) {
        if (!misId) {
            return;
        }
        this._entityUpdateService.canUpdateEntity(EntitiesToUpdateEnum.PPMIS, this.getFormValue('misId'))
            .subscribe((flag: boolean) => {
                this.entityModifiable = flag;
                this.triggerValidation();
            });
    }

    private calcCnaCode(origin: string): void {
        const msg = `calcCnaCode origin=${origin}, isReadonly=${this.isReadonly}, editMode=${this.editMode}, ` + `missionStartDate=${this.missionStartDate}, suvaPeActivationDate=${this.suvaPeActivationDate}`;
        DEBUG(msg);

        this.suvaPeActive = false;
        if (this.suvaPeActivationDate && this.missionStartDate && this.missionStartDate >= this.suvaPeActivationDate) {
            this.suvaPeActive = true;
        }
        if (this.suvaPeActive) {
            this.calcCnaCodeFromProfession();
        } else {
            this.calcCnaCodeFromSecteur();
        }
    }

    private calcCnaCodeFromProfession() {
        DEBUG('calcCnaCodeFromProfession');
        const quaId = this.getFormValue('quaId');
        if (quaId) {
            this._restService.getEntityClient(Ppqua)
                .getById(quaId as string)
                .subscribe(ppqua => {
                    if (this.editMode === 'edit') {
                        this.setFormValue('codecna', ppqua.pe);
                        this.setFormValue('iscoCatCode', ppqua.iscoCatCode);
                    }
                    this.setFormValue('qualif', ppqua.qualif);
                    this.setFormValue('iscoDescriptionFr', ppqua.iscoDescriptionFr);
                });
        } else {
            if (this.editMode === 'edit') {
                this.setFormValue('codecna', '');
                this.setFormValue('iscoCatCode', '');
            }
            this.setFormValue('qualif', '');
            this.setFormValue('iscoDescriptionFr', '');
        }
    }

    private calcCnaCodeFromSecteur() {
        DEBUG('calcCnaCodeFromSecteur');
        this.setFormValue('iscoCatCode', '');
        this.setFormValue('iscoDescriptionFr', '');
        if (this.editMode === 'edit') {
            this._ppsecRest.getById(this.getFormValue('secId') ?? '')
                .subscribe((elem: Ppsecteur) => {
                    if (elem.codecna !== '') {
                        this.setFormValue('codecna', elem.codecna);
                    } else {
                        this._parametersService.getParameterValue('defcodcna')
                            .subscribe((defaultCna) => {
                                this.setFormValue('codecna', defaultCna);
                            });
                    }
                });
        }
    }

    private setAutoriserUnTarifAZeroValidator() {
        this._parametersService.getParameterValue('allotarif0').subscribe(value => {
            if (value === false) {
                this.formGroup.get('clitarif')?.setValidators([
                    Validators.required,
                    NotZeroValidator()]);
            } else {
                this.formGroup.get('clitarif')?.clearValidators();
            }
            this.formGroup.get('clitarif')?.updateValueAndValidity();
        });
    }

}
