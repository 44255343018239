<form [formGroup]="formGroup" autocomplete="no" class="row spaceForm">

    <div class="row col-xs-8 separator-right">

        <iw-textfield-gecliatt #txtResp [cliId]="cliId" [isDisabled]="!cliId || isReadonly" [labelAlign]="'top'"
                               [label]="'adresse_attn' | translate" [size]="'large'" class="iw-input col-xs-12"
                               formControlName="attn"></iw-textfield-gecliatt>

        <iw-gecliadr-dropdown #cboCliadrId (valueChange)="setAdresse()" [isDisabled]="isReadonly" [labelAlign]="'top'"
                              [label]="'adresse' | translate" [resultFilters]="resultFilters" [size]="'large'"
                              class="iw-input col-xs-12" formControlName="cliAdrId"></iw-gecliadr-dropdown>

        <iw-textarea #txtAreaAdresse [height]="110" [isDisabled]="isReadonly" [value]="adresseValue"
                     class="iw-textarea col-xs-12 txtAreaAdresse"></iw-textarea>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-gepaimode-dropdown #cboBvrMpaId [isDisabled]="isReadonly" [labelAlign]="'left'"
                               [label]="'paimode_bvr' | translate" [onlyLbvr]="true"
                               class="iw-input col-xs-12 padding-top-5"
                               formControlName="bvrMpaId"></iw-gepaimode-dropdown>

        <iw-textfield #txtNoRefBvr [isDisabled]="isReadonly" [mask]="'000000 00000000 00000000 0 0 000'"
                      class="iw-input col-xs-12 padding-top-5" formControlName="noRefBvr"></iw-textfield>

        <iw-textfield #txtNoRefBvr [isDisabled]="isReadonly" [mask]="'00 00000 00000 00000 00000 00000'"
                      class="iw-input col-xs-12" formControlName="noRefBvr"></iw-textfield>

    </div>
    <div class="row col-xs-4 fit-content">

        <div *ngIf="factors" class="row col-xs-12">
            <iw-checkbox #chbNonFactors [labelAlign]="'right'" [label]="'non_factors' | translate"
                         class="iw-input col-xs-12" formControlName="nonFactors"></iw-checkbox>

            <iw-textfield #txtMutFactors [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="''"
                          class="iw-input col-xs-2 no-padding txtMutFactors"
                          formControlName="mutFactors"></iw-textfield>

            <iw-textfield #txtBnoFactors [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="''"
                          class="iw-input col-xs-6 no-padding" formControlName="bnoFactors"></iw-textfield>
        </div>

        <div *ngIf="yellowBill === 'ON'" class="row col-xs-12">
            <iw-checkbox #chbYellowBill [labelAlign]="'right'" [label]="'yellow_bill' | translate"
                         class="iw-input col-xs-12" formControlName="ybActif"></iw-checkbox>

            <iw-textfield #txtYbStatus [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="''"
                          class="iw-input col-xs-2 no-padding txtMutFactors" formControlName="ybStatus"></iw-textfield>

            <iw-textfield #txtYbDtSend [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="''"
                          class="iw-input col-xs-8 no-padding" formControlName="ybDtSend"></iw-textfield>

        </div>

    </div>

    <div class="col-xs-12 separator-top no-padding"></div>

    <div class="row col-xs-8 margin-top-5">

        <iw-textfield #txtFacType [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="''"
                      class="iw-input col-xs-1 no-padding txtMutFactors" formControlName="facType"></iw-textfield>

        <iw-textfield #txtOrigine [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="''" [value]="getTxtOrigine()"
                      class="iw-input col-xs-2 no-padding"></iw-textfield>

        <iw-textfield #txtOrigineText [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="''"
                      [value]="getTxtOrigineText()" class="iw-input col-xs-3 no-padding"></iw-textfield>

        <iw-textfield #txtFacStatus [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="''"
                      class="iw-input col-xs-1 no-padding" formControlName="facStatus"></iw-textfield>

        <iw-textfield #txtModeCreat [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="''"
                      class="iw-input col-xs-1 no-padding" formControlName="modeCreat"></iw-textfield>

        <iw-textfield #txtFacId [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="''"
                      class="iw-input col-xs-2 no-padding" formControlName="facId"></iw-textfield>

        <iw-textfield #txtModified [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="''"
                      class="iw-input col-xs-1 no-padding txtModified" formControlName="modified"></iw-textfield>

    </div>

    <div class="row col-xs-4 margin-top-5">

        <iw-textfield #txtParent [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="''"
                      class="iw-input col-xs-6 padding-right-10" formControlName="parent"></iw-textfield>

        <iw-textfield #txtParent2 *ngIf="parent2" [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="''"
                      class="iw-input col-xs-6" formControlName="parent2"></iw-textfield>
    </div>


</form>
