import {Component, OnInit, Type, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {IwStoreService} from '@app/sam-base/core';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {SaparassService, SaparassSubscrition} from '@app/sam-base/core/services/saparass.service';
import {SapardedService} from '@app/sam-base/core/services/saparded.service';
import {UtilsService} from '@app/sam-base/core/services/utils.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ComboboxItem, FormKeys} from '@app/sam-base/models';
import {Saparass} from '@app/sam-base/models/placement/saparass';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {salaryMgtRoles} from '@sam-base/core/auth/access-rules/facture-roles';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {catchError, Subject, takeUntil} from 'rxjs';

@Component({
    templateUrl: './assurances.component.html'
})
export class AssurancesComponent extends BaseStoreFormComponent<Saparass> implements OnInit {

    @ViewChild('saparassGrid') public saparassGrid?: IwRestGridComponent<Saparass>;
    public type: Type<Saparass> = Saparass;
    public query: RestQueryParam<Saparass, string | undefined>[] = [];

    public selectedElem?: Saparass;
    public editing = false;
    public newEntryMode = false;

    public dedCodeOptions: ComboboxItem<string>[] = [];
    public laaAssOptions: ComboboxItem<string>[] = [];
    public lppAssOptions: ComboboxItem<string>[] = [];
    public assCodeValue?: string;
    public assNomValue?: string;
    public assAddress1?: string;
    public assAddress2?: string;
    public assNpa?: string;
    public assLieu?: string;
    public annee?: string;
    public assClient?: string;
    public assContract?: string;
    public assNoCaisse?: string;
    public assIdref?: string;
    public dedCode?: string;
    public assData?: string;
    public sdcxml?: number;
    public sdcdis?: number;
    public incompleteDeclaration?: boolean;
    public readonlyModify = true;
    public isReadonlyField = true;
    public isCodeReadonlyField = true;
    public readonlySave = true;
    public saparass: Saparass = new Saparass();
    public noCaiseMessage = '';

    public subscritions = new Subject();

    public SALAIRE_MGT_ROLES: SamUserRole[] = salaryMgtRoles()

    public constructor(private readonly _saparassService: SaparassService,
                       private readonly _parametersService: ParametersService,
                       private readonly _sapardedService: SapardedService, private readonly _utilsService: UtilsService,
                       protected readonly _store: IwStoreService, private readonly _translate: TranslateService) {
        super(_store);
    }

    public get isLPP(): boolean {
        return this.dedCode === 'LPP';
    }

    public get isAVS(): boolean {
        return this.dedCode === 'AVS';
    }

    public get isLAA(): boolean {
        return this.dedCode === 'LAA';
    }

    public get isLAC(): boolean {
        return this.dedCode === 'LAC';
    }

    public get isIJM(): boolean {
        return this.dedCode === 'IJM';
    }

    public ngOnInit(): void {
        this.getDedCodes();
        // eslint-disable-next-line complexity
        this.subscribeValueChange('dedCode', (val?: string) => {
            this.dedCode = val;
            if (val === 'AVS') {
                this.formGroup.controls['assNocai']
                    .setValidators([
                        Validators.pattern('((?!0{3})^[0-9][0-9]{2})([.])([0-9]{3})$'),
                        Validators.required]);
                this.noCaiseMessage = this._translate.instant('nocaisse_wrong');
            } else {
                this.formGroup.controls['assNocai'].setValidators(Validators.required);
                this.noCaiseMessage = this._translate.instant('nocaisse_required');

            }
            if (val === 'LPP' || val === 'LAA') {
                this.formGroup.controls['validstart'].setValidators(Validators.required);
                this.formGroup.controls['ofsnr'].setValidators(Validators.required);

            } else {
                this.formGroup.controls['validstart'].clearValidators();
                this.formGroup.controls['ofsnr'].clearValidators();
            }
            if (val === 'LPP' || val === 'LAC' || val === 'IJM' || val === 'LAA') {
                this.formGroup.controls['assNocta'].setValidators(Validators.required);
            } else {
                this.formGroup.controls['assNocta'].clearValidators();
            }
            this.formGroup.controls['assNocai'].updateValueAndValidity();
            this.formGroup.controls['validstart'].updateValueAndValidity();
        }, 100);

        this.subscribeValueChange('assCode', (e?: string) => {
            if (e !== '') {
                this.readonlyModify = false;
            }
        }, 100);

        this.setAnnee();
    }

    public getSelectedRow(event?: Saparass[]) {

        this.isReadonlyField = true;
        this.isCodeReadonlyField = true;

        if (!event?.length) {
            this.clearForm();
            this.selectedElem = undefined;
            return;
        }

        this.selectedElem = event[0];
        this.fillFormData(<any>event[0]);
        this.getInfo(event);
        this.editing = false;
    }

    public getInfo(data: Saparass[]) {
        if (data.length > 0) {
            this.fillFormData(data[0]);
            const codeOptions = this.dedCodeOptions
                .filter(dedCode => dedCode.value === data[0].dedCode);
            this.setFormValue('dedCode', codeOptions[0].value);
        }
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public newEntry() {
        this.newEntryMode = true;
        this.setFormValue('dedCode', '');
        this.isReadonlyField = false;
        this.readonlySave = false;
        this.isCodeReadonlyField = false;
        this.readonlyModify = true;
        this.assCodeValue = '';
        this.assNomValue = '';
        this.assAddress1 = '';
        this.assAddress2 = '';
        this.assNpa = '';
        this.assLieu = '';
        this.assClient = '';
        this.assContract = '';
        this.assNoCaisse = '';
        this.assIdref = '';
        this.assData = '';
        this.sdcxml = 0;
        this.sdcdis = 0;
        this.incompleteDeclaration = false;
    }

    public modifySaparass() {
        this.isReadonlyField = false;
    }

    public deleteEntry() {
        this._saparassService
            .deleteSaparass(this.annee, this.getFormValue('assCode'))
            .subscribe(() => this.saparassGrid?.refresh());
    }

    public loadEntry() {
        this.saparass = this.selectedElem ?? new Saparass();
        this.selectedElem = undefined;
        this.isCodeReadonlyField = true;
        this.isReadonlyField = true;
        this.newEntryMode = false;
    }

    public saveEntry() {
        this.setFormValue('annee', this.annee);
        this._saparassService
            .saveSaparass(this.formGroup.value)
            .subscribe(() => {
                catchError(async () => this.newEntryMode = false);
                this._saparassService.saparassSaveSub.next({
                    uuid: this.uuid,
                    saved: true
                });
                this.isReadonlyField = true;
                this.newEntryMode = false;
            });

        this._saparassService.saparassSaveSub
            .pipe(takeUntil(this.subscritions))
            .subscribe((data: SaparassSubscrition) => {
                if (this.uuid === data.uuid && data.saved) {
                    this.saparassGrid?.refresh();
                }
            });
    }

    public enableSaveButton(): boolean {
        return this.readonlySave;
    }

    public sousNoContractDisabled(): boolean {
        return this.isReadonlyField || (this.dedCode !== 'AVS' && this.dedCode !== 'CAF');
    }

    public validstartDisabled(): boolean {
        return this.isReadonlyField || (this.dedCode !== 'LAA' && this.dedCode !== 'LPP');
    }

    public ofsnrAndLppentcDisabled(): boolean {
        return this.isReadonlyField || (this.dedCode !== 'LPP' && this.dedCode !== 'LAA');
    }

    public checkNoofVal(): void {
        if (this._utilsService.calculateNoofValidity(this.getFormValue('ofsnr') || '')) {
            this.formGroup?.controls['ofsnr'].clearValidators();
        } else {
            this.formGroup?.controls['ofsnr'].setValidators(Validators.pattern(/^INVALID$/));
        }
        this.formGroup?.controls['ofsnr'].updateValueAndValidity();
    }

    protected getValidationType() {
        return Saparass;
    }

    protected getFormControlNames(): FormKeys<Saparass> {
        return [
            'annee',
            'assCode',
            'assNom',
            'assAdr1',
            'assAdr2',
            'assNpa',
            'assLieu',
            'assNocli',
            'assNocta',
            'assNocai',
            'assIdref',
            'assData',
            'assSdcxml',
            'assSdcdis',
            'dedCode',
            'validstart',
            'assSubnc',
            'ofsnr',
            'lppentc',
            'laaassu',
            'avsLaaAssCode',
            'avsLppAssCode',
            'avsLaaFrom',
            'avsLppFrom',
            'incompleteDeclaration'];
    }

    private setAnnee() {
        this._parametersService
            .getCurrentSapar()
            .subscribe((sapar) => {
                this.annee = sapar.annee;
                if (sapar.annee) {
                    this.setQuery();
                    this.getLaaassu();
                    this.getLppAssurances();
                }
            });
    }

    private getDedCodes() {
        this._sapardedService.getDedCodes()
            .subscribe((dedcodes: string[]) => {
                dedcodes.forEach((dedCode) => {
                    this.dedCodeOptions.push({
                        name: dedCode,
                        value: dedCode
                    });
                });
            });
    }

    private getLaaassu() {
        this._saparassService.getAssuranceByAnneeAndDedCode(this.annee || '', 'LAA')
            .subscribe((assurances: Saparass[]) => {
                assurances.forEach((ass) => {
                    this.laaAssOptions.push({
                        name: ass.assNom || '',
                        value: ass.assCode + ''
                    });
                });
            });
    }

    private getLppAssurances() {
        this._saparassService.getAssuranceByAnneeAndDedCode(this.annee || '', 'LPP')
            .subscribe((assurances: Saparass[]) => {
                assurances.forEach((ass) => {
                    this.lppAssOptions.push({
                        name: ass.assNom || '',
                        value: ass.assCode + ''
                    });
                });
            });
    }

    private setQuery() {
        this.query = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'annee',
                value: this.annee
            }];
    }

}
