<form [formGroup]="formGroup" class="row space-form">
    <iw-textfield #txtDecId [isDisabled]="true" [value]="id" class="iw-input col-xs-4 no-padding"
                  id="id_employee"></iw-textfield>

    <iw-textfield #txt [isDisabled]="true" [value]="titleInfo"
                  class="iw-input col-xs-6 no-padding label-name"></iw-textfield>

    <iw-textfield #txtROProfession [isDisabled]="true" [value]="status"
                  class="iw-input col-xs-2 no-padding"></iw-textfield>
    <div class="row">
        <iw-busy [isBusy]="isLoading"></iw-busy>
        <div class="row col-xs-12 no-padding-right" style="padding-left: 10%"></div>
    </div>
</form>

<div class="row">
    <div class="col-xs-2">
        <button (click)="debug()">DEBUG</button>
    </div>
</div>


<iw-tab [(selectedTab)]="selectedTab" [invalidTabs]="invalidTabList" [tabs]="tabList">

    <!--TODO handle the isReadOnly correctly-->
    <iw-tab-sasx5dec-general *ngIf="selectedTab?.title === 'general'" [formGroup]="formGroup" [isNew]="isCreateMode"
                             [isReadonly]="isReadonly"></iw-tab-sasx5dec-general>
    <iw-tab-sasx5dec-employees *ngIf="selectedTab?.title === 'employees'" [formGroup]="formGroup"
                               [isReadonly]="isReadonly"></iw-tab-sasx5dec-employees>
    <iw-tab-sasx5dec-assurances *ngIf="isCreateMode" [formGroup]="formGroup"
                                [hidden]="selectedTab?.title !== 'assurances'"></iw-tab-sasx5dec-assurances>
    <iw-tab-sasx5dec-institutions *ngIf="selectedTab?.title === 'institutions'"
                                  [formGroup]="formGroup"></iw-tab-sasx5dec-institutions>
    <iw-tab-sasx-xml *ngIf="selectedTab?.title === 'xmlSent'" [xmlContent]="xmlSent"></iw-tab-sasx-xml>
    <iw-tab-sasx-xml *ngIf="selectedTab?.title === 'xmlReceived'" [xmlContent]="xmlReceived"></iw-tab-sasx-xml>
    <iw-tab-sasx5dec-viewgen *ngIf="selectedTab?.title === 'viewgen'" [formGroup]="formGroup"></iw-tab-sasx5dec-viewgen>

</iw-tab>
