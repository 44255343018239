<form [formGroup]="formGroup"
      class="row">
    <div class="row space-form col-xs-7">
        <div class="col-xs-12">
            <div class="row">
                <iw-dropdown #drpCodeImp
                             (valueChange)="onCodeImpChange($event)"
                             [isDisabled]="isReadonly"
                             [labelAlign]="'top'"
                             [label]="'codeImpot' | translate"
                             [options]="impotOptions"
                             class="iw-input col-xs-6"
                             formControlName="codeImp"></iw-dropdown>

                <!--        HIDE FOR NOW -->
                <!--        <iw-textfield-->
                <!--          #txtBarc3-->
                <!--          class="col-xs-3 margin-top-auto margin-left-10"-->
                <!--          [noLabel]="true"-->
                <!--          [value]="barc3"-->
                <!--          [isDisabled]="true"-->
                <!--        ></iw-textfield>-->
            </div>
            <div class="row">
                <iw-dropdown #drpNoIsCode
                             (valueChange)="changeValidators()"
                             [isDisabled]="!enableNoIsCodeDropdown"
                             [labelAlign]="'top'"
                             [label]="'special_agreement' | translate"
                             [options]="noIsOptions"
                             class="iw-input col-xs-8"
                             formControlName="noIsCode"></iw-dropdown>
            </div>
            <div class="row">
                <iw-textfield #txtNoIsCatOpen
                              [isDisabled]="!enableIsCatOpen"
                              [label]="'categorieOuverte'"
                              class="iw-input col-xs-8"
                              formControlName="noIsCatOpen"></iw-textfield>
            </div>
            <div class="row">
                <iw-canton-dropdown #drpCodeImp
                                    [isDisabled]="isReadonly || !cantonEnabled"
                                    [labelAlign]="'top'"
                                    [label]="'canton' | translate"
                                    class="iw-input col-xs-8 padding-top-10"
                                    formControlName="cantonimp"></iw-canton-dropdown>
            </div>
            <div class="row">
                <iw-dropdown *ngIf="!noIsCode"
                             [hintMessage]="baremHint"
                             [isDisabled]="!isBaremEditable"
                             [labelAlign]="'top'"
                             [label]="'bareme' | translate"
                             [options]="baremOptions"
                             class="iw-input col-xs-5"
                             formControlName="baremImp"></iw-dropdown>
                <iw-textfield *ngIf="noIsCode"
                              [isDisabled]="true"
                              [labelAlign]="'top'"
                              [label]="'bareme' | translate"
                              class="iw-input col-xs-5"
                              formControlName="baremImp"></iw-textfield>

                <iw-checkbox #ckbImpathee
                             [isDisabled]="isReadonly"
                             [labelAlign]="'top'"
                             [label]="'athee' | translate"
                             class="iw-input col-xs-2 margin-left-5 margin-top-25"
                             formControlName="impathee"></iw-checkbox>
                <div class="col-xs-1"></div>
                <iw-textfield #txtImpsalfact
                              [isDisabled]="true"
                              [labelAlign]="'top'"
                              [label]="'facteurTauxAct' | translate"
                              class="iw-input col-xs-3 margin-left-5"
                              formControlName="impsalfact"></iw-textfield>

            </div>

            <div class="row">
                <iw-dropdown #drpModcalcimp
                             (valueChange)="checkValidation($event)"
                             [isDisabled]="isReadonly || enableDrops"
                             [labelAlign]="'top'"
                             [label]="'modeDeCalcul' | translate"
                             [options]="modeCalcOptions"
                             class="iw-input col-xs-6"
                             formControlName="modcalcimp"></iw-dropdown>
                <iw-checkbox #ckbImpmModFroz
                             [isDisabled]="isReadonly"
                             [labelAlign]="'right'"
                             [label]="'bloque' | translate"
                             class="iw-input col-xs-2 margin-left-5 flex-end"
                             formControlName="impmodfroz"></iw-checkbox>
            </div>

            <div class="row">
                <iw-textfield #txtTauxImp
                              [isDisabled]="isReadonly || enableTaux"
                              [labelAlign]="'top'"
                              [label]="'tauxBut' | translate"
                              [type]="'number'"
                              class="iw-input col-xs-3 text-align-right"
                              formControlName="tauxImp"></iw-textfield>
                <iw-textfield #txtTxImpCom
                              *ngIf="this.getFormValue('cantontrav') === 'VS'"
                              [isDisabled]="isReadonly || enableTxImp"
                              [labelAlign]="'top'"
                              [label]="'tauxCommunal' | translate"
                              class="iw-input col-xs-3 margin-left-5"
                              formControlName="txImpCom"></iw-textfield>
                <iw-button #butSearch
                           (press)="openSearch()"
                           *ngIf="!isReadonly"
                           [isDisabled]="isReadonly || enableTaux"
                           [text]="'search' | translate"
                           class="col-xs-3 margin-top-auto margin-left-5"></iw-button>
            </div>
            <div class="row">
                <!--  Grant tax at source  -->
                <iw-checkbox #ckbImpGrant
                             [isDisabled]="isReadonly || !isSoumisImpot"
                             [labelAlign]="'right'"
                             [label]="'impGrant' | translate"
                             class="iw-input col-xs-12"
                             formControlName="impGrant"></iw-checkbox>

            </div>

            <div class="row">
                <iw-button #butMajISDM
                           (press)="calculateMaj()"
                           [isDisabled]="!isReadonly"
                           [text]="'majISDM' | translate"
                           class="col-xs-3 margin-top-10"></iw-button>
            </div>

            <div class="row">
                <iw-textarea #txtNotes
                             [height]="100"
                             [height]="120"
                             [isDisabled]="isReadonly"
                             [label]="'notesImpots' | translate"
                             class="iw-textarea col-xs-10 padding-top-15"
                             formControlName="impNote"></iw-textarea>
            </div>

        </div>
    </div>

    <div class="row space-form col-xs-5">
        <iw-textfield #txtHabitation
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'habitation' | translate"
                      [value]="habOfsnr"
                      class="iw-input col-xs-10 padding-top-10"></iw-textfield>

        <iw-canton-dropdown #cboCantonTrav
                            [isDisabled]="isReadonly"
                            [labelAlign]="'top'"
                            [label]="'cantonTravail' | translate"
                            class="iw-input col-xs-10 padding-top-10"
                            formControlName="cantontrav"></iw-canton-dropdown>

        <iw-textfield-lookups #txtComTravail
                              [isDisabled]="isReadonly"
                              [labelAlign]="'top'"
                              [label]="'communeTravail' | translate"
                              [lookupName]="'COMMUNE'"
                              class="iw-input col-xs-10 padding-top-10"
                              formControlName="commutrav"></iw-textfield-lookups>


        <ng-container *ngIf="isSwissCountry">
            <iw-canton-dropdown #cboCantonHab
                                [isDisabled]="isReadonly"
                                [labelAlign]="'top'"
                                [label]="'cantonHabitation' | translate"
                                class="iw-input col-xs-10 padding-top-10"
                                formControlName="cantonhab"></iw-canton-dropdown>
        </ng-container>
        <ng-container *ngIf="!isSwissCountry">
            <iw-textfield #txtCantonHab
                          [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'cantonHabitation' | translate"
                          class="iw-input col-xs-10 padding-top-10"
                          formControlName="cantonhab"
                          value="EX"></iw-textfield>
        </ng-container>

        <iw-textfield-lookups #txtCommHabit
                              [isDisabled]="isReadonly"
                              [labelAlign]="'top'"
                              [label]="'communeHabitation' | translate"
                              [lookupName]="'COMMUNE'"
                              class="iw-input col-xs-10 padding-top-10"
                              formControlName="commune"></iw-textfield-lookups>

        <iw-textfield #txtTaxId
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'taxId' | translate"
                      class="iw-input col-xs-10 padding-top-10"
                      formControlName="taxId"></iw-textfield>
        <small *ngIf="taxIdInvalid"
               class="iw-input col-xs-10 padding-left-0"
               style="color: #ff0000; font-size:10px"> {{ taxIdInvalidMessage | translate }} </small>

        <iw-date-picker [isDisabled]="isReadonly"
                        [labelAlign]="'top'"
                        [label]="'depuis' | translate"
                        class="iw-input col-xs-6 padding-top-10"
                        formControlName="crossborderValidAsOf"></iw-date-picker>

        <iw-button #butRecapAnuelle
                   (press)="openRecapissd4()"
                   [isDisabled]="!isReadonly"
                   [text]="'recapAnuelle' | translate"
                   class="col-xs-7 margin-top-10"></iw-button>
        <iw-checkbox #ckbAttfisc
                     [isDisabled]="isReadonly"
                     [labelAlign]="'right'"
                     [label]="'attestationFiascale' | translate"
                     class="iw-input col-xs-8 padding-top-10"
                     formControlName="attfisc"
                     style="display: block"></iw-checkbox>

        <iw-button #butParamSD4
                   (press)="openBtnSD4()"
                   [isDisabled]="!isReadonly"
                   [text]="'parametresSD4' | translate"
                   class="col-xs-7 margin-top-10"></iw-button>
        <iw-checkbox #ckbISEL
                     (valueChange)="setExclude($event)"
                     [isDisabled]="isReadonly"
                     [labelAlign]="'right'"
                     [label]="'exclureISEL' | translate"
                     [value]="exclude"
                     class="iw-input col-xs-8 padding-top-10"
                     style="display: block"></iw-checkbox>
    </div>
</form>
