import {SamUserRole} from '@sam-base/models/admin/sam-user';

/**
 * Defines the roles that can actual entity buttons
 */
export function employeeMgtAccessRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_ADMIN_GOD_MODE
    ];
}

/**
 * Defines the roles that can actual entity buttons
 */
export function employeeCommunicationAccessRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_COM,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

export function rapportCalculateDataAccessRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

/**
 * Defines the roles that can use mission button from employee side menu
 */
export function employeeMissionAccessRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}


export function salaryUserRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_SALAIRE_RW,
        SamUserRole.SAM_SALAIRE_RO,
        SamUserRole.SAM_SALAIRE_ADMIN,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

