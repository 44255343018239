<form *ngIf="formGroupReady" [formGroup]="formGroup" class="row">
    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()" [decimals]="2"
                  [isDisabled]="isReadonly || isGlobalSalaryMode" [label]="'salaire_base' | translate" class="col-xs-7"
                  formControlName="salBase" labelAlign="left" type="number"></iw-textfield>
    <i *ngIf="isSalBaseInvalid" [pTooltip]="salBaseTooltip" class="fas fa-exclamation-triangle init-danger"
       style="margin-left: 0px !important; align-items: center; display: flex;"></i>
    <iw-textfield [isDisabled]="true" [value]="'mois' | translate" class="col-xs-2"></iw-textfield>
    <div class="space-block space-block-6"></div>
    <!-- ATTENTION checkbox component comment out to be included in future version or removed forever -->
    <!-- <iw-checkbox
      class="col-xs-12"
      formControlName="shtxmode"
      (onSelect)="asyncCalc('shtxmode')"
      [label]="'saisie_montants' | translate"
      [isDisabled]="shtxmodeDisabled"
    ></iw-checkbox> -->

    <iw-checkbox (valueSelected)="asyncCalc('l13')" [isDisabled]="isReadonly" [label]="'13eme' | translate"
                 class="col-xs-3" formControlName="l13"></iw-checkbox>
    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()" [decimals]="2"
                  [isDisabled]="!shtxmode" class="col-xs-3" formControlName="mnt13" type="number"></iw-textfield>
    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()" [decimals]="2"
                  [isDisabled]="isReadonly || !is13 || tempdataActivated" class="col-xs-3" formControlName="tx13"
                  suffix="%" type="number"></iw-textfield>
    <iw-checkbox [isDisabled]="isReadonly" [label]="'prov' | translate" class="col-xs-3"
                 formControlName="lprov13"></iw-checkbox>

    <div class="space-block space-block-6"></div>
    <iw-textfield (controlFocus)="!$event ? calculate() : triggerReadyToCalculate()"
                  [isDisabled]="isReadonly || isBaseSalaryMode" [label]="'salaire_global' | translate" class="col-xs-6"
                  formControlName="salGlobal" labelAlign="left"></iw-textfield>
    <iw-enum-dropdown (selectedChanged)="calculate()" (valueSelected)="asyncCalc('shbaseglob')"
                      [inputEnum]="salaryModeEnum" [isDisabled]="isReadonly" class="col-xs-6"
                      formControlName="shbaseglob" label=""></iw-enum-dropdown>
    <div class="space-block space-block-6"></div>
    <iw-textfield [isDisabled]="true" [label]="'salaire_total' | translate" class="col-xs-6" formControlName="salTotal"
                  labelAlign="left"></iw-textfield>
    <iw-textfield [isDisabled]="true" [value]="'mois' | translate" class="col-xs-2"></iw-textfield>
</form>
