<form [formGroup]="formGroup" class="row">
    <iw-textarea #txtProfession (doubleClick)="openQualifSelection()" [allowMaximize]="false" [height]="22"
                 [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'activity_ex' | translate"
                 [multiselTextarea]="true" class="iw-textarea col-xs-4 col-no-padding"
                 formControlName="profession"></iw-textarea>
    <div class="col-xs-8"></div>

    <div class="iw-single-label col-xs-1 padding-top-15">1.</div>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(0)" [label]="'1' | translate"
                  class="iw-input col-xs-1" formControlName="h1"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(1)" [label]="'2' | translate"
                  class="iw-input col-xs-1" formControlName="h2"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(2)" [label]="'3' | translate"
                  class="iw-input col-xs-1" formControlName="h3"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(3)" [label]="'4' | translate"
                  class="iw-input col-xs-1" formControlName="h4"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(4)" [label]="'5' | translate"
                  class="iw-input col-xs-1" formControlName="h5"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(5)" [label]="'6' | translate"
                  class="iw-input col-xs-1" formControlName="h6"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(6)" [label]="'7' | translate"
                  class="iw-input col-xs-1" formControlName="h7"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(7)" [label]="'8' | translate"
                  class="iw-input col-xs-1" formControlName="h8"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(8)" [label]="'9' | translate"
                  class="iw-input col-xs-1" formControlName="h9"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(9)" [label]="'10' | translate"
                  class="iw-input col-xs-1" formControlName="h10"></iw-textfield>
    <div class="iw-single-label col-xs-1 padding-top-15"></div>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(10)" [label]="'11' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h11"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(11)" [label]="'12' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h12"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(12)" [label]="'13' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h13"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(13)" [label]="'14' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h14"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(14)" [label]="'15' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h15"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(15)" [label]="'16' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h16"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(16)" [label]="'17' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h17"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(17)" [label]="'18' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h18"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(18)" [label]="'19' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h19"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(19)" [label]="'20' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h20"></iw-textfield>
    <div class="iw-single-label col-xs-1 padding-top-15"></div>


    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(20)" [label]="'21' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h21"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(21)" [label]="'22' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h22"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(22)" [label]="'23' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h23"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(23)" [label]="'24' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h24"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(24)" [label]="'25' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h25"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(25)" [label]="'26' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h26"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(26)" [label]="'27' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h27"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(27)" [label]="'28' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h28"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(28)" [label]="'29' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h29"></iw-textfield>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(29)" [label]="'30' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h30"></iw-textfield>
    <div class="iw-single-label col-xs-1 padding-top-15"></div>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || isDisabledWRap(30)" [label]="'31' | translate"
                  class="iw-input col-xs-1 padding-top-5" formControlName="h31"></iw-textfield>
    <div class="iw-single-label col-xs-10 padding-top-15"></div>

    <div class="iw-single-label col-xs-1 padding-top-15">2.</div>
    <div class="iw-single-label col-xs-11 padding-top-15">{{ 'written_contract' | translate }}</div>

    <div class="iw-single-label col-xs-1 padding-top-15">3.</div>
    <div class="iw-single-label col-xs-11 padding-top-15">{{ 'duree_hebdo' | translate }}</div>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-checkbox #Standardlogic2 (valueChange)="setDuree($event)" [isDisabled]="isReadonly" [label]="'yes' | translate"
                 class="iw-input col-xs-1 padding-top-10" formControlName="ldureehebd"></iw-checkbox>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || !duree" [label]="'' | translate"
                  class="iw-input col-xs-5 padding-top-10" formControlName="cdureehebd"></iw-textfield>
    <div class="iw-single-label col-xs-5 padding-top-15"></div>

    <div class="iw-single-label col-xs-1 padding-top-15">4.</div>
    <div class="iw-single-label col-xs-11 padding-top-15">{{ 'dur_hebdn' | translate }}</div>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-textfield #txtUser_id [isDisabled]="isReadonly || !duree" [label]="'' | translate"
                  class="iw-input col-xs-6 padding-top-10" formControlName="cdurhebdn"></iw-textfield>
</form>
