<form [formGroup]="formGroup" class="row">

    <iw-textfield #txtCnacodes [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'codesLaaAutorises' | translate"
                  [size]="'large'" class="iw-input col-xs-10 no-label no-padding"
                  formControlName="cnacodes"></iw-textfield>

    <iw-textfield #txtCode_cnale [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'defaut' | translate"
                  class="iw-input col-xs-2 padding-right-15 padding-left-0" formControlName="defcodcna"></iw-textfield>
    <iw-textarea #edtCnacodexpl [height]="45" [isDisabled]="isReadonly" [label]=" '' | translate" [size]="'large'"
                 class="iw-textarea col-xs-9 margin-left-200 " formControlName="cnacodexpl"></iw-textarea>

    <iw-textarea #edtPgmcodesh [height]="22" [isDisabled]="isReadonly" [labelAlign]="'left'"
                 [label]="'codesPgmAutorisesH' | translate" [size]="'large'" class="iw-textarea col-xs-10 no-label"
                 formControlName="pgmcodesh"></iw-textarea>
    <iw-textfield #txtDefcodpgmh [isDisabled]="isReadonly" class="iw-input col-xs-2 padding-right-15 padding-left-0"
                  formControlName="defcodpgmh"></iw-textfield>

    <iw-textarea #edtPgmcodesf [height]="22" [isDisabled]="isReadonly" [labelAlign]="'left'"
                 [label]="'codesPgmAutorisesF' | translate" [size]="'large'"
                 class="iw-textarea col-xs-10 no-label no-padding" formControlName="pgmcodesf"></iw-textarea>
    <iw-textfield #txtDefcodpgmf [isDisabled]="isReadonly" [size]="'large'"
                  class="iw-input col-xs-2 padding-right-15 padding-left-0" formControlName="defcodpgmf"></iw-textfield>
    <div class="col-xs-5"></div>

    <iw-textarea #edtPgmcodexpl [height]="45" [isDisabled]="isReadonly" [label]=" '' | translate" [size]="'large'"
                 class="iw-textarea col-xs-9 margin-left-200" formControlName="pgmcodexpl"></iw-textarea>
    <div class="col-xs-5"></div>

    <iw-textfield #txtCaisselpp [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'assureurLpp' | translate"
                  [size]="'large'" class="iw-input col-xs-10 padding-right-15 padding-left-0"
                  formControlName="caisselpp"></iw-textfield>

</form>
