<form [formGroup]="formGroup"
      class="row space-form">

    <iw-entity-textfield (valueChange)="onEmpIdChange($event)"
                         [allowSearch]="true"
                         [entity]="entityClient"
                         [isDisabled]="readonly || editDisabled"
                         [labelAlign]="'left'"
                         [label]="'employe' | translate"
                         [name]="['nom', 'prenom']"
                         [showIdField]="false"
                         [value]="getEmpId()"
                         class="iw-input col-xs-10"
                         formControlName="empId"
                         id="empId"></iw-entity-textfield>

    <iw-button #empBtn
               (press)="openEmp()"
               [isDisabled]="false"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>

    <iw-agency-combo-box #cboAge_id
                         [isDisabled]="readonly || editDisabled"
                         [labelAlign]="'left'"
                         [label]="'agence' | translate"
                         class="iw-input col-xs-10"
                         formControlName="ageId"
                         id="cboAge_id"></iw-agency-combo-box>

    <div class="row col-xs-6 no-padding">
        <iw-date-picker #txtAcodate
                        [isDisabled]="readonly"
                        [labelAlign]="'left'"
                        [label]="'date' | translate"
                        class="iw-input col-xs-12 no-padding"
                        formControlName="acodate"
                        id="txtAcodate"></iw-date-picker>


        <iw-textfield #txtMontant
                      [isDisabled]="readonly"
                      [labelAlign]="'left'"
                      [label]="'montant' | translate"
                      class="iw-input col-xs-12 no-padding"
                      formControlName="montant"
                      id="txtMontant"
                      stepValue="0.01"
                      type="number"></iw-textfield>

        <p *ngIf="amoutAllowed >= 0 && isNew"
           [style.color]="amoutAllowed < getFormValue('montant') ? 'red' : ''"
           class="iw-input col-xs-12">Limit {{ amoutAllowed.toString() }}</p>

        <p *ngIf="mntNotNew()"
           [style.color]="amoutAllowed < getFormValue('montant')? 'red' : ''"
           class="iw-input col-xs-12">Limit {{ amoutAllowed }}</p>

        <iw-enum-dropdown #cboAcoPayType
                          (selectedChanged)="onAcoPayChanges($event)"
                          [inputEnum]="acopaytype"
                          [isDisabled]="readonly || !isNew"
                          [labelAlign]="'left'"
                          [label]="'payepar' | translate"
                          class="iw-input col-xs-12 no-padding"
                          formControlName="acopaytype"
                          id="cboAcoPayType"></iw-enum-dropdown>
    </div>
    <div *ngIf="currentAcopaytype === 3"
         class="row col-xs-6 no-padding">
        <iw-textarea #dtaInfo
                     [(value)]="dtaDetail"
                     [height]="72"
                     [isDisabled]="true"
                     class="iw-textarea col-xs-12"
                     id="dtaInfo"></iw-textarea>
    </div>

    <div class="row col-no-padding">
        <iw-checkbox #chkAcoPaid
                     [isDisabled]="true"
                     [label]="'acomptePaye' | translate"
                     class="iw-input no-padding col-xs-6"
                     formControlName="acopaid"></iw-checkbox>
        <iw-date-picker #datePmnt
                        [isDisabled]="true"
                        [labelAlign]="'left'"
                        class="iw-input col-xs-6"
                        formControlName="datePmnt"
                        id="datePmnt"></iw-date-picker>
    </div>


    <iw-dropdown #cboMpa_id
                 [isDisabled]="readonly || editDisabled || currentAcopaytype === 3"
                 [labelAlign]="'left'"
                 [label]="'payeDepuis' | translate"
                 [options]="cboMpa"
                 class="iw-input col-xs-10"
                 formControlName="mpaId"></iw-dropdown>


    <iw-textfield #txtMpa_id
                  [isDisabled]="true"
                  class="iw-input col-xs-2"
                  formControlName="mpaId"></iw-textfield>


    <iw-textfield #txtNoCheque
                  [isDisabled]="readonly || editDisabled || currentAcopaytype !== 2"
                  [labelAlign]="'left'"
                  [label]="'noCheque' | translate"
                  class="iw-input col-xs-6"
                  formControlName="nocheque"></iw-textfield>


    <iw-checkbox #chkLnoaco
                 [isDisabled]="readonly || editDisabled"
                 [label]="'horsAcomptesAutomatiques' | translate"
                 class="iw-input col-xs-6"
                 formControlName="lnoaco"></iw-checkbox>


    <iw-textfield #txtLibelle
                  [isDisabled]="readonly || editDisabled"
                  [labelAlign]="'left'"
                  [label]="'libelle' | translate"
                  class="iw-input col-xs-12"
                  formControlName="libelle"></iw-textfield>


    <iw-textarea #edtMisDet
                 [height]="88"
                 [isDisabled]="true"
                 [labelAlign]="'left'"
                 [label]="'mission' | translate"
                 class="iw-textarea col-xs-10"
                 style="padding: 0 7px"></iw-textarea>


    <iw-textfield #txtMis_id
                  [isDisabled]="true"
                  class="iw-input col-xs-2"
                  formControlName="misId"></iw-textfield>

    <h3 class="col-xs-12">{{ 'title_element_in_salary_slip' | translate }}</h3>
    <iw-table
            [columns]="acompteTableColumns"
            [data]="acompteInfo"
            [defaultSelected]="false"
            [isDisabled]="false"
            [isFilterEnable]="false"
            [isGridColumnMenuEnable]="false"
            [isSortIconEnable]="false"
            [selectionMode]="'single'"
            [virtualScroll]="true"
            class="iw-grid col-xs-12 no-padding"
            style="height: 10vh"></iw-table>


</form>
