import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppind} from '@app/sam-base/models/placement';

import {PpindemniteComponent} from '../components/ppheusaid2/ppindemnite/ppindemnite.component';

export const PPINDEMNITE_DEFAULT_FORM: FormDescription<Ppind> = {
    form: PpindemniteComponent, state: {
        ...buildDefaultState(Ppind),
        diagTitle: 'ppindemnite_form_title',
        diagModal: true,
        isNew: true,
        patch: false,
        width: 500
    }
};
