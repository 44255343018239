import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('saepview')
export class SaepView {
    @IwRestEntityId()
    @IwColumn({index: 0})
    public epId?: string;
    public empId?: string;
    @IwColumn({index: 1})
    public nom?: string;
    @IwColumn({index: 2})
    public prenom?: string;
    @IwColumn({index: 3})
    public salId?: string;
    public payType?: number;
    @IwColumn({
        index: 5,
        type: 'mnt',
        align: 'right'
    })
    public montant?: number;
    @IwColumn({
        index: 6,
        type: 'date'
    })
    public datePmnt?: string;
    public dateDelet?: string;
    public banque?: string;
    public banqueCpt?: string;
    public banqueClr?: string;
    public banqueSwi?: string;
    public iban?: string;
    @IwColumn({index: 6})
    public dtaId?: string;
    public noCheque?: string;
    public texte?: string;
    public encaisse?: boolean;
    public origine?: string;
    public parentId?: string;
    public paiement?: string;
    public mpaId?: string;
    @IwColumn({
        index: 7,
        type: 'boolean'
    })
    public paid?: boolean;
    @IwColumn({index: 4})
    public mpaLabel?: string;
    @IwColumn({
        name: 'date_creat',
        type: "dateTime",
        index: 8
    }) public dateCreat?: string;
    @IwColumn({
        name: 'date_modif',
        type: 'dateTime',
        index: 9
    }) public dateModif?: string;
    public mode?: string;
}
