<form [formGroup]="formGroup" class="row">
    <div class="iw-single-label col-xs-1 padding-top-15">5.</div>
    <div class="iw-single-label col-xs-11 padding-top-15">
        {{ 'collectiveLaborAgreement' | translate }}
    </div>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-checkbox #Standardlogic1 (valueChange)="setLaborAgreement($event)" [isDisabled]="isReadonly"
                 [label]="'yes' | translate" class="iw-input col-xs-1 padding-top-10"
                 formControlName="lconvcoll"></iw-checkbox>
    <iw-textfield #txtLabor [isDisabled]="isReadonly || !laborAgreement" class="iw-input col-xs-5 padding-top-10"
                  formControlName="cconvcoll"></iw-textfield>
    <div class="iw-single-label col-xs-5 padding-top-15"></div>

    <div class="iw-single-label col-xs-1 padding-top-15">6.</div>
    <div class="iw-single-label col-xs-11 padding-top-15">
        {{ 'workingHours' | translate }}
    </div>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-checkbox #Standardlogic2 (valueChange)="setExtraHours($event)" [isDisabled]="isReadonly"
                 [label]="'yes' | translate" class="iw-input col-xs-1 padding-top-10"
                 formControlName="lplusdheu"></iw-checkbox>
    <iw-textfield #txtExtraHours [isDisabled]="isReadonly || !extraHours" class="iw-input col-xs-5 padding-top-10"
                  formControlName="cplusdheu"></iw-textfield>
    <div class="iw-single-label col-xs-5 padding-top-15"></div>

    <div class="iw-single-label col-xs-1 padding-top-15">7.</div>
    <div class="iw-single-label col-xs-11 padding-top-15 padding-bottom-10">
        {{ 'insuredPartyRefuse' | translate }}
    </div>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-textarea #edtNote [height]="80" [isDisabled]="isReadonly" class="iw-textarea col-xs-6"
                 formControlName="crefusplus" style="padding-left: 7px; padding-right: 7px"></iw-textarea>
</form>
