import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ModalService} from '@app/sam-base/core/services';
import {Ppagi} from '@app/sam-base/models/placement';
import {PpquamsComponent} from '@shared/widgets/modal-components/ppquams/ppquams.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {MatchingService} from '../../../services/matching.service';

@Component({
    selector: 'iw-tab-14-aginter', templateUrl: './tab-14-aginter.component.html'
})
export class Tab14AginterComponent extends BasePartialFormComponent<Ppagi> implements OnInit, OnDestroy {

    @Input() public isReadonly = true;
    public withRaps = '';
    public duree = false;

    public set professionsToShow(v: string) {
        this._professionsToShow = v;
    }

    public get professionsToShow() {
        return this._professionsToShow;
    }

    private _professionsToShow = '';

    private subscriptions = new Subject();

    constructor(private readonly _modalService: ModalService, private readonly _matchingService: MatchingService) {
        super();
    }

    public ngOnInit() {
        this.formGroup?.controls['profession']
            .valueChanges
            .pipe(takeUntil(this.subscriptions))
            .subscribe((val: string) => {
                if (!val) {
                    this._professionsToShow = '';
                    return;
                }
                this.professionsToShow = val;
            });
        this.formGroup?.controls['jwithraps']
            .valueChanges
            .pipe(takeUntil(this.subscriptions))
            .subscribe((val: string) => {
                this.withRaps = val;
            });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public async openQualifSelection() {
        try {
            const qualifValue = this.getFormValue('profession') || '0';

            const res = await this._modalService.showModal(PpquamsComponent, qualifValue);

            const qualifToSave = res;
            this.setFormValue('profession', qualifToSave);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    }

    /**
     * Check if passed textfield should de disabled based on jwithraps field
     *
     * @param idx (zero based)
     * @returns true if jwithraps not empty on idx
     */
    public isDisabledWRap(idx: number): boolean {
        if (!this.withRaps) {
            return true;
        }
        return this.withRaps.substr(idx, 1) === ' ';
    }

    public setDuree(event: boolean) {
        this.duree = event;
    }
}
