import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {Sapar} from '@app/sam-base/models/salary/sapar';

@Component({
    selector: 'iw-tab-saemp-autre', templateUrl: './tab-saemp-autre.component.html'
})
export class TabSaempAutreComponent extends BasePartialFormComponent<Saempc> {
    @Input() public sapar?: Sapar;
}
