import {Component, OnInit} from '@angular/core';
import {PayacoService} from '@app/modules/sam-main/placement/services/payaco.service';

import {Sapaimode} from '@app/sam-base/models/placement/sapaimode';
import {PeriodeService} from '@modules/sam-main/placement/services/periode.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseStoreFormComponent} from '@sam-base/base';
import {MessageFormRefreshService} from '@sam-base/components/subjects/message-service-subject.service';
import {IwDateHelper} from '@sam-base/core/dates/iw-date-helper';
import {RestApiService} from '@sam-base/core/rest-api/rest-api.service';
import {SaepService} from '@sam-base/core/services/saep.service';
import {SagenService} from '@sam-base/core/services/sagen.service';
import {IwStoreService} from '@sam-base/core/store';
import * as actions from '@sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@sam-base/core/toast';
import {ComboboxItem, FormKeys, sortGridList, TableSortEvent} from '@sam-base/models';
import {IwGridColumn} from '@sam-base/models/components/grid-column.model';
import {Ppper} from '@sam-base/models/placement';
import {PppacoPay} from '@sam-base/models/placement/payacompte';
import {Payment} from '@sam-base/models/placement/payment';
import {PaymentForm} from '@sam-base/models/placement/paymentForm';
import {SaepView} from '@sam-base/models/salary/saepview';
import {Sagen} from '@sam-base/models/salary/sagen';
import {
    PaymentRequest,
    PaymentRequestType,
    PaymentsToPayResponse
} from 'src/app/modules/sam-main/placement/components/acompte/paiement/payment.model';

@Component({
    templateUrl: './payment.component.html'
})
export class PaymentComponent extends BaseStoreFormComponent<PaymentForm> implements OnInit {
    public payacoSelected = 0;
    public selectedPayements: Payment[] = [];
    public libelle?: string;
    public cpainavisOptions: ComboboxItem<string>[] = [];
    public columns: IwGridColumn<PppacoPay>[] = [];
    public isLoading = false;
    public availablePayments: Payment[] = [];
    private tableSortEvent?: TableSortEvent<Payment>;

    constructor(store: IwStoreService, _restService: RestApiService,
                private _translate: TranslateService, private readonly payacoService: PayacoService,
                private readonly _saepService: SaepService,
                private readonly refreshFormMessageService: MessageFormRefreshService,
                private _toastService: ToastService,
                private readonly _periodeService: PeriodeService,
                private readonly _sagenService: SagenService) {
        super(store);
        this.columns = this.getColumns();
        this.cpainavisOptions = [
            {
                name: this._translate.instant('NOA'),
                value: 'NOA'
            },
            {
                name: this._translate.instant('SIA'),
                value: 'SIA'
            },
            {
                name: this._translate.instant('CND'),
                value: 'CND'
            },
            {
                name: this._translate.instant('CWD'),
                value: 'CWD'
            }];
    }

    public get isPpaco(): boolean {
        return this.entityName === 'ppaco';
    }

    public get isSaep(): boolean {
        return this.entityName === 'saepview';
    }

    public get entityName(): string {
        return this.getData('entityName') || '';
    }

    public ngOnInit() {
        this.setFormValue('dateExec', IwDateHelper
            .getCurrentDateMonthFormatDDMMYYYY());
        if (this.isPpaco) {
            this.getAvailableAcosToPay();
        } else if (this.isSaep) {
            this.getAvailableSaepToPay();
        }
    }

    public changeDropdownValue(event: Sapaimode) {
        if (event) {
            this.setFormValue('bank', event.banque);
            this.setFormValue('bankClr', event.banqueClr);
            this.setFormValue('bankCpt', event.banqueCpt);
            let payType = '';
            switch (event.payType) {
                case 6: {
                    payType = 'PAIN';
                    break;
                }
                case 5: {
                    payType = 'OPAE';
                    break;
                }
                default: {
                    payType = 'DTA';
                    break;
                }
            }
            this.setFormValue('npaytype', payType);
        }
    }

    public getAvailableAcosToPay() {
        this._periodeService.getActivePeriod().subscribe((per: Ppper) => {
            this.setFormValue('libelle', per.salId);
            this.libelle = per.salId;
            this.setFormValue('salId', per.salId);
            this.payacoService.getAvailablePpacos(per)
                .subscribe((data: PppacoPay[]) => {
                    this.availablePayments = data.map(d => this.pppacoPayToPayment(d));
                });
        });
    }

    public getAvailableSaepToPay() {
        this._sagenService.getSelectedSagen().subscribe((sagen: Sagen) => {
            this.setFormValue('libelle', sagen.salId);
            this.libelle = sagen.salId;
            this.setFormValue('salId', sagen.salId);
            this._saepService.getAvailableSaepToPay(sagen?.salId || '')
                .subscribe((data: SaepView[]) => {
                    this.availablePayments = data.map(d => this.saepToPayment(d));
                });
        });
    }


    /**
     * Update clients values when selection change
     *
     * @param event selected clients
     */
    public updateClientValues(event: PppacoPay[]) {
        this.payacoSelected = event.length;
        this.selectedPayements = event;
    }

    public async submit() {
        this._store
            .dispatch(new actions.SetLoading(this.uuid, true));
        const params: PaymentRequest = {
            ids: this.selectedPayements.map((elem: Payment) => elem.id)
        };
        let amount = 0;
        this.selectedPayements.forEach(element => {
            amount = amount + element?.montant;
        });
        params.amountsSum = amount;
        params.sapaimodeId = this.getFormValue('bankId'); // SelMpaId
        params.crLf = this.getFormValue('crLf');
        params.paymentRequestType = this.isPpaco ? PaymentRequestType.ACOMPTE : PaymentRequestType.SALAIRE;
        params.libelle = this.getFormValue('libelle');
        params.dateExec = this.getFormValue('dateExec');
        params.cpainavis = this.getFormValue('cpainavisOpt');
        params.salId = this.getFormValue('salId');
        this.payacoService.postPayAcos(params)
            .subscribe((resp: PaymentsToPayResponse) => {
                // todo handle those events
                this.refreshFormMessageService.sendMessage(this.isPpaco ? 'ppaco' : 'saepview');
                this._toastService.success(this._translate.instant('fichier') + ' ' + resp.fileName + ' ' + this._translate.instant('dat_gen_success') + '.');
                this.getAvailableAcosToPay();
                this.payacoSelected = 0;
                this._store
                    .dispatch(new actions.SetLoading(this.uuid, false));
            });
    }

    public formFullfilled() {
        return this.payacoSelected > 0 && this.getFormValue('bankId');
    }

    public onSort($event?: TableSortEvent<Payment>) {
        this.tableSortEvent = $event;
        if (!$event) return;
        this.availablePayments = [...sortGridList(this.availablePayments, $event)];
    }


    protected getFormControlNames(): FormKeys<PaymentForm> {
        return [
            'cpyfrommdl',
            'createmodel',
            'mismdlId',
            'bankId',
            'crLf',
            'cpainavisOpt',
            'dateExec',
            'libelle',
            'salId',
            'bank',
            'bankClr',
            'bankCpt',
            'npaytype'];
    }

    private getColumns(): IwGridColumn<Payment>[] {
        return [
            {
                prop: 'nom',
                name: this._translate.instant('nom'),
                index: 1,
                type: 'string',
            },
            {
                prop: 'prenom',
                name: this._translate.instant('prenom'),
                index: 2,
                type: 'string',
            },
            {
                prop: 'montant',
                name: this._translate.instant('montant'),
                type: 'mnt',
                index: 3
            },
            {
                prop: 'mode',
                name: this._translate.instant('mode'),
                index: 4,
                type: 'string'
            }];
    }

    private saepToPayment(d: SaepView) {
        const payment = new Payment();
        payment.id = d.epId;
        payment.empId = d.empId;
        payment.prenom = d.prenom;
        payment.nom = d.nom;
        payment.montant = d.montant || 0;
        payment.mode = d.mode;
        payment.fullName = `${d.nom} ${d.prenom}`;
        return payment
    }

    private pppacoPayToPayment(d: PppacoPay) {
        const payment = new Payment();
        payment.id = d.acoId;
        payment.empId = d.empId;
        payment.prenom = d.prenom;
        payment.nom = d.nom;
        payment.montant = d.montant || 0;
        payment.mode = d.aconbmod;
        payment.fullName = `${d.nom} ${d.prenom}`;
        return payment
    }
}
