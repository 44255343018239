<form [formGroup]="formGroup" class="row">
    <div class="row col-xs-12">
        <iw-multiautocomplete-qualif #txtProfession [doubleClickable]="true" [isDisabled]="isReadonly"
                                     [labelAlign]="'top'" [label]="'professionQualification' | translate"
                                     [multiple]="true" class="iw-input col-xs-4 col-no-padding"
                                     formControlName="profession" id="qualif"></iw-multiautocomplete-qualif>

        <iw-textfield #edtRegions [isDisabled]="true" [labelAlign]="'top'" [label]="'regions' | translate"
                      class="iw-input col-xs-3" formControlName="regions"></iw-textfield>

        <iw-button #btnRegions (press)="openRegionsSelection()" [isDisabled]="isReadonly" [text]="'TEXT' | translate"
                   class="iw-button col-xs-1 without-label no-padding"></iw-button>

        <iw-ppkw-matching [formId]="formId" [isReadonly]="!entityId || isReadonly"
                          [label]="'mots_clefs' | translate" [parentId]="entityId" [type]="'ppemp'"
                          class="col-xs-4"></iw-ppkw-matching>
    </div>

    <div class="row">
        <div class="col-xs-12">
            <div [style.height]="'250px'" class="iw-table-edit flex flex-h">
                <iw-rest-grid #scheduleReadGrid [autoFormClick]="false" [hasGridColumnMenu]="false"
                              [hasSortIcon]="false" [queryStatements]="query"
                              [type]="gridEntityType" class="flex-grow padding-5"></iw-rest-grid>
            </div>
        </div>
        <div class="col-xs-2">
            <iw-button (press)="absencesClick()" [isDisabled]="!entityId || isReadonly"
                       [text]="'schedule_event_change_btn' | translate" class="padding-left-5"></iw-button>
        </div>
    </div>

</form>

