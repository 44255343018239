<div class="row">
    <div class="col-xs-10" style="height: 300px;">
        <iw-rest-grid #ppdoctypeGrid (selected)="getSelectedRow($event)" (sortInfo)="setSortInfo($event)"
                      [autoFormClick]="false" [defaultSelected]="true" [hasGridColumnMenu]="false"
                      [queryStatements]="query" [queryStatements]="query" [selectedData]="selectedData"
                      [sortDir]="sortPropDir" [sortProp]="sortProp" [type]="type"></iw-rest-grid>
    </div>

    <div class="col-xs-2 flex flex-v flex-evenly">
        <iw-button (press)="createNewEntry()" [isDisabled]="!isReadonly" [text]="'sidemenu_new' | translate"
                   id="sidemenu_new"></iw-button>
        <iw-button (press)="modifyEntry()" [isDisabled]="!isReadonly" [text]="'sidemenu_edit' | translate"
                   id="sidemenu_edit"></iw-button>
        <iw-button (press)="saveEntry()" [isDisabled]="isReadonly" [text]="'sidemenu_save' | translate"
                   id="sidemenu_save"></iw-button>
        <iw-button (press)="cancelEditionMode()" [isDisabled]="isReadonly" [text]="'sidemenu_cancel' | translate"
                   id="sidemenu_cancel"></iw-button>
    </div>

</div>

<form [formGroup]="formGroup" class="row">
    <iw-textfield #txtPdtId [isDisabled]="!newEntryMode" [label]="'ppdoctype_pdtId' | translate"
                  class="iw-input col-xs-8 margin-bottom-5" formControlName="pdtId" id="pdtId"></iw-textfield>

    <div class="col-xs-4"></div>
    <iw-textfield #txtPdtLibelle [isDisabled]="isReadonly" [labelAlign]="'top'"
                  [label]="'ppdoctype_libelle' | translate" class="iw-input col-xs-3" formControlName="pdtLibelle"
                  id="pdtLibelle"></iw-textfield>

    <iw-enum-dropdown #txtPartype [inputEnum]="docType" [isDisabled]="isReadonly" [labelAlign]="'top'"
                      [label]="'ppdoctype_partype' | translate" class="iw-input col-xs-3"
                      formControlName="pdtPartype"></iw-enum-dropdown>

    <iw-checkbox #chkdocMand [isDisabled]="isReadonly" [label]="'obligatoire' | translate"
                 class="iw-input col-xs-2 margin-top-25" formControlName="mandatory"></iw-checkbox>
</form>
<div class="row flex flex-hr">
    <iw-button #Closebutton1 (press)="closeDialog()" [text]="'fermer' | translate"
               class="iw-button col-xs-2 end-xs"></iw-button>
</div>