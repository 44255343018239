<div class="iw-send-sms__container row">
    <div class="table__container col-xs-9 no-padding">
        <iw-table [columns]="columns" [dataKey]="'cliId'" [data]="contacts" [isFilterEnable]="false"
                  [isGridColumnMenuEnable]="false" [isSortIconEnable]="false"></iw-table>
    </div>
    <div class="flex flex-v col-xs-3">
        <iw-button (press)="sendSms()" [iconClass]="buttonOkIcon" [isDisabled]="!buttonEnabled"
                   [text]="buttonOkText | translate" class="btn-block"></iw-button>
        <iw-button (press)="onCancel()" [text]="'cancel' | translate" class="btn-block"
                   style="margin-top: 3px"></iw-button>
    </div>
    <div class="col-xs-9">
        <iw-textarea [(value)]="message" [height]="100" [label]="'label_sms_message' | translate"
                     [maxLength]="maxMessageSize" class="iw-textarea col-xs-12"></iw-textarea>
        <iw-textarea [(value)]="console" [height]="100" [isDisabled]="true" [label]="'label_sms_console' | translate"
                     class="iw-textarea col-xs-12"></iw-textarea>
    </div>

    <div class="flex flex-v col-xs-3">
        <iw-textfield [isDisabled]="true" [label]="' ' | translate" [value]="maxMessageSize - message.length"
                      class="iw-textfield"></iw-textfield>
        <iw-textfield [isDisabled]="true" [label]="'label_sms_count' | translate" [value]="smsCredit"
                      class="iw-textfield"></iw-textfield>
    </div>
</div>
