<form [formGroup]="formGroup" class="row">
    <div class="row col-xs-10">
        <div class="row space-form col-xs-12">
            <iw-checkbox [isDisabled]="!isWriteMode" [label]="'combo_ncs_transport_gratuit' | translate"
                         class="iw-input col-xs-3 padding-top-15" formControlName="ncsF"></iw-checkbox>

            <iw-checkbox [isDisabled]="!isWriteMode" [label]="'combo_ncs_repas_cantine' | translate"
                         class="iw-input col-xs-3 padding-top-15" formControlName="ncsG"></iw-checkbox>

            <iw-textfield [isDisabled]="true" class="iw-input col-xs-2 padding-top-10  col-xs-offset-4"
                          formControlName="annee"></iw-textfield>
        </div>

        <div class="row space-form col-xs-12">
            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'2.3:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT23"></iw-textfield>

            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'3:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT3"></iw-textfield>

            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'4:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT4"></iw-textfield>

            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'7.1:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT71"></iw-textfield>

            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'7.2:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT72"></iw-textfield>
        </div>

        <iw-checkbox [isDisabled]="!isWriteMode" [label]="'combo_ncs_voyage_repas_nuitees' | translate"
                     class="col-xs-10 flex-start" formControlName="ncs1311"></iw-checkbox>

        <iw-button (press)="avancee()" [isDisabled]="!isWriteMode" [text]="'combo_ncs_avancee' | translate"
                   class="iw-button col-xs-2 flex-end padding-bottom-5"></iw-button>

        <div class="row space-form col-xs-12">
            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'13.1.2:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT1312"></iw-textfield>

            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'13.2.3:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT1323"></iw-textfield>

            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'14.1:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT141"></iw-textfield>

            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'14.2:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT142"></iw-textfield>

            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'15.1:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT151"></iw-textfield>

            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'15.2:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT152"></iw-textfield>

            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'15.3:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT153"></iw-textfield>

            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'15.4:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT154"></iw-textfield>

            <iw-textfield [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'15.5:'" [size]="'small'"
                          class="iw-input col-xs-12" formControlName="ncsT155"></iw-textfield>

            <iw-textarea [height]="60" [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'15:'"
                         [size]="'small'" class="iw-textarea col-xs-12 padding-bottom-10"
                         formControlName="ncsT15"></iw-textarea>
        </div>

        <div class="row col-xs-12 padding-top-10">
            <iw-checkbox [isDisabled]="!isWriteMode" [label]="'combo_ncs_nb_jours' | translate"
                         class="iw-input col-xs-3" formControlName="ncsPrtnbj"></iw-checkbox>

            <iw-checkbox [isDisabled]="!isWriteMode" [label]="'combo_ncs_prt_nbh' | translate" class="iw-input col-xs-3"
                         formControlName="ncsPrtnbh"></iw-checkbox>

            <iw-checkbox [isDisabled]="true" [label]="'combo_ncs_1_chf' | translate"
                         class="iw-input col-xs-3"></iw-checkbox>

            <!--  NEW CONTACT  -->
            <div class="row col-xs-12">
                <iw-rest-grid #contactGrid (selected)="getContactNCSRow($event)" [customColumns]="contactNCSColumns"
                              [hasGridColumnMenu]="false" [hasSortIcon]="false" [queryStatements]="contactNCSQuery"
                              [type]="contactNCS" class="col-xs-11 no-padding" style="max-height: 25vh;"></iw-rest-grid>

                <div class="col-xs-1 flex flex-between">
                    <iw-button (press)="openNewContactNCSDialog()" [isDisabled]="!isWriteMode"
                               [tooltip]="'sidemenu_new' | translate" iconClass="fas fa-file"
                               style="padding-bottom: 6px"></iw-button>

                    <iw-button (press)="editContactNCS()" [isDisabled]="!selectedContactNCSRow || !isWriteMode"
                               [tooltip]="'sidemenu_edit' | translate" iconClass="fas fa-pencil-alt"
                               style="padding-bottom: 6px"></iw-button>

                    <iw-button (press)="removeContactNCS()" [isDisabled]="!selectedContactNCSRow || !isWriteMode"
                               [tooltip]="'sidemenu_delete' | translate" iconClass="fas fa-trash"></iw-button>
                </div>
            </div>
            <div class="row col-xs-12">
                <iw-contact-ncs-combo-box [isDisabled]="!isWriteMode" [labelAlign]="'left'"
                                          [label]="'contact' | translate" [size]="'large'" class="iw-combobox col-xs-12"
                                          formControlName="contactNcsId"></iw-contact-ncs-combo-box>
            </div>
        </div>
    </div>
    <div class="space-form col-xs-2">
        <iw-button #btnModif (press)="editEntry()" *ngIf="!isWriteMode" [text]="'sidemenu_edit' | translate"
                   class="iw-button padding-top-10"></iw-button>
        <iw-button (press)="saveEntry()" *ngIf="!!isWriteMode" [text]="'enregistrer' | translate"
                   class="iw-button padding-top-10"></iw-button>
        <iw-button #btnCancel (press)="cancelEditionMode()" *ngIf="isWriteMode" [text]="'sidemenu_cancel' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>
        <iw-button (press)="closeDialog()" [text]="'fermer' | translate" class="iw-button padding-top-10"></iw-button>
    </div>
</form>
