import {defer, finalize, Observable} from 'rxjs';

const prepare = <T>(callback: () => void) => {
    return (source: Observable<T>): Observable<T> =>
        defer(() => {
            callback();
            return source;
        });
};

export const busyIndicator = <T>(busyRef: { loading: boolean }) => {
    // let alive = true;
    return (source: Observable<T>): Observable<T> =>
        source.pipe(
            prepare(() => busyRef.loading = true
                // timer(500)
                //     .pipe(
                //         takeWhile(() => alive),
                //         take(1)
                //     )
                //     .subscribe(() => {
                //         loadingRef.loading = true;
                //     })
            ),
            finalize(() => {
                // alive = false;
                busyRef.loading = false;
            })
        );
};
