import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Saempc} from '@app/sam-base/models/salary/saempc';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const employeeTabsFormControls: TabFormControl<Saempc>[] = [
    {
        formControls: [
            'empId',
            'ofsnr']
    },
    {
        title: 'donnes_base',
        formControls: [
            'cpolitesse',
            'nom',
            'prenom',
            'adresse1',
            'adresse2',
            'npa',
            'lieu',
            'pays',
            'tel1',
            'tel2',
            'natel',
            'telinterne',
            'email',
            'bstoemail',
            'noavs13',
            'datenaiss',
            'dateInfir',
            'dateInlas',
            'dateOutla',
            'langue',
            'codeAvs']
    },
    {
        title: 'situation',
        formControls: [
            'etatcivil',
            'datetatciv',
            'nation',
            'origine',
            'lieunaiss',
            'nomjf',
            'enfnb',
            'enfnbcharg',
            'enfnballoc',
            'conjWork',
            'conjWkch',
            'conjFi',
            'enfConcub',
            'enfMenind',
            'conjnoas13',
            'conjNom',
            'conjprenom',
            'conjNaiss',
            'conjnation',
            'conjEmpl',
            'enfmajbigsal',
            'enfconjbigsal',
            'enfnoconcub']
    },
    {
        title: 'permis',
        formControls: [
            'permis',
            'permisno',
            'permDtde',
            'permDta',
            'permLieu',
            'permis2',
            'permisno2',
            'perm2Dtde',
            'perm2Dta',
            'perm2Lieu',
            'permNote',
            'nation']
    },
    {
        title: 'deductions',
        formControls: [
            'empId',
            'codeLpp',
            'tableLpp',
            'lppmanuel',
            'lppHor',
            'lppCoomod',
            'codeImp',
            'codeCu1',
            'tableCu1',
            'cu1manuel',
            'codeCu2',
            'tableCu2',
            'cu2manuel',
            'codeCu3',
            'tableCu3',
            'cu3manuel',
            'codeCu4',
            'tableCu4',
            'cu4manuel',
            'codeCct',
            'cctId',
            'cantonimp',
            'baremImp',
            'modcalcimp',
            'tauxImp',
            'freqSal']
    },
    {
        title: 'impot',
        formControls: [
            'codeImp',
            'cantonimp',
            'baremImp',
            'impathee',
            'modcalcimp',
            'impsalfact',
            'modcalcimp',
            'impmodfroz',
            'tauxImp',
            'txImpCom',
            'impNote',
            'cantontrav',
            'pays',
            'cantonhab',
            'commune',
            'commutrav',
            'attfisc',
            'emcData',
            'ofsnr',
            'npa',
            'taxId',
            'noIsCatOpen',
            'noIsCode',
            'crossborderValidAsOf',
            'impGrant']
    },
    {
        title: 'salaire_vacances',
        formControls: [
            'freqSal',
            'salannuel',
            'salmensuel',
            'saljour',
            'saljour30',
            'salhorauto',
            'salhoraire',
            'jparAn',
            'hparJ',
            'hparS',
            'vacmode',
            'nbjVac',
            'nbjVacini',
            'txactivite',
            'ifactsal',
            'ifactsalfu',
            'salhebdo',
            'lparS',
            'txactioth']
    },
    {
        title: 'paiement',
        formControls: [
            'paytype',
            'dtatype',
            'banqueAdr',
            'banqueClr',
            'banqueSwi',
            'iban',
            'paybloque',
            'banque',
            'payadoth',
            'payfromoth',
            'paynomeoth',
            'payprenomoth',
            'payadroth',
            'paynpaoth',
            'paylieuoth',
            'paypaysoth']
    },
    /* {
         title: 'indemnites',
         formControls: [
             'codeMj1',
             'codeMj2',
             'codeMj3',
             'codeMj4',
             'tableMj1',
             'tableMj2',
             'tableMj3',
             'tableMj4',
             'mj1manuel',
             'mj2manuel',
             'mj3manuel',
             'mj4manuel',
             'lalfHor',
             'alfhorMn',
             'alfhorMa',
             'alfdateD',
             'alfdateF']
     },*/
    {
        title: 'professionAffectation',
        formControls: [
            'profession',
            'profDate',
            'cantontrav',
            'categorie1',
            'categorie2',
            'categorie3',
            'ageId']
    },
    {
        title: 'autreTab',
        formControls: [
            'notes',
            'userdate1',
            'userdate2',
            'userdate3',
            'userchamp1',
            'userchamp2',
            'userchamp3',
            'adresse1']
    },
    {
        title: 'ncs',
        formControls: [
            'ncsA',
            'ncsB',
            'ncsF',
            'ncsG',
            'ncsT23',
            'ncsT3',
            'ncsT4',
            'ncsT71',
            'ncsT72',
            'ncs1311',
            'ncsT1312',
            'ncsT1323',
            'ncsT141',
            'ncsT142',
            'ncsT15',
            'txtJtrav',
            'txtHtrav',
            'txtFirstDay',
            'txtLastDay',
            'contactNcsId']
    },
    {
        title: 'swissdec_title',
        formControls: [
            'sdsI',
            'sdsB',
            'sdsD',
            'sdsJ',
            'sdsK',
            'sdsL',
            'sdsM',
            'sdsN',
            'sdsP',
            'sdsC',
            'sdsE',
            'sdsH',
            'degreeofrel',
            'statpaytype',
            'pad',
            'ppap',
            'profilage',
            'monthCont13',
            'monthCont14',
            'monthContSalary',
            'adresse1Oth',
            'adresse2Oth',
            'npaoth',
            'lieuoth',
            'paysoth',
            'monthContSalary',
            'hourlyContPaidByHour',
            'hourlyContPaidByLesson',
            'hourlyContVac',
            'hourlyContJf',
            'hourlyCont13',
            'noTimeContSalary',
            'noTimeContVac',
            'noTimeContJf',
            'noTimeCont13']
    },
    {
        title: 'annee',
        formControls: [
            'empId',
            'ipresence',
            'isalaires',
            'iforce',
            'din1',
            'din2',
            'din3',
            'din4',
            'din5',
            'din6',
            'din7',
            'din8',
            'din9',
            'din10',
            'din11',
            'din12',
            'dout1',
            'dout2',
            'dout3',
            'dout4',
            'dout5',
            'dout6',
            'dout7',
            'dout8',
            'dout9',
            'dout10',
            'dout11',
            'dout12',
            'nap1',
            'nap2',
            'nap3',
            'nap4',
            'nap5',
            'nap6',
            'nap7',
            'nap8',
            'nap9',
            'nap10',
            'nap11',
            'nap12',
            'lmf1',
            'lmf2',
            'lmf3',
            'lmf4',
            'lmf5',
            'lmf6',
            'lmf7',
            'lmf8',
            'lmf9',
            'lmf10',
            'lmf11',
            'lmf12']
    },
    {
        title: 'docs',
        formControls: []
    }
];
