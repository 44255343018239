import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ComboboxItem, FormKeys, Saconj} from '@app/sam-base/models';
import {Saemi} from '@app/sam-base/models/salary/saemi';
import {Subject} from 'rxjs';
import {EmaType, MotifsEntree, MotifsMutation, MotifsSortie} from './saemi-lpp-enums';

@Component({
    templateUrl: './saemi-lpp.component.html'
})
export class SaemiLPPComponent extends BaseStoreFormComponent<Saemi> implements OnInit, OnDestroy {


    public emaType = EmaType;
    public motifsEntree = MotifsEntree;
    public motifsMutation = MotifsMutation;
    public motifsSortie = MotifsSortie;
    public optionSalIdCor: ComboboxItem<string>[] = [{
        name: ' ',
        value: ''
    }];
    private subscriptions = new Subject();

    constructor(store: IwStoreService) {
        super(store);
    }

    public get empId(): string {
        return this.getData('empId', true) || '';
    }

    public ngOnInit() {
        this.setSaemiValues();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public fieldDisabled(emiTypes: string): boolean {
        const emiType = this.getFormValue('emiType');
        return (emiTypes.indexOf(emiType) !== -1);
    }

    public saveEntry() {
        this.saveChanges();
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public isEmiTypeSelected(): boolean {
        return !!this.getFormValue('emiType');
    }

    public isValueSelected(val: string): boolean {
        return this.getFormValue('emiType') === val;
    }

    protected getFormControlNames(): FormKeys<Saemi> {
        return ['emiType',
            'salId',
            'emiId',
            'salIdCor',
            'emaCode',
            'empId',
            'emiGenre',
            'emaDate',
            'modecreat',
            'baseLppY'];
    }

    protected getValidationType() {
        return Saemi;
    }

    protected validateFields(e: Saconj): boolean {
        return super.validateFields(e);
    }

    private setSaemiValues(): void {
        this.setFormValue('empId', this.empId);
        this.setFormValue('salId', this.getData('salId', true) || '');
        this.setFormValue('salIdCor', this.getData('salId', true) || '');
        this.setFormValue('emiGenre', 'LPP');
        this.setFormValue('modecreat', 'M');
    }

}
