import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Saemc} from '@app/sam-base/models/salary/saemc';
import {ParamSD4Component} from '../components/paramSD4/paramSD4.component';

export const SAEMC_DEFAULT_FORM: FormDescription<Saemc> = {
    form: ParamSD4Component, state: {
        ...buildDefaultState(Saemc), diagTitle: 'parametresSD4', diagSideMenu: {
            showCancel: true, showClose: true
        }, editMode: 'waiting', width: 500
    }
};
