import {Component} from '@angular/core';
import {ModalComponent} from '@app/sam-base/models';
import {SmsTypes} from '@app/sam-base/models/placement';

@Component({
    templateUrl: './iw-send-sms-modal.component.html'
})
export class IwSendSMSModalComponent implements ModalComponent<void, SmsTypes[]> {

    public clients: SmsTypes[] = [];

    public visible = true;

    private _onOk?: (result: void) => void;
    private _onCancel?: (error?: any) => void;

    constructor() {
    }

    public setData(data: SmsTypes[]): Promise<void> {
        this.clients = data;
        return Promise.resolve();
    }

    public onCancel() {
        this.visible = false;
        if (this._onCancel) {
            this._onCancel();
        }
    }

    public onOk() {
        this.visible = false;
        if (this._onOk) {
            this._onOk();
        }
    }

    public registerOk(action: (result: void) => void): void {
        this._onOk = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._onCancel = action;
    }
}
