<div class="row">

    <div class="col-xs-11 col-no-padding" style="height: 200px">
        <iw-rest-grid #olvClicha (selected)="getSelectedRow($event)" [queryStatements]="query"
                      [type]="type"></iw-rest-grid>
    </div>

    <div class="col-xs-1 grid-buttons">
        <iw-button #btnDelCha (press)="deleteChantier()" [tooltip]="'chantierSupprimer' | translate"
                   class="iw-button col-xs-1 no-padding" iconClass="fas fa-trash"></iw-button>
    </div>

    <div class="col-xs-11 col-no-padding" style="margin-top: 10px">
        <iw-textarea #edtCliChaFacRef [height]="90" [isDisabled]="true" class="iw-textarea"></iw-textarea>
    </div>

    <div style="margin-top: 15px">
        <iw-button #btnChantiers (press)="openChantiers()" [isDisabled]="isReadonly" [text]="'chantiers' | translate"
                   class="iw-button col-xs-2 no-padding"></iw-button>
    </div>

</div>
