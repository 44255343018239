import {Component, Input, OnDestroy} from '@angular/core';
import {MenuItem} from '@app/sam-base/models';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from '@shared/profile/profile.service';
import {lastValueFrom, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {IwEventHubService} from '../../../core/events';
import {SideMenuStateService} from '../../../core/services/side-menu-state.service';
import {LayoutEvents} from '../../events/events.models';

@Component({
    selector: 'iw-side-layout-menu',
    templateUrl: './menu.component.html'
})
export class MenuComponent implements OnDestroy {

    public activeMenu?: LayoutEvents;
    public visibleEntries: MenuItem[] = [];
    private _subs = new Subject();

    constructor(private readonly _eventHub: IwEventHubService<string>, public _menuState: SideMenuStateService,
                public _profileService: ProfileService, public readonly _translate: TranslateService) {
        this._menuState.sideMenuActiveChange
            .pipe(takeUntil(this._subs))
            .subscribe((e: LayoutEvents) => this.activeMenu = e);
    }

    private _open = false;

    public get open(): boolean {
        return this._open;
    }

    @Input()
    public set open(isOpen: boolean) {
        this._open = isOpen;
        // this.updateLabelEntries(isOpen);
    }

    @Input()
    public set menuEntries(entries: MenuItem[]) {
        this.visibleEntries = this.filterVisibleItems(entries);
        this.addComandToMenuItems(this.visibleEntries);
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }


    private filterVisibleItems(entries: MenuItem[]): MenuItem[] {
        return entries.filter((entry: MenuItem) => {
            if (!entry.accessRoles) {
                return true;
            }
            if (this._profileService.checkUserPermission(entry.accessRoles)) {
                if (entry.items) {
                    entry.items = this.filterVisibleItems(entry.items);  // recursive call
                }
                return true;
            }
            return false;
        });
    }

    private async addComandToMenuItems(items: MenuItem[]): Promise<MenuItem[]> {
        const list: MenuItem[] = [];

        for (const m of items) {
            if (m.items) {
                m.items = await this.addComandToMenuItems(m.items);
            }
            if (m.label) {
                m.label = await lastValueFrom(this.getTranslation(m.label));
            }
            if (m.event) {
                const event = m.event;
                m.command = () => this.emitEvent(event);
            }

            list.push(m);
        }

        return list;
    }

    private getTranslation(key: string) {
        return this._translate.get(key);
    }

    private emitEvent(event: string) {
        this._eventHub.emit(event);
    }


}
