<div class="row">
    <div *ngIf="annee" class="col-xs-12">
        <div style="height: 200px;">

            <iw-rest-grid #assurancesGrid (selected)="selectAssuranceChange($event)" [autoFormClick]="false"
                          [hasGridColumnMenu]="false" [hasSortIcon]="false" [queryStatements]="query"
                          [selectMode]="'checkbox'" [type]="type" id="assurancesGrid"></iw-rest-grid>
        </div>
    </div>
</div>

