<form [formGroup]="formGroup" class="row space-form">

    <div class="row col-xs-12 padding-top-10">
        <iw-enum-dropdown [(value)]="ded" [inputEnum]="deductionType" [labelAlign]="'top'"
                          [label]="'ded_type' | translate" class="iw-input col-xs-12"></iw-enum-dropdown>
    </div>
    <div class="row col-xs-12 margin-top-10">
        <iw-button #btnOk (press)="openCreationForm()" [isDisabled]="!ded" [text]="'ok' | translate"
                   class="iw-button col-xs-6"></iw-button>

        <iw-button #btnClose (press)="closeDialog()" [text]="'annuler' | translate"
                   class="iw-button col-xs-6"></iw-button>
    </div>
</form>
