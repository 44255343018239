export enum Acopaytype {
    especes = 1, cheque = 2, virement_dta = 3, autre = 4,
}

export enum Aacotype {
    rapport = 1, manuel = 2, automatique = 3,
}

export enum AacoTypeToMpaId {
    especes = 'ESP', cheque = 'CHQ', virement_dta = 'DTA',
}
