<form [formGroup]="formGroup"
      class="row">
    <div class="row space-form col-xs-9">
        <iw-rest-grid #sadedtxConfigGrid
                      (selected)="getSelectedRow($event)"
                      (sortInfo)="setSortInfo($event)"
                      [autoFormClick]="false"
                      [defaultSelected]="true"
                      [hasGridColumnMenu]="false"
                      [hasSortIcon]="true"
                      [queryStatements]="query"
                      [sortDir]="sortPropDir"
                      [sortProp]="sortProp"
                      [type]="type"
                      class="col-xs-12"
                      style="height: 200px;"></iw-rest-grid>

        <iw-textfield #txtCode
                      [isDisabled]="readonly || !newEntryMode"
                      [labelAlign]="'left'"
                      [label]="'code' | translate"
                      [warningMessage]="'tx_code_wrong'"
                      class="iw-input col-xs-6 padding-top-5"
                      formControlName="txCode"></iw-textfield>
        <div class="col-xs-5"></div>
        <iw-textfield #txtAssurance
                      *ngIf="!showAssurance"
                      [(value)]="assCodeVal"
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'assurance' | translate"
                      class="iw-input col-xs-10"></iw-textfield>
        <iw-dropdown *ngIf="showAssurance"
                     [(value)]="assCodeVal"
                     [isDisabled]="readonly"
                     [labelAlign]="'left'"
                     [label]="'assurance' | translate"
                     [options]="assuranceOptions"
                     class="iw-input col-xs-10 padding-top-10"></iw-dropdown>
        <div class="col-xs-5"></div>
        <iw-textfield #txtLibelle
                      [isDisabled]="readonly"
                      [labelAlign]="'left'"
                      [label]="'Libellé' | translate"
                      class="iw-input col-xs-10"
                      formControlName="titre"></iw-textfield>
        <div class="col-xs-2"></div>

        <!-- Employé/Employeur  -->
        <div class="col-xs-3"></div>
        <div class="iw-single-label col-xs-3 padding-top-15"
             style="text-align-last: center">
            {{ 'employe' | translate }}
        </div>
        <div class="col-xs-1"></div>
        <div class="iw-single-label col-xs-3 padding-top-15"
             style="text-align-last: center">
            {{ 'employeur' | translate }}
        </div>

        <iw-textfield #txtTxcot
                      [decimals]="4"
                      [isDisabled]="readonly || !isTauxMode2"
                      [labelAlign]="'left'"
                      [label]="'tauxBut' | translate"
                      class="iw-input col-xs-6 padding-top-5 text-align-right"
                      formControlName="txcot"
                      type="number"></iw-textfield>
        <div class="col-xs-1"></div>
        <iw-textfield #txtTxcoe
                      [decimals]="4"
                      [isDisabled]="readonly || !isTauxMode2"
                      [noLabel]="true"
                      class="iw-input col-xs-3 padding-top-5 text-align-right"
                      formControlName="txcoe"
                      type="number"></iw-textfield>
        <iw-textfield #txtTxcotH
                      [decimals]="4"
                      [isDisabled]="readonly || !isTauxMode3"
                      [labelAlign]="'left'"
                      [label]="'tauxH' | translate"
                      class="iw-input col-xs-6 text-align-right"
                      formControlName="txcotH"
                      type="number"></iw-textfield>
        <div class="col-xs-1"></div>
        <iw-textfield #txtTxcoeH
                      [decimals]="4"
                      [isDisabled]="readonly || !isTauxMode3"
                      [noLabel]="true"
                      class="iw-input col-xs-3 text-align-right"
                      formControlName="txcoeH"
                      type="number"></iw-textfield>
        <iw-textfield #txTxcotF
                      [decimals]="4"
                      [isDisabled]="readonly || !isTauxMode3"
                      [labelAlign]="'left'"
                      [label]="'tauxF' | translate"
                      class="iw-input col-xs-6 text-align-right"
                      formControlName="txcotF"
                      type="number"></iw-textfield>
        <div class="col-xs-1"></div>
        <iw-textfield #txtTxcoeF
                      [decimals]="4"
                      [isDisabled]="readonly || !isTauxMode3"
                      [noLabel]="true"
                      class="iw-input col-xs-3 text-align-right"
                      formControlName="txcoeF"
                      type="number"></iw-textfield>


        <!-- Depuis/Jusqu'à  -->
        <div class="col-xs-3"></div>
        <div class="iw-single-label col-xs-3 padding-top-15"
             style="text-align-last: center">
            {{ 'depuis' | translate }}
        </div>
        <div class="col-xs-1"></div>
        <div class="iw-single-label col-xs-3 padding-top-15"
             style="text-align-last: center">
            {{ 'jusqua' | translate }}
        </div>

        <iw-textfield #txrSalmin
                      [decimals]="2"
                      [isDisabled]="readonly"
                      [labelAlign]="'left'"
                      [label]="'salaireMax' | translate"
                      class="iw-input col-xs-6 padding-top-5 text-align-right"
                      formControlName="salmin"
                      type="number"></iw-textfield>
        <div class="col-xs-1"></div>
        <iw-textfield #txtSalmax
                      [decimals]="2"
                      [isDisabled]="readonly"
                      [noLabel]="true"
                      class="iw-input col-xs-3 padding-top-5 text-align-right"
                      formControlName="salmax"
                      type="number"></iw-textfield>

        <ng-template [ngIf]="isCAF">
            <div class="row col-xs-12 margin-top-10">

                <iw-rest-grid #sadedtxageGrid
                              (selected)="getSadedtxageRow($event)"
                              [customColumns]="sadedtxageColumns"
                              [hasGridColumnMenu]="false"
                              [hasSortIcon]="false"
                              [queryStatements]="sadedtxageQuery"
                              [type]="sadedtxage"
                              class="col-xs-11 no-padding"></iw-rest-grid>

                <div class="col-xs-1 flex flex-between flex-column">
                    <iw-button (press)="openSadedtxageDialog()"
                               [isDisabled]="readonly || !selectedRow"
                               [tooltip]="'sidemenu_new' | translate"
                               iconClass="fas fa-file"
                               style="padding-bottom: 6px"></iw-button>

                    <iw-button (press)="changeSadedtxage()"
                               [isDisabled]="readonly || !selectedSadedtxageRow"
                               [tooltip]="'sidemenu_edit' | translate"
                               iconClass="fas fa-pencil-alt"
                               style="padding-bottom: 6px"></iw-button>

                    <iw-button (press)="removeSadedtxage()"
                               [isDisabled]="readonly || !selectedSadedtxageRow"
                               [tooltip]="'sidemenu_delete' | translate"
                               iconClass="fas fa-trash"></iw-button>
                </div>
            </div>
        </ng-template>
    </div>

    <div class="col-xs-3 flex flex-v separator-left">
        <iw-button #btnNew
                   (press)="createNewEntry()"
                   *ngIf="readonly"
                   [isDisabled]="!readonly"
                   [text]="'sidemenu_new' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnModif
                   (press)="modifyEntry()"
                   *ngIf="readonly"
                   [isDisabled]="!readonly || !selectedRow"
                   [text]="'sidemenu_edit' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnSave
                   (press)="saveEntry()"
                   *ngIf="!readonly"
                   [isDisabled]="!formGroup.valid"
                   [text]="'sidemenu_save' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnDel
                   (press)="removeEntry()"
                   *ngIf="readonly"
                   [isDisabled]="!readonly || !selectedRow"
                   [text]="'sidemenu_delete' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnClose
                   (press)="cancelEditionMode()"
                   *ngIf="!readonly"
                   [text]="'sidemenu_cancel' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-textfield #txtSaparAnnee
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="sapar?.annee"
                      class="iw-textfield padding-top-10"
                      style="text-align-last: center"></iw-textfield>

        <iw-button #btnClose
                   (press)="closeDialog()"
                   [text]="'fermer' | translate"
                   class="iw-button btn-block"
                   style="margin-block-start: auto"></iw-button>
    </div>

</form>
