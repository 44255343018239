import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {InterijobConfigComponent} from '../components/interijob-config/interijob-config.component';
import {IJConfig} from '../components/interijob-config/interijob-config.model';


export const INTERIJOB_CONFIG: FormDescription<IJConfig> = {
    form: InterijobConfigComponent, state: {
        ...buildDefaultState(IJConfig), diagModal: true, diagTitle: 'ij_config_menu', width: 800
    }
};
