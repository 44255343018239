<div #hostDiv
     [id]="entityName | lowercase"
     class="iw-entity-select"
     tabindex="0">
    <div class="title-bar">
        <h1>{{ entityName | translate }}</h1>
    </div>
    <div #gridBody
         class="row">
        <iw-smart-grid #smartGrid
                       (rowDoubleClick)="onRowDoubleClick($event)"
                       (selected)="onSelect($event)"
                       [autoFormClick]="false"
                       [contextMenuEnabled]="false"
                       [customColumns]="columns"
                       [gridType]="gridType"
                       [hasGridColumnMenu]="true"
                       [itemsPerPage]="100"
                       [queryStatements]="statements"
                       [selectAllFromServerEnabled]="true"
                       [selectMode]="selectMode"
                       [type]="entity"
                       [virtualScroll]="false"
                       style="height: 60vh;width: 76.5vw;"></iw-smart-grid>
    </div>
</div>
