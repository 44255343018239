import {Component, EventEmitter, Input, OnDestroy, Output, Type, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components/iw-rest-grid/iw-rest-grid.component';
import {FormHandlerService} from '@app/sam-base/core/form-handler/form-handler.service';
import {FATAL, mapError} from '@app/sam-base/core/logger';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {MissionCalculationsService} from '@app/sam-base/core/services/mission-calculations.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {Ppgrh, PpminIndhhView, PpminIndihView, Ppmis, Ppmisih, PpmissalView} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {lastValueFrom, Subject} from 'rxjs';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';

import {PlacementForm} from '../../../placement.forms';
import {MissionMisIhService} from '../../../services/mission-misih.service';
import {MissionStatus} from '../mission-enums';
import {MisheuOptions} from './misheu/misheu-options.model';
import {MisheuComponent} from './misheu/misheu.component';

@Component({
    selector: 'iw-ppmis-sal-ind',
    templateUrl: './tab-ppmis-ind.component.html'
})
export class TabMisIndComponent extends BasePartialFormComponent<Ppmis> implements OnDestroy {

    public selectedMisHH?: Ppmisih;
    public selectedMisIH?: Ppmisih;
    public txtMargeHour = '';
    public txtCoeffAdv = '';
    public typeIndHHView = PpminIndhhView;
    public typeRefreshHH: Type<Ppmisih> = Ppmisih;
    public typeIndIHView = PpminIndihView;
    public typeMissal = PpmissalView;
    public misIhEqTypes = [
        'HEQ1',
        'HEQ2',
        'HEQ3',
        'SN1',
        'SN2',
        'SN',
        'SD'];
    public misHTypes = [
        'HS25',
        'H110',
        'H120',
        'H125',
        'H150',
        'H200',
        'HXXX'];
    public misihAutreSupGrhs = [
        'A',
        'B'];
    @Output() public salIndChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    public salIndChangedDebouncer: Subject<boolean> = new Subject<boolean>();
    @Input() public sent2fac = false;
    @Input() public sent2sal = false;
    @Input() public hasRapport = false;
    @Input() public formValue?: Ppmis;
    @Input() public formDirty?: boolean;
    @ViewChild('olvMishh') public _misHHGrid!: IwRestGridComponent<PpminIndhhView>;
    @ViewChild('olvMisIh') public _misIHGrid!: IwRestGridComponent<PpminIndihView>;
    private _misFilter: RestQueryParam<any, any>[] = [];
    private _misIhClient: RestEntityClient<Ppmisih>;
    private _ppgrhClient: RestEntityClient<Ppgrh>;
    private subscriptions = new Subject();

    constructor(private readonly _formHandler: FormHandlerService<PlacementForm>,
                private readonly _misIhService: MissionMisIhService,
                private readonly _calculation: MissionCalculationsService, private _modalService: ModalService,
                private translate: TranslateService, restService: RestApiService) {
        super();
        this._misFilter = [this.buildFilter()];
        this._misIhClient = restService.getEntityClient(Ppmisih);
        this._ppgrhClient = restService.getEntityClient(Ppgrh);
        this.salIndChangedDebouncer.pipe(takeUntil(this.subscriptions), debounceTime(1000))
            .subscribe((value: boolean) => this.salIndChanged.emit(value));
    }

    public get filterByMisId() {
        return this._misFilter;
    }

    public get hasSalFacReadonly(): boolean {
        return this.hasRapport || this.sent2fac || this.sent2sal || this.isReadonly;
    }

    public get misId() {
        return this.getFormValue<string>('misId') || '';
    }

    public get misNotArchived() {
        const misStatus = Number(this.getFormValue<string>('misstatus') || -1);
        return misStatus !== MissionStatus.archivee;
    }

    public get tooltip() {
        return (this.formDirty && !this.isReadonly) ? this.translate.instant('cant_add_hours') : '';
    }

    public get showListMisSal() {
        const facMod = this.getFormValue<number>('misfacmode') || 0;
        const salMod = this.getFormValue<number>('missalmode') || 0;
        return facMod > 1 || salMod > 1;
    }

    public get canAddHours() {
        return !this.formDirty && !this.isReadonly && this.misNotArchived;
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public onAddMisHH(grhId: string) {
        this._misIhService.createOrUpdate(this.misId, grhId)
            .pipe(tap(() => FATAL('WALK HERE')))
            .subscribe(() => this._misHHGrid.refresh());
    }

    // eslint-disable-next-line complexity
    public disabledEditMisHH(): boolean {
        return !this.selectedMisHH || this.selectedMisHH.grhId === 'H100' || this.selectedMisHH.grhId === 'H125' || this.selectedMisHH.grhId === 'H150' || this.selectedMisHH.grhId === 'H200' || this.selectedMisHH.grhId === 'HNUI';
    }

    // eslint-disable-next-line complexity
    public async openMisHHForm(type: string) {
        if (!this.formValue) {
            return;
        }
        const ppmis: Ppmis = this.formValue;

        if (type === 'new') {
            const options: MisheuOptions = {
                title: 'misheu_form_title',
                ppmis,
                isEditing: false,
                isAdding: true,
                lMisHasSal: this.sent2sal
            };

            try {
                await this._modalService.showModal(MisheuComponent, options);
            } catch (error) {
            }
        } else if (type === 'edit' && this.selectedMisHH && this.selectedMisHH.grhId) {

            if (this.selectedMisHH.misihId && this.misHTypes.includes(this.selectedMisHH.grhId)) {
                const ppmisih = await lastValueFrom(this._misIhClient
                    .getById(this.selectedMisHH.misihId));

                const options: MisheuOptions = {
                    title: 'misheu_edit_form_title',
                    ppmis,
                    ppmisih,
                    isEditing: true,
                    isAdding: false,
                    lMisHasSal: this.sent2sal
                };

                try {
                    await this._modalService.showModal(MisheuComponent, options);
                } catch (error) {
                }
            } else if (this.selectedMisHH.misihId && this.misIhEqTypes.includes(this.selectedMisHH.grhId)) {
                this.openMisheupc();
            } else {
                const ppgrh: Ppgrh = await lastValueFrom(this._ppgrhClient
                    .getById(this.selectedMisHH.grhId));

                if (this.selectedMisHH.misihId && ppgrh.grhType && this.misihAutreSupGrhs.includes(ppgrh.grhType)) {
                    const ppmisih = await lastValueFrom(this._misIhClient
                        .getById(this.selectedMisHH.misihId));

                    const options: MisheuOptions = {
                        title: 'misheu_edit_form_title',
                        ppmis,
                        ppmisih,
                        isEditing: true,
                        isAdding: false,
                        lMisHasSal: this.sent2sal
                    };

                    try {
                        await this._modalService.showModal(MisheuComponent, options);
                    } catch (error) {
                    }
                } else {
                    this.openMisheupc();
                }
            }
        }

    }

    public deleteMisHH() {
        if (!this.selectedMisHH || !this.selectedMisHH.misihId) {
            return;
        }
        this._misIhClient.delete(this.selectedMisHH.misihId)
            .pipe(mapError('Error deleting ppmisIh'))
            .subscribe(() => this._misHHGrid.refresh());
    }

    public deleteMisIH() {
        if (!this.selectedMisIH || !this.selectedMisIH.misihId) {
            return;
        }
        this._misIhClient.delete(this.selectedMisIH.misihId)
            .pipe(mapError('Error deleting ppmisIh'))
            .subscribe(() => this._misIHGrid.refresh());
    }

    public onAddMisheupc() {
        if (!this.formGroup) {
            return;
        }

        const misId = this.getFormValue<string>('misId');

        this._formHandler
            .showFormDialog(PlacementForm.Misheuspcnew, {misId});
    }

    public openMisheupc() {
        if (this.selectedMisHH && this.selectedMisHH.misihId) {
            const id = this.selectedMisHH.misihId;
            this._formHandler.showFormDialog(PlacementForm.Misheuspc, undefined, s => ({
                ...s,
                entityId: id
            }));
        }
    }

    public setMisHH(event: Ppmisih[]) {
        this.selectedMisHH = (event && event[0]) || undefined;
    }

    public setMisIH(event: Ppmisih[]) {
        this.selectedMisIH = (event && event[0]) || undefined;
    }

    public createMisIh() {
        this._formHandler.showFormDialog(PlacementForm.MisIndemniteCreate, {misId: this.misId});
    }

    public editMisIh() {
        if (!this.selectedMisIH || !this.selectedMisIH.misihId) {
            return;
        }
        const id = this.selectedMisIH.misihId;
        this._formHandler.showFormDialog(PlacementForm.MisIndemniteUpdate, {misId: this.misId}, e => ({
            ...e,
            entityId: id
        }));
    }

    public emitSalIndChanged() {
        this.salIndChangedDebouncer.next(true);
    }

    disabledEditMisIH() {
        return false;
    }

    protected onFormGroupSet() {
        this.subscribeValueChange('misId', () => this.reloadBinds());
        this.reloadBinds();
    }

    protected reloadBinds() {
        this.updateFilter();
        this.applyCalculations();
    }

    private applyCalculations() {
        if (!this.formGroup) {
            return;
        }
        this._calculation.calculateStats(this.formGroup.value)
            .pipe(mapError('Error calculating stats'))
            .subscribe(e => {
                if (!e) {
                    return;
                }
                this.txtMargeHour = e.margeHours;
                this.txtCoeffAdv = e.txtCoeffAdv;
            });
    }

    private updateFilter() {
        const misId = this.getFormValue<string>('misId') || '';
        this._misFilter = [this.buildFilter(misId)];
    }

    /** Build filter for a misId */
    private buildFilter(misId?: string): RestQueryParam<PpminIndihView & PpminIndhhView, string> {
        return {
            operation: RestQueryOperation.Equals,
            prop: 'misId',
            value: misId || ''
        };
    }
}
