import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppagi} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-remarques-aginter', templateUrl: './tab-remarques-aginter.component.html'
})
export class TabRemarquesAginterComponent extends BasePartialFormComponent<Ppagi> {

    @Input() public isReadonly = true;

    constructor() {
        super();
    }
}
