import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Saparass} from '@app/sam-base/models/placement/saparass';
import {Observable, Subject} from 'rxjs';
import {RestClient} from '../rest-api/core';

export interface SaparassSubscrition {
    uuid: string;
    saved: boolean;
}

@Injectable()
export class SaparassService extends RestClient<any> {

    public saparassSaveSub: Subject<SaparassSubscrition> = new Subject();

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getSaparass(annee?: string): Observable<Saparass[]> {
        return this._http.get<Saparass[]>(this._baseUrl + `saparass/list/${annee}`);
    }

    public saveSaparass(saparass: Saparass): Observable<any> {
        return this._http.post(this._baseUrl + `saparass/save`, saparass);
    }

    public deleteSaparass(annee?: string, assCode?: string) {
        return this._http.delete<boolean>(this._baseUrl + `saparass/delete/${annee}/${assCode}`);
    }

    public getAssuranceByAnneeAndDedCode(annee: string, dedCode: string): Observable<Saparass[]> {
        return this._http.get<Saparass[]>(this._baseUrl + `saparass/list/${annee}/${dedCode}`);
    }

}
