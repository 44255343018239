<div *iwAccessGroups="missionRoles"
     class="flex flex-v flex-evenly">
    <div class="row margin-top-10">
        <iw-button #btnSendCCByMail
                   (press)=openModalToSendEmail()
                   *ngIf="!isMissionCreating"
                   [tooltip]="'send_cc_by_mail' | translate"
                   class="btn-block"
                   iconClass="fas fa-envelope"></iw-button>
    </div>

    <div class="row margin-top-20">
        <iw-button #btnOpenClose
                   (press)="openCloseMissionDialog()"
                   *ngIf="!isMissionCreating"
                   [text]="'oF' | translate"
                   [tooltip]="'open_close_m' | translate"
                   class="btn-block"></iw-button>
    </div>

    <div class="row">
        <iw-button #btnCopy
                   (press)="showMiscpyModal()"
                   *ngIf="!isMissionCreating"
                   [tooltip]="'copy_mission' | translate"
                   class="btn-block"
                   iconClass="fas fa-copy"></iw-button>
    </div>

    <div class="row">
        <iw-button #btnActCon
                   *ngIf="!isMissionCreating"
                   [text]="'actCons' | translate"
                   [tooltip]="'act_con' | translate"
                   class="btn-block"></iw-button>
    </div>
</div>
