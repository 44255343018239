import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AdminModule} from '@modules/sam-main/admin';
import {SalaryModule} from '@modules/sam-main/salary';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '@shared/shared.module';

import {PlacementModule} from './placement';
import {SamMainComponent} from './sam-main.component';

@NgModule({
    imports: [CommonModule,
        SharedModule,
        TranslateModule,
        PlacementModule,
        SalaryModule,
        AdminModule],
    declarations: [SamMainComponent]
})
export class SamMainModule {
}
