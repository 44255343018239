<div class="row">
    <div class="col-xs-10" style="height: 200px;">
        <iw-rest-grid #ppsecteurConfigGrid (selected)="getSelectedRow($event)" (sortInfo)="setSortInfo($event)"
                      [autoFormClick]="false" [defaultSelected]="true" [hasGridColumnMenu]="false"
                      [queryStatements]="query" [selectedData]="selectedData" [sortDir]="sortPropDir"
                      [sortProp]="sortProp" [type]="type"></iw-rest-grid>
    </div>

    <div class="col-xs-2 flex flex-v flex-evenly">
        <iw-button (press)="createNewEntry()" [isDisabled]="!isReadonly" [text]="'sidemenu_new' | translate" id="new"
                   name="nouveau"></iw-button>
        <iw-button (press)="modifyEntry()" [isDisabled]="!isReadonly" [text]="'sidemenu_edit' | translate"
                   id="modify"></iw-button>
        <iw-button (press)="removeEntry()" [isDisabled]="!isReadonly" [text]="'sidemenu_delete' | translate"
                   id="remove"></iw-button>
        <iw-button (press)="saveEntry()" [isDisabled]="isReadonly" [text]="'sidemenu_save' | translate" id="save"
                   name="save"></iw-button>
        <iw-button (press)="cancelEditionMode()" [isDisabled]="isReadonly" [text]="'sidemenu_cancel' | translate"
                   id="cancel"></iw-button>
    </div>

</div>
<form [formGroup]="formGroup" class="row">
    <div class="row col-xs-10 no-padding">

        <iw-textfield #txtSec_id [isDisabled]="isReadonly || !newEntryMode" [labelAlign]="'top'"
                      [label]="'code' | translate" class="iw-input col-xs-3" formControlName="secId"
                      id="secId"></iw-textfield>

        <iw-textfield #txtSec_lib [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'libelle' | translate"
                      class="iw-input col-xs-9" formControlName="secLib" id="secLib"></iw-textfield>

        <iw-textfield-compte #txtCpt_tempo [isDisabled]="isReadonly" [labelAlign]="'top'"
                             [label]="'cptTempo' | translate" class="iw-input col-xs-3" formControlName="cptTempo"
                             id="cptTempo"></iw-textfield-compte>

        <iw-textfield-compte #txtCpt_tequ [isDisabled]="isReadonly" [labelAlign]="'top'"
                             [label]="'cptTempoEq' | translate" class="iw-input col-xs-3"
                             formControlName="cptTequ"></iw-textfield-compte>

        <iw-textfield-compte #txtCpt_pari [isDisabled]="isReadonly" [labelAlign]="'top'"
                             [label]="'cptTempoCct' | translate" class="iw-input col-xs-3"
                             formControlName="cptPari"></iw-textfield-compte>

        <iw-textfield-compte #txtCpt_fixe [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'cptFixe' | translate"
                             class="iw-input col-xs-3" formControlName="cptFixe"></iw-textfield-compte>

        <iw-textfield #txtCodeCNA [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'suva' | translate"
                      class="iw-input col-xs-3" formControlName="codecna" id="suva"></iw-textfield>


        <iw-textfield #txtCoeff_min [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'coeffMinimum' | translate"
                      class="iw-input col-xs-2" decimals="0" formControlName="coeffMin" type="number"></iw-textfield>
        <div class="iw-single-label col-xs-1 padding-top-25">%</div>


        <iw-textfield #txtpccharges [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'charges' | translate"
                      class="iw-input col-xs-3" decimals="0" formControlName="pccharges" type="number"></iw-textfield>


        <iw-checkbox #chkActif [isDisabled]="isReadonly" [label]="'leSecteurEstActif' | translate"
                     class="iw-input col-xs-3" formControlName="actif" style="margin-top: 24px;"></iw-checkbox>


    </div>
    <div class="row col-xs-2 no-padding">
        <iw-button #Closebutton (press)="closeDialog()" [text]="'fermer' | translate"
                   class="iw-button col-xs-12 flex-end"></iw-button>
    </div>

</form>