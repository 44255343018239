import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class MessageFormRefreshService {
    private subject = new Subject<string>();

    public sendMessage(message: string) {
        this.subject.next(message);
    }

    public clearMessages() {
        this.subject.next('');
    }

    public getMessage(): Observable<string> {
        return this.subject.asObservable();
    }
}
