<form [formGroup]="formGroup" class="row">
    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <div class="form-label col-xs-11 padding-top-5">
        {{ 'incomeGainful' | translate }}
    </div>

    <!-- Question 8-->
    <div class="iw-single-label col-xs-1 padding-top-15">8.</div>
    <div class="iw-single-label col-xs-3 padding-top-15">
        {{ 'agreedSalary' | translate }}
    </div>
    <iw-textfield #txtSalaryConvenu [decimals]="2" [isDisabled]="isReadonly"
                  class="iw-input col-xs-2 padding-top-15 padding-left-0" formControlName="salH"
                  type="number"></iw-textfield>
    <div class="iw-single-label col-xs-2 padding-top-15">
        {{ 'perHour' | translate }}
    </div>
    <iw-dropdown #ddSalaryPerHour [isDisabled]="isReadonly" [options]="salHorModeOptions"
                 class="iw-input col-xs-4 padding-top-15 padding-left-0" formControlName="salhormode"></iw-dropdown>

    <!-- Question 9-->
    <div class="iw-single-label col-xs-1 padding-top-15">9.</div>
    <div class="iw-single-label col-xs-11 padding-top-15">
        {{ 'grossSalary' | translate }}
    </div>

    <!--Hours-->
    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <div class="iw-single-label col-xs-2 padding-top-15">
        {{ 'heuresRaports' | translate }}
    </div>
    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <div class="iw-single-label col-xs-2 padding-top-15">
        <div style="display: flex;">
            <iw-textfield #txtHeuresRapports1 [decimals]="2" [isDisabled]="isReadonly" formControlName="totheurap"
                          style="width: 70%" type="number"></iw-textfield>
            <iw-textfield #txtHeuresRapports2 [isDisabled]="isReadonly" formControlName="nbsalh"
                          style="width: 30%; text-align-last: center"></iw-textfield>
        </div>
    </div>
    <div class="iw-single-label col-xs-3 padding-top-15">
        {{ 'heuresTeam' | translate }}
    </div>
    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-textfield #txtHeuresEquipe [decimals]="2" [isDisabled]="isReadonly" class="iw-input col-xs-2 padding-top-15"
                  formControlName="indEq" type="number"></iw-textfield>

    <div class="iw-single-label col-xs-1"></div>
    <div class="iw-single-label col-xs-2">
        {{ 'heuresMannuelles' | translate }}
    </div>
    <div class="iw-single-label col-xs-1 flex-text-center">+</div>
    <div class="iw-single-label col-xs-2">
        <div style="display: flex">
            <iw-textfield [decimals]="2" [isDisabled]="isReadonly" class="padding-right-0" formControlName="totheuman"
                          style="width: 70%" txtHeuresManuelles type="number"></iw-textfield>
            <div style="width: 30%"></div>
        </div>
    </div>
    <div class="iw-single-label col-xs-3">
        {{ 'autreInd' | translate }}
    </div>
    <div class="iw-single-label col-xs-1 flex-text-center">+</div>
    <iw-textfield #txtAutresInd [decimals]="2" [isDisabled]="isReadonly" class="iw-input col-xs-2"
                  formControlName="indOth" type="number"></iw-textfield>

    <div class="iw-single-label col-xs-3"></div>
    <div class="iw-single-label col-xs-1 flex-text-center">=</div>
    <div class="iw-single-label col-xs-2">
        <div style="display: flex">
            <iw-textfield #txtHeuresTotal [decimals]="2" [isDisabled]="isReadonly" class="padding-right-0"
                          formControlName="totheu" style="width: 70%" type="number"></iw-textfield>
            <div style="width: 30%"></div>
        </div>
    </div>
    <div class="iw-single-label col-xs-3">
        {{ 'indemniteSoumises' | translate }}
    </div>
    <div class="iw-single-label col-xs-1 flex-text-center">+</div>
    <iw-textfield #txtIndSoumisse [decimals]="2" [isDisabled]="isReadonly" class="iw-input col-xs-2"
                  formControlName="indMan" type="number"></iw-textfield>

    <!--Salary-->

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <div class="iw-single-label col-xs-2 padding-top-15">
        {{ 'salaryAVS' | translate }}
    </div>
    <iw-textfield #txtSalaireAVS [decimals]="2" [isDisabled]="isReadonly"
                  class="iw-input col-xs-2 padding-top-15 padding-right-0" formControlName="salavs"
                  type="number"></iw-textfield>
    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <div class="iw-single-label col-xs-3 padding-top-15">
        {{ 'indemniteSoumisesTotal' | translate }}
    </div>
    <div class="iw-single-label col-xs-1 padding-top-15 flex-text-center">=</div>
    <iw-textfield #txtTotalInd [decimals]="2" [isDisabled]="isReadonly" class="iw-input col-xs-2 padding-top-15"
                  formControlName="indemnites" type="number"></iw-textfield>

    <div class="iw-single-label col-xs-1"></div>
    <iw-checkbox #Standardlogic (valueChange)="setSalaryAVS($event)" [isDisabled]="isReadonly"
                 [label]="'salaryAVSForce' | translate" class="iw-input col-xs-2"
                 formControlName="lforceavs"></iw-checkbox>
    <iw-textfield #txtForcerAVS [decimals]="2" [isDisabled]="isReadonly || !salaryAVS"
                  class="iw-input col-xs-2 padding-right-0" formControlName="salavsforc" type="number"></iw-textfield>
    <div class="iw-single-label col-xs-7"></div>

    <!-- Question 10-->
    <div class="iw-single-label col-xs-1 padding-top-15">10.</div>
    <div class="iw-single-label col-xs-11 padding-top-15">
        {{ 'grossSalaryAmount' | translate }}
    </div>

    <div class="col-xs-12 padding-top-15" style="height: 150px">
        <iw-rest-grid #olvDet [autoFormClick]="false" [defaultSelected]="true" [hasGridColumnMenu]="false"
                      [hasSortIcon]="true" [queryStatements]="query" [type]="type"></iw-rest-grid>
    </div>
</form>
