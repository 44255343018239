import {IwColumn} from '@app/sam-base/core/column/decorators/iw-column.decorator';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('saep')
export class Saep {
    @IwColumn() @IwRestEntityId() public epId?: string;
    public empId?: string;
    public salId?: string;
    public ficsalId?: string;
    public payType?: number;
    public dtaType?: number;
    public mpaId?: string;
    public payId?: string;
    public montant?: number;
    public datePmnt?: string;
    public banque?: string;
    public banqueAdr?: string;
    public banqueCpt?: string;
    public banqueClr?: string;
    public banqueSwi?: string;
    public iban?: string;
    public dtaId?: string;
    public noCheque?: string;
    public texte?: string;
    public payit?: boolean;
    public paid?: boolean;
    public salIdEnc?: string;
    public dateEnc?: string;
    public encaisse?: boolean;
    public supprime?: boolean;
    public origine?: string;
    public parentType?: string;
    public parentId?: string;
    public userId?: string;
}

