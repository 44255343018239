<form [formGroup]="formGroup" class="row">
    <iw-textfield ##txtLibcatemp3 [isDisabled]="isReadonly" [labelAlign]="'left'"
                  [label]="'title_category3' | translate" class="iw-input col-xs-9 padding-top-10 padding-bottom-20"
                  formControlName="libcatemp3"></iw-textfield>
    <iw-checkbox #Standardlogic3 [isDisabled]="isReadonly" [labelAlign]="'right'" [label]="'mandatory' | translate"
                 class="iw-input col-xs-3 padding-top-20" formControlName="cat3Force"></iw-checkbox>

    <iw-textfield #txtLibuserdat [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'title_date1' | translate"
                  class="iw-input col-xs-9 padding-top-10" formControlName="libuserdat"></iw-textfield>
    <div class="col-xs-3 padding-top-15"></div>

    <iw-textfield ##txtLibuserdat2 [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'title_date2' | translate"
                  class="iw-input col-xs-9 padding-top-10" formControlName="libuserda2"></iw-textfield>
    <div class="col-xs-3 padding-top-15"></div>

    <iw-textfield ##txtLibuserdat3 [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'title_date3' | translate"
                  class="iw-input col-xs-9 padding-top-10 padding-bottom-20"
                  formControlName="libuserda3"></iw-textfield>
    <div class="col-xs-3 padding-top-15"></div>

    <iw-textfield #txtLibusercha [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'title_champ1' | translate"
                  class="iw-input col-xs-9 padding-top-10" formControlName="libusercha"></iw-textfield>
    <div class="col-xs-3 padding-top-15"></div>

    <iw-textfield #txtLibusercha2 [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'title_champ2' | translate"
                  class="iw-input col-xs-9 padding-top-10" formControlName="libuserch2"></iw-textfield>
    <div class="col-xs-3 padding-top-15"></div>

    <iw-textfield #txtLibusercha3 [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'title_champ3' | translate"
                  class="iw-input col-xs-9 padding-top-10" formControlName="libuserch3"></iw-textfield>
    <div class="col-xs-3 padding-top-15"></div>
</form>
