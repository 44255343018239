<form [formGroup]="formGroup"
      class="row space-form">
    <iw-sapaimode-dropdown (selectedChanged)="changeDropdownValue($event)"
                           [isDisabled]="false"
                           [labelAlign]="'top'"
                           [label]="'pay_after' | translate"
                           [loadAtStartUp]="true"
                           [payType]="[3, 5, 6]"
                           [selectedChoice]="0"
                           class="iw-input col-xs-2 no-padding-left"
                           formControlName="bankId"></iw-sapaimode-dropdown>
    <iw-textfield [isDisabled]="true"
                  class="iw-input col-xs-4 no-label"
                  formControlName="bank"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  class="iw-input col-xs-2 no-label"
                  formControlName="bankClr"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  class="iw-input col-xs-2 no-label"
                  formControlName="bankCpt"></iw-textfield>
    <!--iw-textfield
                  class="iw-input col-xs-1 no-label"
                  [isDisabled]="true"
                  [hidden]="true"></iw-textfield-->
    <iw-textfield [isDisabled]="true"
                  class="iw-input col-xs-2 no-label"
                  formControlName="npaytype"></iw-textfield>

    <iw-textfield [label]="'date_execution' | translate"
                  class="iw-input col-xs-2 col-no-padding"
                  formControlName="dateExec"></iw-textfield>

    <iw-textfield [isDisabled]="false"
                  [label]="'libelle' | translate"
                  [value]="libelle"
                  class="iw-input col-xs-3 col-no-padding"
                  formControlName="libelle"></iw-textfield>

    <iw-dropdown [isDisabled]="isReadonly"
                 [options]="cpainavisOptions"
                 class="iw-input col-xs-1 no-label"
                 formControlName="cpainavisOpt"></iw-dropdown>

    <iw-checkbox [isDisabled]="false"
                 [labelAlign]="'top'"
                 [label]="'cr_lf' | translate"
                 class="iw-input col-xs-2 margin-top-25"
                 formControlName="crLf"></iw-checkbox>

    <iw-button #btnOk
               (press)="submit()"
               [isDisabled]="!formFullfilled()"
               [text]="'execute' | translate"
               class="iw-button col-xs-2 no-label"
               id="ok-misnew"></iw-button>

    <iw-button #Standardbutton2
               (press)="destroyForm()"
               [text]="'annuler' | translate"
               class="iw-button col-xs-2 no-label"></iw-button>

    <iw-table #lstMdl
              (columnSort)="onSort($event)"
              (selectedChange)="updateClientValues($event)"
              [columns]="columns"
              [data]="availablePayments"
              [defaultSelected]="true"
              [isDisabled]="false"
              [isFilterEnable]="false"
              [isGridColumnMenuEnable]="false"
              [isSortIconEnable]="true"
              [selectionMode]="'checkbox'"
              class="iw-grid col-xs-12"
              style="height: 200px"></iw-table>
</form>
