import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {RestEntityClient} from '@app/sam-base/core/rest-api/entity/rest-entity-client';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys} from '@app/sam-base/models';
import {Ppgri, Ppmis, Ppmisih} from '@app/sam-base/models/placement';
import {SetLoading} from '@sam-base/core/store/actions/global-form.actions';

import {SaisieEnum} from './misind-enums';

@Component({
    templateUrl: './misind.component.html'
})
export class MisindComponent extends BaseStoreFormComponent<Ppmisih> implements OnInit {

    public get misId(): string | undefined {
        return this.getData('misId');
    }

    public mis?: Ppmis = {};
    public gri: string | undefined;
    public saisie = SaisieEnum;
    private _entityPpgriService: RestEntityClient<Ppgri>;

    constructor(store: IwStoreService, restService: RestApiService) {
        super(store);
        this._entityPpgriService = restService.getEntityClient(Ppgri);
        this.subscribeValueChange('griId', () => {
            this.gri = this.getFormValue('griId');
            if (!this.gri) {
                return;
            }
            this._entityPpgriService.getById(this.gri)
                .subscribe((ppgri: Ppgri) => {
                    this.setFormValue('libelle', ppgri.griName);
                });
        });
    }

    public ngOnInit() {
        if (this.isNew) {
            this.setFormValue('misId', this.misId);
            this.setFormValue('cid', this.misId);
            this.setFormValue('cdeormis', 'M');
            this.setFormValue('indorheu', 'I');
            this.setFormValue('saisiemode', SaisieEnum.semaine);
        }
    }

    public saveIndemnity() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        setTimeout(() => {
            this.saveChanges();
        }, 500);
    }

    protected getFormControlNames(): FormKeys<Ppmisih> {
        return [['griId', new UntypedFormControl(undefined, Validators.required)], 'misId', 'cid', 'qte', 'unite', 'cdeormis', 'indorheu', 'mntemp', 'mntcli', 'mntcli2', 'libelle', 'saisiemode', 'notes'];
    }
}
