import {Ppheu} from '@app/sam-base/models/placement';

// eslint-disable-next-line complexity
export function getGrhIdKey(grh?: string): keyof Ppheu | undefined {
    switch (grh) {
        case 'H100':
            return 'nbheu100';
        case 'H125':
            return 'nbheu125';
        case 'H150':
            return 'nbheu150';
        case 'H200':
            return 'nbheu200';
        case 'HEQ1':
            return 'nbheuequip';
        case 'HPAU':
            return 'nbheupause';
        case 'HNUI':
            return 'nbheunuit';
        case 'HEQX':
            return 'nbheuequix';
        case 'HXXX':
            return 'nbheuxxx';
        default:
            return undefined;
    }
}
