/** Available form descriptions */
export enum AdminForm {
    SamUserAdminEdit = 'kusers',
    SamUserAdminList = 'kuserstable',
    SwissdecList = 'swissdectable',
    SwissdecNew = 'swissdecnew',
    SwissdecDeclaration = 'sasx5dec',
    SwissdecDeclarationInstitution = 'swissdecdeclarationinstitution',
    ResetUserPassword = 'kuserpwdreset',
}
