<form [formGroup]="formGroup" class="row space-form">
    <div class="row no-padding">

        <iw-entity-textfield #empId [allowSearch]="true" [entity]="ppemp" [isDisabled]="readonly" [labelAlign]="'top'"
                             [label]="'employe' | translate" [name]="['nom', 'prenom']" [showIdField]="false"
                             class="iw-input col-xs-4" formControlName="empId"></iw-entity-textfield>

        <iw-button #btnGenerate (press)="generate()" [isDisabled]="!isValid" [text]="'generer' | translate"
                   class="iw-button col-xs-4 col-xs-offset-4 flex-end"></iw-button>

        <iw-periode-dropdown #salId [isDisabled]="readonly" [labelAlign]="'top'" [label]="'periode' | translate"
                             class="iw-input col-xs-4" formControlName="salIdLim"></iw-periode-dropdown>

        <iw-button #btnCancel (press)="cancel()" [text]="'annuler' | translate"
                   class="iw-button col-xs-4 col-xs-offset-4 flex-end"></iw-button>
    </div>
</form>