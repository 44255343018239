import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';

import {MisnewComponent} from '../components/missions/misnew/misnew.component';
import {Misnew} from '../components/missions/misnew/misnew.model';

export const MISNEWDEF_DEFAULT_FORM: FormDescription<Misnew> = {
    form: MisnewComponent, state: {
        ...buildDefaultState(Misnew), diagTitle: 'misnew_form_title', isNew: true, width: 460
    }
};
