<iw-modal-form (pressCancel)="onPressCancel()" (pressOk)="onPressOk()" [btnOk]="btnOk" [height]="'auto'"
               [title]="'employeeDeclarationEdit'" [width]="750">
    <div class="row">
        <div class="col-xs-6">
            <iw-textfield #prenom [(value)]="employeeDeclaration.prenom" [isDisabled]="true" [labelAlign]="'top'"
                          [label]="'prenom' | translate"></iw-textfield>
        </div>
        <div class="col-xs-6">
            <iw-textfield #nom [(value)]="employeeDeclaration.nom" [isDisabled]="true" [labelAlign]="'top'"
                          [label]="'emp_nom' | translate"></iw-textfield>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-6">
            <iw-date-picker #orignalDate [(value)]="employeeDeclaration.originalDate" [isDisabled]="true"
                            [labelAlign]="'top'" [label]="'originalDate' | translate"></iw-date-picker>
        </div>
        <div class="col-xs-6">
            <iw-textfield #orignalDocId [(value)]="employeeDeclaration.originalDocId" [isDisabled]="true"
                          [labelAlign]="'top'" [label]="'originalDocId' | translate"></iw-textfield>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-6">
            <iw-textfield #newDocId [(value)]="employeeDeclaration.newDocId" [isDisabled]="true" [labelAlign]="'top'"
                          [label]="'newDocId' | translate"></iw-textfield>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">

            <iw-textarea #remarks [(value)]="employeeDeclaration.remarks" [isDisabled]="isReadOnly" [labelAlign]="'top'"
                         [label]="'remarques' | translate" [maxLength]="250"></iw-textarea>
        </div>
    </div>
    <ng-container *ngIf="employeeDeclaration?.avsIncome < 0">
        <hr>

        <div class="row">
            <div class="col-xs-12">
                <iw-date-picker #additionalDeliveryDate (valueChange)="onSplitDateChange()"
                                [(value)]="employeeDeclaration.additionalDeliveryDate" [isDisabled]="isReadOnly"
                                [labelAlign]="'top'" [label]="'additionalDeliveryDate' | translate"></iw-date-picker>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-5">
                <iw-textfield #avsIncome [(value)]="employeeDeclaration.avsIncome" [isDisabled]="true"
                              [labelAlign]="'top'" [label]="'avsIncomeToSplit' | translate"
                              type="number"></iw-textfield>
            </div>
            <div class="col-xs-6">
                <iw-textfield #avsIncomeUserSplit [isDisabled]="true" [labelAlign]="'top'"
                              [label]="'diffUserAvsSplitTotalAmount' | translate" [value]="diffUserAvsSplitTotalAmount"
                              type="number"></iw-textfield>
            </div>
        </div>
        <div *ngFor="let split of splits; let i = index" class="row">
            <div class="col-xs-5">
                <iw-textfield-autocomplete #drpPeriod [(value)]="split.year" [doubleClickable]="true"
                                           [fetchAction]="annees$" [isDisabled]="i == 0 || isReadOnly"
                                           [propsToFilter]="['name']" class="iw-input"></iw-textfield-autocomplete>
            </div>
            <div class="col-xs-6">
                <iw-textfield #avsIncome (valueChange)="onIncomeSplitChange()" [(value)]="split.amount"
                              [isDisabled]="i == 0 || isReadOnly" type="number"></iw-textfield>
            </div>
            <div class="col-xs-1">
                <iw-button (press)="removeSplit(split)" [iconClass]="'fas fa-minus'" [isDisabled]="i == 0 || isReadOnly"
                           [isDisabled]="isReadOnly"></iw-button>
            </div>

        </div>
        <div class="row end-xs">
            <div class="col-xs-1">
                <iw-button (press)="addSplit()" [iconClass]="'fas fa-plus'" [isDisabled]="isReadOnly"></iw-button>
            </div>
        </div>
    </ng-container>
    <div class="col-xs-12 padding-top-10"></div>
</iw-modal-form>

