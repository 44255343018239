<form [formGroup]="formGroup" class="row">
    <div class="col-xs-12" style="height: 150px">
        <div class="col-xs-12 title-grid">{{ 'rapports' | translate }}</div>
        <iw-rest-grid #olvRap (dataChanged)="dataReloadedDebouncer.next(true)"
                      (rowDoubleClick)="openRapportForm($event)" [autoFormClick]="false" [defaultSelected]="false"
                      [hasGridColumnMenu]="false" [hasSortIcon]="true" [innerWidth]="tableWidthR"
                      [queryStatements]="queryR" [type]="typeR" class="tab-rapports"></iw-rest-grid>
    </div>

    <div class="col-xs-12 margin-top-30" style="height: 150px">
        <div class="col-xs-12 title-grid">{{ 'indemnites' | translate }}</div>
        <iw-rest-grid #olvInd (dataChanged)="dataReloadedDebouncer.next(true)" [autoFormClick]="false"
                      [defaultSelected]="false" [hasGridColumnMenu]="false" [hasSortIcon]="true"
                      [innerWidth]="tableWidthI" [queryStatements]="queryI" [type]="typeI"
                      class="tab-rapports"></iw-rest-grid>
    </div>

    <div class="row col-xs-12 margin-top-30 no-padding">
        <iw-textfield #txtTotHeu [isDisabled]="true" [labelAlign]="'left'" [label]="'totalHeures' | translate"
                      class="iw-input col-xs-3" formControlName="totheu" stepValue="0.01" type="number"></iw-textfield>

        <iw-date-picker #txtFirstday [isDisabled]="true" [labelAlign]="'left'" [label]="'1ErJour' | translate"
                        [showWeekDay]="true" class="iw-input col-xs-offset-1 col-xs-4 no-padding-right"
                        formControlName="firstday"></iw-date-picker>

        <div class="col-xs-1"></div>

        <iw-textfield #txtTotRap [isDisabled]="true" [labelAlign]="'left'" [label]="'rapports' | translate"
                      class="iw-input col-xs-3" formControlName="totrap" type="number"></iw-textfield>

        <iw-textfield #txtTotJou [isDisabled]="true" [labelAlign]="'left'" [label]="'totalJours' | translate"
                      class="iw-input col-xs-3" formControlName="totjou" type="number"></iw-textfield>

        <div class="col-xs-1"></div>

        <iw-date-picker #txtLastDay [isDisabled]="true" [labelAlign]="'left'" [label]="'dernierJour' | translate"
                        [showWeekDay]="true" class="iw-input col-xs-4 no-padding-right"
                        formControlName="lastday"></iw-date-picker>

        <iw-textfield #txtNbJMis [isDisabled]="true" class="iw-input col-xs-1" formControlName="nbjmis"></iw-textfield>

        <iw-textfield #txtTotsem [isDisabled]="true" [labelAlign]="'left'" [label]="'semaines' | translate"
                      class="iw-input col-xs-3" formControlName="totsem" type="number"></iw-textfield>
    </div>
</form>
