import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const sasx5institutionTabsFormControls: TabFormControl<Sasx5institution>[] = [{
    title: 'general',
    formControls: ['id',
        'xmlReceived',
        'state',
        'userMessage',
        'completionUrl',
        'login',
        'password',
        'notifications']
},
    {
        title: 'dialog',
        formControls: ['id',
            'xmlReceived']
    },
    {
        title: 'xml',
        formControls: ['xmlReceived']
    }];
