<form [formGroup]="formGroup" class="row spaceForm">
    <iw-rest-grid #lstJfDate [autoFormClick]="false" [customColumns]="columns" [hasGridColumnMenu]="false"
                  [hasSortIcon]="false" [queryStatements]="query" [rawTableMode]="true" [type]="type"
                  class="iw-input col-xs-12" formControlName="jfId" style="height: 150px"></iw-rest-grid>

    <iw-agency-combo-box #cboAge_id [labelAlign]="'left'" [label]="'agence' | translate"
                         class="iw-input col-xs-12 padding-top-5"></iw-agency-combo-box>

    <iw-textfield #txtHmin [labelAlign]="'left'" [label]="'minimumHJourOuvrable' | translate" [size]="'large'"
                  class="iw-input col-xs-12 padding-top-5" formControlName="hmin"></iw-textfield>

    <iw-textfield #txtNbjperiode [labelAlign]="'left'" [label]="'dureePeriodeDeCalcul' | translate" [size]="'large'"
                  class="iw-input col-xs-12 padding-top-5" formControlName="nbjperiode"></iw-textfield>

    <iw-checkbox #Standardlogic1 [label]="'2JoursSuivants' | translate" class="iw-input col-xs-5 padding-top-5"
                 formControlName="l2Jsuiv"></iw-checkbox>
    <div class="col-xs-7"></div>

    <div class="col-xs-8 no-padding"></div>

    <iw-button #btnCancel (press)="destroyForm()" [text]="'annuler' | translate" class="iw-button col-xs-4"></iw-button>
</form>
