import {AfterViewInit, Component, OnDestroy, OnInit, Type} from '@angular/core';

import {Validators} from '@angular/forms';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {SortInfo} from '@app/sam-base/components';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {ERROR} from '@app/sam-base/core/logger/logger';
import {RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {CctService} from '@app/sam-base/core/services/cct.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {EntityCreate, EntityDelete, EntityUpdate, IwStoreService} from '@app/sam-base/core/store';
import * as globalActions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormState} from '@app/sam-base/core/store/reducers/form.reducer';
import {getFormState} from '@app/sam-base/core/store/selectors/form.selectors';
import {ToastService} from '@app/sam-base/core/toast';
import {ComboboxItem, FormKeys, ModalComponent} from '@app/sam-base/models';
import {Ppcct} from '@app/sam-base/models/placement';
import {CctSearchTerm} from '@app/sam-base/models/placement/cct-search-term';
import {select} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from '@shared/profile/profile.service';
import {UserProfile} from '@shared/profile/user-profile.model';
import {
    IwModalSelectTableComponent
} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.component';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {cctConfigTabsFormControls} from './cct-config-tabs-formcontrols';
import {CctLanguages, ModalCctResponse} from './cct-config.enums';

@Component({
    templateUrl: './cct-config.component.html'
})
export class CctConfigComponent extends BaseTabFormComponent<Ppcct> implements AfterViewInit, OnDestroy, OnInit {

    public type = Ppcct;
    public readonly = true;
    public newEntryMode = false;
    public cctId = '';
    public selectedRow?: Ppcct;
    public isSaving = false;
    public formState?: FormState;
    public cboFact2gsOptions: ComboboxItem<string>[] = [];
    public searchTerm?: string;
    public listOption?: ModalSelectTableOption<CctSearchTerm, ModalCctResponse>;
    public sortProp?: keyof Ppcct = 'dateTrack';
    public sortPropDir?: 'asc' | 'desc' = 'desc';
    public cctLanguageOptions = CctLanguages;
    /** Dummy query created to bypass filter that requires some sort of params */
    public query: RestQueryParam<Ppcct, string>[] = [{
        prop: 'cctstatus', operation: RestQueryOperation.Equals, value: '1'
    }];
    private readonly _restPpcctClient: RestEntityClient<Ppcct>;
    private readonly cctIdPatternValidator = Validators.pattern('^[a-zA-Z0-9]{1,8}$');
    private subscriptions = new Subject();

    constructor(rest: RestApiService, store: IwStoreService, private _translate: TranslateService, private _toastService: ToastService, private readonly _modalService: ModalService, private _cctService: CctService, private _profileService: ProfileService) {
        super(store);
        this._restPpcctClient = rest.getEntityClient(Ppcct);
    }

    public get tabData(): Ppcct | undefined {
        return this.getFormData();
    }

    public set tabData(v: Ppcct | undefined) {
        this.mergeEntityChanges(v);
    }

    public get selectedData() {
        if (!this.selectedRow) {
            return undefined;
        }
        return {name: 'cctId', value: this.selectedRow.cctId};
    }

    public get isWriteMode() {
        const value = this.formState && this.formState.mode === 'edit';
        return typeof value === 'boolean' ? value : true;
    }

    public get isLexfcgroupChecked() {
        const formData = super.getFormData();

        return !!(formData && formData.lexfcgroup);
    }

    public get tempdataSearchDisabled() {
        return (this.readonly || !this.searchTerm || !this.getFormValue('tempdataTrans'));
    }

    public ngOnInit() {
        this.subscribeValueChange('tempdataTrans', (translation) => {
            if (!translation) {
                this.getUserLanguage();
            }
        });
    }

    public ngAfterViewInit() {
        this._store
            .pipe(takeUntil(this.subscriptions), select((state) => getFormState(state, this.uuid)))
            .subscribe((state) => {
                setTimeout(() => {
                    this.formState = state;
                }, 0);
            });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public getTabsValues(): TabFormControl<Ppcct>[] {
        return cctConfigTabsFormControls;
    }

    /**
     * Function to get selected row
     *
     * @param event Ppcct[]
     * @returns void
     */
    // eslint-disable-next-line complexity
    public getSelectedRow(event: Ppcct[]) {
        if (this.isSaving) {
            // Prevents clearing of the selectedRow
            this.isSaving = false;
            return;
        }

        this.readonly = true;
        // eslint-disable-next-line complexity
        if (event.length) {
            this._store.dispatch(new globalActions.SetReadMode(this.uuid));
        }

        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }

        this.cctId = (event[0] && event[0].cctId) || '';
        this.selectedRow = event[0];
        this._store.dispatch(new globalActions
            .NavigationEntity(this.uuid, event[0].cctId ?? ''));
    }

    /**
     * Function to create new ppcct entry
     *
     * @returns void
     */
    public createNewEntry() {
        this.selectedRow = undefined;
        this.readonly = false;
        this._store.dispatch(new globalActions.SetEditMode(this.uuid));
        this.clearForm();
        this.setFormValue('cctstatus', '1');
        this.newEntryMode = true;
        this.updateCctIdValidator(true);
        this.searchTerm = undefined;
    }

    /**
     * Function to save changes on existing or new entry
     *
     * @returns void
     */
    public saveEntry() {
        const formData = this.getFormData();

        const updatedData = this._restPpcctClient
            .construct({...this.selectedRow, ...formData});
        this.readonly = true;
        this.clearForm();

        if (!updatedData || !updatedData.cctId) {
            this._toastService.error('need_to_fill_id');
            this.newEntryMode = false;
            return;
        }

        this.isSaving = true;

        if (this.newEntryMode) {
            this._restPpcctClient.getById(updatedData.cctId)
                .pipe(catchError(err => of(err)))
                .subscribe((res) => {
                    if (res['status'] === 404) {
                        this.setSortByModif();
                        this._store.dispatch(new EntityCreate(updatedData));
                    } else {
                        this._toastService
                            .error(this._translate.instant('id_already_exits'));
                    }
                });

            this.newEntryMode = false;
            return;
        }
        this._store.dispatch(new EntityUpdate(updatedData));
        this.newEntryMode = false;
        this.searchTerm = undefined;
    }

    /**
     * Set fields editable
     *
     * @returns void
     */
    public modifyEntry() {
        if (this.selectedRow && this.selectedRow.cctId) {
            this.readonly = false;
            this._store.dispatch(new globalActions.SetEditMode(this.uuid));
            this.newEntryMode = false;
            this.updateCctIdValidator(false);
        }
    }

    /**
     * Function to remove entry
     *
     * @returns void
     */
    public async removeEntry() {
        const options: ModalMessageOptions = {
            message: [],
            showCancel: false,
            title: this._translate.instant('cct'),
            confirmMessage: this._translate.instant('supprimerEnregisrement'),
            alertsMessage: '',
            okDisabled: false
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            if (this.selectedRow && this.selectedRow.cctId) {
                this._store.dispatch(new EntityDelete(Ppcct, this.cctId));
            }
        } catch (error) {
        }
    }

    /**
     * Cancel the edition mode
     *
     * @returns void
     */
    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.readonly = true;
        this._store.dispatch(new globalActions.SetReadMode(this.uuid));
        this.newEntryMode = false;
        this.searchTerm = undefined;
    }

    public closeDialog() {
        this._store.dispatch(new globalActions.DestroyForm(this.uuid));
    }

    public openCloseCCT() {
        this.setFormValue('cctstatus', this.getFormValue('cctstatus') === '0' ? '1' : '0');
        this._store.dispatch(new globalActions.SetReadMode(this.uuid));
        this.saveEntry();
    }

    public getCctClass() {
        let cctstatus = this.getFormValue('cctstatus');

        if (cctstatus === '0') {
            cctstatus = '0';
        } else if (cctstatus === '1') {
            cctstatus = '1';
        } else {
            cctstatus = '';
        }
        return 'cct-color-' + cctstatus;
    }

    public copyCCT() {
        this.selectedRow = undefined;
        this.readonly = false;
        this._store.dispatch(new globalActions.SetEditMode(this.uuid));
        this.setFormValue('cctId', undefined);
        this.newEntryMode = true;
    }

    public async searchByTerm() {
        this.listOption = {
            columns: [{prop: 'contractName'}],
            map: (e: CctSearchTerm) => ({
                contractNo: e.contractNumber || '', contractName: e.contractName || ''
            }),
            selection: 'single',
            size: 900,
            fetchAction: () => this._cctService.getSuggestionsByTerm(this.searchTerm || '', this.getFormValue('tempdataTrans'))
        };

        if (!this.listOption) {
            return;
        }
        // eslint-disable-next-line max-len
        const comp: Type<ModalComponent<ModalCctResponse[], CctSearchTerm>> = <any>IwModalSelectTableComponent;
        try {
            const data = await this._modalService
                .showModal<ModalCctResponse[], CctSearchTerm>(comp, <any>this.listOption);

            this.setFormValue('contractNo', data[0].contractNo);
            this.setFormValue('tempdataTitle', data[0].contractName);
            this.searchTerm = undefined;
        } catch (err) {
            ERROR('Unable to open modal', err);
        }
    }

    public setSortInfo(sortInfo: SortInfo<Ppcct>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    protected getValidationType() {
        return Ppcct;
    }

    protected getFormControlNames(): FormKeys<Ppcct> {
        return this.getTabsFormControls(cctConfigTabsFormControls);
    }

    private setSortByModif() {
        // Force the update of the string reference
        this.sortProp = 'dateTrack';
        this.sortPropDir = 'desc';
    }

    private getUserLanguage() {
        this._profileService.loadProfile()
            .subscribe((userProfile: UserProfile) => {
                this.setFormValue('tempdataTrans', (ProfileService.langParser(userProfile?.lang) || 'fr'));
            });
    }

    private updateCctIdValidator(isCreateMode: boolean) {
        // In case of creation, we need to add validators for cctId.
        // Existing cctIds might not fulfill this pattern and cannot be changed
        if (isCreateMode) {
            this.formGroup.controls['cctId'].addValidators(this.cctIdPatternValidator);
        } else {
            this.formGroup.controls['cctId'].removeValidators(this.cctIdPatternValidator);
        }
        this.formGroup.controls['cctId'].updateValueAndValidity();
    }
}
