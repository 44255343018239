import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('sancsmot')
export class Sancsmot {
    public csEmpid?: string;
    public csYear?: string;
    public csMot1?: number;
    public csMot11?: string;
    public csMot12?: string;
    public csMot2?: number;
    public csMot3?: number;
    public csMot31?: string;
    public csMot32?: string;
    public csMot4?: number;
    public csMot41?: string;
    public csMot42?: string;
    public csMot5?: number;
    public csMot51?: string;
    public csMot52?: string;
    public csMot53?: string;
    public csMot6?: number;
    public csMot61?: string;
    public csMot7?: number;
    public csMot71?: number;
    public csMot8?: number;
    public csMot81?: number;
    public csMot9?: number;
    public csMot10?: number;
    public csMot110?: number;
    public csMot111?: string;
    public csMot112?: string;
    public csMot120?: number;
    public csMot1201?: string;
    public csMot1201Rate?: number;
    public csMot1201CState?: string;

    @IwRestEntityId()
    public get id(): string {
        return `${this.csEmpid},${this.csYear}`;
    };
}
