import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {SamUserData} from '@sam-base/models/admin/sam-user-data.model';

import {UserRolesConfigComponent} from '../components/user-roles-config/user-roles-config.component';

export const USER_ROLES_CONFIG: FormDescription<SamUserData> = {
    form: UserRolesConfigComponent,
    state: {
        ...buildDefaultState(SamUserData),
        diagModal: true,
        diagTitle: 'user_roles',
        width: 1000
    }
};
