<div class="row doc-type-manager-container">
    <div class="col-xs-9">
        <iw-rest-grid #docGrid
                      (rowDoubleClick)="editDocumentType($event)"
                      (selected)="onRowSelect($event)"
                      [hasGridColumnMenu]="false"
                      [hasSortIcon]="false"
                      [queryStatements]="query"
                      [type]="type"
                      id="docGrid"></iw-rest-grid>
    </div>
    <!--    DISABLE FOR NOW AS IT SEEMS WE DO NOT WANT THOSE BEHAVIOR ANYMORE -->
    <!--    <div class="col-xs-3 row flex flex-v flex-evenly">-->
    <!--        <div class="row">-->
    <!--            <div class="col-xs-12 no-padding">-->
    <!--                <iw-button #validate (press)="onValidateDocuments()" [isDisabled]="isReadonly"-->
    <!--                           [text]="'validateDocuments' | translate" id="validate"></iw-button>-->
    <!--            </div>-->
    <!--            <div class="col-xs-12 no-padding-left no-padding-right padding-top-10">-->
    <!--                <iw-button #edit (press)="editDocumentType()" [isDisabled]="!selectedDocTypeId || isReadonly"-->
    <!--                           [text]="'doc_manag_edit' | translate"></iw-button>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <iw-button #supprimer (press)="deleteDoctype()" [isDisabled]="!selectedDocTypeId || isReadonly"-->
    <!--                   [text]="'supprimer' | translate"></iw-button>-->
    <!--    </div>-->
</div>
