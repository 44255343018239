import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Invoice} from '@app/sam-base/models/placement';

import {InvoicesComponent} from '../components/invoices/invoices.component';

export const INVOICES_DEFAULT_FORM: FormDescription<Invoice> = {
    form: InvoicesComponent, state: {
        ...buildDefaultState(Invoice),
        diagTitle: 'invoices_form_title',
        diagModal: false,
        editMode: 'waiting',
        width: 1600
    }
};
