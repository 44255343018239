import {KeycloakAngularModule} from 'keycloak-angular';

import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {ApplicationRef, DoBootstrap, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EnvironmentModule} from '@app/sam-base/core';
import {
    BootstrapAuthService
} from '@app/sam-base/core/auth/services/bootstrap-auth.service';
import {reducers} from '@app/sam-base/core/store';
import {CoreModule} from '@core/core.module';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '@root/environments/environment';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing';
import {LayoutModule} from './layout';
import {SamMainModule} from './modules/sam-main/sam-main.module';
import {SharedModule} from './shared/shared.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '/api/translation/', '');
}

const STORE_DEVTOOLS = environment.production ? [] : [StoreDevtoolsModule.instrument({
    logOnly: environment.production, maxAge: 25
})];

@NgModule({ declarations: [AppComponent], imports: [BrowserModule, BrowserAnimationsModule, CoreModule, EnvironmentModule.forRoot(environment), TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader, useFactory: createTranslateLoader, deps: [HttpClient]
            }
        }), StoreModule.forRoot(reducers, {
            metaReducers: [], runtimeChecks: {
                strictStateImmutability: false, strictActionImmutability: false
            }
        }), ...STORE_DEVTOOLS, LayoutModule, SharedModule, KeycloakAngularModule, AppRoutingModule, SamMainModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppModule implements DoBootstrap {

    constructor(private readonly _bootstrapAuth: BootstrapAuthService) {
    }

    public ngDoBootstrap(appRef: ApplicationRef) {
        this._bootstrapAuth.bootstrapWithAuth(appRef, AppComponent);
    }
}
