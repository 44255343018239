import {IwRestEntity} from '@app/sam-base/core/rest-api/entity/rest-entity.decorator';

@IwRestEntity('ppmis')
export class Misnew {
    public cdeId?: string;
    public empId?: string;
    public cliId?: string;
    public conId?: string;
    public cpyfrommdl?: boolean;
    public createmodel?: boolean;
    public modelTitle?: boolean;
    public mismdlId?: string;
}
