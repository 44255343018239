<form [formGroup]="formGroup"
      class="row">
    <iw-textfield #txtRap_id
                  [isDisabled]="true"
                  [labelAlign]="'left'"
                  [label]="'id' | translate"
                  class="iw-input col-xs-3 no-padding-right"
                  formControlName="rapId"></iw-textfield>


    <iw-textfield #txtSal_id
                  [isDisabled]="true"
                  class="iw-input col-xs-1 no-padding"
                  formControlName="salId"></iw-textfield>


    <iw-textfield #txtModecreat
                  [isDisabled]=true
                  [ngClass]="createdModeClass"
                  class="iw-input col-xs-1 no-padding-left"
                  formControlName="modecreat"></iw-textfield>

    <iw-entity-textfield #txtNomEmployé
                         [entity]="entityPpemp"
                         [inputClass]="'highlight'"
                         [isDisabled]=true
                         [labelAlign]="'left'"
                         [label]="'employe' | translate"
                         [name]="['nom', 'prenom']"
                         [showIdField]="true"
                         class="iw-input col-xs-6"
                         formControlName="empId"></iw-entity-textfield>


    <iw-button #empBtn
               (press)="openEmp()"
               [isDisabled]="false"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>


    <iw-textfield #txtSemaine
                  [isDisabled]="true"
                  [labelAlign]="'left'"
                  [label]="'semaine' | translate"
                  [value]="currentWeekDay"
                  class="iw-input col-xs-3 no-padding-right"></iw-textfield>


    <iw-textfield #txtDateLundi
                  [isDisabled]="true"
                  class="iw-input col-xs-2 no-padding-left"
                  formControlName="datelundi"></iw-textfield>

    <iw-entity-textfield #txtNomClient
                         [entity]="entityClient"
                         [inputClass]="'highlight'"
                         [isDisabled]=true
                         [labelAlign]="'left'"
                         [label]="'client' | translate"
                         [name]="['nom']"
                         [showIdField]="true"
                         class="iw-input col-xs-6"
                         formControlName="cliId"></iw-entity-textfield>
    <iw-button #cliBtn
               (press)="openCli()"
               [isDisabled]="false"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>

    <iw-textfield #txtRap_no
                  [isDisabled]="true"
                  [labelAlign]="'left'"
                  [label]="'noSess' | translate"
                  class="iw-input col-xs-3 no-padding-right"
                  formControlName="rapNo"></iw-textfield>


    <iw-textfield #txtSession
                  [isDisabled]="true"
                  class="iw-input col-xs-2 no-padding-left"
                  formControlName="session"></iw-textfield>


    <iw-textfield #txtMis_id
                  [isDisabled]="true"
                  [labelAlign]="'left'"
                  [label]="'mission' | translate"
                  class="iw-input col-xs-3 no-padding-right"
                  formControlName="misId"></iw-textfield>


    <iw-textfield #Standardfield1
                  [(value)]="mission.ageId"
                  [isDisabled]="true"
                  class="iw-input col-xs-1 no-padding"></iw-textfield>


    <iw-textfield #Standardfield2
                  [(value)]="mission.secId"
                  [isDisabled]="true"
                  class="iw-input col-xs-1 no-padding"></iw-textfield>


    <iw-textfield #txtQualif
                  [(value)]="mission.qualif"
                  [isDisabled]="true"
                  class="iw-input col-xs-1 no-padding-left"></iw-textfield>

    <iw-button #misBtn
               (press)="openMis()"
               [isDisabled]="false"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>


</form>

<iw-tab [(selectedTab)]="selectedTab"
        [invalidTabs]="invalidTabList"
        [tabs]="tabList">
    <iw-tab-rapport-recap *ngIf="selectedTab === tabList[0]"
                          [acompte]="acompte"
                          [formGroup]="formGroup"
                          [isReadonly]="!isWriteMode"
                          [mission]="mission"
                          [uuid]="uuid"></iw-tab-rapport-recap>
    <iw-tab-rapport-heures *ngIf="selectedTab === tabList[1]"
                           [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"
                           [report]="report"></iw-tab-rapport-heures>
    <iw-tab-rapport-indemnites *ngIf="selectedTab === tabList[2]"
                               [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-rapport-indemnites>
    <iw-tab-rapport-notes *ngIf="selectedTab === tabList[3]"
                          [formGroup]="formGroup"
                          [isReadonly]="!isWriteMode"></iw-tab-rapport-notes>
</iw-tab>
