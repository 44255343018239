import {buildDefaultState} from '@app/sam-base/core';
import {FormDescription} from '@app/sam-base/core/form-handler';
import {Sancsmot} from '@app/sam-base/models/salary/sancsmot';
import {DialogAvanceeComponent} from '../components/employee/tab-saemp-ncs/dialog-avancee/dialog-avancee.component';

export const SANCSMOT_DEFAULT_FORM: FormDescription<Sancsmot> = {
    form: DialogAvanceeComponent, state: {
        ...buildDefaultState(Sancsmot),
        diagTitle: 'combo_ncs',
        diagModal: true,
        width: 700,
        diagShowSideMenu: true,
        diagSideMenu: {
            showCancel: false, showClose: true, showEdit: true, showList: false, showPrint: false, showDelete: false
        },
        editMode: 'edit'
    }
};
