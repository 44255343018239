import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ParametersService} from '@core/services/parameters.service';
import {MessageFormRefreshService} from '@modules/sam-main/subjects/message-service-subject.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseSideFormComponent} from '@sam-base/base/base-side-form.component';
import {ModalMessageComponent} from '@sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@sam-base/components/modal/modal-message/modal-message.options';
import {
    EntityNavigationService,
    IwEventHubService,
    IwStoreService,
    ModalService,
    ViewContainerRefService
} from '@sam-base/core';
import {EncaissementEvents} from '@sam-base/core/events/actions/encaissement.actions';
import {EncaissementService} from '@sam-base/core/services/encaissement.service';
import {EncaissementAutomatic} from '@sam-base/models/invoices/encaissement-automatic.model';
import {Subject, takeUntil} from 'rxjs';


@Component({
    templateUrl: './encaissement-automatic-side-menu.component.html'
})
export class EncaissementAutomaticSideMenuComponent extends BaseSideFormComponent<EncaissementAutomatic> implements OnInit, OnDestroy {

    @Input() public isReadonly = true;
    public encaissementAutomatic: EncaissementAutomatic = new EncaissementAutomatic();
    public isLoading = false;
    public isEditMode = '';
    private subscription = new Subject();

    constructor(private readonly _store: IwStoreService, private _translate: TranslateService,
                private _modalService: ModalService, private _encaissementService: EncaissementService,
                private _parametersService: ParametersService,
                private _navigationService: EntityNavigationService, private _viewHostRef: ViewContainerRefService,
                private readonly _eventHub: IwEventHubService<EncaissementEvents>,
                private readonly refreshFormMessageService: MessageFormRefreshService) {
        super();
    }

    public ngOnInit(): void {
        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscription))
            .subscribe((data: any) => {
                this.encaissementAutomatic = data;
            });
        this._store.globalForm(this.uuid)
            .state
            .pipe(takeUntil(this.subscription))
            .subscribe((state: any) => {
                this.isEditMode = state.editMode;
            });
    }

    public ngOnDestroy(): void {
    }

    public async importBVRLines() {
        const options: ModalMessageOptions = {
            message: [this._translate.instant('create_encaissements')],
            showCancel: true,
            title: '',
            width: 400,
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this.isLoading = true;
            this._encaissementService.importBVRLines(this.encaissementAutomatic).subscribe((encaissements) => {
                this.isLoading = false;
                console.log('encaissements', encaissements);
                this._eventHub.emit(EncaissementEvents.imported, encaissements);
            });
        } catch (error) {
            this.isLoading = false;
        }
    }

}
