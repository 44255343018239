import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Pppar} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-pppar-formats', templateUrl: './tab-pppar-formats.component.html'
})
export class TabPpparFromatsComponent extends BasePartialFormComponent<Pppar> {

    @Input() public isReadonly = true;

}
