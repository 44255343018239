import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';

import {Ppjf} from '@app/sam-base/models/placement/ppjf';
import {
    CalendarJoursFeriesComponent
} from '../components/calendar-jours-feries-config/calendar-jours-feries-config.component';

export const PPJF_CONFIG_DEFAULT_FORM: FormDescription<Ppjf> = {
    form: CalendarJoursFeriesComponent, state: {
        ...buildDefaultState(Ppjf), diagModal: true, diagTitle: 'calendrier_jours_feries', width: 700, isNew: true
    }
};
