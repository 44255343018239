<iw-dialog #dialog
           (beforeHide)="close()"
           [bodyPadding]="'10'"
           [closable]="true"
           [cls]="'iw-modal'"
           [draggable]="true"
           [height]="height"
           [modal]="true"
           [resizable]="false"
           [showHeader]="true"
           [title]="title | translate"
           [visible]="display"
           [width]="width">
    <div #form
         align="center"
         style="width: 100%; height: 100%;">
        <iw-busy [ajustLeft]="0"
                 [ajustTop]="0"
                 [isBusy]="loading"></iw-busy>
        <div class="row">

            <iw-textfield [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'tenant' | translate"
                          [value]="tenant"
                          class="iw-input col-xs-3"></iw-textfield>
            <iw-dropdown
                    [(value)]="subTenant"
                    [labelAlign]="'top'"
                    [label]="'subTenant' | translate"
                    [options]="agencies"
                    class="iw-input col-xs-2"></iw-dropdown>
            <iw-dropdown
                    [(value)]="selectedView"
                    [labelAlign]="'top'"
                    [label]="'views' | translate"
                    [options]="views"
                    class="iw-input col-xs-2"></iw-dropdown>
            <iw-dropdown
                    [(value)]="action"
                    [labelAlign]="'top'"
                    [label]="'actions' | translate"
                    [options]="actions"
                    class="iw-input col-xs-2"></iw-dropdown>

            <iw-button (press)="executeAction()"
                       [text]='"execute"'
                       class="iw-input col-xs-1 margin-top-25"></iw-button>
            <iw-button (press)="indexSingleView()"
                       [iconClass]="'rotate'"
                       [text]='"index"'
                       class="iw-input col-xs-1 margin-top-25"></iw-button>
            <iw-button (press)="indexAll()"
                       [iconClass]="'rotate'"
                       [text]='"indexAll"'
                       class="iw-input col-xs-1 margin-top-25"></iw-button>
            <iw-button (press)="migrateProfilePicture()"
                       [iconClass]="'rotate'"
                       [text]='"Migrate Profile Picture"'
                       class="iw-input col-xs-2 margin-top-25"></iw-button>
            <iw-button (press)="dataMigrationToUltimate()"
                       [iconClass]="'rotate'"
                       [text]='"Migrate Data for Ultimate"'
                       class="iw-input col-xs-2 margin-top-25"></iw-button>
        </div>
        <div class="row">
            <iw-button (press)="onClickClear()"
                       [text]="'clear'"
                       class="iw-input col-xs-2"
                       iconClass="ban"
                       id="cancel-create"></iw-button>
        </div>
        <div class="row">
            <p-tabView class="col-xs-12">
                <p-tabPanel header="Server Log">
                    <div class="row">
                        <iw-textarea #dtaInfo
                                     [height]="500"
                                     [isDisabled]="true"
                                     [value]="logsAsString"
                                     class="iw-textarea col-xs-12"></iw-textarea>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Instances">
                    <div class="row">
                        <iw-button (press)="getInstances()"
                                   [text]="'Get Instances'"
                                   class="iw-input col-xs-2"
                                   iconClass="ban"></iw-button>
                    </div>
                    <div class="row">
                        <p-tree [value]="jsonTree"
                                class="col-xs-12"></p-tree>
                    </div>

                </p-tabPanel>
                <p-tabPanel header="Other">
                    <div class="row">
                        <p class="col-xs-12">
                            At vero eos et accusamus et iusto odio dignissimos...
                        </p>
                    </div>
                </p-tabPanel>
            </p-tabView>

        </div>
    </div>
</iw-dialog>
