<form [formGroup]="formGroup" class="row">

    <div class="form-host row col-xs-6 separator-right no-padding-left">
        <iw-enum-dropdown #cboTypejob [inputEnum]="typeDemploi" [isDisabled]="isReadonly" [labelAlign]="'left'"
                          [label]="'typeDemploi' | translate" class="iw-input col-xs-12 col-no-padding"
                          formControlName="typejob"></iw-enum-dropdown>

        <iw-textfield-lookups #txtpostdesire [isDisabled]="isReadonly" [labelAlign]="'left'"
                              [label]="'postedesire' | translate" [lookupName]="'POSTEDESIRE'"
                              class="iw-input col-xs-12 col-no-padding"
                              formControlName="postdesire"></iw-textfield-lookups>

        <iw-textfield #txtsaldesire [isDisabled]="isReadonly" [labelAlign]="'left'"
                      [label]="'salaireDesire' | translate" class="iw-input col-xs-12 col-no-padding"
                      formControlName="saldesire"></iw-textfield>

        <iw-textfield #txtCongdesire [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'congeDesire' | translate"
                      class="iw-input col-xs-12 col-no-padding" formControlName="congdesire"></iw-textfield>
    </div>

    <div class="form-host row col-xs-6 no-padding-right">
        <iw-checkbox #Standardlogic4 [isDisabled]="isReadonly" [label]="'clauseDeNonConcurrence' | translate"
                     class="iw-input col-xs-12 col-no-padding" formControlName="concuc"></iw-checkbox>

        <iw-textfield #txtConcuctxt [isDisabled]="checkClause()" class="iw-input col-xs-12 col-no-padding"
                      formControlName="concuctxt"></iw-textfield>

    </div>

    <div class="col-xs-12 separator-top no-padding"></div>

    <iw-textarea #edtPreavis [height]="60" [isDisabled]="isReadonly" [labelAlign]="'top'"
                 [label]="'preavis' | translate" class="iw-textarea col-xs-12" formControlName="preavis"></iw-textarea>

</form>
