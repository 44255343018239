import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Saconj} from '@sam-base/models';
import {Observable} from 'rxjs';

import {RestClient} from '../rest-api/core/rest-client';

@Injectable()
export class SaconjService extends RestClient<Saconj> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getSaconjByEmpId(empId: string): Observable<Saconj> {
        return this.GET<Saconj>(undefined, `saconj/emp/${empId}`);
    }

    public save(saconj: Saconj): Observable<Saconj> {
        return this.POST<Saconj>(saconj, undefined, `saconj`);
    }

    public update(conjId: string, saemp: Saconj): Observable<Saconj> {
        return this.PUT<Saconj>(saemp, undefined, `saconj/${conjId}`);
    }

    public delete(conjId: string): Observable<string> {
        return this.DELETE<string>(undefined, `saconj/${conjId}`);
    }
}
