<form [formGroup]="formGroup" class="row">
    <iw-textarea #edtNote [height]="110" [isDisabled]="true" class="iw-textarea col-xs-12"
                 formControlName="note"></iw-textarea>


    <iw-textfield #txtIJRap_id [isDisabled]="true" [labelAlign]="'left'" [label]="'ijRapId' | translate"
                  class="iw-input col-xs-5 padding-top-10 padding-bottom-10" formControlName="ijrapId"></iw-textfield>


    <iw-textarea #edtIj_note [height]="121" [isDisabled]="true" class="iw-textarea col-xs-12 "
                 formControlName="ijNote"></iw-textarea>
</form>
