import {SamUserRole} from '@sam-base/models/admin/sam-user';

/**
 * Defines the roles that have access to the relevedheures
 */
export function saisirRapportheures(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN];
}

export function menuGestionPLacement(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN];
}

/**
 * Defines the roles that have access to the acomptes
 */
export function acomptesRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_ACOMPTE_ADMIN];

}

/**
 * Defines the roles that have access to the indemnites
 */
export function indemnitesRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN];
}

/**
 * Defines the roles that have access to the salaryPreparation
 */
export function salaryPreparationRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_SALAIRE_RW,
        SamUserRole.SAM_SALAIRE_ADMIN,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

export function invoicesMgtMenu(): SamUserRole[] {
    return [
        SamUserRole.SAM_FACTURE_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_FACTURE_COM,
        SamUserRole.SAM_FACTURE_PRINT];
}

/**
 * Defines the roles that have access to the invoicesPreparation
 */
export function invoicesPreparationRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_FACTURE_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN];
}

/**
 * Defines the roles that have access to the invoicesPreparation
 */
export function Rappels(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN,
    ];
}


/**
 * Defines the roles that have access to the attestationGainIntermediaire
 */
export function menuAttestationsPlacement(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_ATTESTATIONS];

}

/**
 * Defines the roles that have access to the attestationGainIntermediaire
 */
export function attestationGainIntermediaireRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_ATTESTATIONS];

}

/**
 * Defines the roles that have access to the attestationsLemployeur
 */
export function attestationsLemployeurRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_ATTESTATIONS];
}

/**
 * Defines the roles that have access to the attestationsLemployeur
 */
export function certificatTravail(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_ATTESTATIONS];
}

/**
 * Defines the roles that have access to the Rapports
 */
export function rapportsSaisisRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

/**
 * Defines the roles that have access to the Rapports Manquants
 */
export function rapportsManquantsRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];

}
