<iw-modal-form #modal
               [(loading)]="loading"
               [btnCancel]="btnCancel"
               [btnOk]="btnOk"
               [height]="650"
               [leftBusy]="5"
               [title]="'recapissd4'"
               [topBusy]="5"
               [width]="1650">
    <div class="row">
        <iw-table #table
                  [(selected)]="selectedElement"
                  [columns]="tableColumns"
                  [data]="tableData"
                  [isFilterEnable]="false"
                  [isGridColumnMenuEnable]="false"
                  [isSortIconEnable]="false"
                  class="iw-input col-xs-12"
                  style="height: 550px; width: 800px;"></iw-table>
        <div class="col-xs-2 col-xs-offset-10">
            <iw-button (press)="registerCancel()"
                       [text]="'fermer' | translate"
                       class="col-xs-12"
                       id="fermer"></iw-button>
        </div>
    </div>
</iw-modal-form>
