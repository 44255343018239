import {Component, OnInit} from '@angular/core';
import {BaseFormComponent} from '@sam-base/base';
import {EntityNavigationService, IwStoreService} from '@sam-base/core';
import {SacomptaService} from '@sam-base/core/services/sacompta.service';
import * as actions from '@sam-base/core/store/actions/global-form.actions';
import {SetLoading} from '@sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@sam-base/core/toast';
import {FormKeys, IwGridColumn, RowClickEvent} from '@sam-base/models';
import {Saempview} from '@sam-base/models/salary';
import {JcptaErr, Sacompta} from '@sam-base/models/salary/sacompta';
import _ from 'lodash';

@Component({
    selector: 'iw-salary-compta',
    templateUrl: './salary-compta.component.html'
})
export class SalaryComptaComponent extends BaseFormComponent<Sacompta> implements OnInit {

    public errors: JcptaErr[] = [];
    public columnsError: IwGridColumn<JcptaErr>[] = [
        {
            name: 'id',
            prop: 'empId',
            index: 0
        },
        {
            name: 'message',
            prop: 'errText',
            index: 1
        }
    ];
    private sacompta?: Sacompta;

    constructor(private readonly _sacomptaService: SacomptaService,
                private readonly _toastService: ToastService,
                private _navigationService: EntityNavigationService,
                private readonly _store: IwStoreService) {
        super();
    }

    ngOnInit(): void {
        const salId = this.getData<string>("salId", true)!;
        this._sacomptaService.init(salId)
            .subscribe(sacompta => {
                this.sacompta = sacompta;
                this.fillFormData(sacompta);
            });
    }

    public comptabiliser() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        const sacompta = this.getFormData()!;
        sacompta.nbEcrSal = undefined;
        sacompta.nbEcrPay = undefined;
        sacompta.nbErreurs = undefined;
        this._sacomptaService.comptabiliser(sacompta)
            .subscribe({
                next: (sacompta) => {
                    this.sacompta = sacompta;
                    this.errors = sacompta.jcptaResult?.jcptaErr || [];
                    this.fillFormData(sacompta);
                    this._store.dispatch(new SetLoading(this.uuid, false));
                    if (this.errors.length > 0) {
                        this._toastService.warning('toast.facture_compta.warning.comptabiliser');
                    } else {
                        this._toastService.success('toast.facture_compta.success.comptabiliser');
                    }
                },
                error: err => {
                    this._toastService.error('toast.facture_compta.error.comptabiliser');
                    this._store.dispatch(new SetLoading(this.uuid, false));
                }
            });
    }

    public decomptabiliser() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        const sacompta = _.assign({}, this.sacompta, this.getFormData()!);
        // sacompta.prepareResult = undefined;
        this._sacomptaService.decomptabiliser(sacompta)
            .subscribe({
                next: sacompta => {
                    this._store.dispatch(new SetLoading(this.uuid, false));
                    this.sacompta = sacompta;
                    this.fillFormData(sacompta);
                    this.errors = sacompta.jcptaResult?.jcptaErr || [];
                    if (this.errors.length > 0) {
                        this._toastService.warning('toast.facture_compta.warning.decomptabiliser');
                    } else {
                        this._toastService.success('toast.facture_compta.success.decomptabiliser');
                    }
                },
                error: err => {
                    this._toastService.error('toast.facture_compta.error.decomptabiliser');
                    this._store.dispatch(new SetLoading(this.uuid, false));
                }
            });
    }

    closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    openErrorEntity(event: RowClickEvent<JcptaErr>) {
        const entityId = event.row.empId;
        if (!entityId) return;
        this._navigationService.navigateToEntityForm(Saempview, entityId, undefined, 'read');
    }

    protected getFormControlNames(): FormKeys<Sacompta> {
        return [
            'salId',
            'cptagrpemp',
            'cptagrpgs',
            'cptaan',
            'cptaanobli',
            'cptatxfcoe',
            'cptatxfpay',
            'cptatxfchq',
            'nbEcrSal',
            'nbEcrPay',
            'nbErreurs',
            'compteNap'
        ];
    }

    protected getValidationType() {
        return Sacompta;
    }

}
