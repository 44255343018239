<form [formGroup]="formGroup" class="row">

    <iw-textarea #edtRemarques [height]="340" [isDisabled]="isReadonly" class="iw-textarea col-xs-12"
                 formControlName="remarques" style="padding: 10px"></iw-textarea>

    <iw-textarea #edtcli_data [height]="32" [isDisabled]="isReadonly" class="iw-textarea col-xs-5"
                 formControlName="cliData" style="padding: 10px"></iw-textarea>

    <div class="col-xs-2"></div>

    <iw-textarea #edtXml_data [height]="32" [isDisabled]="isReadonly" class="iw-textarea col-xs-5"
                 formControlName="xmlData" style="padding: 10px"></iw-textarea>

</form>