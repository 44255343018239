<form [formGroup]="formGroup"
      class="row">
    <iw-table #missions
              (rowDoubleClick)="openMission($event)"
              [columns]="columns"
              [data]="linkedMissions"
              [defaultSelected]="true"
              [isDisabled]="false"
              [isFilterEnable]="false"
              [isGridColumnMenuEnable]="false"
              [isSortIconEnable]="false"
              [labelAlign]="'top'"
              class="iw-grid col-xs-12"
              style="height: 200px"></iw-table>

    <iw-table #rapports
              (rowDoubleClick)="openRapport($event)"
              [columns]="columnsrap"
              [data]="linkedRapports"
              [defaultSelected]="true"
              [isDisabled]="false"
              [isFilterEnable]="false"
              [isGridColumnMenuEnable]="false"
              [isSortIconEnable]="false"
              class="iw-grid col-xs-12"
              style="height: 200px"></iw-table>

    <iw-table #indemnities
              (rowDoubleClick)="openIndemnity($event)"
              [columns]="columnsind"
              [data]="linkedIndemnities"
              [defaultSelected]="true"
              [isDisabled]="false"
              [isFilterEnable]="false"
              [isGridColumnMenuEnable]="false"
              [isSortIconEnable]="false"
              class="iw-grid col-xs-12"
              style="height: 200px"></iw-table>
</form>
