import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {getFormState, IwEventHubService, IwStoreService} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {AvsNumberService} from '@app/sam-base/core/services/avs-number.service';
import {SaconjService} from '@app/sam-base/core/services/saconj.service';
import * as globalFormActions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormState} from '@app/sam-base/core/store/reducers/form.reducer';
import {ToastService} from '@app/sam-base/core/toast';
import {FormKeys, Pays, Saconj} from '@app/sam-base/models';

import {
    noAvs13AsyncValidator
} from '@modules/sam-main/salary/components/employee/tab-saemp-donnees-base/tab-saemp-donnees-base.component';
import {select} from '@ngrx/store';
import {of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {EmploymentType, RevenueType} from './saconj-enums';

@Component({
    templateUrl: './saconj.component.html'
})
export class SaconjComponent extends BaseStoreFormComponent<Saconj> implements OnInit, AfterViewInit, OnDestroy {
    public hasConj = false;
    public canModify = false;
    public formState: FormState | undefined;
    public saconj: Saconj | undefined;
    public readonly = true;
    public employmenttype = EmploymentType;
    public revenuetype = RevenueType;
    private subscriptions = new Subject();

    constructor(store: IwStoreService, private readonly saconjService: SaconjService,
                private readonly _events: IwEventHubService<string>, private readonly _toastService: ToastService,
                private readonly _avsService: AvsNumberService) {
        super(store);
    }

    public get empId(): string {
        return this.getData('empId', true) || '';
    }

    public get empNom() {
        return this.getData('empNom');
    }

    public get editDisabled() {
        return !this.canModify || !this.hasConj;
    }

    public get isNoAvs13Invalid(): boolean | undefined {
        return this.getFormControl('noavs13')?.invalid;
    }

    public get age() {
        const datenaiss = this.getFormValue('datenaiss');
        if (!datenaiss) {
            return '';
        }
        const ageInMonths = IwDateHelper.getAgeInMonthsByDateOfBirth(datenaiss);
        const ageInYears = (ageInMonths - (ageInMonths % 12)) / 12;
        return `${ageInYears ?? ''} / ${ageInMonths - ageInYears * 12}`;
    }

    public get editConjointTravDisabled() {
        return (this.hasConj && this.readonly) || !this.getFormValue('ltravail');
    }

    public get editAutreAdresseDisabled() {
        return (this.hasConj && this.readonly) || !this.getFormValue('adrother');
    }

    public get editConjointCantonDisabled() {
        return (this.readonly || !this.getFormValue('ltravail') || this.getFormValue('paystrav') !== 'CH');
    }

    public ngOnInit() {
        this.saconjService
            .getSaconjByEmpId(this.empId)
            .pipe(catchError((err) => of(err)))
            .subscribe((elem: any) => {
                if (elem['status'] === 404) {
                    this._store.dispatch(new globalFormActions.SetNewMode(this.uuid));
                    this.readonly = false;
                    this.setFormValue('nom', this.empNom);
                } else {
                    this.hasConj = !!elem;
                    if (!!elem) {
                        this.saconj = elem;
                        this.canModify = true;
                        this.formGroup.patchValue(elem);
                    }
                }
            });
        this.updateValidatorsForConjointTravail(this.getFormValue('ltravail'));
        this.getFormControl('noavs13')
            ?.setAsyncValidators(noAvs13AsyncValidator(this._avsService));
        this.initSubscriptions();
    }

    public ngAfterViewInit() {
        this._store
            .pipe(select((state) => getFormState(state, this.uuid)))
            .pipe(takeUntil(this.subscriptions))
            .subscribe((state) => {
                setTimeout(() => {
                    this.formState = state;
                }, 0);
            });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public modify(): void {
        this.canModify = false;
        this.readonly = false;
        this._store.dispatch(new globalFormActions.SetEditMode(this.uuid));
    }

    public delete(): void {
        this.saconjService
            .delete(this.getFormValue('conjId'))
            .subscribe((str: string) => {
                this._events.emit('conjoint_closed', this.empId);
                this._store.dispatch(new globalFormActions.DestroyForm(this.uuid));
            });
    }

    public save(): void {
        this._store.dispatch(new globalFormActions.SetLoading(this.uuid, true));

        const saemp: Saconj = {
            ...this.formGroup.value,
            empId: this.empId
        };

        const conjId = this.getFormValue('conjId');

        if (this.hasConj && !!conjId) {
            this.saconjService.update(conjId, saemp)
                .subscribe((saconj: Saconj) => {
                    this._events.emit('conjoint_closed', saconj);
                    this.canModify = true;
                    this.readonly = true;
                    this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
                }, (err) => {
                    this._toastService.error('saconj_error');
                    this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
                });
        } else {
            this.saconjService.save(saemp)
                .subscribe((saconj: Saconj) => {
                    this._events.emit('conjoint_closed', saconj);
                    this.canModify = true;
                    this.hasConj = true;
                    this.readonly = true;
                    this.setFormValue('conjId', saconj.conjId);
                }, () => {
                    this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
                });
        }
        this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
    }

    public changeValidators(event: Pays): void {
        if (event && event.codepays2 === 'CH') {
            this.formGroup.controls['cantontrav'].setValidators(Validators.required);
            this.formGroup.controls['cantontrav'].updateValueAndValidity();
        } else {
            this.setFormValue('cantontrav', undefined);
            this.formGroup.controls['cantontrav'].clearValidators();
            this.formGroup.controls['cantontrav'].updateValueAndValidity();
        }
    }

    protected getFormControlNames(): FormKeys<Saconj> {
        return [
            'conjId',
            'empId',
            'nom',
            'prenom',
            'adresse1',
            'adresse2',
            'npa',
            'lieu',
            'pays',
            'noavs13',
            'ltravail',
            'cantontrav',
            'paystrav',
            'travtype',
            'revenutype',
            'adrother',
            'datenaiss',
            'travdebut',
            'travfin',
            'travrevenu'];
    }

    protected getValidationType() {
        return Saconj;
    }

    protected validateFields(e: Saconj): boolean {
        return super.validateFields(e);
    }

    private initSubscriptions() {
        this.formGroup.controls['adrother'].valueChanges
            .pipe(takeUntil(this.subscriptions))
            .subscribe((val: boolean) => {
                if (!val) {
                    this.setFormValue('adresse1', undefined);
                    this.setFormValue('adresse2', undefined);
                    this.setFormValue('npa', undefined);
                    this.setFormValue('lieu', undefined);
                    this.setFormValue('pays', undefined);

                    this.formGroup.controls['adresse1'].clearValidators();
                    this.formGroup.controls['adresse1'].updateValueAndValidity();
                    this.formGroup.controls['lieu'].clearValidators();
                    this.formGroup.controls['lieu'].updateValueAndValidity();
                    this.formGroup.controls['npa'].clearValidators();
                    this.formGroup.controls['npa'].updateValueAndValidity();
                    this.formGroup.controls['pays'].clearValidators();
                    this.formGroup.controls['pays'].updateValueAndValidity();

                    return;
                }
                this.formGroup.controls['adresse1'].setValidators(Validators.required);
                this.formGroup.controls['adresse1'].updateValueAndValidity();
                this.formGroup.controls['npa'].setValidators(Validators.required);
                this.formGroup.controls['npa'].updateValueAndValidity();
                this.formGroup.controls['lieu'].setValidators(Validators.required);
                this.formGroup.controls['lieu'].updateValueAndValidity();
                this.formGroup.controls['pays'].setValidators(Validators.required);
                this.formGroup.controls['pays'].updateValueAndValidity();
            });

        this.formGroup.controls['ltravail'].valueChanges
            .pipe(takeUntil(this.subscriptions))
            .subscribe((val: boolean) => {
                this.updateValidatorsForConjointTravail(val);
            });
    }

    private updateValidatorsForConjointTravail(val: boolean) {
        if (!val) {
            this.setFormValue('travdebut', undefined);
            this.setFormValue('travfin', undefined);

            this.setFormValue('travtype', undefined);
            this.setFormValue('revenutype', undefined);

            this.setFormValue('paystrav', undefined);
            this.setFormValue('cantontrav', undefined);

            this.formGroup.controls['travdebut'].clearValidators();
            this.formGroup.controls['travdebut'].updateValueAndValidity();
            this.formGroup.controls['paystrav'].clearValidators();
            this.formGroup.controls['paystrav'].updateValueAndValidity();
            this.formGroup.controls['cantontrav'].clearValidators();
            this.formGroup.controls['cantontrav'].updateValueAndValidity();
            this.formGroup.controls['travtype'].clearValidators();
            this.formGroup.controls['travtype'].updateValueAndValidity();
            this.formGroup.controls['revenutype'].clearValidators();
            this.formGroup.controls['revenutype'].updateValueAndValidity();
            return;
        }
        this.formGroup.controls['travdebut'].setValidators(Validators.required);
        this.formGroup.controls['travdebut'].updateValueAndValidity();
        this.formGroup.controls['paystrav'].setValidators(Validators.required);
        this.formGroup.controls['paystrav'].updateValueAndValidity();
        this.formGroup.controls['travtype'].setValidators(Validators.required);
        this.formGroup.controls['travtype'].updateValueAndValidity();
        this.formGroup.controls['revenutype'].setValidators(Validators.required);
        this.formGroup.controls['revenutype'].updateValueAndValidity();
    }
}
