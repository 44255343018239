import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {
    EncaissementAutomaticSideMenuComponent
} from '@modules/sam-main/salary/components/encaissement/automatic/encaissement-automatic-side-menu.component';
import {
    EncaissementAutomaticComponent
} from '@modules/sam-main/salary/components/encaissement/automatic/encaissement-automatic.component';
import {EncaissementAutomatic} from '@sam-base/models/invoices/encaissement-automatic.model';

export const ENCAISSEMENT_AUTOMATIC_FORM: FormDescription<EncaissementAutomatic> = {
    form: EncaissementAutomaticComponent,
    sideMenu: EncaissementAutomaticSideMenuComponent,
    state: {
        ...buildDefaultState(EncaissementAutomatic),
        diagTitle: 'encaissement_automatic',
        diagShowSideMenu: true,
        diagSideMenu: {
            showCancel: false,
            showClose: true,
            showEdit: false,
            showList: false,
            showPrint: false,
            showDelete: false,
        },
        diagShowStatus: true,
        editMode: 'read',
        useNavigation: false,
        width: 1000,
        patch: false
    }

};

