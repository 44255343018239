<form [formGroup]="formGroup"
      class="row">
    <div class="row col-xs-12">
        <div class="row col-xs-9">
            <iw-checkbox [isDisabled]="!isWriteMode"
                         [label]="'combo_ncs_avancee_dialog_csmot1' | translate"
                         class="iw-input col-xs-12 no-padding-left"
                         formControlName="cpMot1"></iw-checkbox>

            <iw-textfield [isDisabled]="!isWriteMode"
                          [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_canton' | translate"
                          class="iw-input col-xs-6 padding-bottom-20"
                          formControlName="cpMot11"></iw-textfield>

            <iw-date-picker [isDisabled]="!isWriteMode"
                            [labelAlign]="'left'"
                            [label]="'combo_ncs_avancee_dialog_date' | translate"
                            class="col-xs-6 padding-bottom-20"
                            formControlName="cpMot12"></iw-date-picker>

            <iw-checkbox [isDisabled]="!isWriteMode"
                         [label]="'combo_ncs_avancee_dialog_csmot2' | translate"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-20"
                         formControlName="cpMot2"></iw-checkbox>

            <iw-checkbox [isDisabled]="!isWriteMode"
                         [label]="'combo_ncs_avancee_dialog_csmot3' | translate"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"
                         formControlName="cpMot3"></iw-checkbox>

            <iw-textfield [isDisabled]="!isWriteMode"
                          [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_canton' | translate"
                          class="iw-input col-xs-6 padding-bottom-20"
                          formControlName="cpMot31"></iw-textfield>

            <iw-date-picker [isDisabled]="!isWriteMode"
                            [labelAlign]="'left'"
                            [label]="'combo_ncs_avancee_dialog_date' | translate"
                            class="col-xs-6 padding-bottom-20"
                            formControlName="cpMot32"></iw-date-picker>

            <iw-checkbox [isDisabled]="!isWriteMode"
                         [label]="'combo_ncs_avancee_dialog_csmot4' | translate"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"
                         formControlName="cpMot4"></iw-checkbox>

            <iw-textfield [isDisabled]="!isWriteMode"
                          [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_canton' | translate"
                          class="iw-input col-xs-6 padding-bottom-20"
                          formControlName="cpMot41"></iw-textfield>

            <iw-date-picker [isDisabled]="!isWriteMode"
                            [labelAlign]="'left'"
                            [label]="'combo_ncs_avancee_dialog_date' | translate"
                            class="col-xs-6 padding-bottom-20"
                            formControlName="cpMot42"></iw-date-picker>

            <iw-checkbox [isDisabled]="!isWriteMode"
                         [label]="'combo_ncs_avancee_dialog_csmot5' | translate"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"
                         formControlName="cpMot5"></iw-checkbox>

            <iw-enum-dropdown #cboFormeJuri
                              [inputEnum]="cpMotOptions"
                              [isDisabled]="!isWriteMode"
                              [label]="'' | translate"
                              class="col-xs-12 no-padding-left padding-bottom-5"
                              formControlName="cpMot51"></iw-enum-dropdown>

            <iw-enum-dropdown #cboFormeJuri
                              [inputEnum]="cpMotOptions"
                              [isDisabled]="!isWriteMode"
                              [label]="'' | translate"
                              class="col-xs-12 no-padding-left padding-bottom-5"
                              formControlName="cpMot52"></iw-enum-dropdown>
            <iw-enum-dropdown [inputEnum]="cpMotOptions"
                              [isDisabled]="!isWriteMode"
                              [label]="'' | translate"
                              class="col-xs-12 no-padding-left padding-bottom-5"
                              formControlName="cpMot53"></iw-enum-dropdown>

            <iw-checkbox [isDisabled]="!isWriteMode"
                         [label]="'combo_ncs_avancee_dialog_csmot6' | translate"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"
                         formControlName="cpMot6"></iw-checkbox>

            <iw-textfield [isDisabled]="!isWriteMode"
                          [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_csmot61' | translate"
                          class="iw-input col-xs-9 padding padding-bottom-20"
                          formControlName="cpMot61"></iw-textfield>

            <iw-checkbox [isDisabled]="!isWriteMode"
                         [label]="'combo_ncs_avancee_dialog_csmot7' | translate"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"
                         formControlName="cpMot7"></iw-checkbox>

            <iw-textfield [isDisabled]="!isWriteMode"
                          [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_montant' | translate"
                          class="iw-input col-xs-9 padding-bottom-20"
                          formControlName="cpMot71"></iw-textfield>

            <iw-checkbox [isDisabled]="!isWriteMode"
                         [label]="'combo_ncs_avancee_dialog_csmot8' | translate"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"
                         formControlName="cpMot8"></iw-checkbox>

            <iw-textfield [isDisabled]="!isWriteMode"
                          [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_montant' | translate"
                          class="iw-input col-xs-9 padding-bottom-20"
                          formControlName="cpMot81"></iw-textfield>

            <iw-checkbox [isDisabled]="!isWriteMode"
                         [label]="'combo_ncs_avancee_dialog_csmot9' | translate"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-20"
                         formControlName="cpMot9"></iw-checkbox>

            <iw-checkbox [isDisabled]="!isWriteMode"
                         [label]="'combo_ncs_avancee_dialog_csmot10' | translate"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-20"
                         formControlName="cpMot10"></iw-checkbox>
        </div>
        <div class="row col-xs-3 no-padding-left">
            <iw-textfield [isDisabled]="true"
                          class="iw-input col-xs-12 no-padding-right flex-start"
                          formControlName="cpYear"></iw-textfield>
        </div>
        <div class="row col-xs-9">
            <iw-checkbox [isDisabled]="!isWriteMode"
                         [label]="'combo_ncs_avancee_dialog_csmot110' | translate"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"
                         formControlName="cpMot110"></iw-checkbox>

            <iw-textfield [isDisabled]="!isWriteMode"
                          [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_canton' | translate"
                          class="iw-input col-xs-6 padding-bottom-20"
                          formControlName="cpMot111"></iw-textfield>

            <iw-date-picker [isDisabled]="!isWriteMode"
                            [labelAlign]="'left'"
                            [label]="'combo_ncs_avancee_dialog_date' | translate"
                            class="col-xs-6 padding-bottom-20"
                            formControlName="cpMot112"></iw-date-picker>
        </div>

        <div class="row col-xs-9">
            <iw-checkbox [isDisabled]="!isWriteMode"
                         [label]="'combo_ncs_avancee_dialog_csmot120' | translate"
                         class="iw-input col-xs-12 no-padding-left padding-bottom-5"
                         formControlName="cpMot120"></iw-checkbox>
            <iw-enum-dropdown #cboFormeJuri
                              (valueChange)="changeCs120Options($event)"
                              [inputEnum]="cpMot1201Options"
                              [isDisabled]="cpMot120Disabled()"
                              [label]="'' | translate"
                              class="col-xs-12 no-padding-left padding-bottom-5 padding-top-5"
                              formControlName="cpMot1201"></iw-enum-dropdown>
            <iw-textfield *ngIf="cpActive"
                          [isDisabled]="cpMot120Disabled()"
                          [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_percent' | translate"
                          class="iw-input col-xs-6 padding-bottom-20"
                          formControlName="cpMot1201Rate"></iw-textfield>
            <iw-textfield *ngIf="cpActive3"
                          [isDisabled]="cpMot120Disabled()"
                          [labelAlign]="'left'"
                          [label]="'combo_ncs_avancee_dialog_canton' | translate"
                          class="iw-input col-xs-6 padding-bottom-20"
                          formControlName="cpMot1201CState"></iw-textfield>
        </div>
    </div>
</form>
