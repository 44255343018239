import {Component, Input, OnInit, Output, Type} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core';
import {ComboboxItem} from '@app/sam-base/models/components/combobox-item.model';
import {Ppagi, Ppagidet} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'iw-tab-810-aginter', templateUrl: './tab-810-aginter.component.html'
})
export class Tab810AginterComponent extends BasePartialFormComponent<Ppagi> implements OnInit {
    @Input() public isReadonly = true;

    @Output() public salaryAVS = false;

    public type: Type<Ppagidet> = Ppagidet;

    public salHorModeOptions: ComboboxItem<number>[] = [];

    public query: RestQueryParam<Ppagidet, string>[] = [];

    constructor(private _translate: TranslateService) {
        super();
        this.salHorModeOptions = [{
            name: this._translate.instant('Global'), value: 1
        }, {name: this._translate.instant('Global-Provisions'), value: 2}, {
            name: this._translate.instant('Base'), value: 3
        }];
    }

    public ngOnInit() {
        this.setQueryStart();
    }

    public setSalaryAVS(event: boolean) {
        this.salaryAVS = event;
    }

    private subscribeAgiId() {
        this.subscribeValueChange('agiId', (id?: string) => {
            if (!id) {
                return;
            }
            this.setQuery(id);
        });
    }

    private setQueryStart() {
        this.subscribeAgiId();
        const id: string | undefined = this.getFormValue('agiId');
        if (!id) {
            return;
        }
        this.setQuery(id);
    }

    private setQuery(agiId: string) {
        this.query = [{
            operation: RestQueryOperation.Equals, prop: 'agiId', value: agiId
        }];
    }
}
