import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Pprap} from '@app/sam-base/models/placement';

import {RapportSidemenuComponent} from '../components/rapport/rapport-sidemenu.component';
import {RapportComponent} from '../components/rapport/rapport.component';

export const PPRAP_DEFAULT_FORM: FormDescription<Pprap> = {
    form: RapportComponent, sideMenu: RapportSidemenuComponent, state: {
        ...buildDefaultState(Pprap),
        diagTitle: 'weekly_report',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showList: true
        },
        useNavigation: true,
        patch: false,
        width: 1000
    }
};
