import * as AdminRoles from '@app/sam-base/core/auth/access-rules/administration-menu';
import * as ConfigRoles from '@app/sam-base/core/auth/access-rules/config-menu';
import * as StatisticsRoles from '@app/sam-base/core/auth/access-rules/statistics-menu';
import {
    LayoutAdminNavigationEvent,
    LayoutEventNone,
    LayoutEvents,
    LayoutPlacementNavigationEvent,
    LayoutSalaryNavigationEvent,
    LayoutSubNavigationEvent
} from '@app/sam-base/layout/events/events.models';
import {MenuEntry, MenuItem} from '@app/sam-base/models';
import * as SideMenuRoles from '@sam-base/core/auth/access-rules/side-menu';

export const MENU_PLACEMENT: MenuItem[][] = [
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.gestion_des_rapports',
            accessRoles: AdminRoles.menuGestionPLacement(),
            items: [
                {
                    label: 'releves_heures',
                    event: LayoutSubNavigationEvent.RAPPORTS,
                    accessRoles: AdminRoles.saisirRapportheures()
                },
                {
                    label: 'rapports_saisis',
                    event: LayoutSubNavigationEvent.RAPPORTS_SAISIS,
                    accessRoles: AdminRoles.rapportsSaisisRoles()
                },
                {
                    label: 'rapports_manquants',
                    event: LayoutSubNavigationEvent.RAPPORTS_MANQUANTS,
                    accessRoles: AdminRoles.rapportsManquantsRoles()
                },
                {
                    label: 'weekly_timesheets',
                    accessRoles: StatisticsRoles.rapportsVierges(),
                    event: LayoutSubNavigationEvent.WEEKLY_TIMESHEETS_DECOMPTE
                }]
        },
        {
            event: LayoutEventNone,
            label: 'menu.subheader.commandes',
            accessRoles: SideMenuRoles.commandesRoles(),
            items: [
                {
                    label: 'layout_menu_label_order',
                    event: LayoutPlacementNavigationEvent.ORDERS,
                    accessRoles: SideMenuRoles.commandesRoles(),
                },
                {
                    label: 'layout_menu_label_order_fixe',
                    event: LayoutPlacementNavigationEvent.ORDERS_FIXE,
                    accessRoles: SideMenuRoles.commandesRoles(),
                }]
        },
        {
            event: LayoutEventNone,
            label: 'menu.subheader.placements',
            accessRoles: SideMenuRoles.placementfixeRoles(),
            items: [
                {
                    label: 'layout_menu_label_placement',
                    event: LayoutPlacementNavigationEvent.PLACEMENT,
                    accessRoles: SideMenuRoles.placementfixeRoles(),
                }]
        },
        {
            event: LayoutEventNone,
            label: 'menu.subheader.attestations',
            accessRoles: AdminRoles.menuAttestationsPlacement(),
            items: [
                {
                    label: 'attestations_intermediaire',
                    event: LayoutSubNavigationEvent.AGINTER,
                    accessRoles: AdminRoles.attestationGainIntermediaireRoles()
                },
                {
                    label: 'attestations_employer',
                    event: LayoutSubNavigationEvent.ATTESTATION_EMPLOYER,
                    accessRoles: AdminRoles.attestationsLemployeurRoles()
                },
                {
                    label: 'certificat_travail',
                    accessRoles: AdminRoles.certificatTravail(),
                    event: LayoutSubNavigationEvent.CERT_TRAVAIL
                }],
        }
    ],
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.extraction',
            accessRoles: StatisticsRoles.extractionPlacementMenu(),
            items: [
                {
                    label: 'decompteLPP',
                    event: LayoutSubNavigationEvent.DECLPP,
                    accessRoles: StatisticsRoles.decompteLPP()
                },
                {
                    label: 'heures_allocations_familiales',
                    event: LayoutSubNavigationEvent.HEURES_ALLOCATIONS_FAMILIALES,
                    accessRoles: StatisticsRoles.allocationsFamiliales()
                },
                {
                    event: '',
                    title: '',
                    separator: true,
                    accessRoles: StatisticsRoles.debutFinMissions(),
                },
                {
                    label: 'debuts_mission',
                    accessRoles: StatisticsRoles.debutFinMissions(),
                    event: LayoutSubNavigationEvent.DEBUTS
                },
                {
                    label: 'active_missions_decompte',
                    accessRoles: StatisticsRoles.missionsActives(),
                    event: LayoutSubNavigationEvent.ACTIVE_MISSIONS
                },
                {
                    label: 'historique_mission',
                    accessRoles: StatisticsRoles.historiqueMissions(),
                    event: LayoutSubNavigationEvent.MISSION_HISTORY
                },
                {
                    label: 'recapmis',
                    accessRoles: StatisticsRoles.recapMissions(),
                    event: LayoutSubNavigationEvent.RECAP_MIS
                },
                {
                    label: 'suva',
                    accessRoles: StatisticsRoles.suva(),
                    event: LayoutSubNavigationEvent.SUVA
                },
                {
                    label: 'contrats_non_retournes',
                    accessRoles: StatisticsRoles.contratNonRetournes(),
                    event: LayoutSubNavigationEvent.CONTRACTS
                },
                {
                    event: '',
                    title: '',
                    separator: true,
                    accessRoles: StatisticsRoles.decompteLPP(),
                },
                {
                    label: 'mensuel_swissstaffing',
                    accessRoles: StatisticsRoles.decompteLPP(),
                    event: LayoutSubNavigationEvent.SWISSSTAFFING
                },
                {
                    label: 'heures_produites',
                    accessRoles: StatisticsRoles.heuresProduites(),
                    event: LayoutSubNavigationEvent.HEURES_PRODUITES
                },
                {
                    label: 'rapport_location_service',
                    accessRoles: StatisticsRoles.rapportAnnuelmissions(),
                    event: LayoutSubNavigationEvent.RAPPORT_LOCATION_SERVICE
                },
                {
                    label: 'plac_fixe_rap_annuel',
                    accessRoles: StatisticsRoles.rapportAnnuelPLacementsFixes(),
                    event: LayoutSubNavigationEvent.RAPPORT_ANNUEL
                },
                {
                    event: '',
                    title: '',
                    separator: true,
                    accessRoles: StatisticsRoles.acomptes(),
                },
                {
                    label: 'acolist',
                    accessRoles: StatisticsRoles.acomptes(),
                    event: LayoutSubNavigationEvent.ACOLIST
                },
                {
                    event: '',
                    title: '',
                    separator: true,
                    accessRoles: StatisticsRoles.documentsEmployesNonRecus(),
                },
                {
                    label: 'emp_doc_non_recus',
                    accessRoles: StatisticsRoles.documentsEmployesNonRecus(),
                    event: LayoutSubNavigationEvent.EMP_DOCS
                },
                {
                    label: 'abscences',
                    accessRoles: StatisticsRoles.listeAbsences(),
                    event: LayoutSubNavigationEvent.ABS
                },
                {
                    label: 'timesheet',
                    accessRoles: StatisticsRoles.heuresProduites(),
                    event: LayoutSubNavigationEvent.TIMESHEET
                },
                {
                    label: 'echeance_permis',
                    accessRoles: StatisticsRoles.echeancesermis(),
                    event: LayoutSubNavigationEvent.ECHEANCE_PERMIS
                }
            ]
        },
    ],
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.pilotage',
            accessRoles: StatisticsRoles.pilotagePlacementMenu(),
            items: [
                {
                    label: 'heures_produites_global',
                    accessRoles: StatisticsRoles.statsheuresProduites(),
                    event: LayoutSubNavigationEvent.KIBANA
                },
                {
                    label: 'followup_manager',
                    accessRoles: StatisticsRoles.followUpManager(),
                    expanded: false,
                    event: LayoutSubNavigationEvent.KIBANA_FOLLOWUP_MANAGER,
                },
                {
                    label: 'margin_profit',
                    accessRoles: StatisticsRoles.statsMargesRevenus(),
                    event: LayoutSubNavigationEvent.KIBANA_MARGIN_PROFIT
                },
                {
                    label: 'decompte_client_resume_par_compte',
                    accessRoles: StatisticsRoles.extractionDecompteResumeParcompte(),
                    event: LayoutSubNavigationEvent.MARGIN,
                }
                // ,
                // {
                //     label: 'resume',
                //     accessRoles: StatisticsRoles.statsresume(),
                //     event: LayoutSubNavigationEvent.KIBANA_RESUME
                // }
                ]
        }]
    ,
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.admin',
            accessRoles: ConfigRoles.periodActivePlacementRoles(),
            items: [
                {
                    id: 'period_active',
                    label: 'period_active',
                    accessRoles: ConfigRoles.periodActivePlacementRoles(),
                    event: LayoutSubNavigationEvent.PERIODE_ACTIVE
                }],
        },
    ]
]

export const MENU_FACTURE: MenuItem[][] = [
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.gestion_facture',
            accessRoles: AdminRoles.invoicesMgtMenu(),
            items: [
                {
                    label: 'preparer_factures',
                    event: LayoutSubNavigationEvent.INVOICES_PREPARATION,
                    accessRoles: AdminRoles.invoicesPreparationRoles()
                },
                {
                    label: 'enum.operation.type.PUBLISH_INVOICE',
                    event: LayoutSubNavigationEvent.INVOICES_PUBLICATION,
                    accessRoles: AdminRoles.invoicesMgtMenu()
                },
                {
                    label: 'enum.operation.type.EMAIL_INVOICE',
                    event: LayoutSubNavigationEvent.INVOICES_EMAIL,
                    accessRoles: ConfigRoles.factureCommunicationRoles()
                },
                {
                    label: 'rappels_factures',
                    event: LayoutSubNavigationEvent.RAPPELS_FACTURES,
                    accessRoles: AdminRoles.Rappels()
                }
            ],
        }
    ],
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.extraction',
            accessRoles: StatisticsRoles.extractionFactureMenu(),
            items: [
                {
                    label: 'Chiffre_Affaire_client',
                    accessRoles: StatisticsRoles.extractionsCAClient(),
                    event: LayoutSubNavigationEvent.CACLIENT
                },
                {
                    label: 'sitdebiteur',
                    accessRoles: StatisticsRoles.extractionsituationDebiteur(),
                    event: LayoutSubNavigationEvent.SITDEBITEUR
                },
                {
                    label: 'baldebiteur',
                    accessRoles: StatisticsRoles.extractionsituationDebiteur(),
                    event: LayoutSubNavigationEvent.BALDEBITEUR
                },
                {
                    label: 'relevescomptesdebiteurs',
                    accessRoles: StatisticsRoles.extractionRelevecomoteDebiteurs(),
                    event: LayoutSubNavigationEvent.RELEVE_COMPTES_DEBITEURS
                },
                {
                    label: 'new_client_decompte',
                    accessRoles: StatisticsRoles.extractionNouveauxCLients(),
                    event: LayoutSubNavigationEvent.NEW_CLIENT_DECOMPTE
                }
            ]
        }

    ],
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.pilotage',
            accessRoles: StatisticsRoles.pilotageFacturesMenu(),
            items: [
                {
                    label: 'etat_recouvrement',
                    event: LayoutSubNavigationEvent.KIBANA_RECOUVREMENT,
                    accessRoles: StatisticsRoles.statEtatRecouvrement()
                }],
        }
    ],
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.admin',
            accessRoles: ConfigRoles.periodActivefactureRoles(),
            items: [
                {
                    label: 'period_active',
                    event: LayoutSubNavigationEvent.GEPER,
                    accessRoles: ConfigRoles.periodActivefactureRoles()
                },
                {
                    label: 'menu.subheader.booking_files',
                    event: LayoutSubNavigationEvent.BOOKING_FILES_INVOICE,
                    accessRoles: ConfigRoles.periodFActureAccountingRoles()
                }
            ],
        }
    ]];
export const MENU_SALAIRE: MenuItem[][] = [
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.preparation',
            accessRoles: AdminRoles.salaryPreparationRoles(),
            items: [
                {
                    label: 'preparer_salaires',
                    event: LayoutSubNavigationEvent.SALARY_PREPARATION,
                    accessRoles: AdminRoles.salaryPreparationRoles()
                }
            ],
        }
    ],
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.reporting',
            accessRoles: StatisticsRoles.extractionSalairesMenu(),
            items: [
                {
                    label: 'listedecomptesmensuel',
                    event: LayoutSubNavigationEvent.JOURNAUX,
                    accessRoles: StatisticsRoles.extractionjournauxmensuels()
                },
                {
                    event: '',
                    title: '',
                    separator: true,
                    accessRoles: StatisticsRoles.extractionjdecomptesAnnuels()
                },
                {
                    label: 'decomptegs',
                    event: LayoutSubNavigationEvent.DECOMPTEGS,
                    accessRoles: StatisticsRoles.extractionjdecomptesAnnuels()
                },
                {
                    label: 'decomptecct',
                    event: LayoutSubNavigationEvent.DECOMPTECCT,
                    accessRoles: StatisticsRoles.extractionjdecomptesAnnuels()
                },
                {
                    label: 'salarydefinition',
                    event: LayoutSubNavigationEvent.SALARYDEF,
                    accessRoles: StatisticsRoles.extractionjdecomptesAnnuels()
                },
                {
                    label: 'listedossieremployes',
                    event: LayoutSubNavigationEvent.LISTEDOSSIEREMPLOYES,
                    accessRoles: StatisticsRoles.extractionjdecomptesAnnuels()
                },
                {
                    label: 'recapgsannuel',
                    event: LayoutSubNavigationEvent.GSRECAPANNUEL,
                    accessRoles: StatisticsRoles.extractionjdecomptesAnnuels()
                },
                {
                    label: 'recapaf',
                    event: LayoutSubNavigationEvent.AFRECAPANNUEL,
                    accessRoles: StatisticsRoles.extractionjdecomptesAnnuels()
                },
                {
                    label: 'paiements',
                    event: LayoutSubNavigationEvent.PAIEMENTS,
                    accessRoles: StatisticsRoles.extractionjdecomptesAnnuels()
                },
                {
                    label: 'decomptepermis',
                    event: LayoutSubNavigationEvent.PERMIS,
                    accessRoles: StatisticsRoles.extractionjdecomptesAnnuels()
                },
                {
                    label: 'decomptelppnonpayee',
                    event: LayoutSubNavigationEvent.LPPNONPAYEE,
                    accessRoles: StatisticsRoles.extractionjdecomptesAnnuels()
                },
                {
                    label: 'decompteentreprise',
                    event: LayoutSubNavigationEvent.DECOMPTEENTREPRISE,
                    accessRoles: StatisticsRoles.extractionjdecomptesAnnuels()
                },
                {
                    label: 'gsannuelrevisions',
                    event: LayoutSubNavigationEvent.GS_ANNUEL_REVISIONS,
                    accessRoles: StatisticsRoles.extractionjdecomptesAnnuels()
                }
            ],
        }
    ],
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.banque',
            accessRoles: SideMenuRoles.paiementsWriteMenuRoles(),
            items: [
                {
                    label: 'ordres_paiement',
                    event: LayoutSalaryNavigationEvent.ORDRE_PAIEMENT,
                    accessRoles: SideMenuRoles.paiementsWriteMenuRoles(),

                }
            ],
        }
    ],

    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.admin',
            accessRoles: ConfigRoles.periodActiveSalaireRoles(),
            items: [
                {
                    label: 'periode',
                    event: LayoutSubNavigationEvent.PERIODE,
                    accessRoles: ConfigRoles.periodActiveSalaireRoles()

                },
                {
                    label: 'exerciseSelection',
                    event: LayoutSubNavigationEvent.SELECTION_EXERCISE,
                    accessRoles: ConfigRoles.exerciceMgtRoles()

                },
                {
                    label: 'layout_menu_recalcio',
                    event: LayoutSubNavigationEvent.RECALCIO,
                    accessRoles: ConfigRoles.salaireEntreeSortiesMenuRoles()

                },
                {
                    label: 'menu.subheader.booking_files',
                    event: LayoutSubNavigationEvent.BOOKING_FILES_SALARY,
                    accessRoles: ConfigRoles.periodAccountingRoles()
                }
            ],
        }
    ]];

export const MENU_PARAMETERS: MenuItem[][] = [
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.parameter_front_office',
            accessRoles: ConfigRoles.placementParamGenerauxRoles(),
            items: [
                {
                    id: 'textesGeneraux',
                    label: 'parametres_generaux',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.PARAMETRES_GENERAUX
                },
                {
                    event: '',
                    title: '',
                    separator: true,
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                },
                {
                    id: 'secteurs',
                    label: 'secteurs',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.PPSECTEUR_CONFIG
                },
                {
                    id: 'agences',
                    label: 'agency',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.AGENCE_CONFIG,
                },
                {
                    id: 'professions',
                    label: 'professions',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.PROF_CONFIG
                },
                {
                    id: 'genretrav',
                    label: 'genretrav',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.GENRE_TRAVAIL
                },
                {
                    event: '',
                    title: '',
                    separator: true,
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                },
                {
                    id: 'keywords',
                    label: 'Keywords',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.KEYWORD_CONFIG
                },
                {
                    id: 'genres_heures',
                    label: 'genres_heures',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.PPGRH_CONFIG
                },
                {
                    id: 'genres_indemnites',
                    label: 'genres_indemnites',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.GENRES_INDEMNITES
                },
                {
                    id: 'cctMenu',
                    label: 'cct',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.PPCCT_CONFIG
                },
                {
                    id: 'calendrier',
                    label: 'calendrier_jours_feries',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.CALENDAR_JOURS_FERIES
                },
                {
                    id: 'documents_types',
                    label: 'documents_types',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.PPDOC_TYPE
                },
                {
                    event: '',
                    title: '',
                    separator: true,
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                },
                {
                    id: 'gestion_modeles_placement',
                    label: 'gestion_modeles',
                    accessRoles: ConfigRoles.gestionModelesRoles(),
                    event: LayoutSubNavigationEvent.GESTION_MODELES_PLACEMENT
                },
                {
                    id: 'lookups',
                    label: 'Lookups',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.LOOKUPS
                },
                {
                    id: 'textesParametrables',
                    label: 'textes_parametrables',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.TEXTES_PARAMETRABLES
                },
                {
                    id: 'ij-config',
                    label: 'ij_config_menu',
                    accessRoles: ConfigRoles.placementParamGenerauxRoles(),
                    event: LayoutSubNavigationEvent.IJ_CONFIG,
                    disabled: true
                }
            ]
        }
    ],
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.parametres_facturation',
            accessRoles: ConfigRoles.factureParamGenerauxRoles(),
            items: [
                {
                    label: 'parametres_generaux',
                    event: LayoutSubNavigationEvent.PARAMETRES_GENERAUX_FACTURATION,
                    accessRoles: ConfigRoles.factureParamGenerauxRoles()
                },
                {
                    event: '',
                    title: '',
                    separator: true,
                    accessRoles: ConfigRoles.factureParamGenerauxRoles()
                },
                {
                    label: 'mode_paiement',
                    event: LayoutSubNavigationEvent.GEPAIMODE,
                    accessRoles: ConfigRoles.factureParamGenerauxRoles()
                },
                {
                    id: 'condition_paiment',
                    label: 'condition_paiment',
                    event: LayoutSubNavigationEvent.GEPAICOND,
                    accessRoles: ConfigRoles.factureParamGenerauxRoles()
                },
                {
                    event: '',
                    title: '',
                    separator: true,
                    accessRoles: ConfigRoles.gestionModelesRoles()
                },
                {
                    id: 'gestion_modeles_facturation',
                    label: 'gestion_modeles',
                    accessRoles: ConfigRoles.gestionModelesRoles(),
                    event: LayoutSubNavigationEvent.GESTION_MODELES_FACTURATION
                }

            ]
        }
    ],
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.parametres_salaires',
            accessRoles: ConfigRoles.salaireParamGenerauxMenuRoles(),
            items: [
                {
                    label: 'parametres_generaux',
                    event: LayoutSubNavigationEvent.PARAMETRES_GENERAUX_SALARY,
                    accessRoles: ConfigRoles.salaireParamGenerauxRoles()
                },
                {
                    event: '',
                    title: '',
                    separator: true,
                    accessRoles: ConfigRoles.salaireParamGenerauxRoles()

                },
                {
                    label: 'genreSalaire',
                    event: LayoutSubNavigationEvent.GENRE_DE_SALAIRE,
                    accessRoles: ConfigRoles.salaireParamGenerauxRoles()
                },
                {
                    label: 'sapardedview',
                    event: LayoutSubNavigationEvent.SALARY_DEDUCTION,
                    accessRoles: ConfigRoles.salaireParamGenerauxRoles()
                },
                {
                    label: 'assurance',
                    event: LayoutSubNavigationEvent.ASSURANCES,
                    accessRoles: ConfigRoles.salaireParamGenerauxRoles()
                },
                {
                    label: 'canton_IS',
                    event: LayoutSubNavigationEvent.CANTON_IS,
                    accessRoles: ConfigRoles.salaireParamGenerauxRoles()
                },
                {
                    label: 'bareme_IS',
                    event: LayoutSubNavigationEvent.CANTON_BAREME,
                    accessRoles: ConfigRoles.salaireParamGenerauxRoles()
                },
                {
                    label: 'saparmcs_form_title',
                    event: LayoutSubNavigationEvent.PARAMETRES_NCS,
                    accessRoles: ConfigRoles.salaireParamGenerauxAdminRoles()
                },
                {
                    label: 'mode_paiement',
                    event: LayoutSubNavigationEvent.SAPAIMODE,
                    accessRoles: ConfigRoles.salaireParamGenerauxAdminRoles()
                },
                {
                    event: '',
                    title: '',
                    separator: true,
                    accessRoles: ConfigRoles.gestionModelesRoles()
                },
                {
                    id: 'gestion_modeles_salaire',
                    label: 'gestion_modeles',
                    accessRoles: ConfigRoles.gestionModelesRoles(),
                    event: LayoutSubNavigationEvent.GESTION_MODELES_SALAIRE
                },
            ],
        }
    ],
    [
        {
            event: LayoutEventNone,
            label: 'menu.subheader.parametres_droits',
            accessRoles: ConfigRoles.gestionDesDroitsData(),
            items: [
                {
                    id: 'conseillers',
                    label: 'conseillers',
                    accessRoles: ConfigRoles.gestionDroitsConseillers(),
                    event: LayoutSubNavigationEvent.CONSEILLERS
                },
                {
                    id: 'user-roles',
                    label: 'user_roles',
                    accessRoles: ConfigRoles.gestionDroitsConseillers(),
                    event: LayoutSubNavigationEvent.USER_ROLES
                },
                {
                    id: 'user-teams',
                    label: 'user_teams',
                    accessRoles: ConfigRoles.gestionDroitsConseillers(),
                    event: LayoutSubNavigationEvent.USER_TEAMS
                }
            ]
        }
    ]
];

export const MENU_ADMIN_APP: MenuItem[][] = [
    [
        {
            label: 'layout_menu_label_conf_admin_app',
            accessRoles: SideMenuRoles.adminMenuRoles(),
            items: [
                {
                    icon: 'file-invoice-dollar',
                    label: 'layout_menu_label_swissdec',
                    event: LayoutAdminNavigationEvent.SWISSDEC,
                    title: 'layout_menu_swissdec',
                    accessRoles: SideMenuRoles.swissdecRoles()
                },
                {
                    icon: 'user-tie',
                    label: 'layout_menu_label_user',
                    event: LayoutAdminNavigationEvent.KUSERS,
                    title: 'layout_menu_user',
                    accessRoles: SideMenuRoles.userAdmRoles()
                },
                {
                    icon: 'book-bible',
                    label: 'layout_menu_ultimate_admin',
                    event: LayoutAdminNavigationEvent.ULTIMATE_ADMIN,
                    title: 'layout_menu_user',
                    accessRoles: SideMenuRoles.godRoles()
                },
                {
                    icon: 'microchip',
                    label: 'layout_menu_operations',
                    event: LayoutAdminNavigationEvent.OPERATIONS,
                    title: 'layout_menu_operations',
                    accessRoles: SideMenuRoles.godRoles()
                }

                // {
                //     icon: 'microchip',
                //     label: 'layout_menu_bulk_manager',
                //     event: LayoutAdminNavigationEvent.BULK_MANAGER,
                //     title: 'layout_menu_bulk_manager',
                //     accessRoles: SideMenuRoles.godRoles()
                // }
            ]
        }]];


export const HEADER_MENU_ENTRIES: MenuEntry[] = [//     {
    {
        icon: '',
        tooltip: 'layout_menu_label_placement',
        label: 'layout_menu_label_placement',
        key: '',
        accessRoles: SideMenuRoles.placementMenuRoles(),
        items: MENU_PLACEMENT
    },
    {
        icon: '',
        tooltip: 'layout_menu_label_facture',
        label: 'layout_menu_label_facture',
        key: '',
        accessRoles: SideMenuRoles.factureHeaderMenuRoles(),
        items: MENU_FACTURE
    },
    {
        icon: '',
        tooltip: 'layout_menu_label_salaire',
        label: 'layout_menu_label_salaire',
        key: '',
        accessRoles: SideMenuRoles.salaireHeaderMenuRoles(),
        items: MENU_SALAIRE
    },
    {
        icon: '',
        tooltip: 'layout_menu_label_parameters_gestion',
        label: 'layout_menu_label_parameters_gestion',
        key: '',
        accessRoles: ConfigRoles.paramGenerauxRoles(),
        items: MENU_PARAMETERS
    },
    {
        icon: 'fas fa-toolbox',
        tooltip: 'layout_menu_label_config',
        label: 'layout_menu_label_config',
        key: LayoutEvents.TOGGLE_SIDE_MENU_CONFIG, // hasSubMenu: true
        accessRoles: SideMenuRoles.adminMenuRoles(),
        items: MENU_ADMIN_APP
    }
];


