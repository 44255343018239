<div style="display: flex; height: 100%; flex-direction: column;">
    <form [formGroup]="formGroup"
          class="row">

        <iw-textfield #txtCli_id
                      [isDisabled]="true"
                      [ngClass]="getCliClass()"
                      class="iw-input col-xs-2 no-padding"
                      formControlName="cliId"></iw-textfield>


        <iw-textfield #txtCliNom
                      [isDisabled]="true"
                      [value]="nom"
                      class="iw-input col-xs-9 no-padding label-name"></iw-textfield>
        <iw-button #associationBtn
                   (press)="openGecli()"
                   *iwAccessGroups="INVOICE_USER_ROLES"
                   [isDisabled]="false"
                   class="iw-button col-xs-1 flex-end"
                   iconClass="fas fa-link"></iw-button>

    </form>
    <iw-tab [(selectedTab)]="selectedTab"
            [disabledTabs]="disabledTabList"
            [invalidTabs]="invalidTabList"
            [tabs]="tabList">

        <iw-tab-general *ngIf="selectedTab === tabList[0]"
                        [entityId]="cliId"
                        [formGroup]="formGroup"
                        [isNew]="checkIfNew()"
                        [isReadonly]="!isWriteMode"></iw-tab-general>

        <iw-tab-autre *ngIf="selectedTab === tabList[1]"
                      [entityId]="cliId"
                      [formGroup]="formGroup"
                      [isReadonly]="!isWriteMode"></iw-tab-autre>

        <iw-tab-contacts (defaultContactChanged)="onDefaultContactChanged($event)"
                         *ngIf="selectedTab === tabList[2]"
                         [ageId]="ageId"
                         [defaultCliAttId]="cliAttId"
                         [entityId]="cliId"
                         [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"></iw-tab-contacts>

        <iw-tab-address *ngIf="selectedTab === tabList[3]"
                        [entityId]="cliId"
                        [formGroup]="formGroup"
                        [isReadonly]="!isWriteMode"
                        [uuid]="uuid"></iw-tab-address>

        <iw-tab-notes *ngIf="selectedTab === tabList[4]"
                      [formGroup]="formGroup"
                      [isReadonly]="!isWriteMode"></iw-tab-notes>

        <iw-tab-suivi *ngIf="selectedTab === tabList[5]"
                      [entityId]="cliId"
                      [isReadonly]="!isWriteMode"></iw-tab-suivi>

        <iw-tab-chantier *ngIf="selectedTab === tabList[6]"
                         [entityId]="cliId"
                         [isReadonly]="!isWriteMode"></iw-tab-chantier>

        <iw-tab-matching *ngIf="selectedTab === tabList[7]"
                         [entityId]="cliId || ''"
                         [formGroup]="formGroup"
                         [formId]="uuid"
                         [isReadonly]="!isWriteMode"></iw-tab-matching>

        <iw-tab-doc *ngIf="selectedTab === tabList[8]"
                    [clientId]="cliId"
                    [isReadonly]="!isWriteMode"></iw-tab-doc>

        <iw-tab-missions *ngIf="selectedTab === tabList[9]"
                         [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"></iw-tab-missions>

        <iw-tab-pointeuse *ngIf="selectedTab === tabList[10]"
                          [formGroup]="formGroup"
                          [isReadonly]="!isWriteMode"></iw-tab-pointeuse>

    </iw-tab>

    <form [formGroup]="formGroup"
          class="row"
          style="flex-grow: 1;">
        <iw-textarea #edtNotes
                     [height]="'100%'"
                     [isDisabled]="!isWriteMode"
                     class="iw-textarea col-xs-12"
                     formControlName="remarques"></iw-textarea>
    </form>
</div>
