import {IwColumn} from '@app/sam-base/core/column/decorators/iw-column.decorator';
import {IwRestEntityId} from '@app/sam-base/core/rest-api';
import {IwRestEntity} from '@app/sam-base/core/rest-api/entity/rest-entity.decorator';

@IwRestEntity('saempop')
export class Saempop {
    @IwRestEntityId() @IwColumn({name: 'Id', index: 0}) public empopId?: number;
    public empId?: string;
    @IwColumn({name: 'GS', index: 1}) public gsId?: string;
    @IwColumn({name: 'fou', index: 2}) public fouId?: string;
    public foudpId?: string;
    @IwColumn({name: 'ref_paiement', index: 3}) public refop?: string;
    public refop2?: string;
    public norefbvr?: string;
    public compte?: string;

}
