<form [formGroup]="formGroup">

    <div class="row">

        <iw-enum-dropdown #cboSalMode
                          (valueChange)="onSalaryModeChange()"
                          [(value)]="salaryTimeMode"
                          [inputEnum]="salaryTimeModeEnum"
                          [label]="'modeSalary' | translate"
                          class="col-xs-3 padding-bottom-5"
                          labelAlign="left"
                          style="height: 30px;"></iw-enum-dropdown>


        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'owner' | translate"
                      class="iw-input col-xs-offset-3 col-xs-2"
                      formControlName="sessionOwner"></iw-textfield>
        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'sessionId' | translate"
                      [value]="this.salaryProcessId ? '' + this.salaryProcessId : ''"
                      class="col-xs-2"></iw-textfield>

        <iw-button #btnListAllSessions
                   (press)="openSalaryProcessList()"
                   [iconClass]="'fas fa-chalkboard-user'"
                   [text]="'nb_salary_processes_active' | translate: {nb: salaryProcesses?.length}"
                   class="iw-input col-xs-2"></iw-button>
    </div>
    <div class="row">

        <div class="col-xs-10 no-padding">
            <div class="row">
                <iw-textfield #txtLabelEmp
                              [isDisabled]="true"
                              [textAlign]="'center'"
                              [value]="txtWarning"
                              class="iw-input col-xs-10 no-padding"></iw-textfield>
                <iw-textfield #txtLwithCCT
                              [inputClass]="'small-font'"
                              [isDisabled]="true"
                              [textAlign]="'center'"
                              [value]="withCct"
                              class="iw-input col-xs-1 no-padding"></iw-textfield>
                <iw-textfield #txtLPPMode
                              [inputClass]="'small-font'"
                              [isDisabled]="true"
                              [textAlign]="'center'"
                              [value]="lppmode"
                              class="iw-input col-xs-1 no-padding"></iw-textfield>
            </div>
            <div class="row">
                <iw-textfield #txtNbSalBuffErrorCount
                              [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'error' | translate"
                              [textAlign]="'center'"
                              [value]="nbSalBuffErrorCount"
                              class="iw-input col-xs-offset-8 col-xs-2 no-padding"></iw-textfield>
                <iw-textfield #txtNbSalBuffSelectedCount
                              [isDisabled]="true"
                              [textAlign]="'center'"
                              [value]="nbSalBuffSelectedCount"
                              class="iw-input col-xs-2 no-padding"></iw-textfield>
            </div>
            <div class="row salary-table">
                <iw-table (applyFilter)="onApplyFilter($event)"
                          (columnSort)="onSort($event)"
                          (contextMenuClick)="onContextMenu($event)"
                          (rowClicked)="onSalBufferClicked($event)"
                          (rowDoubleClick)="openFicheSal($event)"
                          (selectedChange)="updateSalbufSel($event)"
                          [columns]="columnsHourly"
                          [data]="salariesBuffer"
                          [defaultSelected]="true"
                          [isDisabled]="false"
                          [isFilterEnable]="true"
                          [isGridColumnMenuEnable]="false"
                          [isSortIconEnable]="true"
                          [selectionMode]="'checkbox'"
                          [virtualScroll]="true"
                          class="iw-grid col-xs-12 no-padding"
                          style="height: 530px"></iw-table>
                <iw-context-menu #menu
                                 [context]="contextMenuData"
                                 [items]="contextMenuItems"
                                 [popup]="true"></iw-context-menu>
            </div>
            <div *ngIf="salBufferClicked?.error"
                 class="row col-xs-12">
                <div [innerHTML]="salBufferClickedFormattedErrorMessage"
                     class="iw-textarea col-xs-12"></div>
            </div>
        </div>
        <div class="col-xs-2 no-padding">
            <div class="row col-xs-12"
                 style="height: 600px;">
                <iw-textfield #lblPeriode
                              [isDisabled]="true"
                              [ngClass]="backgroundPerColor"
                              [textAlign]="'center'"
                              [tooltip]="'periode_active' | translate"
                              [value]="periode?.texte"
                              class="iw-input col-xs-12"></iw-textfield>
                <iw-button #btnSetup
                           (press)="openParameters()"
                           [text]="'parametres' | translate"
                           class="iw-button col-xs-12"></iw-button>

                <iw-textfield #lblPeriode
                              [isDisabled]="true"
                              [ngClass]="backgroundForceColor"
                              [textAlign]="'center'"
                              [value]="lforceTxt"
                              class="iw-input col-xs-12 padding-top-15"></iw-textfield>
                <iw-button #btnSetup
                           (press)="initSalaries()"
                           [isDisabled]="!initEnabled"
                           [text]="'init' | translate"
                           class="iw-button col-xs-12"></iw-button>
                <iw-button #btnSetup
                           (press)="pressForce()"
                           [text]="'forcer' | translate"
                           class="iw-button col-xs-12"></iw-button>

                <div class="col-xs-12 no-label margin-bottom-5 margin-top-15"></div>

                <iw-button #btnReset
                           (press)="deleteProcess()"
                           [isDisabled]="!canClean"
                           [text]="'effacer' | translate"
                           class="iw-button col-xs-12 margin-bottom-5 margin-top-15"></iw-button>

                <iw-button #btnGenerate
                           (press)="generate()"
                           [isDisabled]="!canGenerate"
                           [text]="'generer' | translate"
                           class="iw-button col-xs-12 margin-bottom-5"></iw-button>
                <iw-button #btnGenerate
                           (press)="cancelGeneration()"
                           [isDisabled]="!canCancelGeneration"
                           [text]="'annulerGeneration' | translate"
                           class="iw-button col-xs-12 margin-bottom-5"></iw-button>

                <iw-button #btnCalculate
                           (press)="calculate()"
                           [isDisabled]="!canCalculate"
                           [text]="'calculer' | translate"
                           class="iw-button col-xs-12 margin-bottom-5"></iw-button>

                <iw-button #btnSaveAndQuit
                           (press)="saveSalaryProcessAndQuit()"
                           [isDisabled]="!canEditSession"
                           [text]="'saveAndQuit' | translate"
                           class="iw-button col-xs-12 margin-bottom-5"></iw-button>

                <iw-button #btnClose
                           (press)="closeDialog()"
                           [text]="'quit' | translate"
                           class="iw-button col-xs-12"></iw-button>
            </div>
        </div>
    </div>

</form>
