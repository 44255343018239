export enum SxResponseState {
    ACCEPTED_WITH_WARNING = 'ACCEPTED_WITH_WARNING',
    NOT_ACCEPTED = 'NOT_ACCEPTED',
    ACCEPTED = 'ACCEPTED',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    COMPLETION_RELEASE_IS_MISSING = 'COMPLETION_RELEASE_IS_MISSING',
    PROCESSING = 'PROCESSING',
    IGNORED = 'IGNORED',
}
