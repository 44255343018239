import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Gefachdr} from '@app/sam-base/models/invoices/gefachdr';
import {FactureNCSideMenuComponent} from '../components/facture-NC/facture-nc-side-menu.component';
import {FactureNCComponent} from '../components/facture-NC/facture-nc.component';

export const FACTURE_NC_FORM: FormDescription<Gefachdr> = {
    form: FactureNCComponent, sideMenu: FactureNCSideMenuComponent, state: {
        ...buildDefaultState(Gefachdr), diagTitle: 'Facture/NC', diagShowSideMenu: true, diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showPrint: false, showDelete: true
        }, diagShowStatus: true, editMode: 'read', useNavigation: true, width: 1000, patch: false
    }

};

