export enum AnswerType {
    STRING = 'STRING',
    INTEGER = 'INTEGER',
    DOUBLE = 'DOUBLE',
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    YES_NO = 'YES_NO',
    PROBLEM = 'PROBLEM',
    OPTIONAL = 'OPTIONAL',
    AMOUNT = 'AMOUNT',
    LABEL = 'LABEL',
}
