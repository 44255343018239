<form [formGroup]="formGroup"
      class="row">
    <div class="row col-xs-12">
        <div class="row col-xs-12">
            <iw-checkbox [isDisabled]="isReadonly"
                         [label]="'certificat' | translate"
                         class="iw-input col-xs-2 padding-top-15 no-padding-left"
                         formControlName="ncsA"></iw-checkbox>

            <iw-checkbox [isDisabled]="isReadonly"
                         [label]="'combo_ncs_rente' | translate"
                         class="iw-input col-xs-2 padding-top-15"
                         formControlName="ncsB"></iw-checkbox>

            <iw-checkbox [isDisabled]="isReadonly"
                         [label]="'combo_ncs_transport_gratuit' | translate"
                         class="iw-input col-xs-3 padding-top-15"
                         formControlName="ncsF"></iw-checkbox>

            <iw-checkbox [isDisabled]="isReadonly"
                         [label]="'combo_ncs_repas_cantine' | translate"
                         class="iw-input col-xs-3 padding-top-15"
                         formControlName="ncsG"></iw-checkbox>

            <iw-textfield [isDisabled]="true"
                          [value]="currentYear"
                          class="iw-input col-xs-2 padding-top-10 no-padding-right"></iw-textfield>
        </div>

        <div class="col-xs-12">
            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'2.3:'"
                          class="iw-input col-xs-12"
                          formControlName="ncsT23"></iw-textfield>

            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'3:'"
                          class="iw-input col-xs-12"
                          formControlName="ncsT3"></iw-textfield>

            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'4:'"
                          class="iw-input col-xs-12"
                          formControlName="ncsT4"></iw-textfield>

            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'7.1:'"
                          class="iw-input col-xs-12"
                          formControlName="ncsT71"></iw-textfield>

            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'7.2:'"
                          class="iw-input col-xs-12"
                          formControlName="ncsT72"></iw-textfield>
        </div>

        <iw-checkbox [isDisabled]="isReadonly"
                     [label]="'combo_ncs_voyage_repas_nuitees' | translate"
                     class="col-xs-10 flex-start"
                     formControlName="ncs1311"></iw-checkbox>

        <iw-button (press)="avancee()"
                   [isDisabled]="isReadonly"
                   [text]="'combo_ncs_avancee' | translate"
                   class="iw-button col-xs-2 flex-end"></iw-button>

        <div class="col-xs-12">
            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'13.1.2:'"
                          class="iw-input col-xs-12"
                          formControlName="ncsT1312"></iw-textfield>

            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'13.1.3:'"
                          class="iw-input col-xs-12"
                          formControlName="ncsT1323"></iw-textfield>

            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'14.1:'"
                          class="iw-input col-xs-12"
                          formControlName="ncsT141"></iw-textfield>

            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'14.2:'"
                          class="iw-input col-xs-12"
                          formControlName="ncsT142"></iw-textfield>

            <iw-textarea [height]="60"
                         [isDisabled]="isReadonly"
                         [labelAlign]="'left'"
                         [label]="'15:'"
                         class="iw-input col-xs-12"
                         class="iw-textarea col-xs-12"
                         formControlName="ncsT15"></iw-textarea>
        </div>

        <div class="row col-xs-12 padding-top-15">

            <div class="iw-label col-xs-3 no-padding-left"
                 style="align-self: center;">{{ 'combo_ncs_jours_de_travail' | translate }}
            </div>

            <div class="row col-xs-9 no-padding-right">
                <iw-textfield [isDisabled]="isReadonly"
                              [label]="'combo_jour' | translate"
                              class="iw-input col-xs-3 flex-end text-align-right"
                              formControlName="txtJtrav"></iw-textfield>

                <iw-textfield [isDisabled]="isReadonly"
                              [label]="'heures' | translate"
                              class="iw-input col-xs-3 flex-end text-align-right"
                              formControlName="txtHtrav"></iw-textfield>

                <iw-textfield [isDisabled]="isReadonly"
                              [label]="'1ErJour' | translate"
                              class="iw-input col-xs-3 flex-end"
                              formControlName="txtFirstDay"></iw-textfield>

                <iw-textfield [isDisabled]="isReadonly"
                              [label]="'dernierJour' | translate"
                              class="iw-input col-xs-3 no-padding-right flex-end"
                              formControlName="txtLastDay"></iw-textfield>
            </div>
        </div>

        <div class="row col-xs-12 padding-top-15">
            <iw-contact-ncs-combo-box [isDisabled]="isReadonly"
                                      [labelAlign]="'left'"
                                      [label]="'contact' | translate"
                                      [size]="'large'"
                                      class="iw-combobox col-xs-12"
                                      formControlName="contactNcsId"></iw-contact-ncs-combo-box>
        </div>


    </div>
</form>
