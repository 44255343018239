import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppemp} from '@app/sam-base/models/placement';

import {HoraireDesire, TypeEmploi} from './tab-ppemp-disp-souhait-enums';

@Component({
    selector: 'iw-tab-ppemp-disp-souhait', templateUrl: './tab-ppemp-disp-souhait.component.html'
})
export class TabPpempDispSouhaitComponent extends BasePartialFormComponent<Ppemp> {

    public get entityId() {
        return this.getFormValue<string>('empId');
    }

    public typeDemploi = TypeEmploi;
    public horaireDes = HoraireDesire;

    constructor() {
        super();
    }

    public checkClause() {
        return !(this.getFormValue('concuc') && !this.isReadonly);
    }

}
