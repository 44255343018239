import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GepaihdrCalc} from '@app/sam-base/models/invoices/gepaihdrCalc';
import {environment} from '@root/environments/environment';
import {BvrLoad} from '@sam-base/models/invoices/bvrLoad';
import {EncaissementAutomatic} from '@sam-base/models/invoices/encaissement-automatic.model';
import {Gepaihdr} from '@sam-base/models/invoices/gepaihdr';
import {Observable} from 'rxjs';
import {PaiementsModel} from './../../models/paiements.model';

@Injectable()
export class EncaissementService {

    constructor(private _http: HttpClient) {
    }

    public getEncaissementsTable(facId: string): Observable<PaiementsModel[]> {
        return this._http.get<PaiementsModel[]>(environment.backendURL + 'gepaiementview/' + facId);
    }

    public deleteSolde(payId: string): Observable<void> {
        return this._http.post<void>(environment.backendURL + 'gepaihdr/deleteSolde/' + payId, undefined);
    }

    public calculate(data: GepaihdrCalc) {
        return this._http.post<void>(environment.backendURL + 'gepaihdr/recalculate', data);
    }

    public importBVRLines(encaissementAuto: EncaissementAutomatic): Observable<Gepaihdr[]> {
        return this._http.post<Gepaihdr[]>(environment.backendURL + 'paybvr/camt/import', encaissementAuto);
    }

    public loadCamt54File(formData: FormData): Observable<BvrLoad> {
        return this._http.post(environment.backendURL + 'paybvr/camt/upload', formData);
    }

    public createEncaissementForClient(clientId?: string): Observable<Gepaihdr> {
        let encaissement: Gepaihdr = new Gepaihdr();
        encaissement.cliId = clientId;
        return this._http.post(environment.backendURL + `gepaihdr`, encaissement);
    }

}
