import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class QRService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public createQR(facId?: string): Observable<string> {
        return this._http.get(this._baseUrl + `qr/createQR/${facId}`, {
            responseType: 'text'
        });
    }

}
