import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppacattest} from '@app/sam-base/models/placement';

import {PpacattestNewComponent} from '../components/ppacattest/ppacattest-new/ppacattest-new.component';
import {PpacattestSidemenuComponent} from '../components/ppacattest/ppacattest-sidemenu.component';
import {PpacattestComponent} from '../components/ppacattest/ppacattest.component';

export const PPACATTEST_DEFAULT_FORM: FormDescription<Ppacattest> = {
    form: PpacattestComponent, sideMenu: PpacattestSidemenuComponent, state: {
        ...buildDefaultState(Ppacattest),
        diagTitle: 'aca_form_title',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showDelete: true
        },
        useNavigation: true,
        patch: false,
        width: 1000
    }
};

export const PPACATTEST_CREATE_FORM: FormDescription<Ppacattest> = {
    form: PpacattestNewComponent, state: {
        ...buildDefaultState(Ppacattest),
        diagTitle: 'ppacattestnew_form_title',
        isNew: true,
        width: 600,
        destroyOnSave: true
    }
};
