import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppdoctype} from '@app/sam-base/models/placement';

import {PpdoctypeComponent} from '../components/ppdoctype/ppdoctype.component';

export const PPDOC_TYPE: FormDescription<Ppdoctype> = {
    form: PpdoctypeComponent, state: {
        ...buildDefaultState(Ppdoctype), diagModal: true, diagTitle: 'documents_types', width: 800, isNew: true
    }
};
