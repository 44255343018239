<form [formGroup]="formGroup" class="row">

    <iw-textarea #edtReferences [height]="200" [isDisabled]="isReadonly" [labelAlign]="'top'"
                 [label]="'references' | translate" class="iw-textarea col-xs-12"
                 formControlName="ppempReferences"></iw-textarea>

    <iw-checkbox #chkForceLpp [isDisabled]="isReadonly" [label]="'autorisationDeDemanderDesRenseignements' | translate"
                 class="iw-input col-xs-12 no-padding padding-top-5" formControlName="inquiry"></iw-checkbox>

    <div class="col-xs-12 separator-top no-padding"></div>

    <iw-checkbox #Standardlogic1 [isDisabled]="isReadonly" [label]="'poursuites' | translate"
                 class="iw-input col-xs-12 no-padding" formControlName="poursuites"></iw-checkbox>

    <iw-checkbox #Standardlogic2 [isDisabled]="isReadonly" [label]="'casierJudiciaire' | translate"
                 class="iw-input col-xs-12 no-padding" formControlName="casier"></iw-checkbox>

    <iw-checkbox #Standardlogic3 [isDisabled]="isReadonly" [label]="'extraitDeCasierFourniSurDemande' | translate"
                 class="iw-input col-xs-12 no-padding" formControlName="extrcasier"></iw-checkbox>

</form>
