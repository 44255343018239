<form [formGroup]="formGroup" class="row"></form>

<iw-tab [(selectedTab)]="selectedTab" [invalidTabs]="invalidTabList" [tabs]="tabList">
    <iw-tab-pppar-general *ngIf="selectedTab === tabList[0]" [formGroup]="formGroup"
                          [isReadonly]="!isWriteMode"></iw-tab-pppar-general>
    <iw-tab-pppar-commande *ngIf="selectedTab === tabList[1]" [formGroup]="formGroup" [isReadonly]="!isWriteMode"
                           [uuid]="uuid"></iw-tab-pppar-commande>
    <iw-tab-pppar-employe *ngIf="selectedTab === tabList[2]" [formGroup]="formGroup"
                          [isReadonly]="!isWriteMode"></iw-tab-pppar-employe>
    <iw-tab-pppar-client *ngIf="selectedTab === tabList[3]" [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"></iw-tab-pppar-client>
    <iw-tab-pppar-salaire1 *ngIf="selectedTab === tabList[4]" [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"></iw-tab-pppar-salaire1>
    <iw-tab-pppar-salaire2 *ngIf="selectedTab === tabList[5]" [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"></iw-tab-pppar-salaire2>
    <iw-tab-pppar-formats *ngIf="selectedTab === tabList[6]" [formGroup]="formGroup"
                          [isReadonly]="!isWriteMode"></iw-tab-pppar-formats>
    <iw-tab-pppar-signature *ngIf="selectedTab === tabList[7]" [formGroup]="formGroup"
                            [isReadonly]="!isWriteMode"></iw-tab-pppar-signature>
    <iw-tab-pppar-rating *ngIf="selectedTab === tabList[8]" [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"></iw-tab-pppar-rating>
</iw-tab>

<form [formGroup]="formGroup" class="row">
    <iw-textarea #edtNotes [height]="40" [isDisabled]="!isWriteMode" [value]=""
                 class="iw-textarea col-xs-12"></iw-textarea>
</form>
