<form [formGroup]="formGroup"
      class="row">
    <div class="row space-form col-xs-10">
        <iw-rest-grid #sadsGrid
                      (selected)="getSelectedRow($event)"
                      [autoFormClick]="false"
                      [customColumns]="sadsColumns"
                      [defaultSelected]="true"
                      [hasGridColumnMenu]="false"
                      [hasSortIcon]="true"
                      [queryStatements]="query"
                      [type]="type"
                      class="col-xs-12"
                      style="height: 200px"></iw-rest-grid>

        <div class="row col-xs-8 padding-top-15"
             style="margin-block-end: auto">
            <iw-sags-dropdown #dropSags
                              [isDisabled]="readonly"
                              [labelAlign]="'top'"
                              [label]="'genreSalaire' | translate"
                              [types]="['1','2','3','9','A','B','V']"
                              class="iw-input col-xs-8"
                              formControlName="gsId"></iw-sags-dropdown>

            <iw-dropdown #drpFreq
                         (valueSelected)="enableFields($event)"
                         [isDisabled]="readonly"
                         [labelAlign]="'top'"
                         [label]="'freqSalaire' | translate"
                         [options]="freqOptions"
                         class="col-xs-4"
                         formControlName="frequence"></iw-dropdown>

            <iw-date-picker #txtDateDec
                            [isDisabled]="readonly || enable"
                            [labelAlign]="'top'"
                            [label]="'partirDu' | translate"
                            class="col-xs-3 padding-top-15"
                            formControlName="debut"></iw-date-picker>
            <iw-date-picker #txtDateFin
                            [isDisabled]="readonly || enable"
                            [labelAlign]="'top'"
                            [label]="'jusqu_au' | translate"
                            class="col-xs-3 padding-top-15"
                            formControlName="fin"></iw-date-picker>
            <iw-textfield #txtNbois
                          [isDisabled]="true"
                          class="iw-input col-xs-3 padding-top-15"
                          formControlName="nbmois"
                          style="align-self: self-end"></iw-textfield>
            <div class="col-xs-3"></div>

            <div *ngIf="enable"
                 class="iw-input col-xs-12 padding-top-15">
                <iw-months-picker #monthPicker
                                  (selectionChange)="setMonths($event)"
                                  [isDisabled]="readonly"
                                  [monthNumber]="months"></iw-months-picker>
            </div>

            <iw-textfield #txtText
                          [isDisabled]="readonly"
                          [labelAlign]="'top'"
                          [label]="'texteComple' | translate"
                          class="iw-input col-xs-9 padding-top-15"
                          formControlName="texte"></iw-textfield>
            <div class="col-xs-3"></div>

            <iw-textfield-compte #txtDebit
                                 [isDisabled]="readonly"
                                 [label]="'debit' | translate"
                                 class="iw-input col-xs-3 padding-top-15"
                                 formControlName="cptdebit"></iw-textfield-compte>
            <iw-textfield-compte #txtCredit
                                 [isDisabled]="readonly"
                                 [labelAlign]="'top'"
                                 [label]="'credit' | translate"
                                 class="iw-input col-xs-3 padding-top-15"
                                 formControlName="cptcredit"></iw-textfield-compte>
            <iw-button #btnReparti
                       (press)="openRepartition()"
                       [isDisabled]="readonly"
                       [text]="'repartitionAnalytique' | translate"
                       class="iw-button btn-block col-xs-6"
                       style="margin-block-start: auto; height: auto"></iw-button>
        </div>

        <div class="row col-xs-4 separator-left padding-top-15"
             style="align-content: baseline">
            <iw-textfield #txtBase
                          [decimals]="2"
                          [isDisabled]="readonly"
                          [labelAlign]="'top'"
                          [label]="'base' | translate"
                          class="iw-input col-xs-9"
                          formControlName="base"
                          type="number"></iw-textfield>
            <div class="col-xs-3"></div>

            <iw-checkbox #chkLdivimois
                         (valueChange)="enableCkr1($event)"
                         [isDisabled]="readonly || enable"
                         [label]="'diviserNbMois' | translate"
                         class="col-xs-12 padding-top-15"
                         formControlName="ldivimois"
                         style="margin-block-start: auto"></iw-checkbox>

            <iw-checkbox #chkLfacteurs
                         (valueChange)="enableCkr2($event)"
                         [isDisabled]="readonly || enable"
                         [label]="'facteursAppliquer' | translate"
                         class="col-xs-12 padding-top-15 padding-bottom-15"
                         formControlName="lfacteurs"
                         style="margin-block-start: auto"></iw-checkbox>

            <div class="iw-label col-xs-12">
                {{ 'facteurHM' | translate }}
            </div>
            <iw-textfield #txtifact
                          [decimals]="2"
                          [isDisabled]="readonly || !enableFacteurs"
                          class="iw-input col-xs-5"
                          formControlName="ifact"
                          type="number"></iw-textfield>
            <div class="col-xs-1"
                 style="align-self: center">{{ '/' }}
            </div>
            <iw-textfield #txtifactfull
                          [decimals]="2"
                          [isDisabled]="readonly || !enableFacteurs"
                          class="iw-input col-xs-5"
                          formControlName="ifactfull"
                          type="number"></iw-textfield>
            <div class="col-xs-1"></div>

            <div class="iw-label col-xs-12">
                {{ 'facteurAnnuel' | translate }}
            </div>
            <iw-textfield #txtifact2
                          [decimals]="2"
                          [isDisabled]="readonly || !enableFacteurs"
                          class="iw-input col-xs-5"
                          formControlName="ifact2"
                          type="number"></iw-textfield>
            <div class="col-xs-1"
                 style="align-self: center">{{ '/' }}
            </div>
            <iw-textfield #txtifact2full
                          [decimals]="2"
                          [isDisabled]="readonly || !enableFacteurs"
                          class="iw-input col-xs-5"
                          formControlName="ifact2full"
                          type="number"></iw-textfield>
            <div class="col-xs-1"></div>

            <iw-textfield #txtMontant
                          [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'montant' | translate"
                          class="iw-input col-xs-5"
                          formControlName="montant"
                          type="number"></iw-textfield>
            <div class="col-xs-1"></div>
            <iw-dropdown #drpArrondir
                         [isDisabled]="readonly || !enableArrondir"
                         [labelAlign]="'top'"
                         [label]="'arrondir' | translate"
                         [options]="arrondirOptions"
                         class="col-xs-5"
                         formControlName="iroundmode"></iw-dropdown>
            <div class="col-xs-1"></div>
        </div>
    </div>

    <div class="col-xs-2 flex flex-v separator-left">
        <iw-button #btnNew
                   (press)="createNewEntry()"
                   *ngIf="readonly"
                   [isDisabled]="!readonly"
                   [text]="'sidemenu_new' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnModif
                   (press)="modifyEntry()"
                   *ngIf="readonly"
                   [isDisabled]="!readonly || !selectedRow"
                   [text]="'sidemenu_edit' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnSave
                   (press)="saveEntry()"
                   *ngIf="!readonly"
                   [isDisabled]="!formGroup.valid"
                   [text]="'sidemenu_save' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnDelete
                   (press)="deleteEntry()"
                   [isDisabled]="!selectedRow"
                   [text]="'supprimer' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnCancel
                   (press)="cancelEditionMode()"
                   *ngIf="!readonly"
                   [text]="'sidemenu_cancel' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnClose
                   (press)="closeDialog()"
                   [text]="'fermer' | translate"
                   class="iw-button btn-block"
                   style="margin-block-start: auto"></iw-button>
    </div>
</form>
