<form [formGroup]="formGroup"
      class="row">

    <div class="col-xs-6 no-padding">
        <div class="col-xs-12 flex flex-center no-padding-right">
            <hr class="header-line col-xs-1">
            <div class="header-line-no-color">{{ 'placement_temp' | translate }}</div>
            <hr class="header-line">
        </div>
        <div class="row col-xs-12 no-padding separator-right">
            <iw-textfield #txtLibConc
                          [labelAlign]="'left'"
                          [label]="'concerne' | translate"
                          class="iw-input col-xs-12 margin-bottom-5"
                          formControlName="libconc"></iw-textfield>

            <iw-textfield #txtLibHeu
                          [labelAlign]="'left'"
                          [label]="'heures' | translate"
                          class="iw-input col-xs-12"
                          formControlName="libheu"></iw-textfield>

            <iw-textfield #txtlibheuequi
                          [labelAlign]="'left'"
                          [label]="'heuresDequipe' | translate"
                          class="iw-input col-xs-12"
                          formControlName="libheuequi"></iw-textfield>

            <iw-textfield #txtLibheuequx
                          [labelAlign]="'left'"
                          [label]="'heures100' | translate"
                          class="iw-input col-xs-12"
                          formControlName="libheuequx"></iw-textfield>

            <iw-textfield #txtlibheunuit
                          [labelAlign]="'left'"
                          [label]="'heuresDeNuit' | translate"
                          class="iw-input col-xs-12"
                          formControlName="libheunuit"></iw-textfield>

            <iw-textfield #txtLibHeuPaus
                          [labelAlign]="'left'"
                          [label]="'heuresDePause' | translate"
                          class="iw-input col-xs-12 margin-bottom-5"
                          formControlName="libheupaus"></iw-textfield>

            <iw-enum-dropdown #cboHpauFacMod
                              [inputEnum]="facPause"
                              [labelAlign]="'left'"
                              [label]="'facturationHPause' | translate"
                              class="iw-input col-xs-12 margin-bottom-5"
                              formControlName="hpaufacmod"></iw-enum-dropdown>

            <iw-textfield #txtLibunite
                          [labelAlign]="'left'"
                          [label]="'uniteHeures' | translate"
                          class="iw-input col-xs-7 margin-bottom-5 no-padding-right"
                          formControlName="libunite"></iw-textfield>

            <iw-textfield #txtlLbsupcct
                          [labelAlign]="'left'"
                          [label]="'supplCct' | translate"
                          class="iw-input col-xs-12 margin-bottom-5"
                          formControlName="libsupcct"></iw-textfield>

            <iw-enum-dropdown #cboFacEleOrd
                              [inputEnum]="ordreElems"
                              [labelAlign]="'left'"
                              [label]="'ordreDesElements' | translate"
                              class="iw-input col-xs-7 margin-bottom-5 no-padding-right"
                              formControlName="faceleord"></iw-enum-dropdown>

            <iw-checkbox #chkElernd05
                         [label]="'arrondirA5Cts' | translate"
                         class="iw-input col-xs-5 margin-bottom-5"
                         formControlName="elernd05"></iw-checkbox>

            <iw-checkbox #chkChant2conc
                         [label]="'copierLeChantierDansLeConcerne' | translate"
                         class="iw-input col-xs-12 margin-bottom-5"
                         formControlName="chant2Conc"></iw-checkbox>

            <iw-enum-dropdown #cboDefChaMode
                              [inputEnum]="modeChantier"
                              [labelAlign]="'left'"
                              [label]="'modeChantierDefaut' | translate"
                              class="iw-input col-xs-7 no-padding-right"
                              formControlName="defchamode"></iw-enum-dropdown>

        </div>
    </div>
    <div class="col-xs-6 no-padding">
        <div class="col-xs-12 flex flex-center no-padding-left">
            <hr class="header-line col-xs-1">
            <div class="header-line-no-color">{{ 'placement_fixe' | translate }}</div>
            <hr class="header-line">
        </div>
        <div class="row col-xs-12 no-padding">

            <iw-enum-dropdown #cboFixgenmode
                              [inputEnum]="mode"
                              [labelAlign]="'left'"
                              [label]="'mode' | translate"
                              class="iw-input col-xs-12 margin-bottom-5"
                              formControlName="fixgenmode"></iw-enum-dropdown>

            <iw-textfield #txtLibconcfix
                          [labelAlign]="'left'"
                          [label]="'concerne' | translate"
                          class="iw-input col-xs-12 margin-bottom-5"
                          formControlName="libconcfix"></iw-textfield>

            <iw-textarea #edtTextplfix
                         [height]="132"
                         [isDisabled]="checkTextDisabled()"
                         [labelAlign]="'top'"
                         [label]="'texte' | translate"
                         class="col-xs-12 iw-textarea"
                         formControlName="textplfix"
                         style="padding-left: 0.5rem; padding-right: 0.5rem;"></iw-textarea>

            <div class="row col-xs-12 flex-end margin-top-70">
                <iw-button #btnCancel
                           (press)="destroyForm()"
                           [text]="'annuler' | translate"
                           class="iw-button col-xs-3"></iw-button>

                <iw-button #btnOk
                           (press)="saveChanges()"
                           [text]="'ok' | translate"
                           class="iw-button col-xs-3"></iw-button>
            </div>

        </div>
    </div>
</form>