<iw-dialog #dialog
           (beforeHide)="onBeforeHide()"
           [bodyPadding]="'10'"
           [closable]="true"
           [cls]="'iw-modal'"
           [draggable]="true"
           [height]="'80vh'"
           [maximized]="true"
           [modal]="true"
           [resizable]="true"
           [showHeader]="true"
           [title]="'layout_menu_operations' | translate"
           [visible]="true"
           [width]="'80vw'">
    <iw-busy [ajustLeft]="5"
             [ajustTop]="5"
             [isBusy]="loading"></iw-busy>

    <p-stepper [linear]="true">
        <p-stepperPanel header="Paramétrage">
            <ng-template let-index="index"
                         let-nextCallback="nextCallback"
                         pTemplate="content">
                <div class="row middle-xs">
                    <p-messages class="col-xs-12 row middle-xs"
                                severity="info">
                        <ng-template pTemplate>
                            <i class="col-xs-1 pi pi-info-circle"
                               style="font-size: 2.5rem"></i>
                            <p class="col-xs-11">{{ bulkOperationNotice }}</p>
                        </ng-template>
                    </p-messages>
                </div>
                <div class="row">
                    <iw-enum-dropdown [(value)]="operationType"
                                      [inputEnum]="operationTypeType"
                                      [isDisabled]="true"
                                      [labelAlign]="'top'"
                                      [label]="'type' | translate"
                                      class="iw-input col-xs-3"></iw-enum-dropdown>

                    <iw-dropdown #drpPeriod
                                 *ngIf="showSalaryPeriod"
                                 [(value)]="salId"
                                 [isDisabled]="true"
                                 [labelAlign]="'top'"
                                 [label]="'combo_periode_salaire' | translate"
                                 [options]="salaryOptionPeriodes"
                                 class="iw-input col-xs-3"></iw-dropdown>
                    <iw-dropdown #drpPeriod
                                 *ngIf="showInvoicePeriod"
                                 [(value)]="salId"
                                 [isDisabled]="true"
                                 [labelAlign]="'top'"
                                 [label]="'combo_periode_salaire' | translate"
                                 [options]="invoiceOptionPeriodes"
                                 class="iw-input col-xs-3"></iw-dropdown>
                    <iw-textfield #txtPeriod
                                  *ngIf="showYear"
                                  [(value)]="year"
                                  [isDisabled]="true"
                                  [labelAlign]="'top'"
                                  [label]="'annee' | translate"
                                  class="iw-input col-xs-3"></iw-textfield>
                </div>
                <div class="row">
                    <iw-checkbox [(value)]="force"
                                 [isDisabled]="true"
                                 [label]="'forcer' | translate"
                                 class="iw-input col-xs-12"></iw-checkbox>

                </div>
                <div class="row end-xs">
                    <p-button (onClick)="nextCallback.emit()"
                              [disabled]="!canGoToStep2"
                              icon="pi pi-arrow-right"
                              iconPos="right"
                              label="Next"/>
                </div>
            </ng-template>
        </p-stepperPanel>
        <p-stepperPanel header="Sélection">
            <ng-template let-index="index"
                         let-nextCallback="nextCallback"
                         let-prevCallback="prevCallback"
                         pTemplate="content">
                <div class="row">
                    <iw-bulk-entity-select-saemp (selectedEvent)="onSelect($event)"
                                                 *ngIf="isSaempEntityOperation"
                                                 [operationType]="operationType"
                                                 [salId]="salId"
                                                 [year]="year"
                                                 class="col-xs-12"></iw-bulk-entity-select-saemp>
                    <iw-bulk-entity-select-saems (selectedEvent)="onSelect($event)"
                                                 *ngIf="isSaemsEntityOperation"
                                                 [force]="force"
                                                 [operationType]="operationType"
                                                 [salId]="salId"
                                                 class="col-xs-12"></iw-bulk-entity-select-saems>
                    <iw-bulk-entity-select-invoices (selectedEvent)="onSelect($event)"
                                                    *ngIf="isInvoiceEntityOperation"
                                                    [operationType]="operationType"
                                                    class="col-xs-12"></iw-bulk-entity-select-invoices>
                </div>
                <div class="row between-xs">
                    <p-button (onClick)="prevCallback.emit()"
                              icon="pi pi-arrow-left"
                              label="Back"/>
                    <p-button (onClick)="createOperation(nextCallback)"
                              [disabled]="selected?.length === 0"
                              icon="pi pi-arrow-right"
                              iconPos="right"
                              label="Next"/>
                </div>
            </ng-template>
        </p-stepperPanel>
        <p-stepperPanel header="Confirmation">
            <ng-template let-index="index"
                         let-prevCallback="prevCallback"
                         pTemplate="content">
                <div class="row">
                    <iw-operation-detail [operationDetail]="operationDetail"
                                         class="col-xs-12"></iw-operation-detail>
                </div>
                <div class="row between-xs">
                    <p-button (onClick)="prevCallback.emit()"
                              icon="pi pi-arrow-left"
                              label="Back"/>
                    <p-button (onClick)="confirmOperation()"
                              [disabled]="selected?.length === 0"
                              [label]="'Confirm' | translate"
                              icon="pi pi-check"
                              iconPos="right"/>
                </div>
            </ng-template>
        </p-stepperPanel>
    </p-stepper>

</iw-dialog>
