import {Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IwGridColumn, MenuItem, ModalComponent} from '@app/sam-base/models';
import {SalaryProcessService} from '@modules/sam-main/placement/services/salary-process.service';
import {IwContextMenuComponent, IwTableComponent} from '@sam-base/components';
import {ContextMenuEvent, IwEventHubService} from '@sam-base/core';
import {ToastService} from '@sam-base/core/toast';
import {SalaryProcess, SalaryProcessEvent} from '@sam-base/models/placement';
import {
    ModalSalarySessionOptions
} from '@shared/widgets/modal-components/modal-salary-session/modal-salary-session.options';

import {toLower} from 'lodash';
import {Subject, takeUntil} from 'rxjs';

@Component({
    templateUrl: './modal-salary-session.component.html'
})
export class ModalSalarySessionComponent implements ModalComponent<number, ModalSalarySessionOptions>, OnInit, OnDestroy {
    @ViewChild('grdSessions', {static: true}) public gridSession?: IwTableComponent<SalaryProcess>;

    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public sessions: SalaryProcess[] = [];
    public columns = this.getColumns();
    public display = true;
    public loading = false;
    public countSelected: SalaryProcess[] = [];
    public _options!: ModalSalarySessionOptions;

    // CONTEXT MENU
    @ViewChild('menu', {static: true}) public menu?: IwContextMenuComponent;
    public contextMenuData?: ContextMenuEvent<SalaryProcess>;
    public contextMenuItems: MenuItem[] = this.buildContextMenu();

    private _ok?: (result: number) => void;
    private _cancel?: (result?: undefined) => void;
    private subscriptions = new Subject();

    constructor(private readonly _eventService: IwEventHubService<SalaryProcessEvent>,
                private readonly _salaryService: SalaryProcessService,
                private readonly _toastService: ToastService,) {
    }

    public get title() {
        return this._options.title;
    }

    public get width() {
        return this._options.width;
    }

    public get height() {
        return this._options.height;
    }

    public get okColor() {
        return this._options.okColor === 'white' ? 'ok' : '';
    }

    // eslint-disable-next-line complexity
    public get atLeastOneSelected(): boolean {
        return this.countSelected?.length! > 0
    }

    public ngOnInit() {
        this.getSalaryProcesses();
        this._eventService.forType(SalaryProcessEvent.REASSIGN)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                const payload = e.payload as ContextMenuEvent<SalaryProcess>;
                this._salaryService.reassign(payload?.selected[0]?.id).subscribe({
                    next: () => this.getSalaryProcesses(),
                    error: () => this._toastService.error('error_reassign_process')
                });
            });

    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressOk.emit();
        this.display = false;
    }

    public onPressOk() {
        if (this._ok) {
            this._ok(this.countSelected[0].id);
        }
        this.pressOk.emit();
        this.display = false;
    }

    public selectCountOption(event: SalaryProcess[]) {
        this.countSelected = event
    }

    public async setData(data: ModalSalarySessionOptions): Promise<void> {
        this._options = data;
    }

    public registerOk(action: (result: number) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (result?: string[]) => void): void {
        this._cancel = action;
    }

    public async onContextMenu(event: ContextMenuEvent<SalaryProcess>) {

        this.contextMenuData = event;
        await this.showContextMenu(event.event);

    }

    private getSalaryProcesses() {
        this._salaryService.listSalaryProcess().subscribe(sessions => this.sessions = sessions);
    }

    private getColumns(): IwGridColumn<SalaryProcess>[] {
        return [
            {
                prop: 'id',
                name: 'id',
                index: 1
            },
            {
                prop: 'owner',
                name: 'owner',
                index: 2
            },
            {
                prop: 'type',
                name: 'type',
                index: 3
            },
            {
                prop: 'salId',
                name: 'period',
                index: 4
            },
            {
                prop: 'version',
                name: 'version',
                index: 5
            }
        ];
    }

    private buildContextMenu() {
        const menu: MenuItem[] = [];
        Object.values(SalaryProcessEvent).forEach((event) => {
            menu.push(
                {
                    label: toLower(event),
                    event: event,
                    contextMenuVisibleMode: 'all'
                },
            );
        });
        return menu;
    }

    private async showContextMenu(event: MouseEvent) {
        if (this.menu) {
            await this.menu.show(event);
        }
    }
}
