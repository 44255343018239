import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Pppar} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-ppopttxt-page3', templateUrl: './tab-ppopttxt-page3.component.html'
})
export class IwTabPpopttxtPage3Component extends BasePartialFormComponent<Pppar> {

    @Input() public isReadonly = true;

    constructor() {
        super();
    }
}
