import {Component, OnInit} from '@angular/core';
import {GedtaService} from '@modules/sam-main/salary/services/gedta.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseStoreFormComponent} from '@sam-base/base';
import {IwStoreService, RestQueryOperation, RestQueryParam} from '@sam-base/core';
import {SagenService} from '@sam-base/core/services/sagen.service';
import * as actions from '@sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@sam-base/core/toast';
import {FormKeys, IwGridColumn} from '@sam-base/models';
import {Gedta} from '@sam-base/models/invoices/gedta';
import {PpacoView} from '@sam-base/models/placement/ppacoview';
import {Saep} from '@sam-base/models/salary/saep';
import {SaepView} from '@sam-base/models/salary/saepview';
import {Sagen} from '@sam-base/models/salary/sagen';

@Component({
    selector: 'iw-ordre-paiement',
    templateUrl: './ordre-paiement.component.html'
})
export class OrdrePaiementComponent extends BaseStoreFormComponent<Gedta> implements OnInit {
    public query: RestQueryParam<Saep, string>[] = [];
    public saepColumns: IwGridColumn<SaepView>[] = [
        {
            prop: 'nom',
            name: 'nom',
            type: 'string'
        },
        {
            prop: 'prenom',
            name: 'prenom',
            type: 'string'
        },
        {
            prop: 'mode',
            name: 'mode',
            type: 'string'
        },
        {
            prop: 'montant',
            name: 'montant',
            type: 'mnt',
            align: 'right'
        },
        {
            prop: 'datePmnt',
            name: 'datePmnt',
            type: 'date'
        },
        {
            prop: 'paid',
            name: 'montant_paye',
            type: 'boolean'
        }
    ];
    public ppacoColumns: IwGridColumn<PpacoView>[] = [
        {
            prop: 'empId',
            name: 'empId',
            type: 'string'
        },
        {
            prop: 'libelle',
            name: 'libelle',
            type: 'string'
        },
        {
            prop: 'acotype',
            name: 'acotype',
            type: 'enum',
            enumPrefix: 'ppaco.acotype'
        },
        {
            prop: 'montant',
            name: 'montant',
            type: 'mnt',
            align: 'right'
        },
        {
            prop: 'datePmnt',
            name: 'datePmnt',
            type: 'date'
        },
        {
            prop: 'acopaid',
            name: 'montant_paye',
            type: 'boolean'
        }
    ];
    protected readonly typeSaepView = SaepView;
    protected readonly typePpacoView = PpacoView;
    private sagen?: Sagen;

    constructor(public store: IwStoreService,
                private readonly _gedtaService: GedtaService,
                private readonly _toastService: ToastService,
                private readonly _sagenService: SagenService,
                private readonly _translateService: TranslateService) {
        super(store);
    }

    public get origine(): string {
        return this.getFormValue('origine');
    }

    public get dtaId(): string {
        return this.getFormValue('dtaId');
    }

    public get canCancelOrder(): boolean {
        return this.getFormValue('annule') !== true && this.sagen?.status === 1;
    }

    public get gridElementTypeToShow(): any {
        return this.origine === 'SA' ? this.typeSaepView : this.typePpacoView;
    }

    public get columnsToShow(): IwGridColumn<PpacoView | SaepView>[] {
        return this.origine === 'SA' ? this.saepColumns : this.ppacoColumns;
    }

    public ngOnInit(): void {
        this.subscribeValueChange('dtaId', dtaId => {
            this.query = [
                {
                    operation: RestQueryOperation.Equals,
                    prop: 'dtaId',
                    value: this.getFormValue('dtaId')
                }];
        });
        this.subscribeValueChange('salId', (salId: string | undefined) => {
            if (!salId) return;
            this._sagenService.getSagenBySalId(salId).subscribe(sagen => {
                this.sagen = sagen;
            });
        })
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public print() {

    }

    public downloadFile() {
        this._gedtaService.getUrlForDocument(this.dtaId).subscribe((url: string) => {
            if (url) {
                window.open(url, '_blank')
            }
        });
    }

    public getSelectedRow($event: any[]) {
    }

    public cancelOrder() {
        this._gedtaService.cancelGedta(this.dtaId).subscribe({
            next: () => {
                this._toastService.success(this._translateService.instant('cancel_ordre_paiement_success'));
            }
        });
    }

    protected getFormControlNames(): FormKeys<Gedta> {
        return [
            'dtaId',
            'salId',
            'mpaId',
            'gMode',
            'dateExec',
            'payId',
            'libelle',
            'origine',
            'nbPay',
            'total',
            'fContent',
            'noOrdre',
            'filErased',
            'annule',
            'userId',
            'annUserId',
            'dateAnnul',
        ];
    }

    protected getValidationType() {
        return Gedta;
    }
}
