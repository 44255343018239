import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {EntityNavigationService, EntitySelectService} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys, ObjectKeyArrayMap, ObjectMap} from '@app/sam-base/models';
import {Ppclimis, Ppemp, Ppgri, Ppind, Ppjf, Ppmis} from '@app/sam-base/models/placement';
import {ParametersService} from '@core/services/parameters.service';
import {lastValueFrom, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {EntitiesToUpdateEnum, EntityUpdateService} from '../../services/entity-update.service';

@Component({
    templateUrl: './ppindsaid.component.html'
})
export class PpindsaidComponent extends BaseStoreFormComponent<Ppind> implements OnInit, OnDestroy {

    public ppemp = Ppemp;
    public isPpjfEnable = false;
    public filterPpemp?: ObjectKeyArrayMap<Ppemp>;
    public message = '';
    public griType?: number;
    @Input() public filter?: ObjectMap<Ppclimis>;
    private entityModifiable = false;
    private readonly _ppgriRestClient: RestEntityClient<Ppgri>;
    private readonly _ppjfRest: RestEntityClient<Ppjf>;
    private subscriptions = new Subject();

    constructor(store: IwStoreService, private _restService: RestApiService,
                private _entityUpdateService: EntityUpdateService,
                private readonly _select: EntitySelectService<Ppclimis>,
                public readonly _store: IwStoreService, private readonly _parameters: ParametersService,
                private readonly _entityNavService: EntityNavigationService) {
        super(store);
        this._ppjfRest = this._restService.getEntityClient(Ppjf);
        this._ppgriRestClient = this._restService.getEntityClient(Ppgri);
        this.setSubscriptions();
    }

    public get empId(): string | undefined {
        return this.getData('empId');
    }

    public get griId(): string | undefined {
        return this.getFormValue('griId');
    }

    public get readonly(): boolean {
        return !this.isWriteMode;
    }

    public setSubscriptions() {
        this.subscribeToGriIdChanges();
        this.subscribeToQteChanges();
        this.subscribeToMntChanges();
    }

    public ngOnInit() {
        if (this.empId) {
            this.setFormValue('empId', this.empId);
        }
        if (this.getFormValue('indId')) {
            this.canUpdateIndemnity(this.getFormValue('indId'));
        }
        this.subscribeValueChange<string>('indId', (id) => {
            this.canUpdateIndemnity(id);
        });
        this.subscribeValueChange<string>('misId', (id) => {
            this.buildMission(id || '');
        });

        this._parameters.getString('actSalId')
            .subscribe((e) => this.setFormValue('salId', e));
        this.setFormValue('indType', '2');
        if (this.isNew) {
            this.setFormValue('qte', 0);
            this.setFormValue('mntemp', 0);
        }
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public resetEdtMisDet() {
        this.setFormValue('misId', undefined);
        this.message = '';
    }

    public isGriType(): boolean {
        return ('145').indexOf(this.griType?.toString() || '') !== -1;
    }

    public searchMis(): void {
        this._select.selectEntity({
            entity: Ppclimis,
            filter: {empId: this.getFormValue('empId')}
        });
        this._store.entitySelect(Ppclimis)
            .pipe(takeUntil(this.subscriptions), filter(s => s.completed))
            .subscribe((s: any) => {
                this.setFormValue('misId', s.selected[0].misId);
                this.buildMission(s.selected[0].misId);
            });
    }

    public setRemarques(gri?: Ppgri) {
        if (gri?.griName && !this.readonly) {
            this.setFormValue('libelle', gri?.griName);
        }
    }

    public openEmp() {
        this._entityNavService
            .navigateToEntityForm(Ppemp, this.getFormValue('empId'));
    }

    protected getValidationType() {
        return Ppind;
    }

    protected getFormControlNames(): FormKeys<Ppind> {
        return [
            'indId',
            'rapId',
            'datasessionid',
            'griId',
            'indDate',
            'lgeneaco',
            'misId',
            'cctId',
            'jfId',
            'dateDe',
            'dateA',
            'qte',
            'mntcli',
            [
                'mntemp',
                new UntypedFormControl(0.00, Validators.pattern('^-?\\s*(?=.*[1-9])\\d*(?:\\.\\d*)?\\s*$'))],
            'totemp',
            'totcli',
            'empId',
            'libelle',
            'salId',
            'indType'];
    }

    // ^-?\s*(?=.*[1-9])\d*(?:\.\d*)?\s*$
    protected validateReadonlyStatus(e: Ppind) {
        return !this.entityModifiable && !!e.indId;
    }

    private buildMission(mis: string | Ppclimis): void {
        if (typeof mis === 'string' && mis !== '') {
            this._restService.getEntityClient(Ppclimis)
                .getById(mis)
                .subscribe((mission: Ppclimis) => {
                    this.getMissionText(mission);
                });
        } else if (mis) {
            this.getMissionText(mis);
        } else {
            this.message = '';
            return;
        }
    }

    private getMissionText(climis: Ppclimis) {
        this._restService.getEntityClient(Ppmis)
            .getById(climis.misId || '')
            .subscribe((mis: Ppmis) => {
                this.message = `${climis.nomclient ?? ''}\
            \nDébut: ${IwDateHelper.dateFormatFromString(mis.datedebut, ' DD-MM-YYYY')}\
            \nTarif: ${mis.clitarif ?? '0'}\
            \nSal. Base: ${mis.salBase ?? '0'}\
            \nSal. Global: ${mis.salGlobal ?? '0'}`;
            });
    }

    private canUpdateIndemnity(indId?: string) {
        if (!indId) {
            return;
        }
        this._entityUpdateService.canUpdateEntity(EntitiesToUpdateEnum.PPIND, indId)
            .subscribe((flag: boolean) => {
                this.entityModifiable = flag;
                this.triggerValidation();
            });
    }

    private subscribeToGriIdChanges() {
        this.formGroup.controls['griId'].valueChanges
            .pipe(takeUntil(this.subscriptions))
            .subscribe(async (val: string | undefined) => {
                this.griType = (await lastValueFrom(this._ppgriRestClient.getById(this.griId || ''))).griType;
                this.isPpjfEnable = val === 'JF';
            });
        this.formGroup.controls['jfId'].valueChanges
            .pipe(takeUntil(this.subscriptions))
            .subscribe(() => this.autoFillRemarque());
    }

    private subscribeToQteChanges() {
        this.formGroup.controls['qte'].valueChanges
            .pipe(takeUntil(this.subscriptions))
            .subscribe((val: number | undefined) => {
                const mntcli: number = this.getFormValue('mntcli');
                const mntemp: number = this.getFormValue('mntemp');
                if (mntcli && val) {
                    this.setFormValue('totcli', (mntcli * val));
                }

                if (mntemp && val) {
                    this.setFormValue('totemp', (mntemp * val));
                }
            });
    }

    private subscribeToMntChanges() {
        this.formGroup.controls['mntemp'].valueChanges
            .pipe(takeUntil(this.subscriptions))
            .subscribe((val: number | undefined) => {
                const qte = this.getFormValue('qte');
                if (qte && val) {
                    this.setFormValue('totemp', (qte * val));
                }
            });

        this.formGroup.controls['mntcli'].valueChanges
            .pipe(takeUntil(this.subscriptions))
            .subscribe((val: number) => {
                const qte = this.getFormValue('qte');
                this.setFormValue('totcli', (qte * val));
            });
    }

    private autoFillRemarque() {
        const jfId = this.getFormValue('jfId');
        if (!jfId) {
            return;
        }
        this._ppjfRest.getById(jfId)
            .subscribe((ppjf: Ppjf) => {
                if (ppjf && ppjf.jflibelle) {
                    this.setFormValue('libelle', ppjf.jflibelle);
                }
            });
    }
}
