import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Pppar} from '@app/sam-base/models/placement';

import {PpopttxtComponent} from '../components/ppopttxt/ppopttxt.component';

export const TEXTES_PARAMETRABLES_DEFAULT_FORM: FormDescription<Pppar> = {
    form: PpopttxtComponent, state: {
        ...buildDefaultState(Pppar),
        diagTitle: 'ppopttxt_form_title',
        width: 520,
        isNew: false,
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: false, showClose: true, showEdit: true, showList: false, showPrint: false, showDelete: false
        },
        editMode: 'read',
        useNavigation: false
    }
};
