<div [formGroup]="formGroup"
     class="row">
    <div class="row col-xs-12">
        <div class="col-xs-9"
             style="height: 400px;">
            <iw-rest-grid #griGrid
                          (selected)="getSelectedRow($event)"
                          (sortInfo)="setSortInfo($event)"
                          [autoFormClick]="false"
                          [customColumns]="columns"
                          [defaultSelected]="true"
                          [hasGridColumnMenu]="false"
                          [itemsPerPage]="1000"
                          [queryStatements]="query"
                          [selectedData]="selectedData"
                          [sortDir]="sortPropDir"
                          [sortProp]="sortProp"
                          [type]="type"></iw-rest-grid>
        </div>

        <div class="col-xs-3">
            <iw-button (press)="createGenre()"
                       [isDisabled]="addMode"
                       [text]="'nouveau' | translate"
                       [tooltip]="'nouveau_tool' | translate"
                       class="iw-button row"
                       id="sidemenu_new"></iw-button>
            <iw-button (press)="editGenre()"
                       *ngIf="!addMode"
                       [text]="'modif' | translate"
                       [tooltip]="'modifier' | translate"
                       class="iw-button row"
                       id="sidemenu_edit"></iw-button>
            <iw-button (press)="saveGenre()"
                       *ngIf="addMode"
                       [isDisabled]="!(addMode && canSave)"
                       [text]="'enregistrer' | translate"
                       [tooltip]="'modifier' | translate"
                       class="iw-button row"
                       id="sidemenu_save"></iw-button>
            <iw-button (press)="deleteGenre()"
                       *ngIf="!addMode"
                       [text]="'supprimer' | translate"
                       [tooltip]="'supprimerAnuller' | translate"
                       class="iw-button row"
                       id="sidemenu_delete"></iw-button>
            <iw-button (press)="cancelGenre()"
                       *ngIf="addMode"
                       [text]="'annuler' | translate"
                       [tooltip]="'supprimerAnuller' | translate"
                       class="iw-button row"
                       id="sidemenu_cancel"></iw-button>
        </div>
    </div>

    <div class="row col-xs-12"
         id="indForm">
        <div class="col-xs-9">
            <div class="row">
                <iw-textfield #txtGri_id
                              [isDisabled]="!addMode || (addMode && editMode)"
                              [labelAlign]="'top'"
                              [label]="'code' | translate"
                              [warningMessage]="'errorMsgGriId' | translate"
                              class="iw-input col-xs-3 no-padding uppercase"
                              formControlName="griId"
                              id="griId"></iw-textfield>
                <iw-textfield #txtGri_Name
                              [isDisabled]="!addMode"
                              [labelAlign]="'top'"
                              [label]="'libelle' | translate"
                              class="iw-input col-xs-9 no-padding"
                              formControlName="griName"
                              id="griLibelle"></iw-textfield>
            </div>
            <div class="row">
                <iw-enum-dropdown #cboType
                                  (valueChange)="setGriTypeValue()"
                                  [inputEnum]="typeEnum"
                                  [isDisabled]="!addMode"
                                  [labelAlign]="'top'"
                                  [label]="'type' | translate"
                                  class="iw-input col-xs-11 no-padding"
                                  formControlName="griType"></iw-enum-dropdown>
                <iw-textfield #txtGri_type
                              [isDisabled]="true"
                              [textAlign]="'center'"
                              [value]="griTypeValue"
                              class="iw-input col-xs-1 no-padding no-label"></iw-textfield>
            </div>
            <div class="row">
                <iw-sags-dropdown #cboGs_id
                                  (selectedChanged)="changedValue($event)"
                                  [isDisabled]="checkGenreSalaireDisabled()"
                                  [labelAlign]="'top'"
                                  [label]="'genreDeSalaire' | translate"
                                  class="iw-input col-xs-10 no-padding"
                                  formControlName="griGsId"
                                  id="cboGs_id"></iw-sags-dropdown>
                <iw-textfield-compte #txtCompte
                                     [isDisabled]="checkCompteDisabled()"
                                     [labelAlign]="'top'"
                                     [label]="'compte' | translate"
                                     class="iw-input col-xs-2 no-padding"
                                     formControlName="griCompte"></iw-textfield-compte>
            </div>
            <div class="row no-margin">
                <iw-checkbox #chkActif
                             [isDisabled]="!addMode"
                             [label]="'indemniteActive' | translate"
                             class="iw-input col-xs-6 no-padding"
                             formControlName="actif"
                             id="actif"></iw-checkbox>
                <iw-checkbox #chkExclstats
                             [isDisabled]="!addMode"
                             [label]="'exclude_from_statistics' | translate"
                             class="iw-input col-xs-6 no-padding"
                             formControlName="exclstat"
                             id="actif"></iw-checkbox>
            </div>
        </div>

        <div class="col-xs-3 margin-top-auto">
            <iw-button (press)="closeDialog()"
                       [text]="'fermer' | translate"
                       class="iw-button"></iw-button>
        </div>
    </div>
</div>
