import { HttpClient } from '@angular/common/http';

import {Component, OnDestroy, OnInit, Type} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwStoreService, ModalService} from '@app/sam-base/core';
import {formatSalId} from '@app/sam-base/core/helpers/parameters.helper';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {SaesService} from '@app/sam-base/core/services/saes.service';
import {SaisdefService} from '@app/sam-base/core/services/saisdef.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ComboboxItem, FormKeys, ModalComponent, Saconj} from '@app/sam-base/models';
import {Sasd4emacode} from '@app/sam-base/models/common/sasd4emacode';
import {Satbl} from '@app/sam-base/models/salary';
import {Saemi} from '@app/sam-base/models/salary/saemi';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {
    IwModalSelectTableComponent
} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.component';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {of, Subject} from 'rxjs';
import {AemaCodes, CorType} from './saemi-is-enums';

@Component({
    templateUrl: './saemi-is.component.html'
})
export class SaemiISComponent extends BaseStoreFormComponent<Saemi> implements OnInit, OnDestroy {

    public motifOptions: ComboboxItem<string>[] = [];
    public optionSalIdCor: ComboboxItem<string>[] = [];
    public emaReasons: ComboboxItem<string>[] = [];
    public readonly listOption: ModalSelectTableOption<ComboboxItem<string>, string>;
    public corType = CorType;
    public aemaCode = AemaCodes;
    public baremes: ComboboxItem<string>[] = [];
    public categorie1Txt = '';
    private emaCodes: Sasd4emacode[] = [];
    private _baseUrl = ' ';
    private subscriptions = new Subject();
    private readonly _restSasdEmacodeClient: RestEntityClient<Sasd4emacode>;

    constructor(store: IwStoreService, private readonly _saisdefService: SaisdefService,
                private readonly _http: HttpClient, private readonly _translate: TranslateService,
                private readonly _saesService: SaesService, private readonly rest: RestApiService,
                private readonly _modalService: ModalService) {
        super(store);
        this._baseUrl = environment.backendURL;
        this._restSasdEmacodeClient = rest.getEntityClient(Sasd4emacode);
        this.listOption = {
            columns: [{prop: 'name'}],
            map: (e: ComboboxItem<string>) => e.value || '',
            selection: 'single'
        };
    }

    public get empId(): string {
        return this.getData('empId', true) || '';
    }

    public get baremeVal(): string {
        return this.getFormValue('barc3')
            ?.substring(0, 2);
    }

    public get emaCode(): string {
        return this.getFormValue('emaCode') || '';
    }

    public get athee(): boolean {
        return this.getFormValue('barc3')
            ?.charAt(this.getFormValue('barc3').length - 1) === 'N';
    }

    public get cantonEnabled(): boolean {
        return ['1',
            '3',
            '5',
            '9'].indexOf(this.getFormValue('emiType')) !== -1;
    }

    public get atheeEnabled(): boolean {
        return ['1',
            '2',
            '3',
            '4',
            '5'].indexOf(this.getFormValue('emiType')) !== -1;
    }

    public get agenceEnabled(): boolean {
        return ['1',
            '3',
            '5',
            '9'].indexOf(this.getFormValue('emiType')) !== -1;
    }

    public get genreSalaireEnabled(): boolean {
        return ['4',
            '5'].indexOf(this.getFormValue('emiType')) !== -1;
    }

    public get mntSalEnabled(): boolean {
        return ['2',
            '3',
            '4'].indexOf(this.getFormValue('emiType')) !== -1;
    }

    public get tauxEnabled(): boolean {
        return ['1',
            '2',
            '3',
            '4',
            '6'].indexOf(this.getFormValue('emiType')) !== -1;
    }

    public get emaCodeEnabled(): boolean {
        return ['4',
            '5',
            '9'].indexOf(this.getFormValue('emiType')) !== -1 && !this.payAfterLeave;
    }

    public get emaReasonEnabled(): boolean {
        return ['2',
            '3',
            '4',
            '9'].indexOf(this.getFormValue('emiType')) !== -1 && !this.payAfterLeave;
    }

    public get motif(): string {
        const emaReason = this.getFormValue('emaReason');
        // eslint-disable-next-line max-len
        const title = this.emaCodes?.find((e) => e.emaCode === this.emaCode && e.emaReason === emaReason)?.titre || 'combo_no_option';
        return this._translate.instant(title);
    }

    public get payAfterLeave(): boolean {
        return this.getFormValue('payAfterLeave');
    }

    public get maxEmaDate(): Date | undefined {
        const emaCode = this.getFormValue('emaCode');
        if (emaCode !== '1') {
            return undefined;
        }
        const dateString = this.getFormValue('salId') || '';
        const year = Number(dateString.substring(0, 4));
        const month = Number(dateString.substring(4, 6)) - 1; // Months are zero-based (0-11)
        // Technic to get the last day of the previous month (with the index 0 for the day)
        // More info : https://stackoverflow.com/a/43182841
        return new Date(year, month + 1, 0);
    }

    public ngOnInit() {
        this.subscribeValueChange('cantonimp', (val?: string) => {
            this.getIsBaremesByCanton(val ?? '');
        });
        this.getOptions();
        this.loadEmaCodes();
        this.getSalIdCorOptions();
        if (this.isNew) {
            this.setNewSaemiValues();
            this.subscribeValueChange('salIdCor', (val?: string) => {
                this.getBaseSaemi();
            });
            this.subscribeValueChange('emiType', (val?: string) => {
                this.getBaseSaemi();
            });
        }
        this.subscribeValueChange('emaCode', (val?: string) => {
            this.updateEmaReasons();
        });
        this.setSaemiImpValidators();
        this.subscribeValueChange('mntSal', (val?: string) => this.calcMntDed());
        this.subscribeValueChange('taux', (val?: string) => this.calcMntDed());
        this.subscribeValueChange('emaReason', (val?: string) => console.log('emaReason changed', val));
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public getOptions(tableNo: string = '05', recType: number = 0) {
        return this._http
            .get<Satbl>(this._baseUrl + `satbl/` + tableNo + '/' + recType)
            .subscribe((res: any) => {
                this.motifOptions.push({
                    name: ' ',
                    value: ''
                });
                for (const k of Object.keys(res)) {
                    this.motifOptions.push({
                        name: res[k].titre,
                        value: res[k].elemId
                    });
                }
            });
    }

    public motifChange(): void {
        this.categorie1Txt = this.getFormValue('categorie1');
    }

    public fieldDisabled(emiTypes: string): boolean {
        const emiType = this.getFormValue('emiType');
        return (emiTypes.indexOf(emiType) !== -1);
    }

    public changeCanton(canton: string): void {
        this.setFormValue('esparent', `IS${canton ?? ''}:${this.getFormValue('barc3')}:1`);
    }

    public changeBareme(barem: string): void {
        const canton = this.getFormValue('cantonimp');
        this.setFormValue('barc3', `${barem}${this.athee ? 'N' : 'Y'}`);
        this.setFormValue('esparent', `IS${canton ?? ''}:${this.getFormValue('barc3')}:1`);
    }

    public changeAthee(isAthee: boolean): void {
        this.setFormValue('barc3', `${this.baremeVal}${isAthee ? 'N' : 'Y'}`);
        const canton = this.getFormValue('cantonimp');
        this.setFormValue('esparent', `IS${canton ?? ''}:${this.getFormValue('barc3')}:1`);
    }

    public onPayAfterLeaveChange(isPayAfterLeave: boolean): void {
        if (isPayAfterLeave) {
            this.setFormValue('emaReason', '');
            this.setFormValue('emaDate', undefined);
            this.getFormControl('emaReason')
                ?.removeValidators([Validators.required]);
        } else {
            this.getFormControl('emaReason')
                ?.setValidators([Validators.required]);
        }
    }

    public saveEntry() {
        this.setFormValue('emiGenre', 'IMP');
        this.saveChanges();
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public async onBtnCodeEmaReasonClick() {
        if (!this.emaReasons) {
            return;
        }
        // eslint-disable-next-line max-len
        const comp: Type<ModalComponent<string[], ComboboxItem<string>[]>> = <any>IwModalSelectTableComponent;
        try {
            this.listOption.fetchAction = () => of(this.emaReasons);
            const data = await this._modalService
                .showModal<string[], ComboboxItem<string>[]>(comp, <any>this.listOption);
            this.setFormValue('emaReason', data[0]);
        } catch {
        }
    }

    protected getFormControlNames(): FormKeys<Saemi> {
        return ['emiType',
            'corType',
            'salId',
            'emiId',
            'salIdCor',
            'cantonOld',
            'barc3Old',
            'mntsalOld',
            'mntdedOld',
            'tauxOld',
            'esparOld',
            'cat1Old',
            'cantonimp',
            'categorie1',
            'barc3',
            'gsId',
            'mntSal',
            'mntDed',
            'taux',
            'emaCode',
            'emaReason',
            'empId',
            'emaDate',
            'emiGenre',
            'modecreat',
            'esparent',
            'payAfterLeave'];
    }

    protected getValidationType() {
        return Saemi;
    }

    protected validateFields(e: Saconj): boolean {
        return super.validateFields(e);
    }

    private getSalIdCorOptions() {
        this._saesService.getComboSalIdOptions()
            .subscribe(options => {

                this.optionSalIdCor.push({
                    name: ' ',
                    value: ''
                });
                options.forEach((option) => {
                    this.optionSalIdCor.push({
                        name: formatSalId(option),
                        value: option
                    });
                });
            });
    }

    private loadEmaCodes() {
        this._restSasdEmacodeClient.getRefData()
            .subscribe((data: Sasd4emacode[]) => {
                this.emaCodes = data;
                this.updateEmaReasons();
            });
    }

    private getIsBaremesByCanton(txCode: string) {
        this.baremes = [];
        this._saisdefService.getIsBaremesByCanton(txCode)
            .subscribe((data: string) => {
                if (data) {
                    const dataInfo = data.split(',');
                    this.baremes.push({
                        name: ' ',
                        value: ''
                    });
                    dataInfo.forEach((element) => {
                        this.baremes.push({
                            name: element,
                            value: element
                        });
                    });
                }
            });
    }

    private setNewSaemiValues(): void {
        this.setFormValue('empId', this.empId);
        this.setFormValue('salId', this.getData('salId', true) || '');
        this.setFormValue('emiGenre', 'IMP');
        this.setFormValue('emiType', '2');
        this.setFormValue('emaCode', '2');
        this.setFormValue('modecreat', 'M');
    }

    private getBaseSaemi() {
        const empId = this.getFormValue('empId');
        const salId = this.getFormValue('salId');
        const salIdCor = this.getFormValue('salIdCor');
        const emiType = this.getFormValue('emiType');

        if (empId && salId && salIdCor && emiType) {
            this._http
                // eslint-disable-next-line max-len
                .get<Saemi>(this._baseUrl + `saemi/getSaemiBase/${empId}?salId=${salId}&salIdCor=${salIdCor}&emiType=${emiType}`)
                .subscribe((res: Saemi) => {
                    this.fillFormData(res);
                    this.setFormValue('emiGenre', 'IMP');
                });
        }
    }

    private calcMntDed() {
        const mntSal = this.getFormValue('mntSal');
        const taux = this.getFormValue('taux');
        if (mntSal && taux) {
            this.setFormValue('mntDed', (mntSal * taux / 100).toFixed(2));
        }
    }

    private setSaemiImpValidators() {
        this.getFormControl('corType')
            ?.setValidators([Validators.required]);
        this.getFormControl('emaCode')
            ?.setValidators([Validators.required]);
        this.getFormControl('emaReason')
            ?.setValidators([Validators.required]);
        this.getFormControl('salIdCor')
            ?.setValidators([Validators.required]);
    }

    private updateEmaReasons() {
        const emaCode = this.getFormValue('emaCode');
        this.emaReasons = this.emaCodes
            .filter((e) => e.emaCode === emaCode)
            .map((e) => ({
                name: this._translate.instant(e.titre || ''),
                value: e.emaReason || ''
            }));
        // Add at the first element of emaReasons the empty value
        this.emaReasons.unshift({
            name: this._translate.instant('combo_no_option'),
            value: ''
        });
        console.log('this.emaCodes', this.emaCodes);
    }
}
