import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {
    EmployeeCreationModeComponent
} from '../components/employee/employee-creation-mode/employee-creation-mode.component';
import {EmployeeCreationMode} from '../components/employee/employee-creation-mode/employee-creation-mode.model';
import {PpempCandidateNewComponent} from '../components/employee/ppemp-candidate-new/ppemp-candidate-new.component';
import {EmployeeCandidateNew} from '../components/employee/ppemp-candidate-new/ppemp-candidate-new.model';

export const PPEMP_CREATE_DEFAULT_FORM: FormDescription<EmployeeCreationMode> = {
    form: EmployeeCreationModeComponent, state: {
        ...buildDefaultState(EmployeeCreationMode), diagModal: true, diagTitle: 'ppemp_create_title', width: 200
    }
};

export const PPEMP_CANDIDATE_CREATE_FORM: FormDescription<EmployeeCandidateNew> = {
    form: PpempCandidateNewComponent, state: {
        ...buildDefaultState(EmployeeCandidateNew), diagModal: true, diagTitle: 'ppemp_candidate_creation', width: 500
    }
};
