<div class="flex flex-v flex-evenly">
    <div class="row margin-top-10 flex flex-h flex-between">
        <iw-button #Standardbutton2
                   (press)="openSendSMSForm()"
                   *iwAccessGroups="employeeCommunicationRoles"
                   [isDisabled]="!cansms"
                   [tooltip]="'envoyerSms' | translate"
                   class="iw-button no-padding"
                   iconClass="fas fa-comment-dots"
                   id="sms_button"></iw-button>
        <iw-button #btnSendMail
                   (press)="openEmailForm()"
                   *iwAccessGroups="employeeCommunicationRoles"
                   class="iw-button no-padding"
                   iconClass="fas fa-envelope"
                   id="mail_button"></iw-button>
    </div>
    <div class="row margin-top-10 flex flex-h flex-gap">

        <iw-button #btnOpenClose
                   (press)="openCloseEmployeeDialog()"
                   *iwAccessGroups="employeeMgtAccessRoles"
                   [text]="'oF' | translate"
                   [tooltip]="'open_close_emp' | translate"
                   class="btn-block "
                   id="btnOpenClose"></iw-button>

        <ng-container *iwAccessGroups="employeeMgtAccessRoles">
            <iw-button #btnBlockEmp
                       (press)="toggleBlockEmployee()"
                       *ngIf="ppemp && ppemp.cempstatus !== '8'"
                       [isDisabled]="!ppemp.conId"
                       [text]="'block_entity' | translate"
                       [tooltip]="'block_entity' | translate"
                       class="btn-block"
                       id="blocked"></iw-button>
            <iw-button #btnUnblockCli
                       (press)="toggleBlockEmployee()"
                       *ngIf="ppemp && ppemp.cempstatus === '8'"
                       [isDisabled]="!ppemp.conId"
                       [text]="'unblock_entity' | translate"
                       class="btn-block"
                       id="btnUnblockCli"></iw-button>
        </ng-container>

        <iw-button #btnMis
                   (press)="openMissions()"
                   *iwAccessGroups="employeeMissionButtonRoles"
                   [text]="'missions' | translate"
                   class="btn-block"></iw-button>

        <iw-button #btnRap
                   (press)="openRapports()"
                   *iwAccessGroups="employeeMissionButtonRoles"
                   [text]="'rapports' | translate"
                   class="btn-block"></iw-button>

        <iw-button #btnInd
                   (press)="openIndemnites()"
                   *iwAccessGroups="employeeMissionButtonRoles"
                   [text]="'indemnites' | translate"
                   class="btn-block"></iw-button>

        <iw-button #btnAco
                   (press)="openAcomptes()"
                   *iwAccessGroups="employeeMissionButtonRoles"
                   [text]="'acomptes' | translate"
                   class="btn-block"></iw-button>


        <iw-button #btnEmpHeu
                   (press)="openHeuresT()"
                   *iwAccessGroups="employeeMissionButtonRoles"
                   [text]="'heuresTravaillees' | translate"
                   class="btn-block"></iw-button>

        <iw-button #Standardbutton7
                   (press)="openPlanning()"
                   *iwAccessGroups="employeeMissionButtonRoles"
                   [text]="'planning' | translate"
                   class="btn-block padding-bottom-5"></iw-button>

        <iw-button #btnStats
                   (press)="syncEmp()"
                   [text]="'syncEmp' | translate"
                   *iwAccessGroups="salaryUserRoles"
                   class="btn-block padding-bottom-5"></iw-button>
    </div>
</div>
<iw-planning (closeDialogEvent)=closeDialogPlanning()
             *ngIf="showPlanning"
             [result]="[ppemp]"></iw-planning>
