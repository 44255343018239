import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {GeperComponent} from '@modules/sam-main/salary/components/geper/geper.component';
import {Geper} from '@sam-base/models/invoices/geper';

export const GEPER_DEFAULT_FORM: FormDescription<Geper> = {
    form: GeperComponent,
    state: {
        ...buildDefaultState(Geper),
        diagTitle: 'title_periode_invoice',
        width: 600,
        editMode: 'read'
    }
};
