<form [formGroup]="formGroup" class="row">
    <div class=" flex col-xs-12 padding-top-15">
        <iw-textarea #txtnotes [height]="250" [isDisabled]="isReadonly" [noLabel]="true" [value]="edtSaemi"
                     class="iw-textarea col-xs-11"></iw-textarea>
    </div>
    <div class="flex col-xs-12 padding-top-20">
        <iw-button #btnxml (press)="openEmaAVS()" [isDisabled]="isReadonly" [text]="'emaAVS' | translate"
                   class="iw-button btn-block col-xs-2"></iw-button>
        <iw-button #btnxml (press)="openEmaIS()" [isDisabled]="isReadonly" [text]="'emaIS' | translate"
                   class="iw-button btn-block col-xs-2"></iw-button>
        <iw-button #btnxml (press)="openAuto()" [isDisabled]="isReadonly" [text]="'outAuto' | translate"
                   class="iw-button btn-block col-xs-2" style="align-self: self-end"></iw-button>
    </div>
</form>
