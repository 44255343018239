export enum Degreeofrel {
    inconnu = 'inconnu',
    indetermine = 'indetermine',
    epouse_du_chef_d_entreprise = 'epouse_du_chef_d_entreprise',
    epoux_de_la_cheffe_d_entreprise = 'epoux_de_la_cheffe_d_entreprise',
    parent_consanguin_du_chef_d_entreprise = 'parent_consanguin_du_chef_d_entreprise',
    frere_ou_soeur_du_chef_d_entreprise = 'frere_ou_soeur_du_chef_d_entreprise',
    enfant_recueilli_par_le_chef_d_entreprise = 'enfant_recueilli_par_le_chef_d_entreprise'
}

export enum PayType {
    monthly = 'paytype_monthly', hourly = 'paytype_hourly', noTimeConstraint = 'paytype_notimeconstraint'
}

export enum SdsI1 {
    indefiniteSalaryMth = '01',
    indefiniteSalaryMthAWT = '02',
    fixedSalaryMth = '05',
    apprentice = '08',
    internshipContract = '09'
}

export enum SdsI2 {
    indefiniteSalaryHrs = '03', fixedSalaryHrs = '06',
}

export enum SdsI3 {
    indefiniteSalaryNoTimeConstraint = '04', fixedSalaryNoTimeConstraint = '07', administrativeBoard = '00' // Todo change to real value once jreuse has added it
}
