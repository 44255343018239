import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Gepar} from '@sam-base/models/invoices';

export const parametresGenerauxFacturationTabsFormControls: TabFormControl<Gepar>[] = [
    {
        title: 'Facturation',
        formControls: [
            'nomSoc1',
            'tvaCode',
            'tvaTaux',
            'tvaNo',
            'tvaNoIde',
            'tvaperiode',
            'tvaType',
            'defMpaId',
            'escMpaId',
            'letMpaId',
            'paicondId',
            'autofacNo',
            'facNo',
            'pfxfacno',
            'txmaxesc1',
            'txmaxesc2']
    },
    {
        title: 'Compta',
        formControls: [
            'cptdeb',
            'cptrab',
            'cptfrais',
            'cpttva',
            'cptrist',
            'cptprvrist',
            'cptrist1',
            'lprvrist']
    },
    {
        title: 'Formats',
        formControls: [
            'facfrxfa',
            'facfrxeml',
            'facfrxpt',
            'facfrxpf',
            'ncfrx',
            'facfrxyb',
            'rplfrx',
            'smtpConfigId',
            'emailInvoice']
    },
    {
        title: 'BVR/QR/Factors',
        formControls: [
            'bvractif',
            'qractif',
            'bvrnobnk',
            'bvrnocli',
            'bvrfileext',
            'bvrMpaId',
            'bvrrefdef',
            'bvrbydate',
            'factors',
            'factorsmod',
            'ftrMpaId',
            'ftrclimode']
    }];
