import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';

import {Satbl} from '@app/sam-base/models/salary/satbl';
import {TranslateService} from '@ngx-translate/core';
import {ComboboxItem} from '@sam-base/models';
import {Sasdstbl} from '@sam-base/models/common/sasdstbl';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable()
export class SatblService extends RestClient<any> {

    constructor(_http: HttpClient, private readonly _translate: TranslateService) {
        super(_http);
    }

    public getSatblOptions(tableNo: string, recType: number): Observable<Satbl[]> {
        return this._http
            .get<Satbl[]>(`${environment.backendURL}satbl/${tableNo}/${recType}`);
    }

    public getSasdstblOptions(option: string): Observable<Sasdstbl[]> {
        return this._http
            .get<Sasdstbl[]>(`${environment.backendURL}sasdstbl/${option}`);
    }

    public populateComboboxItemForOption(option: string, optionType: ComboboxItem<string>[]) {
        return this.getSasdstblOptions(option)
            .subscribe((res: any) => {
                // Add undefined option
                optionType.push({
                    name: this._translate.instant('combo_no_option'),
                    value: ''
                });
                for (const k of Object.keys(res)) {
                    optionType.push({
                        name: res[k].titre,
                        value: res[k].elemId
                    });
                }
            });
    }

}
