<div class="row">
    <div class="col-xs-12">
        <div style="height: 200px;">
            <iw-rest-grid #institutionsGrid (rowDoubleClick)="onRowDoubleClick($event)" [autoFormClick]="false"
                          [hasGridColumnMenu]="false" [hasSortIcon]="false" [queryStatements]="query" [type]="type"
                          id="institutionsGrid"></iw-rest-grid>
        </div>
    </div>
</div>

