<div class="row">
    <div class="col-xs-10" style="height: 300px;">
        <iw-rest-grid #ppquaConfigGrid (selected)="getSelectedRow($event)" (sortInfo)="setSortInfo($event)"
                      [autoFormClick]="false" [customColumns]="columns" [defaultSelected]="true" [hasFilter]="true"
                      [hasGridColumnMenu]="false" [hasSortIcon]="true" [queryStatements]="query"
                      [queryStatements]="query" [selectedData]="selectedData" [sortDir]="sortPropDir"
                      [sortProp]="sortProp" [type]="type"></iw-rest-grid>
    </div>

    <div *ngIf="!suvaPeActive" class="col-xs-2 flex flex-v flex-evenly">
        <iw-button (press)="createNewEntry()" [isDisabled]="!isReadonly" [text]="'sidemenu_new' | translate"
                   id="sidemenu_new" name="nouveau"></iw-button>
        <iw-button (press)="modifyEntry()" [isDisabled]="!isReadonly" [text]="'sidemenu_edit' | translate"
                   id="sidemenu_edit"></iw-button>
        <iw-button (press)="removeEntry()" [isDisabled]="!isReadonly" [text]="'sidemenu_delete' | translate"
                   id="sidemenu_delete"></iw-button>
        <iw-button (press)="saveEntry()" [isDisabled]="isReadonly" [text]="'sidemenu_save' | translate"
                   id="sidemenu_save" name="save"></iw-button>
        <iw-button (press)="cancelEditionMode()" [isDisabled]="isReadonly" [text]="'sidemenu_cancel' | translate"
                   id="sidemenu_cancel"></iw-button>
    </div>

</div>

<form [formGroup]="formGroup">
    <div class="row">
        <iw-textfield #txtQuaId [isDisabled]="true" [label]="'id' | translate" class="iw-input col-xs-2 margin-bottom-5"
                      formControlName="quaId"></iw-textfield>
        <iw-textfield #txtQualif [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'profession' | translate"
                      class="iw-input col-xs-8" formControlName="qualif" id="qualif"></iw-textfield>
    </div>
    <div *ngIf="suvaPeActive" class="row">
        <iw-textfield #txtQuaId [isDisabled]="true" [label]="'Genre ISCO' | translate"
                      class="iw-input col-xs-7 margin-bottom-5" formControlName="iscoDescriptionFr"></iw-textfield>
        <div class="col-xs-1"></div>
        <iw-textfield #txtPa [isDisabled]="true" [label]="'Partie entreprise' | translate" class="iw-input col-xs-2"
                      formControlName="pe" id="pa"></iw-textfield>
    </div>
</form>
<div class="row flex flex-hr">
    <iw-button #Closebutton (press)="closeDialog()" [text]="'fermer' | translate"
               class="iw-button col-xs-2 end-xs"></iw-button>
</div>
