import {Observable} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {AttestEmp} from '@sam-base/models/placement/attestEmp';

@Injectable()
export class PpacattestService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }


    public getCalculation(empId: string): Observable<AttestEmp> {
        return this.GET<AttestEmp>(undefined, `/ppacattest/calculate/${empId}`);
    }

    public getPrint(empId: string, type: string, lang: string): Observable<Blob> {
        return this._http.get(this._baseUrl + `ppacattest/pdf/${empId}/${type}/${lang}`, {responseType: 'blob'});
    }
}
