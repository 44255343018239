import {TabFormControl} from '@app/sam-base/core/form-handler/models/form-description.model';
import {Saems} from '@app/sam-base/models/salary/saems';

export const ficheSalaireTabsFormControls: TabFormControl<Saems>[] = [
    {
        formControls: [
            'empId',
            'categorie1',
            'mntpaid',
            'calculated',
            'mntnap',
            'mntrep',
            'salId',
            'ficsalId']
    },
    {
        title: 'Fiche',
        formControls: [
            'salId',
            'cantontrav']
    },
    {
        title: 'Paiement',
        formControls: [
            'bloque',
            'salId',
            'id']
    },
    {
        title: 'Informations',
        formControls: [
            'nbjTravTot',
            'nbjTravCh',
            'cantonhab',
            'cantontrav',
            'cantonimp',
            'categorie1',
            'categorie2',
            'categorie3',
            'params',
            'ppparams',
            'dateDe',
            'dateA',
            'dateCreat',
            'userCreat',
            'dateModif',
            'userModif',
            'dateCalc',
            'nbofcalc',
            'datePublished',
            'nbOfPublished',
            'datePrnt',
            'nbofprint',
            'dateMail',
            'nbofmail',
            'dateBs']
    },
    {
        title: 'Analytique',
        formControls: []
    },
    {
        title: 'notes',
        formControls: [
            'notesbs',
            'notesint',
            'salId']
    },
    {
        title: 'Missions',
        formControls: []
    },
    {
        title: 'swissdec_title',
        formControls: []
    }];
