import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Ppcde} from '@app/sam-base/models/placement';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const cdetempoTabsFormControls: TabFormControl<Ppcde>[] = [{
    formControls: ['cdeId', 'cdestatus', 'cliId', 'datedebut', 'datefin', 'horaire', 'webData', 'region', 'cliattId']
}, {
    title: 'general',
    formControls: ['dateCde', 'refinterne', 'refclient', 'nogestion', 'ageId', 'secId', 'conId', 'lieu1', 'lieu2', 'lieu3', 'misNpa', 'misLieu', 'dureetype', 'duree', 'service', 'resp', 'tel', 'fax', 'natel', 'email', 'nbMis', 'nbEmp', 'satisfaite', 'motifSat']
}, {
    title: 'autreTab',
    formControls: ['cliadrId', 'attn', 'ccfrx', 'cmfrx', 'codecna', 'frais', 'chantier', 'forcechant', 'freezchant', 'factbyrap', 'clitarif', 'heurequipe', 'mnteqemp', 'mnteqcli']
}, {
    title: 'descremarques', formControls: ['note']
}, {
    title: 'matching', formControls: ['profession', 'needSummary', 'qualif', 'qualifDescription']
}, {title: 'missions', formControls: []}, {title: 'suivi', formControls: []}];
