import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Operation, OperationDetail, OperationType} from '@modules/sam-main/admin/models/operation.model';
import {environment} from '@root/environments/environment';
import {Observable} from 'rxjs';


@Injectable({providedIn: 'root'})
export class OperationService {


    constructor(private readonly _http: HttpClient) {
    }

    public gelAll(): Observable<Operation[]> {
        return this._http.get<Operation[]>(environment.backendURL + `operation`);
    }

    public getOperationDetails(operationId: number): Observable<OperationDetail> {
        return this._http.get<OperationDetail>(environment.backendURL + `operation/${operationId}`);
    }

    public createOperation(operationType: OperationType, entityIds: string[] | number[]): Observable<OperationDetail> {
        return this._http.post<OperationDetail>(environment.backendURL + `operation/create`, {
            operationType,
            entityIds
        });
    }

    public submitOperation(operationDetail: OperationDetail): Observable<OperationDetail> {
        return this._http.post<OperationDetail>(environment.backendURL + `operation/submit`, operationDetail);
    }

}
