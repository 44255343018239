<form [formGroup]="formGroup"
      class="row">
    <div class="col-xs-2 col-no-padding">
        <iw-textfield #txtSui_id
                      [isDisabled]="true"
                      class="col-xs-2"
                      formControlName="suiId"></iw-textfield>
    </div>
    <div class="col-no-padding"
         style="height: 200px; width: 96%;">
        <iw-rest-grid #olvClisui
                      (dataChanged)="clearSortDir()"
                      (selected)="getSelectedRow($event)"
                      [autoFormClick]="false"
                      [customColumns]="columns"
                      [defaultSelected]="true"
                      [defaultSortDir]="'desc'"
                      [defaultSort]="'dateaction'"
                      [hasGridColumnMenu]="false"
                      [hasSortIcon]="true"
                      [innerWidth]="tableWidth"
                      [queryStatements]="query"
                      [refreshType]="refreshType"
                      [type]="type"
                      class="suivi-grid"></iw-rest-grid>
    </div>

    <div class="flex flex-v flex-between">
        <div class="flex flex-v margin-left-5"
             style="height: calc(4 * 25px);">
            <iw-button #btnAddAct
                       (press)="addSuiviEmployee()"
                       [isDisabled]="!entityId"
                       [tooltip]="'nouvelleAction' | translate"
                       *iwAccessGroups="employeeMgtAccessRoles"
                       class="margin-bottom-5"
                       iconClass="fas fa-file"
                       id="btnAddAct"></iw-button>

            <iw-button #btnModAct
                       (press)="editSuiviEmployee()"
                       [isDisabled]="!suiId"
                       [tooltip]="'modifierAction' | translate"
                       *iwAccessGroups="employeeMgtAccessRoles"
                       class="margin-bottom-5"
                       iconClass="fas fa-pencil-alt"></iw-button>

            <iw-button #btnDelAct
                       (press)="deleteSuiviEmployee()"
                       [isDisabled]="!suiId || isReadonly"
                       *iwAccessGroups="employeeMgtAccessRoles"
                       [tooltip]="'supprimerAction' | translate"
                       iconClass="fas fa-trash"></iw-button>
        </div>
    </div>

    <div class="col-xs-12 padding-top-5 no-padding">
        <iw-textarea #edtClisuiNotes
                     [height]="150"
                     [isDisabled]="true"
                     [value]="suiviDescription"
                     class="iw-textarea col-xs-12"></iw-textarea>
    </div>
</form>
