import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@sam-base/core/rest-api/core';
import {SamUser} from '@sam-base/models/admin/sam-user';
import {Team} from '@sam-base/models/admin/team.model';
import {Observable} from 'rxjs';

@Injectable()
export class TeamService extends RestClient<Team> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getAllTeams(): Observable<Team[]> {
        return this._http.get<Team[]>(`/api/team`);
    }

    public getTeamMembers(teamId: number): Observable<SamUser[]> {
        return this._http.get<SamUser[]>(`/api/team/${teamId}/members`);
    }
}
