export enum Modefactu {
    une_fature = 1,
    employe,
    mois,
    rapport,
    chantier,
    chantier_employe,
    semaine,
    semaine_chantier,
    rapport_force,
    semaine_employe,
    employe_mois,
    manuel,
    semaine_chantier_employe,
    mission,
    ref_client,
    employe_ref,
}

export enum Facnpmode {
    nom_prenom = 1, nom_prenom_profession, nom_prenom_travail,
}

export enum TextSupMode {
    duree_horaire = 1,
    detail_jours_travailles,
    salaire_coeff = 3,
    qualif_salaire_coeff,
    empid_salaire_coeff,
    empid_service,
    qualif_coeff,
    qualif,
    genretrav
}

export enum Clitarifmd {
    h_supp_global = 1,
    h_supp_base,
    marge_constant,
    selon_taux_secteur,
    coeff_constant,
    autre_coeff_tarif,
    autre_coeff_salaire,
    autre_coeff_salaire_base,
    autre_coeff_suppl,
    autre_coeff_b13,
    autre_coeff_b13jf,
}

export enum Hpaufacmod {
    separe = 1, groupe, separe_coeff_constant, separe_tarif,
}

export enum Hpausaimod {
    inclure = 1, adjouter,
}

export enum Shsupmode {
    base = 1, bv, b13, bv13, bjf, bjfv, bjf13, global, cctls_base, cctls_misson,
}

export enum Langue {
    francais = 1, english, deutsch, italiano, espagnol,
}

export enum Formejuri {
    societe_anonyme = 'A',
    raison_sociale = 'B',
    snc = 'C',
    raison_individuelle = 'E',
    sarl = 'G',
    inscrit = 'H',
    societe_commanditee = 'L',
    cooperative = 'O',
    prive = 'P',
    corporation_publique = 'S',
    inconnu = 'U',
    association = 'V',
}
