import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Saep} from '@app/sam-base/models/salary/saep';
import {SaepView} from '@sam-base/models/salary/saepview';
import {Observable, Subject} from 'rxjs';
import {RestClient} from '../rest-api/core';

export interface SaepSubscription {
    uuid: string;
    saved: boolean;
}

@Injectable()
export class SaepService extends RestClient<any> {

    public saepSavedSub: Subject<SaepSubscription> = new Subject();

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public createNewEntry(saep: Saep): Observable<Saep> {
        return this._http.post<Saep>(this._baseUrl + `saep`, saep);
    }

    public modifyEntry(epId: string, saep: Saep): Observable<Saep> {
        return this._http.put(this._baseUrl + `saep/${epId}`, saep);
    }

    public getEntry(epId: string): Observable<Saep> {
        return this._http.get(this._baseUrl + `saep/${epId}`);
    }

    public deleteEntry(epId: string): Observable<Saep> {
        return this._http.delete(this._baseUrl + `saep/${epId}`);
    }

    public getAvailableSaepToPay(salId: string): Observable<SaepView[]> {
        return this._http.get<SaepView[]>(this._baseUrl + `paiements/pay/${salId}`);
    }
}
