<div class="row">
    <iw-button (press)="calculate()"
               [isDisabled]="!canCalculate"
               [text]="'period_button_calculate' | translate"
               *iwAccessGroups="SALAIRE_RW_ROLES"
               class="btn-block"></iw-button>
</div>
<div class="row">
    <iw-button (press)="download()"
               [iconClass]="'fas fa-print'"
               [isDisabled]="!canDownload"
               *iwAccessGroups="SALAIRE_PRINT_ROLES"
               class="btn-block"></iw-button>
</div>
<div class="row">
    <iw-button (press)="publish()"
               [isDisabled]="!canPublish"
               *iwAccessGroups="SALAIRE_PRINT_ROLES"
               [text]="'fiche_sal_button_publish' | translate"
               class="btn-block"></iw-button>
</div>
<div class="row">
    <iw-button (press)="sendByMail()"
               [isDisabled]="!canSendByMail"
               *iwAccessGroups="SALAIRE_COMMUNICATION_ROLES"
               class="btn-block"
               iconClass="fas fa-envelope"></iw-button>
</div>

