{
  "name": "sam-placemanent-app",
  "version": "2.0.6-12092024-0924",
  "scripts": {
    "ng": "ng",
    "build": "ng build --configuration production",
    "local-dev": "ng run iw-sam-app:serve-local-dev --port=4200 --open",
    "local-staging": "ng run iw-sam-app:serve-local-staging --port=4200 --open",
    "local-prod": "ng run iw-sam-app:serve-local-prod --port=4200 --open",
    "remote-dev": "ng run iw-sam-app:serve-remote-dev --port=4200 --open",
    "i18n": "node ../scripts/organize-translations.js && bash ../scripts/i18nsort.sh",
    "i18n-extract": "bash ../scripts/i18n.sh",
    "addi18n": "node scripts/add-translation.js",
    "build-dev": "ng build",
    "build-stats": "rm -fr build && ng build --configuration production --progress --statsJson",
    "build-docs": "compodoc -c .compodocrc.json -p ./src/tsconfig.app.json",
    "lint": "ng lint",
    "e2e": "npx cypress run --config baseUrl=https://dev-reference.dev.sam-placement.ch/ --env keycloak_redirect_uri=https://dev-reference.dev.sam-placement.ch/",
    "clean": "rimraf node_modules doc dist build",
    "test": "ng test --source-map=false",
    "test:dev": "concurrently 'yarn dev' 'cypress open'",
    "test:local": "concurrently 'yarn local' 'cypress open'",
    "test:ci": "ng run iw-sam-app:e2e-ci",
    "test:ci:detached": "ng run iw-sam-app:e2e-ci-detached",
    "test:ci:local": "ng run iw-sam-app:e2e-ci-local",
    "test:ci:e2e": "yarn test:ci --spec 'tests/specs/e2e/**/*.spec.*'",
    "test:ci:integration": "yarn test:ci --spec 'tests/specs/integration/**/*.spec.*'",
    "test:nowatch": "ng test --watch=false --progress=false",
    "webdriver-up1e": "webdriver-manager update",
    "webdriver-start": "webdriver-manager start",
    "e2e-live": "protractor --elementExplorer",
    "pretest": "yarn lint",
    "bumpVersion": "node bumpVersion",
    "coverage": "ng test --code-coverage",
    "coverage:nowatch": "yarn coverage --watch=false",
    "analyze": "webpack-bundle-analyzer build/stats-es2015.json -p 1963",
    "docs": "compodoc -c .compodocrc.json -p ./src/tsconfig.app.json -s -o"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.4",
    "@angular/cdk": "^18.2.4",
    "@angular/common": "^18.2.4",
    "@angular/compiler": "^18.2.4",
    "@angular/core": "^18.2.4",
    "@angular/forms": "^18.2.4",
    "@angular/platform-browser": "^18.2.4",
    "@angular/platform-browser-dynamic": "^18.2.4",
    "@angular/router": "^18.2.4",
    "@cypress/schematic": "1.6.0",
    "@fullcalendar/angular": "^6.1.15",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/timegrid": "^6.1.15",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/store": "^18.0.2",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "angular-iban": "^18.0.0",
    "file-saver": "^2.0.5",
    "flatted": "^3.3.1",
    "flexboxgrid": "^6.3.1",
    "iban": "^0.0.14",
    "jwt-decode": "^4.0.0",
    "keycloak-angular": "^16.0.1",
    "keycloak-js": "^25.0.4",
    "lodash": "^4.17.21",
    "moment": "^2.30.1",
    "ngx-mask": "^18.0.0",
    "ngx-pagination": "^6.0.3",
    "normalize.css": "^8.0.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.10",
    "quill": "^2.0.2",
    "rxjs": "^7.8.1",
    "tslib": "^2.6.3",
    "uuidjs": "^4.2.1",
    "xml2js": "^0.6.2",
    "zone.js": "~0.14.10",
    "timers": "^0.1.1"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.4",
    "@angular-eslint/builder": "^18.3.1",
    "@angular-eslint/eslint-plugin": "^18.3.1",
    "@angular-eslint/eslint-plugin-template": "^18.3.1",
    "@angular-eslint/schematics": "^18.3.1",
    "@angular-eslint/template-parser": "^18.3.1",
    "@angular/cli": "^18.2.4",
    "@angular/compiler-cli": "^18.2.4",
    "@angular/language-service": "^18.2.4",
    "@biesbjerg/ngx-translate-extract": "^7.0.4",
    "@compodoc/compodoc": "^1.1.11",
    "@cypress/code-coverage": "3.9.12",
    "@cypress/webpack-preprocessor": "5.11.1",
    "@fortawesome/fontawesome-free": "^6.5.1",
    "@ngrx/store-devtools": "18.0.2",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "~2.0.3",
    "@types/lodash": "^4.14.201",
    "@types/moment": "^2.13.0",
    "@types/node": "^18.18.9",
    "@types/xml2js": "^0.4.14",
    "@types/yargs": "^17.0.32",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "babel-plugin-istanbul": "^6.1.1",
    "concurrently": "^5.3.0",
    "cypress": "9.5.2",
    "cypress-file-upload": "5.0.8",
    "cypress-keycloak": "1.8.0",
    "cypress-wait-until": "1.7.2",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "^46.9.1",
    "eslint-plugin-no-null": "^1.0.2",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "istanbul-instrumenter-loader": "^3.0.1",
    "jasmine-core": "~4.6.0",
    "jasmine-spec-reporter": "~6.0.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ng-packagr": "^18.0.0",
    "nyc": "^15.1.0",
    "rimraf": "^2.6.3",
    "sort-json": "^2.0.0",
    "stylus": "^0.54.8",
    "ts-node": "~7.0.0",
    "typescript": "~5.5.4",
    "webpack-bundle-analyzer": "^4.10.1"
  }
}
