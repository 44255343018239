import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Gecli} from '@app/sam-base/models/invoices';
import {Gecliadr} from '@app/sam-base/models/invoices/gecliadr';
import {Addresses, Formejuri, Langue} from './tab-client-autre-enums';

@Component({
    selector: 'iw-client-tab-autre', templateUrl: './tab-client-autre.component.html'
})
export class TabClientAutreComponent extends BasePartialFormComponent<Gecli> implements OnInit {

    @Input() public isReadonly = true;

    @Input() public entityId?: string;

    @Input() public isNew = false;

    public isChecked = false;
    public langue = Langue;
    public formejuri = Formejuri;
    public addresses = Addresses;

    public ngOnInit() {

    }

    public resultFilters: (e: Gecliadr) => boolean = (e) => e.cliId === this.entityId;

}

