import {Component, Input, Type, ViewChild} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components/iw-rest-grid/iw-rest-grid.component';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {EsQueryStatement, RestApiService, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';

import {SuiviService} from '@app/sam-base/core/services/suivi.service';
import {EntityDelete, IwStoreService} from '@app/sam-base/core/store';
import {FormKeys, IwGridColumn} from '@app/sam-base/models';
import {Ppcde, Ppsuivi, Ppsuiviview} from '@app/sam-base/models/placement';
import {PrintService} from '@core/print/print.service';
import {TranslateService} from '@ngx-translate/core';
import {PlacementForm} from '../../../placement.forms';

@Component({
    selector: 'iw-tab-ppcdetempo-suivi', templateUrl: './tab-ppcdetempo-suivi.component.html'
})
export class TabPpcdetempoPageSuiviComponent extends BaseFormComponent<Ppsuivi> {

    @Input()
    public set entityId(v: string | undefined) {
        if (v) {
            this.setEntityId(v);
            this.updateFilters(v);
        }
    }

    public get suiId() {
        return this.getFormValue('suiId') || '';
    }

    public get cliId() {
        return this.getFormValue('cliId') || '';
    }

    public get empId() {
        return this.getFormValue('empId') || '';
    }

    @Input() public isReadonly = true;

    public query: RestQueryParam<Ppsuiviview, string | boolean>[] = [];
    public columns = this.getCdeSuiviColumns();

    public tableWidth = '1200px';

    public suiviDescription = '';

    public type: Type<Ppsuiviview> = Ppsuiviview;
    public refreshType: Type<Ppsuivi> = Ppsuivi;
    public selected?: Ppsuiviview;

    @ViewChild('olvClisui', {static: true}) private _table?: IwRestGridComponent<Ppsuiviview>;
    private _entityId?: string;

    constructor(private readonly _store: IwStoreService, private readonly _formHandler: FormHandlerService<PlacementForm>, private readonly _printService: PrintService, private _restService: RestApiService, private readonly _translate: TranslateService, private _suiviService: SuiviService) {
        super();
    }

    public clearSortDir() {
        if (this._table) {
            this._table.defaultSort = undefined;
            this._table.defaultSortDir = 'asc';
        }
    }

    /**
     * Function to add new suivi for CDE
     */
    public addSuiviCde() {
        if (!this._entityId) {
            return;
        }
        this._restService.getEntityClient(Ppcde)
            .getById(this._entityId)
            .subscribe((elem) => {
                this._formHandler.showFormDialog(PlacementForm.SuiviNew, {
                    cdeId: this._entityId, cliId: elem.cliId, empId: this.empId, suitype: 'T', action: 'CDEACTION'
                }, s => ({...s, diagTitle: 'title_ppcdesui'}));
            });
    }

    /**
     * Function to edit one suivi in CDE
     */
    public editSuiviCde() {
        if (!this.selected) {
            return;
        }
        if (!this._entityId) {
            return;
        }
        const id = this.selected.suiId;
        this._restService.getEntityClient(Ppcde)
            .getById(this._entityId)
            .subscribe((elem) => {
                this._formHandler.showFormDialog(PlacementForm.SuiviEdit, {
                    cdeId: this._entityId, cliId: elem.cliId, empId: this.empId, suitype: 'T', action: 'CDEACTION'
                }, s => ({...s, entityId: id || '', diagTitle: 'title_ppcdesui'}));
            });
    }

    /**
     * Function to delete suivi in CDE
     */
    public deleteSuiviCde() {
        if (this.selected) {
            const id = this.selected.suiId;
            if (!id) {
                return;
            }

            this._store.dispatch(new EntityDelete(Ppsuivi, this.selected.suiId || ''));
        }
    }

    public getSelectedRow(event: Ppsuiviview[]) {
        this.selected = (event && event[0]) || undefined;
        if (this.selected) {
            this.setFormValue('suiId', this.selected.suiId);
            this.setFormValue('notes', this.selected.notes);
            this.setSuiviDescription(this.selected);
        } else {
            this.setFormValue('suiId', '');
            this.setFormValue('notes', '');
            this.suiviDescription = '';
        }
    }

    public printService() {
        if (!this._table) {
            return;
        }
        const profile = this._table.loadGridProfile();

        this._printService.print<Ppsuivi>({
            entity: Ppsuivi,
            extraStatements: [EsQueryStatement
                .fromMatch({cdeId: {query: this.entityId || ''}}, 'filter')],
            format: 'pdf',
            group: profile,
            parameters: {}
        });
    }

    protected getFormControlNames(): FormKeys<Ppsuivi> {
        return ['cliId', 'notes'];
    }

    // eslint-disable-next-line complexity
    private setSuiviDescription(suivi: Ppsuiviview) {
        this.suiviDescription = this._suiviService.getSuiviDescription(suivi);
    }

    private setEntityId(v: string) {
        if (v !== this._entityId) {
            this._entityId = v;
        }
    }

    private async updateFilters(cdeId: string) {
        this.query = [{
            operation: RestQueryOperation.Equals, prop: 'cdeId', value: cdeId
        }];
    }

    private getCdeSuiviColumns(): IwGridColumn<Ppsuiviview>[] {
        return [{
            prop: 'dateaction', name: 'date', type: 'date', index: 0
        }, {
            prop: 'nomconseille', name: 'conseiller', index: 1
        }, {
            prop: 'action', index: 2
        }, {
            prop: 'nomemploye', name: 'employe', index: 3
        }, {
            prop: 'nomclient', name: 'client', index: 4
        }, {
            prop: 'clientcontact', name: 'contact', index: 5
        }];
    }
}
