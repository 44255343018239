import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {
    Sasx5institutionSideMenuComponent
} from '@modules/sam-main/admin/components/swissdec/institution/side-menu/sasx5institution-side-menu.component';
import {
    SwissdecInstitutionComponent
} from '@modules/sam-main/admin/components/swissdec/institution/swissdec-institution.component';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';

export const SASX5DEC_INSTITUTION: FormDescription<Sasx5institution> = {
    form: SwissdecInstitutionComponent,
    sideMenu: Sasx5institutionSideMenuComponent,
    state: {
        ...buildDefaultState(Sasx5institution),
        diagTitle: 'sasx5institution_form_title',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: false,
            showClose: true,
            showEdit: false,
            showList: false,
            showPrint: false,
            showDelete: false
        },
        useNavigation: false,
        patch: false,
        diagModal: true,
        editMode: 'read',
        width: 700
    }
};

