<form *ngIf="formGroupReady" [formGroup]="formGroup" class="row">
    <iw-textfield (controlFocus)="!$event ? calculateTotal() : triggerReadyToCalculate()" [isDisabled]="isReadonly"
                  [label]="'tarifClient' | translate" class="col-xs-7" formControlName="clitarif"
                  labelAlign="left"></iw-textfield>
    <iw-textfield [isDisabled]="true" [value]="'mois' | translate" class="col-xs-2"></iw-textfield>
    <div class="space-block space-block-2"></div>
    <iw-textfield (controlFocus)="!$event ? calculateTotal() : triggerReadyToCalculate()" [isDisabled]="isReadonly"
                  [label]="'supplementCct' | translate" class="col-xs-7" formControlName="clitarifra"
                  labelAlign="left"></iw-textfield>
    <iw-textfield #txtSupCCT [isDisabled]="true" class="col-xs-2"></iw-textfield>
    <iw-button (press)="onCalcCCT()" [isDisabled]="isReadonly" [text]="'appliquer' | translate"
               class="col-xs-3"></iw-button>
    <div class="space-block space-block-2"></div>
    <iw-textfield #txtTarifTotal [isDisabled]="true" [label]="'tarifTotal' | translate" class="col-xs-7"
                  labelAlign="left"></iw-textfield>
    <iw-textfield [isDisabled]="true" [value]="'mois' | translate" class="col-xs-2"></iw-textfield>
</form>
