import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base/base-partial-form.component';
import {Gecli} from '@app/sam-base/models/invoices';


@Component({
    selector: 'iw-client-tab-notes', templateUrl: './tab-client-notes.component.html'
})
export class TabClientNotesComponent extends BasePartialFormComponent<Gecli> {
    constructor() {
        super();
    }
}
