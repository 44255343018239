import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {
    Ppgrh, Ppheu, Ppheusaid, Ppmisih, Pprapihdet
} from '@app/sam-base/models/placement';
import {
    Ppheusaid2Hours
} from '@shared/widgets/iw-components/iw-hours-grid/iw-hours-grid.model';

import {getGrhIdKey} from './map-grh';

function getHour(mondayDate: Date, offset: number, h: Ppheu[], rapId: string, grhId?: string): number {
    const date = IwDateHelper.dateFormatFromString(IwDateHelper.addToDate(offset, 'day', mondayDate), 'YYYY-MM-DD');

    const heur = h.find(e => IwDateHelper.dateFormatFromString(IwDateHelper.getMoment(e.date), 'YYYY-MM-DD') === date && e.rapId === rapId);

    const key = getGrhIdKey(grhId);

    if (heur && key) {
        return heur[key] as number;
    }

    return 0;
}

function findRapihdet(hour: Ppheusaid2Hours, rapihdetsList?: Pprapihdet[][]): Pprapihdet[] {
    if (!rapihdetsList) {
        return [];
    }
    let foundRapideht: Pprapihdet[] = [];
    rapihdetsList.some(rapihdet => {
        if (rapihdet[0].misihId === hour.misihId) {
            foundRapideht = rapihdet;
        }
    });
    return foundRapideht;
}

function mapRapihdetDay(rapihdet: Pprapihdet[], date: Date, hour: Ppheusaid2Hours) {
    for (let i = 1; i < 8; i++) {
        const h = rapihdet.find(rapih => {
            const cDay = new Date(date);
            cDay.setDate(date.getDate() + i - 1);
            return rapih.date && date ? IwDateHelper.areDatesEqual(rapih.date, cDay) : false;
        });
        if (h && h.nb) {
            const auxHour: any = hour;
            auxHour['day' + i + 'Hour'] = h.nb;
        }
    }
}

// eslint-disable-next-line complexity
function mapPpmisih2Ppheusaid2(m: Ppmisih, h: Ppheu[], rapId: string, date: Date, grh?: Ppgrh): Ppheusaid2Hours {
    return {
        cid: m.cid || '',
        grhId: m.grhId || '',
        griId: m.griId || '',
        grhType: grh?.grhType || '',
        gsId: m?.gsId || '',
        indorheu: m.indorheu || '',
        libelle: m.libelle || '',
        misId: m.misId || '',
        misihId: m.misihId || '',
        mntcli: m.mntcli || 0,
        mntemp: m.mntemp || 0,
        rapId,
        totalHours: 0,
        day1Hour: getHour(date, 0, h, rapId, m?.grhId),
        day2Hour: getHour(date, 1, h, rapId, m?.grhId),
        day3Hour: getHour(date, 2, h, rapId, m?.grhId),
        day4Hour: getHour(date, 3, h, rapId, m?.grhId),
        day5Hour: getHour(date, 4, h, rapId, m?.grhId),
        day6Hour: getHour(date, 5, h, rapId, m?.grhId),
        day7Hour: getHour(date, 6, h, rapId, m?.grhId)
    };
}

function mapSpecialHours(p: Ppheusaid, date: Date, hours: Ppheusaid2Hours[]): Ppheusaid2Hours[] {
    hours.forEach(hour => {
        const rapihdet = findRapihdet(hour, p.rapihdet);
        if (rapihdet.length) {
            mapRapihdetDay(rapihdet, date, hour);
        }
    });
    return hours;
}

export function mapPpheusaidToPpheusaid2(p: Ppheusaid, date: Date): Ppheusaid2Hours[] {
    let hours = p.misih?.map(m => mapPpmisih2Ppheusaid2(m, p.heu || [], p.rap?.rapId || '', date, p.grh?.find(g => g.grhId === m.grhId)))
        .sort((a, b) => (a.grhId > b.grhId) ? 1 : ((b.grhId > a.grhId) ? -1 : 0))
        .sort((a, b) => (a.indorheu > b.indorheu) ? 1 : ((b.indorheu > a.indorheu) ? -1 : 0)) || [];
    hours = mapSpecialHours(p, date, hours);
    return hours;
}
