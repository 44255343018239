import {Observable} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {Ppplf} from '@sam-base/models/placement';

@Injectable()
export class PpplfService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }


    public getPlacFixeByCdfId(cdfId: string): Observable<Ppplf[]> {
        return this.GET<Ppplf[]>(undefined, `/ppplf/cdf/${cdfId}`);
    }
}
