import {buildDefaultState} from '@app/sam-base/core';
import {FormDescription} from '@app/sam-base/core/form-handler';
import {Saparncsmot} from '@app/sam-base/models/salary/saparncsmot';
import {SaparncsmotComponent} from '../components/saparncsmot/saparncsmot.component';

export const SAPARNCSMOT_DEFAULT_FORM: FormDescription<Saparncsmot> = {
    form: SaparncsmotComponent, state: {
        ...buildDefaultState(Saparncsmot),
        diagTitle: 'combo_ncs',
        diagModal: true,
        width: 700,
        diagShowSideMenu: true,
        diagSideMenu: {
            showCancel: false, showClose: true, showEdit: true, showList: false, showPrint: false, showDelete: false
        },
        editMode: 'edit'
    }
};
