import {IwRestEntity, IwRestEntityId} from '@sam-base/core/rest-api';

export class JcptaErr {

    errText?: string;

    cType?: string;

    entity?: string;

    entityId?: string;
}

export class CurRab {

    centre?: string;

    montant?: number;

    tvaCode?: string;
}

export class CurRis {

    centre?: string;

    conId?: string;

    montant?: number;

    tvaCode?: string;
}

export class CurEsc {

    compte?: string;

    centre?: string;

    cliId?: string;

    montant?: number;

    tvaCode?: string;
}

export class CurHRep {

    centre?: string;

    montant?: number;

    tvaCode?: string;
}

export class CurHRepGrouped {

    centre?: string;

    montant?: number;

    tvaCode?: string;
}


export class Jcpta2 {

    cType?: string
    no?: number
    dateCpta?: string
    libelle?: string
    cptDebit?: string
    cptCredit?: string
    cenDebit?: string
    cenCredit?: string
    cliId?: string
    conId?: string
    conId2?: string
    conId2Pc?: number
    montant?: number
    tvaCode?: string
    refPieces?: string
    parentId?: string
    tvaPeriode?: string
    lOk?: boolean
}

export class PrepareResult {

    jcpta2List?: Jcpta2[];

    jcpta2GroupedList?: Jcpta2[];

    jcptaErrList?: JcptaErr[];

    curRisList?: CurRis[];

    curRabList?: CurRab[];

    curEscList?: CurEsc[];

    curHRepList?: CurHRep[];

    curHRepGroupedList?: CurHRepGrouped[];

    nbEcrFac?: number;

    nbEcrPay?: number;

    noEcr?: number;

    noEcrPay?: number;
}


@IwRestEntity('gecompta')
export class Gecompta {
    @IwRestEntityId() dateDe?: string;
    dateA?: string;
    facId?: string;
    fac?: boolean;
    pay?: boolean;
    facZero?: boolean;
    force?: boolean;
    facDebCre?: boolean;
    alwaysSplitEcr?: boolean;
    modeRegoup?: number;
    modeAgence?: number;
    withCompteAux?: boolean;
    prvRist?: boolean;
    cptDeb?: string;
    cptRist?: string;
    cptPrvRist?: string;
    cptRist1?: string;
    cptRab?: string;
    cptFrais?: string;
    nbEcrFac?: number;
    nbEcrPay?: number;
    nbErreurs?: number;
    prepareResult?: PrepareResult;
}

export enum ModeRegroup {
    mode_regroupement_aucun = 1, mode_regroupement_date_compte_centre = 2, mode_regroupement_compte_centre
}

export enum ModeAgence {
    mode_agence_mission = 1, mode_agence_conseiller = 2,
}
