<form [formGroup]="formGroup" class="row">

    <iw-textarea [height]="99" [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'good' | translate"
                 class="iw-textarea col-xs-12" formControlName="good"></iw-textarea>

    <iw-textarea [height]="99" [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'medium' | translate"
                 class="iw-textarea col-xs-12" formControlName="medium"></iw-textarea>

    <iw-textarea [height]="99" [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'bad' | translate"
                 class="iw-textarea col-xs-12" formControlName="bad"></iw-textarea>

</form>
