import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('saes')
export class Saes {
    @IwRestEntityId() public esId?: string;
    public empId?: string;
    public salId?: string;
    public ficsalId?: string;
    public salIdCor?: string;
    public gsId?: string;
    public prBase?: number;
    public prTaux?: number;
    public taux?: number;
    public taux2?: number;
    public base?: number;
    public montant?: number;
    public unite?: string;
    public texte?: string;
    public cptdebit?: string;
    public cptcredit?: string;
    public ismodified?: boolean;
    public origine?: string;
    public parent?: string;
    public parenttype?: string;
    public parent2?: string;
    public parent2typ?: string;
}
