<form [formGroup]="formGroup"
      class="row">
    <div class="row space-form col-xs-6 separator-right">
        <div class="iw-label col-xs-3 flex-end">{{ 'formule' | translate }}</div>
        <iw-enum-dropdown #cboPolitesse
                          (valueChange)="recalculateDeductions()"
                          [inputEnum]="cpolitesse"
                          [isDisabled]="isReadonly"
                          [noLabel]="true"
                          class="iw-input col-xs-9"
                          formControlName="cpolitesse"></iw-enum-dropdown>

        <div class="iw-label col-xs-3 flex-end">{{ 'nom' | translate }}</div>
        <iw-textfield #txtNom
                      [isDisabled]="isReadonly"
                      [noLabel]="true"
                      class="iw-input col-xs-9 padding-top-10"
                      formControlName="nom"></iw-textfield>
        <div class="iw-label col-xs-3 flex-end">{{ 'prenom' | translate }}</div>
        <iw-textfield #txtPrenom
                      [isDisabled]="isReadonly"
                      [noLabel]="true"
                      class="iw-input col-xs-9"
                      formControlName="prenom"></iw-textfield>

        <div class="iw-label col-xs-3 padding-top-10 flex-end">{{ 'adresse' | translate }}</div>
        <iw-textfield #txtAdresse1
                      [isDisabled]="isReadonly"
                      [noLabel]="true"
                      class="iw-input col-xs-9 padding-top-10"
                      formControlName="adresse1"></iw-textfield>
        <iw-textfield #txtAdresse2
                      [isDisabled]="isReadonly"
                      [noLabel]="true"
                      class="iw-input col-xs-9 col-xs-offset-3"
                      formControlName="adresse2"></iw-textfield>

        <div class="col-xs-12"></div>

        <div class="iw-label col-xs-3 flex-center">{{ 'pays' | translate }}</div>
        <iw-pays-dropdown #cboPays
                          [isDisabled]="isReadonly"
                          [noLabel]="true"
                          class="iw-input col-xs-9"
                          formControlName="pays"></iw-pays-dropdown>

        <div class="iw-label col-xs-3 flex-center">{{ 'npaLieu' | translate }}</div>
        <iw-npa-lieu [formGroup]="formGroup"
                     [isDisabled]="isReadonly || !pays"
                     [labelNpa]="''"
                     [lieuFormControlName]="'lieu'"
                     [npaFormControlName]="'npa'"
                     [pays]="pays"
                     class="iw-input col-xs-9"></iw-npa-lieu>

        <div class="iw-label col-xs-3 flex-end padding-top-10">{{ 'commune' | translate }}</div>
        <iw-textfield #txtHabOfsnr
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="habOfsnr"
                      class="iw-input col-xs-9 padding-top-10"></iw-textfield>

        <div class="col-xs-12 separator-top"></div>
        <div class="iw-label col-xs-3 flex-end  padding-top-10">{{ 'telephones' | translate }}</div>
        <iw-textfield #txtTel1
                      [isDisabled]="isReadonly"
                      [noLabel]="true"
                      class="iw-input col-xs-9  padding-top-10"
                      formControlName="tel1"></iw-textfield>
        <iw-textfield #txtTel2
                      [isDisabled]="isReadonly"
                      [noLabel]="true"
                      class="iw-input col-xs-9 col-xs-offset-3"
                      formControlName="tel2"></iw-textfield>
        <div class="iw-label col-xs-3 flex-end">{{ 'natel' | translate }}</div>
        <iw-phone-textfield #txtNatel
                            [isDisabled]="isReadonly"
                            [noLabel]="true"
                            class="iw-input col-xs-9"
                            formControlName="natel"></iw-phone-textfield>

        <div class="iw-label col-xs-3 flex-end padding-top-10 padding-top-10">{{ 'internal' | translate }}</div>
        <iw-textfield #txtTelInterne
                      [isDisabled]="isReadonly"
                      [noLabel]="true"
                      class="iw-input col-xs-9 padding-top-10"
                      formControlName="telinterne"></iw-textfield>

        <div class="iw-label col-xs-3 flex-end padding-top-10">{{ 'email' | translate }}
        </div>
        <iw-textfield #txtEmail
                      [isDisabled]="isReadonly"
                      [noLabel]="true"
                      class="iw-input col-xs-8 padding-top-10"
                      formControlName="email"></iw-textfield>
        <iw-button #btnCopyAdr2Clipboard
                   (press)="addToClipboard('email')"
                   [isDisabled]="!isReadonly"
                   [tooltip]="'copierDans' | translate"
                   class="iw-button col-xs-1 flex-end"
                   iconClass="fas fa-copy"></iw-button>
        <iw-checkbox #chkBstoemail
                     [isDisabled]="isReadonly || !hasEmail"
                     [label]="'send_bulletin' | translate"
                     class="col-xs-9 col-xs-offset-3"
                     formControlName="bstoemail"></iw-checkbox>
        <div class="col-xs-12 separator-top"></div>
    </div>

    <div class="row col-xs-6">
        <iw-input-avs-mask #txtNoavs13
                           [isDisabled]="isReadonly"
                           [labelAlign]="'top'"
                           [label]="'no_avs' | translate"
                           [warningMessage]="'no_avs_13_invalid'"
                           class="iw-input col-xs-12"
                           formControlName="noavs13"></iw-input-avs-mask>

        <iw-date-picker #txtDatenaiss
                        (valueChange)="recalculateDeductions()"
                        [isDisabled]="isReadonly"
                        [labelAlign]="'top'"
                        [label]="'datenaiss' | translate"
                        [maxDate]="dateNow"
                        class="col-xs-6 padding-top-10 no-padding-right"
                        formControlName="datenaiss"></iw-date-picker>
        <iw-textfield #txtDatenaiss2
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="' '"
                      [value]="age"
                      class="iw-input col-xs-6 no-padding-left padding-top-10 "></iw-textfield>

        <div class="row col-xs-12 separator-top">
            <iw-date-picker #txtDateinfirs
                            [isDisabled]="true"
                            [labelAlign]="'top'"
                            [label]="'first_entry' | translate"
                            class="col-xs-6 padding-top-10 no-padding-right"
                            formControlName="dateInfir"></iw-date-picker>
            <iw-textfield #txtInactif
                          [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="' '"
                          [value]="inactive"
                          class="iw-input col-xs-6 no-padding-left padding-top-10"></iw-textfield>

            <iw-date-picker #txtDateinlastf
                            [isDisabled]="true"
                            [labelAlign]="'top'"
                            [label]="'derniere_entree' | translate"
                            class="col-xs-6 padding-top-10 no-padding-right"
                            formControlName="dateInlas"></iw-date-picker>

            <iw-date-picker #txtDatelastout
                            [isDisabled]="true"
                            [labelAlign]="'top'"
                            [label]="'derniere_sortie' | translate"
                            class="col-xs-6 padding-top-10 no-padding-right"
                            formControlName="dateOutla"></iw-date-picker>
        </div>
        <div class="row col-xs-12 separator-top">
            <iw-enum-dropdown #cboILangue
                              [inputEnum]="langues"
                              [isDisabled]="isReadonly"
                              [label]="'langue' | translate"
                              class="iw-input col-xs-12 padding-top-10"
                              formControlName="langue"></iw-enum-dropdown>
        </div>
    </div>
</form>
