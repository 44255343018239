<form [formGroup]="formGroup">
    <div class="row">
        <div class="col-xs-11 col"
             style="height: 200px">
            <iw-rest-grid #ppcliattGrid
                          (selected)="getSelectedRow($event)"
                          [autoFormClick]="false"
                          [defaultSelected]="true"
                          [hasGridColumnMenu]="false"
                          [queryStatements]="query"
                          [type]="type"></iw-rest-grid>
        </div>
        <div class="col-xs-1 grid-buttons">
            <iw-button #btnAddAtt
                       (press)="newContactClient()"
                       [isDisabled]="isReadonly"
                       [tooltip]="'contactNewClient' | translate"
                       iconClass="fas fa-file"
                       style="padding-bottom: 6px"></iw-button>

            <iw-button #btnModAtt
                       (press)="editContactClient()"
                       [isDisabled]="isReadonly || !cliattId"
                       [tooltip]="'contactEdit' | translate"
                       iconClass="fas fa-pencil-alt"></iw-button>

            <iw-button #btnDelAtt
                       (press)="removeContactClient()"
                       [isDisabled]="isReadonly || !cliattId"
                       [tooltip]="'contactDelete' | translate"
                       iconClass="fas fa-trash"></iw-button>

            <iw-button #btnDefContact
                       (press)="setActiveContactAsDefault()"
                       [isDisabled]="isReadonly || !cliattId || isDefaultContact"
                       [tooltip]="'parDefaut' | translate"
                       class="iw-button"
                       iconClass="fas fa-solid fa-star"
                       style="width: 24px"></iw-button>
        </div>
    </div>
    <div class="row">
        <iw-textarea #edtAttInfo
                     [height]="110"
                     [isDisabled]="true"
                     [labelAlign]="'top'"
                     [label]="'contact' | translate"
                     [value]="contact"
                     class="iw-textarea col-xs-11"></iw-textarea>

    </div>

    <div class="row">
        <iw-phone-textfield #txtTel
                            [isDisabled]="true"
                            [labelAlign]="'top'"
                            [label]="'telephone' | translate"
                            class="iw-input col-xs-5"
                            formControlName="tel"></iw-phone-textfield>

        <iw-textfield #txtFax
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'fax' | translate"
                      class="iw-input col-xs-offset-1 col-xs-5"
                      formControlName="fax"></iw-textfield>
    </div>
    <div class="row">
        <iw-phone-textfield #txtNatel
                            [isDisabled]="true"
                            [labelAlign]="'top'"
                            [label]="'telephonePortable' | translate"
                            class="iw-input col-xs-5"
                            formControlName="natel"></iw-phone-textfield>

        <iw-button #Standardbutton1
                   (press)="sendSms()"
                   [tooltip]="'envoyerSms' | translate"
                   *iwAccessGroups="buttonEntityComRoles"
                   class="col-xs-1"
                   iconClass="fas fa-comment-dots"
                   style="padding-top: 24px"></iw-button>
    </div>

    <div class="row">
        <iw-email-textfield #txtEmail
                            [isDisabled]="true"
                            [labelAlign]="'top'"
                            [label]="'eMail' | translate"
                            *iwAccessGroups="buttonEntityComRoles"
                            class="iw-input col-xs-7"
                            formControlName="email"></iw-email-textfield>
        <iw-button #btnCopy2ClipBoard
                   (press)="addToClipboard()"
                   [tooltip]="'copierDans' | translate"
                   iconClass="fas fa-copy"
                   style="padding-top: 24px"></iw-button>

        <iw-button #btnSendMail2Contact
                   (press)="sendEmail()"
                   iconClass="fas fa-comment-dots"
                   style="padding-top: 24px; margin-left: 10px"></iw-button>
    </div>

    <div class="row padding-top-25">
        <iw-checkbox [isDisabled]="true"
                     [label]="'primary_contact'"
                     [value]="isDefaultContact"
                     class="iw-input col-xs-5"
                     labelAlign="top"></iw-checkbox>
    </div>


</form>
