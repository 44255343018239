import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Gepaihdr} from '@app/sam-base/models/invoices/gepaihdr';
import {EncaissementSideMenuComponent} from '../components/encaissement/encaissement-side-menu.component';
import {EncaissementComponent} from '../components/encaissement/encaissement.component';

export const ENCAISSEMENT_DEFAULT_FORM: FormDescription<Gepaihdr> = {
    form: EncaissementComponent,
    sideMenu: EncaissementSideMenuComponent,
    state: {
        ...buildDefaultState(Gepaihdr),
        diagTitle: 'encaissement_debiteur',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true,
            showClose: true,
            showEdit: true,
            showList: true,
            showPrint: false,
            showDelete: true
        },
        editMode: 'read',
        useNavigation: true,
        width: 900,
        patch: false
    }
};
