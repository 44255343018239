import {Component} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core/store';
import {DestroyForm} from '@app/sam-base/core/store/actions/global-form.actions';
import {SidemenuState} from '@app/sam-base/core/store/reducers/form.reducer';
import {ComboboxItem, DynamicComponent, FormKeys} from '@app/sam-base/models';

export interface Cdffact {
    nomprenom: string;
    facDate: string;
    facMnt: string;
    facConcerne: string;
    facTexte: string;
}

@Component({
    templateUrl: './cdffact.component.html'
})
export class CdffactComponent extends BaseFormComponent<Cdffact> {

    constructor(private readonly _store: IwStoreService) {
        super();
    }

    public closeDialog() {
        this._store.dispatch(new DestroyForm(this.uuid));
    }

    protected getFormControlNames(): FormKeys<Cdffact> {
        return [
            'nomprenom',
            'facDate',
            'facMnt',
            'facConcerne',
            'facTexte'];
    }

    protected getSideMenuState(): SidemenuState {
        return {};
    }

    protected getOrderByOptions(): ComboboxItem<keyof Cdffact>[] {
        return [];
    }

    protected getSideComponent(): DynamicComponent | undefined {
        return undefined;
    }

}
