import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {FactureComptaComponent} from '@modules/sam-main/salary/components/facture-compta/facture-compta.component';
import {Gecompta} from '@sam-base/models/invoices/gecompta';

export const FACTURE_COMPTA_FORM: FormDescription<Gecompta> = {
    form: FactureComptaComponent,
    state: {
        ...buildDefaultState(Gecompta),
        diagTitle: 'Facturation - Comptabilisation',
        width: 900,
        diagShowSideMenu: false
    }
};
