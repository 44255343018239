import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {MissionContracts} from '@app/sam-base/models/placement/mission-contracts';
import {Observable} from 'rxjs';

@Injectable()
export class MissionInterijobService extends RestClient<{}> {

    constructor(http: HttpClient) {
        super(http);
    }

    public publishMission(misId: string, contractType: MissionContracts, ijmod: number): Observable<boolean> {
        return this.POST({ijmod}, undefined, `mission/${misId}/publish/${contractType}`);
    }
}
