import {Component, Input} from '@angular/core';
import {Validators} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppagi} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-1518-aginter', templateUrl: './tab-1518-aginter.component.html'
})
export class Tab1518AginterComponent extends BasePartialFormComponent<Ppagi> {
    @Input() public isReadonly = true;
    public timeInd = false;
    public until = false;
    public cancel = false;

    public setTimeInd(event: boolean, formName: string) {
        this.timeInd = event;
        this.uncheckOtherboxes(event, formName);
    }

    public setUntil(event: boolean, formName: string) {
        this.until = event;
        this.uncheckOtherboxes(event, formName);
        const dactgoond = this.getFormControl('dactgoond');
        if (this.until) {
            dactgoond?.setValidators([Validators.required]);
        } else {
            dactgoond?.clearValidators();
        }
        dactgoond?.updateValueAndValidity();
    }

    public setCancel(event: boolean, formName: string) {
        this.cancel = event;
        this.uncheckOtherboxes(event, formName);
        const cactnogoq = this.getFormControl('cactnogoq');
        const cactnogow = this.getFormControl('cactnogow');
        const cactnogop = this.getFormControl('cactnogop');
        const cactnogom = this.getFormControl('cactnogom');
        if (this.cancel) {
            cactnogoq?.setValidators([Validators.required]);
            cactnogow?.setValidators([Validators.required]);
            cactnogop?.setValidators([Validators.required]);
            cactnogom?.setValidators([Validators.required]);
        } else {
            cactnogoq?.clearValidators();
            cactnogow?.clearValidators();
            cactnogop?.clearValidators();
            cactnogom?.clearValidators();
        }
        cactnogoq?.updateValueAndValidity();
        cactnogow?.updateValueAndValidity();
        cactnogop?.updateValueAndValidity();
        cactnogom?.updateValueAndValidity();
    }

    // eslint-disable-next-line complexity
    public uncheckOtherboxes(event: boolean, formName: string) {
        if (!event) {
            return;
        }
        switch (formName) {
            case 'lactgoon':
                this.setFormValue('lactgoond', '');
                this.setFormValue('lactnogo', '');
                // Reset value for other fields
                this.setFormValue('dactgoond', '');
                this.setFormValue('cactnogoq', '');
                this.setFormValue('cactnogow', '');
                this.setFormValue('cactnogop', '');
                this.setFormValue('cactnogom', '');

                break;
            case 'lactgoond':
                this.setFormValue('lactgoon', '');
                this.setFormValue('lactnogo', '');
                // Reset value for other fields
                this.setFormValue('cactnogoq', '');
                this.setFormValue('cactnogow', '');
                this.setFormValue('cactnogop', '');
                this.setFormValue('cactnogom', '');
                break;
            case 'lactnogo':
                this.setFormValue('lactgoon', '');
                this.setFormValue('lactgoond', '');
                // Reset value for other fields
                this.setFormValue('dactgoond', '');
                break;
            default:
                break;
        }
    }
}
