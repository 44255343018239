import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Sads} from '@app/sam-base/models/salary';
import {SadsComponent} from '../components/employee/tab-saemp-salaire-vacances/definitionSalaire/sads.component';

export const SADS_DEFAULT_FORM: FormDescription<Sads> = {
    form: SadsComponent, state: {
        ...buildDefaultState(Sads), diagTitle: 'definitionSalaire', diagModal: false, width: 900, isNew: true
    }
};
