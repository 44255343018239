import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@root/environments/environment';
import {Observable} from 'rxjs';

export enum EntitiesToUpdateEnum {
    PPMIS = 'ppmis', PPIND = 'ppind', PPACO = 'ppaco', PPCDE = 'ppcde', SAEMS = 'saems'
}

@Injectable()
export class EntityUpdateService {

    protected readonly _baseUrl: string;

    constructor(public readonly _http: HttpClient) {
        this._baseUrl = environment.backendURL;
    }

    /**
     * Validates if an entity can be modified
     *
     * @param entity Name of the entity to validate
     * @param id ID of the entity
     */
    public canUpdateEntity(entity: EntitiesToUpdateEnum, id: string): Observable<boolean> {
        const url = this._baseUrl + `${entity}/${id}/can-update`;
        return this._http.get<boolean>(url, {});
    }
}
