<form [formGroup]="formGroup"
      class="row">
    <iw-rest-grid [autoFormClick]="false"
                  [customColumns]="js3Columns"
                  [hasGridColumnMenu]="false"
                  [hasSortIcon]="true"
                  [itemsPerPage]="100"
                  [queryStatements]="query"
                  [type]="type"
                  class="col-xs-12 padding-top-15"
                  style="height: 400px;"></iw-rest-grid>

    <div class="col-xs-offset-11 col-xs-1 padding-top-15">
        <iw-button #btnClose
                   (press)="closeDialog()"
                   [text]="'fermer' | translate"
                   class="iw-button"></iw-button>
    </div>

</form>
