import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ComboboxItem} from '@sam-base/models';

@Injectable({providedIn: 'root'})
export class SalaryPeriodeService {

    constructor(private readonly _translate: TranslateService) {
    }

    public getPeriodesForYear(year: string): ComboboxItem<string>[] {
        return [
            {
                name: this._translate.instant('january') + ' ' + year,
                value: `${year} 1`
            },
            {
                name: this._translate.instant('february') + ' ' + year,
                value: `${year} 2`
            },
            {
                name: this._translate.instant('march') + ' ' + year,
                value: `${year} 3`
            },
            {
                name: this._translate.instant('april') + ' ' + year,
                value: `${year} 4`
            },
            {
                name: this._translate.instant('may') + ' ' + year,
                value: `${year} 5`
            },
            {
                name: this._translate.instant('june') + ' ' + year,
                value: `${year} 6`
            },
            {
                name: this._translate.instant('july') + ' ' + year,
                value: `${year} 7`
            },
            {
                name: this._translate.instant('august') + ' ' + year,
                value: `${year} 8`
            },
            {
                name: this._translate.instant('september') + ' ' + year,
                value: `${year} 9`
            },
            {
                name: this._translate.instant('october') + ' ' + year,
                value: `${year}10`
            },
            {
                name: this._translate.instant('november') + ' ' + year,
                value: `${year}11`
            },
            {
                name: this._translate.instant('december') + ' ' + year,
                value: `${year}12`
            }]
    }
}