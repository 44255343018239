import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {Pplpp} from '@app/sam-base/models/placement';

import {environment} from '../../../../../environments/environment';

@Injectable()
export class PplppService extends RestClient<Pplpp> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    /**
     * Get a PPLPP
     *
     * @param empId id
     * @param datelundi date to get (YYYY-MM-DD)
     */
    public GetPplpp(empId: string, datelundi: string): Observable<Pplpp | undefined> {
        return this.GET<Pplpp[]>(undefined, `pplpp/custom/${datelundi}/${empId}`)
            .pipe(catchError(() => []), map(l => {
                if (l.length) {
                    return l[0];
                }
                return undefined;
            }));
    }

    /**
     * Update semstatus
     *
     * @param empId empId
     * @param datelundi date (YYYY-MM-DD)
     * @param semstatus new semstatus
     */
    public UpdateStatus(empId: string, datelundi: string, semstatus: number): Observable<boolean> {
        // eslint-disable-next-line max-len
        const url = `pplpp/custom/${datelundi}/${empId}/update/semstatus/${semstatus}`;
        return this.POST<{ success: boolean }>({}, undefined, url)
            .pipe(catchError(() => of({success: false})), map(({success}) => success));
    }

    /**
     * Update semstatus
     *
     * @param empId empId
     * @param datelundi date (YYYY-MM-DD)
     * @param semstatus new semstatus
     */
    public createPplpp(empId: string, datelundi: string, semstatus: number): Observable<Pplpp> {
        // eslint-disable-next-line max-len
        const url = `pplpp/custom/${datelundi}/${empId}/create/semstatus/${semstatus}`;
        return this._http.post<Pplpp>(environment.backendURL + url, {
            empId, datelundi, semstatus
        });
    }

}
