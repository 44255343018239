import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@root/environments/environment';

@Injectable()
export class EntityService {

    protected readonly _baseUrl: string;

    constructor(public readonly _http: HttpClient) {
        this._baseUrl = environment.backendURL;
    }

    public blockEmploye(empId: string) {
        const url = this._baseUrl + `empstatus/${empId}/toggleblock`;
        return this._http.post(url, {});
    }

    public blockClient(cliId: string) {
        const url = this._baseUrl + `clientstatus/${cliId}/toggleblock`;
        return this._http.post(url, {});
    }

    public openMission(misId: string) {
        const url = this._baseUrl + `mission/${misId}/open`;
        return this._http.put(url, {});
    }

    public closeMission(misId: string) {
        const url = this._baseUrl + `mission/${misId}/close`;
        return this._http.put(url, {});
    }

    public openEmployee(empId: string) {
        const url = this._baseUrl + `employee/${empId}/open`;
        return this._http.put(url, {});
    }

    public closeEmployee(empId: string) {
        const url = this._baseUrl + `employee/${empId}/close`;
        return this._http.put(url, {});
    }
}
