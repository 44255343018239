import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Saparass} from '@app/sam-base/models/placement/saparass';
import {AssurancesComponent} from '../components/assurances/assurances.component';

export const ASSURANCES: FormDescription<Saparass> = {
    form: AssurancesComponent, state: {
        ...buildDefaultState(Saparass),
        diagTitle: 'assurance',
        diagShowSideMenu: false,
        diagShowStatus: true,
        width: 1200
    }
};
