<form [formGroup]="formGroup" class="row">
    <div class="row space-form col-md-12">


        <iw-textfield #txtdepartment [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'departement' | translate"
                      class="iw-input col-xs-12" formControlName="department"></iw-textfield>
        <iw-textfield #txtaddress [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'adrunit' | translate"
                      class="iw-input col-xs-12" formControlName="address"></iw-textfield>
        <iw-npa-lieu [formGroup]="formGroup" [isDisabled]="isReadonly" [labelNpaAlign]="'left'"
                     [lieuFormControlName]="'lieu'" [npaFormControlName]="'npa'"
                     class="iw-input col-xs-12"></iw-npa-lieu>

        <iw-textfield #txtphone [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'telephone' | translate"
                      class="iw-input col-xs-12" formControlName="phone"></iw-textfield>
        <iw-textfield #txtfullName [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'signature' | translate"
                      class="iw-input col-xs-12" formControlName="fullName"></iw-textfield>


        <div class="row col-xs-12 padding-top-10" style="align-items: end;">
            <iw-button (press)="save()" [text]="'enregistrer' | translate"
                       class="iw-button padding-top-15 margin-right-5"></iw-button>
            <iw-button (press)="destroyForm()" [text]="'Annuler' | translate" class="iw-button"></iw-button>
        </div>
    </div>
</form>
