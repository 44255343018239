import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Sapaimode} from '@app/sam-base/models/salary/sapaimode';
import {Acopaytype} from '@modules/sam-main/placement/components/acompte/acompte-enums';
import {Observable, Subject} from 'rxjs';
import {RestClient} from '../rest-api/core';

export interface SapaimodeSubscrition {
    uuid: string;
    saved: boolean;
}


@Injectable()
export class SapaimodeService extends RestClient<any> {

    public sapaimodeSub: Subject<SapaimodeSubscrition> = new Subject();

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getSapaimodeData(paytype: Acopaytype): Observable<Sapaimode[]> {
        return this._http.get<Sapaimode[]>(this._baseUrl + `sapaimode/type/${paytype}`);
    }

    public getAllSapaimode(): Observable<Sapaimode[]> {
        return this._http.get<Sapaimode[]>(this._baseUrl + `sapaimode/modes`);
    }


    public save(sapaimode: Sapaimode): Observable<void> {
        return this._http.post<void>(this._baseUrl + `sapaimode/save`, sapaimode);
    }

    public delete(mpaId: string): Observable<void> {
        return this._http.delete<void>(this._baseUrl + `sapaimode/delete/${mpaId}`);
    }

    /*public getSapaimodeByPaytype(): Observable<Sapaimode[]> {
       return this._http.get<Sapaimode[]>(this._baseUrl + `sapaimode/findByPaytype`);
    }*/

}
