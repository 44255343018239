import {IwRestEntity, IwRestEntityId} from '@sam-base/core/rest-api';

@IwRestEntity('geper')
export class Geper {
    @IwRestEntityId() public date?: string;
    public salId?: string;
    public dateDebug?: string;
    public dateFin?: string;
    public texte?: string;
    public status?: number;
    public dateXfer?: string;
    public gestXfer?: string;
    public userId?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userModif?: string;
    public userDelet?: string;
}
