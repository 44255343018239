<form [formGroup]="formGroup" class="row space-form">
    <iw-date-picker #txtDateAction [labelAlign]="'top'" [label]="'date' | translate" class="iw-input col-xs-3"
                    formControlName="dateaction"></iw-date-picker>

    <iw-conseiller-dropdown #cboCon_id [labelAlign]="'top'" [label]="'conseiller' | translate"
                            class="iw-combobox col-xs-6" formControlName="conId" id="dropCons"></iw-conseiller-dropdown>

    <iw-textfield #txtUser_id [isDisabled]="true" [value]="userId" class="iw-input col-xs-3" label=" "></iw-textfield>

    <iw-textfield-action [labelAlign]="'top'" [label]="'action' | translate" [lkname]="action"
                         [warningMessage]="'errorMessageFieldSize' | translate" class="iw-input col-xs-12"
                         formControlName="action" id="txtfAction"></iw-textfield-action>

    <iw-entity-textfield [allowSearch]="true" [entity]="entityEmploye" [label]="'employee' | translate"
                         [name]="['nom', 'prenom']" [showIdField]="false" class="iw-input col-xs-11"
                         formControlName="empId" id="txtfEmp"></iw-entity-textfield>
    <iw-button #originMisButton (press)="openEmploye()" [isDisabled]="!hasEmploye" class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>

    <iw-entity-textfield #ctnSelCli [allowSearch]="true" [entity]="entityClient" [label]="'client' | translate"
                         [name]="['nom', 'prenom']" [showIdField]="false" class="iw-input col-xs-11"
                         formControlName="cliId"></iw-entity-textfield>
    <iw-button #originMisButton (press)="openClient()" [isDisabled]="!hasClient" class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>

    <iw-cliatt-dropdown #cboCliatt_id [labelAlign]="'top'" [label]="'contactClient' | translate"
                        [resultFilters]="resultFilters" class="iw-combobox col-xs-12"
                        formControlName="cliattId"></iw-cliatt-dropdown>

    <iw-entity-textfield #ctnSelCli [allowSearch]="true" [entity]="entityMis" [label]="'mission' | translate"
                         [name]="['misId']" [showIdField]="false" class="iw-input col-xs-11"
                         formControlName="misId"></iw-entity-textfield>
    <iw-button #originMisButton (press)="openMission()" [isDisabled]="!hasMission" class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>

    <iw-entity-textfield #ctnSelCde [allowSearch]="true" [entity]="entityCde" [label]="'commandeTemporaire' | translate"
                         [name]="['cdeId']" [showIdField]="false" class="iw-input col-xs-11"
                         formControlName="cdeId"></iw-entity-textfield>
    <iw-button #originMisButton (press)="openCde()" [isDisabled]="!hasCommandeTempo" class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>

    <iw-entity-textfield #ctnSelCde [allowSearch]="true" [entity]="entityCdf" [label]="'combo_ppplf' | translate"
                         [name]="['cdfId']" [showIdField]="false" class="iw-input col-xs-11"
                         formControlName="cdfId"></iw-entity-textfield>
    <iw-button #originMisButton (press)="openCdf()" [isDisabled]="!hasCommandeFixe" class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>

    <iw-textarea #edtNotes [height]="99" [labelAlign]="'top'" [label]="'remarques' | translate"
                 class="iw-textarea col-xs-12" formControlName="notes"></iw-textarea>

    <div class="col-xs-12 space-block-6"></div>

    <div class="row col-xs-9">
        <div class="col-xs-6 col-xs-offset-5 padding-0 flex flex-h flex-between">
            <iw-button #btn7days (press)="forwardDateBy1()" [isDisabled]="!isRappeler" [text]="'1S' | translate"
                       class="iw-button"></iw-button>

            <iw-button #btn28days (press)="forwardDateBy4()" [isDisabled]="!isRappeler" [text]="'4S' | translate"
                       class="iw-button"></iw-button>

            <iw-button #btn84days (press)="forwardDateBy12()" [isDisabled]="!isRappeler" [text]="'12S' | translate"
                       class="iw-button"></iw-button>
        </div>
        <iw-checkbox #chkRappelFlag [label]="'rapPeler' | translate" class="iw-input col-xs-5 padding-0"
                     formControlName="rappelflag"></iw-checkbox>
        <div class="row col-xs-6 padding-0">
            <iw-date-picker #txtDateRappel [isDisabled]="!isRappeler" [showWeekDay]="true"
                            class="iw-input col-xs-12 padding-0" formControlName="daterappel"></iw-date-picker>
        </div>
        <iw-checkbox #chkRappelDone [isDisabled]="isNew" [label]="'rappelEffectue' | translate"
                     class="iw-input col-xs-5 padding-0" formControlName="rappeldone"></iw-checkbox>
    </div>
    <!-- Side buttons, save and close -->
    <div class="col-xs-3 flex flex-v flex-between" style="height: calc(3 * 25px);">
        <div class="col-xs-12" style="max-height: 24px;"></div>
        <iw-button #btnOk (press)="saveChanges()" [isDisabled]="!isValid" [text]="'enregistrer' | translate"
                   class="iw-button btn-block" id="btnOk"></iw-button>
        <iw-button #btnCancel (press)="destroyForm()" [text]="'annuler' | translate"
                   class="iw-button btn-block"></iw-button>
    </div>

</form>
