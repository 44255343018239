import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppofa} from '@app/sam-base/models/placement';

import {InvoicesParametersComponent} from '../components/invoices/invoices-parameters/invoices-parameters.component';

export const INVOICES_PARAMS_DEFAULT_FORM: FormDescription<Ppofa> = {
    form: InvoicesParametersComponent, state: {
        ...buildDefaultState(Ppofa),
        diagTitle: 'invoices_param_form_title',
        diagModal: true,
        width: 850,
        editMode: 'edit',
        destroyOnSave: true
    }
};
