import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@sam-base/base';
import {Gepar} from '@sam-base/models/invoices';

@Component({
    selector: 'iw-tab-gepar-compta', templateUrl: './tab-gepar-compta.component.html'
})
export class TabGeparComptaComponent extends BasePartialFormComponent<Gepar> {

}
