import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base/base-tab-form.component';
import {EntityNavigationService, ModalService} from '@app/sam-base/core';
import {TabFormControl} from '@app/sam-base/core/form-handler/models/form-description.model';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {IwStoreService} from '@app/sam-base/core/store/iw-store.service';
import {ComboboxItem} from '@app/sam-base/models';
import {FormKeys} from '@app/sam-base/models/form-keys.model';
import {Saemp, Saempview} from '@app/sam-base/models/salary';
import {Saems} from '@app/sam-base/models/salary/saems';
import {Sagen} from '@app/sam-base/models/salary/sagen';
import {EntitiesToUpdateEnum, EntityUpdateService} from '@modules/sam-main/placement/services/entity-update.service';
import {IsSalaryCalculableResp} from '@modules/sam-main/salary/components/fiche-salaire/SalAllowResponce';
import {SalaryService} from '@modules/sam-main/salary/services/salary-service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {SaemsService} from '@sam-base/core/services/saems.service';
import {SaepService, SaepSubscription} from '@sam-base/core/services/saep.service';
import {SagenService} from '@sam-base/core/services/sagen.service';
import {ToastService} from '@sam-base/core/toast';
import {SaemsStatusEnum} from '@sam-base/models/salary/saems-status.enum';
import {saemsStatusResolver} from '@sam-base/models/salary/saemsview';
import {
    ModalSalaryChoiceComponent
} from '@shared/widgets/modal-components/modal-salarychoice/modal-salarychoice.component';
import {
    Choicemodal,
    ModalSalaryChoiceOptions
} from '@shared/widgets/modal-components/modal-salarychoice/modal-salarychoice.options';
import {Subject, takeUntil} from 'rxjs';
import {ficheSalaireTabsFormControls} from './fiche-salaire-tabs-formcontrols';

@Component({
    templateUrl: './fiche-salaire.component.html'
})
export class FicheSalaireComponent extends BaseTabFormComponent<Saems> implements OnInit, OnDestroy {

    public saemp?: Saemp;

    public sagen?: Sagen;
    public salIdcbo: ComboboxItem<string | undefined>[] = [];
    public empnom = '';
    public annee = '';
    public salcnt = 0;
    public subscriptions = new Subject();
    protected readonly _baseUrl: string;
    private isCalculable?: IsSalaryCalculableResp;
    private entityModifiable = false;




    constructor(store: IwStoreService, private readonly _entityNavService: EntityNavigationService,
                private _restService: RestApiService, private _sagenService: SagenService,
                private _saemsService: SaemsService, private _translate: TranslateService,
                private readonly _entityUpdateService: EntityUpdateService,
                private readonly _salaryService: SalaryService,
                private readonly _saepService: SaepService,
                private readonly _toastService: ToastService,
                private _modalService: ModalService, private _http: HttpClient) {
        super(store);
        this._baseUrl = environment.backendURL;
    }

    public get txtNetPayer() {
        if (this.salcnt > 0) {
            if (this.getFormValue('calculated')) {
                return this.getFormValue('mntnap');
            }
        }
        return 0;
    }

    public get txtTotalPayer() {
        if (this.salcnt > 0) {
            return this.getFormValue('mntpaid');
        }
        return 0;
    }

    public get txtReporter() {
        if (this.salcnt > 0) {
            return this.getFormValue('mntrep');
        }
        return 0;
    }

    public get status(): SaemsStatusEnum {
        return saemsStatusResolver(this.getFormData());
    }

    public get saemsEnumStatus(): string {
        return 'enum.saems.status.' + this.status;
    }

    public get ficheStatusCssClass(): string {
        return 'fiche-status-' + this.status;
    }

    public get periodeCssClass(): string {
        return 'fiche-sal-periode-status-' + this.sagen?.status;
    }

    public ngOnInit() {

        this.subscribeValueChange('salId', (salId) => {
            this.setFormValue('id', `${this.getFormValue('empId')}_${salId}`);
        })
        this.subscribeValueChange('id', (id?: string) => {
            // id is of format empId_salId i need to init a variable empId and salId from the id
            const [empId, salId] = (id || '').split('_');

            if (empId) {
                this._restService.getEntityClient(Saemp)
                    .getById(empId ?? '')
                    .subscribe(emp => {
                        this.saemp = emp;
                        this.empnom = emp.nom + ' ' + emp.prenom;
                        this.annee = (this.getFormValue('salId') + '').substring(0, 4);
                        this._saemsService.getSaemsByEmpIdAndSalIdStartingWith(this.saemp.empId || '', this.getFormValue('salId'))
                            .subscribe((list: Saems[]) => {
                                this.salIdcbo = list.map(elem => elem.salId)
                                    .map(option => ({
                                        name: option || '',
                                        value: option
                                    }));
                                this.salcnt = list.length;
                            });
                    })
            }
            if (salId) {
                this._sagenService.getSagenBySalId(salId)
                    .subscribe(sagen => {
                        this.sagen = sagen;
                    });
                this._saemsService.getSaemsData(empId, salId)
                    .subscribe((elem: Saems) => {
                        this.formGroup.patchValue(elem);
                    });
            } else {
                this.sagen = undefined;
            }
            this.canUpdateSaems(id);
        });
        this.canUpdateSaems(this.getFormValue('id'));

        // on update of SAEP
        this._saepService.saepSavedSub
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data: SaepSubscription) => {
                if (this.uuid === data.uuid && data.saved) {
                    this._saemsService.getSaemsData(this.getFormValue('empId'), this.getFormValue('salId')).subscribe(value => {
                        this.fillFormData(value);
                    });
                }
            });


    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public getTabsValues(): TabFormControl<Saems>[] {
        return ficheSalaireTabsFormControls;
    }

    public getTotalPayerClass() {
        if (this.salcnt > 0) {
            if (this.getFormValue('mntpaid') > this.getFormValue('mntnap')) {
                return 'fiche-total-payer-color-1';
            } else {
                return 'fiche-total-payer-color-2';
            }
        }
        return 'fiche-total-payer-color-def';
    }

    public getReporterClass() {
        if (this.salcnt > 0) {
            if (this.getFormValue('mntrep') !== 0) {
                return 'fiche-reporter-color-1';
            } else {
                return 'fiche-reporter-color-2';
            }
        }
        return 'fiche-reporter-color-def';
    }

    public openEmp() {
        this._entityNavService
            .navigateToEntityForm(Saempview, this.getFormValue('empId'));
    }

    protected getValidationType() {
        return Saems;
    }

    protected getFormControlNames(): FormKeys<Saems> {
        return this.getTabsFormControls(ficheSalaireTabsFormControls);
    }

    protected validateReadonlyStatus(e?: Saems) {
        if (!e) {
            return true;
        }
        return e.sent2fac || !this.entityModifiable;
    }


    private openSalIdEmpIdChoice(): Promise<Choicemodal | undefined> {
        const options: ModalSalaryChoiceOptions = {
            showCancel: true,
            title: this._translate.instant('salary'),
            width: 500,
            height: 280,
            okColor: 'white',
            cancelColor: 'red'
        };
        return this._modalService.showModal(ModalSalaryChoiceComponent, options);
    }

    private canUpdateSaems(id?: string) {
        if (!id) {
            return;
        }
        this._entityUpdateService.canUpdateEntity(EntitiesToUpdateEnum.SAEMS, id)
            .subscribe((flag: boolean) => {
                this.entityModifiable = flag;
                this.triggerValidation();
            });
    }

}
