import { HttpClient, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Sarep} from '@app/sam-base/models/salary/sarep';
import {SarepCentre} from '@app/sam-base/models/salary/sarepCentre';
import {FicheSalIndemnityView} from '@sam-base/models/salary/fiche-sal-indemnity-view';
import {FicheSalMissionView} from '@sam-base/models/salary/fiche-sal-mission-view';
import {FicheSalRapportView} from '@sam-base/models/salary/fiche-sal-rapport-view';
import {Observable} from 'rxjs';

import {environment} from '../../../../environments/environment';
import {RestClient} from '../rest-api/core';

@Injectable()
export class SarepService extends RestClient<any> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getSarepData(key: string): Observable<Sarep[]> {
        return this._http.get<Sarep[]>(this._baseUrl + `sarep/${key}`);
    }

    public saveSarep(sarep: Sarep): Observable<Sarep> {
        return this._http.post(this._baseUrl + `sarep/save`, sarep);
    }

    public getSarepCentreInfo(key: string): Observable<SarepCentre[]> {
        return this._http.get<SarepCentre[]>(this._baseUrl + `sarep/datacentre/${key}`);
    }

    public getSarepCentreInfoByCentype(key: string, centype: string): Observable<SarepCentre[]> {
        return this._http.get<SarepCentre[]>(this._baseUrl + `sarep/datacentre/${key}/${centype}`);
    }

    public deleteByKey(key: string, centre: string, centype: string) {
        return this._http.delete<number>(environment.backendURL + `sarep/${key}/${centre}/${centype}`);
    }

    public getMissionInfoForFicheSal(empId: string, salId: string): Observable<FicheSalMissionView[]> {
        let params = new HttpParams();
        params = params.append('empId', empId);
        params = params.append('salId', salId);
        return this._http.get<FicheSalMissionView[]>(this._baseUrl + 'sarep/findMissionInfoForFicheSal', {params: params});
    }

    public getRapportInfoForFicheSal(empId: string, salId: string): Observable<FicheSalRapportView[]> {
        let params = new HttpParams();
        params = params.append('empId', empId);
        params = params.append('salId', salId);
        return this._http.get<FicheSalRapportView[]>(this._baseUrl + 'sarep/findRapportInfoForFicheSal', {params: params});
    }

    public getindemnityInfoForFicheSal(empId: string, salId: string): Observable<FicheSalIndemnityView[]> {
        let params = new HttpParams();
        params = params.append('empId', empId);
        params = params.append('salId', salId);
        return this._http.get<FicheSalIndemnityView[]>(this._baseUrl + 'sarep/findIndemnityInfoForFicheSal', {params: params});
    }
}
