<iw-dialog [closable]="false"
           [draggable]="true"

           [modal]="true"
           [title]="'email_form_title' | translate"
           [visible]="true"
           [width]="800">
    <iw-busy [ajustLeft]="5"
             [ajustTop]="5"
             [isBusy]="isLoading"></iw-busy>
    <form [formGroup]="formGroup">
        <div class="row">
            <iw-dropdown [labelAlign]="'left'"
                         [label]="'de' | translate"
                         [options]="replyToOptions"
                         class="iw-input col-xs-12"
                         formControlName="replyTo"></iw-dropdown>
        </div>
        <div class="row">
            <iw-multi-select
                    (selectedFromList)="onSelectedFromListDestination($event)"
                    [allowNewEntries]="false"
                    [class.error]="destinations?.length === 0"
                    [defaultOptions]="config?.bcc"
                    [labelAlign]="'left'"
                    [label]="'bcc' | translate"
                    [matchValues]="matchSelectedEntries"
                    [textSource]="matchSourceName"
                    [textTarget]="matchSourceName"
                    [value]="destinations"
                    class="iw-input col-xs-12"></iw-multi-select>
        </div>
        <!-- CC (hidden for now as it does not seem to be useful for us now) -->
        <!--        <div class="row">-->
        <!--            <iw-multi-select-->
        <!--                    (selectedFromList)="onSelectedFromListDestinationCC($event)"-->
        <!--                    [(value)]="destinationsCC"-->
        <!--                    [allowNewEntries]="false"-->
        <!--                    [defaultOptions]="[]"-->
        <!--                    [labelAlign]="'left'"-->
        <!--                    [label]="'copie' | translate"-->
        <!--                    [matchValues]="matchSelectedEntries"-->
        <!--                    class="iw-input col-xs-12 iw-multi-small"></iw-multi-select>-->
        <!--        </div>-->
        <div class="row">
            <iw-textfield #txtMsg_subject
                          [labelAlign]="'left'"
                          [label]="'sujet' | translate"
                          class="iw-input col-xs-12"
                          formControlName="subject"></iw-textfield>

        </div>
        <div class="row">
            <iw-editor #edtMsg_text
                       (valueChange)="setBody($event)"
                       [(value)]="body"
                       [labelAlign]="'left'"
                       [label]="'message' | translate"
                       class="iw-input col-xs-12"></iw-editor>

        </div>
        <div class="row">
            <iw-checkbox #chkLgenSuivi
                         [isDisabled]="!suiviAllowed"
                         [label]="'genererUnSuivi' | translate"
                         class="iw-input col-xs-2"
                         formControlName="generateSuivi"></iw-checkbox>
            <p-message *ngIf="!suiviAllowed"
                       [text]="'suivis_not_possible_no_conseiller_linked' | translate"
                       class="col-xs"
                       severity="warn"></p-message>

        </div>
        <div class="row separator-top">
            <iw-editor *ngIf="signature"
                       [isDisabled]="true"
                       [labelAlign]="'left'"
                       [labelHeight]="'130px'"
                       [label]="'signature' | translate"
                       [value]="signature"
                       class="col-xs-12"></iw-editor>
            <div *ngIf="!signature"
                 class="no-signature col-xs-12"
                 style="font-weight: bold;"> {{ 'no_signature' | translate }}
            </div>
        </div>

        <div class="row seperator-top">
            <iw-uploader
                    (fileSelect)="addAttachment($event)"
                    [label]="'ajouter' | translate"
                    class=" col-xs-4"></iw-uploader>
        </div>

        <div *ngIf="attachments?.length > 0"
             class="row seperator-top">
            <p class="iw-label col-xs-2 no-margin">{{ 'attachments' | translate }}</p>
            <div class="col-xs">
                <div *ngFor="let att of attachments"
                     class="row">
                    <a [href]="att.resourceUrl"
                       class="col-xs-11"
                       target="_blank">{{ att.fileName }}</a>
                    <div class="col-xs-1">
                        <i (click)="removeAttachment(att)"
                           class="pi pi-times clickable"></i>
                    </div>
                </div>

            </div>
        </div>
        <div class="row between-xs seperator-top">
            <iw-button #btnCancel
                       (press)="closeForm()"
                       [text]="'annuler' | translate"
                       class="iw-button col-xs-2 btn-block"></iw-button>
            <iw-button #btnSend3
                       (press)="onSendClick()"
                       [isDisabled]="!canSend"
                       [text]="'envoyer' | translate"
                       class="iw-button col-xs-2 btn-block"></iw-button>

        </div>

    </form>

</iw-dialog>
