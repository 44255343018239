import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppsecteur} from '@app/sam-base/models/placement';

import {SecteurConfigComponent} from '../components/secteur-config/secteur-config.component';

export const PPSECTEUR_DEFAULT_FORM: FormDescription<Ppsecteur> = {
    form: SecteurConfigComponent, state: {
        ...buildDefaultState(Ppsecteur), diagModal: true, diagTitle: 'secteurs', width: 750, isNew: true
    }
};
