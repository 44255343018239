<form [formGroup]="formGroup" class="row space-form">

    <iw-textfield [labelAlign]="'top'" [label]="''" [value]="empNom" class="iw-input col-xs-4"
                  isDisabled="true"></iw-textfield>

    <iw-radio-list [(value)]="semstatus" [radioOptions]="checkboxes" class="iw-input col-xs-10"></iw-radio-list>

    <div class="row space-form col-xs-12 padding-top-20">
        <iw-button #btnCancel (press)="destroyForm()" [text]="'annuler' | translate"
                   class="iw-button col-xs-3 col-xs-offset-6"></iw-button>


        <iw-button #btnOk (press)="saveChanges()" [text]="'enregistrer' | translate"
                   class="iw-button col-xs-3"></iw-button>
    </div>

</form>
