import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';

import {PpclistatsComponent} from '../components';
import {Ppclistats} from '../models/ppclistats.model';

export const PPCLISTATS_DEFAULT_FORM: FormDescription<Ppclistats> = {
    form: PpclistatsComponent, state: {
        ...buildDefaultState(Ppclistats),
        diagTitle: 'title_ppclistats',
        isNew: true,
        destroyOnSave: true,
        editMode: 'edit'
    }
};
