import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {IwEventHubService, IwStoreService, ModalService} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {Gecli} from '@app/sam-base/models/invoices';
import {CommunicationType} from '@core/mail/mail.models';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import {TranslateService} from '@ngx-translate/core';
import {facturePrintRoles} from '@sam-base/core/auth/access-rules/config-menu';
import {
    factureComRoles, factureRWRoles
} from '@sam-base/core/auth/access-rules/facture-roles';
import {KeycloakService} from 'keycloak-angular';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
    templateUrl: './client-side-menu.component.html'
})
export class ClientSideMenuComponent extends BaseSideFormComponent<Gecli> implements OnInit, OnDestroy {
    public userId?: string;
    public gecli: Gecli = new Gecli();
    private subscription = new Subject();

    constructor(private readonly _store: IwStoreService, private readonly _modalService: ModalService,
                private _translate: TranslateService, private readonly _keycloak: KeycloakService,
                _restService: RestApiService, private readonly _eventHub: IwEventHubService<string>) {
        super();
    }

    public FACTURE_COMMUNICATION_ROLES: string[] = factureComRoles();
    public FACTURE_RW_ROLES: string[] = factureRWRoles()
    public FACTURE_PRINT_ROLES: string[] = facturePrintRoles()

    public ngOnInit() {
        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscription))
            .subscribe((data: any) => {
                this.gecli = data;
            });
        this.userId = this._keycloak.getUsername();
    }

    public ngOnDestroy() {
        this.subscription.next(undefined);
        this.subscription.complete();
    }

    /**
     * Function to open email form
     */
    public openEmailForm() {
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.GECLI_GENERIC,
            entityIds: [this.gecli.cliId ?? '']
        });
    }

    public async openSendSMSForm() {

    }

    public changeCliStatus() {
        this.showModal(this.gecli);
    }

    public async showModal(cli: Gecli | undefined) {
        if (!cli) {
            return;
        }
        const options: ModalMessageOptions = {
            message: [`${cli.clistatus === 1 ? this._translate.instant('desactivate_client') : this._translate.instant('activate_client')} `],
            showCancel: false,
            title: this._translate.instant('gecli_form_title')
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            cli.clistatus = cli.clistatus === 1 ? 2 : 1;
            this.saveCliStatus(cli);
        } catch (error) {
        }
    }

    public saveCliStatus(cli: Gecli) {
        this._store.dispatch(new actions.UpdateEntity<Gecli>(this.uuid, cli));
        setTimeout(() => {
            this._store.dispatch(new actions.SaveEntity(this.uuid));
            this._eventHub.emit('status_cliId', cli.clistatus);
        }, 0);
    }
}

