import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ClientStat} from '../models';
import {Ppclistats} from '../models/ppclistats.model';

export function clientStatToPpclistats(s: ClientStat): Ppclistats {
    return {
        cafixeht: s.fixeHt.toFixed(2),
        caht: s.ht.toFixed(2),
        coeff: s.coef.toFixed(2),
        coeffcustom: s.coefXY.toFixed(2),
        dateA: '',
        dateDe: '',
        firstMis: s.firstAssign,
        lastMis: s.lastAssign,
        margebrute: s.grossMargin.toFixed(2),
        nbcde: s.orders.toFixed(2),
        nbcdf: s.fixeOrders.toFixed(2),
        nbemp: s.employees.toFixed(2),
        nbheu: s.hours,
        nbmis: s.assignements.toFixed(2),
        nbplf: s.fixeInvestiments.toFixed(2),
        nbrap: s.reports
    };
}

@Injectable()
export class ClientStatsService {
    constructor(public _http: HttpClient) {
    }

    public calculateStats(cliId: string, start: Date, end: Date): Observable<ClientStat> {

        const iniDate = IwDateHelper.dateIsoString(start);
        const endDate = IwDateHelper.dateIsoString(end);

        // eslint-disable-next-line max-len
        return this._http.get<ClientStat>(`/api/clistat?id=${cliId}&dateIni=${iniDate}&dateEnd=${endDate}`);

    }

    public calculatePPCliStats(cliId: string, start: Date, end: Date): Observable<Ppclistats> {
        return this.calculateStats(cliId, start, end)
            .pipe(map(clientStatToPpclistats));
    }
}
