<div class="row">
    <iw-busy [isBusy]="isLoading"></iw-busy>
    <div class="col-xs-10"
         style="height: 200px;">
        <iw-rest-grid #ppconConfigGrid
                      (selected)="getSelectedRow($event)"
                      (sortInfo)="setSortInfo($event)"
                      *ngIf="canLoadTable"
                      [autoFormClick]="false"
                      [defaultSelected]="true"
                      [hasFilter]="true"
                      [hasGridColumnMenu]="false"
                      [queryStatements]="query"
                      [selectedData]="selectedData"
                      [sortDir]="sortPropDir"
                      [sortProp]="sortProp"
                      [type]="type"></iw-rest-grid>
    </div>

    <div class="col-xs-2 flex flex-v flex-evenly">
        <iw-button (press)="createNewEntry()"
                   [isDisabled]="!isReadonly"
                   [text]="'sidemenu_new' | translate"
                   class="btn-block"
                   name="nouveau"></iw-button>
        <iw-button (press)="modifyEntry()"
                   [isDisabled]="!isReadonly || !selectedRow"
                   [text]="'sidemenu_edit' | translate"
                   class="btn-block"
                   id="modif-btn"></iw-button>
        <iw-button (press)="openRemoveConsModal()"
                   [isDisabled]="isDeleteDisabled"
                   [text]="'sidemenu_delete' | translate"
                   class="btn-block"></iw-button>
        <iw-button (press)="saveEntry()"
                   [isDisabled]="isReadonly || !validateFields()"
                   [text]="'sidemenu_save' | translate"
                   class="btn-block"
                   id="save-btn"
                   name="save"></iw-button>
        <iw-button (press)="cancelEditionMode()"
                   [isDisabled]="isReadonly"
                   [text]="'sidemenu_cancel' | translate"
                   class="btn-block"></iw-button>
    </div>

</div>
<form [formGroup]="formGroup"
      class="row">
    <div class="row col-xs-10 no-padding">
        <iw-textfield #txtCon_id
                      [isDisabled]="true"
                      class="iw-input col-xs-2"
                      formControlName="conId"></iw-textfield>


        <iw-textfield #txtConNom
                      [isDisabled]="true"
                      class="iw-input col-xs-10"
                      formControlName="nom"></iw-textfield>

        <iw-textfield #cboAge_id
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'sam_user' | translate"
                      class="iw-input col-xs-12"
                      formControlName="samuserId"
                      id="samuser-input"></iw-textfield>

        <iw-enum-dropdown #cboPolitesse
                          [inputEnum]="cpolitesse"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'formule' | translate"
                          class="iw-input col-xs-10"
                          formControlName="cpolitesse"></iw-enum-dropdown>


        <iw-textfield #txtInitiales
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'initiales' | translate"
                      class="iw-input col-xs-2"
                      formControlName="initiales"></iw-textfield>


        <iw-agency-combo-box #cboAge_id
                             [isDisabled]="!newEntryMode"
                             [labelAlign]="'top'"
                             [label]="'agence' | translate"
                             class="iw-combobox col-xs-6 padding-left-5"
                             formControlName="ageId"></iw-agency-combo-box>

        <iw-phone-textfield #txtNatel
                            [isDisabled]="isReadonly"
                            [labelAlign]="'top'"
                            [label]="'natel' | translate"
                            class="iw-input col-xs-6"
                            formControlName="natel"></iw-phone-textfield>

        <iw-phone-textfield #txtTel
                            [isDisabled]="isReadonly"
                            [labelAlign]="'top'"
                            [label]="'tel' | translate"
                            class="iw-input col-xs-4"
                            formControlName="tel"></iw-phone-textfield>

        <iw-email-textfield #txtEmail
                            [isDisabled]="isReadonly"
                            [labelAlign]="'top'"
                            [label]="'email' | translate"
                            class="iw-input col-xs-8"
                            formControlName="email"></iw-email-textfield>
        <div class="col-xs-2 no">
            <iw-textfield #txtNom
                          [isDisabled]="isReadonly"
                          [label]="'nom' | translate"
                          class="iw-input col-xs-12"
                          formControlName="nom"></iw-textfield>

            <iw-textfield #txtPrenom
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'prenom' | translate"
                          class="iw-input col-xs-12"
                          formControlName="prenom"></iw-textfield>
        </div>
        <div class="col-xs-5 row">
            <div class="col-xs-6">
                <div class="form-subtitle"
                     style="font-size:13px;"> {{ 'documents_signature' | translate }}
                </div>
                <p-fileUpload #fileUploader
                              (uploadHandler)="uploadDocument($event, fileUploader)"
                              [accept]="'.png, .jpeg, .jpg'"
                              [chooseLabel]="'choose' | translate"
                              [disabled]="isReadonly"
                              [showCancelButton]="false"
                              [uploadLabel]="'upload' | translate"
                              customUpload="true"
                              invalidFileTypeMessageDetail=""
                              invalidFileTypeMessageSummary="Invalid"
                              name="myfile[]"
                              previewWidth="0"></p-fileUpload>
            </div>
            <div class="column col-xs-6">
                <div class="flex flex-center fixed-height">
                    <img (error)="removeSignature()"
                         *ngIf="!noSignature"
                         [src]="showSignature()"
                         alt="consSignature"
                         class="cons-img">
                    <div *ngIf="noSignature"
                         class="no-signature"> {{ 'noAdvisorSignature' | translate }}
                    </div>
                </div>
                <iw-button (press)="removeSignature() "
                           [isDisabled]="isReadonly || !getFormValue('signfName')"
                           [text]="'removeSignature' | translate"
                           class="padding-10 btn-block"></iw-button>
            </div>
        </div>

        <div class="col-xs-5 row  separator-left">
            <div class="col-xs-6">
                <div class="form-subtitle"
                     style="font-size:13px;"> {{ 'email_signature' | translate }}
                </div>
                <iw-button (press)="addEmailSignature() "
                           [isDisabled]="isReadonly"
                           [text]="'addSignature' | translate"
                           class="padding-10 btn-block"></iw-button>
            </div>
            <div class="column col-xs-6">
                <div class="flex flex-center fixed-height">
                    <div *ngIf="!!emailSignature"
                         class="iw-editor-wrapper">
                        <iw-editor [isDisabled]="true"
                                   [labelHeight]="'130px'"
                                   [value]="emailSignature"></iw-editor>
                    </div>
                    <div *ngIf="!emailSignature"
                         class="no-signature"> {{ 'no_signature' | translate }}
                    </div>
                </div>
            </div>
            <div class="column col-xs-6">
                <iw-button (press)="removeSignatureEmail() "
                           [isDisabled]="isReadonly || !getFormValue('emailSignature')"
                           [text]="'removeSignature' | translate"
                           class="padding-10 btn-block"></iw-button>
            </div>
        </div>
    </div>
    <div class="row col-xs-2 no-padding">
        <iw-button #Closebutton
                   (press)="closeDialog()"
                   [text]="'fermer' | translate"
                   class="iw-button btn-block flex-end"
                   id="closebutton"></iw-button>
    </div>
</form>
