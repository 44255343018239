<form [formGroup]="formGroup" class="row">

    <!-- Question 11 -->
    <div class="iw-single-label col-xs-1 padding-top-15">11.</div>
    <div class="iw-single-label col-xs-11 padding-top-15">
        {{ 'salary13month' | translate }}
    </div>

    <!-- Question 12 -->
    <div class="iw-single-label col-xs-1 padding-top-15">12.</div>
    <div class="iw-single-label col-xs-11 padding-top-15 padding-bottom-15">
        {{ 'contribuitionsLPP' | translate }}
    </div>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-checkbox #Standardlogic1 (valueChange)="setContribuitions($event)" [isDisabled]="isReadonly"
                 [label]="'yes' | translate" class="iw-input col-xs-2 padding-top-10"
                 formControlName="lsoumislpp"></iw-checkbox>
    <iw-textarea #txtContribuitionsLPP [height]="60" [isDisabled]="isReadonly || !contribuitionsLPP"
                 class="iw-textarea col-xs-8 padding-top-10" formControlName="cnolppwhy"
                 style="padding-left: 7px; padding-right: 7px"></iw-textarea>
    <div class="iw-single-label col-xs-1 padding-top-15"></div>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <div class="iw-single-label col-xs-2 padding-top-15 padding-bottom-15">
        {{ 'insurerLPP' | translate }}
    </div>
    <iw-textfield #txtInsurerLPP [isDisabled]="isReadonly" class="iw-input col-xs-5 padding-top-10"
                  formControlName="caisselpp"></iw-textfield>
    <div class="iw-single-label col-xs-4 padding-top-15"></div>

    <!-- Question 13 -->
    <div class="iw-single-label col-xs-1 padding-top-15">13.</div>
    <div class="iw-single-label col-xs-11 padding-top-15">
        {{ 'compensationAVS' | translate }}
    </div>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-textfield #txtCompensationsAVS [isDisabled]="isReadonly" class="iw-input col-xs-5 padding-top-10"
                  formControlName="caissecomp"></iw-textfield>
    <div class="iw-single-label col-xs-6 padding-top-15"></div>


    <!-- Question 14 -->
    <div class="iw-single-label col-xs-1 padding-top-15">14.</div>
    <div class="iw-single-label col-xs-9 padding-top-15">
        {{ 'allocationsEnfantsFormation' | translate }}
    </div>
    <iw-checkbox #Standardlogic2 (valueChange)="setAllowances($event)" [isDisabled]="isReadonly"
                 [label]="'yes' | translate" class="iw-input col-xs-1 padding-top-20"
                 formControlName="lallocdem"></iw-checkbox>
    <div class="iw-single-label col-xs-1 padding-top-15"></div>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <div class="iw-single-label col-xs-10 padding-top-15">
        <div style="display: flex;">
            <div class="padding-right-5" style="width: 35%">
                {{ 'allocationsEnfantsFormationReconnu' | translate }}
                <iw-checkbox #Standardlogic3 [isDisabled]="isReadonly" [label]="'yes' | translate"
                             class="iw-input padding-top-10" formControlName="lallocoui"></iw-checkbox>
            </div>
            <div style="width: 65%;">
                <!--Line 1-->
                <div style="display: flex;">
                    <iw-textfield #txt1 [decimals]="0" [isDisabled]="isReadonly || !allowances"
                                  class="iw-single-label col-xs-3" formControlName="allenfnb"
                                  type="number"></iw-textfield>
                    <div class="iw-single-label col-xs-1 padding-top-5" style="text-align: center;">X
                    </div>
                    <iw-textfield #txt2 [decimals]="2" [isDisabled]="isReadonly || !allowances"
                                  class="iw-single-label col-xs-3" formControlName="allenfpx"
                                  type="number"></iw-textfield>
                    <div class="iw-single-label col-xs-1 padding-top-5" style="text-align:center;">=
                    </div>
                    <iw-textfield #txt3 [decimals]="2" [isDisabled]="true" [value]="setCalculatedValue1()"
                                  class="iw-single-label col-xs-3" type="number"></iw-textfield>
                    <div class="iw-single-label col-xs-1"></div>
                </div>

                <!--Line 2-->
                <div style="display: flex;">
                    <iw-textfield #txt4 [decimals]="0" [isDisabled]="isReadonly || !allowances"
                                  class="iw-single-label col-xs-3" formControlName="allfornb"
                                  type="number"></iw-textfield>
                    <div class="iw-single-label col-xs-1 padding-top-5" style="text-align: center;">X
                    </div>
                    <iw-textfield #txt5 [decimals]="2" [isDisabled]="isReadonly || !allowances"
                                  class="iw-single-label col-xs-3" formControlName="allforpx"
                                  type="number"></iw-textfield>
                    <div class="iw-single-label col-xs-1 padding-top-5" style="text-align:center;">=
                    </div>
                    <iw-textfield #txt6 [decimals]="2" [isDisabled]="true" [value]="setCalculatedValue2()"
                                  class="iw-single-label col-xs-3" type="number"></iw-textfield>
                    <div class="iw-single-label col-xs-1"></div>
                </div>

                <!--Line 3-->
                <div style="display: flex;">
                    <iw-textfield #txt7 [decimals]="0" [isDisabled]="isReadonly || !allowances"
                                  class="iw-single-label col-xs-3" formControlName="allnb" type="number"></iw-textfield>
                    <div class="iw-single-label col-xs-1 padding-top-5" style="text-align: center;">X
                    </div>
                    <iw-textfield #txt8 [decimals]="2" [isDisabled]="isReadonly || !allowances"
                                  class="iw-single-label col-xs-3" formControlName="allpx" type="number"></iw-textfield>
                    <div class="iw-single-label col-xs-1 padding-top-5" style="text-align:center;">=
                    </div>
                    <iw-textfield #txt9 [decimals]="2" [isDisabled]="true" [value]="setCalculatedValue3()"
                                  class="iw-single-label col-xs-3" type="number"></iw-textfield>
                    <div class="iw-single-label col-xs-1"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="iw-single-label col-xs-1 padding-top-15"></div>

</form>
