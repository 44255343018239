import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Pppar} from '@app/sam-base/models/placement';

import {ParametresGenerauxComponent} from '../components/parametres-generaux/parametresGeneraux.component';

export const PPPAR_DEFAULT_FORM: FormDescription<Pppar> = {
    form: ParametresGenerauxComponent, state: {
        ...buildDefaultState(Pppar), diagTitle: 'pppar_form_title', diagShowSideMenu: true, diagSideMenu: {
            showCancel: false, showClose: true, showEdit: true, showList: false, showPrint: false, showDelete: false
        }, editMode: 'read', useNavigation: false, width: 1000, isNew: false
    }
};
