import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Pppar} from '@app/sam-base/models/placement';

/**
 * Tab Employe of parametres Generaux - SAM-4837
 */
@Component({
    selector: 'iw-tab-pppar-employe', templateUrl: './tab-pppar-employe.component.html'
})
export class TabPpparEmployeComponent extends BasePartialFormComponent<Pppar> {

    constructor() {
        super();
    }
}
