// eslint-disable-next-line max-classes-per-file
import {Component, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core';
import {FormKeys} from '@app/sam-base/models';
import {Sapar} from '@app/sam-base/models/salary';
import {Saparncsmot} from '@app/sam-base/models/salary/saparncsmot';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {CsMot, CsMot1201} from '../employee/tab-saemp-ncs/dialog-avancee/dialog-avancee.enum';

@Component({
    templateUrl: './saparncsmot.component.html'
})
export class SaparncsmotComponent extends BaseStoreFormComponent<Saparncsmot> implements OnInit {
    public sapar?: Sapar;

    public cpMotOptions = CsMot;
    public cpMot1201Options = CsMot1201;

    public cpActive = false;
    public cpActive3 = false;

    constructor(private readonly parameterService: ParametersService, private readonly _translate: TranslateService,
                public readonly store: IwStoreService) {
        super(store);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
    }

    private get checkboxControlNames(): Array<keyof Saparncsmot> {
        return [
            'cpMot1',
            'cpMot2',
            'cpMot3',
            'cpMot4',
            'cpMot5',
            'cpMot6',
            'cpMot7',
            'cpMot8',
            'cpMot9',
            'cpMot10',
            'cpMot110',
            'cpMot120'];
    }

    public ngOnInit(): void {
    }

    public changeCs120Options(event: string) {
        if (!event) {
            this.cpActive = false;
            this.cpActive3 = false;
        } else if (event === 'prealableSepare') {
            this.cpActive = true;
            this.cpActive3 = true;
        } else {
            this.cpActive = true;
            this.cpActive3 = false;
        }
    }

    public cpMot120Disabled(): boolean | undefined {
        return this.isReadonly || !(this.getFormValue('cpMot120') === '1');
    }

    protected getFormControlNames(): FormKeys<Saparncsmot> {
        return [
            'cpMot1',
            'cpMot11',
            'cpMot12',
            'cpMot2',
            'cpMot3',
            'cpMot31',
            'cpMot32',
            'cpMot4',
            'cpMot41',
            'cpMot42',
            'cpMot5',
            'cpMot51',
            'cpMot52',
            'cpMot53',
            'cpMot6',
            'cpMot61',
            'cpMot7',
            'cpMot71',
            'cpMot8',
            'cpMot81',
            'cpMot9',
            'cpMot10',
            'cpYear',
            'cpMot110',
            'cpMot111',
            'cpMot112',
            'cpMot120',
            'cpMot1201',
            'cpMot1201Rate',
            'cpMot1201CState'];
    }
}
