<form [formGroup]="formGroup"
      class="row">

    <iw-textarea #edtNote
                 [height]="140"
                 [isDisabled]="isReadonly"
                 [labelAlign]="'top'"
                 [label]="'notesRemarques' | translate"
                 class="iw-textarea col-xs-12"
                 formControlName="note"></iw-textarea>

    <iw-textarea #edtApprec
                 [height]="97"
                 [isDisabled]="isReadonly"
                 [labelAlign]="'top'"
                 [label]="'appreciationRating' | translate"
                 class="iw-textarea col-xs-12"
                 formControlName="apprec"></iw-textarea>

    <iw-textfield #txtUserTag
                  [isDisabled]="isReadonly"
                  [labelAlign]="'left'"
                  [label]="'tag' | translate"
                  [size]="'small'"
                  class="iw-input col-xs-4 no-padding padding-top-5 padding-bottom-5"
                  formControlName="usertag"></iw-textfield>

    <iw-checkbox #chkListenoir
                 [isDisabled]="isReadonly"
                 [label]="'listeNoire' | translate"
                 class="iw-input col-xs-12 no-padding"
                 formControlName="listenoir"></iw-checkbox>

    <iw-textarea #edtCmotifnoir
                 [height]="43"
                 [isDisabled]="checkListNoire()"
                 class="iw-textarea col-xs-12"
                 formControlName="cmotifnoir"></iw-textarea>

</form>
