<form [formGroup]="formGroup" class="row">
    <div class="row space-form col-xs-9">
        <iw-textfield #txtAnnee [isDisabled]="true" [noLabel]="true" [value]="this.sapar?.annee"
                      class="iw-input col-xs-12" style="text-align-last: center"></iw-textfield>

        <iw-textarea #txtData [height]="140" [isDisabled]="true" [value]="textAreaData" class="iw-textarea col-xs-12"
                     style="padding-left: 0.5rem; padding-right: 0.5rem; padding-top: 15px"></iw-textarea>

        <iw-dropdown #drpTableMj4 (valueChange)="setValues($event)" [isDisabled]="readonly" [noLabel]="true"
                     [options]="dropOptions" class="iw-input col-xs-5 padding-top-15"></iw-dropdown>

        <iw-textfield #txtData [isDisabled]="true" [value]="title"
                      class="iw-input col-xs-2 padding-top-15"></iw-textfield>

        <iw-button #btnCopy (press)="copyData()" [isDisabled]="readonly" class="col-xs-1 padding-top-15"
                   iconClass="fas fa-copy"></iw-button>

        <iw-button #btnDel (press)="removeData()" [isDisabled]="readonly" class="col-xs-1 padding-top-15"
                   iconClass="fas fa-trash"></iw-button>
    </div>

    <div class="col-xs-3 flex flex-v separator-left">
        <iw-button #btnModif (press)="modifyEntry()" *ngIf="readonly" [isDisabled]="!readonly"
                   [text]="'sidemenu_edit' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnSave (press)="saveEntry()" *ngIf="!readonly" [isDisabled]="!formGroup.valid"
                   [text]="'sidemenu_save' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnCancel (press)="cancelEditionMode()" *ngIf="!readonly" [text]="'sidemenu_cancel' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnClose (press)="closeDialog()" [text]="'fermer' | translate" class="iw-button btn-block"
                   style="margin-block-start: auto"></iw-button>
    </div>
</form>
