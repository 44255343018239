export interface EmployeeDeclarationEditOptions {
    employeeDeclarationId: string;
    year?: string;
    readonly?: boolean;
}

export class EmployeeDeclarationSplit {
    public year?: string;
    public amount?: number;
}
