import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Sais} from '@app/sam-base/models/salary/sais';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class SaisService extends RestClient<any> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getSaisByBaremeCantonAndAnnee(canton: string, bareme: string, annee: string): Observable<Sais[]> {
        const req = {
            annee, bareme, canton
        };
        return this._http.post<Sais[]>(this._baseUrl + `sais/byAnneeCantonAndBareme`, req);
    }

    public majCodes(canton: string, annee: string): Observable<void> {
        const req = {
            annee, canton
        };
        return this._http.post<void>(this._baseUrl + `sais/majCodes`, req);
    }
}
