<form [formGroup]="formGroup" class="row">
    <div class="form-label col-xs-4 no-padding">
        <div class="col-xs-12 flex flex-center no-padding-right">
            <hr class="header-line">
            <div class="header-line-color">{{ 'ra' | translate }}</div>
            <hr class="header-line">
        </div>
        <div class="row">
            <iw-dropdown #cboRa_dedmod [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'mode' | translate"
                         [options]="raDedmodOptions" class="iw-input col-xs-12"
                         formControlName="raDedmod"></iw-dropdown>
        </div>
    </div>
    <div class="form-label col-xs-4 no-padding">
        <div class="col-xs-12 flex flex-center no-padding">
            <hr class="header-line">
            <div class="header-line-color">{{ '[%]' }}</div>
            <hr class="header-line">
        </div>
        <div class="row">
            <iw-textfield #cboRa_tx_emp [isDisabled]="!cboRaTxEmpSocEnabled()" [labelAlign]="'top'"
                          [label]="'ppemp' | translate" class="iw-input col-xs-6 no-padding-right"
                          formControlName="raTxEmp" type="number"></iw-textfield>
            <iw-textfield #txtRa_tx_soc [isDisabled]="!cboRaTxEmpSocEnabled()" [labelAlign]="'top'"
                          [label]="'employeur' | translate" class="iw-input col-xs-6 no-padding-left"
                          formControlName="raTxSoc" type="number"></iw-textfield>
        </div>
    </div>
    <div class="form-label col-xs-4 no-padding">
        <div class="col-xs-12 flex flex-center no-padding-left">
            <hr class="header-line">
            <div class="header-line-color">{{ 'frs_heure' | translate }}</div>
            <hr class="header-line">
        </div>
        <div class="row">
            <iw-textfield #txtRa_mnt_emp [isDisabled]="!cboRaMntEmpSocEnabled()" [labelAlign]="'top'"
                          [label]="'ppemp' | translate" class="iw-input col-xs-6 no-padding-right"
                          formControlName="raMntEmp" type="number"></iw-textfield>
            <iw-textfield #txtRa_mnt_soc [isDisabled]="!cboRaMntEmpSocEnabled()" [labelAlign]="'top'"
                          [label]="'employeur' | translate" class="iw-input col-xs-6 no-padding-left"
                          formControlName="raMntSoc" type="number"></iw-textfield>
        </div>
    </div>
    <div class="row col-xs-12 no-padding">
        <div #cboRa_gs_cotLabel class="iw-label col-xs-3"
             title="{{'deductionEmploye' | translate}}">{{ 'deductionEmploye' | translate }}
        </div>
        <iw-sags-dropdown #cboRa_gs_cot [isDisabled]="isReadonly" [noLabel]="true" [types]="['P']"
                          class="iw-input  col-xs-9" formControlName="raGsCot" id="raGsCot"></iw-sags-dropdown>
        <div #cboRa_gs_coeLabel class="iw-label col-xs-3"
             title="{{'deductionEmployeur' | translate}}">{{ 'deductionEmployeur' | translate }}
        </div>
        <iw-sags-dropdown #cboRa_gs_coe [isDisabled]="isReadonly" [noLabel]="true" [types]="['Q']"
                          class="iw-input col-xs-9" formControlName="raGsCoe" id="raGsCoe"></iw-sags-dropdown>
        <div #cboRa_gs_cofLabel class="iw-label col-xs-3"
             title="{{'correctionEmploye' | translate}}">{{ 'correctionEmploye' | translate }}
        </div>
        <iw-sags-dropdown #cboRa_gs_cof [isDisabled]="isReadonly" [noLabel]="true" [types]="['2', 'M']"
                          class="iw-input col-xs-9" formControlName="raGsCof"></iw-sags-dropdown>
        <div #cboRa_gs_cfeLabel class="iw-label col-xs-3"
             title="{{'correctionEmployeur' | translate}}">{{ 'correctionEmployeur' | translate }}
        </div>
        <iw-sags-dropdown #cboRa_gs_cfe [isDisabled]="isReadonly" [noLabel]="true" [types]="['9', 'N']"
                          class="iw-input col-xs-9" formControlName="raGsCfe"></iw-sags-dropdown>
        <div #cboRa_gs_basLabel class="iw-label col-xs-3" title="{{'base' | translate}}">{{ 'base' | translate }}
        </div>
        <iw-sags-dropdown #cboRa_gs_bas [isDisabled]="isReadonly" [noLabel]="true" [types]="['O']"
                          class="iw-input col-xs-9" formControlName="raGsBas"></iw-sags-dropdown>
    </div>
</form>