export enum SxStatus {
    INIT = 'INIT',
    INIT_ERROR = 'INIT_ERROR',
    SALARY_DECLARATION_SENT = 'SALARY_DECLARATION_SENT',
    GET_STATUS_OK = 'GET_STATUS_OK',
    GET_STATUS_ERROR = 'GET_STATUS_ERROR',
    GET_STATUS_PROCESSING = 'GET_STATUS_PROCESSING',
    DONE = 'DONE',
    MERGED = 'MERGED',
}
