<form [formGroup]="formGroup"
      class="row notes">

    <iw-date-picker #txtDatecontra
                    [isDisabled]="isReadonly"
                    [labelAlign]="'top'"
                    [label]="'ctaDate' | translate"
                    class="iw-input col-xs-2"
                    formControlName="datecontra"></iw-date-picker>

    <iw-textfield #txtRefInterne
                  [isDisabled]="isReadonly"
                  [labelAlign]="'top'"
                  [label]="'notreRef' | translate"
                  class="iw-input col-xs-3"
                  formControlName="refinterne"></iw-textfield>

    <iw-textfield #txtRefClient
                  [isDisabled]="isReadonly"
                  [labelAlign]="'top'"
                  [label]="'referenceClient' | translate"
                  class="iw-input col-xs-4"
                  formControlName="refclient"></iw-textfield>

    <iw-textfield #txtCliemp_id
                  [isDisabled]="isReadonly"
                  [labelAlign]="'top'"
                  [label]="'idEmployeClient' | translate"
                  class="iw-input col-xs-3"></iw-textfield>

    <div class="col-xs-12 separator-top"></div>

    <div class="row col-xs-6 separator-right no-padding">

        <div class="col-xs-12 flex flex-h no-padding">
            <iw-agency-combo-box #cboAge_id
                                 [isDisabled]="isReadonly || hasRapport"
                                 [labelAlign]="'top'"
                                 [label]="'agence' | translate"
                                 class="iw-input col-xs-4"
                                 formControlName="ageId"></iw-agency-combo-box>

            <iw-sector-combo-box #cboSec_id
                                 [isDisabled]="isReadonly || hasRapport"
                                 [labelAlign]="'top'"
                                 [label]="'nomsecteur' | translate"
                                 class="iw-input col-xs-4"
                                 formControlName="secId"
                                 id="cboSec_id"></iw-sector-combo-box>

            <iw-conseiller-dropdown #cboCon_id
                                    [isDisabled]="isReadonly || hasRapport"
                                    [labelAlign]="'top'"
                                    [label]="'conseiller' | translate"
                                    class="iw-combobox col-xs-4 padding-right-5"
                                    formControlName="conId"></iw-conseiller-dropdown>
        </div>

        <iw-multiautocomplete-qualif *ngIf="suvaPeActive"
                                     [isDisabled]="isReadonly || hasRapport"
                                     [labelAlign]="'top'"
                                     [label]="'professionQualification' | translate"
                                     class="iw-input padding-left-5 padding-right-5 col-xs-12"
                                     formControlName="quaId"
                                     id="cboQuaId"></iw-multiautocomplete-qualif>

        <iw-textfield-autocomplete #txtProfession
                                   (doubleClick)="openQualifSelection()"
                                   *ngIf="!suvaPeActive"
                                   [doubleClickable]="true"
                                   [fetchAction]="profFetchAction"
                                   [forceSelection]="suvaPeActive"
                                   [isDisabled]="isReadonly"
                                   [labelAlign]="'top'"
                                   [label]="'professionQualification' | translate"
                                   [propsToFilter]="['qualif']"
                                   class="iw-input padding-left-5 padding-right-5 col-xs-12"
                                   formControlName="qualif"
                                   id="txtProfession"></iw-textfield-autocomplete>

        <iw-textfield-genretravail #txtGenretrav
                                   [isDisabled]="isReadonly"
                                   [labelAlign]="'top'"
                                   [label]="'genretrav' | translate"
                                   class="iw-input col-xs-12"
                                   formControlName="genretrav"></iw-textfield-genretravail>

        <iw-lookups-dropdown #cboDurType
                             [isDisabled]="isReadonly"
                             [labelAlign]="'top'"
                             [label]="'duree' | translate"
                             [lkname]="'MISDURTYPE'"
                             class="iw-input col-xs-4 no-padding-right"
                             formControlName="dureetype"
                             id="cboDurType"></iw-lookups-dropdown>
        <iw-textfield-lookups #txtDuree
                              [isDisabled]="isReadonly"
                              [label]="'motif' | translate"
                              [lookupName]="'DUREEMISSION'"
                              class="iw-input col-xs-8"
                              formControlName="duree"></iw-textfield-lookups>

        <iw-hours-input [isDisabled]="isReadonly"
                        [label]="'heure_debut' | translate"
                        class="iw-input col-xs-4"
                        formControlName="heuredebut"></iw-hours-input>

        <iw-textarea #needSummaryTxt
                     [allowMaximize]="false"
                     [height]="120"
                     [isDisabled]="isReadonly"
                     [isReadOnly]="true"
                     [label]="'schedule_event_change_ppmis' | translate"
                     class="iw-textarea col-xs-12"
                     formControlName="needSummary"
                     style="padding: 0 0.5rem"></iw-textarea>

        <iw-button #btnCreatNeed
                   (press)="openScheduleEventComp()"
                   [isDisabled]="isReadonly"
                   [text]="'schedule_event_change_ppmis_btn' | translate"
                   class="iw-button"
                   id="btnCreatNeed"
                   style="padding: 0 0.5rem"></iw-button>

    </div>
    <div class="row col-xs-6 no-padding">

        <iw-textfield #txtLieu1
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'lieuDeLaMission' | translate"
                      class="iw-input col-xs-11"
                      formControlName="lieu1"></iw-textfield>

        <iw-button #btnCopyAdr
                   (press)="copyAddressByClient()"
                   [isDisabled]="isReadonly"
                   [tooltip]="'copierAdrClient' | translate"
                   class="iw-input col-xs-1 no-padding without-label"
                   iconClass="fas fa-copy"
                   id="copy-cliAdr"></iw-button>

        <iw-textfield #txtLieu2
                      [isDisabled]="isReadonly"
                      class="iw-input col-xs-12"
                      formControlName="lieu2"></iw-textfield>

        <iw-textfield #txtLieu3
                      [isDisabled]="isReadonly"
                      class="iw-input col-xs-12"
                      formControlName="lieu3"></iw-textfield>

        <iw-textfield #txtmis_Npa
                      (controlFocus)="!$event ? getLieuByNpa() : ''"
                      [isDisabled]="isReadonly"
                      class="iw-input col-xs-4 no-padding-right padding-top-5"
                      formControlName="misNpa"></iw-textfield>

        <iw-textfield #txtmis_Lieu
                      [isDisabled]="isReadonly"
                      class="iw-input col-xs-8 no-padding-left padding-top-5"
                      formControlName="misLieu"></iw-textfield>

        <iw-textfield #txtService
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'serviceDepartement' | translate"
                      class="iw-input col-xs-12"
                      formControlName="service"></iw-textfield>

        <iw-textfield-cliatt #txtResp
                             (cliAttChanged)="setAtt($event)"
                             [cliId]="cliId"
                             [isDisabled]="!cliId || isReadonly"
                             [labelAlign]="'top'"
                             [label]="'responsableContact' | translate"
                             class="iw-input col-xs-12"
                             formControlName="resp"></iw-textfield-cliatt>

        <iw-textfield #txtTel
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'telephone' | translate"
                      class="iw-input col-xs-12"
                      formControlName="tel"></iw-textfield>

        <div class="col-xs-12 separator-top"></div>

        <iw-date-picker #txtDatefinp
                        [isDisabled]="isReadonly"
                        [labelAlign]="'top'"
                        [label]="'finPrevue' | translate"
                        [showWeekDay]="true"
                        [showWeekNumber]="true"
                        class="iw-input col-xs-6 no-padding-right"
                        formControlName="datefinp"></iw-date-picker>

        <iw-textfield #txtEmpAge
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'empAge' | translate"
                      [value]="employeeAge"
                      class="iw-input col-xs-6"></iw-textfield>

        <iw-textfield-lookups #txtFinMotif
                              [isDisabled]="isReadonly"
                              [labelAlign]="'top'"
                              [label]="'motifFin' | translate"
                              [lookupName]="'FINMISSION'"
                              class="iw-input col-xs-12"
                              formControlName="finmotif"></iw-textfield-lookups>

        <iw-date-picker #txtDateAlert
                        [isDisabled]="isReadonly"
                        [labelAlign]="'top'"
                        [label]="'dateAlerte' | translate"
                        [showWeekDay]="true"
                        [showWeekNumber]="true"
                        class="iw-input col-xs-6 no-padding-right"
                        formControlName="datealert"></iw-date-picker>

        <iw-button #btn7days
                   (press)="forwardDateBy1()"
                   [isDisabled]="isReadonly"
                   [text]="'1S' | translate"
                   class="iw-button col-xs-2 without-label"></iw-button>

        <iw-button #btn28days
                   (press)="forwardDateBy4()"
                   [isDisabled]="isReadonly"
                   [text]="'4S' | translate"
                   class="iw-button col-xs-2 without-label"></iw-button>

        <iw-button #btn84days
                   (press)="forwardDateBy12()"
                   [isDisabled]="isReadonly"
                   [text]="'12S' | translate"
                   class="iw-button col-xs-2 without-label"></iw-button>

        <iw-textfield #txtAlertMotif
                      *ngIf="showAlertMotif"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'motifAlert' | translate"
                      class="iw-input col-xs-12"
                      formControlName="alertMotif"></iw-textfield>
    </div>

    <div class="col-xs-12 separator-top"></div>

    <iw-textfield #txtCoeff
                  [isDisabled]="true"
                  [labelAlign]="'left'"
                  [label]="'coeff' | translate"
                  [ngClass]="coeffColor"
                  [status]="coeffStatus"
                  [value]="txtcoeffValue"
                  class="iw-input col-xs-5 no-padding-right"></iw-textfield>

    <iw-textfield #txtcoeffcalcmode
                  [isDisabled]="true"
                  [value]="coeffcalcmode"
                  class="iw-input col-xs-2 no-padding-left"></iw-textfield>

    <iw-textfield #txtMargeHor
                  [isDisabled]="true"
                  [value]="txtMergeHeur"
                  class="iw-input col-xs-5"></iw-textfield>
</form>
