import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Sags} from '@app/sam-base/models/salary';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const genreSalaireTabsFormControls: TabFormControl<Sags>[] = [
    {
        formControls: [
            'gsId',
            'titre']
    },
    {
        title: 'donnes_base',
        formControls: [
            'gsCtype',
            'baseBrut',
            'baseMj1',
            'baseMj2',
            'baseMj3',
            'baseMj4',
            'baseAvs',
            'avsacNb',
            'baseCna',
            'baseLac',
            'basePgm',
            'baseLpp',
            'baseImp',
            'baseCu1',
            'baseCu2',
            'baseCu3',
            'baseCu4',
            'retInd',
            'roundIt',
            'printIt',
            'salBase',
            'salHor',
            'salHormnt',
            'salHorlpp',
            'salLppmnt',
            'salJour',
            'corrmode',
            'cumformule',
            'gsOpp',
            'gsPaiPrv',
            'gsRepPrv',
            'lppprev',
            'lppfact',
            'lppretro']
    },
    {
        title: 'autreTab',
        formControls: [
            'csType',
            'cs06Type',
            'cs06Type2',
            'cptdebit',
            'cptcredit',
            'debitsplit',
            'creditsplit',
            'detdebit',
            'detcredit',
            'analytique',
            'basishmode',
            'sdsCaf']
    },
    {
        title: 'swissdec_title',
        formControls: [
            'sd4Statan',
            'sd4Statoct',
            'sd4Istype']
    },
    {
        title: 'version',
        formControls: ['gsId']
    }];
