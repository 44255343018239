<div [formGroup]="formGroup" class="row">

    <iw-textfield #txtDate_modif [labelAlign]="'top'" [label]="'modifie' | translate" [value]="dateMotif"
                  class="iw-input col-xs-4" isDisabled="true"></iw-textfield>

    <iw-textfield #txtDate_modif class="iw-input col-xs-4 flex-end" formControlName="userId"
                  isDisabled="true"></iw-textfield>

    <iw-tab #tabs [(selectedTab)]="selectedTab" [invalidTabs]="invalidTabList" [tabs]="tabList"
            class="col-xs-12 padding-top-20">

        <iw-tab-ppopttxt-page1 #tabGeneral *ngIf="selectedTab === tabList[0]" [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-ppopttxt-page1>

        <iw-tab-ppopttxt-page2 #tabGeneral *ngIf="selectedTab === tabList[1]" [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-ppopttxt-page2>

        <iw-tab-ppopttxt-page3 #tabGeneral *ngIf="selectedTab === tabList[2]" [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-ppopttxt-page3>

        <iw-tab-ppopttxt-page4 #tabGeneral *ngIf="selectedTab === tabList[3]" [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-ppopttxt-page4>

        <iw-tab-ppopttxt-page5 #tabGeneral *ngIf="selectedTab === tabList[4]" [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-ppopttxt-page5>

        <iw-tab-ppopttxt-page6 #tabGeneral *ngIf="selectedTab === tabList[5]" [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-ppopttxt-page6>

        <iw-tab-ppopttxt-page7 #tabGeneral *ngIf="selectedTab === tabList[6]" [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-ppopttxt-page7>

        <iw-tab-ppopttxt-page8 #tabGeneral *ngIf="selectedTab === tabList[7]" [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-ppopttxt-page8>

        <iw-tab-ppopttxt-page9 #tabGeneral *ngIf="selectedTab === tabList[8]" [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-ppopttxt-page9>
    </iw-tab>
</div>
