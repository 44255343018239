import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestParams} from '@app/sam-base/core/rest-api/models/rest-params.model';
import {environment} from '@root/environments/environment';
import {Observable} from 'rxjs';

import {Attachment, CommunicationType, EmailRequest, MailConfigResponse} from './mail.models';

@Injectable()
export class MailService {

    constructor(private readonly _http: HttpClient) {
    }

    public getEmailConfig(entityIds: string[], communicationType: CommunicationType): Observable<MailConfigResponse> {
        const params: RestParams = {
            entityIds,
            communicationType
        };
        return (this._http.post<MailConfigResponse>(environment.backendURL + `mail/config/${communicationType}`, params));
    }

    public sendEmailRequest(emailRequest: EmailRequest): Observable<MailConfigResponse> {
        return (this._http.post<MailConfigResponse>(environment.backendURL + `mail/send/${emailRequest.communicationType}`, emailRequest));
    }

    public uploadAttachment(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        return (this._http.post<Attachment>(environment.backendURL + `mail/attachment/add`, formData));
    }

}
