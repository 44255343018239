<form [formGroup]="formGroup"
      class="row">
    <iw-textfield [isDisabled]="true"
                  [label]="'placement_number' | translate"
                  class="iw-input col-lg-2"
                  formControlName="plfId"></iw-textfield>

    <iw-textfield [isDisabled]="true"
                  [label]="'order_number' | translate"
                  class="iw-input col-lg-2"
                  formControlName="cdfId"></iw-textfield>

    <iw-button (press)="openCommande()"
               [isDisabled]="!getFormValue('cdfId')"
               [text]="'...'"
               class="iw-button col-lg-1"
               style="align-self: flex-end"></iw-button>

    <iw-textfield [isDisabled]="true"
                  [label]="'invoice_number' | translate"
                  class="iw-input col-lg-offset-1 col-lg-2"
                  formControlName="facNo"></iw-textfield>

    <iw-date-picker [isDisabled]="true"
                    [label]="'invoice_date' | translate"
                    [yearFrom]="1980"
                    [yearTo]="2050"
                    class="iw-input col-lg-2"
                    formControlName="facDate"></iw-date-picker>

    <iw-button (press)="openInvoicePreview()"
               [isDisabled]="!facId"
               [text]="'Facture'"
               class="iw-button col-lg-2"
               style="align-self: flex-end"></iw-button>


    <iw-entity-textfield #ctnSelCli
                         [allowSearch]="true"
                         [entity]="entityClient"
                         [isDisabled]="!isWriteMode"
                         [label]="'client' | translate"
                         [name]="['nom', 'prenom']"
                         [showIdField]="true"
                         class="iw-input col-xs-12"
                         formControlName="ctaCliId"></iw-entity-textfield>

    <iw-entity-textfield #ctnSelEmp
                         [allowSearch]="true"
                         [entity]="entityEmploye"
                         [isDisabled]="!isWriteMode"
                         [label]="'employee' | translate"
                         [name]="['nom', 'prenom']"
                         [showIdField]="true"
                         class="iw-input col-xs-12"
                         formControlName="ctaEmpId"></iw-entity-textfield>

    <iw-date-picker [isDisabled]="!isWriteMode"
                    [label]="'date' | translate"
                    [yearFrom]="1980"
                    [yearTo]="2050"
                    class="iw-input col-lg-2"
                    formControlName="ctaDate"></iw-date-picker>

    <iw-agency-combo-box [isDisabled]="!isWriteMode"
                         [label]="'agency' | translate"
                         class="iw-combobox col-lg-offset-1 col-lg-4"
                         formControlName="ageId"></iw-agency-combo-box>

    <iw-sector-combo-box [isDisabled]="!isWriteMode"
                         [label]="'sector' | translate"
                         class="iw-combobox col-lg-offset-1 col-lg-4"
                         formControlName="secId"></iw-sector-combo-box>

    <iw-conseiller-dropdown [isDisabled]="!isWriteMode"
                            [label]="'advisor' | translate"
                            class="iw-combobox col-lg-4"
                            formControlName="conId"></iw-conseiller-dropdown>


    <iw-textfield-autocomplete #txtProfession
                               (doubleClick)="openQualifSelection()"
                               [doubleClickable]="true"
                               [fetchAction]="profFetchAction"
                               [isDisabled]="!isWriteMode"
                               [labelAlign]="'top'"
                               [label]="'function' | translate"
                               [propsToFilter]="['qualif']"
                               class="iw-input padding-left-5 padding-right-5 col-xs-4"
                               formControlName="ctaFct"></iw-textfield-autocomplete>

    <iw-textfield [isDisabled]="!isWriteMode"
                  [label]="'salary' | translate"
                  class="iw-input col-lg-4"
                  formControlName="ctaSal"></iw-textfield>

    <iw-conseiller-dropdown [isDisabled]="!isWriteMode"
                            [label]="'sencond_advisor' | translate"
                            class="iw-combobox col-lg-3"
                            formControlName="conId2"></iw-conseiller-dropdown>

    <iw-textfield [decimals]="0"
                  [isDisabled]="!isWriteMode"
                  [label]="'[%]:'"
                  [pattern]="'[0-9]*'"
                  [stepValue]="1"
                  class="iw-input col-lg-1"
                  formControlName="conid2Pc"
                  type="number"></iw-textfield>

    <iw-date-picker [isDisabled]="!isWriteMode"
                    [label]="'start' | translate"
                    class="iw-input col-lg-2"
                    formControlName="ctaDebut"></iw-date-picker>

    <iw-textfield [isDisabled]="!isWriteMode"
                  [label]="'detail_calculation' | translate"
                  class="iw-input col-lg-offset-2 col-lg-4"
                  formControlName="ctaHon"></iw-textfield>

    <iw-template-dropdown #cboPlaFixe
                          [isDisabled]="!isWriteMode"
                          [labelAlign]="'top'"
                          [label]="'modele_contrat' | translate"
                          [report]="'PF'"
                          [size]="'large'"
                          class="iw-input iw-input col-xs-4 margin-top-10"
                          formControlName="plffrx"></iw-template-dropdown>
    <iw-button (press)="setFormValue('plffrx', undefined)"
               [isDisabled]="!isWriteMode"
               [text]="'raz' | translate"
               class="iw-button col-xs-1 flex-end"></iw-button>

    <iw-textfield [decimals]="3"
                  [isDisabled]="!isWriteMode"
                  [label]="'amount' | translate"
                  class="iw-input col-lg-offset-3 col-lg-4 align-number-left"
                  formControlName="ctaMnt"
                  labelAlign="top"
                  type="number"></iw-textfield>

    <iw-textfield [isDisabled]="!isWriteMode"
                  [label]="'laattention' | translate"
                  class="iw-input col-lg-4"
                  formControlName="attention"
                  labelAlign="top"></iw-textfield>

    <div class="col-xs-8"></div>

    <iw-textarea [height]="100"
                 [isDisabled]="!isWriteMode"
                 [label]="'remarks_client' | translate"
                 class="iw-textarea col-xs-6"
                 formControlName="ctaRemcli"
                 style="padding-left: 0.5rem; padding-right: 0.5rem; padding-top: 10px"></iw-textarea>

    <iw-textarea [height]="100"
                 [isDisabled]="!isWriteMode"
                 [label]="'remarks_internal' | translate"
                 class="iw-textarea col-xs-6"
                 formControlName="ctaRem"
                 style="padding-left: 0.5rem; padding-right: 0.5rem; padding-top: 10px"></iw-textarea>
</form>
