<form [formGroup]="formGroup">
    <iw-checkbox [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Factures avec BVR'" class="iw-input"
                 formControlName="bvractif"></iw-checkbox>
    <iw-checkbox [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'QR-Code'" class="iw-input"
                 formControlName="qractif"></iw-checkbox>

    <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'No adhérent banque'" class="iw-input"
                  formControlName="bvrnobnk"></iw-textfield>

    <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'No adhérent client'" class="iw-input"
                  formControlName="bvrnocli"></iw-textfield>

    <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Ext fichier BVR'" class="iw-input"
                  formControlName="bvrfileext"></iw-textfield>

    <iw-gepaimode-dropdown [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Mode de paiement par défaut'"
                           class="iw-input" formControlName="bvrMpaId"></iw-gepaimode-dropdown>

    <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'top'"
                  [label]="'Construction de la référence (client start;len;facture start;len;)'" class="iw-input"
                  formControlName="bvrrefdef"></iw-textfield>

    <iw-checkbox [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Séparé par date'" class="iw-input"
                 formControlName="bvrbydate"></iw-checkbox>

    <iw-checkbox [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Factors'" class="iw-input"
                 formControlName="factors"></iw-checkbox>

    <iw-textfield [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'factorsmod ?'" class="iw-input"
                  formControlName="factorsmod"></iw-textfield>

    <iw-gepaimode-dropdown [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Mode de paiement par défaut'"
                           class="iw-input" formControlName="ftrMpaId"></iw-gepaimode-dropdown>
    <iw-enum-dropdown [inputEnum]="CliMode" [isDisabled]="isReadonly" [labelAlign]="'top'"
                      [label]="'Numérotation des clients'" class="iw-input"
                      formControlName="ftrclimode"></iw-enum-dropdown>

</form>
