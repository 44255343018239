import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {yearsBetweenDates} from '@app/sam-base/helpers/date';

export const calcMissionEmployeeAge = (date1?: string, date2?: string) => {
    if (!date2) {
        date2 = IwDateHelper.dateIsoString(IwDateHelper.getMoment());
    }
    let y: string | number = '';
    let m: string | number = '';
    if (date1 && date2) {
        y = yearsBetweenDates(date1, date2)
            .toString();
        m = (IwDateHelper.dateMonth(new Date(date2)) - IwDateHelper.dateMonth(new Date(date1)));
        if (m < 0) {
            m = 12 + m;
        }
    }

    return y + ',' + m;
};
