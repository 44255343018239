<form [formGroup]="formGroup" class="row">
    <div class="row space-form col-xs-10">
        <iw-table #sarepCentreGrid (selectedChange)="selectedChanged($event)" [(selected)]="selectedElement"
                  [columns]="tableColumns" [data]="tableData" [defaultSelected]="true" [isFilterEnable]="false"
                  [isGridColumnMenuEnable]="false" [isSortIconEnable]="false" class="col-xs-12"
                  style="height: 180px"></iw-table>

        <div class="iw-label col-xs-2 padding-top-10">
            {{ 'create' | translate }}
        </div>
        <div class="iw-label col-xs-2 padding-top-10" style="margin-left: 10px">
            {{ 'dtAnyModif' | translate }}
        </div>
        <div class="iw-label col-xs-7 padding-top-10" style="margin-left: 10px">
            {{ 'facteurHM' | translate }}
        </div>

        <iw-textfield #txtCreate [isDisabled]="true" [noLabel]="true" [value]="xeDateCreate"
                      class="iw-input col-xs-2"></iw-textfield>
        <iw-textfield #txtModif [isDisabled]="true" [noLabel]="true" [value]="xeDateMofif" class="iw-input col-xs-2"
                      style="margin-left: 10px"></iw-textfield>
        <iw-textfield #txtFactsal [decimals]="2" [isDisabled]="true" [noLabel]="true" [value]="ifactsal"
                      class="iw-input col-xs-2" style="margin-left: 10px" type="number"></iw-textfield>
        <div class="col-xs-1" style="text-align-last: center; flex: none">
            {{ '/' }}
        </div>
        <iw-textfield #txtSalfull [decimals]="2" [isDisabled]="true" [noLabel]="true" [value]="ifactsalfu"
                      class="iw-input col-xs-2" type="number"></iw-textfield>
        <iw-textfield #txtTotalMax [isDisabled]="true" [noLabel]="true" [value]="totalmax" class="iw-input col-xs-2"
                      style="margin-left: 10px"></iw-textfield>

        <iw-dropdown #drpCentre *ngIf="pcentype !== '5'" [(value)]="xeCentre" [isDisabled]="readonly"
                     [label]="'centreAnalytique' | translate" [options]="optionCentre"
                     class="iw-input col-xs-4"></iw-dropdown>

        <iw-entity-textfield #ctnSelCli *ngIf="pcentype === '5'" [(value)]="xeCentre" [allowSearch]="true"
                             [entity]="clientType" [isDisabled]="readonly" [label]="'client' | translate"
                             [name]="['nom']" [showIdField]="false" class="iw-input"></iw-entity-textfield>

        <iw-textfield #txtRepartition [(value)]="xeValeur" [decimals]="2" [isDisabled]="readonly"
                      [label]="'repart' | translate" class="iw-input col-xs-2" style="margin-left: 20px"
                      type="number"></iw-textfield>
        <div class="col-xs-1" style="text-align-last: center; flex: none; margin-top: 27px;">
            {{ '/' }}
        </div>
        <iw-textfield #txtCenTotal [decimals]="2" [isDisabled]="true" [noLabel]="true" [value]="centotal"
                      class="iw-input col-xs-2 no-label" type="number"></iw-textfield>
    </div>

    <div class="col-xs-2 flex flex-v separator-left">
        <iw-button #btnNew (press)="createNewEntry()" *ngIf="readonly"
                   [isDisabled]="!readonly || selectedElement?.length" [text]="'sidemenu_new' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnModif (press)="modifyEntry()" *ngIf="readonly"
                   [isDisabled]="!readonly || !selectedElement?.length" [text]="'sidemenu_edit' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnSave (press)="saveEntry()" *ngIf="!readonly" [isDisabled]="!formGroup.valid"
                   [text]="'sidemenu_save' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>
        <iw-button #btnDel (press)="removeEntry()" *ngIf="readonly" [isDisabled]="!readonly || !selectedElement?.length"
                   [text]="'sidemenu_delete' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnCancel (press)="cancelEditionMode()" *ngIf="!readonly" [text]="'sidemenu_cancel' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnClose (press)="closeDialog()" [text]="'fermer' | translate" class="iw-button btn-block"
                   style="margin-block-start: auto"></iw-button>
    </div>
</form>
