<div [class.invert]="invert" class="iw-dashboard-toggle">
    <div (click)="onToggleClick()" class="arrow">
        <span><span [ngClass]="isOpen? 'fa-angle-double-left' :'fa-angle-double-right'" class="fas"></span></span>
    </div>
    <div class="menu-container">
        <iw-side-layout-menu [menuEntries]="menuEntries" [open]="isOpen"></iw-side-layout-menu>
        <!--        <p-panelMenu [model]="menuEntries"></p-panelMenu>-->

    </div>
</div>
