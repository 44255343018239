import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppcde} from '@app/sam-base/models/placement';

import {CdeNewComponent} from '../components/cdetempo/cdenew/cdenew.component';
import {CdetempoSideMenuComponent} from '../components/cdetempo/cdetempo-side-menu.component';
import {CdetempoComponent} from '../components/cdetempo/cdetempo.component';

export const PPCDETEMPO_DEFAULT_FORM: FormDescription<Ppcde> = {
    form: CdetempoComponent, sideMenu: CdetempoSideMenuComponent, state: {
        ...buildDefaultState(Ppcde),
        diagTitle: 'ppcdetempo_form_title',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showPrint: true, showDelete: true
        },
        useNavigation: true,
        patch: false,
        width: 1000
    }
};
export const CDENEW_DEFAULT_FORM: FormDescription<Ppcde> = {
    form: CdeNewComponent, state: {
        ...buildDefaultState(Ppcde), diagTitle: 'ppcdetempo_form_title', isNew: true, width: 460, destroyOnSave: true
    }
};
