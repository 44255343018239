import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {ParametersService} from '@core/services/parameters.service';
import {BaseStoreFormComponent} from '@sam-base/base';
import {EntityNavigationService, IwStoreService} from '@sam-base/core';
import {FactureService} from '@sam-base/core/services/facture.service';
import {FormKeys} from '@sam-base/models';
import {GefachdrNew} from '@sam-base/models/gefachdr-new';
import {Gepar} from '@sam-base/models/invoices';
import {Gecliview} from '@sam-base/models/invoices/gecliview';
import {Gefachdr} from '@sam-base/models/invoices/gefachdr';
import {FacTypeEnum} from '../facture-NC/facture-nc-factype.enum';

@Component({
    templateUrl: './facture-note-credit-new.component.html'
})
export class FactureNoteCreditNewComponent extends BaseStoreFormComponent<GefachdrNew> implements OnInit {

    public facTypeEnum = FacTypeEnum;
    public defaultOption = FacTypeEnum.standard;
    public clientType = Gecliview;
    public xeCentre?: string;
    public radioValues = [
        {
            label: 'facture',
            value: 'F',
            checked: true
        },
        {
            label: 'note_de_credit',
            value: 'N',
            checked: false
        }];
    private currentDate = new Date().getFullYear().toString() + new Date().getMonth().toString();
    private gepar?: Gepar;

    constructor(private _parameterService: ParametersService, private store: IwStoreService,
                private _factureService: FactureService, private _navigationService: EntityNavigationService) {
        super(store);
    }

    public get tvaPeriode(): string {
        return new Date().getFullYear()
            .toString() + '-' + new Date().getMonth()
            .toString();
    }

    public ngOnInit(): void {
        this.getGerpar();
        this.getPeriodeClass();
        this.setFormValue('facType', FacTypeEnum.standard);
        this.setFormValue('facOrnc', 'F');
    }

    public save() {
        this.setFormValue('creating', true);
        this._factureService.save(this.getFormData())
            .subscribe((gefachdr: Gefachdr) => {
                const facId = gefachdr.facId;
                this.setFormValue('facId', gefachdr.facId);
                this._navigationService.navigateToEntityForm(Gefachdr, facId || '', undefined, 'edit');
            });

        this.destroyForm();
    }

    public getPeriodeClass() {
        // todo check with jreuse, but as the tva periode is year + trimestre, this does not make sense
        if (this.gepar?.tvaperiode === this.currentDate) {
            return 'emp-status-saemp_active';
        } else {
            return 'emp-status-saemp_emp_not_ok';
        }
    }

    public formFullfilled() {
        return this.getFormValue('cliId') && this.getFormValue('ageId') && this.getFormValue('facType') && this.getFormValue('facOrnc');
    }

    protected getFormControlNames(): FormKeys<GefachdrNew> {
        return [
            'facId',
            [
                'ageId',
                new UntypedFormControl('', [Validators.required])],
            [
                'cliId',
                new UntypedFormControl('', [Validators.required])],
            [
                'facType',
                new UntypedFormControl('', [Validators.required])],
            [
                'facOrnc',
                new UntypedFormControl('', [Validators.required])],
            'creating'];
    }

    private getGerpar() {
        this._parameterService.getGepar().subscribe(gepar => {
            this.gepar = gepar;
            console.log('gepar', gepar);
        })
    }
}
