import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormHandlerService} from '@app/sam-base/core';
import {IwGridColumn} from '@app/sam-base/models';
import {SagsAud} from '@app/sam-base/models/salary';
import {SalaryForm} from '@modules/sam-main/salary/salary.forms';
import {SagsService} from '@modules/sam-main/salary/services/sags.service';

@Component({
    selector: 'iw-tab-sags-version', templateUrl: './tab-sags-version.component.html'
})
export class TabSagsVersionComponent extends BasePartialFormComponent<SagsAud> implements OnInit {

    public tableData: SagsAud[] = [];
    public tableColumns: IwGridColumn<SagsAud>[] = [{
        prop: 'gsId', name: 'gsId', index: 0
    }, {
        prop: 'titre', name: 'titre', index: 1
    }, {
        prop: 'revisionNumber', name: 'version', index: 2, width: 50
    }, {
        prop: 'revisionDate', name: 'date', type: 'dateTime', index: 3
    }];
    public loading = false;

    constructor(private readonly _formHandler: FormHandlerService<SalaryForm>, private readonly _sagsService: SagsService) {
        super();
    }

    public get version(): number {
        return this.getFormValue('revisionNumber') || 0;
    }

    public ngOnInit() {
        this.loadTableData();
    }

    public loadTableData() {
        const gsId = this.getFormValue('gsId') as string;
        if (!gsId) {
            return;
        }
        this._sagsService.getAllSagsVersions(gsId)
            .subscribe({
                next: data => {
                    this.tableData = data;
                    this.loading = false;
                }, error: () => this.loading = false
            });
    }


    public loadVersion(elements: SagsAud[]) {
        if (elements && elements[0]) {
            this._formHandler.showFormDialog(SalaryForm.GenreSalaireVersion, {
                gsId: this.getFormValue('gsId'), version: elements[0].revisionNumber
            });
        }

    }
}
