<form [formGroup]="formGroup" class="row">
    <iw-sags-dropdown #txtGsSalBru [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'salaire_brut' | translate"
                      [types]="['6']" class="iw-input col-xs-10 padding-top-15" formControlName="gsSalBru"
                      id="GsSalBru"></iw-sags-dropdown>
    <div class="col-xs-2"></div>

    <iw-sags-dropdown #txtGsSalBru [isDisabled]="isReadonly" [labelAlign]="'left'"
                      [label]="'totalDeductions' | translate" [types]="['6']" class="iw-input col-xs-10 padding-top-15"
                      formControlName="gsTotDed"></iw-sags-dropdown>
    <div class="col-xs-2"></div>

    <iw-sags-dropdown #txtGsSalNet [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'salaryNet' | translate"
                      [types]="['6']" class="iw-input col-xs-10 padding-top-15"
                      formControlName="gsSalNet"></iw-sags-dropdown>
    <div class="col-xs-2"></div>

    <iw-sags-dropdown #txtGsTotRet [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'totalRet' | translate"
                      [types]="['6']" class="iw-input col-xs-10 padding-top-15"
                      formControlName="gsTotRet"></iw-sags-dropdown>
    <div class="col-xs-2"></div>

    <iw-sags-dropdown #txtGsSalNap [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'netPayer' | translate"
                      [types]="['6']" class="iw-input col-xs-10 padding-top-15"
                      formControlName="gsSalNap"></iw-sags-dropdown>
    <div class="col-xs-2"></div>

    <iw-sags-dropdown #txtGsSalNeg [isDisabled]="isReadonly" [labelAlign]="'left'"
                      [label]="'salaryNegative' | translate" [types]="['3']" class="iw-input col-xs-10 padding-top-15"
                      formControlName="gsSalNeg"></iw-sags-dropdown>
    <div class="col-xs-2"></div>

    <iw-sags-dropdown #txtGsTotCoe [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'totalDedEmp' | translate"
                      [types]="['6']" class="iw-input col-xs-10 padding-top-15"
                      formControlName="gsTotCoe"></iw-sags-dropdown>
    <div class="col-xs-2"></div>

    <iw-sags-dropdown #txtGsNbH [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'nbHours' | translate"
                      [types]="['6']" class="iw-input col-xs-10 padding-top-15"
                      formControlName="gsNbH"></iw-sags-dropdown>
    <div class="col-xs-2"></div>

    <iw-sags-dropdown #txtGsNbJ [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'nbDays' | translate"
                      [types]="['6']" class="iw-input col-xs-10 padding-top-15"
                      formControlName="gsNbJ"></iw-sags-dropdown>
    <div class="col-xs-2"></div>

    <iw-sags-dropdown #txtGsNbJ [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'nbDaysAVS' | translate"
                      [types]="['6']" class="iw-input col-xs-10 padding-top-15 padding-bottom-10"
                      formControlName="gsNbJavs"></iw-sags-dropdown>
    <iw-button #btnGs (press)="openGenreSalaire()" [text]="'gs' | translate"
               class="col-xs-2 btn-block padding-top-15 padding-bottom-10"></iw-button>
</form>
