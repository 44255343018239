<form [formGroup]="formGroup" class="row">

    <div class="row col-xs-6 separator-right">
        <iw-cliadr-dropdown #cboCliadr_id (valueChange)="setClientAddress()" [isDisabled]="isReadonly"
                            [labelAlign]="'top'" [label]="'adresse' | translate" [resultFilters]="resultFilters"
                            [showId]="true" class="iw-input col-xs-12" formControlName="cliadrId"></iw-cliadr-dropdown>

        <iw-textfield-cliatt #txtAttn [cliId]="cliId" [isDisabled]="isReadonly" [labelAlign]="'top'"
                             [label]="'contratALattentionDe' | translate" class="iw-input col-xs-12"
                             formControlName="attn"></iw-textfield-cliatt>

        <iw-textarea #edtAdresse [height]="140" [isDisabled]="true" [value]="fullAddress" class="iw-textarea col-xs-12"
                     style="padding: 0 7px"></iw-textarea>

        <div class="row col-xs-12 separator-top no-padding">
            <iw-frxmis-combo-box #drpLocServ [isDisabled]="isReadonly" [labelAlign]="'top'"
                                 [label]="'locationDeServices' | translate" class="iw-combobox col-xs-10"
                                 formControlName="ccfrx"></iw-frxmis-combo-box>
            <iw-button (press)="resetLocServ()" [isDisabled]="isReadonly" [text]="'raz' | translate"
                       class="iw-button col-xs-2 flex-end"></iw-button>

            <iw-frxmis-combo-box #drpContMis [isDisabled]="isReadonly" [labelAlign]="'top'"
                                 [label]="'contratDeMission' | translate" class="iw-combobox col-xs-10"
                                 formControlName="cmfrx"></iw-frxmis-combo-box>
            <iw-button (press)="setFormValue('cmfrx', undefined)" [isDisabled]="isReadonly" [text]="'raz' | translate"
                       class="iw-button col-xs-2 flex-end"></iw-button>
        </div>
    </div>

    <div class="row col-xs-6">
        <iw-enum-dropdown #drpSUVA [inputEnum]="suvaDropdownOptions" [isDisabled]="isReadonly" [labelAlign]="'top'"
                          [label]="'codeSuva' | translate" [translateOptions]="false" class="col-xs-4"
                          formControlName="codecna"></iw-enum-dropdown>

        <div class="row col-xs-12 separator-top">
            <iw-textarea #edtFrais [height]="80" [isDisabled]="isReadonly" [labelAlign]="'top'"
                         [label]="'frais' | translate" class="iw-textarea col-xs-12"
                         formControlName="frais"></iw-textarea>
        </div>

        <div class="row col-xs-12 separator-top no-padding">
            <iw-textfield-chantiers #txtChantier [cliId]="cliId" [isDisabled]="isReadonly" [labelAlign]="'top'"
                                    [label]="'codeDeFacturationChantier' | translate" class="iw-input col-xs-12"
                                    formControlName="chantier"></iw-textfield-chantiers>

            <iw-checkbox #chkForceChant [isDisabled]="isReadonly" [labelAlign]="'right'"
                         [label]="'chantierObligatoire' | translate" class="iw-input col-xs-12"
                         formControlName="forcechant"></iw-checkbox>

            <iw-checkbox #chkFreezChant [isDisabled]="isReadonly || !getFormValue('chantier')" [labelAlign]="'right'"
                         [label]="'chantierNonModifiable' | translate" class="iw-input col-xs-12"
                         formControlName="freezchant"></iw-checkbox>

            <iw-checkbox #chkFactByRap [isDisabled]="isReadonly" [labelAlign]="'right'"
                         [label]="'uneFactureParRapport' | translate" class="iw-input col-xs-12"
                         formControlName="factbyrap"></iw-checkbox>
        </div>

        <div class="row col-xs-12 separator-top no-padding">
            <iw-textfield #txtCliTarif [isDisabled]="isReadonly" [labelAlign]="'top'"
                          [label]="'tarifHoraire' | translate" class="iw-input col-xs-4" formControlName="clitarif"
                          stepValue="0.01" type="number"></iw-textfield>


            <iw-checkbox #chkLallows [isDisabled]="isReadonly" [labelAlign]="'right'" [label]="'equipe' | translate"
                         class="iw-input col-xs-3 flex-end" formControlName="heurequipe"></iw-checkbox>

            <iw-textfield #txtMnteqemp [isDisabled]="isReadonly || !getFormValue('heurequipe')" [labelAlign]="'top'"
                          [label]="'paye' | translate" class="iw-input col-xs-2" formControlName="mnteqemp"
                          stepValue="0.01" type="number"></iw-textfield>


            <iw-textfield #txtMnteqcli [isDisabled]="isReadonly || !getFormValue('heurequipe')" [labelAlign]="'top'"
                          [label]="'facture' | translate" class="iw-input col-xs-3" formControlName="mnteqcli"
                          stepValue="0.01" type="number"></iw-textfield>
        </div>

    </div>
</form>
