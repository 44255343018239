<form [formGroup]="formGroup" class="row">
    <iw-textfield #txtCaissecomp [isDisabled]="isReadonly" [labelAlign]="'top'"
                  [label]="'caisseDeCompensation' | translate" class="iw-input col-xs-6"
                  formControlName="caissecomp"></iw-textfield>

    <iw-enum-dropdown #cboIlangue [inputEnum]="langue" [isDisabled]="isReadonly" [labelAlign]="'top'"
                      [label]="'langueCorrespondance' | translate" class="iw-input col-xs-6"
                      formControlName="langue"></iw-enum-dropdown>

    <iw-agency-combo-box #cboAge_id (selectedChanged)="ppagenceChange($event)" [isDisabled]="isReadonly"
                         [labelAlign]="'top'" [label]="'agence' | translate" class="iw-input col-xs-6"
                         formControlName="ageId"></iw-agency-combo-box>
    <iw-textfield #txtAge_id [isDisabled]="true" [labelAlign]="'top'" [label]="'' | translate" [value]="valueageId"
                  class="iw-input col-xs-2 no-label"></iw-textfield>
    <iw-textfield #txtAge_prefix [isDisabled]="true" class="iw-input col-xs-2 no-label"
                  formControlName="agePrefix"></iw-textfield>

    <iw-checkbox [isDisabled]="isReadonly" [label]="'activate_multi_window_feature' | translate"
                 class="col-xs-10 margin-top-20" formControlName="entityMultiWindow"></iw-checkbox>
</form>
