import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UntypedFormGroup, Validators} from '@angular/forms';

import {Js3} from '@app/modules/sam-main/salary/components/employee/tab-saemp-impot/recapissd4/recapissd4.model';
import {Saemp} from '@app/sam-base/models/salary';
import {DtaType, PayType} from '@sam-base/models/salary/payment-type-enum';
import {ValidatorService} from 'angular-iban';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class SaempService extends RestClient<any> {
    private readonly alphanumericIbanPattern = '^[a-zA-Z0-9]{1,34}$';

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public updateDeductions(empId: string, datenaiss: string, politiesse: string): Observable<Saemp> {
        return this._http.get(this._baseUrl + `saemp/${empId}/${datenaiss}/${politiesse}/deductions`);
    }

    public getRecalculation(annee: string | undefined): Observable<Saemp> {
        return this._http.get(this._baseUrl + `saemp/recalculation/${annee}`);
    }

    public getMajISDM(empId: string, annee: string): Observable<Saemp> {
        return this._http.get(this._baseUrl + `saemp/${empId}/${annee}/majISDM`);
    }

    public getRecapissd4(empId: string, annee: string): Observable<Js3[]> {
        return this._http.get<Js3[]>(this._baseUrl + `saemp/${empId}/${annee}/recap`);
    }

    public getSaempPayTypeByEmpId(empId: string): Observable<number> {
        return this._http.get<number>(this._baseUrl + `saemp/${empId}/paytype`);
    }

    public getLppHorByEmpId(empId: string): Observable<boolean> {
        return this._http.get<boolean>(this._baseUrl + `saemp/${empId}/lppHor`);
    }

    public saveSaempRemAVS(empId: string, remAVS: string): Observable<void> {
        return this._http.put<void>(this._baseUrl + `saemp/${empId}/${remAVS}/remavs`, undefined);
    }

    public getRemAVSByEmpId(empId: string): Observable<string> {
        return this._http.get(this._baseUrl + `saemp/${empId}/remavs`, {responseType: 'text'});
    }

    public checkIbanValidators(formGroup?: UntypedFormGroup, paytype?: PayType, dta?: DtaType) {
        if (!formGroup || Object.keys(formGroup.controls).length === 0) {
            return;
        }
        if (paytype === PayType.dta && dta === DtaType.iban) {
            formGroup.controls['iban'].clearValidators();
            formGroup.controls['banqueSwi'].clearValidators();
            formGroup.controls['iban']
                .setValidators([
                    ValidatorService.validateIban,
                    Validators.required,
                    Validators.pattern(this.alphanumericIbanPattern)]);
            formGroup.controls['banqueSwi']
                .setValidators(Validators.required);
            formGroup.controls['iban'].updateValueAndValidity();
            formGroup.controls['banqueSwi'].updateValueAndValidity();
        } else {
            formGroup.controls['iban'].clearValidators();
            formGroup.controls['banqueSwi'].clearValidators();
            formGroup.controls['iban'].updateValueAndValidity();
            formGroup.controls['banqueSwi'].updateValueAndValidity();
        }
    }

}
