import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('saparncsmot')
export class Saparncsmot {
    public cpEmpid?: string;
    @IwRestEntityId() public cpYear?: string;
    public cpMot1?: boolean;
    public cpMot11?: string;
    public cpMot12?: string;
    public cpMot2?: boolean;
    public cpMot3?: boolean;
    public cpMot31?: string;
    public cpMot32?: string;
    public cpMot4?: boolean;
    public cpMot41?: string;
    public cpMot42?: string;
    public cpMot5?: boolean;
    public cpMot51?: string;
    public cpMot52?: string;
    public cpMot53?: string;
    public cpMot6?: boolean;
    public cpMot61?: string;
    public cpMot7?: boolean;
    public cpMot71?: number;
    public cpMot8?: boolean;
    public cpMot81?: number;
    public cpMot9?: boolean;
    public cpMot10?: boolean;
    public cpMot110?: boolean;
    public cpMot111?: string;
    public cpMot112?: string;
    public cpMot120?: boolean;
    public cpMot1201?: string;
    public cpMot1201Rate?: number;
    public cpMot1201CState?: string;
}
