import {SamUserRole} from '@sam-base/models/admin/sam-user';


export function paramGenerauxRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN,
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_PLACEMENT_PARAM];
}

/**
 * Defines the roles that have access to the items on
 * statistics menu (all of them have same permissions)
 */
export function placementParamGenerauxRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_PARAM,
        SamUserRole.SAM_PLACEMENT_ADMIN];
}

export function factureParamGenerauxRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN
    ];
}

export function gestionModelesRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE
    ];
}

export function factureDeleteRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_DELETE
    ];
}

export function factureCommunicationRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_FACTURE_COM
    ];
}

export function facturePrintRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_FACTURE_PRINT
    ];
}


export function salaireParamGenerauxRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN
    ];
}

export function salaireParamGenerauxAdminRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN];
}

export function salaireParamGenerauxMenuRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN];
}

export function salaireEntreeSortiesMenuRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN,
        SamUserRole.SAM_SALAIRE_RW];
}

export function gestionDesDroitsData(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_ADMIN_USERS];
}

export function gestionDroitsConseillers(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_PARAM,
        SamUserRole.SAM_PLACEMENT_ADMIN];
}

export function gestionDroitsUsers(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_ADMIN_USERS];
}


export function periodActivePlacementRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_ADMIN_PERIOD,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}


export function periodActivefactureRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_FACTURE_ADMIN_PERIOD,
        SamUserRole.SAM_FACTURE_COMPTA,
        SamUserRole.SAM_FACTURE_PRINT,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

export function periodActiveSalaireRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_SALAIRE_RW,
        SamUserRole.SAM_SALAIRE_ADMIN,
        SamUserRole.SAM_SALAIRE_RO,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_COM,
        SamUserRole.SAM_SALAIRE_COMPTA,
        SamUserRole.SAM_SALAIRE_ADMIN_PERIOD];
}

export function periodFActureAccountingRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_COMPTA
    ];
}

export function periodAccountingRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_COMPTA
    ];
}

export function exerciceMgtRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_SALAIRE_ADMIN_PERIOD,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}
