import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Saconj} from '@app/sam-base/models/common';

import {SaempconjComponent} from '../components/saempconj/saempconj.component';

export const SAEMPCONJ_DEFAULT_FORM: FormDescription<Saconj> = {
    form: SaempconjComponent, state: {
        ...buildDefaultState(Saconj),
        diagModal: true,
        diagTitle: 'saempconj_form_title',
        editMode: 'waiting',
        width: 800
    }
};
