import {camelCase, mapKeys} from 'lodash';
import {Observable} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    ContextMenuEvent, EntityNavigationService, FormHandlerService, IwEventHubService
} from '@app/sam-base/core';
import {
    StoreEvents
} from '@app/sam-base/core/events/actions/store.actions';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {OptionalDictionary} from '@app/sam-base/models';
import {
    Ppclimis, Ppmis, Ppmismdl
} from '@app/sam-base/models/placement';
import {environment} from '@root/environments/environment';

import {Misnew} from '../components/missions/misnew/misnew.model';
import {PlacementForm} from '../placement.forms';

@Injectable()
export class MissionService extends RestClient<{}> {

    constructor(httpClient: HttpClient, private readonly _events: IwEventHubService<string>, private readonly _entityService: EntityNavigationService, private readonly _formHandler: FormHandlerService<any>) {
        super(httpClient);
    }

    public createMission(mission: Misnew): Observable<Ppmis> {
        return this._http
            .post(environment.backendURL + 'mission', mission);
    }

    public generateCCCM(misId: string): Observable<boolean> {
        return this._http
            .post<boolean>(environment.backendURL + `mission/${misId}/docs`, undefined);
    }

    public createMissionFromCde(nav: OptionalDictionary<any>, event: ContextMenuEvent<Ppmis>): void {
        const mission = {
            cdeId: nav['cdeId'],
            cliId: nav['cliId'],
            empId: event.value.empId,
            conId: nav['conId'],
            copylastmisdata: false,
            cpyfrommdl: false,
            createmodel: false
        };
        this.createMission(mission)
            .subscribe((mis: Ppmis) => {
                const newMis: Ppmis = mapKeys(mis, (value, key) => camelCase(key));
                if (!newMis || !newMis.misId) {
                    return;
                }

                this._entityService.navigateToEntityForm(Ppclimis, newMis.misId, undefined, 'edit');
                this._events
                    .emit(StoreEvents.EntitySaved, {$entity: 'ppmis', ...mis});
            });
    }

    public createMissionFromMisnew(event: ContextMenuEvent<Ppmis>): void {
        if (event.extraData === 'ppcli') {
            this._formHandler
                .showFormDialog(PlacementForm.Misnewdef, {cliId: event.value.cliId});
        } else {
            this._formHandler
                .showFormDialog(PlacementForm.Misnewdef, {empId: event.value.empId});
        }
    }

    public getAvailableModels(cliId?: string): Observable<Ppmismdl[]> {
        return this._http.get<Ppmismdl[]>(environment.backendURL + 'ppmismdl/models/' + cliId);
    }
}
