<form [formGroup]="formGroup" class="row space-form">

    <iw-textarea #edtMisnotecli [height]="50" [isDisabled]="isReadonly" [labelAlign]="'top'"
                 [label]="'remarquesContratDeLocationDeServices' | translate" [size]="'large'"
                 class="iw-textarea col-xs-12" formControlName="misnotecli"></iw-textarea>

    <iw-textarea #edtMisnoteemp [height]="50" [isDisabled]="isReadonly" [labelAlign]="'top'"
                 [label]="'remarquesContratDeMission' | translate" [size]="'large'" class="iw-textarea col-xs-12"
                 formControlName="misnoteemp"></iw-textarea>

    <iw-textarea #edtMisnoteint [height]="130" [isDisabled]="isReadonly" [labelAlign]="'top'"
                 [label]="'remarquesInternesMission' | translate" [size]="'large'" class="iw-textarea col-xs-12"
                 formControlName="misnoteint"></iw-textarea>

</form>
