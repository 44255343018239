import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base/base-partial-form.component';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {Ppcli} from '@app/sam-base/models/placement/ppcli';
import {PpcctmsComponent} from '@shared/widgets/modal-components/ppcctms/ppcctms.component';
import {PpclicatmsComponent} from '@shared/widgets/modal-components/ppclicatms/ppclicatms.component';

import {
    Clitarifmd,
    Facnpmode,
    Formejuri,
    Hpaufacmod,
    Hpausaimod,
    Langue,
    Modefactu,
    Shsupmode,
    TextSupMode
} from './tab-autre-enums';

@Component({
    selector: 'iw-tab-autre',
    templateUrl: './tab-autre.component.html',
    styleUrls: ['./tab-autre.component.scss']
})
export class TabAutreComponent extends BasePartialFormComponent<Ppcli> {

    @Input() public isReadonly = true;
    @Input() public entityId?: string;

    public facnpmode = Facnpmode;
    public clitarifmd = Clitarifmd;
    public hpaufacmodOptions = Hpaufacmod;
    public hpausaimodOptions = Hpausaimod;
    public langue = Langue;
    public modefactu = Modefactu;
    public shsupmode = Shsupmode;
    public formejuri = Formejuri;
    public textsupmode = TextSupMode;

    constructor(private _modalService: ModalService) {
        super();
    }

    public async openCttSelection() {
        try {
            const cttValue: string = this.getFormValue('cctids') || '';
            const cttList: string[] = cttValue.split(';')
                .filter((e) => e !== '');
            const res = await this._modalService.showModal(PpcctmsComponent, cttList);
            this.setFormValue('cctids', res);
        } catch (_) {
        }
    }

    public async openCategories() {
        try {
            const catValue: string = this.getFormValue('categids') || '';
            const catList: string[] = catValue.split(';')
                .filter((e) => e !== '');

            const res: any = await this._modalService.showModal(PpclicatmsComponent, catList);

            const ids = res.map((e: { id: any }) => e.id)
                .join(';');
            const titles = res.map((e: { title: any }) => e.title)
                .join(';');

            this.setFormValue('categids', ids);
            this.setFormValue('categories', titles);
        } catch (_) {
        }
    }
}
