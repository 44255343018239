import {IwRestEntity, IwRestEntityId} from '../core/rest-api';

@IwRestEntity('gefachdrNew')
export class GefachdrNew {
    @IwRestEntityId() public facId?: string;
    public ageId?: string;
    public cliId?: string;
    public facType?: string;
    public facOrnc?: string;
    public facNo?: string;
    public creating?: boolean;

}
