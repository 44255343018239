import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('saparass')
export class Saparass {
    @IwRestEntityId() @IwColumn() public annee?: string;
    @IwColumn({name: 'code', index: 0}) public assCode?: string;
    public dedCode?: string;
    public assNocli?: string;
    public assNocai?: string;
    public assNocta?: string;
    public assIdref?: string;
    @IwColumn({name: 'nom', index: 1}) public assNom?: string;
    public assAdr1?: string;
    public assAdr2?: string;
    public assNpa?: string;
    public assLieu?: string;
    public assPays?: string;
    @IwColumn({name: 'xml', index: 2}) public assSdcxml?: boolean;
    @IwColumn({name: 'distributeur', index: 2}) public assSdcdis?: boolean;
    public assData?: string;
    public rectype?: number;
    public assSubnc?: string;
    public validstart?: string;
    public ofsnr?: string;
    public lppentc?: string;
    public laaassu?: string;
    public avsLaaAssCode?: string;
    public avsLppAssCode?: string;
    public avsLaaFrom?: string;
    public avsLppFrom?: string;
    public incompleteDeclaration?: boolean;
}
