import {Component, OnDestroy, OnInit, Type, ViewChild} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwSmartGridComponent} from '@app/sam-base/components';
import {GridProfile, IwEventHubService} from '@app/sam-base/core';
import {LocalPpclimisRapStatusProfile} from '@app/sam-base/core/grid-profile/models/grid-profile-default';
import {EsQueryStatement, RestQueryParam} from '@app/sam-base/core/rest-api';
import {EntityNavigationService} from '@app/sam-base/core/services/entity-navigation.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys, IwGridColumn} from '@app/sam-base/models';
import {Ppcli, Ppclimis, Ppemp, Pprap} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {SystemLockService} from '@shared/services/system-lock.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    templateUrl: './rapports.component.html'
})
export class RapportsComponent extends BaseStoreFormComponent<Pprap> implements OnInit, OnDestroy {

    public date?: string;
    public type: Type<Ppclimis> = Ppclimis;
    public query: RestQueryParam<Ppclimis, string>[] = [];
    public selected?: Ppclimis;
    public statmsMissions: EsQueryStatement<Ppclimis>[] = [];

    public columns = this.getMissionsColumns();
    public tableWidth = '1700px';
    // Boolean to check if one row is selected
    public rowSelected = false;
    @ViewChild('grdMissions', {static: true}) public missionsGrid?: IwSmartGridComponent<Ppclimis>;
    private subscriptions = new Subject();

    constructor(private readonly _entityService: EntityNavigationService, store: IwStoreService,
                private readonly _systemLockService: SystemLockService,
                private readonly _events: IwEventHubService<string>, private readonly _translate: TranslateService) {
        super(store);
    }

    public get staments(): EsQueryStatement<Ppclimis>[] | undefined {
        return this.getData('statmsMissions');
    }

    public ngOnDestroy() {
        this._systemLockService.removeBlockProcess('rapports');
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public ngOnInit() {
        this._events.forType<string>('week_change')
            .pipe(takeUntil(this.subscriptions))
            .subscribe((e) => this.date = e.payload);
        this._events.forType('hour_grid_close')
            .pipe(takeUntil(this.subscriptions))
            .subscribe((e) => {
                this.missionsGrid?.refreshData();
            });
        this.missionsGrid?.grid?.setGridProfile(new GridProfile(LocalPpclimisRapStatusProfile));
        this.filterMissions();
    }

    /**
     * Get data from row selected
     *
     * @param event: Ppclimis[]
     */
    public getSelectedRow(event: Ppclimis[]) {
        this.selected = (event && event[0]) || undefined;
        this.rowSelected = true;
    }

    /**
     * Open mission form
     */
    public openMission() {
        if (this.selected && this.selected.misId) {
            const misId = this.selected.misId;
            this._entityService.navigateToEntityForm(Ppclimis, misId);
        }
    }

    /**
     * Open employe form
     */
    public openEmploye() {
        if (this.selected && this.selected.empId) {
            const empId = this.selected.empId;
            this._entityService.navigateToEntityForm(Ppemp, empId);
        }
    }

    /**
     * Open client form
     */
    public openClient() {
        if (this.selected && this.selected.cliId) {
            const cliId = this.selected.cliId;
            this._entityService.navigateToEntityForm(Ppcli, cliId);
        }
    }

    /**
     * Update missions data when date change
     *
     * @param query
     */
    public onSearch(query?: string) {
        this.date = query;
    }

    public async destroyForm() {
        super.destroyForm();
        this._systemLockService.removeBlockProcess('rapports');
    }

    /**
     * Default missions - misstatus < 4 => don't show missions with
     * archivee status
     */
    protected async filterMissions() {
        const statms: EsQueryStatement<Ppclimis>[] = [];
        const misCreation = EsQueryStatement.fromTerm({creating: true}, 'must_not');
        statms.push(misCreation);

        const misFactureMonth = EsQueryStatement.fromTerm({misfacmode: 3}, 'must_not');
        statms.push(misFactureMonth);
        const misSalraryMonth = EsQueryStatement.fromTerm({missalmode: 3}, 'must_not');
        statms.push(misSalraryMonth);

        if (this.staments) {
            statms.push(...this.staments);
        }
        this.statmsMissions = statms;
    }

    protected getFormControlNames(): FormKeys<Pprap> {
        return [
            'datelundi',
            'session',
            'salId',
            'misId',
            'rapId',
            'modecreat',
            'ijrapId',
            'ijAlert',
            'empId',
            'datasessionid'];
    }

    private getMissionsColumns(): IwGridColumn<Ppclimis>[] {
        return [
            {
                prop: 'misId',
                name: 'mission_no',
                index: 0
            },
            {
                prop: 'misstatustext',
                valueFormat: 'ppmis_status_',
                type: 'misStatusRap',
                name: this._translate.instant('status'),
                index: 1
            },
            {
                prop: 'nomclient',
                name: 'client',
                index: 2
            },
            {
                prop: 'empNom',
                name: 'nomemploye',
                index: 3
            },
            {
                prop: 'empPrenom',
                name: 'prenom',
                index: 3
            },
            {
                prop: 'datedebut',
                name: 'debut',
                index: 4,
                type: 'date'
            },
            {
                prop: 'datefin',
                name: 'fin',
                type: 'date',
                index: 5
            },
            {
                prop: 'chantier',
                index: 6
            },
            {
                prop: 'cctId',
                name: 'cct',
                index: 7
            },
            {
                prop: 'ageId',
                name: 'agency',
                index: 8
            },
            {
                prop: 'nomsecteur',
                name: 'nomsecteur',
                index: 9
            }];
    }
}
