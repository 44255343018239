export enum ImpotEnum {
    notSelected = 0, nonSoumis = 1, soumis = 2, soumisFrontalier = 3
}

export enum ModCalcEnum {
    selonTaux = 1,
    autoMensuel = 2,
    autoHorarire = 3,
    autoAnnuel = 4,
    autoMoisJour = 5,
    autoMoisNbJour = 6,
    swissdecAnnuel = 7,
    swissdecMensuel = 8,
}
