import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppmisih} from '@app/sam-base/models/placement';

import {MisheuspcComponent} from '../components/missions/tab-ppmis-ind/misheuspc/misheuspc.component';

export const MISHEUSPC_EDIT_FORM: FormDescription<Ppmisih> = {
    form: MisheuspcComponent, state: {
        ...buildDefaultState(Ppmisih),
        diagModal: true,
        diagTitle: 'misheu_edit_form_title',
        destroyOnSave: true,
        editMode: 'edit',
        width: 600
    }
};

export const MISHEUSPC_NEW_FORM: FormDescription<Ppmisih> = {
    form: MisheuspcComponent, state: {
        ...buildDefaultState(Ppmisih),
        diagModal: true,
        diagTitle: 'misheu_edit_form_title',
        destroyOnSave: true,
        isNew: true,
        width: 600
    }
};
