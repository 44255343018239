<form [formGroup]="formGroup" class="row">

    <iw-textarea #edtRemarques [height]="213" [isDisabled]="isReadonly" [labelAlign]="'top'"
                 [label]="'remarques' | translate" class="iw-textarea col-xs-10"
                 formControlName="remarques"></iw-textarea>


    <iw-textfield #txtCaissecho [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'caisseChomage' | translate"
                  class="iw-input col-xs-5" formControlName="caissecho"></iw-textfield>

    <iw-agency-combo-box #cboAge_id [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'agence' | translate"
                         class="iw-input col-xs-8" formControlName="ageId"></iw-agency-combo-box>

</form>
