<form [formGroup]="formGroup" class="row">
    <div class="row space-form col-xs-9">
        <iw-textfield #txtCanton [isDisabled]="true" [noLabel]="true" [value]="canton"
                      class="iw-input col-xs-3 padding-top-15" style="text-align-last: center"></iw-textfield>
        <iw-textfield #txtSalId [isDisabled]="true" [noLabel]="true" [value]="salId"
                      class="iw-input col-xs-3 padding-top-15" style="text-align-last: center"></iw-textfield>
        <div class="col-xs-1"></div>
        <iw-textfield #txtMois [isDisabled]="true" [noLabel]="true" [value]="imois"
                      class="iw-input col-xs-1 padding-top-15" style="text-align-last: center"></iw-textfield>
        <iw-textfield #txtYear [isDisabled]="true" [noLabel]="true" [value]="iyear"
                      class="iw-input col-xs-2 padding-top-15" style="text-align-last: center"></iw-textfield>
        <iw-textfield #txtEmp [isDisabled]="true" [noLabel]="true" [value]="empId"
                      class="iw-input col-xs-2 padding-top-15" style="text-align-last: center"></iw-textfield>

        <iw-date-picker #txtDebut [isDisabled]="readonly" [labelAlign]="'top'" [label]="'datedebut' | translate"
                        [value]="xdatede" class="col-xs-4 padding-top-10 no-padding-right"></iw-date-picker>
        <iw-date-picker #txtFin [isDisabled]="readonly" [labelAlign]="'top'" [label]="'datefin' | translate"
                        [value]="xdatea" class="col-xs-4 padding-top-10 no-padding-right"></iw-date-picker>
        <iw-textfield #txtJours [isDisabled]="true" [labelAlign]="'top'" [label]="'nbjours'" [value]="xnbjismois"
                      class="iw-input col-xs-2 no-padding-left padding-top-10"></iw-textfield>
        <div class="col-xs-2"></div>
        <iw-textarea #txtRecap [height]="200" [isDisabled]="true" [value]="xtextrecap"
                     class="iw-textarea col-xs-12"></iw-textarea>
    </div>
    <div class="col-xs-3 flex flex-v separator-left">
        <iw-button #btnModif (press)="modifyEntry()" *ngIf="readonly" [isDisabled]="!readonly"
                   [text]="'sidemenu_edit' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnSave (press)="saveEntry()" *ngIf="!readonly" [isDisabled]="!formGroup.valid"
                   [text]="'sidemenu_save' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnCancel (press)="cancelEditionMode()" *ngIf="!readonly" [text]="'sidemenu_cancel' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnClose (press)="closeDialog()" [text]="'fermer' | translate" class="iw-button btn-block"
                   style="margin-block-start: auto"></iw-button>
    </div>
</form>
