<form [formGroup]="formGroup" class="row">
    <div class="row col-xs-6" style="margin-block-end: auto">
        <iw-multiautocomplete-qualif #txtProfession [doubleClickable]="true" [isDisabled]="isReadonly"
                                     [labelAlign]="'top'" [label]="'professionQualification' | translate"
                                     [multiple]="true" class="iw-input col-xs-12" formControlName="profession"
                                     id="qualif"></iw-multiautocomplete-qualif>

        <iw-date-picker #txtDepuis [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'depuis' | translate"
                        class="col-xs-8 padding-top-5" formControlName="profDate"></iw-date-picker>
        <div class="col-xs-12 padding-top-5"></div>
        <iw-canton-dropdown #cboCantonTrav [isDisabled]="isReadonly" [labelAlign]="'top'"
                            [label]="'cantonTravail' | translate" class="iw-input col-xs-12"
                            formControlName="cantontrav"></iw-canton-dropdown>

    </div>

    <div class="row col-xs-6 separator-left">
        <iw-agency-combo-box #cboAge_id [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'agence' | translate"
                             class="iw-input col-xs-12 padding-top-5"
                             formControlName="categorie1"></iw-agency-combo-box>
        <iw-secteur-dropdown #cboSecteur [isDisabled]="isReadonly" [labelAlign]="'top'"
                             [label]="'nomsecteur' | translate" class="iw-input col-xs-12 padding-top-5"
                             formControlName="categorie2"></iw-secteur-dropdown>
        <iw-dropdown #drpTableMj1 [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'combo_cat_3' | translate"
                     [options]="optionTableMj3" class="iw-input col-xs-12 padding-top-5"
                     formControlName="categorie3"></iw-dropdown>
    </div>

    <div class="row padding-top-15" style="margin-left: 8px">
        <div class="iw-label col-xs-12">{{ 'repart_analityque' | translate }}</div>
        <iw-table #sarepGrid [(selected)]="selectedElement" [columns]="tableColumns" [data]="tableData"
                  [isFilterEnable]="false" [isGridColumnMenuEnable]="false" [isSortIconEnable]="false" class="col-xs-9"
                  style="height: 180px"></iw-table>

        <div class="col-xs-3">
            <iw-button #btnRepa1 (press)="onBtnRepa1Click()" [isDisabled]="!isReadonly"
                       [text]="'repartition1' | translate" class="iw-button col-xs-12 flex-end"></iw-button>
            <iw-button #btnRepa2 (press)="onBtnRepa2Click()" [isDisabled]="!isReadonly"
                       [text]="'repartition2' | translate" class="iw-button col-xs-12 flex-end"></iw-button>
            <iw-button #btnRepa3 (press)="onBtnRepa5Click()" [isDisabled]="!isReadonly"
                       [text]="'repartition5' | translate" class="iw-button col-xs-12 flex-end"></iw-button>
        </div>
    </div>
</form>
