import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Sagen} from '@app/sam-base/models/salary/sagen';
import {PeriodSideMenuComponent} from '../components/periode/periode-side-menu.component';
import {PeriodeComponent} from '../components/periode/periode.component';

export const SAGEN_DEFAULT_FORM: FormDescription<Sagen> = {
    form: PeriodeComponent,
    sideMenu: PeriodSideMenuComponent,
    state: {
        ...buildDefaultState(Sagen),
        diagTitle: 'periode',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showEdit: false,
            showCancel: false,
            showDelete: false
        },
        width: 800,
        editMode: 'edit'
    }
};
