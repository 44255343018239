<div [formGroup]="formGroup"
     class="row">
    <div class="row">
        <div class="row space-form col-xs-12">
            <iw-table #gepaicondGrid
                      (selectedChange)="selectedChanged($event)"
                      [columns]="tableColumns"
                      [data]="tableData"
                      [defaultSelected]="true"
                      [isFilterEnable]="false"
                      [isGridColumnMenuEnable]="false"
                      [isSortIconEnable]="false"
                      class="col-xs-10 padding-top-15"
                      style="height: 180px"></iw-table>
            <div class="space-form col-xs-2">
                <iw-button (press)="newEntry()"
                           [isDisabled]="isEditing || isNew"
                           [text]="'Nouveau' | translate"
                           class="iw-button padding-top-10"></iw-button>

                <iw-button #btnModif
                           (press)="editEntry()"
                           *ngIf="!isEditing"
                           [isDisabled]="!isSelected"
                           [text]="'sidemenu_edit' | translate"
                           class="iw-button padding-top-10"></iw-button>
                <iw-button (press)="saveEntry()"
                           *ngIf="isEditing"
                           [isDisabled]="!formGroup.valid"
                           [text]="'enregistrer' | translate"
                           class="iw-button padding-top-10"></iw-button>

                <iw-button (press)="deleteEntry()"
                           *ngIf="!isEditing"
                           [isDisabled]="!isSelected"
                           [text]="'Supprimer'"
                           class="iw-button padding-top-10"></iw-button>

                <iw-button #btnCancel
                           (press)="cancelEditionMode()"
                           *ngIf="isEditing"
                           [text]="'sidemenu_cancel' | translate"
                           class="iw-button btn-block padding-bottom-10"></iw-button>

                <iw-button (press)="closeDialog()"
                           [text]="'fermer' | translate"
                           class="iw-button padding-top-10"></iw-button>


            </div>
        </div>
        <div class="row space-form col-xs-12">
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'code' | translate"
                          [size]="'small'"
                          [value]="paiCond"
                          class="iw-input col-xs-2 padding-top-5"
                          formControlName="paiCondId"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'titre' | translate"
                          [size]="'small'"
                          [value]="titre"
                          class="iw-input col-xs-6 padding-top-5"
                          formControlName="libelle"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'nbjours' | translate"
                          [size]="'small'"
                          [value]="nbjours"
                          class="iw-input col-xs-4 padding-top-5 text-align-right"
                          formControlName="nbjours"
                          type="number"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'texte' | translate"
                          [size]="'small'"
                          [value]="paiCdText"
                          class="iw-input col-xs-6 padding-top-5"
                          formControlName="paiCdText"></iw-textfield>
        </div>
    </div>
</div>
