import {Component} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys} from '@app/sam-base/models';
import {Pppar} from '@app/sam-base/models/placement';
import {TabItem} from '@app/sam-base/models/tab-item.model';

import {ppopttxtTabsFormControls} from './ppopttxt-tabs-formcontrols';

@Component({
    templateUrl: './ppopttxt.component.html'
})
export class PpopttxtComponent extends BaseTabFormComponent<Pppar> {

    public get ppparId() {
        return this.getFormValue('ageId') || '';
    }

    public get dateMotif() {
        return IwDateHelper.dateIsoString(this.getFormValue('dateModif'));
    }

    public get tabData(): Partial<Pppar> | undefined {
        return this.getFormData();
    }

    public set tabData(v: Partial<Pppar> | undefined) {
        this.mergeEntityChanges(v);
    }

    public selectedTab: TabItem | undefined;

    constructor(store: IwStoreService) {
        super(store);
    }

    public onTabClick(tab: TabItem) {
        this.selectedTab = tab;
    }

    public getTabsValues(): TabFormControl<Pppar>[] {
        return ppopttxtTabsFormControls;
    }

    protected getFormControlNames(): FormKeys<Pppar> {
        {
            return this.getTabsFormControls(ppopttxtTabsFormControls);
        }
    }
}
