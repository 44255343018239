import {Component, Input} from '@angular/core';

import {IwEventHubService} from '../../../core/events';
import {MenuItem} from '../../../models';
import {LayoutEvents} from '../../events/events.models';

@Component({
    selector: 'iw-dashboard-toggle',
    templateUrl: './dashboard-toggle.component.html'
})
export class DashboardToggleComponent {
    public invert = true;

    @Input() public isOpen = false;

    @Input() public menuEntries: MenuItem[] = [];

    constructor(private _events: IwEventHubService<LayoutEvents>) {
    }

    public onToggleClick() {
        this._events.emit(LayoutEvents.TOGGLE_MENU);
    }

    public invertColors() {
        this.invert = !this.invert;
    }
}
