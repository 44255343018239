import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('sadedda')
export class Sadedda {
    @IwRestEntityId() public annee?: string;
    public dedCode?: string;
    @IwColumn({name: 'code', index: 0}) public txCode?: string;
    public imode?: number;
    public sexe?: string;
    @IwColumn({name: 'depuis', index: 1}) public ageDe?: number;
    @IwColumn({name: 'jusqua', index: 2}) public ageA?: number;
    public canton?: string;
    public cid?: string;
    @IwColumn({name: 'soumis', index: 3}) public soumis?: number;
    public soumiscode?: number;
}
