import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('sagen')
export class Sagen {
    @IwRestEntityId() public salId?: string;
    public notesbs?: string;
    public notesint?: string;
    public notessd4?: string;
    public status?: number;
    public dateOpen?: string;
    public dateClose?: string;
    public dateXfer?: string;
    public gestXfer?: string;
    public dateDe?: Date;
    public dateA?: Date;
    public nbsal?: number;
    public nbsalcalc?: number;
    public compte_nap?: string;
    public mntnap?: number;
    public mntpaid?: number;
    public mntrep?: number;
    public params?: string;
    public sd4info?: string;
    public sd4data?: string;
    public userId?: string;
}
