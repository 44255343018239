import {Type} from '@angular/core';
import {FormComponentMetadata} from '@app/sam-base/models';
import {Gecliview} from '@app/sam-base/models/invoices/gecliview';
import {Gefacliview} from '@app/sam-base/models/invoices/gefacliview';
import {Saempview} from '@app/sam-base/models/salary/saempview';
import {IwSendSMSComponent, IwSendSMSModalComponent} from '@modules/sam-main/salary/components';
import {AssurancesComponent} from '@modules/sam-main/salary/components/assurances/assurances.component';
import {
    BookingFileListComponent
} from '@modules/sam-main/salary/components/booking-file-list/booking-file-list.component';
import {
    TabClientDocsComponent
} from '@modules/sam-main/salary/components/client/tab-client-docs/tab-client-docs.component';
import {
    TabSaempDocsComponent
} from '@modules/sam-main/salary/components/employee/tab-saemp-docs/tab-saemp-docs.component';
import {
    EncaissementAutomaticSideMenuComponent
} from '@modules/sam-main/salary/components/encaissement/automatic/encaissement-automatic-side-menu.component';
import {
    EncaissementAutomaticComponent
} from '@modules/sam-main/salary/components/encaissement/automatic/encaissement-automatic.component';
import {
    EncaissementCaculateBtnFormComponent
} from '@modules/sam-main/salary/components/encaissement/encaissement-calculatebtn-form/encaissement-calculatebtn-form.component';
import {FactureComptaComponent} from '@modules/sam-main/salary/components/facture-compta/facture-compta.component';
import {
    TabFactureAdresseComponent
} from '@modules/sam-main/salary/components/facture-NC/tab-facture-adresse/tab-facture-adresse.component';
import {
    GefaceleComponent
} from '@modules/sam-main/salary/components/facture-NC/tab-facture-detail/gefacele/gefacele.component';
import {
    TabSaemsAnalytiqueComponent
} from '@modules/sam-main/salary/components/fiche-salaire/tab-analytique/tab-saems-analytique.component';
import {
    TabSaemsInformationsComponent
} from '@modules/sam-main/salary/components/fiche-salaire/tab-informations/tab-saems-informations.component';
import {
    TabSaemsMissionsComponent
} from '@modules/sam-main/salary/components/fiche-salaire/tab-missions/tab-saems-missions.component';
import {GecliadrComponent} from '@modules/sam-main/salary/components/gecliadr/gecliadr.component';
import {GeclicatComponent} from '@modules/sam-main/salary/components/geclicat/geclicat.component';
import {
    GenreSalaireVersionComponent
} from '@modules/sam-main/salary/components/genre-salaire/genre-salaire-version.component';
import {
    TabSagsVersionComponent
} from '@modules/sam-main/salary/components/genre-salaire/tab-sags-version/tab-sags-version.component';
import {GeperComponent} from '@modules/sam-main/salary/components/geper/geper.component';
import {OrdrePaiementComponent} from '@modules/sam-main/salary/components/ordre-paiement/ordre-paiement.component';
import {
    ParametresGenerauxFacturationComponent
} from '@modules/sam-main/salary/components/parametres-generaux-facturation/parametres-generaux-facturation.component';
import {
    TabGeparAutreComponent
} from '@modules/sam-main/salary/components/parametres-generaux-facturation/tab-gepar-autre/tab-gepar-autre.component';
import {
    TabGeparComptaComponent
} from '@modules/sam-main/salary/components/parametres-generaux-facturation/tab-gepar-compta/tab-gepar-compta.component';
import {
    TabGeparFacturationComponent
} from '@modules/sam-main/salary/components/parametres-generaux-facturation/tab-gepar-facturation/tab-gepar-facturation.component';
import {
    TabGeparFormatComponent
} from '@modules/sam-main/salary/components/parametres-generaux-facturation/tab-gepar-format/tab-gepar-format.component';
import {SalaryComptaComponent} from '@modules/sam-main/salary/components/salary-compta/salary-compta.component';
import {SapaimodeComponent} from '@modules/sam-main/salary/components/sapaimode/sapaimode.component';
import {ContactNCSComponent} from '@modules/sam-main/salary/components/saparncs/contact-ncs/contact-ncs.component';
import {EntityDetailClientComponent} from '@shared/widgets/iw-components/entity-details/entity-detail-client.component';
import {
    EntityDetailFactureComponent
} from '@shared/widgets/iw-components/entity-details/entity-detail-facture.component';
import {EntityDetailSaempComponent} from '@shared/widgets/iw-components/entity-details/entity-detail-saemp.component';
import {BaremeComponent} from './components/bareme/bareme.component';
import {CantonBaremeComponent} from './components/canton-bareme/canton-bareme.component';
import {CantonISComponent} from './components/canton-is/canton-is.component';
import {ClientSideMenuComponent} from './components/client/client-side-menu.component';
import {ClientComponent} from './components/client/client.component';
import {TabClientAddressComponent} from './components/client/tab-client-address/tab-client-address.component';
import {TabClientAutreComponent} from './components/client/tab-client-autre/tab-client-autre-component';
import {TabClientEFactureComponent} from './components/client/tab-client-e-facture/tab-client-efacture.component';
import {TabClientGeneralComponent} from './components/client/tab-client-general/tab-client-general.component';
import {TabClientIjComponent} from './components/client/tab-client-ij/tab-client-ij-component';
import {TabClientNotesComponent} from './components/client/tab-client-notes/tab-client-notes.component';
import {GeclisuiviComponent} from './components/client/tab-client-suivi/geclisuivi/geclisuivi.component';
import {TabClientSuiviComponent} from './components/client/tab-client-suivi/tab-client-suivi.component';
import {GecliattComponent} from './components/client/tab-contacts/gecliatt/gecliatt.component';
import {TabContactsComponent} from './components/client/tab-contacts/tab-contacts.component';
import {RepartitionComponent} from './components/definitionSalaire/repartitionAnalytique/repartition.component';
import {EmployeeSideMenuComponent} from './components/employee/employee-side-menu.component';
import {EmployeeComponent} from './components/employee/employee.component';
import {TabPaiementComponent} from './components/employee/tab-paiement/tab-paiement.component';
import {TabSaempProfessionComponent} from './components/employee/tab-profession-affectation/tab-saemp-profession';
import {DecompteGSComponent} from './components/employee/tab-saemp-annee/decompte-gs/decompte-gs.component';
import {TabSaempAnneeComponent} from './components/employee/tab-saemp-annee/tab-saemp-annee.component';
import {TabSaempAutreComponent} from './components/employee/tab-saemp-autre/tab-saemp-autre.component';
import {
    DeductionCreateComponent
} from './components/employee/tab-saemp-deductions/deduction-create/deduction-create.component';
import {
    EmployeeDeductionComponent
} from './components/employee/tab-saemp-deductions/deductionEdit/employeeDeduction.component';
import {TabSaempDeductionsComponent} from './components/employee/tab-saemp-deductions/tab-saemp-deductions.component';
import {
    TabSaempDonneesBaseComponent
} from './components/employee/tab-saemp-donnees-base/tab-saemp-donnees-base.component';
import {Recapissd4Component} from './components/employee/tab-saemp-impot/recapissd4/recapissd4.component';
import {TabSaempImpotComponent} from './components/employee/tab-saemp-impot/tab-saemp-impot.component';
import {TabSaempSearchComponent} from './components/employee/tab-saemp-impot/tab-saemp-search.component';
import {TabSaempIndemnitesComponent} from './components/employee/tab-saemp-indemnites/tab-saemp-indemnites.component';
import {DialogAvanceeComponent} from './components/employee/tab-saemp-ncs/dialog-avancee/dialog-avancee.component';
import {TabSaempNcsComponent} from './components/employee/tab-saemp-ncs/tab-saemp-ncs.component';
import {TabSaempPermisComponent} from './components/employee/tab-saemp-permis/tab-saemp-permis.component';
import {SadsComponent} from './components/employee/tab-saemp-salaire-vacances/definitionSalaire/sads.component';
import {
    TabSaempSalaireVacancesComponent
} from './components/employee/tab-saemp-salaire-vacances/tab-saemp-salaire-vacances.component';
import {TabSaempSituationComponent} from './components/employee/tab-saemp-situation/tab-saemp-situation.component';
import {TabSaempSwissdecComponent} from './components/employee/tab-saemp-swissdec/tab-saemp-swissdec.component';
import {
    EncaissementAttributionManyCliComponent
} from './components/encaissement/encaissement-attribution-many-cli.component';
import {EncaissementAttributionComponent} from './components/encaissement/encaissement-attribution.component';
import {EncaissementSideMenuComponent} from './components/encaissement/encaissement-side-menu.component';
import {EncaissementComponent} from './components/encaissement/encaissement.component';
import {FactureNCSideMenuComponent} from './components/facture-NC/facture-nc-side-menu.component';
import {FactureNCComponent} from './components/facture-NC/facture-nc.component';
import {TabFactureDetailComponent} from './components/facture-NC/tab-facture-detail/tab-facture-detail.component';
import {TabFactureEnteteComponent} from './components/facture-NC/tab-facture-entete/tab-facture-entete.component';
import {TabFactureQRComponent} from './components/facture-NC/tab-facture-qr/tab-facture-qr.component';
import {FactureNoteCreditNewComponent} from './components/facture-note-credit-new/facture-note-credit-new.component';
import {FicheSalaireSideMenuComponent} from './components/fiche-salaire/fiche-salaire-side-menu.component';
import {FicheSalaireComponent} from './components/fiche-salaire/fiche-salaire.component';
import {TabSaemsEmaComponent} from './components/fiche-salaire/tab-ema/tab-saems-ema.component';
import {
    ElementDeSalaireComponent
} from './components/fiche-salaire/tab-fichesal-fiche/elementDeSalaire/elementdesalaire.component';
import {JoursIsComponent} from './components/fiche-salaire/tab-fichesal-fiche/joursIS/joursis.component';
import {PayerProvComponent} from './components/fiche-salaire/tab-fichesal-fiche/payerProv/payer-prov.component';
import {TabFichesalFicheComponent} from './components/fiche-salaire/tab-fichesal-fiche/tab-fichesal-fiche.component';
import {TabSaemsNotesComponent} from './components/fiche-salaire/tab-notes/tab-saems-notes.component';
import {FichePaiementComponent} from './components/fiche-salaire/tab-paiment/fichePaiement/fichePaiement.component';
import {TabSaepPaimentComponent} from './components/fiche-salaire/tab-paiment/tab-saep-paiment.component';
import {GenreSalaireSideMenuComponent} from './components/genre-salaire/genre-salaire-side-menu.component';
import {GenreSalaireComponent} from './components/genre-salaire/genre-salaire.component';
import {SagscptComponent} from './components/genre-salaire/sagscpt/sagscpt.component';
import {TabSagsAutreComponent} from './components/genre-salaire/tab-sags-autre/tab-sags-autre.component';
import {
    TabSagsDonnesBaseComponent
} from './components/genre-salaire/tab-sags-donnes-base/tab-sags-donnes-base.component';
import {TabSagsSwissdecComponent} from './components/genre-salaire/tab-sags-swissdec/tab-sags-swissdec.component';
import {GepaicondComponent} from './components/gepaicond/gepaicond.component';
import {GepaimodeComponent} from './components/gepaimode/gepaimode.component';
import {ParametresGenerauxComponent} from './components/parametres-generaux/parametresGeneraux.component';
import {
    TabSaparFiduciaireComponent
} from './components/parametres-generaux/tab-sapar-fiduciaire/tab-sapar-fiduciaire.component';
import {TabSaparFormatsComponent} from './components/parametres-generaux/tab-sapar-formats/tab-sapar-formats.component';
import {TabSaparGeneralComponent} from './components/parametres-generaux/tab-sapar-general/tab-sapar-general.component';
import {
    TabSaparGenresDeSalaireComponent
} from './components/parametres-generaux/tab-sapar-generes-de-salaire/tab-sapar-genres-de-salaire.component';
import {
    TabSaparHorairesComponent
} from './components/parametres-generaux/tab-sapar-horaires/tab-sapar-horaires.component';
import {
    TabSaparInfoEnterpriseComponent
} from './components/parametres-generaux/tab-sapar-info-entreprise/tab-sapar-info-enterprise.component';
import {
    TabSaparInterfaceComponent
} from './components/parametres-generaux/tab-sapar-interface/tab-sapar-interface.component';
import {TabSaparOptionsComponent} from './components/parametres-generaux/tab-sapar-options/tab-sapar-options.component';
import {TabSaparPaimentComponent} from './components/parametres-generaux/tab-sapar-paiment/tab-sapar-paiment.component';
import {
    TabSaparSwissdecComponent
} from './components/parametres-generaux/tab-sapar-swissdec/tab-sapar-swissdec.component';
import {TabSaparTextsComponent} from './components/parametres-generaux/tab-sapar-texts/tab-sapar-texts.component';
import {ParamSD4Component} from './components/paramSD4/paramSD4.component';
import {PeriodSideMenuComponent} from './components/periode/periode-side-menu.component';
import {PeriodeComponent} from './components/periode/periode.component';
import {TabSagenInformationsComponent} from './components/periode/tab-informations/tab-sagen-informations.component';
import {TabSagenNotesComponent} from './components/periode/tab-notes/tab-sagen-notes.component';
import {TabSagenSwissdecComponent} from './components/periode/tab-swissdec/tab-sagen-swissdec.component';
import {SaconjComponent} from './components/saconj/saconj.component';
import {SadeddaComponent} from './components/sadedda/sadedda.component';
import {SaeioComponent} from './components/saeio/saeio.component';
import {SaemiAVSComponent} from './components/saemi/saemi_avs/saemi-avs.component';
import {SaemiISComponent} from './components/saemi/saemi_is/saemi-is.component';
import {SaemiLPPComponent} from './components/saemi/saemi_lpp/saemi-lpp.component';
import {SaempopComponent} from './components/saempop/saempop.component';
import {SaenfComponent} from './components/saenf/saenf.component';
import {SalaryDeductionComponent} from './components/salary-deduction/salaryDeduction.component';
import {
    TabSapardedInformationsComponent
} from './components/salary-deduction/tab-saparded-informations/tab-saparded-informations.component';
import {
    TabSapardedParametersComponent
} from './components/salary-deduction/tab-saparded-parameters/tab-saparded-parameters.component';
import {SaparncsComponent} from './components/saparncs/saparncs.component';
import {SaparncsmotComponent} from './components/saparncsmot/saparncsmot.component';
import {GesaexeComponent} from './components/selec-exercice/selec-exercice.component';
import {SadedtxComponent} from './components/taux/sadedtx.component';
import {SadedtxageComponent} from './components/taux/sadedtxage.component';

/** List of components that can be loaded at runtime */
    // TODO: remove unused components
export const SALARY_COMPONENTS_LIST = [
        IwSendSMSComponent,
        IwSendSMSModalComponent,
        GenreSalaireComponent,
        GenreSalaireVersionComponent,
        TabSagsAutreComponent,
        TabSagsDonnesBaseComponent,
        TabSagsSwissdecComponent,
        TabSagsVersionComponent,
        ParametresGenerauxComponent,
        TabSaparFormatsComponent,
        TabSaparGeneralComponent,
        TabSaparGenresDeSalaireComponent,
        TabSaparInterfaceComponent,
        TabSaparPaimentComponent,
        TabSaparOptionsComponent,
        TabSaparHorairesComponent,
        ParametresGenerauxFacturationComponent,
        TabGeparAutreComponent,
        TabGeparComptaComponent,
        TabGeparFacturationComponent,
        TabGeparFormatComponent,
        GenreSalaireSideMenuComponent,
        SagscptComponent,
        FicheSalaireComponent,
        FicheSalaireSideMenuComponent,
        EmployeeComponent,
        EncaissementComponent,
        EmployeeSideMenuComponent,
        TabSaempDonneesBaseComponent,
        TabSaempAutreComponent,
        TabSaempSituationComponent,
        TabSaempPermisComponent,
        TabSaempSwissdecComponent,
        TabSaempImpotComponent,
        TabSaempSalaireVacancesComponent,
        TabSaempIndemnitesComponent,
        TabSaempNcsComponent,
        TabSaempAnneeComponent,
        TabSaempSearchComponent,
        TabSaempProfessionComponent,
        TabSaempDeductionsComponent,
        TabPaiementComponent,
        SalaryDeductionComponent,
        TabSapardedParametersComponent,
        TabSapardedInformationsComponent,
        SaconjComponent,
        SaenfComponent,
        SadedtxComponent,
        SadeddaComponent,
        SaeioComponent,
        SapaimodeComponent,
        GesaexeComponent,
        GeperComponent,
        BookingFileListComponent,
        FactureComptaComponent,
        SalaryComptaComponent,
        SadsComponent,
        DialogAvanceeComponent,
        ParamSD4Component,
        PeriodSideMenuComponent,
        PeriodeComponent,
        TabSagenNotesComponent,
        TabSagenInformationsComponent,
        TabSagenSwissdecComponent,
        TabFichesalFicheComponent,
        TabSaemsAnalytiqueComponent,
        TabSaemsInformationsComponent,
        TabSaemsNotesComponent,
        TabSaemsMissionsComponent,
        TabSaepPaimentComponent,
        JoursIsComponent,
        ElementDeSalaireComponent,
        FichePaiementComponent,
        EmployeeDeductionComponent,
        RepartitionComponent,
        SaempopComponent,
        DecompteGSComponent,
        Recapissd4Component,
        ClientComponent,
        ClientSideMenuComponent,
        GecliattComponent,
        GeclicatComponent,
        TabContactsComponent,
        TabClientGeneralComponent,
        PayerProvComponent,
        TabClientDocsComponent,
        TabClientIjComponent,
        TabClientNotesComponent,
        TabClientEFactureComponent,
        GepaimodeComponent,
        TabFactureQRComponent,
        FactureNCComponent,
        EncaissementAutomaticComponent,
        EncaissementAutomaticSideMenuComponent,
        TabClientAddressComponent,
        TabClientAutreComponent,
        TabFactureEnteteComponent,
        FactureNCSideMenuComponent,
        EncaissementSideMenuComponent,
        TabFactureAdresseComponent,
        TabClientSuiviComponent,
        GeclisuiviComponent,
        ContactNCSComponent,
        SadedtxageComponent,
        FactureNoteCreditNewComponent,
        TabFactureDetailComponent,
        GefaceleComponent,
        TabSaparTextsComponent,
        TabSaparFiduciaireComponent,
        TabSaparInfoEnterpriseComponent,
        TabSaparSwissdecComponent,
        GepaicondComponent,
        DeductionCreateComponent,
        CantonISComponent,
        AssurancesComponent,
        SaparncsComponent,
        SaparncsmotComponent,
        TabSaemsEmaComponent,
        TabSaempDocsComponent,
        SaemiISComponent,
        SaemiAVSComponent,
        SaemiLPPComponent,
        EncaissementCaculateBtnFormComponent,
        CantonBaremeComponent,
        BaremeComponent,
        EncaissementAttributionComponent,
        EncaissementAttributionManyCliComponent,
        GecliadrComponent,
        OrdrePaiementComponent];

// ########################################################
// #################### DEPRECATED ########################
// ########################################################
// ########################################################

/**
 * Interface that list possible components,
 * used to validations at compile time
 */
export interface SalaryComponents {
    sags: FormComponentMetadata;
    // Gecliatt: FormComponentMetadata;
}

/** Object that lists Component classes to be loaded at runtime */
export const SALARY_COMPONENTS: SalaryComponents = {
    sags: {type: GenreSalaireComponent}
    // Gecliatt: { type: GecliattComponent },
};

export const SALARY_ENTITY_DETAIL: {
    entity: Type<any>; component: Type<any>;
}[] = [
    {
        entity: Gecliview,
        component: EntityDetailClientComponent
    },
    {
        entity: Saempview,
        component: EntityDetailSaempComponent
    },
    {
        entity: Gefacliview,
        component: EntityDetailFactureComponent
    }];
