import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Saes} from '@app/sam-base/models/salary/saes';

import {
    ElementDeSalaireComponent
} from '../components/fiche-salaire/tab-fichesal-fiche/elementDeSalaire/elementdesalaire.component';

export const ELEMSAL_DEFAULT_FORM: FormDescription<Saes> = {
    form: ElementDeSalaireComponent, state: {
        ...buildDefaultState(Saes), diagTitle: 'element_salary', diagModal: true, isNew: true, width: 800
    }
};
