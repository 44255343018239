<form [formGroup]="formGroup" class="row">

    <div class="row col-xs-12 padding-10">
        <iw-enum-dropdown #cboij_rap_sm [inputEnum]="ijrapsaisiemode"
                          [isDisabled]="!(getFormValue('lexpmis2Ij') && !isReadonly)" [labelAlign]="'top'"
                          [label]="'modeSaisie' | translate"
                          class="iw-combobox col-xs-2 no-padding-left no-padding-right"
                          formControlName="ijRapSm"></iw-enum-dropdown>

        <iw-checkbox #chklexpmis2Ij [isDisabled]="!lexpmis2IjEnabled() || isReadonly"
                     [label]="'combo_interijob' | translate" class="col-xs-1 col-xs-offset-1 flex-end no-padding-left"
                     formControlName="lexpmis2Ij"></iw-checkbox>

        <iw-textarea #edtij_rapnote [height]="72" [isDisabled]="!(getFormValue('lexpmis2Ij') && !isReadonly)"
                     [labelAlign]="'top'" [label]="'noteSaisie' | translate" class="iw-textarea col-xs-12"
                     formControlName="ijRapnote" style="padding-top: 10px !important;"></iw-textarea>

        <iw-textarea #edtIJ_data [height]="72" [isDisabled]="true" [labelAlign]="'top'"
                     [label]="'parametres' | translate" class="iw-textarea col-xs-12" formControlName="ijRapnote"
                     style="padding-top: 10px !important;"></iw-textarea>

        <div class="row col-xs-12 padding-top-10">
            <iw-textfield #lblPubIJ [labelAlign]="'top'" [label]="'contratLocation' | translate"
                          [value]="'publicationIJ' | translate" class="iw-input col-xs-3 no-padding"
                          isDisabled="true"></iw-textfield>
            <iw-dropdown #cboicc2ijmod (valueChange)="setEmail($event, 'CC')"
                         [isDisabled]="!(getFormValue('lexpmis2Ij') && !isReadonly)" [options]="publishDropdownOptions"
                         class="iw-input col-xs-5 no-padding flex-end" formControlName="icc2Ijmod"></iw-dropdown>
            <iw-button #btnIJAutoCC (press)="publish('CC')" [isDisabled]="!isReadonly || contratLocationDisabled"
                       [text]="'publication' | translate" class="iw-input col-xs-2 no-padding flex-end"></iw-button>
            <iw-button #btnIJCCDel (press)="unpublish('CC')" [isDisabled]="!isReadonly  || contratLocationDisabled"
                       [text]="'unpublish' | translate" class="iw-input col-xs-2 no-padding flex-end"></iw-button>
            <iw-textfield #lblCCemail [value]="'emails' | translate" class="iw-input col-xs-3 no-padding"
                          isDisabled="true"></iw-textfield>
            <iw-textfield #edtCCemail [isDisabled]="isReadonly || !canEditCCEmail()" [tooltipPosition]="'bottom'"
                          [tooltip]="'separatorComma' | translate" class="iw-input col-xs-9 no-padding"
                          formControlName="ccemail"></iw-textfield>
        </div>

        <div class="row col-xs-12 padding-top-10">
            <iw-textfield #lblPubIJ2 [labelAlign]="'top'" [label]="'contratDeMission' | translate"
                          [value]="'publicationIJ' | translate" class="iw-input col-xs-3 no-padding"
                          isDisabled="true"></iw-textfield>
            <iw-dropdown #cboicm2ijmod (valueChange)="setEmail($event, 'CM')"
                         [isDisabled]="!(getFormValue('lexpmis2Ij') && !isReadonly)" [options]="publishDropdownOptions"
                         class="iw-input col-xs-5 no-padding flex-end" formControlName="icm2Ijmod"></iw-dropdown>
            <iw-button #btnIJAutoCM (press)="publish('CM')" [isDisabled]="!isReadonly || contratMissionDisabled"
                       [text]="'publication' | translate" class="iw-input col-xs-2 no-padding flex-end"></iw-button>
            <iw-button #btnIJAutoCM (press)="unpublish('CM')" [isDisabled]="!isReadonly || contratMissionDisabled"
                       [text]="'unpublish' | translate" class="iw-input col-xs-2 no-padding flex-end"></iw-button>
            <iw-textfield #lblCMemail [value]="'emails' | translate" class="iw-input col-xs-3 no-padding"
                          isDisabled="true"></iw-textfield>
            <iw-textfield #edtCMemail [isDisabled]="isReadonly || !canEditCMEmail()" [tooltipPosition]="'bottom'"
                          [tooltip]="'separatorComma' | translate" class="iw-input col-xs-9 no-padding"
                          formControlName="cmemail"></iw-textfield>

        </div>
    </div>

</form>
