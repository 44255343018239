import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {SearchNavigationService} from '@app/sam-base/core';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {Ppagiview, Ppemp} from '@app/sam-base/models/placement';
import {SalaryForm} from '@modules/sam-main/salary/salary.forms';
import {SaenfService} from '@sam-base/core/services/saenf.service';

import {PlacementForm} from '../../../placement.forms';

@Component({
    selector: 'iw-tab-ppemp-situation',
    templateUrl: './tab-ppemp-situation.component.html'
})
export class TabPpempSituationComponent extends BasePartialFormComponent<Ppemp> implements OnInit {

    @Input() public isNew = false;
    @Input() public isValid = false;

    constructor(private readonly _formHandler: FormHandlerService<PlacementForm>,
                private readonly _salFormHandler: FormHandlerService<SalaryForm>,
                private readonly _saenfService: SaenfService,
                private readonly _searchNavService: SearchNavigationService) {
        super();
    }

    public get ppempId() {
        return this.getFormValue<string>('empId');
    }

    public get nom() {
        return this.getFormValue<string>('nom');
    }

    public get hasenf() {
        return Number(this.getFormValue('enfnb')) > 0;
    }

    public get hasPermis() {
        const nation = this.getFormValue('nation');
        if (!nation) {
            return false;
        }
        return nation !== 'CH';
    }

    public get isMarried() {
        const civilStatus = this.getFormValue('etatcivil');
        if (!civilStatus) {
            return false;
        }
        return civilStatus === 'M';
    }

    public ngOnInit() {
        this.getSaenfData();

        this.subscribeValueChange('etatcivil', (etatcivil) => {
            const datetatciv = this.getFormValue('datetatciv');
            if ((etatcivil === 'C' || etatcivil === 'U') && !datetatciv) {
                this.setFormValue('datetatciv', this.getFormValue('datenaiss'));
            }
        });
    }

    public openAgi() {
        this._searchNavService.navigate({
            entity: Ppagiview,
            baseFilters: {empId: this.ppempId}
        });
    }

    public onBtnEnfentsClick() {
        if (!this.ppempId) {
            return;
        }
        this._salFormHandler.showFormDialog(SalaryForm.EmployeEnf, {
            empId: this.ppempId,
            nom: this.nom
        });
    }

    public onBtnConjointClick() {
        if (!this.ppempId) {
            return;
        }
        this._formHandler.showFormDialog(PlacementForm.EmployeConjoint, {empId: this.ppempId});
    }

    public setPaiementForcer(nb: number) {
        if (this.isReadonly) {
            return;
        }
        const lppforce1 = this.getFormValue('lppforce1');
        // If lppforce1 already set, do nothing (user must unset it first in the lpp tab)
        if (lppforce1 === true) {
            return;
        }
        this.setFormValue('lppforce1', nb > 0);
    }

    private getSaenfData() {
        if (!this.ppempId) {
            return;
        }
        this._saenfService.updateSaenf(this.ppempId);
    }
}
