<form [formGroup]="formGroup"
      class="row no-padding">
    <iw-dropdown #drpPeriod
                 (valueChange)="getBareme()"
                 [(value)]="selBareme"
                 [isDisabled]="isReadonly"
                 [labelAlign]="'top'"
                 [label]="'bareme' | translate"
                 [options]="optionsBareme"
                 class="iw-input col-xs-3 padding-bottom-10"></iw-dropdown>
    <iw-table [columns]="columns"
              [data]="saisList"
              [isFilterEnable]="false"
              [isGridColumnMenuEnable]="false"
              [isSortIconEnable]="false"
              [rawTableMode]="true"
              class="col-xs-12 no-padding"
              style="height: 300px"></iw-table>
    <div class="col-xs-3 col-xs-offset-9 padding-top-20">
        <iw-button #Closebutton
                   (press)="closeDialog()"
                   [text]="'fermer' | translate"
                   class="iw-button col-xs-12 fle
                   x-end"></iw-button>
    </div>
</form>