import {Component} from '@angular/core';
import {HEADER_MENU_ENTRIES} from '@app/layout/configs/menus';
import {SIDE_MENU_ITEMS} from '@app/layout/configs/side-menu';
import {IwEventHubService} from '@app/sam-base/core/events';
import {LayoutEvents} from '@app/sam-base/layout/events/events.models';
import {TranslateService} from '@ngx-translate/core';

import {IwStoreService} from '@sam-base/core/store/iw-store.service';
import packageInfo from 'package.json';

@Component({
    selector: 'iw-layout',
    templateUrl: './layout.component.html'
})
export class LayoutComponent {
    public open = false;

    public interijobActive = false;

    public activeMenu?: LayoutEvents;
    // eslint-disable-next-line
    public readonly SlidePanelEvent = LayoutEvents.TOGGLE_SLIDE_PANEL;

    public menuEntries = HEADER_MENU_ENTRIES;
    public sideMenuItems = SIDE_MENU_ITEMS;
    public readonly appVersion = packageInfo.version;

    constructor(private _events: IwEventHubService<LayoutEvents>, private _store: IwStoreService,
                private readonly _translate: TranslateService) {
        this._events.events.subscribe(e => {
            if (e.type === LayoutEvents.TOGGLE_MENU) {
                this.open = !this.open;
            }
        });

        this._store.getLicenseOption('interijobwebaccess')
            .subscribe((active: boolean) => {
                this.interijobActive = active;
                let needToRefresh = false;
                /** Enables IJ configuration menu if interijob is active in license */
                this.menuEntries.forEach(entry => {
                    entry.items?.forEach(items => {
                        items.forEach(item => {
                            item?.items?.forEach(subItem => {
                                if (subItem.id === 'ij-config') {
                                    subItem.disabled = !this.interijobActive;
                                    // Force update on setter
                                    needToRefresh = true;
                                }
                            })
                        })
                    })
                });
                if (needToRefresh) {
                    this.menuEntries = [...this.menuEntries];
                }
            });

        this.menuEntries.forEach(menuEntry => {
            menuEntry.label = this._translate.instant(menuEntry.label || ' ');
            menuEntry.command = () => {
                this._events.emit(menuEntry.key as LayoutEvents);
            }
        })
        this.sideMenuItems.forEach(menuEntry => {
            menuEntry.title = this._translate.instant(menuEntry.tooltip || ' ');
        })
    }
}
