import {Component, OnInit, Type} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {EntityNavigationService} from '@app/sam-base/core/services/entity-navigation.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys, RowClickEvent} from '@app/sam-base/models';
import {Ppclimis, Ppmismdlview} from '@app/sam-base/models/placement';

@Component({
    templateUrl: './ppmismdl2.component.html'
})
export class Ppmismdl2Component extends BaseStoreFormComponent<Ppmismdlview> implements OnInit {

    /**
     * Mission id required for loading data
     */
    public get misId() {
        return this.getData('ppmismdl');
    }

    /**
     * Type of data in rest-grid
     */
    public type: Type<Ppmismdlview> = Ppmismdlview;

    /**
     * Private var to store row selected
     */
    private _selected?: Ppmismdlview;

    constructor(store: IwStoreService, private readonly _entityService: EntityNavigationService) {
        super(store);
    }

    public ngOnInit() {
        const v = this.misId;
        if (v instanceof Ppmismdlview && v.misId) {
            this.setFormValue('misId', v.misId);
        }
    }

    /**
     * Method called when row is selected
     *
     * @param event with ppmismdlview selected info
     */
    public onSelectRow(event: Ppmismdlview[]) {
        this._selected = (event && event[0]) || undefined;
        if (this._selected) {
            this.setFormValue('mismdlId', this._selected.mismdlId);
        }
    }

    /**
     * Open mission form with misId of row selected
     */
    public openMission(event: RowClickEvent<Ppmismdlview>) {
        const misId = (event && event.row && event.row.misId);
        if (!misId) {
            return;
        }
        this._entityService.navigateToEntityForm(Ppclimis, misId);
    }

    protected getFormControlNames(): FormKeys<Ppmismdlview> {
        return ['misId', 'mismdlId', 'cliId', 'nomclient', 'qualif', 'cctId'];
    }
}
