<form [formGroup]="formGroup" class="row">
    <div class="row space-form col-xs-9">
        <iw-textfield #tauxAuto [isDisabled]="true" [value]="tauxautoLabel"
                      class="iw-input col-xs-6 padding-top-10"></iw-textfield>
        <iw-checkbox #hommes (valueChange)="setFilterH($event)" *ngIf="showFilters" [labelAlign]="'top'"
                     [label]="'h' | translate" [value]="this.filterH"
                     class="iw-button btn-block col-xs-1 margin-top-10 margin-left-5"></iw-checkbox>
        <iw-checkbox #femmes (valueChange)="setFilterF($event)" *ngIf="showFilters" [labelAlign]="'top'"
                     [label]="'f' | translate" [value]="this.filterF"
                     class="iw-button btn-block col-xs-1 margin-top-10 margin-left-5"></iw-checkbox>
        <iw-textfield #txCode [isDisabled]="true" class="iw-input col-xs-2 padding-top-10 margin-left-5"
                      formControlName="txCode"></iw-textfield>
        <div *ngIf="!showFilters" class="col-xs-3"></div>
        <iw-rest-grid #sadeddaTable (selected)="getSelectedRow($event)" (sortInfo)="setSortInfo($event)"
                      [defaultSelected]="true" [defaultSelected]="true" [hasSortIcon]="true" [queryStatements]="query"
                      [sortDir]="sortPropDir" [sortProp]="sortProp" [type]="type" class="col-xs-12 padding-top-5"
                      style="height: 200px"></iw-rest-grid>

        <iw-textfield #ageDe [decimals]="0" [isDisabled]="!enableAge" [labelAlign]="'top'"
                      [label]="'depuis' | translate" class="iw-input col-xs-2 padding-top-10" formControlName="ageDe"
                      type="number"></iw-textfield>
        <iw-textfield #ageA [decimals]="0" [isDisabled]="!enableAge" [labelAlign]="'top'" [label]="'jusqua' | translate"
                      class="iw-input col-xs-2 padding-top-10" formControlName="ageA" type="number"></iw-textfield>
        <div class="col-xs-1"></div>
        <iw-textfield #canton [isDisabled]="readonly" [labelAlign]="'top'" [label]="'canton' | translate"
                      class="iw-input col-xs-2 padding-top-10" formControlName="canton"></iw-textfield>
        <iw-textfield #cid [isDisabled]="!enableCat" [labelAlign]="'top'" [label]="'cat13' | translate"
                      class="iw-input col-xs-2 padding-top-10" formControlName="cid"></iw-textfield>
        <div class="col-xs-2"></div>

        <iw-textfield #code [isDisabled]="readonly || !newEntryMode" [labelAlign]="'top'" [label]="'code' | translate"
                      class="iw-input col-xs-2 padding-top-10" formControlName="txCode"></iw-textfield>
        <div class="col-xs-3"></div>
        <iw-checkbox #chkSoumis (valueChange)="setSoumis($event)" [isDisabled]="readonly" [label]="'soumis' | translate"
                     class="iw-input col-xs-2" formControlName="soumis" style="padding-top: 45px"></iw-checkbox>
        <iw-dropdown #drpSoumis [isDisabled]="readonly || !this.soumis" [options]="soumisOptions" class="col-xs-4"
                     formControlName="soumiscode" style="padding-top: 32px"></iw-dropdown>
    </div>

    <div class="col-xs-3 flex flex-v separator-left">
        <iw-button #btnNew (press)="createNewEntry()" *ngIf="readonly" [isDisabled]="!readonly"
                   [text]="'sidemenu_new' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnModif (press)="modifyEntry()" *ngIf="readonly" [isDisabled]="!readonly || !selectedRow"
                   [text]="'sidemenu_edit' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnSave (press)="saveEntry()" *ngIf="!readonly" [isDisabled]="!formGroup.valid"
                   [text]="'sidemenu_save' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnDel (press)="removeEntry()" *ngIf="readonly" [isDisabled]="!readonly || !selectedRow"
                   [text]="'sidemenu_delete' | translate" class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnCancel (press)="cancelEditionMode()" *ngIf="!readonly" [text]="'sidemenu_cancel' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-textfield #txtSaparAnnee [isDisabled]="true" [noLabel]="true" [value]="sapar?.annee"
                      class="iw-textfield padding-top-10" style="text-align-last: center"></iw-textfield>

        <iw-button #btnClose (press)="closeDialog()" [text]="'fermer' | translate" class="iw-button btn-block"
                   style="margin-block-start: auto"></iw-button>
    </div>
</form>
