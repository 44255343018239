import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Type, ViewChild} from '@angular/core';
import {AbstractControl, ValidatorFn, Validators} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {
    FormHandlerService,
    IwEventHubService,
    ModalService,
    RestApiService,
    RestEntityClient
} from '@app/sam-base/core';
import {ERROR} from '@app/sam-base/core/logger/logger';
import {CctService} from '@app/sam-base/core/services/cct.service';
import {MissionCalculationsService} from '@app/sam-base/core/services/mission-calculations.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ComboboxItem, FieldStatus, ModalComponent} from '@app/sam-base/models';
import {Ppcct, Ppmis, ScheduleEvent} from '@app/sam-base/models/placement';
import {EditionsCct, VersionCct} from '@app/sam-base/models/placement/cct.model';
import {SalaryValues, TempdataFormResponse} from '@app/sam-base/models/placement/min-salary-calc.model';
import {
    MissionCalcCCTResponse,
    SalaryDeduction,
    SalaryDeductionResponse
} from '@app/sam-base/models/placement/mission-calculations-result.model';
import {ParametersService} from '@core/services/parameters.service';
import {PlacementForm} from '@modules/sam-main/placement/placement.forms';
import {Actions, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {BusinessException, BusinessExceptionErrors} from '@sam-base/core/auth/interceptors/error.model';

import {StoreEvents} from '@sam-base/core/events/actions/store.actions';
import {ToastService} from '@sam-base/core/toast';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {ProfileService} from '@shared/profile/profile.service';
import {CctDynamicFormComponent} from '@shared/widgets/modal-components/cct-dynamic-form/cct-dynamic-form.component';
import {ModalCctLogOptions} from '@shared/widgets/modal-components/cct-dynamic-form/cct-dynamic-form.options';
import {
    IwModalSelectTableComponent
} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.component';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {lastValueFrom, of, Subject, Subscription, switchMap, takeUntil} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {MatchingService} from '../../../services/matching.service';
import {MisSalaryHoursComponent} from './components/mis-salary-hours/mis-salary-hours.component';
import {SalaryMode, SalaryTarifMode} from './tab-ppmis-salary-enums';

function greaterThanZero(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value;
        if (value === undefined || isNaN(value) || value <= 0) {
            return {greaterThanZero: true};
        }
        // eslint-disable-next-line no-null/no-null
        return null;
    };
}

function strToCbp(lst: string[]): ComboboxItem<string>[] {
    return lst.map(e => (<ComboboxItem<string>>{
        value: e,
        name: e
    }));
}

@Component({
    templateUrl: './tab-ppmis-salary-tarif.component.html',
    selector: 'iw-ppmis-salary-tarif',
    styleUrls: ['./tab-ppmis-salary-tarif.component.scss']
})
export class TabMisSalaryTarifComponent extends BasePartialFormComponent<Ppmis> implements OnInit, AfterViewInit, OnDestroy {

    @Input() public sent2fac = false;
    @Input() public sent2sal = false;
    @Input() public employeeAge?: string;
    @Input() public suvaPeActive = false;
    @ViewChild('misSal') public misSal?: MisSalaryHoursComponent;
    @Output() public updatePgmOptions = new EventEmitter<boolean>();
    public salaryModeEnum = SalaryTarifMode;
    public optionsCodeCna: ComboboxItem<string>[] = [];
    public defCnaCode?: string | number | boolean;
    public forceUseCctTempData = false;
    public isPPAdmin = false;
    public cbValues = {
        canHeurPause: true,
        canHeurPauseAuto: true
    };
    public txtCoeffValue = '';
    public txtMargeHoursValue = '';
    public coeffStatus: FieldStatus = 'undefined';
    public editionid?: string;
    public cctVersion?: number;
    public scheduleEventDateStart?: string;
    public cctTempEnable = false;
    public codepgmValue = '';
    public readonly listOption: ModalSelectTableOption<ComboboxItem<string>, string>;
    private _statsSubs?: Subscription;
    private readonly _ppcctRest: RestEntityClient<Ppcct>;
    private _subSaveEntity = new Subscription();
    private _subscriptions = new Subject();
    private _cctLanguage?: string;
    /**
     * Function to vet the cct version for struct call
     * first we do a call to get the start date of
     * the schedule event to pass as param
     */
    private readonly DEFAULT_LANGUAGE = 'fr';

    constructor(private readonly _calcService: MissionCalculationsService, private readonly _modalService: ModalService,
                private readonly _translate: TranslateService, private readonly _cctService: CctService,
                private readonly _matchingService: MatchingService, private readonly _events: IwEventHubService<string>,
                private _parametersService: ParametersService, private _actions: Actions,
                private readonly _storeActions: IwEventHubService<string>, _restService: RestApiService,
                private readonly _formHandler: FormHandlerService<PlacementForm>,
                private readonly _profileService: ProfileService, private readonly _toastService: ToastService) {
        super();
        this._ppcctRest = _restService.getEntityClient(Ppcct);

        this.listOption = {
            columns: [{prop: 'name'}],
            map: (e: ComboboxItem<string>) => e.value || '',
            selection: 'single'
        };

    }

    public get salaryMode(): number {
        const mode = this.getFormValue<number>('missalmode');
        return typeof mode === 'number' ? mode : 1;
    }

    public get factureMode(): number {
        const mode = this.getFormValue<number>('misfacmode');
        return typeof mode === 'number' ? mode : 1;
    }

    public get jfNonPayesEnabled() {
        return this._cctSelected?.jfNonPayes || false;
    }

    public get tempdataBtnClass() {
        return this._tempdataBtnModeOn ? 'cct-tempdata' : '';
    }

    public get isCctInvalid() {
        return (!this.isReadonly && this.formGroup?.controls['cctMinValue'].invalid);
    }

    public get codePgmNotExist() {
        const codePgm = this.getFormValue('codepgm');
        return this.optionsCodePgm.every(c => c.value !== codePgm);
    }

    public get codeCnaNotExist() {
        if (this.suvaPeActive) {
            // No check, cna code is not editable
            return false;
        }
        const codeCna = this.getFormValue('codecna') as string;
        return this.optionsCodeCna?.every(c => c.value !== codeCna);
    }

    public get isIwMisSalaryDisabled(): boolean {
        return this.forceUseCctTempData && !this.tempdataBtnModeOn && !this.isPPAdmin;
    }

    @Input()
    public set pgmOptions(values: ComboboxItem<string>[]) {
        this._optionsCodePgm = values;
    }

    private _optionsCodePgm: ComboboxItem<string>[] = [];

    public get optionsCodePgm() {
        return this._optionsCodePgm;
    }

    private _calculating = false;

    public get calculating() {
        return this._calculating;
    }

    public set calculating(isCalculating: boolean) {
        this.setFormValue('isCalculating', isCalculating);
        if (!isCalculating) {
            setTimeout(() => {
                this._calculating = isCalculating;
            }, 500);
        } else {
            this._calculating = isCalculating;
        }
    }

    private _tempdataBtnModeOn = false;

    public get tempdataBtnModeOn() {
        const cctMinValue = this.getFormValue<number>('cctMinValue') || 0;
        this._tempdataBtnModeOn = cctMinValue > 0;

        return this._tempdataBtnModeOn;
    }

    private _cctSelected?: Ppcct;

    public set cctSelected(cct: Ppcct) {
        this._cctSelected = cct;
    }

    public ngOnInit() {
        this.updatePgmOptions.emit(false);
        this.getCnacodes();
        this.subscribeFormChanges();
        this.calculateStats();
        this.checkValidators();

        this.isPPAdmin = this._profileService.checkUserPermission([SamUserRole.SAM_PLACEMENT_PARAM]);
        this._parametersService.getParameterValue('forceUseCctTempData')
            .subscribe((forceUseCctTempData) => {
                this.forceUseCctTempData = forceUseCctTempData as boolean;
                this.updateCctValidators();
            });

        this.subscribeToSaveEffect();

        this._storeActions.forType<StoreEvents>(StoreEvents.FormEntityStoreCancelEdit)
            .pipe(takeUntil(this._subscriptions))
            .subscribe(() => this.calculating = false);

    }

    // eslint-disable-next-line complexity
    public ngAfterViewInit() {
        if (this.getFormValue('codecna') === '' || this.getFormValue('codecna') === undefined || this.getFormValue('secId') === undefined) {
            this.setFormValue('codecna', this.defCnaCode);
        }

    }

    public ngOnDestroy() {
        this._subSaveEntity.unsubscribe();
        super.ngOnDestroy();
    }

    public async openCctForm() {
        const options: ModalCctLogOptions = {
            title: this._translate.instant('ctt_tempdata'),
            width: 700,
            height: 700,
            id: this.editionid,
            tempdataText: this.getFormValue('tempdataText'),
            tempdataObj: this.getFormValue('tempdataObject'),
            cctLanguage: this._cctLanguage ?? 'fr'
        };

        try {
            const data = await this._modalService
                .showModal<TempdataFormResponse | undefined, ModalCctLogOptions>(CctDynamicFormComponent, options);

            const minSal = data?.minSalarycalc.salary;
            if (!minSal) {
                return;
            }
            const tempdataTextComplete = data?.tempdataText + this.getCctContratInformation();
            this.setFormValue('tempdataText', tempdataTextComplete);
            const tempdataStringfyObject = JSON.stringify(data?.minSalarycalc, undefined, 2);
            this.setFormValue('tempdataObject', tempdataStringfyObject);

            this.applyCctminSalaryCalc(minSal);
        } catch (_) {
            // Form closed without selecting anything
        }
    }

    public dropdownCalcCCT() {
        this.formGroup?.controls['cctId']
            .valueChanges.pipe(take(1))
            .subscribe(() => {
                this.forceResetDefaultRatesFromCctObject();
                this.setCctMinValue(0);
                this.setFormValue('tempdataText', '');
                this.setFormValue('tempdataObject', '');
                this.calculating = true;
                this.calculateCCT();
                this.updatePgmOptions.emit(false);
                this.updateCctValidators();
            });
    }

    public updateCctClient(): void {
        if (!this.getFormValue('cliId')) {
            return;
        }
        this._events.emit<string>('mission_cliId', this.getFormValue('cliId'));
    }

    public async onBtnCodePgmClick() {
        if (!this.optionsCodePgm) {
            return;
        }
        // eslint-disable-next-line max-len
        const comp: Type<ModalComponent<string[], ComboboxItem<string>[]>> = <any>IwModalSelectTableComponent;
        try {
            this.listOption.fetchAction = () => of(this.optionsCodePgm);
            const data = await this._modalService
                .showModal<string[], ComboboxItem<string>[]>(comp, <any>this.listOption);
            this.setFormValue('codepgm', data[0]);
        } catch {
        }
    }

    public async onBtnCodeSuvaClick() {
        if (!this.optionsCodeCna) {
            return;
        }
        // eslint-disable-next-line max-len
        const comp: Type<ModalComponent<string[], ComboboxItem<string>[]>> = <any>IwModalSelectTableComponent;
        try {
            this.listOption.fetchAction = () => of(this.optionsCodeCna);
            const data = await this._modalService
                .showModal<string[], ComboboxItem<string>[]>(comp, <any>this.listOption);
            this.setFormValue('codecna', data[0]);
        } catch {
        }
    }

    private updateCctValidators(): void {
        if (this.forceUseCctTempData && (!this.tempdataBtnModeOn && !this.isPPAdmin)) {
            this.formGroup?.controls['cctMinValue'].addValidators(greaterThanZero());
        } else {
            this.formGroup?.controls['cctMinValue'].clearValidators();
        }
        this.formGroup?.controls['cctMinValue'].updateValueAndValidity();
    }

    private getCnacodes() {
        this._parametersService.getParameterValue('cnacodes')
            .subscribe((codes) => {
                this.optionsCodeCna = strToCbp((codes as string).split(','));
            });

        this._parametersService.getParameterValue('defcodcna')
            .subscribe((defaultCna) => {
                this.defCnaCode = defaultCna;
            });
    }

    private getCctContratInformation(): string {
        return `\n Contrat no: ${this._cctSelected?.contractNo} \n` + ` Version: ${this.cctVersion?.toString()} \n` + ` Edition: ${this.editionid} \n` + ` Date: ${this.scheduleEventDateStart} \n`;
    }

    private async setPpshcalcmodeCombo(data: SalaryValues) {
        if (!data.publicHolidaysCalculationType || !data.vacationCalculationType || !data.month13thSalaryCalculationType) {
            return;
        }
        const holidaysType = data.publicHolidaysCalculationType;
        const vacationType = data.vacationCalculationType;
        const month13thType = data.month13thSalaryCalculationType;

        const comboSelection = await this._cctService
            .getCaclModeComboValue(holidaysType, vacationType, month13thType);

        if (!comboSelection) {
            return;
        }

        this.setFormValue('shcalcmode', comboSelection[0].shcalcmode);
    }

    private calculateCCT() {
        if (!this.formGroup || this.isReadonly) {
            return;
        }
        if (!this.getFormValue<string>('cctId')) {
            return;
        }

        setTimeout(async () => {

            const cctId = this.getFormValue<string>('cctId');
            if (cctId) {
                this.cctSelected = await lastValueFrom(this._ppcctRest.getById(cctId));

                if (!this._cctSelected?.jfNonPayes) {
                    this.setFormValue('jfNonPayes', false);
                }
            }

            const mis: Ppmis = this.getFormData() as Ppmis;
            const res = await lastValueFrom(this.getCalculationCCT(mis));

            if (typeof res !== 'boolean') {
                this.applyCalculations(res);
                this.getCCTData(this.getFormValue('cctId'));
            }
            this.calculating = false;
        }, 0);
    }

    private forceResetDefaultRatesFromCctObject() {
        this.setFormValue('txJf', 0);
        this.setFormValue('tx13', 0);
        this.setFormValue('txVac', 0);
        if (this.forceUseCctTempData && !this.isPPAdmin) {
            this.setFormValue('salBase', '');
        }
    }

    private applyCctminSalaryCalc(sal?: SalaryValues) {
        if (!sal) {
            return;
        }
        this.calculating = true;

        // Force to "base" mode when tempdata is applied
        this.setFormValue('shbaseglob', SalaryMode.salary_mode_base);

        this._tempdataBtnModeOn = true;
        this.setPpshcalcmodeCombo(sal);

        this.setCctValues(this.getFormValue('jfNonPayes') ? 'mntJfz' : 'mntJf', sal.publicHolidaysCompensation, false, 'ljfh');
        console.log('sal.pctHolidaysCompensation', sal.pctHolidaysCompensation);
        this.setCctValues('txJf', sal.pctHolidaysCompensation, true);
        this.setCctValues('mnt13', sal.compensation13thSalary, false, 'l13');
        this.setCctValues('tx13', sal.pct13thMonthSalary, true);
        this.setCctValues('mntVac', sal.vacationCompensation, false, 'lvac');
        this.setCctValues('txVac', sal.pctVacationCompensation, true);

        if (this.getFormValue('missalmode') === 3) {
            this.setCctValues('salBase', sal.monthSalary);
            this.setFormValue('l13', sal.has13thMonthSalary);
        } else {
            this.setCctValues('salBase', sal.basicSalary);
        }
        this.setCctMinValue(sal.basicSalary);


        this._calcService
            .caculateDeductions(this.getAllDeductionFields())
            .pipe(catchError(err => {
                ERROR('Error during deductions calculations', [err]);
                return of(false);
            }))
            .subscribe((res: SalaryDeductionResponse | boolean) => {
                if (typeof res !== 'boolean') {
                    this.applyFormControls(res);
                    const shcalcMode = this.getFormValue<number>('shcalcmode') || 0;
                    const missalMode = this.getFormValue<number>('missalmode') || 0;

                    this._calcService.calcTempdataTotal(sal, shcalcMode, missalMode)
                        .pipe(catchError(err => {
                            ERROR('Error during tempdata total calculations', [err]);
                            return of(false);
                        }))
                        .subscribe(totalValues => {
                            if (typeof totalValues !== 'boolean') {
                                this.applyFormControls(totalValues);
                            }
                            this.calculateCCT();
                            this.calculating = false;
                        });
                }
                this.calculating = false;
            });
    }

    private setCctValues(field: keyof Ppmis, value: number, percent = false, checkbox?: keyof Ppmis) {

        if (value > 0 && checkbox) {
            this.setFormValue(checkbox, true);
        }
        if (percent) {
            console.log('-----');
            console.log('field', field);
            console.log('value * 100', value * 100);
            this.setFormValue(field, value * 100);
            return;
        }

        this.setFormValue(field, Math.round((value + Number.EPSILON) * 100) / 100);
    }

    private subscribeFormChanges() {
        this.subscribeValueChange('clitarif', () => this.calculateStats(), 300);
        this.subscribeValueChange('clitarifra', () => this.calculateStats(), 300);
        this.subscribeValueChange('mntAvsetc', () => this.calculateStats(), 300);
        this.subscribeValueChange('mntAvspgn', () => this.calculateStats(), 300);
        this.subscribeValueChange('mntCct', () => this.calculateStats(), 300);
        this.subscribeValueChange('mntJfz', () => this.calculateStats(), 300);
        this.subscribeValueChange('mntLpp', () => this.calculateStats(), 300);
        this.subscribeValueChange('salTotal', () => this.calculateStats(), 300);
        this.subscribeValueChange('secId', () => this.calculateStats(), 300);
        this.subscribeValueChange('shbaseglob', () => this.checkValidators(), 300);
        this.subscribeValueChange('misId', () => {
            this.calculateStats();
            this.getCCTData(this.getFormValue('cctId'));
            this.updatePgmOptions.emit(false);
        }, 300);
    }

    private checkValidators(): void {
        if (!this.formGroup) {
            return;
        }
        const shbaseglob = this.getFormValue('shbaseglob');
        if (shbaseglob === 1) {
            this.formGroup.controls['salBase'].clearValidators();
            this.formGroup.controls['salGlobal'].setValidators(Validators.required);
            this.formGroup.updateValueAndValidity();
        } else {
            this.formGroup.controls['salGlobal'].clearValidators();
            this.formGroup.controls['salBase'].setValidators(Validators.required);
            this.formGroup.updateValueAndValidity();
        }
        if (this.tempdataBtnModeOn) {
            this.setCctMinValue(this.getFormValue('cctMinValue') ?? 0);
        }
        this.updateCctValidators();
    }

    private getCCTData(cctId: string | undefined) {
        if (!cctId) {
            this.resetCctData();
            return;
        }
        this._ppcctRest.getById(cctId)
            .subscribe((cct: Ppcct) => {
                this.cctSelected = cct;
                this._cctLanguage = cct.tempdataTrans;
                if (cct.contractNo) {
                    this.getCctVersion(cct.contractNo);
                } else {
                    this.cctTempEnable = false;
                }
                if (cct.heurepause) {
                    this.setFormValue('heurepause', true);
                    this.setFormValue('hpauseauto', true);
                    if (!this.misSal) {
                        return;
                    }
                    this.misSal.canHeurPause = false;
                    this.misSal.canHeurPauseAuto = false;
                } else {
                    if (!this.misSal) {
                        return;
                    }
                    this.misSal.canHeurPause = true;
                    this.misSal.canHeurPauseAuto = true;
                }
            });
    }

    private resetCctData() {
        this.cctTempEnable = false;
        this.editionid = undefined;
        this.cctVersion = undefined;
    }

    private getCctVersion(contractId: string): void {
        this._matchingService.getScheduleEvent(this.getFormValue('misId') || '', 'ppmis')
            .pipe(switchMap((se: ScheduleEvent[]) => {
                this.scheduleEventDateStart = se[0].dateStart;

                return this._cctService.getListOfVersions(contractId, se[0].dateStart || '', this._cctLanguage ?? this.DEFAULT_LANGUAGE);
            }), map((versions: VersionCct[]) => {
                const applicableVersion = versions
                    .filter((version) => new Date(version.validFrom) <= new Date(this.scheduleEventDateStart || ''))
                    .pop();

                if (applicableVersion) {
                    this.cctVersion = applicableVersion.number;
                    return applicableVersion.number;
                }

                throw new Error('No applicable version found.');
            }), switchMap((versionNumber: number) => this._cctService.getListOfEditions(contractId, versionNumber)))
            .subscribe({
                next: (editions: EditionsCct[]) => {
                    this.editionid = editions[editions.length - 1].id;
                    this.cctTempEnable = true;
                },
                error: (e) => {
                    let businessError: BusinessException = {
                        code: 'tempdata.version.not-found',
                        message: 'An example error has occurred.',
                        params: new Map<string, string[]>()
                    };

                    let businessErrors: BusinessExceptionErrors = {
                        errors: [businessError]
                    };

                    this._toastService.businessExceptionMessage(businessErrors)
                }
            });
    }

    // eslint-disable-next-line complexity
    private calculateStats() {
        if (!this.formGroup) {
            return;
        }
        if (this.salaryMode !== 1 && this.factureMode !== 1) {
            return;
        }

        const total = Number(this.getFormValue<string>('salTotal')) || 0;
        const tarif = Number(this.getFormValue<string>('clitarif')) || 0;
        if (!total || !tarif) {
            return;
        }

        if (this._statsSubs) {
            this._statsSubs.unsubscribe();
        }

        setTimeout(() => {
            this._statsSubs = this._calcService
                .calculateStats(this.getFormData() as Ppmis)
                .pipe(catchError(err => {
                    ERROR('Error during salary calculations', [err]);
                    return of(false);
                }))
                .subscribe(e => {
                    if (typeof e === 'boolean') {
                        return;
                    }

                    this.txtCoeffValue = e.txtCoeff;
                    this.txtMargeHoursValue = e.margeHours;
                    this.coeffStatus = e.validCoeff ? 'valid-content' : 'invalid-content';

                    this.setFormValue('coeff', e.coeff);
                });
        }, 0);
    }

    private getCalculationCCT(mis: Ppmis) {
        return this._calcService.getCalculationsWithCCT(mis)
            .pipe(catchError(err => {
                ERROR('Error during salary calculations', [err]);
                return of(false);
            }));
    }

    // eslint-disable-next-line complexity
    private getAllDeductionFields(): SalaryDeduction {
        return {
            ageId: this.getFormValue('ageId') || '',
            empId: this.getFormValue('empId') || '',
            codepgm: this.getFormValue('codepgm') || '',
            codecna: this.getFormValue('codecna') || '',
            cctRa: this.getFormValue('cctRa') || false,
            cctEx: this.getFormValue('cctEx') || false,
            cctFc: this.getFormValue('cctFc') || false,
            mntFrais1: this.getFormValue('mntFrais1') || 0,
            mntFrais2: this.getFormValue('mntFrais2') || 0,
            cct: this.getFormValue('cctId') || '',
            misId: this.getFormValue('misId') || '',
            salGlobal: this.getFormValue('salGlobal') || 0,
            missalmode: this.getFormValue('missalmode') || 1
        };
    }

    private applyCalculations(res: MissionCalcCCTResponse) {
        if (!this.formGroup) {
            return;
        }
        const fixReadonly = this.isReadonly;
        this.isReadonly = true;

        this.applyFormControls(res);

        this.isReadonly = fixReadonly;
    }

    private applyFormControls(result: any) {
        for (const k of Object.keys(result)) {
            if (this._calcService.canApplyFormcontrol(k, result, this.formGroup)) {
                if (k === 'clitarifra') {
                    this._calcService.supCttCalculation.next({
                        misId: this.getFormValue('misId') ?? '',
                        supCct: result[k]
                    });
                } else {
                    this.setFormValue(<any>k, result[k]);
                }
            }
        }
    }

    private subscribeToSaveEffect() {
        this._subSaveEntity = this._actions
            .pipe(ofType(actions.GLOBAL_FORM_ENTITY_SAVE))
            .subscribe(() => {
                const cct = this._cctSelected;
                const entity = this.getFormData();

                if (!cct || !entity) {
                    return;
                }
                this.validateCctFields(entity, cct);

                if (!this._tempdataBtnModeOn) {
                    this.setCctMinValue(0);
                }
            });
    }

    // eslint-disable-next-line complexity
    private async validateCctFields(entity: Ppmis, cct: Ppcct) {
        const roundToTwo = (num: number) => Math.round(num * 100) / 100;

        const txJfEntity = roundToTwo(entity.txJf || 0);
        const txJfCct = roundToTwo(cct.txJf || 0);
        const jfMessage = this.validateValues(txJfEntity, txJfCct, '[%JF]');

        const tx13Entity = roundToTwo(entity.tx13 || 0);
        const tx13Cct = roundToTwo(cct.tx13 || 0);
        const m13Message = this.validateValues(tx13Entity, tx13Cct, '[%13]');

        const txVacEntity = roundToTwo(entity.txVac || 0);
        const txVacCct = roundToTwo(cct.txVac4 || 0);
        const vacMessage = this.validateValues(txVacEntity, txVacCct, '[%VAC]');

        // eslint-disable-next-line max-len
        // const jfMessage = this.validateValues(entity.txJf || 0, cct.txJf || 0, '[%JF]');
        // eslint-disable-next-line max-len
        // const m13Message = this.validateValues(entity.tx13 || 0, cct.tx13 || 0, '[%13]');
        // eslint-disable-next-line max-len
        // const vacMessage = this.validateValues(entity.txVac || 0, cct.txVac4 || 0, '[%VAC]');

        const allMessages: string[] = [];
        allMessages.push(jfMessage || '');
        allMessages.push(m13Message || '');
        allMessages.push(vacMessage || '');

        const messageList = allMessages.filter(e => e !== '');

        if (messageList.length > 0) {
            const options: ModalMessageOptions = {
                message: messageList,
                showCancel: false,
                title: this._translate.instant('attention'),
                okDisabled: false
            };
            await this._modalService
                .showModal(ModalMessageComponent, options);
        }
    }

    private validateValues(formVal: number, cctVal: number, field: string) {
        if (formVal > 0 && cctVal > 0) {
            if (this.isFormValueLowerThanCct(formVal, cctVal)) {
                return this.getAlertMessage(field, cctVal);
            }
        }
    }

    private isFormValueLowerThanCct(formValue: number, cctValue: number): boolean {
        return formValue < cctValue;
    }

    private getAlertMessage(field: string, refValue: number) {
        return this._translate.instant('cct_lower_value_intro') + ' ' + field.replace('[', '')
            .replace(']', '') + ' ' + this._translate.instant('cct_lower_value_message') + ' (' + refValue.toString() + '%)';
    }

    private setCctMinValue(value: number) {
        this.setFormValue('cctMinValue', value);
        if (value > 0) {
            this.formGroup?.controls['salBase'].setValidators(Validators.min(parseFloat(value.toFixed(2))));
        } else {
            this.formGroup?.controls['salBase'].clearValidators();
        }

        this.formGroup?.controls['salBase'].updateValueAndValidity();
    }
}
