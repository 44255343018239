<form [formGroup]="formGroup" class="row">
    <iw-checkbox #chkYbActif [(value)]="efactureActive" [isDisabled]="isReadonly" [label]="'ybactif' | translate"
                 class="iw-input col-xs-12" formControlName="ybactif" style="padding: 10px"></iw-checkbox>

    <iw-textarea #txtEBillAcc [height]="32" [isDisabled]="isReadonly || !efactureActive" [labelAlign]="'top'"
                 [label]="'ybebillacc' | translate" class="iw-textarea col-xs-5" formControlName="ebillacc"
                 style="padding: 10px"></iw-textarea>

    <iw-textarea #edtYbNote [height]="150" [isDisabled]="isReadonly || !efactureActive" [labelAlign]="'top'"
                 [label]="'ybnote' | translate" class="iw-textarea col-xs-12" formControlName="ybNote"
                 style="padding: 10px"></iw-textarea>

    <iw-textarea #edtYbData [height]="150" [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'ybdata' | translate"
                 class="iw-textarea col-xs-12" formControlName="ybData" style="padding: 10px"></iw-textarea>

</form>
