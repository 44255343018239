<form [formGroup]="formGroup"
      class="row">
    <div class="col-no-padding"
         style="height: 200px; width: 96%;">
        <iw-rest-grid #olvClisui
                      (dataChanged)="clearSortDir()"
                      (selected)="getSelectedRow($event)"
                      [autoFormClick]="false"
                      [customColumns]="columns"
                      [defaultSelected]="true"
                      [defaultSortDir]="'desc'"
                      [defaultSort]="'dateaction'"
                      [hasGridColumnMenu]="false"
                      [hasSortIcon]="true"
                      [innerWidth]="tableWidth"
                      [queryStatements]="query"
                      [refreshType]="refreshType"
                      [type]="type"
                      class="suivi-grid"></iw-rest-grid>
    </div>

    <div class="flex flex-v flex-between">
        <div class="flex flex-v margin-left-5"
             style="height: calc(4 * 25px);">
            <iw-button #btnAddAct
                       (press)="addSuiviMission()"
                       [isDisabled]="isReadonly"
                       *iwAccessGroups="employeeMgtAccessRoles"
                       [tooltip]="'nouvelleAction' | translate"
                       class="margin-bottom-5"
                       iconClass="fas fa-file"
                       id="btnCreateSuivi"></iw-button>

            <iw-button #btnModAct
                       (press)="editSuiviMission()"
                       [isDisabled]="!suiId || isReadonly"
                       *iwAccessGroups="employeeMgtAccessRoles"
                       [tooltip]="'modifierAction' | translate"
                       class="margin-bottom-5"
                       iconClass="fas fa-pencil-alt"
                       id="btnEditSuivi"></iw-button>

            <iw-button #btnDelAct
                       (press)="deleteSuiviMission()"
                       [isDisabled]="!suiId || isReadonly"
                       *iwAccessGroups="employeeMgtAccessRoles"
                       [tooltip]="'supprimerAction' | translate"
                       iconClass="fas fa-trash"
                       id="btnDeleteSuivi"></iw-button>
        </div>
        <iw-button #btnPrtSui
                   (press)="printService()"
                   [isDisabled]="!suiId"
                   [tooltip]="'imprimer' | translate"
                   class="margin-left-5"
                   iconClass="fas fa-print"></iw-button>
    </div>

    <div class="col-xs-12 padding-top-5 no-padding">
        <iw-textarea #edtClisuiNotes
                     [height]="150"
                     [isDisabled]="true"
                     [value]="suiviDescription"
                     class="iw-textarea col-xs-12"></iw-textarea>
    </div>
</form>
