import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';

import {Sapar} from '@app/sam-base/models/salary';
import {ParametresGenerauxComponent} from '../components/parametres-generaux/parametresGeneraux.component';

export const SAPAR_DEFAULT_FORM: FormDescription<Sapar> = {
    form: ParametresGenerauxComponent, state: {
        ...buildDefaultState(Sapar), diagTitle: 'sapar_form_title', width: 600, diagShowSideMenu: true, diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true
        }, editMode: 'read'
    }
};
