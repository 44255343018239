import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Gefacele} from '@app/sam-base/models/invoices/gefacele';
import {
    GefaceleComponent
} from '@modules/sam-main/salary/components/facture-NC/tab-facture-detail/gefacele/gefacele.component';

export const GEFACELE_NEW: FormDescription<Gefacele> = {
    form: GefaceleComponent, state: {
        ...buildDefaultState(Gefacele),
        diagTitle: 'form_gefacele',
        diagModal: false,
        width: 650,
        isNew: true,
        destroyOnSave: true
    }
};

export const GEFACELE_EDIT: FormDescription<Gefacele> = {
    form: GefaceleComponent, state: {
        ...buildDefaultState(Gefacele), diagTitle: 'form_gefacele', diagModal: true, width: 650, destroyOnSave: true
    }
};
