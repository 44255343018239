import {Injectable} from '@angular/core';

/** Service to retrieve configuration headers */
@Injectable()
export class UtilsService {

    private readonly _MODULO11_DATASTRING = '54327654';

    constructor() {
    }

    // eslint-disable-next-line complexity
    public calculateNoofValidity(noofs: string): boolean {
        if (!noofs) {
            return false;
        }
        let x = '';
        if (noofs.toLocaleLowerCase()
            .trim() === 'inconnu') {
            return true;
        }
        for (let i = 0; i < noofs.trim().length; i++) {
            if ('0123456789'.includes(noofs[i])) {
                x += noofs[i];
            }
        }

        if (x.length !== 9) {
            return false;
        }
        if (this.sd4CalcCC11(x) !== noofs[noofs.length - 1]) {
            return false;
        }
        return true;
    }

    private sd4CalcCC11(val: string): string {
        let sum = 0;
        for (let i = 0; i < val.length - 1; i++) {
            sum += Number(val[i]) * Number(this._MODULO11_DATASTRING[i]);
        }
        let ccle = '';
        let rest = sum % 11;
        if (rest === 10) {
            ccle = '?';
        } else {
            ccle = (11 - rest).toString();
        }
        rest = 11 - (sum % 11);

        switch (rest) {
            case 10:
                ccle = '?';
                break;
            case 11:
                ccle = '0';
                break;
            default:
                ccle = rest + '';
        }

        return ccle;
    }
}
