import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {LookupsComponent} from '@modules/sam-main/placement/components/lookups/lookups.component';
import {Lookups} from '@sam-base/models';

export const LOOKUPS_FORM: FormDescription<Lookups> = {
    form: LookupsComponent,
    state: {
        ...buildDefaultState(Lookups),
        diagTitle: 'Lookups',
        diagModal: false,
        editMode: 'waiting',
        width: 600
    }
};
