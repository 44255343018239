import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseStoreFormComponent} from '@sam-base/base';
import {IwTableComponent} from '@sam-base/components';
import {ModalMessageComponent} from '@sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@sam-base/components/modal/modal-message/modal-message.options';
import {HeaderToolbarService, IwStoreService, ModalService} from '@sam-base/core';
import * as actions from '@sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@sam-base/core/toast/toast.service';
import {IwGridColumn} from '@sam-base/models';
import {Ppaco, Ppper} from '@sam-base/models/placement';
import {Autoaco} from '@sam-base/models/placement/autoaco';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {PayacoService} from '../../../services/payaco.service';


@Component({
    templateUrl: './autoaco.component.html'
})
export class AutoacoComponent extends BaseStoreFormComponent<Autoaco> implements OnInit {

    @ViewChild('lstAutoAco', {static: true}) public autoAcoTable?: IwTableComponent<Autoaco>;

    public autoAcoData: Autoaco[] = [];
    public salId = '';
    public selectedAcos: Autoaco[] = [];

    public firstClick = true;

    public columns = this.getAutoacoColumns();

    constructor(store: IwStoreService, private readonly _payacoService: PayacoService, private readonly _headerService: HeaderToolbarService, private _toast: ToastService, private _modalService: ModalService) {
        super(store);
    }


    public ngOnInit() {
        this.fetchAutoacoData();
        this.firstClick = true;
    }

    public updateSelectionList(event: Autoaco[]) {
        this.selectedAcos = event;
    }

    public generate() {
        this.firstClick = false;
        this._payacoService
            .generateAcomptes(this.salId, this.selectedAcos)
            .subscribe((ppacos) => {
                this.handleSuccessMessage(ppacos);
                this.fetchAutoacoData();
            });
    }

    public isEnregistreDisabled(): boolean {
        return this.selectedAcos.length === 0 || !this.firstClick;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    private async handleSuccessMessage(ppacos: Ppaco[]) {

        let messageToDisplay = ppacos.length.toString() + ' acomptes générés avec succès.' + '\n';

        ppacos.map((ppaco) => messageToDisplay = messageToDisplay + `${ppaco?.nomemploye} - ${ppaco.montant}` + '\n');

        const options: ModalMessageOptions = {
            message: [messageToDisplay],
            title: 'Acomptes générés',
            showCancel: true,
            okMessage: 'ok_and_exit',
            cancelMessage: 'relaunch'
        };


        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this._store.dispatch(new actions.DestroyForm(this.uuid));
        } catch (e) {
            this.firstClick = true;
            this.selectedAcos = [];
            this.fetchAutoacoData();
        }
    }

    private fetchAutoacoData() {
        this._headerService.getActivePeriod()
            .pipe(mergeMap((per: Ppper) => this.handlePeriod(per)))
            .subscribe((data: Autoaco[]) => {
                this.autoAcoData = data;
            });
    }

    private handlePeriod(per: Ppper): Observable<Autoaco[]> {
        this.salId = per.salId ?? '';
        return this._payacoService.getAutoAcomptes(this.salId);
    }


    private getAutoacoColumns(): IwGridColumn<Autoaco>[] {
        return [{
            prop: 'nom', index: 0
        }, {
            prop: 'prenom', index: 1
        }, {
            prop: 'mnt100dispo', name: 'montant_100_dispo', index: 2
        }, {
            prop: 'mnt60dispo', name: 'montant_60_dispo', index: 3
        }, {
            prop: 'ageId', index: 5
        }, {
            prop: 'empId', index: 6
        }];
    }
}
