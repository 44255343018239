<form [formGroup]="formGroup"
      class="row space-form">

    <iw-textarea #edtLastJob
                 [height]="55"
                 [isDisabled]="isReadonly"
                 [labelAlign]="'top'"
                 [label]="'emploiActuelOuPrecedent' | translate"
                 class="iw-textarea col-xs-11"
                 formControlName="lastjob"></iw-textarea>

    <iw-textarea #edtParcours
                 [height]="55"
                 [isDisabled]="isReadonly"
                 [labelAlign]="'top'"
                 [label]="'parcoursProfessionnelExperience' | translate"
                 class="iw-textarea col-xs-11"
                 formControlName="parcours"></iw-textarea>

    <iw-textarea #edtApprent
                 [height]="55"
                 [isDisabled]="isReadonly"
                 [labelAlign]="'top'"
                 [label]="'etudesFormation' | translate"
                 class="iw-textarea col-xs-11"
                 formControlName="apprent"></iw-textarea>

    <iw-textarea #edtCertificat
                 [height]="55"
                 [isDisabled]="isReadonly"
                 [labelAlign]="'top'"
                 [label]="'certificats' | translate"
                 class="iw-textarea col-xs-11"
                 formControlName="certificat"></iw-textarea>

    <iw-dropdown #educationFormation
                 [isDisabled]="isReadonly"
                 [labelAlign]="'top'"
                 [label]="'educationFormation' | translate"
                 [options]="optionB"
                 class="iw-input col-xs-11 padding-top-10"
                 formControlName="swissdecFormation"
                 id="educationFormation"></iw-dropdown>
    <iw-dropdown #position
                 [isDisabled]="isReadonly"
                 [labelAlign]="'top'"
                 [label]="'position' | translate"
                 [options]="optionD"
                 class="iw-input col-xs-11 padding-top-10"
                 formControlName="swissdecPosition"
                 id="position"></iw-dropdown>
    <iw-dropdown #confession
                 [isDisabled]="isReadonly"
                 [labelAlign]="'top'"
                 [label]="'confession' | translate"
                 [options]="optionK"
                 class="iw-input col-xs-11 padding-top-10"
                 formControlName="swissdecConfession"
                 id="confession"></iw-dropdown>


</form>
