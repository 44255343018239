<div class="row">
    <div class="col-xs-10" style="height: 300px;">
        <iw-rest-grid #ppekwConfigGrid (selected)="getSelectedRow($event)" (sortInfo)="setSortInfo($event)"
                      [autoFormClick]="false" [defaultSelected]="true" [hasFilter]="true" [hasGridColumnMenu]="false"
                      [hasSortIcon]="true" [queryStatements]="query" [queryStatements]="query"
                      [selectedData]="selectedData" [sortDir]="sortPropDir" [sortProp]="sortProp"
                      [type]="type"></iw-rest-grid>
    </div>

    <div class="col-xs-2 flex flex-v flex-evenly">
        <iw-button (press)="createNewEntry()" [isDisabled]="!isReadonly" [text]="'sidemenu_new' | translate"
                   id="sidemenu_new" name="nouveau"></iw-button>
        <iw-button (press)="modifyEntry()" [isDisabled]="!isReadonly" [text]="'sidemenu_edit' | translate"
                   id="sidemenu_edit"></iw-button>
        <iw-button (press)="removeEntry()" [isDisabled]="!isReadonly" [text]="'sidemenu_delete' | translate"
                   id="sidemenu_delete"></iw-button>
        <iw-button (press)="saveEntry()" [isDisabled]="isReadonly" [text]="'sidemenu_save' | translate"
                   id="sidemenu_save" name="save"></iw-button>
        <iw-button (press)="cancelEditionMode()" [isDisabled]="isReadonly" [text]="'sidemenu_cancel' | translate"
                   id="sidemenu_cancel"></iw-button>
    </div>

</div>

<form [formGroup]="formGroup" class="row">
    <iw-textfield #txtPpekwId [isDisabled]="true" [label]="'id' | translate" class="iw-input col-xs-6 margin-bottom-5"
                  formControlName="kwId"></iw-textfield>
    <iw-textfield #txtKw [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Keyword' | translate"
                  class="iw-input col-xs-6" formControlName="kw" id="kw"></iw-textfield>
</form>
<div class="row flex flex-hr">
    <iw-button #Closebutton (press)="closeDialog()" [text]="'fermer' | translate"
               class="iw-button col-xs-2 end-xs"></iw-button>
</div>