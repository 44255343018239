<div class="row">
    <div [formGroup]="formGroup"
         class="col-xs-9">
        <div class="row">
            <iw-textfield
                    [isDisabled]="true"
                    class="iw-input col-xs-2 no-padding-right"
                    formControlName="dtaId"
                    id="id_employee"></iw-textfield>

            <iw-textfield
                    [isDisabled]="true"
                    class="iw-input col-xs-6 no-padding label-name"
                    formControlName="libelle"></iw-textfield>
            <iw-textfield
                    [isDisabled]="true"
                    class="iw-input col-xs-2 no-padding"
                    formControlName="origine"></iw-textfield>
            <iw-textfield
                    [isDisabled]="true"
                    class="iw-input col-xs-2 no-padding-left"
                    formControlName="salId"></iw-textfield>

        </div>
        <div class="row">
            <iw-textfield [isDisabled]="true"
                          [label]="'total' | translate"
                          [type]="'number'"
                          class="iw-input col-xs-3"
                          formControlName="total"></iw-textfield>

            <iw-date-picker [isDisabled]="true"
                            [label]="'date_execution' | translate"
                            class="iw-input col-xs-3"
                            formControlName="dateExec"></iw-date-picker>
            <iw-checkbox [isDisabled]="true"
                         [label]="'cancelled' | translate"
                         class="iw-input col-xs-offset-4 col-xs-2 flex-bottom"
                         formControlName="annule"></iw-checkbox>


        </div>
        <div *ngIf="query?.length > 0"
             class="row">
            <iw-rest-grid #sadsGrid
                          (selected)="getSelectedRow($event)"
                          [customColumns]="columnsToShow"
                          [defaultSelected]="true"
                          [hasGridColumnMenu]="false"
                          [hasSortIcon]="true"
                          [queryStatements]="query"
                          [type]="gridElementTypeToShow"
                          class="col-xs-12"
                          style="height: 200px"></iw-rest-grid>

        </div>

        <div class="row">
        </div>
    </div>
    <div class="col-xs-3 col">
        <div class="row margin-bottom-10">
            <iw-button (press)="print()"
                       [isDisabled]="true"
                       [text]="'print' | translate"
                       class="iw-button btn-block"></iw-button>
        </div>
        <div class="row margin-bottom-10">
            <iw-button (press)="downloadFile()"
                       [text]="'download' | translate"
                       class="iw-button btn-block"></iw-button>
        </div>
        <div class="row margin-bottom-10">
            <iw-button (press)="cancelOrder()"
                       [isDisabled]="!canCancelOrder"
                       [text]="'cancel_order' | translate"
                       class="iw-button btn-block"></iw-button>
        </div>
        <div class="row">
            <iw-button (press)="closeDialog()"
                       [text]="'Fermer'"
                       class="iw-button btn-block"></iw-button>
        </div>
    </div>
</div>
