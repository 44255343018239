<form [formGroup]="formGroup" class="row space-form">

    <div class="col-xs-12">
        <iw-radio-list #employeeTypeOption [(value)]="radioListValue"
                       [radioOptions]="employeeTypeOptions"></iw-radio-list>
    </div>


    <div class="row col-xs-12 padding-top-10">
        <iw-button #btnOk (press)="openCreationForm()" [isDisabled]="formGroup.invalid" [text]="'ok' | translate"
                   class="iw-button col-xs-6" id="btnOk"></iw-button>

        <iw-button #btnClose (press)="closeDialog()" [text]="'annuler' | translate"
                   class="iw-button col-xs-6"></iw-button>
    </div>

</form>
