import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';

import {TabMissions} from './tab-missions.model';

@Component({
    selector: 'iw-tab-missions', templateUrl: './tab-missions.component.html'
})
export class TabMissionsComponent extends BasePartialFormComponent<TabMissions> {

    @Input() public isReadonly = true;

    constructor() {
        super();
    }
}
