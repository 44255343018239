import {Component} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {SortInfo} from '@app/sam-base/components';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {EntityCreate, EntityUpdate, IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {FormKeys} from '@app/sam-base/models';
import {Ppdoctype} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {Ppdoctypes} from './ppdoctype-enums';

@Component({
    templateUrl: './ppdoctype.component.html'
})
export class PpdoctypeComponent extends BaseFormComponent<Ppdoctype> {

    public type = Ppdoctype;
    public docType = Ppdoctypes;
    public isReadonly = true;

    public pdtId = '';
    public selectedRow: Ppdoctype = {};

    public newEntryMode = false;
    public sortProp?: keyof Ppdoctype;
    public sortPropDir?: 'asc' | 'desc';

    public get selectedData() {
        if (!this.selectedRow) {
            return undefined;
        }
        return {name: 'pdtId', value: this.selectedRow.pdtId};
    }

    /** Dummy query created to bypass filter that requires some sort of params */
    public query: RestQueryParam<Ppdoctype, string>[] = [{
        prop: 'pdtId', operation: RestQueryOperation.NotEquals, value: '_'
    }];
    private readonly _restPpdoctype: RestEntityClient<Ppdoctype>;

    constructor(private _store: IwStoreService, rest: RestApiService, private _toastService: ToastService, private _translate: TranslateService) {
        super();
        this._restPpdoctype = rest.getEntityClient(Ppdoctype);
    }

    /**
     * Function to get selected row
     *
     * @param event Ppdoctype[]
     * @returns void
     */
    public getSelectedRow(event: Ppdoctype[]) {
        if (!event.length) {
            this.clearForm();
            return;
        }

        this.pdtId = (event[0] && event[0].pdtId) || '';
        this.selectedRow = event[0];
        this.fillFormData(<any>event[0]);
    }

    /**
     * Function to create new ppdoctype entry
     *
     * @returns void
     */
    public createNewEntry() {
        this.selectedRow = {};
        this.isReadonly = false;
        this.formGroup.reset();
        this.newEntryMode = true;
    }

    /**
     * Function to save changes on existing or new entry
     *
     * @returns void
     */
    public saveEntry() {
        const formData = this.getFormData();

        const updatedData = this._restPpdoctype
            .construct({...this.selectedRow, ...formData});
        this.isReadonly = true;
        this.clearForm();

        if (!updatedData || !updatedData.pdtId) {
            this._toastService.error('need_to_fill_id');
            this.newEntryMode = false;
            return;
        }

        if (this.newEntryMode) {
            this._restPpdoctype.getById(updatedData.pdtId)
                .pipe(catchError(err => of(err)))
                .subscribe((res) => {
                    if (res['status'] === 404) {
                        if (!updatedData.pdtPartype) {
                            updatedData.pdtPartype = 'E';
                        }
                        this.setSortByModif();
                        this._store.dispatch(new EntityCreate(updatedData));
                    } else {
                        this._toastService
                            .error(this._translate.instant('id_already_exits'));
                    }
                });

            this.newEntryMode = false;
            return;
        }
        this._store.dispatch(new EntityUpdate(updatedData));
        this.newEntryMode = false;
    }

    /**
     * Set fields editable
     *
     * @returns void
     */
    public modifyEntry() {
        if (this.selectedRow && this.selectedRow.pdtId) {
            this.isReadonly = false;
            this.newEntryMode = false;
        }
    }

    /**
     * Cancel the edition mode
     *
     * @returns void
     */
    public cancelEditionMode() {
        this.fillFormData(this.selectedRow);
        this.isReadonly = true;
        this.newEntryMode = false;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public setSortInfo(sortInfo: SortInfo<Ppdoctype>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    protected getValidationType() {
        return Ppdoctype;
    }

    protected getFormControlNames(): FormKeys<Ppdoctype> {
        return ['dateTrack', 'pdtId', 'pdtLibelle', 'pdtPartype', 'mandatory'];
    }

    private setSortByModif() {
        // Force the update of the string reference
        this.sortProp = 'dateTrack';
        this.sortPropDir = 'desc';
    }
}
