import {FormDescription} from '@app/sam-base/core';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Saemi} from '@app/sam-base/models/salary/saemi';
import {SaemiAVSComponent} from '../components/saemi/saemi_avs/saemi-avs.component';
import {SaemiISComponent} from '../components/saemi/saemi_is/saemi-is.component';
import {SaemiLPPComponent} from '../components/saemi/saemi_lpp/saemi-lpp.component';

export const SAEMI_IS_DEFAULT_FORM: FormDescription<Saemi> = {
    form: SaemiISComponent, state: {
        ...buildDefaultState(Saemi),
        diagModal: true,
        diagTitle: 'saemi_form_title',
        editMode: 'edit',
        width: 800,
        diagShowSideMenu: true,
        diagSideMenu: {
            showCancel: false, showClose: true, showEdit: true, showList: false, showPrint: false, showDelete: false
        },
        isNew: true,
        destroyOnSave: true
    }
};

export const SAEMI_AVS_DEFAULT_FORM: FormDescription<Saemi> = {
    form: SaemiAVSComponent, state: {
        ...buildDefaultState(Saemi),
        diagModal: true,
        diagTitle: 'saemi_form_title',
        editMode: 'edit',
        width: 800,
        diagShowSideMenu: true,
        diagSideMenu: {
            showCancel: false, showClose: true, showEdit: true, showList: false, showPrint: false, showDelete: false
        },
        isNew: true,
        destroyOnSave: true
    }
};

export const SAEMI_LPP_DEFAULT_FORM: FormDescription<Saemi> = {
    form: SaemiLPPComponent, state: {
        ...buildDefaultState(Saemi),
        diagModal: true,
        diagTitle: 'saemi_form_title',
        editMode: 'edit',
        width: 800,
        diagShowSideMenu: true,
        diagSideMenu: {
            showCancel: false, showClose: true, showEdit: true, showList: false, showPrint: false, showDelete: false
        },
        isNew: true,
        destroyOnSave: true
    }
};

