<div class="row"
     style="position: relative">
    <iw-busy [ajustLeft]="0"
             [ajustTop]="0"
             [hasDelay]="false"
             [isBusy]="loading"></iw-busy>
    <form [formGroup]="formGroup"
          class="col-xs-12">
        <div class="row">
            <div class="col-xs-4">
                <iw-textfield #txtdateOpen
                              [isDisabled]="true"
                              [labelAlign]="'top'"
                              [label]="'ouverture' | translate"
                              class="iw-input col-xs-2"
                              formControlName="dateOpen"></iw-textfield>
            </div>
            <div class="col-xs-4">
                <iw-textfield #txtdateClose
                              [isDisabled]="true"
                              [labelAlign]="'top'"
                              [label]="'cloture' | translate"
                              class="iw-input col-xs-2"
                              formControlName="dateClose"></iw-textfield>
            </div>
            <div class="col-xs-4">
                <iw-textfield #txtdateXfer
                              [isDisabled]="true"
                              [labelAlign]="'top'"
                              [label]="'transfert' | translate"
                              class="iw-input col-xs-2"
                              formControlName="dateXfer"></iw-textfield>
            </div>
            <div class="col-xs-12">
                <iw-textfield #txtgestXfer
                              [isDisabled]="true"
                              [noLabel]="true"
                              class="iw-input col-xs-2"
                              formControlName="gestXfer"
                              style="align-self: self-end"></iw-textfield>
            </div>
        </div>

        <div class="col-xs-4">
            <iw-textfield #txtnbsal
                          [decimals]="0"
                          [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'nbSalaires' | translate"
                          class="iw-input col-xs-offset-10 text-align-left"
                          formControlName="nbsal"
                          type="number"></iw-textfield>
        </div>
        <div class="col-xs-10"></div>

        <div class="row">
            <div class="col-xs-4">
                <iw-textfield #txtmntnap
                              [decimals]="2"
                              [isDisabled]="true"
                              [labelAlign]="'top'"
                              [label]="'netPayer' | translate"
                              class="iw-input col-xs-2 text-align-right"
                              formControlName="mntnap"
                              type="number"></iw-textfield>
            </div>
            <div class="col-xs-4">
                <iw-textfield #txtmntpaid
                              [decimals]="2"
                              [isDisabled]="true"
                              [labelAlign]="'top'"
                              [label]="'paye' | translate"
                              class="iw-input col-xs-12 text-align-right"
                              formControlName="mntpaid"
                              type="number"></iw-textfield>
            </div>
            <div class="col-xs-4">
                <iw-textfield #txtmntrep
                              [decimals]="2"
                              [isDisabled]="true"
                              [labelAlign]="'top'"
                              [label]="'areporter' | translate"
                              class="iw-input col-xs-2 text-align-right"
                              formControlName="mntrep"
                              type="number"></iw-textfield>
            </div>
        </div>
    </form>
</div>
