import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base/base-partial-form.component';
import {Gecli} from '@app/sam-base/models/invoices';


@Component({
    selector: 'iw-client-tab-efacture', templateUrl: './tab-client-efacture.component.html'
})
export class TabClientEFactureComponent extends BasePartialFormComponent<Gecli> {
    @Input() public isReadonly = true;

    @Input() public entityId?: string;

    @Input() public isNew = false;

    public efactureActive: boolean | undefined;

}
