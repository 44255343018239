import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Canton} from '@app/sam-base/models';
import {BaremeComponent} from '../components/bareme/bareme.component';

export const BAREME: FormDescription<Canton> = {
    form: BaremeComponent, state: {
        ...buildDefaultState(Canton),
        diagTitle: 'bareme',
        diagShowSideMenu: false,
        diagShowStatus: true,
        width: 1000,
        editMode: 'read',
        diagModal: true
    }
};
