import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@sam-base/base';
import {Gltvadef} from '@sam-base/models/accounting/gltvadef';
import {Gepar, TvaType} from '@sam-base/models/invoices';

@Component({
    selector: 'iw-tab-gepar-facturation', templateUrl: './tab-gepar-facturation.component.html'
})
export class TabGeparFacturationComponent extends BasePartialFormComponent<Gepar> {

    protected readonly TvaType = TvaType;

    public changeTva(event: Gltvadef): void {
        if (event) {
            //this.tvaTauxVal = event.tvaTaux ?? 0;
            this.setFormValue('tvaTaux', event.tvaTaux);
        }
    }
}
