<form [formGroup]="formGroup" class="row">
    <div class="col-no-padding" style="height: 200px; width: 96%;">
        <iw-rest-grid #olvClisui (dataChanged)="clearSortDir()" (selected)="getSelectedRow($event)"
                      [autoFormClick]="false" [customColumns]="columns" [defaultSelected]="true"
                      [defaultSortDir]="'desc'" [defaultSort]="'dateaction'" [hasGridColumnMenu]="false"
                      [hasSortIcon]="true" [innerWidth]="tableWidth" [queryStatements]="query"
                      [refreshType]="refreshType" [type]="type"></iw-rest-grid>
    </div>

    <div class="flex flex-v flex-between">
        <div class="flex flex-v margin-left-5" style="height: calc(4 * 25px);">
            <iw-button #btnAddAct (press)="addSuiviCde()" [isDisabled]="isReadonly"
                       [tooltip]="'nouvelleAction' | translate" class="margin-bottom-5" iconClass="fas fa-file"
                       id="btnAddAct"></iw-button>

            <iw-button #btnModAct (press)="editSuiviCde()" [isDisabled]="!selected?.suiId || isReadonly"
                       [tooltip]="'modifierAction' | translate" class="margin-bottom-5"
                       iconClass="fas fa-pencil-alt"></iw-button>

            <iw-button #btnDelAct (press)="deleteSuiviCde()" [isDisabled]="!selected?.suiId || isReadonly"
                       [tooltip]="'supprimerAction' | translate" iconClass="fas fa-trash"></iw-button>
        </div>
        <iw-button #btnPrtSui [isDisabled]="!selected?.suiId" [tooltip]="'imprimer' | translate" class="margin-left-5"
                   iconClass="fas fa-print"></iw-button>
    </div>

    <div class="col-xs-12 margin-top-5 no-padding">
        <iw-textarea #edtClisuiNotes [height]="150" [isDisabled]="true" [value]="suiviDescription"
                     class="iw-textarea col-xs-12"></iw-textarea>
    </div>

</form>
