<div class="row">
    <div class="col-xs-12" style="height: 300px;">
        <iw-rest-grid #placGrid (dataChanged)="checkPpplf($event)" (rowDoubleClick)="onRowDoubleClick($event)"
                      [autoFormClick]="false" [customColumns]="columns" [hasGridColumnMenu]="false"
                      [queryStatements]="query" [type]="type"></iw-rest-grid>
    </div>
    <div class="col-xs-12">
        <div class="flex flex-end">
            <iw-button (press)="openNewPlacement()" [isDisabled]="canCreatePpplf || isReadonly"
                       [text]="'placer' | translate" class="margin-top-5"></iw-button>
        </div>
    </div>