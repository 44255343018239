import {Component, OnInit} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {UtilsService} from '@app/sam-base/core/services/utils.service';
import {Sapar} from '@app/sam-base/models/salary/sapar';


@Component({
    selector: 'iw-tab-sapar-info-enterprise', templateUrl: './tab-sapar-info-enterprise.component.html'
})

export class TabSaparInfoEnterpriseComponent extends BasePartialFormComponent<Sapar> implements OnInit {


    constructor(private readonly _utilsService: UtilsService) {
        super();
    }

    public get pays(): string {
        return this.getFormValue('entpays') || '';
    }

    public ngOnInit(): void {
        this.addValidators();
    }

    private noofValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const isValid = this._utilsService.calculateNoofValidity(control.value);
            // eslint-disable-next-line no-null/no-null
            return isValid ? null : {invalidNoof: {value: control.value}};
        };
    }

    private addValidators() {
        this.formGroup?.controls['entofsnr'].addValidators(this.noofValidator());
        this.formGroup?.controls['entofsnr'].updateValueAndValidity();
    }
}
