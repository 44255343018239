import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@root/environments/environment';
import {Observable} from 'rxjs';

import {SendSMSResponse} from './sms-call-response.model';
import {SendSMS} from './sms-call.model';

@Injectable()
export class SmsCallService {

    protected readonly _baseUrl: string;

    constructor(private _http: HttpClient) {
        this._baseUrl = environment.backendURL;
    }

    public getCredit(ageId: string): Observable<number> {
        return this._http.get<number>(this._baseUrl + `sms/credit/${ageId}`);
    }

    public sendSms(data: SendSMS): Observable<SendSMSResponse> {
        return this._http.post<SendSMSResponse>(this._baseUrl + `sms`, data);
    }
}
