<div class="row">
    <iw-textfield [isDisabled]="true"
                  [labelAlign]="'top'"
                  [label]="'id' | translate"
                  [value]="operation.id"
                  class="iw-input col-xs-2"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  [labelAlign]="'top'"
                  [label]="'parent_id' | translate"
                  [value]="operation.parentId"
                  class="iw-input col-xs-2"></iw-textfield>
    <iw-enum-dropdown [inputEnum]="operationType"
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'type' | translate"
                      [value]="operation.type"
                      class="iw-input col-xs-2"></iw-enum-dropdown>
    <iw-textfield [isDisabled]="true"
                  [labelAlign]="'top'"
                  [label]="'status' | translate"
                  [value]="operation.status"
                  class="iw-input col-xs-2"></iw-textfield
    >
    <iw-textfield [isDisabled]="true"
                  [labelAlign]="'top'"
                  [label]="'version' | translate"
                  [value]="operation.version"
                  class="iw-input col-xs-2"></iw-textfield>
</div>
<div class="row">
    <iw-date-picker [isDisabled]="true"
                    [labelAlign]="'top'"
                    [label]="'operation_component.submittedAt' | translate"
                    [value]="operation.submittedAt"
                    class="iw-input col-xs-6"></iw-date-picker>
    <iw-textfield [isDisabled]="true"
                  [labelAlign]="'top'"
                  [label]="'operation_component.submittedBy' | translate"
                  [value]="operation.submittedBy"
                  class="iw-input col-xs-6"></iw-textfield>
</div>
<div class="row">
    <iw-textfield [isDisabled]="true"
                  [labelAlign]="'top'"
                  [label]="'operation_component.entityId' | translate"
                  [value]="operation.entityId"
                  class="iw-input col-xs-6"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  [labelAlign]="'top'"
                  [label]="'operation_component.entityDescription' | translate"
                  [value]="operation.entityDescription"
                  class="iw-input col-xs-6"></iw-textfield>
</div>
<div class="row">
    <iw-textfield [isDisabled]="true"
                  [labelAlign]="'top'"
                  [label]="'operation_component.nbOfExecutions' | translate"
                  [value]="operation.nbOfExecutions"
                  class="iw-input col-xs-6"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  [labelAlign]="'top'"
                  [label]="'operation_component.maxNbOfExecutions' | translate"
                  [value]="operation.maxNbOfExecutions"
                  class="iw-input col-xs-6"></iw-textfield>
</div>
<div class="row">
    <iw-textarea [isDisabled]="true"
                 [labelAlign]="'top'"
                 [label]="'operation_component.data' | translate"
                 [value]="operation.data"
                 class="iw-input col-xs-12"></iw-textarea>
</div>
<div class="row">
    <iw-textfield [isDisabled]="true"
                  [labelAlign]="'top'"
                  [label]="'operation_component.startedAt' | translate"
                  [value]="operation.startedAt | date:'yyyy-MM-dd HH:mm:ss'"
                  class="iw-input col-xs-6"></iw-textfield>

    <iw-textfield [isDisabled]="true"
                  [labelAlign]="'top'"
                  [label]="'operation_component.endedAt' | translate"
                  [value]="operation.endedAt | date:'yyyy-MM-dd HH:mm:ss'"
                  class="iw-input col-xs-6"></iw-textfield>
</div>
<div *ngIf="operation.error"
     class="row">
    <div [innerHTML]="operationErrorMessage"
         class="iw-textarea col-xs-12"></div>
</div>

<div *ngIf="operationDetail?.subOperations?.length > 0"
     class="row padding-top-10 separator-top">
    <h3>{{ 'operation_details' | translate }}</h3>
</div>
<div *ngIf="operationDetail?.subOperations?.length > 0"
     class="row">
    <iw-table
            (rowClicked)="onOperationDetailClicked($event)"
            (rowDoubleClick)="onRowDoubleClick($event)"
            [columns]="columns"
            [data]="operationDetail?.subOperations"
            [defaultSelected]="false"
            [isDisabled]="false"
            [isFilterEnable]="false"
            [isGridColumnMenuEnable]="false"
            [isSortIconEnable]="true"
            [selectionMode]="'single'"
            [virtualScroll]="true"
            class="iw-grid col-xs-12 no-padding"
            style="height: 20vh"></iw-table>
</div>

<div *ngIf="subOperationSelected?.error"
     class="row">
    <div [innerHTML]="subOperationErrorMessage"
         class="iw-textarea col-xs-12"></div>
</div>