import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {
    Sasx5decSideMenuComponent
} from '@modules/sam-main/admin/components/swissdec/side-menu/sasx5dec-side-menu.component';
import {SwissdecComponent} from '@modules/sam-main/admin/components/swissdec/swissdec.component';
import {Sasx5dec} from '@sam-base/models/admin/sasx5dec.model';

export const SASX5DEC_DECLARATION: FormDescription<Sasx5dec> = {
    form: SwissdecComponent,
    sideMenu: Sasx5decSideMenuComponent,
    state: {
        ...buildDefaultState(Sasx5dec),
        diagTitle: 'sasx5dec_form_title',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true,
            showClose: true,
            showEdit: true,
            showList: false,
            showPrint: false,
            showDelete: true
        },
        editMode: 'read',
        useNavigation: false,
        readonly: false,
        patch: false,
        width: 1600
    }
};

