<form [formGroup]="formGroup" class="row space-form">

    <iw-textfield #txtAco_id [labelAlign]="'left'" [label]="'acompte' | translate" class="iw-input col-xs-4"
                  formControlName="acoId" isDisabled="true"></iw-textfield>

    <iw-textfield #txtPeriode class="iw-input col-xs-4" formControlName="salId" isDisabled="true"></iw-textfield>

    <iw-textfield #txtSession [labelAlign]="'left'" [label]="'session' | translate" class="iw-input col-xs-4"
                  formControlName="session" isDisabled="true"></iw-textfield>


    <iw-enum-textfield #txtAcoType [inputEnum]="aacotype" [labelAlign]="'left'" [label]="'type' | translate"
                       class="iw-input col-xs-4" formControlName="acotype" isDisabled="true"></iw-enum-textfield>

    <!-- Dymanic fields -->

    <iw-textfield #txtRap_no *ngIf="isReportType()" class="iw-input col-xs-3" formControlName="rapNo"
                  isDisabled="true"></iw-textfield>


    <iw-textfield #txtRap_id *ngIf="isReportType()" class="iw-input col-xs-2" formControlName="rapId"
                  isDisabled="true"></iw-textfield>


    <iw-button #btnDummy (press)="isReportType()" *ngIf="isReportType()" [text]="'Rapport...' | translate"
               class="iw-button col-xs-2"></iw-button>

    <div *ngIf="!isReportType()" class="col-xs-6"></div>
    <!-- End of Dynamic fields -->

    <iw-entity-textfield #txtNomEmploye [allowSearch]="true" [entity]="entityClient" [labelAlign]="'left'"
                         [label]="'employe' | translate" [name]="['nom', 'prenom']" [showIdField]="false"
                         class="iw-input col-xs-7" formControlName="empId" isDisabled="true"></iw-entity-textfield>


    <iw-textfield #txtEmp_id class="iw-input col-xs-3" formControlName="empId" isDisabled="true"></iw-textfield>


    <iw-agency-combo-box #cboAge_id [labelAlign]="'left'" [label]="'agence' | translate" class="iw-input col-xs-8"
                         formControlName="ageId" isDisabled="true"></iw-agency-combo-box>

    <iw-textfield #txtAge_id class="iw-input col-xs-2" formControlName="ageId" isDisabled="true"></iw-textfield>

    <iw-enum-dropdown #cboAcoPayType [inputEnum]="acopaytype" [labelAlign]="'left'" [label]="'payepar' | translate"
                      class="iw-input col-xs-8" formControlName="acopaytype" isDisabled="true"></iw-enum-dropdown>

    <iw-checkbox #chkAcoPaid [label]="'lacompteAEtePaye' | translate" class="iw-input col-xs-4"
                 formControlName="acopaid" isDisabled="true"></iw-checkbox>

    <iw-textfield #txtAcodate [labelAlign]="'left'" [label]="'date' | translate" class="iw-input col-xs-5"
                  formControlName="acodate" isDisabled="true"></iw-textfield>


    <iw-textfield #txtNoCheque [labelAlign]="'left'" [label]="'noCheque' | translate" class="iw-input col-xs-5"
                  formControlName="nocheque" isDisabled="true"></iw-textfield>

    <iw-textfield #txtAcoMontant [labelAlign]="'left'" [label]="'montant' | translate" class="iw-input col-xs-5"
                  formControlName="montant" isDisabled="true"></iw-textfield>

    <iw-textfield #txtDate_pmnt [labelAlign]="'left'" [label]="'payeLe' | translate" class="iw-input col-xs-5"
                  formControlName="datePmnt" isDisabled="true"></iw-textfield>

    <iw-textfield #txtMpa_id [labelAlign]="'left'" [label]="'payeDepuis' | translate" class="iw-input col-xs-5"
                  formControlName="mpaId" isDisabled="true"></iw-textfield>

    <iw-textfield #txtDta_id class="iw-input col-xs-2" formControlName="dtaId" isDisabled="true"></iw-textfield>

    <iw-textfield #txtLibelle [labelAlign]="'left'" [label]="'libelle' | translate" class="iw-input col-xs-8"
                  formControlName="libelle" isDisabled="true"></iw-textfield>

    <iw-checkbox #chkLnoaco [label]="'horsAcomptesAutomatiques' | translate" class="iw-input col-xs-8 col-xs-offset-3"
                 formControlName="lnoaco" isDisabled="true"></iw-checkbox>

    <iw-textfield #txtBanque_clr [labelAlign]="'top'" [label]="'infoDta' | translate" class="iw-input col-xs-2"
                  formControlName="banqueClr" isDisabled="true"></iw-textfield>

    <iw-textfield #txtBanque_swi [labelAlign]="'top'" [label]="' '" class="iw-input col-xs-3"
                  formControlName="banqueSwi" isDisabled="true"

    ></iw-textfield>

    <iw-textfield #txtBanque_cpt [labelAlign]="'top'" [label]="' '" class="iw-input col-xs-5"
                  formControlName="banqueCpt" isDisabled="true"></iw-textfield>

    <iw-textfield #txtIban class="iw-input col-xs-10" formControlName="iban" isDisabled="true"></iw-textfield>

</form>

