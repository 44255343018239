import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@sam-base/core';

@IwRestEntity('gedtaview')
export class GedtaView {
    @IwRestEntityId() @IwColumn({
        name: 'id',
        index: 0
    }) public dtaId?: string;
    @IwColumn({
        name: 'period',
        index: 1,
        type: 'salId'
    }) public salId?: string;
    @IwColumn({
        name: 'mode',
        index: 3
    }) public mpaId?: string;
    public gMode?: string;
    @IwColumn({
        name: 'date_paiement',
        index: 2,
        type: 'date'
    }) public dateExec?: string;
    public payId?: string;
    @IwColumn({
        name: 'libelle',
        index: 5
    }) public libelle?: string;
    @IwColumn({
        name: 'origine',
        index: 4
    }) public origine?: string;
    @IwColumn({
        name: 'nb',
        index: 6,
        type: 'number',
        align: 'right'
    }) public nbPay?: number;
    @IwColumn({
        name: 'total',
        index: 7,
        type: 'mnt',
        align: 'right'
    }) public total?: number;
    public fContent?: string;
    public noOrdre?: string;
    public filErased?: boolean;
    @IwColumn({
        name: 'annule',
        index: 8,
        type: 'boolean',
    }) public annule?: boolean;
    public userId?: string;
    public annUserId?: string;
    public dateAnnul?: string;
}
