<form [formGroup]="formGroup" class="row spaceForm">
    <div class="col-xs-12">
        <h2>{{ 'institutions' | translate }}</h2>
        <h3>{{ subtitle }}</h3>
        <h4 [class]="institutionState">{{ institutionState | translate }}</h4>
        <!--  VERSION XSLT  -->
        <div *ngIf="xsltFromatted" class="row">
            <div class="col-xs-12">
                <div [innerHtml]="formattedMessage"></div>
            </div>
        </div>

        <!--  VERSION NON XSLT  -->
        <div *ngIf="institutionState !== 'ERROR'" class="row">
            <ul *ngFor="let notification of notifications">
                <li class="col-xs-12">
                    {{ notification.qualityLevel | translate }} - {{ notification.description }}
                    ({{ notification.descriptionCode }})
                </li>
            </ul>
        </div>
        <div *ngIf="institutionState === 'ERROR'" class="row">
            <p>{{ userMessage }}</p>
        </div>

        <!--  COMPLETION_RELEASE_IS_MISSING  -->
        <div *ngIf="isCompletionReleaseIsMissing || isProcessFlowUrl" class="row">
            <div class="col-xs-12">
                <h4>Completion URL</h4>
            </div>
            <div class="col-xs-12">
                <p class="row">
                    {{ 'completion_url_process_description' | translate }} </p>

                <div class="row">
                    <a [href]="completionUrl" target="_blank">{{ 'completion_url' | translate }}</a>
                </div>
                <div class="row">
                    <iw-textfield [isDisabled]="true" [label]="'login' | translate" [tooltipPosition]="'right'"
                                  [tooltip]="'login' | translate" [value]="login" class="col-xs-12" labelAlign="top"
                                  size="small"></iw-textfield>

                    <iw-textfield [isDisabled]="true" [label]="'password' | translate" [tooltipPosition]="'right'"
                                  [tooltip]="'password' | translate" [value]="password" class="col-xs-12"
                                  labelAlign="top" size="small"></iw-textfield>
                </div>
            </div>

        </div>

        <div *ngIf="isProcessFlowDialog" class="col-xs-12">
            <div class="row">
                <p class="col-xs-12">
                    {{ 'completion_dialog_process_description' | translate }} </p>
            </div>
        </div>
        <div *ngIf="isProcessFlowResult" class="col-xs-12">
            <div class="row">
                <p class="col-xs-12">
                    {{ 'institution_result_description' | translate }} </p>
            </div>
        </div>
    </div>

</form>
