import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {
    SamUserAdminTableComponent
} from '@modules/sam-main/admin/components/sam-user-admin/table/sam-user-admin-table.component';
import {SamUser} from '@sam-base/models/admin/sam-user';

export const SAM_USER_ADMIN_LIST: FormDescription<SamUser> = {
    form: SamUserAdminTableComponent,
    state: {
        ...buildDefaultState(SamUser),
        diagModal: false,
        editMode: 'waiting',
        width: 1600
    }
};

