import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {EsQueryService} from './elastic-search/es-query.service';
import {RestApiService} from './rest-api.service';

@NgModule({
    imports: [CommonModule], providers: [EsQueryService, RestApiService]
})
export class IwRestApiModule {
}
