<form [formGroup]="formGroup"
      class="row space-form">
    <iw-busy [ajustLeft]="14"
             [isBusy]="isLoading"
             class="col-xs-12"></iw-busy>
    <iw-entity-textfield #txtCde
                         (valueChange)="selectClientAndCons($event)"
                         [allowSearch]="true"
                         [entity]="entityCde"
                         [filter]="filterCde"
                         [label]="'commande' | translate"
                         [name]="['cdeId', 'nomclient', 'profession']"
                         [showIdField]="false"
                         class="iw-input col-xs-9"
                         formControlName="cdeId"
                         id="txtCde"></iw-entity-textfield>

    <iw-button #clearForm
               (press)="resetForm()"
               [text]="'raz' | translate"
               class="iw-button col-xs-2 col-xs-offset-1 padding-top-20 flex-end"></iw-button>

    <iw-entity-textfield #ctnSelEmp
                         [allowSearch]="true"
                         [entity]="entityEmploye"
                         [filter]="filterPpemp"
                         [label]="'employe' | translate"
                         [name]="['nom', 'prenom']"
                         [showIdField]="false"
                         class="iw-input col-xs-9"
                         formControlName="empId"
                         id="ctnSelEmp"></iw-entity-textfield>

    <iw-entity-textfield #ctnSelCli
                         [allowSearch]="true"
                         [entity]="entityClient"
                         [filter]="filterPpcli"
                         [label]="'client' | translate"
                         [name]="['nom', 'prenom']"
                         [showIdField]="false"
                         class="iw-input col-xs-9"
                         formControlName="cliId"
                         id="ctnSelCli"></iw-entity-textfield>


    <iw-conseiller-dropdown #cboCon_id
                            [isDisabled]="!isWriteMode"
                            [label]="'conseiller' | translate"
                            class="iw-combobox col-xs-9 padding-bottom-10"
                            formControlName="conId"
                            id="cboCon_id"></iw-conseiller-dropdown>

    <iw-checkbox #chkNew_cpyfrommdl
                 (valueChange)="uncheckOtherboxes($event, 'cpyfrommdl')"
                 [isDisabled]="!canCopyModel()"
                 [label]="'copierDepuisLeModele' | translate"
                 class="iw-input col-xs-10"
                 formControlName="cpyfrommdl"
                 id="chkNew_cpyfrommdl"></iw-checkbox>

    <iw-button #btnOk
               (press)="submitMission()"
               [isDisabled]="!formFullfilled()"
               [text]="'ok' | translate"
               class="iw-button col-xs-2"
               id="ok-misnew"></iw-button>

    <iw-checkbox #chknew_createmdl
                 (valueChange)="uncheckOtherboxes($event, 'createmodel')"
                 [isDisabled]="!canCreateModel()"
                 [label]="'creerUnModele' | translate"
                 class="iw-input col-xs-10"
                 formControlName="createmodel"
                 id="chknew_createmdl"></iw-checkbox>
    <iw-textfield
        #txtTitle
        *ngIf="isCreatingModel"
        [labelAlign]="'top'"
        [label]="'titre' | translate"
        class="iw-input col-xs-10"
        formControlName="modelTitle"
    ></iw-textfield>

    <iw-button #Standardbutton2
               (press)="destroyForm()"
               [text]="'annuler' | translate"
               class="iw-button col-xs-2"
               id="chknew_createmdl"></iw-button>


    <div class="col-xs-12 title-grid">
        {{ 'ppmismdl_form_title' | translate }}
    </div>

    <iw-table #lstMdl
              (selectedChange)="selectMissionModel($event)"
              [columns]="columns"
              [data]="missionModelData"
              [defaultSelected]="true"
              [isDisabled]="!isCopyModelSelected()"
              [isFilterEnable]="false"
              [isGridColumnMenuEnable]="false"
              [isSortIconEnable]="false"
              class="iw-grid col-xs-12"
              id="lstMdl"
              style="height: 200px"></iw-table>
</form>
