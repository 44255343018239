import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {MessageFormRefreshService} from '@app/sam-base/components/subjects/message-service-subject.service';
import {FormHandlerService} from '@app/sam-base/core';
import {ClipboardService} from '@app/sam-base/core/clipboard/clipboard.service';
import {RestApiService, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {ToastService} from '@app/sam-base/core/toast';
import {RowClickEvent} from '@app/sam-base/models';
import {PlacementSearchNavigation} from '@core/placement-search/placement-search.service';
import {AdminForm} from '@modules/sam-main/admin/admin.forms';

import {Sasx5dec} from '@sam-base/models/admin/sasx5dec.model';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxStatus} from '@sam-base/models/admin/sx-status.enum';
import {Subject, takeUntil} from 'rxjs';

/**
 * Tab General of Swissdec Declaration
 */
@Component({
    selector: 'iw-tab-sasx5dec-institutions',
    templateUrl: './tab-sasx5dec-institutions.component.html'
})
export class TabSasx5decInstitutionsComponent extends BasePartialFormComponent<Sasx5dec> implements OnInit, OnDestroy {

    @ViewChild('institutionsGrid', {static: true}) public institutionsGrid?: IwRestGridComponent<Sasx5institution>;

    @Input() public isNew = false;
    public type = Sasx5institution;
    public query: RestQueryParam<Sasx5institution, string>[] = [];
    private subscriptions = new Subject();

    constructor(private readonly _clipboardService: ClipboardService, private readonly _restService: RestApiService,
                private readonly _toastService: ToastService,
                private readonly _gridNavigation: PlacementSearchNavigation,
                private readonly _formHandler: FormHandlerService<AdminForm>,
                private readonly messageService: MessageFormRefreshService) {
        super();
    }

    public get entityId() {
        const id = this.getFormValue('id');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public get canEditInstitutions() {
        // eslint-disable-next-line max-len
        return [SxStatus.GET_STATUS_ERROR,
            SxStatus.INIT,
            SxStatus.INIT_ERROR].indexOf(this.getFormValue('status') || SxStatus.INIT) === -1;
    }

    public ngOnInit() {
        this.initInstitutionQueryWithDeclarationId(this.getFormValue('id') || '');
        this.messageService.getMessage()
            .pipe(takeUntil(this.subscriptions))
            .subscribe(message => {
                if (message && 'sasx5dec.refresh.institutions' === message) {
                    this.refreshGrid();
                }
            });

    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public onRowDoubleClick(event: RowClickEvent<Sasx5institution>) {
        if (!this.canEditInstitutions) {
            return;
        }
        if (event && event.row.id) {
            this._formHandler
                .showFormDialog(AdminForm.SwissdecDeclarationInstitution, event, s => ({
                    ...s,
                    entityId: event.row.id || '',
                    diagModal: true,
                    width: 900,
                    height: 900
                }));
        }
    }

    private initInstitutionQueryWithDeclarationId(annee: string) {
        this.query = [{
            prop: 'declarationId',
            operation: RestQueryOperation.Equals,
            value: annee
        }];
    }

    private refreshGrid(): void {
        if (!this.institutionsGrid) {
            return;
        }
        this.institutionsGrid.refresh();
    }

}
