import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {SagenService} from '@app/sam-base/core/services/sagen.service';
import {Sagen} from '@app/sam-base/models/salary/sagen';

@Component({
    selector: 'iw-tab-sagen-informations', templateUrl: './tab-sagen-informations.component.html'
})
export class TabSagenInformationsComponent extends BasePartialFormComponent<Sagen> implements OnInit {

    public loading = false;

    constructor(private _sagenService: SagenService) {
        super();
    }

    public get isPeriodOpen(): boolean {
        return this.getFormValue('status') === 1;
    }

    public get salId(): string {
        return this.getFormValue('salId') || '';
    }

    public ngOnInit() {
    }

}
