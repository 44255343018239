import {Component, Input} from '@angular/core';
import {DocumentEntityRef} from '@app/sam-base/models/placement/document-entity-ref';

@Component({
    selector: 'iw-tab-doc', templateUrl: 'tab-doc.component.html'
})
export class TabDocComponent {
    @Input() public isReadonly = true;

    public entityRef: DocumentEntityRef = DocumentEntityRef.Client;

    @Input()
    public set clientId(v: string) {
        this._clientId = v;
    }

    public get clientId() {
        return this._clientId;
    }

    private _clientId = '';

    constructor() {
    }
}
