import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {SapaimodeComponent} from '@modules/sam-main/salary/components/sapaimode/sapaimode.component';
import {Sapaimode} from '@sam-base/models/salary/sapaimode';

export const SAPAIMODE_FORM: FormDescription<Sapaimode> = {
    form: SapaimodeComponent, state: {
        ...buildDefaultState(Sapaimode),
        diagTitle: 'mode_paiement',
        diagShowSideMenu: false,
        diagShowStatus: true,
        width: 700
    }
};
