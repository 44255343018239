import {Ppcli, Ppemp} from '@app/sam-base/models/placement';

import {GridProfileData} from './grid-profile.model';

/** Mock to support yet absence of System Grid Profiles */
/** Developed in: https://samredesign.atlassian.net/browse/SAM-4290 */

/* eslint-disable */
export const LocalPpcliProfileClientContacts: GridProfileData<Ppcli> = {
    userid: '',
    cfgid: '',
    name: 'list_client_contacts',
    search: '',
    entity: 'ppcli',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: {0: {prop: 'nom'}, 1: {prop: 'adresse1'}, 2: {prop: 'tel1'}}
    }
};

export const LocalPpcliProfileClientAdresses: GridProfileData<Ppcli> = {
    userid: '',
    cfgid: '',
    name: 'list_client_adresses',
    search: '',
    entity: 'ppcli',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: {0: {prop: 'nom'}, 1: {prop: 'adresse1'}}
    }
};

export const LocalPpcliProfileTelFaxEmail: GridProfileData<Ppcli> = {
    userid: '',
    cfgid: '',
    name: 'list_client_telfaxemail',
    search: '',
    entity: 'ppcli',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: {
            0: {prop: 'cliId', index: 0},
            1: {prop: 'nom', index: 1},
            2: {prop: 'tel1'},
            3: {prop: 'fax'},
            4: {prop: 'cctoblig'}
        }
    }
};

export const LocalPpcliProfileResume: GridProfileData<Ppcli> = {
    userid: '',
    cfgid: '',
    name: 'list_client_resume',
    search: '',
    entity: 'ppcli',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: {
            0: {prop: 'nom', index: 0}, 1: {prop: 'adresse1', index: 1}, 2: {prop: 'tel1'}, 3: {prop: 'fax'}
        }
    }
};

export const LocalPpcliProfileCategories: GridProfileData<Ppcli> = {
    userid: '',
    cfgid: '',
    name: 'list_client_category',
    search: '',
    entity: 'ppcli',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: {
            0: {prop: 'cliId', index: 0}, 1: {prop: 'nom', index: 1}, 2: {prop: 'categories'}
        }
    }
};

export const LocalPpcliProfileCCT: GridProfileData<Ppcli> = {
    userid: '',
    cfgid: '',
    name: 'list_client_cct',
    search: '',
    entity: 'ppcli',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: {
            0: {prop: 'cliId', index: 0}, 1: {prop: 'nom', index: 1}, 2: {prop: 'cctoblig'}
        }
    }
};
