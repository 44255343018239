<form [formGroup]="formGroup">
    <div class="col-ls-12">
        <div class="col-lg-12 row qr-div-resize">
            <img *ngIf="!hasImage"
                 [src]="showQR()"
                 alt="emailSignature"
                 class="cons-img qr-image-resize">
        </div>
    </div>


</form>
