import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Gecli} from '@app/sam-base/models/invoices';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class GecliService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getGecliByCliId(cliId?: string): Observable<Gecli> {
        return this._http.get(this._baseUrl + `gecli/getGecli/${cliId}`);
    }

    public getCliNomByCliId(cliId: string): Observable<any> {
        return this._http.get(this._baseUrl + `gecli/getCliNom/${cliId}`);
    }
}
