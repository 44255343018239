import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {SalaryComptaComponent} from '@modules/sam-main/salary/components/salary-compta/salary-compta.component';
import {Sacompta} from '@sam-base/models/salary/sacompta';

export const SALARY_COMPTA_FORM: FormDescription<Sacompta> = {
    form: SalaryComptaComponent,
    state: {
        ...buildDefaultState(Sacompta),
        diagTitle: 'diag_form_salary_compta',
        width: 600,
        diagShowSideMenu: false
    }
};
