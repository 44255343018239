import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ComboboxItem} from '@app/sam-base/models/components/combobox-item.model';
import {Ppcct} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'iw-tab-ra', templateUrl: './tab-ra.component.html'
})
export class TabRaComponent extends BasePartialFormComponent<Ppcct> implements OnInit {

    public raDedmodOptions: ComboboxItem<number>[] = [];

    constructor(private _translate: TranslateService) {
        super();
        this.raDedmodOptions = [{
            name: this._translate.instant('horaire'), value: 1
        }, {name: this._translate.instant('avs'), value: 2}, {
            name: this._translate.instant('suva'), value: 3
        }, {name: this._translate.instant('brut'), value: 4}, {name: this._translate.instant('avsSans13'), value: 5}];
    }

    public cboRaTxEmpSocEnabled() {
        return !this.isReadonly && Number(this.getFormValue('raDedmod')) > 1;
    }

    public cboRaMntEmpSocEnabled() {
        return !this.isReadonly && Number(this.getFormValue('raDedmod')) <= 1;
    }

    public ngOnInit() {

    }

}
