import {Component} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {SortInfo} from '@app/sam-base/components';
import {EntityCreate, EntityDelete, EntityUpdate, IwStoreService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {DestroyForm} from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Geclicat} from '@app/sam-base/models/invoices/geclicat';


@Component({
    templateUrl: './geclicat.component.html'
})
export class GeclicatComponent extends BaseFormComponent<Geclicat> {

    public type = Geclicat;
    public isReadonly = true;
    public newEntryMode = false;

    public cliCatId = '';
    public selectedRow?: Geclicat;
    public isSaving = false;
    public sortProp?: keyof Geclicat;
    public sortPropDir?: 'asc' | 'desc';
    /** Dummy query created to bypass filter that requires some sort of params */
    public query: RestQueryParam<Geclicat, string>[] = [{
        prop: 'cliCatId', operation: RestQueryOperation.NotEquals, value: '_'
    }];
    private readonly _restGeclicatClient: RestEntityClient<Geclicat>;

    constructor(private rest: RestApiService, private _store: IwStoreService) {
        super();
        this._restGeclicatClient = rest.getEntityClient(Geclicat);
    }

    public get selectedData() {
        if (!this.selectedRow) {
            return undefined;
        }
        return {name: 'cliCatId', value: this.selectedRow.cliCatId};
    }

    /**
     * Function to get selected row
     *
     * @param event Ppgrt[]
     * @returns void
     */
    public getSelectedRow(event: Geclicat[]) {
        this.isReadonly = true;

        if (this.isSaving) {
            // Prevents clearing of the selectedRow
            this.isSaving = false;
            return;
        }

        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }

        this.cliCatId = (event[0] && event[0].cliCatId) || '';
        this.selectedRow = event[0];
        this.fillFormData(<any>event[0]);
    }

    /**
     * Function to create new ppgrt entry
     *
     * @returns void
     */
    public createNewEntry() {
        this.selectedRow = undefined;
        this.isReadonly = false;
        this.formGroup.reset();
        this.newEntryMode = true;
    }

    /**
     * Function to save changes on existing or new entry
     *
     * @returns void
     */
    public saveEntry() {
        const formData = this.getFormData();

        const updatedData = this._restGeclicatClient
            .construct({...this.selectedRow, ...formData});
        this.isReadonly = true;
        this.clearForm();

        this.isSaving = true;

        if (this.newEntryMode) {
            this.setSortByModif();
            this._store.dispatch(new EntityCreate(updatedData));
            this.newEntryMode = false;

            return;
        }
        this._store.dispatch(new EntityUpdate(updatedData));
        this.newEntryMode = false;
    }

    /**
     * Set fields editable
     *
     * @returns void
     */
    public modifyEntry() {
        if (this.selectedRow && this.selectedRow.cliCatId) {
            this.isReadonly = false;
            this.newEntryMode = false;
        }
    }

    /**
     * Function to remove entry
     *
     * @returns void
     */
    public removeEntry() {
        if (this.selectedRow && this.selectedRow.cliCatId) {
            this._store.dispatch(new EntityDelete(Geclicat, this.cliCatId));
        }
    }

    /**
     * Cancel the edition mode
     *
     * @returns void
     */
    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.isReadonly = true;
        this.newEntryMode = false;
    }

    public closeDialog() {
        this._store.dispatch(new DestroyForm(this.uuid));
    }

    public setSortInfo(sortInfo: SortInfo<Geclicat>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    protected getValidationType() {
        return Geclicat;
    }

    protected getFormControlNames(): FormKeys<Geclicat> {
        return ['cliCatId', 'libelle'];
    }

    private setSortByModif() {
        // Force the update of the string reference
        this.sortProp = 'libelle';
        this.sortPropDir = 'desc';
    }
}
