<form [formGroup]="formGroup"
      class="row">
    <div class="row space-form col-md-12">
        <div class="row col-xs-12"
             style="align-items: end;">
            <div class="row col-xs-12"
                 style="align-items: end;">

                <iw-textfield #txtlagemin
                              [decimals]="0"
                              [isDisabled]="isReadonly"
                              [labelAlign]="'top'"
                              [label]="'agemin' | translate"
                              class="iw-input col-xs-4"
                              formControlName="agemin"
                              type="number"></iw-textfield>
                <iw-textfield #txtagemax
                              [decimals]="0"
                              [isDisabled]="isReadonly"
                              [labelAlign]="'top'"
                              [label]="'agemax' | translate"
                              class="iw-input col-xs-4"
                              formControlName="agemax"
                              type="number"></iw-textfield>
                <iw-textfield #txtagemnt
                              [decimals]="2"
                              [isDisabled]="isReadonly"
                              [labelAlign]="'top'"
                              [label]="'montant' | translate"
                              class="iw-input col-xs-4"
                              formControlName="agemnt"
                              type="number"></iw-textfield>
            </div>


            <div class="row col-xs-12 padding-top-10"
                 style="align-items: end;">
                <iw-button (press)="save()"
                           [text]="'enregistrer' | translate"
                           class="iw-button padding-top-15 margin-right-5"></iw-button>
                <iw-button (press)="destroyForm()"
                           [text]="'Annuler' | translate"
                           class="iw-button"></iw-button>
            </div>
        </div>
    </div>
</form>
