import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Gepaimode} from '@app/sam-base/models/invoices/gepaimode';
import {GepaimodeType} from '@modules/sam-main/salary/components/encaissement/encaissement-attribution.component';
import {Observable, Subject} from 'rxjs';
import {RestClient} from '../rest-api/core';

export interface GepaimodeSubscrition {
    uuid: string;
    saved: boolean;
}

@Injectable()
export class GepaimodeService extends RestClient<any> {

    public gepaimodeSub: Subject<GepaimodeSubscrition> = new Subject();

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getAllGepaimode(): Observable<Gepaimode[]> {
        return this._http.get<Gepaimode[]>(this._baseUrl + `gepaimode/getAll`);
    }

    public save(gepaimode: Gepaimode): Observable<void> {
        return this._http.post<void>(this._baseUrl + `gepaimode/save`, gepaimode);
    }

    public delete(mpaId: string): Observable<void> {
        return this._http.delete<void>(this._baseUrl + `gepaimode/delete/${mpaId}`);
    }

    public getGepaimodeByPaytype(): Observable<GepaimodeType[]> {
        return this._http.get<GepaimodeType[]>(this._baseUrl + `gepaimode/findByPaytype`);
    }
}
