<form [formGroup]="formGroup" class="row">
    <iw-table (selectedChange)="getSelectedRow($event)" [columns]="columns" [data]="saemiList" [isFilterEnable]="false"
              [isGridColumnMenuEnable]="false" [isSortIconEnable]="false" [rawTableMode]="true"
              class="col-xs-10 no-padding"></iw-table>
    <div class="col-xs-2">
        <iw-textfield [isDisabled]="true" [labelAlign]="'left'" [label]="'' | translate" [value]="selectedElem?.emiId"
                      class="iw-input col-xs-12 padding-top-15"></iw-textfield>

        <div class="row">
            <iw-button (press)="addIs()" [isDisabled]="isReadonly" [text]="'nouveau_is' | translate"
                       class="col-xs-12"></iw-button>
        </div>
        <div class="row">
            <iw-button (press)="addAvs()" [isDisabled]="isReadonly" [text]="'nouveau_avs_caf' | translate"
                       class="col-xs-12"></iw-button>
        </div>
        <div class="row">
            <iw-button (press)="addLpp()" [isDisabled]="isReadonly" [text]="'nouveau_lpp' | translate"
                       class="col-xs-12"></iw-button>
        </div>
        <div class="row">
            <iw-button (press)="editElem()" [isDisabled]="!selectedElem" [text]="'modif' | translate"
                       class="col-xs-12"></iw-button>
        </div>
        <div class="row">
            <iw-button (press)="deleteElem()" [isDisabled]="!selectedElem" [text]="'sidemenu_delete' | translate"
                       class="col-xs-12"></iw-button>
        </div>
    </div>
</form>
