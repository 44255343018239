import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Ppoptsal} from '@app/sam-base/models/placement';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const salaryParametersTabsFormControls: TabFormControl<Ppoptsal>[] = [
    {
        formControls: [
            'cu1Active',
            'cu1Titre',
            'cu2Active',
            'cu2Titre',
            'cu3Active',
            'cu3Titre',
            'cu4Active',
            'cu4Titre']
    },
    {
        title: 'salaryType',
        formControls: [
            'sags100',
            'sagsjfhor',
            'sags125',
            'sagsvac',
            'sags150',
            'sags200',
            'sags13',
            'sagsxxx',
            'sagsjf',
            'sagsequip',
            'sagsequix',
            'sagshlpp',
            'sagsnuit',
            'sagsop',
            'sagspause',
            'sagsmois',
            'sagsfrais1',
            'sagsfrais2']
    },
    {
        title: 'deductions',
        formControls: [
            'cnamode',
            'pgmmode',
            'impotmode',
            'iscantmode',
            'lppmode',
            'alfmode',
            'satblreq',
            'cctlsinsa',
            'saded1def',
            'satblded1h',
            'satblded1f',
            'saded2def',
            'satblded2h',
            'satblded2f',
            'saded3def',
            'satblded3h',
            'satblded3f',
            'saded4def',
            'satblded4h',
            'satblded4f'

        ]
    },
    {
        title: 'provisions',
        formControls: [
            'sagsvacprv',
            'sagsvacppr',
            'sags13prv',
            'sags13ppr',
            'sagsjfhprv',
            'sagsjfhppr',
            'sagsnuiprv',
            'sagsnuippr']
    },
    {
        title: 'autreTab',
        formControls: [
            'raplibmode',
            'libweek',
            'libhequip',
            'libhequix',
            'libhnuit',
            'libhpause']
    }];
