import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Js3view} from '@app/sam-base/models/salary/js3view';
import {DecompteGSComponent} from '../components/employee/tab-saemp-annee/decompte-gs/decompte-gs.component';

export const DECOMPTE_GS_INFO_FORM: FormDescription<Js3view> = {
    form: DecompteGSComponent,
    state: {
        ...buildDefaultState(Js3view),
        diagTitle: 'decompte_gs_form',
        diagModal: false,
        width: 1440,
        isNew: true
    }
};
