import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppcliatt} from '@app/sam-base/models/placement';

import {PpcliattComponent} from '../components/ppcliatt/ppcliatt.component';

export const PPCLIATT_DEFAULT_FORM: FormDescription<Ppcliatt> = {
    form: PpcliattComponent, state: {
        ...buildDefaultState(Ppcliatt), diagTitle: 'contact', isNew: true, destroyOnSave: true, editMode: 'edit'
    }
};

export const PPCLIATT_BY_ID: FormDescription<Ppcliatt> = {
    form: PpcliattComponent, state: {
        ...buildDefaultState(Ppcliatt), diagTitle: 'contact', destroyOnSave: true, editMode: 'edit'
    }
};
