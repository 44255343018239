<form [formGroup]="formGroup"
      class="row">
    <div class="row space-form col-xs-10">
        <iw-rest-grid #saepGrid
                      (rowDoubleClick)="onPaiementDoubleClick($event)"
                      (selected)="getSelectedRow($event)"
                      [autoFormClick]="false"
                      [customColumns]="columns"
                      [defaultSelected]="true"
                      [queryStatements]="query"
                      [type]="type"
                      class="col-xs-12"
                      style="height: 200px"></iw-rest-grid>

        <iw-textfield #txtText
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="selectedRow?.mpaId"
                      class="iw-input col-xs-2 padding-top-15"></iw-textfield>

        <iw-textfield #txtText
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="selectedRow?.datePmnt"
                      class="iw-input col-xs-2 padding-top-15"></iw-textfield>
        <iw-textfield #txtText
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="selectedRow?.montant"
                      class="iw-input col-xs-2 padding-top-15 text-align-right"
                      type="number"></iw-textfield>
        <iw-textfield #txtText
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="selectedRow?.banqueClr"
                      class="iw-input col-xs-2 padding-top-15"></iw-textfield>
        <iw-textfield #txtText
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="selectedRow?.banqueCpt"
                      class="iw-input col-xs-2 padding-top-15"></iw-textfield>
        <iw-textfield #txtText
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="selectedRow?.dtaId"
                      class="iw-input col-xs-2 padding-top-15"></iw-textfield>

        <iw-textfield #txtText
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="selectedRow?.iban"
                      class="iw-input col-xs-9"></iw-textfield>
        <iw-textfield #txtText
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="selectedRow?.noCheque"
                      class="iw-input col-xs-3"></iw-textfield>

        <iw-textfield #txtText
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="selectedRow?.banque"
                      class="iw-input col-xs-9"></iw-textfield>
        <iw-textfield #txtText
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="selectedRow?.banqueSwi"
                      class="iw-input col-xs-3"></iw-textfield>

        <iw-textfield #txtText
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="selectedRow?.texte"
                      class="iw-input col-xs-12"></iw-textfield>

        <iw-textfield #txtText
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="selectedRow?.encaisse"
                      class="iw-input col-xs-12"></iw-textfield>
    </div>

    <div class="col-xs-2 flex flex-v separator-left">
        <iw-button #btnNew
                   (press)="createNewEntry()"
                   [isDisabled]="isReadonly"
                   [text]="'sidemenu_new' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button #btnModif
                   (press)="modifyEntry()"
                   [isDisabled]="!selectedRow || isReadonly || !canEditSaep"
                   [text]="'sidemenu_edit' | translate"
                   class="iw-button btn-block padding-bottom-10"></iw-button>

        <iw-button (press)="removeEntry()"
                   [isDisabled]="!selectedRow || isReadonly || !canDelete"
                   [text]="'Supprimer'"
                   class="iw-button btn-block padding-bottom-10"></iw-button>
    </div>
</form>
