<div class="flex flex-v flex-evenly">
    <iw-textfield #txtLdedSd4
                  [isDisabled]="true"
                  [noLabel]="true"
                  [value]="ldedValue"
                  class="
      iw-textfield
      col-xs-12
      padding-top-20 padding-bottom-5
      no-padding-left no-padding-right
    "></iw-textfield>

    <div class="row margin-top-10 flex flex-h flex-between">
        <iw-button #Standardbutton2
                   (press)="openSendSMSForm()"
                   [tooltip]="'envoyerSms' | translate"
                   *iwAccessGroups="SALAIRE_COMMUNICATION_ROLES"
                   class="iw-button no-padding"
                   iconClass="fas fa-comment-dots"
                   id="sms_button"></iw-button>
        <iw-button #btnSendMail
                   (press)="openEmailForm()"
                   *iwAccessGroups="SALAIRE_COMMUNICATION_ROLES"
                   class="iw-button no-padding"
                   iconClass="fas fa-envelope"
                   id="mail_button"></iw-button>
    </div>
    <div class="row margin-top-10 flex flex-h flex-gap">
        <iw-button #entreesSortiesButton
                   (press)="openEntriesAndExitsForm()"
                   [isDisabled]="disabled"
                   *iwAccessGroups="SALAIRE_RW_ROLES"
                   [text]="'entrees_sorties' | translate"
                   class="btn-block padding-top-15"></iw-button>

        <iw-button #recalculateButton
                   (press)="printDossier()"
                   [isDisabled]="false"
                   *iwAccessGroups="SALAIRE_PRINT_ROLES"
                   [text]="'dossier' | translate"
                   class="btn-block padding-top-15"></iw-button>
    </div>
</div>
