<form [formGroup]="formGroup" class="row">
    <div class="row space-form col-md-12">
        <div class="row col-xs-12" style="align-items: end;">
            <div class="row col-xs-12" style="align-items: end;">

                <iw-textfield #txtH_par_s [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'h_sem' | translate"
                              class="iw-input col-xs-4" decimals="2" formControlName="hparsem"
                              type="number"></iw-textfield>
                <iw-textfield #txtlec_par_s [isDisabled]="isReadonly" [labelAlign]="'top'"
                              [label]="'leçonsSem' | translate" class="iw-input col-xs-4" decimals="2"
                              formControlName="lecparsem" type="number"></iw-textfield>
            </div>


            <div class="row col-xs-12 padding-top-10" style="align-items: end;">
                <iw-button (press)="save()" [text]="'enregistrer' | translate"
                           class="iw-button padding-top-15 margin-right-5"></iw-button>
                <iw-button (press)="destroyForm()" [text]="'Annuler' | translate" class="iw-button"></iw-button>
            </div>
        </div>
    </div>
</form>
