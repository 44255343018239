<form [formGroup]="formGroup" class="row col-xs-12">
    <div class="row col-xs-12 col-no-padding margin-bottom-0">
        <iw-date-picker #txtSemaine (selectEvent)="setCurrentMonday($event)" [bckClrCalendar]="bckClrCalendar"
                        [bckColor]="bckClrSemaine" [labelAlign]="'left'" [label]="'semaine' | translate"
                        [showWeekDay]="false" [showWeekNumber]="true" class="iw-input col-xs-4 no-padding-left"
                        formControlName="datelundi"></iw-date-picker>

        <iw-button #btnPrevWeek (press)="prevWeek()" [iconClass]="'fas fa-angle-left'"
                   [tooltip]="'semaine_prev' | translate" class="iw-button col-no-padding"></iw-button>

        <iw-button #btnNextWeek (press)="nextWeek()" [iconClass]="'fas fa-angle-right'"
                   [tooltip]="'semaine_next' | translate" class="iw-button col-no-padding"></iw-button>

        <iw-button #btnToday (press)="setCurrentMonday()" [text]="'aujourdhui' | translate"
                   [tooltip]="'semaine_current' | translate" class="iw-button col-xs-2 margin-right-30"></iw-button>

        <iw-textfield #txtSessionSaisie [labelAlign]="'left'" [label]="'session' | translate" [size]="'small'"
                      class="iw-input col-xs-3 col-no-padding" formControlName="session"></iw-textfield>

        <iw-periode-dropdown #cboSal_id (valueChange)="setPeriodeValueAndBackgroundColor()"
                             class="iw-input col-xs-2 col-no-padding" formControlName="salId"
                             style="margin-top: -24px; margin-left:51px;"></iw-periode-dropdown>
    </div>

    <div class="row col-xs-12 col-no-padding">
        <iw-textfield #txtPeriode [isDisabled]="true" [ngClass]="backgroundPerColor" [value]="periodeValue"
                      class="iw-input col-xs-2 col-xs-offset-10 col-no-padding"></iw-textfield>
    </div>
</form>