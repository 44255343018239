<div class="row">
    <iw-button (press)="addToClipboard(xmlContent)" [tooltip]="'copierDans' | translate" class="iw-button col-xs-1"
               iconClass="fas fa-copy"></iw-button>
</div>
<div *ngIf="xmlContent" class="row spaceForm xml-wrapper">
    <div class="form-host row col-xs-12">
        <div class="xml-wrapper-simple">
            <pre>{{ xmlContent }}</pre>
        </div>
    </div>
</div>
