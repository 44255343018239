import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ModalMessageComponent} from '@sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@sam-base/components/modal/modal-message/modal-message.options';

import {ConfigService, IwEventHubService, ModalService} from '@sam-base/core';
import * as AdminRoles from '@sam-base/core/auth/access-rules/administration-menu';
import {ToastService} from '@sam-base/core/toast';
import {MenuEntry, MenuItem} from '@sam-base/models';
import {ProfileService} from '@shared/profile/profile.service';
import {KeycloakService} from 'keycloak-angular';
import {lastValueFrom, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AgencyHandlerService} from '../../../core/auth/services/agency-handler.service';

@Component({
    selector: 'iw-layout-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() public version?: string;
    public visibleEntries: MenuEntry[] = [];
    public username = '';
    public gestion = '';
    public authUrl = '';
    private _subs = new Subject();

    constructor(private readonly _age: AgencyHandlerService, private readonly _keycloak: KeycloakService,
                private readonly _events: IwEventHubService<string>, private readonly _toastService: ToastService,
                private readonly _modalService: ModalService, private _translate: TranslateService,
                private readonly configService: ConfigService, public _profileService: ProfileService,
                private readonly _eventHub: IwEventHubService<string>) {
    }

    public get hasIjAccess(): boolean {
        return this._profileService.checkUserPermission(AdminRoles.saisirRapportheures())
    }

    @Input()
    public set menuEntries(entries: MenuEntry[]) {
        this.visibleEntries = this.filterVisibleEntries(entries);
    }

    public ngOnInit() {
        this.username = this._keycloak.getUsername()
            .toUpperCase();
        this._age.loadAgencyId()
            .subscribe(s => this.gestion = s);
        this._events.forType('request_done')
            .pipe(takeUntil(this._subs))
            .subscribe((e) => {
                if (this.gestion && e.payload !== this.gestion) {
                    const options: ModalMessageOptions = {
                        message: [this._translate.instant('not_right_gestion')],
                        showCancel: false,
                        title: this._translate.instant('not_right_gestion_title'),
                        alertsMessage: '',
                        okDisabled: true
                    };

                    this._modalService.showModal(ModalMessageComponent, options);
                }
            });

        this.configService.getConfig()
            .subscribe(config => {
                this.authUrl = config.authUrl;
            });
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }

    public goToAuthURL() {
        if (!this.authUrl) {
            return;
        }
        window.open(this.authUrl + '/realms/SAM/account/totp', '_blank');
    }

    private filterVisibleEntries(entries: MenuEntry[]): MenuEntry[] {
        return entries.filter((entry: MenuEntry) => {
            if (!entry.accessRoles) {
                return true;
            }

            entry.items = entry.items?.map(items => {
                this.addComandToMenuItems(items || []);
                return this.filterVisibleItems(items || []);
            });
            // filter out sub menues that are empty to prevent having empty columns in mega menu breaking the UI
            entry.items = entry.items?.filter(items => items.some(item => item.visible)).filter(items => items.length > 0);
            return this._profileService.checkUserPermission(entry.accessRoles);
        });
    }

    private filterVisibleItems(entries: MenuItem[]): MenuItem[] {
        return entries.filter((entry: MenuItem) => {
            if (!entry.accessRoles) {
                return true;
            }
            if (this._profileService.checkUserPermission(entry.accessRoles)) {
                entry.visible = true;
                if (entry.items) {
                    entry.items = this.filterVisibleItems(entry.items);  // recursive call
                }
                return true;
            }
            entry.visible = false;
            return true;
        });
    }

    private async addComandToMenuItems(items: MenuItem[]): Promise<MenuItem[]> {
        const list: MenuItem[] = [];

        for (const m of items) {
            if (m.items) {
                m.items = await this.addComandToMenuItems(m.items);
            }
            if (m.label) {
                m.label = await lastValueFrom(this.getTranslation(m.label));
            }
            if (m.event) {
                const event = m.event;
                m.command = () => this.emitEvent(event);
            }

            list.push(m);
        }

        return list;
    }

    private getTranslation(key: string) {
        return this._translate.get(key);
    }

    private emitEvent(event: string) {
        this._eventHub.emit(event);
    }


}
