<form [formGroup]="formGroup" class="row">
    <iw-textarea #txtnotes [height]="120" [isDisabled]="isReadonly" [label]="'notesBulletinEmp' | translate"
                 class="iw-textarea col-xs-12 padding-top-15" formControlName="notesbs"></iw-textarea>

    <iw-textarea #txtnotes [height]="120" [isDisabled]="isReadonly" [label]="'notesInternesEmp' | translate"
                 class="iw-textarea col-xs-12 padding-top-15" formControlName="notesint"></iw-textarea>

    <iw-textarea #txtnotes [height]="120" [isDisabled]="true" [label]="'notesInternesPer' | translate"
                 [value]="notesPer" class="iw-textarea col-xs-12 padding-top-15"></iw-textarea>
</form>
