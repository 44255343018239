import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppind} from '@app/sam-base/models/placement';

import {PpindsaidComponent} from '../components/ppindsaid/ppindsaid.component';

export const PPINDSAID_CREATE_FORM: FormDescription<Ppind> = {
    form: PpindsaidComponent, state: {
        ...buildDefaultState(Ppind),
        diagModal: true,
        diagTitle: 'ppindsaid_form_title',
        editMode: 'edit',
        diagShowSideMenu: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showDelete: true
        },
        patch: false,
        isNew: true,
        width: 850
    }
};

export const PPINDSAID_UPDATE_FORM: FormDescription<Ppind> = {
    form: PpindsaidComponent, state: {
        ...buildDefaultState(Ppind),
        diagModal: true,
        diagTitle: 'ppindsaid_form_title',
        editMode: 'edit',
        destroyOnSave: true,
        patch: false,
        width: 850
    }
};
