import {Component, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base/base-store-form.component';
import {IwStoreService} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {GeclisuiService} from '@app/sam-base/core/services/geclisui.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Gecli} from '@app/sam-base/models/invoices';
import {Geclisui} from '@app/sam-base/models/invoices/geclisui';

@Component({templateUrl: './geclisuivi.component.html'})
export class GeclisuiviComponent extends BaseStoreFormComponent<Geclisui> implements OnInit {


    public date: Date = new Date();
    public userId ? = '';
    public geclisuiDateAction = '';
    public geclisuiAction ? = '';
    public notes ? = '';
    public rappelFlag ? = false;
    public dateRappel ? = new Date();
    public rappelDone ? = false;
    public isRappelerDisabled = true;
    private readonly PPCLIACTION = 'PPCLIACTION';
    public action = this.PPCLIACTION;

    constructor(private readonly _geclisuiService: GeclisuiService, store: IwStoreService) {
        super(store);
    }

    public get gecli(): Gecli {
        return this.getData('gecli', true) || new Gecli();
    }

    public get geclisui(): Geclisui {
        return this.getData('geclisui', true) || new Geclisui();
    }

    public get parentUUID(): string {
        return this.getData('parentUUID', true) || '';
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public ngOnInit(): void {
        this.geclisuiAction = this.geclisui.action;
        this.notes = this.geclisui.notes;
        this.rappelFlag = this.geclisui.rappelFlag;
        this.dateRappel = this.geclisui.dateRappel;
        this.rappelDone = this.geclisui.rappelDone;
        this.geclisuiDateAction = IwDateHelper.getDateNow()
            .toISOString();
        this.setUserId();
        this.transformDate();
        this.setCliId();
        this.setClisuiId();
    }

    public save() {
        this._geclisuiService.save(this.getFormData())
            .subscribe(() => {
                this._geclisuiService.geclisuiSub.next({
                    uuid: this.parentUUID, saved: true
                });
            });
        this.closeDialog();
    }

    public enableRappeler(event: any) {
        if (event === true) {
            this.isRappelerDisabled = false;
        } else {
            this.isRappelerDisabled = true;
        }
    }

    protected getFormControlNames(): FormKeys<Geclisui> {
        return ['action', 'dateAction', 'userId', 'notes', 'rappelFlag', 'dateRappel', 'rappelDone', 'cliId', 'cliSuiId'];
    }

    private setUserId() {
        if (this.geclisui.userId === undefined) {
            this.userId = this.gecli.userId;
        } else {
            this.userId = this.geclisui.userId;
        }
    }

    private transformDate() {
        if (this.geclisui.dateAction) {
            const dateActionTransformerd = this.geclisui.dateAction.toString();
            this.geclisuiDateAction = IwDateHelper.dateIsoString(IwDateHelper
                .dateIsoString(dateActionTransformerd.substring(0, 10)));
        }
    }

    private setCliId() {
        if (this.geclisui.cliId === undefined) {
            this.setFormValue('cliId', this.gecli.cliId);
        } else {
            this.setFormValue('cliId', this.geclisui.cliId);
        }
    }

    private setClisuiId() {
        if (this.geclisui.cliSuiId !== '') {
            this.setFormValue('cliSuiId', this.geclisui.cliSuiId);
        }
    }

}
