import {Component, OnInit} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {GlcentreService} from '@app/sam-base/core/services/glcentre.service';
import {SarepService} from '@app/sam-base/core/services/sarep.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ComboboxItem, IwGridColumn} from '@app/sam-base/models';
import {Gecliview} from '@app/sam-base/models/invoices/gecliview';
import {Sarep} from '@app/sam-base/models/salary/sarep';
import {SarepCentre} from '@app/sam-base/models/salary/sarepCentre';
import {Glcentre} from '@sam-base/models/accounting/glcentre';

@Component({templateUrl: './repartition.component.html'})
export class RepartitionComponent extends BaseFormComponent<Sarep> implements OnInit {
    public readonly = true;
    public isSaving = false;
    public canModify = false;
    public newEntryMode = false;
    public xeDateCreate?: string;
    public xeDateMofif?: string;
    public xeCentre?: string;
    public xeValeur?: number;
    public optionCentre: ComboboxItem<string>[] = [];
    public selectedElement?: SarepCentre[] = [];
    public tableData: SarepCentre[] = [];
    public tableColumns: IwGridColumn<SarepCentre>[] = [
        {
            prop: 'centre',
            name: 'code',
            index: 0
        },
        {
            prop: 'titre',
            name: 'titre',
            index: 1
        },
        {
            prop: 'valeur',
            name: 'valeur',
            index: 2,
            decimals: 2
        }];
    public clientType = Gecliview;
    private readonly _restGlcentreClient: RestEntityClient<Sarep>;

    constructor(private _store: IwStoreService, private _sarepService: SarepService,
                private _glcentreService: GlcentreService, private _restService: RestApiService) {
        super();
        this._restGlcentreClient = this._restService.getEntityClient(Glcentre);
    }

    public get key(): string {
        return this.getData('key') || '';
    }

    public get ifactsal(): string {
        return this.getData('ifactsal', true) || '';
    }

    public get ifactsalfu(): string {
        return this.getData('ifactsalfu', true) || '';
    }

    public get pnTotal(): number {
        return this.getData('pnTotal') || 0;
    }

    public get pcentype(): string {
        return this.getData('centype') || '1';
    }

    public get totalmax(): number {
        if (this.tableData.length && this.tableData.length < 2) {
            return 100;
        }
        return this.pnTotal;
    }

    public get centype(): string {
        if (this.tableData.length && this.tableData.length < 3) {
            return '1';
        }
        return this.pcentype;
    }

    public get centotal(): number {
        if (this.tableData.length) {
            return this.tableData.reduce((sum, sarepCentre) => sum + (sarepCentre.valeur || 0), 0);
        }
        return 0;
    }

    public ngOnInit() {
        if (this.key) {
            this._sarepService.getSarepData(this.key)
                .subscribe((res: Sarep[]) => {
                    if (Object.keys(res).length !== 0) {
                        this.xeDateCreate = res[0].dateCreat?.substring(0, 10);
                        this.xeDateMofif = res[0].dateModif?.substring(0, 10);
                    }
                });
            if (this.pcentype === '1' || this.pcentype === '2') {
                this.getCentreOptions();
            }
            this.loadSarepCentreTable();
        }
    }

    public getCentreOptions() {
        return this._glcentreService
            .getGlcentreData(this.pcentype)
            .subscribe((res: Glcentre[]) => {
                res?.forEach(glcentre => {
                    this.optionCentre.push({
                        name: glcentre.titre + ' | ' + glcentre.centre,
                        value: glcentre.centre ?? ''
                    });
                });
            });
    }

    public getAllGlcentreOptions() {
        this._restGlcentreClient.getRefData()
            .subscribe({
                next: (data: Glcentre[]) => {
                    data = data.sort((a, b) => ((b.centre ?? '') < (a.centre ?? '')) ? 1 : -1);
                    data.forEach(glcentre => this.optionCentre.push({
                        name: glcentre.titre + ' | ' + glcentre.centre,
                        value: glcentre.centre ?? ''
                    }));
                }
            });
    }

    public createNewEntry() {
        this.readonly = false;
        this.newEntryMode = true;
        this.clearFormData();
        this.getCentreOptions();
    }


    public modifyEntry(): void {
        this.readonly = false;
        this.newEntryMode = false;
    }

    public saveEntry() {
        const sarep: Sarep = {
            key: this.key,
            centre: this.xeCentre,
            centype: this.pcentype,
            valeur: Number(this.xeValeur)
        };
        this._sarepService.saveSarep(sarep)
            .subscribe(() => {
                this.readonly = true;
                this.loadSarepCentreTable();
            });
    }

    public cancelEditionMode() {
        this.readonly = true;
        this.newEntryMode = false;
    }

    public removeEntry() {
        if (this.selectedElement?.length) {
            const sarep = this.selectedElement[0];
            this._sarepService.deleteByKey(sarep.key, sarep.centre, sarep.centype)
                .subscribe({
                    next: () => this.loadSarepCentreTable()
                });
        }
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public selectedChanged(sarepCentre: SarepCentre[]) {
        if (sarepCentre.length) {
            this.xeCentre = sarepCentre[0].centre;
            this.xeValeur = sarepCentre[0].valeur;
        } else {
            this.xeCentre = undefined;
            this.xeValeur = 0;
        }
    }

    protected validateFields(e: Sarep): boolean {
        return super.validateFields(e);
    }

    private loadSarepCentreTable() {
        this.selectedElement = [];
        this._sarepService.getSarepCentreInfoByCentype(this.key, this.pcentype)
            .subscribe({
                next: (data: SarepCentre[]) => this.tableData = data
            });
    }

    private clearFormData() {
        this.xeCentre = undefined;
        this.xeValeur = 0;
        this.optionCentre = [];
    }
}
