<div class="flex flex-v flex-evenly">
    <div class="row margin-top-10 flex flex-h flex-between">
        <iw-button #Standardbutton2
                   (press)="openSendSMSForm()"
                   [tooltip]="'envoyerSms' | translate"
                   *iwAccessGroups="FACTURE_COMMUNICATION_ROLES"
                   class="iw-button no-padding"
                   iconClass="fas fa-comment-dots"></iw-button>
        <iw-button #btnSendMail
                   (press)="openEmailForm()"
                   *iwAccessGroups="FACTURE_COMMUNICATION_ROLES"
                   class="iw-button no-padding"
                   iconClass="fas fa-envelope"></iw-button>
    </div>
    <div class="row margin-top-20">
        <iw-button #btnOpenClose
                   (press)="changeCliStatus()"
                   *iwAccessGroups="FACTURE_RW_ROLES"
                   [text]="'oF' | translate"
                   [tooltip]="'open_close_c' | translate"
                   class="btn-block"></iw-button>
    </div>
</div>
