<iw-dialog #dialog
           [bodyPadding]="'10'"
           [cls]="'iw-modal'"
           [height]="height"
           [modal]="true"
           [resizable]="false"
           [title]="title | translate"
           [visible]="display"
           [width]="width">
    <div class="container">
        <div class="row">
            <iw-table #grdSessions
                      (contextMenuClick)="onContextMenu($event)"
                      (selectedChange)="selectCountOption($event)"
                      [columns]="columns"
                      [data]="sessions"
                      [isFilterEnable]="true"
                      [isGridColumnMenuEnable]="false"
                      [isSortIconEnable]="false"
                      [selectionMode]="'single'"
                      class="col-xs-12 no-padding"
                      style="height: 15vh;"></iw-table>
            <iw-context-menu #menu
                             [context]="contextMenuData"
                             [items]="contextMenuItems"
                             [popup]="true"></iw-context-menu>
        </div>
        <div class="row between-xs">
            <div class="col-xs-2">
                <iw-button (press)="onPressCancel()"
                           [ngClass]="okColor"
                           [text]='"Cancel"'
                           id="cancel-create"></iw-button>
            </div>
            <div class="col-xs-2">
                <iw-button (press)="onPressOk()"
                           [isDisabled]="!atLeastOneSelected"
                           [ngClass]="okColor"
                           [text]="'ouvrir' | translate"></iw-button>
            </div>
        </div>

    </div>
</iw-dialog>
