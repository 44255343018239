import {Component, Input, OnInit} from '@angular/core';

import {Validators} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormHandlerService, IwStoreService} from '@app/sam-base/core';
import {SapardedService} from '@app/sam-base/core/services/saparded.service';
import {ComboboxItem} from '@app/sam-base/models';
import {Saparded, Sapardedview} from '@app/sam-base/models/salary';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {SalaryForm} from '../../../salary.forms';

@Component({
    selector: 'iw-tab-saparded-parameters',
    templateUrl: './tab-saparded-parameters.component.html'
})
export class TabSapardedParametersComponent extends BasePartialFormComponent<Saparded> implements OnInit {
    @Input() public isNew = false;

    public sapardedview?: Sapardedview;
    public dedBasaut?: boolean;
    public gsBasaut?: boolean;
    public notShow?: boolean;
    public gsBas?: boolean;
    public minMaxDisabled = false;
    public modeTauxDisabled = false;
    public active?: boolean;
    public salary?: string;

    public listDedCode: string[] = [
        'CAF',
        'MAT',
        '13E',
        'AC',
        'AC1',
        'AC2',
        'LPC',
        'LPP',
        'LAA',
        'IJM'];

    public assuranceOptions: ComboboxItem<string>[] = [];
    public baseModeOptions: ComboboxItem<number>[] = [
        {
            name: this._translate.instant('coefficients'),
            value: 1
        },
        {
            name: this._translate.instant('autreDeduction'),
            value: 2
        },
        {
            name: this._translate.instant('autreGS'),
            value: 3
        }];
    public minMaxOptions: ComboboxItem<number>[] = [
        {
            name: this._translate.instant('aucun'),
            value: 1
        },
        {
            name: this._translate.instant('deductions'),
            value: 2
        },
        {
            name: this._translate.instant('tableTaux'),
            value: 3
        },
        {
            name: this._translate.instant('lpp_gs'),
            value: 4
        }];
    public modeTauxOptions: ComboboxItem<number>[] = [
        {
            name: this._translate.instant('deduction'),
            value: 1
        },
        {
            name: this._translate.instant('table'),
            value: 2
        },
        {
            name: this._translate.instant('tableSex'),
            value: 3
        }];
    public modeAutoOptions: ComboboxItem<number>[] = [
        {
            name: this._translate.instant('aucun'),
            value: 1
        },
        {
            name: this._translate.instant('sexeAge'),
            value: 2
        },
        {
            name: this._translate.instant('sexeAgeRevolu'),
            value: 3
        },
        {
            name: this._translate.instant('cantonTravail'),
            value: 4
        },
        {
            name: this._translate.instant('cantonHabitation'),
            value: 5
        },
        {
            name: this._translate.instant('cantonIS'),
            value: 6
        },
        {
            name: this._translate.instant('cat1'),
            value: 7
        },
        {
            name: this._translate.instant('cat2'),
            value: 8
        },
        {
            name: this._translate.instant('cat3'),
            value: 9
        },
        {
            name: this._translate.instant('avs'),
            value: 80
        },
        {
            name: this._translate.instant('ac'),
            value: 82
        }];

    constructor(private store: IwStoreService, private _translate: TranslateService,
                private _parametersService: ParametersService,
                private readonly _formHandler: FormHandlerService<SalaryForm>,
                public readonly sapardedService: SapardedService) {
        super();
    }

    public get dedCode() {
        return this.getFormValue<string>('dedCode');
    }

    public get annee() {
        return this.getFormValue<string>('annee');
    }

    public get assCode() {
        return this.getFormValue<string>('assCode');
    }

    public get tauxmode() {
        return this.getFormValue<string>('tauxmode');
    }

    public get enableCalBut(): boolean {
        return ((this.getFormValue('tauxauto') as number) > 1 && (this.getFormValue('tauxauto') as number) < 80 && this.isReadonly);
    }

    public ngOnInit() {
        this.setValues();
        if (this.isNew) {
            this._parametersService.getCurrentSapar()
                .subscribe((sapar) => {
                    this.setFormValue('annee', sapar.annee);
                    this.setFormValue('assCode', '');
                });

        }
        this.setAssuranceOptions();
    }

    public setValues() {
        this.dedBasaut = true;
        this.notShow = true;
        this.gsBas = false;
        this.gsBasaut = true;
        this.minMaxDisabled = true;
        this.modeTauxDisabled = false;
        this.subscribeValueChange('dedActive', () => {
            this.active = this.getFormValue('dedActive') === 0 ? false : true;
        });
    }

    public changeActive(event: boolean) {
        if (event) {
            this.setFormValue('dedActive', 1);
        } else {
            this.setFormValue('dedActive', 0);
        }
    }

    public enableTauxBut(): boolean {
        return (this.getFormValue<number>('tauxmode') ?? 0) > 1 && !this.isReadonly;
    }

    public setBaseMode(event: number) {
        if (event === this.baseModeOptions[0].value) {
            this.notShow = true;
            this.dedBasaut = true;
            this.gsBasaut = true;
            this.gsBas = false;
        }
        if (event === this.baseModeOptions[1].value) {
            this.notShow = false;
            this.dedBasaut = true;
            this.gsBasaut = true;
            this.gsBas = true;
        }
        if (event === this.baseModeOptions[2].value) {
            this.notShow = true;
            this.dedBasaut = true;
            this.gsBasaut = false;
            this.gsBas = true;
        }
    }

    public setminMax(event: number) {
        if (event === this.minMaxOptions[0].value || event === this.minMaxOptions[2].value) {
            this.minMaxDisabled = true;
            this.getFormControl('salmin')
                ?.clearValidators();
            this.getFormControl('salmax')
                ?.clearValidators();
        }
        if (event === this.minMaxOptions[1].value || event === this.minMaxOptions[3].value) {
            this.minMaxDisabled = false;
            this.getFormControl('salmin')
                ?.setValidators(Validators.required);
            this.getFormControl('salmax')
                ?.setValidators(Validators.required);
        }
    }

    public showAssurance(): boolean {
        return !this.listDedCode.includes(this.dedCode ?? '');
    }

    public setmodeTaux(event: number) {
        if (event === this.modeTauxOptions[1].value || event === this.modeTauxOptions[2].value) {
            this.modeTauxDisabled = true;
            this.getFormControl('txcot')
                ?.clearValidators();
            this.getFormControl('txcoe')
                ?.clearValidators();

        }
        if (event === this.modeTauxOptions[0].value) {
            this.modeTauxDisabled = false;
            this.getFormControl('txcot')
                ?.setValidators(Validators.required);
            this.getFormControl('txcoe')
                ?.setValidators(Validators.required);
        }
    }

    public openTauxForm() {
        if (!this.dedCode) {
            return;
        }
        this._formHandler.showFormDialog(SalaryForm.SadedTaux, {
            dedCode: this.dedCode,
            annee: this.annee,
            assCode: this.assCode,
            tauxmode: this.tauxmode,
            assurance: this.getFormValue<string>('assurance')
        });
    }

    public openCalculForm() {
        if (!this.dedCode) {
            return;
        }
        this._formHandler.showFormDialog(SalaryForm.Sadedda, {
            dedCode: this.dedCode,
            annee: this.annee,
            tauxauto: this.getFormValue<string>('tauxauto')
        });
    }

    protected getValidationType() {
        return Saparded;
    }

    private setAssuranceOptions() {
        this.fillAssuranceOptionsForDedCode(this.dedCode);
        this.subscribeValueChange('dedCode', (ded) => {
            if (!this.listDedCode.includes(<string>ded)) {
                this.formGroup?.controls['assurance'].setValidators(Validators.required);
            } else {
                this.formGroup?.controls['assurance'].clearValidators();
            }
            this.fillAssuranceOptionsForDedCode(this.dedCode);
        });
    }

    private fillAssuranceOptionsForDedCode(dedCode: string | undefined) {
        this.assuranceOptions.length = 0;
        if (!dedCode) return;
        this.sapardedService.getAssuranceByAnnee(dedCode)
            .subscribe((data: string[]) => {
                data.forEach((e) => {
                    this.assuranceOptions.push({
                        name: e,
                        value: e
                    });
                });
            });
    }
}
