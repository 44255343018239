import {Component, Input, OnInit, Type} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {RestEntityClient} from '@app/sam-base/core/rest-api/entity/rest-entity-client';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {ModalComponent} from '@app/sam-base/models';
import {Npa} from '@app/sam-base/models/common/npa';
import {Ppcde, Ppcliadr, Ppcliatt, Ppcon} from '@app/sam-base/models/placement';
import {ToastService} from '@sam-base/core/toast';
import {
    IwModalSelectTableComponent
} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.component';
import {lastValueFrom, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {
    ModalSelectTableOption
} from '../../../../../../shared/widgets/modal-components/modal-select-table/modal-select-table.model';

@Component({
    selector: 'iw-tab-ppcdetempo-general', templateUrl: './tab-ppcdetempo-general.component.html'
})
export class TabPpcdetempoGeneralComponent extends BasePartialFormComponent<Ppcde> implements OnInit {

    public get cliId() {
        return this.getFormValue<string>('cliId') || '';
    }

    public readonly listOption: ModalSelectTableOption<Ppcliadr, string>;

    @Input() public isReadonly = true;

    @Input() public entityId?: string;

    private readonly _npaRestClient: RestEntityClient<Npa>;

    constructor(private _restService: RestApiService, private _toastService: ToastService, private readonly _modalService: ModalService) {
        super();
        this._npaRestClient = _restService.getEntityClient(Npa);
        this.listOption = {
            columns: [{prop: 'adrlib'}],
            map: (e: Ppcliadr) => e.cliadrId || '',
            selection: 'single',
            fetchAction: () => this._restService
                .getEntityClient(Ppcliadr)
                .getRefData()
        };
    }

    public ngOnInit() {
        this.subscribeValueChange('misNpa', () => {
            this.getLieuByNpa();
        });

        this.subscribeValueChange('conId', () => {
            const conId = this.getFormValue<string>('conId');
            if (!conId) {
                return;
            }
            this._restService
                .getEntityClient(Ppcon)
                .getById(conId)
                .subscribe((ppcon: Ppcon) => this.setFormValue('ageId', ppcon.ageId));
        });
    }

    /**
     * Function to get lieu by npa
     */
    public async getLieuByNpa() {
        if (this.isReadonly) {
            return;
        }
        try {
            const npa: string = this.getFormValue<string>('misNpa') || '';
            if (this.canGenerateNpa(npa)) {
                const lieu: Npa = await lastValueFrom(this._npaRestClient.getById(npa));
                this.setFormValue('misLieu', lieu.lieu);
            }
        } catch (error) {
            this._toastService.error('npa_error');
        }
    }

    /**
     * Function to get address by client
     */
    public async copyAddressByClient() {
        const data = await lastValueFrom(this.getppAdrCli());
        const comp: Type<ModalComponent<string[], Ppcliadr>> = <any>IwModalSelectTableComponent;
        if (data.length > 1) {
            this.listOption.fetchAction = () => of(data);
            const res = await this._modalService
                .showModal<string[], Ppcliadr>(comp, <any>this.listOption);
            this._restService.getEntityClient(Ppcliadr)
                .getById(res[0])
                .subscribe((ppcliadr: Ppcliadr) => {
                    this.setFormValue('lieu1', ppcliadr.nom);
                    this.setFormValue('lieu2', ppcliadr.adresse1);
                    this.setFormValue('misLieu', ppcliadr.lieu);
                    this.setFormValue('misNpa', ppcliadr.npa);
                });
        } else if (data.length === 1) {
            this.setFormValue('lieu1', data[0].nom);
            this.setFormValue('lieu2', data[0].adresse1);
            this.setFormValue('misLieu', data[0].lieu);
            this.setFormValue('misNpa', data[0].npa);
        }
    }

    public setAtt(cliatt: Ppcliatt) {
        this.setFormValue('cliattId', cliatt.cliattId);
        if (cliatt.tel) {
            this.setFormValue('tel', cliatt.tel);
        }
        if (cliatt.fax) {
            this.setFormValue('fax', cliatt.fax);
        }
        if (cliatt.natel) {
            this.setFormValue('natel', cliatt.natel);
        }
        if (cliatt.email) {
            this.setFormValue('email', cliatt.email);
        }
    }

    private canGenerateNpa(npa: string) {
        return (npa.length === 4 && !this.getFormValue('misLieu') && !this.isReadonly);
    }

    private getppAdrCli = () => this._restService
        .getEntityClient(Ppcliadr)
        .getRefData()
        .pipe(map(e => e.filter(v => v.cliId === this.cliId)));

}
