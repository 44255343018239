<iw-table-edit [entity]="entity" [formId]="uuid" [height]="'150px'" [query]="query"
               [saveValid]="isValid"></iw-table-edit>
<div class="row">
    <div class="col-xs-8">
        <form [formGroup]="formGroup" class="row">
            <iw-textfield #txtRefBVR [isDisabled]="true" [label]="'ctaEmpId' | translate"
                          class="iw-input col-xs-12 padding-top-10" formControlName="empId"></iw-textfield>
            <iw-sags-dropdown #dropSags [isDisabled]="!isWriteMode" [labelAlign]="'top'"
                              [label]="'genreSalaire' | translate" [types]="['3']" class="iw-input col-xs-12"
                              formControlName="gsId"></iw-sags-dropdown>
            <iw-textfield-fofou #txtResp (fofouChanged)="setFou($event)" [isDisabled]="!isWriteMode"
                                [labelAlign]="'top'" [label]="'creancier' | translate" class="iw-input col-xs-4"
                                formControlName="fouId"></iw-textfield-fofou>
            <iw-textfield #txtRefBVR [isDisabled]="true" [value]="fouNom"
                          class="iw-input col-xs-8 padding-top-10 no-label"></iw-textfield>
            <iw-textfield-fofoudp #txtResp (fofoudpChanged)="setFoudp($event)" [fouId]="fouId"
                                  [isDisabled]="fofoudpIsDisabled()" [labelAlign]="'top'" [label]="'foudp' | translate"
                                  class="iw-input col-xs-4" formControlName="foudpId"></iw-textfield-fofoudp>
            <iw-textfield #txtRefBVR [isDisabled]="true" [value]="foudpNom"
                          class="iw-input col-xs-8 padding-top-10 no-label"></iw-textfield>
            <iw-textfield #txtRefBVR [isDisabled]="!isWriteMode" [label]="'refBVR' | translate"
                          class="iw-input col-xs-12 padding-top-10" formControlName="norefbvr"></iw-textfield>
            <iw-textfield #txtRedOP [isDisabled]="!isWriteMode" [label]="'refOP' | translate"
                          class="iw-input col-xs-10 padding-top-10" formControlName="refop"></iw-textfield>
            <iw-textfield-compte #txtCredit [isDisabled]="!isWriteMode" [labelAlign]="'top'"
                                 [label]="'compte' | translate" class="iw-input col-xs-2 padding-top-10"
                                 formControlName="compte"></iw-textfield-compte>
        </form>
    </div>
    <div class="col-xs-1"></div>
    <div class="col-xs-3 flex flex-v flex-end">
        <iw-button (press)="close()" [text]="'fermer' | translate" class="btn-block"></iw-button>
    </div>
</div>
