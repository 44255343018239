<form [formGroup]="formGroup" autocomplete="no" class="row spaceForm">

    <div class="form-host row col-xs-6 separator-right">
        <iw-textfield-lookups #txtPrefixe [isDisabled]="isReadonly" [labelAlign]="'left'"
                              [label]="'prefixe' | translate" [lookupName]="'PREFIXE'"
                              class="iw-input  col-xs-12 col-no-padding"
                              formControlName="prefixe"></iw-textfield-lookups>

        <iw-textfield #txtNom [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'nom' | translate"
                      [warningMessage]="'errorClientFieldSize' | translate" class="iw-input col-xs-12 col-no-padding"
                      formControlName="nom" id="textNom"></iw-textfield>

        <iw-textfield #txtAdresse1 [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'rueEtNumero' | translate"
                      class="iw-input col-xs-12 col-no-padding" formControlName="adresse1"></iw-textfield>

        <iw-textfield #txtAdresse2 [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'adresse' | translate"
                      class="iw-input col-xs-12 col-no-padding" formControlName="adresse2"></iw-textfield>

        <iw-textfield #txtAdresse3 [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="' '"
                      class="iw-input col-xs-12 col-no-padding" formControlName="adresse3"></iw-textfield>

        <iw-textfield #txtNpa (valueChange)="getLieuByNpa()" [isDisabled]="isReadonly" [labelAlign]="'left'"
                      [label]="'npaLieu' | translate" class="iw-input col-xs-5 col-no-padding"
                      formControlName="npa"></iw-textfield>

        <iw-textfield #txtLieu [isDisabled]="isReadonly" class="iw-input col-xs-7 col-no-padding"
                      formControlName="lieu"></iw-textfield>

        <iw-pays-dropdown #cboPays
                          id="cbo-pays" [isDisabled]="isReadonly" [labelAlign]="'left'"
                          [label]="'pays' | translate" class="iw-input col-xs-11 col-no-padding"
                          formControlName="pays"
                          [width]="'100%'"></iw-pays-dropdown>

        <iw-button #btnCopyAdr2Clipboard (press)="addToClipboard('adresse')" [tooltip]="'copierDans' | translate"
                   class="iw-button col-xs-1" iconClass="fas fa-copy"></iw-button>

        <iw-textfield #edtRegions [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'regions' | translate"
                      class="iw-input col-xs-10 col-no-padding" formControlName="regions"></iw-textfield>

        <iw-button #btnRegions (press)="openRegionsSelection()" [isDisabled]="isReadonly" [text]="'TEXT' | translate"
                   [width]="50" class="iw-button col-xs-1"></iw-button>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-phone-textfield #txtTel1 [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'telephone' | translate"
                            class="iw-input col-xs-12 col-no-padding" formControlName="tel1"></iw-phone-textfield>

        <div class="col-xs-1"></div>

        <iw-phone-textfield #txtNatel [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'natel' | translate"
                            class="iw-input col-xs-12 col-no-padding" formControlName="natel"></iw-phone-textfield>

        <iw-textfield #txtFax [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'fax' | translate"
                      class="iw-input col-xs-12 col-no-padding" formControlName="fax"></iw-textfield>

        <iw-email-textfield #txtEmail [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'eMail' | translate"
                            class="iw-input col-xs-10 col-no-padding" formControlName="email"
                            id="textEmail"></iw-email-textfield>

        <iw-button #btnCopy2ClipBoard (press)="addToClipboard('email')" [tooltip]="'copierDans' | translate"
                   class="iw-button col-xs-1 col-no-padding" iconClass="fas fa-copy"></iw-button>

        <iw-textfield #txtWeb [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'web' | translate"
                      class="iw-input col-xs-12 col-no-padding" formControlName="www"></iw-textfield>
    </div>

    <div class="row col-xs-6 space-form">
        <iw-agency-combo-box #cboAge_id [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'agence' | translate"
                             class="iw-input col-xs-6 padding-left-0" formControlName="ageId"></iw-agency-combo-box>
        <iw-dropdown #cboCliInType [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'mode' | translate"
                     class="iw-input col-xs-6 padding-left-0" formControlName="cliintype"></iw-dropdown>

        <iw-conseiller-dropdown #cboCon_id (selectedChanged)="agenceChange($event)" [isDisabled]="isReadonly"
                                [labelAlign]="'top'" [label]="'conseiller' | translate"
                                class="iw-input col-xs-8 col-no-padding" formControlName="conId"
                                id="dropCons"></iw-conseiller-dropdown>

        <iw-textfield #txtCli_index [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'classerSous' | translate"
                      class="iw-input col-xs-12 col-no-padding" formControlName="cliIndex"></iw-textfield>

        <iw-textfield #txtUsertag [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'tag' | translate"
                      class="iw-input col-xs-12 col-no-padding" formControlName="usertag"></iw-textfield>

        <iw-checkbox #chkkredchk [isDisabled]="isReadonly" [label]="'creditCheckEffectue' | translate"
                     class="iw-input col-xs-5 margin-top-5" formControlName="kredchk"></iw-checkbox>

        <iw-date-picker #txtKchkDate [isDisabled]="isReadonly" class="iw-input col-xs-3 margin-top-5"
                        formControlName="kchkdate"></iw-date-picker>

        <div class="col-xs-4"></div>
        <iw-enum-dropdown #cbokchkrating [inputEnum]="kchkrating" [isDisabled]="isReadonly"
                          class="iw-input col-xs-4 margin-top-5" formControlName="kchkrating"></iw-enum-dropdown>
    </div>
</form>
