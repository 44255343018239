<form [formGroup]="formGroup" class="row">
    <div class="col-xs-9 row space-row clean-row">
        <iw-textfield #txtNomComplet [isDisabled]="true" class="iw-input col-xs-12"
                      formControlName="nomcomplet"></iw-textfield>

        <iw-enum-dropdown #cboPolitesse (valueChange)="setNomComplet()" [inputEnum]="cpolitesse" [labelAlign]="'top'"
                          [label]="'politesse' | translate" class="iw-input col-xs-5"
                          formControlName="cpolitesse"></iw-enum-dropdown>
        <div class="col-xs-7"></div>

        <iw-textfield #txtNom (valueChange)="setNomComplet()" [labelAlign]="'top'" [label]="'nom' | translate"
                      class="iw-input col-xs-5" formControlName="nom"></iw-textfield>

        <iw-textfield #txtPrenom (valueChange)="setNomComplet()" [labelAlign]="'top'" [label]="'prenom' | translate"
                      class="iw-input col-xs-5" formControlName="prenom"></iw-textfield>

        <iw-textfield #txtFonction [labelAlign]="'top'" [label]="'titreFonction' | translate" class="iw-input col-xs-10"
                      formControlName="fonction"></iw-textfield>

        <iw-textfield #txtDept [labelAlign]="'top'" [label]="'departement' | translate" class="iw-input col-xs-10"
                      formControlName="dept"></iw-textfield>

        <iw-phone-textfield #txtTel [labelAlign]="'top'" [label]="'telephone' | translate" class="iw-input col-xs-7"
                            formControlName="tel"></iw-phone-textfield>

        <iw-textfield #txtFax [labelAlign]="'top'" [label]="'fax' | translate" class="iw-input col-xs-7"
                      formControlName="fax"></iw-textfield>

        <iw-phone-textfield #txtNatel [labelAlign]="'top'" [label]="'telephonePortable' | translate"
                            class="iw-input col-xs-7" formControlName="natel"></iw-phone-textfield>

        <iw-email-textfield #txtEmail [labelAlign]="'top'" [label]="'eMail' | translate" class="iw-input col-xs-10"
                            formControlName="email"></iw-email-textfield>

        <iw-date-picker #txtDateAnniv [labelAlign]="'top'" [label]="'anniversaire' | translate"
                        class="iw-input col-xs-4" formControlName="dateanniv"></iw-date-picker>

        <div class="col-xs-12 simple-row">
            <iw-checkbox #chkNomailing [label]="'pasDeMailing' | translate" class="iw-input col-xs-5 no-padding"
                         formControlName="nomailing"></iw-checkbox>

            <iw-checkbox #chkActif [label]="'actif' | translate" class="iw-input col-xs-3 no-padding"
                         formControlName="actif"></iw-checkbox>
        </div>
    </div>
    <div class="col-xs-3">
        <div class="v-container">
            <iw-button #btnOk (press)="saveChanges()" [isDisabled]="!isValid" [text]="'enregistrer' | translate"
                       class="iw-button btn-block"></iw-button>

            <iw-button #btnClose (press)="destroyForm()" [text]="'annuler' | translate" class="iw-button btn-block"
                       style="margin-top: 10px"></iw-button>
        </div>
    </div>

    <iw-textarea #edtRemarques [height]="120" [labelAlign]="'top'" [label]="'remarques' | translate"
                 class="iw-textarea col-xs-12" formControlName="remarques"></iw-textarea>

</form>
