<form [formGroup]="formGroup"
      class="row">

    <iw-date-picker #txtDate_cde
                    [isDisabled]="isReadonly"
                    [labelAlign]="'top'"
                    [label]="'dateCde' | translate"
                    class="iw-input col-xs-2"
                    formControlName="dateCdf">
        >
    </iw-date-picker>

    <iw-textfield #txtRefInterne
                  [isDisabled]="isReadonly"
                  [labelAlign]="'top'"
                  [label]="'notreRef' | translate"
                  class="iw-input col-xs-4"
                  formControlName="refinterne"></iw-textfield>

    <iw-entity-textfield #ctnSelCli
                         [allowSearch]="true"
                         [entity]="entityClient"
                         [isDisabled]="isReadonly"
                         [labelAlign]="'top'"
                         [label]="'referenceClient' | translate"
                         [name]="['cliId']"
                         [showIdField]="false"
                         class="iw-input col-xs-4"
                         formControlName="refclient"></iw-entity-textfield>

    <iw-textfield #txtNogestion
                  [isDisabled]="true"
                  [labelAlign]="'top'"
                  [label]="'noGestion' | translate"
                  class="iw-input col-xs-2"
                  formControlName="noGestion"></iw-textfield>

    <div class="row col-xs-12 separator-top"></div>

    <div class="row col-xs-6 separator-right no-padding">

        <div class="row col-xs-12 padding-bottom-5 flex-center">
            <iw-agency-combo-box #cboAge_id
                                 [isDisabled]="true"
                                 [labelAlign]="'top'"
                                 [label]="'agence' | translate"
                                 class="iw-combobox col-xs-4"
                                 formControlName="ageId"></iw-agency-combo-box>

            <iw-sector-combo-box #cboSec_id
                                 [isDisabled]="isReadonly"
                                 [labelAlign]="'top'"
                                 [label]="'nomsecteur' | translate"
                                 class="iw-combobox col-xs-4"
                                 formControlName="secId"></iw-sector-combo-box>

            <iw-conseiller-dropdown #cboCon_id
                                    [isDisabled]="isReadonly"
                                    [labelAlign]="'top'"
                                    [label]="'conseiller' | translate"
                                    class="iw-combobox col-xs-4"
                                    formControlName="conId"></iw-conseiller-dropdown>
        </div>

        <div class="row col-xs-12 separator-top no-padding">

            <iw-textfield [decimals]="2"
                          [isDisabled]="isReadonly"
                          [label]="'salary' | translate"
                          class="iw-input col-lg-12"
                          formControlName="salaire"
                          type="number"></iw-textfield>

            <iw-enum-dropdown #cboItypePeriode
                              [inputEnum]="typeEmploi"
                              [isDisabled]="isReadonly"
                              [labelAlign]="'top'"
                              [label]="'typeDemploi' | translate"
                              class="iw-input col-lg-12"
                              formControlName="typecdf"></iw-enum-dropdown>

            <iw-template-dropdown #cboPlaFixe
                                  [isDisabled]="isReadonly"
                                  [labelAlign]="'top'"
                                  [label]="'modele_contrat' | translate"
                                  [report]="'PF'"
                                  [size]="'large'"
                                  class="iw-input iw-input col-xs-10 margin-top-10"
                                  formControlName="cdffrx"></iw-template-dropdown>
            <iw-button (press)="setFormValue('cdffrx', undefined)"
                       [isDisabled]="isReadonly"
                       [text]="'raz' | translate"
                       class="iw-button col-xs-2 flex-center-row"></iw-button>
        </div>
    </div>
    <div class="row col-xs-6 no-padding">

        <iw-cliadr-dropdown #cboCliadr_id
                            (valueChange)="setClientAddress()"
                            [isDisabled]="isReadonly"
                            [labelAlign]="'top'"
                            [label]="'adresse' | translate"
                            [resultFilters]="resultFilters"
                            class="iw-input col-xs-12"
                            formControlName="cliadrId"></iw-cliadr-dropdown>

        <iw-textarea #edtAdresse
                     [height]="100"
                     [isDisabled]="true"
                     [value]="fullAddress"
                     class="iw-textarea col-xs-12"
                     style="padding: 0 7px"></iw-textarea>

        <div class="row col-xs-12 separator-top"></div>

        <iw-textfield #txtService
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'serviceDepartement' | translate"
                      class="iw-input col-xs-12"
                      formControlName="service"></iw-textfield>

        <iw-textfield-cliatt #txtResp
                             (cliAttChanged)="setAtt($event)"
                             [cliId]="cliId"
                             [isDisabled]="isReadonly"
                             [labelAlign]="'top'"
                             [label]="'responsableContact' | translate"
                             class="iw-input col-xs-12"
                             formControlName="resp"></iw-textfield-cliatt>

        <iw-textfield #txtTel
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'telephone' | translate"
                      class="iw-input col-xs-12 padding-top-5"
                      formControlName="tel"></iw-textfield>

        <iw-textfield #txtFax
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'fax' | translate"
                      class="iw-input col-xs-12"
                      formControlName="fax"></iw-textfield>

        <iw-textfield #txtNatel
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'natel' | translate"
                      [placeholder]="'+xxxXXXXXXXXX'"
                      class="iw-input col-xs-12"
                      formControlName="natel"></iw-textfield>

        <iw-email-textfield #txtEmail
                            [isDisabled]="isReadonly"
                            [labelAlign]="'top'"
                            [label]="'email' | translate"
                            class="iw-input col-xs-12"
                            formControlName="email"></iw-email-textfield>

    </div>
    <div class="row col-xs-12 separator-top">
        <div class="iw-form-group row col-xs-6">
            <iw-textfield [isDisabled]="true"
                          [label]="'candidat_place' | translate"
                          class="iw-input col-xs-12 no-padding-right no-padding-left flex"
                          formControlName="cddtPlace"
                          style="align-self: flex-end"></iw-textfield>
        </div>

        <iw-textfield-lookups #txtMotif_sat
                              [isDisabled]="isReadonly"
                              [labelAlign]="'top'"
                              [label]="'motifSiNonSatisfaite' | translate"
                              [lookupName]="'MOTIFCDENS'"
                              class="iw-input col-xs-6 no-padding-right"></iw-textfield-lookups>

    </div>
</form>
