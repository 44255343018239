<form [formGroup]="formGroup" class="row space-form">

    <iw-busy [ajustLeft]="14" [isBusy]="isLoading"></iw-busy>

    <div class="col-xs-12">
        <iw-textfield #txtNom [labelAlign]="'left'" [label]="'nom' | translate"
                      class="iw-input col-xs-12 col-no-padding" formControlName="nom" id="txt-nom"></iw-textfield>

        <iw-textfield #txtPrenom [labelAlign]="'left'" [label]="'prenom' | translate"
                      class="iw-input col-xs-12 col-no-padding" formControlName="prenom" id="txt-prenom"></iw-textfield>

        <iw-email-textfield #txtemail [labelAlign]="'left'" [label]="'eMail' | translate" class="iw-input col-xs-12"
                            formControlName="email" id="txt-email"></iw-email-textfield>

        <iw-phone-textfield #txtNatel [labelAlign]="'left'" [label]="'natel' | translate"
                            class="iw-input col-xs-9 col-no-padding" formControlName="natel"
                            id="txt-natel"></iw-phone-textfield>

        <iw-multiautocomplete-qualif #txtProfession [doubleClickable]="true" [labelAlign]="'top'"
                                     [label]="'professionQualification' | translate" [multiple]="true"
                                     class="iw-input col-xs-12" formControlName="profession"
                                     id="qualif"></iw-multiautocomplete-qualif>
    </div>


    <div class="row col-xs-12">
        <iw-button #btnOk (press)="createCandidate()" [isDisabled]="formGroup.invalid" [text]="'ok' | translate"
                   class="iw-button col-xs-6"></iw-button>

        <iw-button #btnClose (press)="closeDialog()" [text]="'annuler' | translate"
                   class="iw-button col-xs-6"></iw-button>
    </div>

</form>
