import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppmisih} from '@app/sam-base/models/placement';

import {MisindComponent} from '../components/misind/misind.component';

export const MISIND_CREATE_FORM: FormDescription<Ppmisih> = {
    form: MisindComponent, state: {
        ...buildDefaultState(Ppmisih),
        diagTitle: 'misind_create_form_title',
        diagShowStatus: true,
        editMode: 'read',
        patch: false,
        width: 500,
        isNew: true,
        destroyOnSave: true,
        diagModal: true
    }
};

export const MISIND_UPDATE_FORM: FormDescription<Ppmisih> = {
    form: MisindComponent, state: {
        ...buildDefaultState(Ppmisih),
        diagTitle: 'misind_update_form_title',
        diagShowStatus: true,
        editMode: 'edit',
        patch: false,
        width: 500,
        destroyOnSave: true,
        diagModal: true
    }
};
