<form [formGroup]="formGroup" class="row space-form">
    <iw-rest-grid #olvMdl (rowDoubleClick)="openMission($event)" (selected)="onSelectRow($event)"
                  [autoFormClick]="false" [defaultSelected]="true" [hasGridColumnMenu]="false" [hasSortIcon]="true"
                  [type]="type" class="iw-grid col-xs-12" style="height: 250px"></iw-rest-grid>

    <div class="row col-xs-12 no-padding">
        <iw-textfield #txtMis_id [isDisabled]="true" class="iw-input col-xs-2 no-padding-left"
                      formControlName="misId"></iw-textfield>

        <iw-textfield #txtMismdl_id [isDisabled]="true" class="iw-input col-xs-2 col-xs-offset-6"
                      formControlName="mismdlId"></iw-textfield>

        <iw-button #btnClose (press)="destroyForm()" [text]="'fermer' | translate"
                   class="iw-button col-xs-2 no-padding-right"></iw-button>
    </div>
</form>
