export enum Langue {
    francais = 1, english, deutsch, italiano, espagnol,
}

export enum Formejuri {
    societe_anonyme = 'A',
    raison_sociale = 'B',
    snc = 'C',
    raison_individuelle = 'E',
    sarl = 'G',
    inscrit = 'H',
    societe_commanditee = 'L',
    cooperative = 'O',
    prive = 'P',
    corporation_publique = 'S',
    inconnu = 'U',
    association = 'V',
}

export enum Addresses {
    adr_princ = 1, adr_fact = 3, adr_rapl = 2,
}
