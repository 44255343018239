import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('gefacele')
export class Gefacele {
    @IwRestEntityId() public faceleId?: string;
    public facId?: string;
    public cOrder?: string;
    @IwColumn({name: 'qte', index: 5}) public nqte?: number;
    public cqte?: string;
    @IwColumn({name: 'prix', type: 'mnt', index: 7}) public prixUnite?: number;
    @IwColumn({name: 'montant', type: 'mnt', index: 8}) public montant?: number;
    @IwColumn({name: 'un', index: 6}) public unite?: string;
    @IwColumn({name: 'libelle', index: 1}) public libelle?: string;
    public entete?: string;
    public texte?: string;
    public refClient?: string;
    @IwColumn({name: 'rapport', index: 2}) public custom1?: string;
    @IwColumn({name: 'se', index: 3}) public custom2?: string;
    public custom3?: string;
    @IwColumn({name: 'chantier', index: 4}) public custom4?: string;
    public custom5?: string;
    public mntEmp?: number;
    public coeff?: number;
    @IwColumn({name: 'compte', index: 9}) public compte?: string;
    public centre?: string;
    @IwColumn({name: 'secteur', index: 10}) public secId?: string;
    @IwColumn({name: 'cons', index: 11}) public conId?: string;
    @IwColumn({name: 'cons2', index: 12}) public conId2?: string;
    public conId2Pc?: number;
    public empId?: string;
    public parent?: string;
    public parentType?: string;
    public parentDate?: string;
    public parent2?: string;
    public misIhId?: string;
    public tvaCode?: string;
    public tvaTaux?: number;
    public modified?: boolean;
    public eleData?: string;
    public userId?: string;
}
