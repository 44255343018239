<div class="padding-10 row"
     style="height: 500px;">
    <div class="col-xs-12 docmanagement-container">
        <iw-doc-management (refreshEventTriggered)="onRefreshGrid()"
                           (uploadedDoc)="onUploadedDoc($event)"
                           [entityId]="entityId"
                           [entityRef]="entityRef"
                           [isReadonly]="isReadonly || !entityId"></iw-doc-management>
    </div>
    <div class="col-xs-12 padding-top-20 doctypemanagement-container">
        <iw-doc-type-management #docTypeGrid
                                [empId]="entityId"
                                [isReadonly]="isReadonly"></iw-doc-type-management>
    </div>
</div>
