import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {
    GenerateInvoiceRequest,
    InitInvoiceRequest,
    InvoiceCountRequest,
    InvoiceCountResponse,
    InvoiceLockResponse,
    InvoicePrepareRequest,
    InvoiceProcess,
    InvoiceProcessDetails,
    SaveInvoiceProcessRequest
} from '@app/sam-base/models/placement';
import {Observable} from 'rxjs';

@Injectable()
export class InvoiceService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    /**
     * Checks if the invoice is locked to do prepare
     */
    public isInvoiceLocked(): Observable<InvoiceLockResponse> {
        return this.POST<InvoiceLockResponse>(undefined, undefined, 'invoice-process/lock');
    }

    public getInvoiceCount(req: InvoiceCountRequest): Observable<InvoiceCountResponse[]> {
        return this.POST<InvoiceCountResponse[]>(req, undefined, 'invoice-process/count');
    }

    public listInvoiceProcess(): Observable<InvoiceProcess[]> {
        return this.GET<InvoiceProcess[]>(undefined, 'invoice-process');
    }

    public getInvoiceProcessDetails(processId: number): Observable<InvoiceProcessDetails> {
        return this.GET<InvoiceProcessDetails>(undefined, `invoice-process/${processId}`);
    }

    /**
     * Get data to init invoice
     *
     * @param salId period id
     */
    public initInvoice(req: InitInvoiceRequest): Observable<InvoiceProcessDetails> {
        return this.POST<InvoiceProcessDetails>(req, undefined, 'invoice-process');
    }

    // /**
    //  * Changes the person who have the lock
    //  */
    // public stealLock(): Observable<undefined> {
    //     return this.POST<undefined>(undefined, undefined, 'system', 'lock', 'steal/invoice');
    // }
    //
    // /**
    //  * Changes the person who have the lock
    //  */
    // public clearLock(): Observable<undefined> {
    //     return this.POST<undefined>(undefined, undefined, 'system', 'lock', 'clear/invoice');
    // }


    /**
     * Get data to prepare invoice
     *
     * @param req paremeters request
     */
    public prepareInvoice(req: InvoicePrepareRequest): Observable<InvoiceProcessDetails> {
        return this.POST<InvoiceProcessDetails>(req, undefined, `invoice-process/${req.invoiceProcessId}/prepare`);
    }

    /**
     * Get data to prepare invoice
     *
     * @param req paremeters request
     */
    public saveInvoiceProcess(req: SaveInvoiceProcessRequest): Observable<InvoiceProcessDetails> {
        return this.PUT<InvoiceProcessDetails>(req, undefined, `invoice-process/${req.invoiceProcessId}`);
    }

    /**
     * Get data to generate invoice
     *
     * @param req paremeters request
     */
    public generateInvoice(req: GenerateInvoiceRequest): Observable<InvoiceProcessDetails> {
        return this.POST<InvoiceProcessDetails>(req, undefined, `invoice-process/${req.invoiceProcessId}/generate`);
    }

    // /**
    //  * Delete preparation data
    //  *
    //  * @param invoiceId invoice id
    //  */
    // public deletePrepData(invoiceId: string): Observable<any> {
    //     return this.DELETE(undefined, `/invoice/prepare/${invoiceId}`);
    // }
    //
    // /**
    //  * Ty to delete preparation data
    //  *
    //  * @param invoiceId invoice id
    //  */
    // public tryDeletePrepData(invoiceId: string): Observable<any> {
    //     return this.DELETE(undefined, `/invoice/trydelete/prepare/${invoiceId}`);
    // }

    /**
     * Delete init data
     *
     * @param invoiceProcessId
     */
    public deleteData(invoiceProcessId: number): Observable<void> {
        return this.DELETE(undefined, `/invoice-process/${invoiceProcessId}`);
    }

    public reasign(invoiceProcessId: number): Observable<void> {
        return this.PUT(undefined, undefined, `/invoice-process/${invoiceProcessId}/reassign`);
    }
}
