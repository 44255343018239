import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {SamUserAdminComponent} from '@modules/sam-main/admin/components/sam-user-admin/sam-user-admin.component';
import {SamUser} from '@sam-base/models/admin/sam-user';

export const SAM_USER_ADMIN_FORM: FormDescription<SamUser> = {
    form: SamUserAdminComponent,
    state: {
        ...buildDefaultState(SamUser),
        diagModal: false,
        editMode: 'waiting',
        width: 900
    }
};

