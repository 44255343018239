<iw-busy [ajustLeft]="0" [ajustTop]="0" [isBusy]="!canLoadApp"></iw-busy>
<iw-toast-host></iw-toast-host>
<ng-container *ngIf="canLoadApp">
    <iw-slide-panel [entrySide]="'top'" [toggleEvent]="globalSlidePanelEvent" [topMost]="true">
        <router-outlet name="globalPanel"></router-outlet>
    </iw-slide-panel>
    <iw-layout>
        <div class="body-panel" style="height: calc(100% - 50px);" topPanel>
            <router-outlet name="topPanel"></router-outlet>
        </div>
        <div body class="body-content">
            <router-outlet></router-outlet>
        </div>
    </iw-layout>
</ng-container>
