import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Js3view} from '@app/sam-base/models/salary/js3view';
import {
    DeductionCreateComponent
} from '../components/employee/tab-saemp-deductions/deduction-create/deduction-create.component';
import {DeductionCreate} from '../components/employee/tab-saemp-deductions/deduction-create/deduction-create.model';

export const DED_CREATION: FormDescription<DeductionCreate> = {
    form: DeductionCreateComponent, state: {
        ...buildDefaultState(Js3view), diagTitle: 'deduction_create', diagModal: false, width: 400, isNew: true
    }
};
