<iw-table-edit [entity]="entity" [formId]="uuid" [height]="'150px'" [query]="query"
               [saveValid]="isValid"></iw-table-edit>
<div class="row">
    <div class="col-xs-8">
        <form [formGroup]="formGroup" class="row">
            <iw-textfield #txtNom [isDisabled]="!isWriteMode" [labelAlign]="'top'" [label]="'nom' | translate"
                          class="iw-input col-xs-6" formControlName="nom"></iw-textfield>
            <iw-textfield #txtPrenom [isDisabled]="!isWriteMode" [labelAlign]="'top'" [label]="'prenom' | translate"
                          class="iw-input col-xs-6" formControlName="prenom"></iw-textfield>
            <iw-date-picker #txtDateNaiss [isDisabled]="!isWriteMode" [labelAlign]="'top'"
                            [label]="'naissance' | translate" class="iw-input col-xs-6"
                            formControlName="datenaiss"></iw-date-picker>
            <iw-textfield #txtAge [isReadOnly]="true" [label]="' '" [value]="age + ' ans'"
                          class="iw-input col-xs-6"></iw-textfield>
            <iw-textfield #txtNoavs13 [isDisabled]="!isWriteMode" [labelAlign]="'top'" [label]="'noAvs13' | translate"
                          [mask]="'000.0000.0000.00'" class="iw-input col-xs-6"
                          formControlName="noavs13"></iw-textfield>
            <iw-checkbox #chkAcharge [isDisabled]="!isWriteMode" [label]="'aCharge' | translate"
                         class="iw-input col-xs-8" formControlName="acharge"></iw-checkbox>
            <iw-checkbox #chkAllocation [isDisabled]="!isWriteMode" [label]="'allocation' | translate"
                         class="iw-input col-xs-8" formControlName="allocation"></iw-checkbox>
        </form>
    </div>
    <div class="col-xs-4 flex flex-v flex-end">
        <iw-button (press)="onClose()" [text]="'fermer' | translate" class="btn-block"></iw-button>
    </div>
</div>
