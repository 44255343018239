<div class="row period-management">
    <div class="col-xs-8 margin-bottom-5">
        <iw-textfield [label]="'period_active' | translate" [value]="activePer?.texte"
                      class="iw-textfield active-background" isDisabled="true" labelAlign="top"></iw-textfield>
    </div>
    <div class="row" style="width: 100%">
        <div class="col-xs-8" style="height: 500px;">
            <iw-rest-grid #perGrid (selected)="getSelectedRow($event)" [customColumns]="columns"
                          [defaultSelected]="true" [defaultSortDir]="'desc'" [defaultSort]="'datedebut'"
                          [forceSelected]="true" [hasGridColumnMenu]="false" [hasSortIcon]="false"
                          [queryStatements]="query" [type]="type"></iw-rest-grid>
        </div>

        <div class="col-xs-4 grid-buttons">
            <iw-button (press)="createNewPeriod()" [text]="'period_button_new' | translate" class="iw-button"
                       id="newPeriode"></iw-button>
            <iw-button (press)="activatePeriod()"
                       [isDisabled]="!!selectedPeriod.status || selectedPeriod.status !== 0 || selectedPeriod.salId === activePer?.salId"
                       [text]="'period_button_activate' | translate" class="iw-button" id="activatePeriode"></iw-button>
            <iw-button (press)="closePeriod()" *ngIf="selectedPeriod.status !== 1" [isDisabled]="periodCloseDisabled"
                       [text]="'period_button_close' | translate" class="iw-button" id="closePeriod"></iw-button>
            <iw-button (press)="openPeriod()" *ngIf="selectedPeriod.status === 1"
                       [text]="'period_button_open' | translate" class="iw-button" id="openPeriod"></iw-button>
            <iw-button (press)="calculate()" [isDisabled]="!canCalculatePeriod"
                       [text]="'period_button_calculate' | translate" class="iw-button"
                       id="calculatePeriod"></iw-button>
            <iw-button (press)="closeDialog()" [text]="'annuler' | translate" class="iw-button"
                       id="closeDialog"></iw-button>
        </div>
    </div>

</div>
