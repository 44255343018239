<div class="row">
    <iw-table (columnSort)="onSort($event)"
              (contextMenuClick)="onContextMenu($event)"
              [columns]="columns"
              [data]="bookingFiles"
              [defaultSelected]="true"
              [isDisabled]="false"
              [isFilterEnable]="false"
              [isGridColumnMenuEnable]="false"
              [isSortIconEnable]="true"
              [selectionMode]="'checkbox'"
              [virtualScroll]="true"
              class="iw-grid col-xs-12 no-padding"
              style="height: 530px"></iw-table>
    <iw-context-menu #menu
                     [context]="contextMenuData"
                     [items]="contextMenuItems"
                     [popup]="true"></iw-context-menu>
</div>
<div class="row end-xs">
    <iw-button (press)="closeDialog()"
               [text]="'fermer' | translate"
               class="iw-button"></iw-button>
</div>
