import {FormCollection} from '@app/sam-base/core/form-handler/models';
import {SASX5DEC_INSTITUTION} from '@modules/sam-main/admin/components/swissdec/institution/sasx5dec-institution-form';
import {SASX5DEC_DECLARATION} from '@modules/sam-main/admin/components/swissdec/sasx5dec-form';
import {SASX5DEC_LIST} from '@modules/sam-main/admin/components/swissdec/sasx5dec-table';
import {SASX5DEC_CREATE_DEFAULT_FORM} from '@modules/sam-main/admin/components/swissdec/sasx5decreate';
import {SAM_USER_ADMIN_LIST} from '@modules/sam-main/admin/forms/kusersform';
import {SAM_USER_ADMIN_RESETPW} from '@modules/sam-main/admin/forms/kuserspwresetform';

import {SAM_USER_ADMIN_FORM} from '@modules/sam-main/admin/forms/sam-user-admin-create.form';
import {AdminForm} from './admin.forms';


export const PLACEMENT_FORMS: FormCollection<AdminForm> = {
    [AdminForm.SamUserAdminEdit]: SAM_USER_ADMIN_FORM,
    [AdminForm.SamUserAdminList]: SAM_USER_ADMIN_LIST,
    [AdminForm.ResetUserPassword]: SAM_USER_ADMIN_RESETPW,
    [AdminForm.SwissdecList]: SASX5DEC_LIST,
    [AdminForm.SwissdecNew]: SASX5DEC_CREATE_DEFAULT_FORM,
    [AdminForm.SwissdecDeclaration]: SASX5DEC_DECLARATION,
    [AdminForm.SwissdecDeclarationInstitution]: SASX5DEC_INSTITUTION

};
