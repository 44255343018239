import {Component, OnInit} from '@angular/core';
import {SagsService} from '@app/modules/sam-main/salary/services/sags.service';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwEventHubService} from '@app/sam-base/core';
import {SaemrService} from '@app/sam-base/core/services/saemr.service';
import {SaemsService} from '@app/sam-base/core/services/saems.service';
import {SaesService} from '@app/sam-base/core/services/saes.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {IwStoreService} from '@app/sam-base/core/store/iw-store.service';
import {ToastService} from '@app/sam-base/core/toast';
import {ComboboxItem, FormKeys} from '@app/sam-base/models';
import {Saes} from '@app/sam-base/models/salary/saes';
import {SaesCPV2} from '@app/sam-base/models/salary/saesCPV2';
import {TranslateService} from '@ngx-translate/core';
import {switchMap} from 'rxjs/operators';


@Component({templateUrl: './payer-prov.component.html'})
export class PayerProvComponent extends BaseFormComponent<Saes> implements OnInit {

    public readonlyGsProvision = false;
    public readonlyPayer = true;
    public readonlyEffacer = true;
    public readonlyCalcule = true;

    public gsProvisionOptions: ComboboxItem<string>[] = [];

    public genreDeSalaire = '';
    public gsProvisionInfo = '';

    public soldeValue = 0;
    public payerValue = 0;
    public isLoading = false;
    public saes: Saes = new Saes();
    protected readonly event = event;

    constructor(private _store: IwStoreService, private readonly _events: IwEventHubService<string>,
                private _sagsService: SagsService, private _saesService: SaesService,
                private _saemsService: SaemsService, private _saemrService: SaemrService,
                private _translate: TranslateService, private _toast: ToastService) {
        super();
    }

    public get parentUuid(): string {
        return this.getData('parentUuid') ?? '';
    }

    public get empId(): string {
        return this.getData('empId', true) || '';
    }

    public get salId(): string {
        return this.getData('salId', true) || '';
    }

    public ngOnInit() {
        this.sagsInfo();
    }

    public save() {
        this.saes.origine = 'V';
        this.saes.empId = this.empId;
        this.saes.salId = this.salId;
        this.saes.gsId = this.genreDeSalaire;
        this.saes.prBase = this.payerValue;
        this.saes.prTaux = 1;
        this.saes.taux = 1;
        this.saes.base = this.payerValue;
        this.saes.montant = this.payerValue;

        this._saemsService.saveSaemsCalculated(this.empId, this.salId, false)
            .subscribe();
        this._saemsService.elSalSubject.next({
            uuid: this.parentUuid,
            saved: true
        });
        this._saesService.saveSaesPayerProv(this.saes).subscribe({
            next: () => {
                this.isLoading = false;
                this.closeDialog();
                this._saemsService.elSalSubject.next({
                    uuid: this.parentUuid,
                    saved: true
                });
            },
            error: () => {
                this.isLoading = false;
                this._toast.error(this._translate.instant('error_sal_el'));
            }
        });
        this.closeDialog();
    }
    
    public calcule() {
        this.readonlyGsProvision = true;
        this.readonlyPayer = false;
        this.readonlyEffacer = false;

        let saesCPV2Arr: SaesCPV2[] = [];
        const ids: string[] = [
            this.empId,
            this.salId,
            this.getFormValue('gsId').split(' ')[0],
            this.genreDeSalaire
        ];

        const gsId = this.getFormValue('gsId').split(' ')[0]; // Extract gsId for reuse

        this._saesService.getSaesCpv2BySaesIdentification(ids)
            .pipe(
                switchMap((saesCPV2: SaesCPV2[]) => {
                    saesCPV2Arr = saesCPV2;
                    return this._saemrService.getSaemrByEmpIdAndSalIdAndCID(this.empId, this.salId, gsId);
                })
            )
            .subscribe((nRep?: number) => {
                let nProv = 0;
                let nPai = 0;
                nRep = nRep || 0;  // Default to 0 if no value is returned

                if (saesCPV2Arr.length > 0) {
                    saesCPV2Arr.forEach((s) => {
                        if (s.gsId === gsId) {
                            if (s.montant !== undefined) {
                                nProv += s.montant;
                            }
                        } else {
                            if (s.montant !== undefined) {
                                nPai += s.montant;
                            }
                        }
                    });
                }

                const result = nRep + nProv - nPai;
                this.soldeValue = result;
                this.payerValue = result;
            });
    }

    public effacer() {
        this.readonlyGsProvision = false;
        this.readonlyPayer = true;
        this.readonlyEffacer = true;
    }

    public closeDialog() {
        this._events.emit('payerprov_closed');
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public updatePayerValue(event: any) {
        this.payerValue = Number(event);
    }

    protected getFormControlNames(): FormKeys<Saes> {
        return ['gsId'];
    }

    protected getValidationType() {
        return Saes;
    }

    private sagsInfo() {
        this._sagsService.getSagsInfo()
            .subscribe((data: string[]) => {
                data.forEach((val) => [
                    this.gsProvisionOptions.push({
                        name: val,
                        value: val
                    })]);
            });
        this.subscribeValueChange('gsId', (val?: string) => {
            if (val !== undefined) {
                this.readonlyCalcule = false;
                this._sagsService.getSagsPaiPrvByGsId(val.split(' ')[0])
                    .subscribe((response: string) => {
                        this.genreDeSalaire = response;
                    });
            }
        }, 200);
    }
}
