import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Saeio} from '@app/sam-base/models/salary';
import {Observable} from 'rxjs';

import {RestClient} from '../rest-api/core';

@Injectable()
export class SaeioService extends RestClient<any> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public createNewEntry(saeio: Saeio): Observable<Saeio> {
        return this._http.post<Saeio>(this._baseUrl + `saeio`, saeio);
    }

    public saveByEmpIdAndSaeioId(saeioId: number, saeio: Saeio): Observable<Saeio> {
        return this._http.put(this._baseUrl + `saeio/${saeioId}`, saeio);
    }

    public deleteByEmpIdAndSaeioId(saeioId: number): Observable<Saeio> {
        return this._http.delete<Saeio>(this._baseUrl + `saeio/${saeioId}`);
    }

    public getLatestSaeioByEmpId(empId: string): Observable<Saeio> {
        return this._http.get<Saeio>(this._baseUrl + `saeio/${empId}/latest`);
    }
}
