import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {OrdrePaiementComponent} from '@modules/sam-main/salary/components/ordre-paiement/ordre-paiement.component';
import {Gedta} from '@sam-base/models/invoices/gedta';

export const ORDRE_PAIEMENT_FORM: FormDescription<Gedta> = {
    form: OrdrePaiementComponent,
    state: {
        ...buildDefaultState(Gedta),
        diagTitle: 'title_ordre_paiement',
        diagShowSideMenu: false,
        diagShowStatus: true,
        width: 1200
    }
};
