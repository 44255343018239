import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwGridColumn} from '@sam-base/models';
import {Ppacattest} from '@sam-base/models/placement';
import {Cuhismis} from '@sam-base/models/placement/attestEmp';

@Component({
    selector: 'iw-tab-historiquemission', templateUrl: './tab-historiqueMission.component.html'
})

export class TabPpacattesHistoriqueMissionComponent extends BasePartialFormComponent<Ppacattest> {

    @Input() public isReadonly = true;

    @Input()
    public set cuhismis(value: Cuhismis[]) {
        this._cuhismis = value;
        this.orderDates();
    }

    public get cuhismis(): Cuhismis[] {
        return this._cuhismis;
    }

    public customColumns = this.getColumns();

    public sortProp: keyof Cuhismis = 'firstDay';

    private _cuhismis: Cuhismis[] = [];

    constructor() {
        super();
    }


    private getTime(firstDay?: string) {
        return firstDay !== '' ? new Date(+(firstDay?.split('.')[2] || 0), +(firstDay?.split('.')[1] || 0), +(firstDay?.split('.')[0] || 0)).getTime() : 0;
    }

    private orderDates(): void {
        if (!this._cuhismis) {
            return;
        }
        // eslint-disable-next-line complexity
        this._cuhismis.sort((a, b) => // Subtract your dates
            // To get a value that is either negative, positive, or zero.
            this.getTime(a.firstDay) - this.getTime(b.firstDay));
    }

    /* Columns to show in  table */
    private getColumns(): IwGridColumn<Cuhismis>[] {
        return [{
            prop: 'misId', name: 'Mission', index: 0
        }, {
            prop: 'cctId', name: 'cct', index: 1
        }, {
            prop: 'firstDay', name: 'start', index: 2
        }, {
            prop: 'lastDay', name: 'datefin', index: 3
        }, {
            prop: 'nbh', name: 'heures', index: 4
        }, {
            prop: 'nbj', name: 'nbjours', index: 5
        }];
    }
}
