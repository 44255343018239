import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {MessageFormRefreshService} from '@app/sam-base/components/subjects/message-service-subject.service';
import {FormHandlerService} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {ToastService} from '@app/sam-base/core/toast';
import {ComboboxItem, FormKeys, RowClickEvent} from '@app/sam-base/models';
import {IwGridColumn} from '@app/sam-base/models/components/grid-column.model';
import {AdminForm} from '@modules/sam-main/admin/admin.forms';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {SamUser} from '@sam-base/models/admin/sam-user';
import {Subject, takeUntil} from 'rxjs';

@Component({
    templateUrl: './sam-user-admin-table.component.html'
})
export class SamUserAdminTableComponent extends BaseStoreFormComponent<SamUser> implements OnInit, OnDestroy {
    public libelle?: string;
    public cpainavisOptions: ComboboxItem<string>[] = [];
    public columns = this.getMismdlColumns();
    public isLoading = false;
    public tableData: SamUser[] = [];

    private subscriptions = new Subject();

    constructor(store: IwStoreService, _restService: RestApiService, private readonly _http: HttpClient,
                private _translate: TranslateService,
                private readonly refreshFormMessageService: MessageFormRefreshService,
                private _toastService: ToastService, private readonly _formHandler: FormHandlerService<AdminForm>,
                private readonly messageService: MessageFormRefreshService) {
        super(store);
        this.cpainavisOptions = [
            {
                name: this._translate.instant('NOA'),
                value: 'NOA'
            },
            {
                name: this._translate.instant('SIA'),
                value: 'SIA'
            },
            {
                name: this._translate.instant('CND'),
                value: 'CND'
            },
            {
                name: this._translate.instant('CWD'),
                value: 'CWD'
            }];
    }

    public ngOnInit() {
        this.getAllUsers();
        this.messageService.getMessage()
            .pipe(takeUntil(this.subscriptions))
            .subscribe(message => {
                if (message && 'sam_user.table' === message) {
                    this.getAllUsers();
                }
            });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }


    public openCreate() {
        this._formHandler.showFormDialog(AdminForm.SamUserAdminEdit, undefined, s => ({
            ...s,
            diagModal: true,
            diagTitle: 'title_kuser_form',
        }));
    }

    public onRowDoubleClick(event: RowClickEvent<SamUser>) {
        this._formHandler.showFormDialog(AdminForm.SamUserAdminEdit, event, s => ({
            ...s,
            entityId: event.row.id || '',
            diagModal: true,
            diagTitle: 'title_kuser_form',
        }));
    }

    public getAllUsers() {
        return this._http.get<SamUser[]>(environment.backendURL + 'sam-user/all-gestions')
            .subscribe((data: SamUser[]) => {
                this.tableData = data;
            });
    }

    uploadDocument(files: File[]) {
        if (files?.length && files[0].name) {
            const file: File = files[0];
            const formData: FormData = new FormData();
            formData.append('file', file);
            this._http.post(environment.backendURL + 'sam-user/import', formData).subscribe(users => {
                this._toastService.success('import_users_success');
            })
        }

    }

    protected getFormControlNames(): FormKeys<SamUser> {
        return [
            'id',
            'username',
            'email',
            'firstName',
            'lastName',
            'locale',
            'tenant'];
    }

    private getMismdlColumns(): IwGridColumn<SamUser>[] {
        return [
            {
                prop: 'username',
                name: this._translate.instant('username'),
                index: 1
            },
            {
                prop: 'tenant',
                name: this._translate.instant('tenant'),
                index: 2
            },
            {
                prop: 'firstName',
                name: this._translate.instant('firstName'),
                index: 3
            },
            {
                prop: 'lastName',
                name: this._translate.instant('lastName'),
                index: 4
            },
            {
                prop: 'email',
                name: this._translate.instant('email'),
                index: 5
            }];
    }
}
