<div [formGroup]="formGroup"
     class="row">
    <div class="row">
        <div class="row space-form col-xs-12">
            <iw-rest-grid #sapaimodeGrid
                          (selected)="getSelectedRow($event)"
                          [autoFormClick]="false"
                          [hasGridColumnMenu]="false"
                          [hasSortIcon]="true"
                          [queryStatements]="query"
                          [type]="type"
                          class="col-xs-10 padding-top-15"
                          style="height: 300px"></iw-rest-grid>
            <div class="space-form col-xs-2">
                <iw-button (press)="newEntry()"
                           [isDisabled]="isEditing || isNew"
                           [text]="'Nouveau' | translate"
                           class="iw-button padding-top-10"></iw-button>
                <iw-button #btnModif
                           (press)="modifySapaimode()"
                           *ngIf="!isEditing"
                           [isDisabled]="!isSelected"
                           [text]="'sidemenu_edit' | translate"
                           class="iw-button padding-top-10"></iw-button>
                <iw-button (press)="saveEntry()"
                           *ngIf="isEditing"
                           [isDisabled]="!formGroup.valid"
                           [text]="'enregistrer' | translate"
                           class="iw-button padding-top-10"></iw-button>
                <iw-button (press)="deleteEntry()"
                           *ngIf="!isEditing"
                           [isDisabled]="!isSelected"
                           [text]="'Supprimer'"
                           class="iw-button padding-top-10"></iw-button>
                <iw-button (press)="cancelEditionMode()"
                           *ngIf="isEditing"
                           [text]="'sidemenu_cancel' | translate"
                           class="iw-button btn-block padding-bottom-10"></iw-button>
                <iw-button (press)="closeDialog()"
                           [text]="'fermer' | translate"
                           class="iw-button padding-top-10"></iw-button>
            </div>
        </div>
        <div class="row space-form col-xs-12">
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'code' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-2"
                          formControlName="mpaId"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [labelAlign]="'top'"
                          [label]="'titre' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-6"
                          formControlName="titre"></iw-textfield>
            <iw-enum-dropdown [inputEnum]="payTypes"
                              [isDisabled]="!isEditing"
                              [labelAlign]="'top'"
                              [label]="'type' | translate"
                              [size]="'small'"
                              class="iw-input col-xs-6"
                              formControlName="payType"></iw-enum-dropdown>
            <iw-textfield-compte [isDisabled]="!isEditing"
                                 [labelAlign]="'top'"
                                 [label]="'compte' | translate"
                                 [size]="'small'"
                                 class="iw-input col-xs-10"
                                 formControlName="compte"></iw-textfield-compte>
            <iw-textfield [isDisabled]="!isEditing"
                          [label]="'banque' | translate"
                          [noLabel]="true"
                          class="iw-input col-xs-6"
                          formControlName="banque"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [label]="'clearing' | translate"
                          class="iw-input col-xs-3"
                          formControlName="banqueClr"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [label]="'swift' | translate"
                          class="iw-input col-xs-6"
                          formControlName="banqueSwi"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [label]="'compte' | translate"
                          class="iw-input col-xs-6"
                          formControlName="banqueCpt"></iw-textfield>
            <iw-textfield [isDisabled]="!isEditing"
                          [label]="'iban' | translate"
                          class="iw-input col-xs-6"
                          formControlName="iban"></iw-textfield>
            <iw-checkbox [isDisabled]="!isEditing"
                         [label]="'DTA avec IBAN'"
                         class="iw-input col-xs-6"
                         formControlName="dtaiban"></iw-checkbox>
        </div>
    </div>
</div>
