<form [formGroup]="formGroup">
    <iw-textfield-compte [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Compte Débiteurs'" class="iw-input"
                         formControlName="cptdeb"></iw-textfield-compte>

    <iw-textfield-compte [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Compte Rabais'" class="iw-input"
                         formControlName="cptrab"></iw-textfield-compte>

    <iw-textfield-compte [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Compte Frais'" class="iw-input"
                         formControlName="cptfrais"></iw-textfield-compte>

    <iw-textfield-compte [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Compte TVA'" class="iw-input"
                         formControlName="cpttva"></iw-textfield-compte>

    <iw-textfield-compte [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Compte Ristournes'" class="iw-input"
                         formControlName="cptrist"></iw-textfield-compte>

    <iw-textfield-compte [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Compte Prov./Rist.'" class="iw-input"
                         formControlName="cptprvrist"></iw-textfield-compte>

    <iw-textfield-compte [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Compte Prov./Rist. (y-1)'"
                         class="iw-input" formControlName="cptrist1"></iw-textfield-compte>

    <iw-checkbox [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'Provisionner les ristournes'" class="iw-input"
                 formControlName="lprvrist"></iw-checkbox>

</form>
