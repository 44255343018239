<form [formGroup]="formGroup" class="row space-form" style="height: 40vh;">
    <div class="col-xs-12">
        <div class="row">
            <iw-button (press)="openCreate()" [text]="'create' | translate"
                       class="iw-button col-xs-2 no-label"></iw-button>

            <iw-button (press)="destroyForm()" [text]="'fermer' | translate"
                       class="iw-button col-xs-2 no-label"></iw-button>

            <iw-button (press)="onMergeClick()" [text]="'merge' | translate"
                       class="iw-button col-xs-2 no-label"></iw-button>

            <iw-button (press)="createMock()" [text]="'Certification' | translate"
                       class="iw-button col-xs-2 no-label"></iw-button>
        </div>
    </div>
    <div class="row">
        <iw-table #lstMdl (rowDoubleClick)="onRowDoubleClick($event)" (selectedChange)="onSelectedChange($event)"
                  [columns]="columns" [data]="tableData" [defaultSelected]="true" [isDisabled]="false"
                  [isFilterEnable]="false" [isGridColumnMenuEnable]="false" [isSortIconEnable]="false"
                  [selectionMode]="'checkbox'" class="iw-grid col-xs-12" style="height: 36vh;"></iw-table>

    </div>


</form>
