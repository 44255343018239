import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('gesaexe')
export class Gesaexe {
    @IwRestEntityId() public cannee?: string;
    public cflags?: string;
    public larc?: boolean;
    public datearc?: Date;
    public iwsarevno?: number;
}
