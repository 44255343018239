<div class="iw-header">
    <div class="left">
        <div class="row">
            <iw-header-toolbar [version]="version"
                               showChangeGestion="true"></iw-header-toolbar>
            <p-megaMenu [model]="visibleEntries"></p-megaMenu>
        </div>
    </div>
    <div class="center">
        {{ username }}
    </div>
    <iw-header-toolbar [showIJ]="hasIjAccess"
                       [version]="version"
                       class="right"
                       showHelp="true"
                       showLanguage="true"
                       showLogout="true"
                       showPeriodFacture="true"
                       showPeriodPlacement="true"
                       showPeriodSalaire="true"></iw-header-toolbar>
</div>