import {Component, OnDestroy, OnInit} from '@angular/core';
import {CalculationsService} from '@app/modules/sam-main/placement/services/calculations.service';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {WARNING} from '@app/sam-base/core/logger';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys} from '@app/sam-base/models';
import {Ppgrh, Ppmisih} from '@app/sam-base/models/placement';
import {lastValueFrom, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/**
 * Component created regarding SAM-4573
 */
@Component({
    templateUrl: './misheuspc.component.html'
})
export class MisheuspcComponent extends BaseStoreFormComponent<Ppmisih> implements OnInit, OnDestroy {

    public pprgh?: RestEntityClient<Ppgrh>;
    public follow?: string;
    public grhType?: string;
    public grhIdName?: string;

    public get misId() {
        return this.getData('misId');
    }

    private _allowUpdateTrigger = false;
    private subscriptions = new Subject();

    constructor(store: IwStoreService, private readonly _rest: RestApiService, private readonly _calc: CalculationsService) {
        super(store);
        this.pprgh = this._rest.getEntityClient(Ppgrh);
    }

    public ngOnInit() {
        this.updateTriggers();
        this.setFormValue('misId', this.misId);
        this.setFormValue('indorheu', 'H');
        this.checkValues();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public checkValues() {
        const x = this.formGroup.valueChanges
            .pipe(takeUntil(this.subscriptions))
            .subscribe(() => {
                const value2: number = this.getFormValue('mntemp');
                const value1: number = this.getFormValue('mntcli');
                if (value1 && value2) {
                    x.unsubscribe();
                    this.calcCoeff();
                }
            });
    }

    public async calcCoeff() {
        this._allowUpdateTrigger = false;
        const value2: number = this.getFormValue('mntemp');
        const value1: number = this.getFormValue('mntcli');

        const coeffRes = await lastValueFrom(this._calc.calculate('Coeff', {value1, value2}));

        if (coeffRes.success) {
            const coeff = coeffRes.result.coeff;
            this.setFormValue('ncoeff', coeff);
            this.setFormValue('ncoeffCli', coeff);
            this.setFormValue('ncoeffEmp', coeff);
        } else {
            WARNING(coeffRes.errorMessage);
            const coeff = 0;
            this.setFormValue('ncoeff', coeff);
            this.setFormValue('ncoeffCli', coeff);
            this.setFormValue('ncoeffEmp', coeff);
        }
        setTimeout(() => {
            this._allowUpdateTrigger = true;
        }, 0);
    }

    public async calcCoeffCli() {
        const value2: number = this.getFormValue('mntemp');
        const coeff: number = this.getFormValue('ncoeffCli');
        const coeffRes = await lastValueFrom(this._calc.calculate('Coeff', {value2, coeff}));

        if (coeffRes.success) {
            const mntcli = coeffRes.result.value1;
            this.setFormValue('mntcli', mntcli);
        } else {
            WARNING(coeffRes.errorMessage);
        }
    }

    public async calcCoeffEmp() {
        const value1: number = this.getFormValue('mntcli');
        const coeff: number = this.getFormValue('ncoeffEmp');
        const coeffRes = await lastValueFrom(this._calc.calculate('Coeff', {value1, coeff}));

        if (coeffRes.success) {
            const mntemp = coeffRes.result.value2;
            this.setFormValue('mntemp', mntemp);
        } else {
            WARNING(coeffRes.errorMessage);
        }
    }

    // eslint-disable-next-line complexity
    public async getFollow(ev: string) {
        try {
            this.grhIdName = ev;
            if (!this.pprgh) {
                return;
            }
            const grhData = await lastValueFrom(this.pprgh.getById(ev)) || '';
            this.setFormValue('libelle', grhData.grhName);
            this.grhType = grhData.grhType;
            if (grhData.followsh === 2) {
                this.follow = 'S/total';
            } else if (grhData.followsh === 3) {
                this.follow = 'S/base';
            }
            this.follow = '';
        } catch (err) {
        }
    }

    protected getFormControlNames(): FormKeys<Ppmisih> {
        return ['grhId', 'indorheu', 'misId', 'mntemp', 'ncoeffEmp', 'ncoeff', 'mntcli', 'ncoeffCli', 'libelle', 'notes'];
    }

    private updateTriggers() {
        this.triggerOnChange('mntcli', () => this.calcCoeff());
        this.triggerOnChange('mntemp', () => this.calcCoeff());
        this.triggerOnChange('ncoeffCli', () => this.calcCoeffCli());
        this.triggerOnChange('ncoeffEmp', () => this.calcCoeffEmp());
    }

    private triggerOnChange(k: keyof Ppmisih, action: () => any) {
        if (!this.formGroup.controls[k]) {
            return;
        }
        this.formGroup.controls[k].valueChanges
            .pipe(takeUntil(this.subscriptions))
            .subscribe(() => {
                if (this._allowUpdateTrigger) {
                    action();
                }
            });
    }
}
