import {SearchEntityStatusOperator} from '@app/sam-base/core';
import {SearchExactMatch} from '@app/sam-base/core/rest-api';
import {
    Ppacattestview,
    Ppcli,
    Ppclicdes,
    Ppclicdfs,
    Ppclimis,
    Ppemp,
    Ppplf,
    Pprapview
} from '@app/sam-base/models/placement';
import {loadColumnMetadataFromType} from '@sam-base/core/column';
import {Gefacliview} from '@sam-base/models/invoices/gefacliview';
import {Saempview, Sags, Sapardedview} from '@sam-base/models/salary';
import {Saemsview} from '@sam-base/models/salary/saemsview';

import {Ppaco} from '../../../models/placement/ppaco';
import {Ppagiview} from '../../../models/placement/ppagiview';
import {Ppind} from '../../../models/placement/ppind';
import {
    SearchEntityNumberOperator,
    SearchEntityStringOperator
} from '../../rest-api/models/search-entity-operator.model';
import {GridProfileData} from './grid-profile.model';

/* eslint-disable */
export const LocalPpempProfile: GridProfileData<Ppemp> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'ppemp',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Ppemp)
            .filter(col => !col.hidden),
        sordDir: 'desc'
    }
};

export const LocalPpcliProfile: GridProfileData<Ppcli> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'ppcli',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Ppcli)
            .filter(col => !col.hidden),
        sordDir: 'desc',
        filter: {}
    }
};

export const LocalPpclimisProfile: GridProfileData<Ppclimis> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'ppclimis',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Ppclimis)
            .filter(col => !col.hidden),
        sordDir: 'desc',
        filter: {}
    }
};

export const LocalPpclimisRapStatusProfile: GridProfileData<Ppclimis> = {
    userid: '',
    cfgid: 'rap_',
    name: 'rapports',
    search: '',
    entity: 'ppclimis',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: {
            0: {
                prop: 'misId',
                name: 'mission_no',
                index: 0
            },
            1: {
                prop: 'misstatustext',
                index: 1,
                type: 'misStatusRap',
                filterQuery: '',
                statusQuery: [
                    true,
                    true],
                valueFormat: 'ppmis_status_',
                filterOperator: SearchEntityStatusOperator.NotInclude
            },
            2: {
                prop: 'nomclient',
                name: 'client',
                index: 2
            },
            3: {
                prop: 'empNom',
                name: 'nomemploye',
                index: 3
            },
            4: {
                prop: 'empPrenom',
                name: 'empPrenom',
                index: 3
            },
            5: {
                prop: 'datedebut',
                name: 'debut',
                index: 4,
                type: 'date'
            },
            6: {
                prop: 'datefin',
                name: 'fin',
                type: 'date',
                index: 5
            },
            7: {
                prop: 'chantier',
                index: 6
            },
            8: {
                prop: 'cctId',
                name: 'cct',
                index: 7
            },
            9: {
                prop: 'ageId',
                name: 'agency',
                index: 8
            },
            10: {
                prop: 'nomsecteur',
                name: 'nomsecteur',
                index: 9
            }
        },
        sordDir: 'desc',
        sortBy: 'dateModif',
        filter: {}
    }
};

export const LocalPpcdeProfile: GridProfileData<Ppclicdes> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'ppclicdes',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Ppclicdes)
            .filter(col => !col.hidden),
        sordDir: 'desc',
        filter: {}
    }
};

export const LocalPpcdfProfile: GridProfileData<Ppclicdfs> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'ppclicdfs',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Ppclicdfs)
            .filter(col => !col.hidden),
        sordDir: 'desc',
        filter: {}
    }
};

export const LocalPprapProfile: GridProfileData<Pprapview> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'pprapview',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Pprapview)
            .filter(col => !col.hidden),
        sordDir: 'desc',
        sortBy: 'datelundi',
        filter: {}
    }
};

export const LocalPpplfProfile: GridProfileData<Ppplf> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'ppplf',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Ppplf)
            .filter(col => !col.hidden),
        sordDir: 'desc',
        sortBy: 'plfId',
        filter: {}
    }
};

export function pprapviewCustomProfile(salId: string, semaine: number, misId: string): GridProfileData<Pprapview> {
    return {
        userid: '',
        cfgid: '_default_',
        name: 'default',
        search: '',
        entity: 'pprapview',
        isSystem: true,
        defaultProfile: false,
        columns: {
            available: {
                0: {
                    prop: 'rapNo',
                    index: 0
                },
                1: {
                    prop: 'rapId',
                    index: 1
                },
                2: {
                    prop: 'misId',
                    index: 2,
                    filterQuery: misId,
                    filterOperator: SearchEntityStringOperator.Like
                },
                3: {
                    prop: 'salId',
                    index: 3,
                    filterQuery: salId,
                    filterOperator: SearchEntityStringOperator.Like
                },
                4: {
                    prop: 'datelundi',
                    index: 4,
                    type: 'date'
                },
                5: {
                    prop: 'semaine',
                    index: 5,
                    filterQuery: semaine.toString(),
                    filterOperator: SearchEntityNumberOperator.EqualsTo
                },
                6: {
                    prop: 'nbheutot',
                    index: 6,
                    indexed: false
                },
                7: {
                    prop: 'mntindemp',
                    index: 7,
                    indexed: false
                },
                8: {
                    prop: 'sent2fac',
                    index: 8,
                    filterQuery: 'true',
                    filterOperator: SearchExactMatch.Or
                },
                9: {
                    prop: 'sent2sal',
                    index: 9,
                    filterQuery: 'true',
                    filterOperator: SearchExactMatch.Or
                },
                10: {
                    prop: 'chantier',
                    index: 10
                },
                11: {
                    prop: 'empNom',
                    index: 11
                },
                12: {
                    prop: 'empPrenom',
                    index: 11
                },
                13: {
                    prop: 'nomclient',
                    index: 12
                }
            },
            sordDir: 'asc',
            filter: {}
        }
    };
}

export function pprapviewTabRapportsCustomProfile(misId: string): GridProfileData<Pprapview> {
    return {
        userid: '',
        cfgid: '_default_',
        name: 'default',
        search: '',
        entity: 'pprapview',
        isSystem: true,
        defaultProfile: false,
        columns: {
            available: {
                0: {
                    prop: 'rapNo',
                    index: 0
                },
                1: {
                    prop: 'rapId',
                    index: 1
                },
                2: {
                    prop: 'misId',
                    index: 2,
                    filterQuery: misId,
                    filterOperator: SearchEntityStringOperator.Like
                },
                3: {
                    prop: 'salId',
                    index: 3
                },
                4: {
                    prop: 'datelundi',
                    index: 4,
                    type: 'date'
                },
                5: {
                    prop: 'semaine',
                    index: 5
                },
                6: {
                    prop: 'nbheutot',
                    index: 6
                },
                7: {
                    prop: 'mntindemp',
                    index: 7
                },
                8: {
                    prop: 'sent2fac',
                    index: 8
                },
                9: {
                    prop: 'sent2sal',
                    index: 9
                },
                10: {
                    prop: 'chantier',
                    index: 10
                },
                11: {
                    prop: 'empNom',
                    index: 11
                },
                12: {
                    prop: 'empPrenom',
                    index: 11
                },
                13: {
                    prop: 'nomclient',
                    index: 12
                }
            },
            sordDir: 'asc',
            filter: {}
        }
    };
}

export function pprapviewDefaultProfile(entity: string): GridProfileData<Pprapview> {
    if (entity === 'ppemp') {
        return {
            userid: '',
            cfgid: '_default_',
            name: 'default',
            search: '',
            entity: 'pprapview',
            isSystem: true,
            defaultProfile: false,
            columns: {
                available: {
                    0: {
                        prop: 'rapNo',
                        index: 9
                    },
                    1: {
                        prop: 'rapId',
                        index: 1
                    },
                    2: {
                        prop: 'misId',
                        index: 0
                    },
                    4: {
                        prop: 'datelundi',
                        index: 3,
                        type: 'date'
                    },
                    5: {
                        prop: 'semaine',
                        index: 2
                    },
                    6: {
                        prop: 'nbheutot',
                        index: 6
                    },
                    7: {
                        prop: 'mntindemp',
                        index: 7
                    },
                    10: {
                        prop: 'chantier',
                        index: 8
                    },
                    11: {
                        prop: 'nomclient',
                        index: 5
                    }
                },
                sordDir: 'asc',
                filter: {}
            }
        };
    } else {
        return {
            userid: '',
            cfgid: '_default_',
            name: 'default',
            search: '',
            entity: 'pprapview',
            isSystem: true,
            defaultProfile: false,
            columns: {
                available: {
                    0: {
                        prop: 'rapNo',
                        index: 9
                    },
                    1: {
                        prop: 'rapId',
                        index: 1
                    },
                    2: {
                        prop: 'misId',
                        index: 0
                    },
                    4: {
                        prop: 'datelundi',
                        index: 3,
                        type: 'date'
                    },
                    5: {
                        prop: 'semaine',
                        index: 2
                    },
                    6: {
                        prop: 'nbheutot',
                        index: 6
                    },
                    7: {
                        prop: 'mntindemp',
                        index: 7
                    },
                    10: {
                        prop: 'chantier',
                        index: 8
                    },
                    12: {
                        prop: 'empNom',
                        index: 4
                    },
                    13: {
                        prop: 'empPrenom',
                        index: 4
                    }
                },
                sordDir: 'asc',
                filter: {}
            }
        };
    }
}

export const LocalAdvSearchProfile: GridProfileData<any> = {
    userid: '',
    cfgid: '_default_',
    name: 'advSearch',
    search: '',
    entity: 'ppemp',
    isSystem: true,
    defaultProfile: false,
    advsearch: true,
    columns: {
        available: {
            0: {
                prop: 'empId',
                index: 0,
                type: 'empStatus'
            },
            1: {
                prop: 'nom',
                index: 1
            },
            2: {
                prop: 'prenom',
                index: 2
            },
            3: {
                prop: 'profession',
                index: 3
            },
            4: {
                prop: 'regions',
                index: 4,
                hidden: false
            },
            5: {
                prop: 'nomconseiller',
                index: 5
            },
            6: {
                prop: 'email',
                index: 6
            },
            7: {
                prop: 'dateModif',
                index: 7,
                type: 'dateTime'
            },
            8: {
                prop: 'listenoir',
                name: 'listeNoire',
                index: 8,
                filterQuery: 'false',
                filterOperator: SearchExactMatch.Equals,
                hidden: true
            },
            9: {
                prop: 'matchNeedPercentage',
                hidden: false,
                index: -1
            }
        }
    }
};


export const LocalPpacatestviewDefaultProfile: GridProfileData<Ppacattestview> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'ppacattestview',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Ppacattestview)
            .filter(col => !col.hidden),
        sordDir: 'desc',
        sortBy: 'dateaca'
    }
};

export const LocalPpagiviewDefaultProfile: GridProfileData<Ppagiview> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'ppagiview',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Ppagiview)
            .filter(col => !col.hidden),
        sordDir: 'desc',
        sortBy: 'agiId'
    }
};

export const LocalPpacoDefaultProfile: GridProfileData<Ppaco> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'ppaco',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Ppaco)
            .filter(col => !col.hidden),
        sordDir: 'desc',
        sortBy: 'acoId'
    }
};

export const LocalPpindDefaultProfile: GridProfileData<Ppind> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'ppind',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Ppind)
            .filter(col => !col.hidden),
        sordDir: 'desc',
        sortBy: 'indId'
    }
};
export const localSaempviewStatusProfile: GridProfileData<Saempview> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'saempview',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Saempview)
            .filter(col => !col.hidden),
        sordDir: 'asc',
        filter: {}
    }
};

export const localSaemsviewStatusProfile: GridProfileData<Saemsview> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'saemsview',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Saemsview)
            .filter(col => !col.hidden),
        sordDir: 'desc',
        filter: {},
        sortBy: 'salId'
    }
};

export const localSapardedviewStatusProfile: GridProfileData<Sapardedview> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'sapardedview',
    isSystem: true,
    defaultProfile: false,
    columns: {
        sortBy: 'dedOrder',
        available: loadColumnMetadataFromType(Sapardedview)
            .filter(col => !col.hidden),
        sordDir: 'asc',
        filter: {}
    }
};

export const localSagsStatusProfile: GridProfileData<Sags> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'sags',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Sags)
            .filter(col => !col.hidden),
        sordDir: 'asc',
        filter: {}
    }
};

export const localGefacliviewProfile: GridProfileData<Gefacliview> = {
    userid: '',
    cfgid: '_default_',
    name: 'default',
    search: '',
    entity: 'gefacliview',
    isSystem: true,
    defaultProfile: false,
    columns: {
        available: loadColumnMetadataFromType(Gefacliview)
            .filter(col => !col.hidden),
        sordDir: 'desc',
        filter: {},
        sortBy: 'dateFac'
    }
};

export function saempViewCustomProfile(currentAnnee: string): GridProfileData<Saempview> {
    return {
        userid: '',
        cfgid: '_default_',
        name: 'default',
        search: '',
        entity: 'saempview',
        isSystem: true,
        defaultProfile: false,
        columns: {
            available: loadColumnMetadataFromType(Saempview)
                .filter(col => !col.hidden),
            sordDir: 'asc',
            filter: {},
            // wildcard: {activeYears: currentAnnee}
        }
    };
}

