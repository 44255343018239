<form *ngIf="formGroupReady"
      [formGroup]="formGroup"
      class="row">
    <div [pTooltip]="tooltip"
         class="flex flex-row">
        <iw-button (press)="onAddMisHH('H100')"
                   [isDisabled]="hasSalFacReadonly || !canAddHours"
                   id="h100"
                   text="100%"
                   width="61px"></iw-button>
        <div style="width: 20px;"></div>
        <iw-button (press)="onAddMisHH('H125')"
                   [isDisabled]="isReadonly || !canAddHours"
                   id="h125"
                   text="125%"
                   width="60px"></iw-button>
        <iw-button (press)="onAddMisHH('H150')"
                   [isDisabled]="isReadonly || !canAddHours"
                   id="h150"
                   text="150%"
                   width="60px"></iw-button>
        <iw-button (press)="onAddMisHH('H200')"
                   [isDisabled]="isReadonly || !canAddHours"
                   id="h200"
                   text="200%"
                   width="65px"></iw-button>
        <div style="width: 20px;"></div>
        <iw-button (press)="onAddMisHH('HEQ1')"
                   [isDisabled]="isReadonly || !canAddHours"
                   [text]="'equipe' | translate"
                   id="hEQ1"
                   width="80px"></iw-button>
        <iw-button (press)="onAddMisHH('HEQ2')"
                   [isDisabled]="isReadonly || !canAddHours"
                   [text]="'Eq/2'"
                   id="hEQ2"
                   width="50px"></iw-button>
        <iw-button (press)="onAddMisHH('HEQ3')"
                   [isDisabled]="isReadonly || !canAddHours"
                   [text]="'Eq/3'"
                   id="hEQ3"
                   width="50px"></iw-button>
        <div style="width: 20px;"></div>
        <!-- PCA: remove HNUI button until the calculation is clear enough. Should be 25% of an hour, and is now 60-54/54 of an hour ...

        <iw-button
          id="hNUI"
          [text]="'nuit' | translate"
          (press)="onAddMisHH('HNUI')"
          [isDisabled]="isReadonly || !canAddHours"
          width="60px"
        ></iw-button>

        -->

        <div style="width: 20px;"></div>
        <iw-button (press)="openMisHHForm('new')"
                   [isDisabled]="isReadonly || !canAddHours"
                   [text]="'autre_sup' | translate"
                   id="hAUTRE"
                   width="100px"></iw-button>
        <iw-button (press)="onAddMisheupc()"
                   [isDisabled]="isReadonly || !canAddHours"
                   [text]="'autre_eq' | translate"
                   id="hAUTREEQ"
                   width="100px"></iw-button>
    </div>
    <div class="space-block space-block-4"></div>
    <div class="col-no-padding height-200 width-96">
        <iw-rest-grid #olvMishh
                      (dataChanged)="emitSalIndChanged()"
                      (selected)="setMisHH($event)"
                      [defaultSelected]="true"
                      [queryStatements]="filterByMisId"
                      [refreshType]="typeRefreshHH"
                      [type]="typeIndHHView"
                      class="col-xs-11 padding-0"
                      id="hoursGrid"
                      style="height: 140px;"></iw-rest-grid>
    </div>
    <div class="flex flex-v flex-between">
        <div class="flex flex-v margin-left-5"
             style="height: calc(4 * 25px);">
            <iw-button #btnModAct
                       (press)="openMisHHForm('edit')"
                       [isDisabled]="disabledEditMisHH() || hasSalFacReadonly"
                       [tooltip]="'modifierAction' | translate"
                       iconClass="fas fa-pencil-alt"></iw-button>

            <iw-button #btnDelAct
                       (press)="deleteMisHH()"
                       [isDisabled]="!selectedMisHH || hasSalFacReadonly"
                       [tooltip]="'supprimerAction' | translate"
                       iconClass="fas fa-trash"
                       id="hourDelBtn"></iw-button>
        </div>
    </div>
    <div class="space-block space-block-4"></div>
    <div class="title-grid width-96">{{ 'indemnites' | translate }}</div>
    <div class="col-no-padding height-200 width-96">
        <iw-rest-grid #olvMisIh
                      (dataChanged)="emitSalIndChanged()"
                      (selected)="setMisIH($event)"
                      [defaultSelected]="true"
                      [queryStatements]="filterByMisId"
                      [refreshType]="typeRefreshHH"
                      [type]="typeIndIHView"
                      class="col-xs-11 padding-0"
                      style="height: 140px;"></iw-rest-grid>
    </div>
    <div class="flex flex-v flex-between">
        <div class="flex flex-v margin-left-5"
             style="height: calc(4 * 25px);">
            <iw-button #btnAddAct
                       (press)="createMisIh()"
                       [isDisabled]="isReadonly || !canAddHours"
                       [tooltip]="'nouvelleAction' | translate"
                       iconClass="fas fa-file"
                       id="btnAddAct"></iw-button>

            <iw-button #btnModAct
                       (press)="editMisIh()"
                       [isDisabled]="!selectedMisIH || hasSalFacReadonly"
                       [tooltip]="'modifierAction' | translate"
                       iconClass="fas fa-pencil-alt"
                       id="btnModAct"></iw-button>

            <iw-button #btnDelAct
                       (press)="deleteMisIH()"
                       [isDisabled]="!selectedMisIH || hasSalFacReadonly"
                       [tooltip]="'supprimerAction' | translate"
                       iconClass="fas fa-trash"
                       id="btnDelAct"></iw-button>
        </div>
    </div>
    <div class="space-block space-block-4"></div>
    <div *ngIf="showListMisSal"
         class="title-grid width-96">{{ 'mois' | translate }}
    </div>
    <iw-rest-grid #olvMissal
                  (dataChanged)="emitSalIndChanged()"
                  *ngIf="showListMisSal"
                  [queryStatements]="filterByMisId"
                  [type]="typeMissal"
                  class="col-no-padding width-96"
                  style="height: 60px;"></iw-rest-grid>
    <div class="col-xs-6 no-padding-left padding-right-5 padding-top-5">
        <iw-textfield [isDisabled]="true"
                      [value]="txtCoeffAdv"></iw-textfield>
    </div>
    <div class="col-xs-6 padding-left-5 padding-right-5 padding-top-5">
        <iw-textfield [isDisabled]="true"
                      [value]="txtMargeHour"></iw-textfield>
    </div>
</form>
