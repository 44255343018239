import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormHandlerService} from '@app/sam-base/core';
import {SaenfService} from '@app/sam-base/core/services/saenf.service';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {SalaryForm} from '../../../salary.forms';

@Component({
    selector: 'iw-tab-saemp-situation',
    templateUrl: './tab-saemp-situation.component.html',
    styleUrls: ['./tab-saemp-situation.component.scss']
})
export class TabSaempSituationComponent extends BasePartialFormComponent<Saempc> implements OnInit {
    constructor(private readonly _formHandler: FormHandlerService<SalaryForm>,
                private readonly _saenfService: SaenfService) {
        super();
    }

    public get saempId() {
        return this.getFormValue<string>('empId');
    }

    public get nom() {
        return this.getFormValue<string>('nom');
    }

    public get isMarried() {
        const civilStatus = this.getFormValue('etatcivil');
        if (!civilStatus) {
            return false;
        }
        return civilStatus === 'M';
    }

    public ngOnInit() {
        this.getSaenfData();
        this.subscribeValueChange('empId', () => this.setValidators());
        this.setValidators();
        this.subscribeValueChange('etatcivil', (etatcivil) => {
            const datetatciv = this.getFormValue('datetatciv');
            if ((etatcivil === 'C' || etatcivil === 'U') && !datetatciv) {
                this.setFormValue('datetatciv', this.getFormValue('datenaiss'));
            }
        });
    }

    public onBtnEnfentsClick() {
        if (!this.saempId) {
            return;
        }
        this._formHandler.showFormDialog(SalaryForm.EmployeEnf, {
            empId: this.saempId,
            nom: this.nom
        });
    }

    public onBtnConjointClick() {
        if (!this.saempId) {
            return;
        }
        this._formHandler.showFormDialog(SalaryForm.EmployeConjoint, {
            empId: this.saempId,
            empNom: this.nom
        });
    }

    private getSaenfData() {
        if (!this.saempId) {
            return;
        }
        this._saenfService.updateSaenf(this.saempId);
    }

    private setValidators() {
        const codeImp = this.getFormValue<number>('codeImp') || 0;
        if (this.isMarried && codeImp > 1) {
            this.formGroup?.controls['conjNom'].setValidators(Validators.required);
            this.formGroup?.controls['conjprenom'].setValidators(Validators.required);
            this.formGroup?.controls['conjNaiss'].setValidators(Validators.required);

            this.formGroup?.controls['conjNom'].updateValueAndValidity();
            this.formGroup?.controls['conjprenom'].updateValueAndValidity();
            this.formGroup?.controls['conjNaiss'].updateValueAndValidity();

        } else {
            this.formGroup?.controls['conjNom'].clearValidators();
            this.formGroup?.controls['conjprenom'].clearValidators();
            this.formGroup?.controls['conjNaiss'].clearValidators();

            this.formGroup?.controls['conjNom'].updateValueAndValidity();
            this.formGroup?.controls['conjprenom'].updateValueAndValidity();
            this.formGroup?.controls['conjNaiss'].updateValueAndValidity();

        }
    }
}
