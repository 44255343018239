<div class="row">
    <div class="col-xs-10" style="height: 300px;">
        <iw-rest-grid #ppgrhConfigGrid (selected)="getSelectedRow($event)" [autoFormClick]="false"
                      [defaultSelected]="true" [hasGridColumnMenu]="false" [queryStatements]="query"
                      [queryStatements]="query" [selectedData]="selectedData" [type]="type"></iw-rest-grid>
    </div>

    <div class="col-xs-2 flex flex-v flex-evenly">
        <!--  This stays here if we ever want to add a new entry -->
        <!-- <iw-button [text]="'sidemenu_new' | translate"
          [isDisabled]="!isReadonly"
          (press)="createNewEntry()"></iw-button> -->
        <iw-button (press)="modifyEntry()" *ngIf="isReadonly" [isDisabled]="!isReadonly || !selectedRow"
                   [text]="'sidemenu_edit' | translate"></iw-button>
        <iw-button (press)="saveEntry()" *ngIf="!isReadonly" [text]="'sidemenu_save' | translate"></iw-button>
        <!--  This stays here if we ever want to delete entry -->
        <!-- <iw-button [text]="'sidemenu_delete' | translate"  *ngIf="isReadonly"
          [isDisabled]="!isReadonly || !selectedRow"
          (press)="removeEntry()"></iw-button> -->
        <iw-button (press)="cancelEditionMode()" *ngIf="!isReadonly" [text]="'sidemenu_cancel' | translate"></iw-button>
    </div>

</div>

<form [formGroup]="formGroup" class="row no-padding">
    <div class="row col-xs-10 no-padding">
        <iw-textfield #txtGrh_id [isDisabled]="true" [labelAlign]="'top'" [label]="'code' | translate"
                      class="iw-input col-xs-2" formControlName="grhId"></iw-textfield>
        <iw-textfield #txtGrh_Name [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'libelle' | translate"
                      class="iw-input col-xs-10" formControlName="grhName"></iw-textfield>
        <iw-enum-dropdown #cboGrh_Type [inputEnum]="ppgrhtype" [isDisabled]="isReadonly" [labelAlign]="'top'"
                          [label]="'type' | translate" class="col-xs-11" formControlName="grhType"></iw-enum-dropdown>
        <iw-textfield #txtGrh_id [isDisabled]="true" [value]="getFormValue('grhType')"
                      class="iw-input col-xs-1 flex-end"></iw-textfield>
        <iw-sags-dropdown #cboGs_id [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'genreSalaire' | translate"
                          [types]="['1', '3', 'B', 'C']" class="col-xs-9" formControlName="gsId"></iw-sags-dropdown>
        <iw-textfield-compte #txtCompte [isDisabled]="isReadonly || !['1', '4', '5'].includes(getFormValue('grhType'))"
                             [labelAlign]="'top'" [label]="'compte' | translate" class="iw-input col-xs-3"
                             formControlName="compte"></iw-textfield-compte>
        <iw-textfield #tctGSprovisions [isDisabled]="true" [labelAlign]="'top'" [label]="'gsProvisions' | translate"
                      [value]="getGSprovisions()" class="iw-input col-xs-12"></iw-textfield>
        <iw-textfield #txtFacteur [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'facteur' | translate"
                      [type]="'number'" class="iw-input col-xs-2" formControlName="facteur"></iw-textfield>
        <iw-dropdown #cboFact2gs [isDisabled]="isReadonly" [options]="cboFact2gsOptions" class="col-xs-7 flex-end"
                     formControlName="fact2Gs"></iw-dropdown>
        <iw-textfield #txtShortlib [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'abreviation' | translate"
                      class="iw-input col-xs-3" formControlName="shortlib"></iw-textfield>
    </div>
    <div class="row col-xs-2 no-padding">
        <iw-button #Closebutton (press)="closeDialog()" [text]="'fermer' | translate"
                   class="iw-button col-xs-12 flex-end"></iw-button>
    </div>
</form>
