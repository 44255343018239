<form [formGroup]="formGroup" class="row">
    <div class="iw-single-label col-xs-1 padding-top-15">15.</div>
    <div class="iw-single-label col-xs-11 padding-top-15 padding-bottom-10">
        {{ 'activityContinue' | translate }}
    </div>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-checkbox #Standardlogic1 (valueChange)="setTimeInd($event, 'lactgoon')" [isDisabled]="isReadonly"
                 [label]="'indefinitePeriod' | translate" class="iw-input col-xs-11 padding-top-15 padding-bottom-15"
                 formControlName="lactgoon"></iw-checkbox>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-checkbox #Standardlogic2 (valueChange)="setUntil($event, 'lactgoond')" [isDisabled]="isReadonly"
                 [label]="'probablyUntil' | translate" class="iw-input col-xs-4 padding-top-15"
                 formControlName="lactgoond"></iw-checkbox>
    <iw-date-picker #txtUntil [isDisabled]="isReadonly || !until" [isDisabled]="isReadonly"
                    class="iw-input col-xs-3 padding-top-15" formControlName="dactgoond"></iw-date-picker>
    <div class="iw-single-label col-xs-4 padding-top-15"></div>

    <div class="iw-single-label col-xs-1 padding-top-10"></div>
    <iw-checkbox #Standardlogic3s (valueChange)="setCancel($event, 'lactnogo')" [isDisabled]="isReadonly"
                 [label]="'whoCanceled' | translate" class="iw-input col-xs-4 padding-top-10"
                 formControlName="lactnogo"></iw-checkbox>
    <iw-textfield #txtCancel [isDisabled]="isReadonly || !cancel" class="iw-input col-xs-3 padding-top-10"
                  formControlName="cactnogoq"></iw-textfield>
    <div class="iw-single-label col-xs-4 padding-top-15"></div>

    <div class="iw-single-label col-xs-2 padding-top-10"></div>
    <div class="iw-single-label col-xs-1 padding-top-15">
        {{ 'when' | translate }}
    </div>
    <div class="iw-single-label col-xs-2 padding-top-10"></div>
    <iw-textfield #txtWhen [isDisabled]="isReadonly || !cancel" class="iw-input col-xs-3 padding-top-10"
                  formControlName="cactnogow"></iw-textfield>
    <div class="iw-single-label col-xs-4 padding-top-15"></div>

    <div class="iw-single-label col-xs-2 padding-top-10"></div>
    <div class="iw-single-label col-xs-2 padding-top-15">
        {{ 'whatDate' | translate }}
    </div>
    <div class="iw-single-label col-xs-1 padding-top-10"></div>
    <iw-textfield #txtDate [isDisabled]="isReadonly || !cancel" class="iw-input col-xs-3 padding-top-10"
                  formControlName="cactnogop"></iw-textfield>
    <div class="iw-single-label col-xs-4 padding-top-15"></div>

    <div class="iw-single-label col-xs-1 padding-top-15">16.</div>
    <div class="iw-single-label col-xs-11 padding-top-15 padding-bottom-10">
        {{ 'terminatingRelationship' | translate }}
    </div>

    <div class="iw-single-label col-xs-1 padding-top-15"></div>
    <iw-textarea #txtParticipation [height]="80" [isDisabled]="isReadonly || !cancel" class="iw-textarea col-xs-10"
                 formControlName="cactnogom" style="padding-left: 7px; padding-right: 7px"></iw-textarea>
    <div class="iw-single-label col-xs-1 padding-top-15"></div>

    <div class="iw-single-label col-xs-1 padding-top-15">17.</div>
    <div class="iw-single-label col-xs-11 padding-top-15">
        {{ 'leadingPosition' | translate }}
    </div>

    <div class="iw-single-label col-xs-1 padding-top-15">18.</div>
    <div class="iw-single-label col-xs-11 padding-top-15">
        {{ 'incomeGainful' | translate }}
    </div>
    <div class="iw-single-label col-xs-1 padding-top-5"></div>
    <iw-textfield #txtActivity [decimals]="2" [isDisabled]="isReadonly" class="iw-input col-xs-8 padding-top-5"
                  formControlName="revactind" type="number"></iw-textfield>
    <div class="iw-single-label col-xs-3 padding-top-5"></div>
</form>
