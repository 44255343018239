import {Component, OnInit, Type} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {IwEventHubService} from '@app/sam-base/core/events';
import {StoreEvents} from '@app/sam-base/core/events/actions/store.actions';
import {RestEntityClient} from '@app/sam-base/core/rest-api/entity/rest-entity-client';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {EntityNavigationService} from '@app/sam-base/core/services/entity-navigation.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys, ObjectKeyArrayMap} from '@app/sam-base/models';
import {IwGridColumn} from '@app/sam-base/models/components/grid-column.model';
import {Ppcde, Ppcli, Ppclicdes, Ppclimis, Ppemp, Ppmis, Ppmismdl} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {PpconService} from '@sam-base/core';
import {ClientStatus} from '@sam-base/models/placement/client-status';
import {EmployeeStatus} from '@sam-base/models/placement/employee-status';
import {camelCase, mapKeys} from 'lodash';
import {lastValueFrom} from 'rxjs';

import {MissionService} from '../../../services/mission.service';
import {Misnew} from './misnew.model';

@Component({
    templateUrl: './misnew.component.html'
})
export class MisnewComponent extends BaseStoreFormComponent<Misnew> implements OnInit {

    public entityCde = Ppclicdes;
    public entityEmploye = Ppemp;
    public entityClient = Ppcli;
    public entityMismdl = Ppmismdl;
    public filterPpemp?: ObjectKeyArrayMap<Ppemp>;
    public filterPpcli?: ObjectKeyArrayMap<Ppcli>;
    public filterCde?: ObjectKeyArrayMap<Ppclicdes>;
    public type: Type<Ppmismdl> = Ppmismdl;
    public columns = this.getMismdlColumns();
    public isLoading = false;
    public missionModelData: Ppmismdl[] = [];
    private readonly _ppcliRest: RestEntityClient<Ppcli>;
    private readonly _ppempRest: RestEntityClient<Ppemp>;
    private readonly _ppcdeRest: RestEntityClient<Ppcde>;

    constructor(store: IwStoreService, _restService: RestApiService, private _translate: TranslateService,
                private readonly _modalService: ModalService, private readonly missionService: MissionService,
                private _entityNavService: EntityNavigationService,
                private readonly _events: IwEventHubService<StoreEvents>, private _ppconService: PpconService) {
        super(store);

        this._ppcliRest = _restService.getEntityClient(Ppcli);
        this._ppempRest = _restService.getEntityClient(Ppemp);
        this._ppcdeRest = _restService.getEntityClient(Ppcde);
    }

    public get isCreatingModel(): boolean {
        return this.getFormValue('createmodel');
    }

    public get cliId() {
        return this.getData('cliId', false);
    }

    public get cdeId() {
        return this.getData('cdeId', false);
    }

    public get conId() {
        return this.getData('conId', false);
    }

    public get empId() {
        return this.getData('empId', false);
    }

    public resetForm() {
        this.clearForm();
    }

    public ngOnInit() {
        this.setFormValue('conId', this.conId);
        this.setFormValue('cliId', this.cliId);
        this.setFormValue('cdeId', this.cdeId);
        this.setFormValue('empId', this.empId);
        this.filterPpemp = {
            cempstatus: [
                EmployeeStatus.NEW,
                EmployeeStatus.INACTIVE_WITH_SUIVI,
                EmployeeStatus.INACTIVE_HAS_WORKED,
                EmployeeStatus.IN_MISSION]
        };
        this.filterCde = {cdeType: ['cde']};
        this.filterPpcli = {
            clistatus: [
                ClientStatus.NEW,
                ClientStatus.ACTIVE]
        };
        this.missionService.getAvailableModels()
            .subscribe((data: Ppmismdl[]) => this.missionModelData = data);
        this.subscribeValueChange('cliId', (id: string | undefined) => {
            this.missionService.getAvailableModels(id)
                .subscribe((data: Ppmismdl[]) => this.missionModelData = data);
        });
        if (this.isNew && !this.getFormValue('conId')) {
            this._ppconService.fillMainConseiller(this.getFormControl('conId'));
        }

        this.subscribeValueChange('createmodel', isCreatingModel => {
            const modelMissionFormControl = this.getFormControl('modelTitle');
            if (isCreatingModel) {
                modelMissionFormControl?.setValidators([Validators.required]);
            } else {
                modelMissionFormControl?.clearValidators();
            }
            modelMissionFormControl?.updateValueAndValidity();
        });

    }

    // eslint-disable-next-line complexity
    public async submitMission() {
        let ok2add = false;
        let cli;
        let emp;
        const messages: string[] = [];
        // eslint-disable-next-line complexity
        if (this.getFormValue('cliId') && this.getFormValue('empId')) {
            cli = await lastValueFrom(this._ppcliRest.getById(this.getFormValue('cliId')));
            emp = await lastValueFrom(this._ppempRest.getById(this.getFormValue('empId')));
            ok2add = true;
            if (cli.clistatus === 3) {
                messages.push(this._translate.instant('clientBlocked') + '!');
                ok2add = false;
            }
            if (emp.cempstatus === '8') {
                messages.push(this._translate.instant('empBlocked') + '!');
                ok2add = false;
            }
            if (cli.clistatus !== 3 && emp.aptId !== '' && emp.cempstatus !== '8') {
                if (emp.cempstatus === '9') {
                    messages.push(this._translate.instant('empInactive'));
                }
                if (ok2add && emp.noavs13 === '') {
                    messages.push(this._translate.instant('empNSS'));
                }
                if (emp.permis !== '' && (emp.permisno === '' || (emp.permDta === '' && (IwDateHelper.getMoment(emp.permDta) < IwDateHelper.addToDate(1, 'M'))))) {
                    messages.push(this._translate.instant('noPermNoDate'));
                }
                if (emp.misId) {
                    messages.push(this._translate.instant('empInMission'));
                }
                if (cli.clistatus === 2) {
                    messages.push(this._translate.instant('clientInactive'));
                }
            }
        }
        this.showModal(messages, ok2add);
    }

    public async showModal(messages: string[], ok2add: boolean) {
        const options: ModalMessageOptions = {
            message: messages,
            showCancel: true,
            title: this._translate.instant('createMission'),
            confirmMessage: ok2add ? this._translate.instant('continueCreateMission') + '?' : '',
            alertsMessage: this._translate.instant('alertsmsg'),
            okDisabled: !ok2add
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this.isLoading = true;
            this.createMission();
        } catch (error) {
            this.isLoading = false;
        }
    }

    public selectClientAndCons(event: string) {
        if (event) {
            this.isLoading = true;
            this._ppcdeRest.getById(event)
                .subscribe({
                    next: (cde) => {
                        this.setFormValue('cliId', cde.cliId);
                        this.setFormValue('conId', cde.conId);
                        this.isLoading = false;
                    },
                    error: () => {
                        this.isLoading = false;
                    }
                });
        }
    }

    public selectMissionModel(event: Ppmismdl[]) {
        if (event[0]) {
            this.setFormValue('mismdlId', event[0].mismdlId);
        }
    }

    public createMission() {
        const mission: Misnew | undefined = {
            ...this.getFormData()
        };

        if (!this.getFormValue('cpyfrommdl')) {
            mission.mismdlId = undefined;
        }
        if (!mission) {
            return;
        }
        this.missionService.createMission(mission)
            .subscribe({
                next: (mis: Ppmis) => {
                    const newMis: Ppmis = mapKeys(mis, (_, key) => camelCase(key));
                    if (!newMis || !newMis.misId) {
                        return;
                    }

                    this._entityNavService
                        .navigateToEntityForm(Ppclimis, newMis.misId, this.getData('profile'), 'edit');
                    this._events
                        .emit(StoreEvents.EntitySaved, {$entity: 'ppmis', ...mis});
                    this.isLoading = false;
                    this.destroyForm();
                },
                error: () => {
                    this.isLoading = false;
                }
            });

    }

    public formFullfilled() {
        return this.formGroup.valid;
    }

    public uncheckOtherboxes(event: boolean, checkboxType: string) {
        // eslint-disable-next-line complexity
        if (!event) {
            return;
        }
        switch (checkboxType) {
            case 'cpyfrommdl':
                this.setFormValue('createmodel', false);
                break;
            case 'createmodel':
                this.setFormValue('cpyfrommdl', false);
                break;
            default:
                break;
        }
    }

    public canCopyModel() {
        if (this.getFormValue('cliId')) {
            return true;
        }
        this.setFormValue('cpyfrommdl', false);
        return false;
    }

    public canCreateModel() {
        if (!this.getFormValue('cdeId') && this.getFormValue('cliId')) {
            return true;
        }
        this.setFormValue('createmodel', false);
        return false;
    }

    public isCopyModelSelected() {
        return this.getFormValue('cpyfrommdl');
    }

    protected getFormControlNames(): FormKeys<Misnew> {
        return [
            'cdeId',
            [
                'empId',
                new UntypedFormControl('', [Validators.required])],
            [
                'cliId',
                new UntypedFormControl('', [Validators.required])],
            [
                'conId',
                new UntypedFormControl('', [Validators.required])],
            'cpyfrommdl',
            'createmodel',
            'mismdlId',
            'modelTitle',
        ];
    }

    private getMismdlColumns(): IwGridColumn<Ppmismdl>[] {
        return [
            {
                prop: 'mismdlLib',
                name: this._translate.instant('missionMdlLib'),
                index: 0
            },
            {
                prop: 'misId',
                name: 'Id',
                index: 1
            }];
    }
}
