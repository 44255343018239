import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwGridColumn} from '@sam-base/models';
import {Ppacattest} from '@sam-base/models/placement';
import {Cac3} from '@sam-base/models/placement/attestEmp';

@Component({
    selector: 'iw-tab-ppacattest-periodes', templateUrl: './tab-ppacattest-periodes.component.html'
})

export class TabPpacattestPeriodesComponent extends BasePartialFormComponent<Ppacattest> {

    @Input() public isReadonly = true;

    @Input() public cac3: Cac3[] = [];

    public customColumns = this.getColumns();

    constructor() {
        super();
    }

    /* Columns to show in  table */
    private getColumns(): IwGridColumn<Cac3>[] {
        return [{
            prop: 'salId', name: 'periode', index: 0
        }, {
            prop: 'salavs', name: 'salavs', decimals: 2, index: 1
        }, {
            prop: 'nbheu', name: 'heures', decimals: 2, index: 2
        }, {
            prop: 'salhor', name: 'nbrate', decimals: 2, index: 3
        }, {
            prop: 'firstDay', name: 'start', index: 4
        }, {
            prop: 'lastDay', name: 'datefin', index: 5
        }, {
            prop: 'nbrap', name: 'nb_rap', index: 6
        }];
    }

}
