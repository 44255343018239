<div class="row">
    <div class="col-xs-10" style="height: 300px;">
        <iw-rest-grid #ppjfConfigGrid (selected)="getSelectedRow($event)" (sortInfo)="setSortInfo($event)"
                      [autoFormClick]="false" [defaultSelected]="true" [hasFilter]="true" [hasGridColumnMenu]="false"
                      [hasSortIcon]="true" [queryStatements]="query" [queryStatements]="query"
                      [selectedData]="selectedData" [sortDir]="sortPropDir" [sortProp]="sortProp"
                      [type]="type"></iw-rest-grid>
    </div>

    <div class="col-xs-2 flex flex-v flex-evenly">
        <ng-container *ngIf="isReadonly">
            <iw-button (press)="createNewEntry()" [isDisabled]="!isReadonly || !selectedRow?.jfId"
                       [text]="'sidemenu_new' | translate" id="sidemenu_new" name="nouveau"></iw-button>
            <iw-button (press)="modifyEntry()" [isDisabled]="!isReadonly || !selectedRow?.jfId"
                       [text]="'sidemenu_edit' | translate" id="sidemenu_edit"></iw-button>
            <iw-button (press)="removeEntry()" [isDisabled]="!isReadonly || !selectedRow?.jfId"
                       [text]="'sidemenu_delete' | translate" id="sidemenu_delete"></iw-button>
        </ng-container>

        <ng-container *ngIf="!isReadonly">
            <iw-button (press)="saveEntry()" [isDisabled]="isReadonly || !formGroup.valid"
                       [text]="'sidemenu_save' | translate" id="sidemenu_save" name="save"></iw-button>
            <iw-button (press)="cancelEditionMode()" [isDisabled]="isReadonly" [text]="'sidemenu_cancel' | translate"
                       id="sidemenu_cancel"></iw-button>
        </ng-container>
    </div>

</div>

<form [formGroup]="formGroup" class="row">

    <iw-date-picker #txtJfdate [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'date' | translate"
                    [showWeekDay]="true" class="iw-input col-xs-4" formControlName="jfdate"></iw-date-picker>

    <iw-textfield #txtJflibelle [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'libelle' | translate"
                  class="iw-input col-xs-6" formControlName="jflibelle"></iw-textfield>

    <iw-button #Closebutton (press)="closeDialog()" [text]="'fermer' | translate"
               class="iw-button col-xs-2 flex-end"></iw-button>
</form>
