export enum OrdreElems {
    employe = 1, semaine = 2, rapport = 3, chantier = 4,
}

export enum ModePreparation {
    chantier_automatique = 1, mode_client_force = 2, mode_client_grp_mis = 3,
}

export enum RegroupMis {
    vref = 1, chantier2 = 2, tag = 3, adrfac = 4,
}

export enum FacturationPause {
    separe = 1, groupe = 2, separe_coeff_const = 3,
}

export enum OrdreDesElements {
    employe = 1, semaine = 2, rapport = 3,
}

export enum ModeChantier {
    chantier = 1, chantier_employe = 2,
}

export enum Mode {
    standard = 1, selon_texte = 2,
}
