<form [formGroup]="formGroup" class="row">
    <iw-textfield (valueChange)="checkGsExists($event)" [isDisabled]="!isWriteMode || !isNew" [noLabel]="true"
                  class="iw-textfield col-xs-3 no-padding-left" formControlName="gsId" id="sags-id"></iw-textfield>
    <iw-textfield [isDisabled]="!isWriteMode" [noLabel]="true" class="iw-textfield col-xs-7 no-padding-right"
                  formControlName="titre" id="sags-title"></iw-textfield>
    <iw-textfield *ngIf="version" [isDisabled]="true" [noLabel]="true" [value]="version"
                  class="iw-textfield col-xs-2 no-padding-right" id="sags-version"></iw-textfield>
</form>

<iw-tab [(selectedTab)]="selectedTab" [invalidTabs]="invalidTabList" [tabs]="tabList">
    <iw-tab-sags-donnes-base *ngIf="selectedTab === tabList[0]" [formGroup]="formGroup"
                             [isReadonly]="!isWriteMode"></iw-tab-sags-donnes-base>
    <iw-tab-sags-autre *ngIf="selectedTab === tabList[1]" [formGroup]="formGroup"
                       [isReadonly]="!isWriteMode"></iw-tab-sags-autre>
    <iw-tab-sags-swissdec *ngIf="selectedTab === tabList[2]" [formGroup]="formGroup"
                          [isReadonly]="!isWriteMode"></iw-tab-sags-swissdec>
    <iw-tab-sags-version *ngIf="selectedTab === tabList[3] && version === 0" [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"></iw-tab-sags-version>
</iw-tab>
