<form [formGroup]="formGroup" class="row">
    <div class="col-xs-6">

        <iw-sags-dropdown #cboSags100 [labelAlign]="'left'" [label]="'nbheu100' | translate" class="iw-input col-xs-12"
                          formControlName="sags100"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSags125 [labelAlign]="'left'" [label]="'nbheu125' | translate" class="iw-input col-xs-12"
                          formControlName="sags125"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSags150 [labelAlign]="'left'" [label]="'nbheu150' | translate" class="iw-input col-xs-2"
                          formControlName="sags150"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSags200 [labelAlign]="'left'" [label]="'nbheu200' | translate" class="iw-input col-xs-2"
                          formControlName="sags200"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSaGsXxx [labelAlign]="'left'" [label]="'hX' | translate" class="iw-input col-xs-2"
                          formControlName="sagsxxx"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSaGsEquip [labelAlign]="'left'" [label]="'heurEquipe' | translate"
                          class="iw-input col-xs-2" formControlName="sagsequip"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSagsequix [labelAlign]="'left'" [label]="'heurEquipeX' | translate"
                          class="iw-input col-xs-2" formControlName="sagsequix"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSagsnuit [labelAlign]="'left'" [label]="'heurNuit' | translate" class="iw-input col-xs-2"
                          formControlName="sagsnuit"></iw-sags-dropdown>
        <iw-sags-dropdown #cbosagspause [labelAlign]="'left'" [label]="'heurPause' | translate"
                          class="iw-input col-xs-2" formControlName="sagspause"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSagsfrais1 [labelAlign]="'left'" [label]="'frais_horaire_1' | translate"
                          class="iw-input col-xs-2" formControlName="sagsfrais1"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSagsfrais2 [labelAlign]="'left'" [label]="'frais_horaire_2' | translate"
                          class="iw-input col-xs-2" formControlName="sagsfrais2"></iw-sags-dropdown>
    </div>

    <div class="col-xs-6 separator-left">

        <iw-sags-dropdown #cbosagsjfhor [labelAlign]="'left'" [label]="'jf%' | translate" class="iw-input col-xs-2"
                          formControlName="sagsjfhor"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSaGsVac [labelAlign]="'left'" [label]="'vacances' | translate" class="iw-input col-xs-2"
                          formControlName="sagsvac"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSaGs13 [labelAlign]="'left'" [label]="'13_salaire' | translate" class="iw-input col-xs-2"
                          formControlName="sags13"></iw-sags-dropdown>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-sags-dropdown #cboSagsjf [labelAlign]="'left'" [label]="'jours_feries' | translate"
                          class="iw-input col-xs-2" formControlName="sagsjf"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSagshlpp [labelAlign]="'left'" [label]="'heuresLpp' | translate" class="iw-input col-xs-2"
                          formControlName="sagshlpp"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSaGsOP [labelAlign]="'left'" [label]="'op' | translate" class="iw-input col-xs-2"
                          formControlName="sagsop"></iw-sags-dropdown>
        <iw-sags-dropdown #cboSaGsMois [labelAlign]="'left'" [label]="'salaireMensuel' | translate"
                          class="iw-input col-xs-2" formControlName="sagsmois"></iw-sags-dropdown>

    </div>
</form>