import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppheusaid} from '@app/sam-base/models/placement';

import {
    Ppheusaid2Component
} from '../components/ppheusaid2/ppheusaid2.component';

export const PPHEUSAID2DEF_DEFAULT_FORM: FormDescription<Ppheusaid> = {
    form: Ppheusaid2Component, state: {
        ...buildDefaultState(Ppheusaid), diagTitle: 'ppheusaid2_form_title', isNew: true, diagModal: true, width: 1100
    }
};
