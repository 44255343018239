<button (click)="debug()">DEBUG</button>

<form [formGroup]="formGroup" class="row space-form">
    <iw-busy [isBusy]="isLoading"></iw-busy>
</form>

<iw-tab [(selectedTab)]="selectedTab" [invalidTabs]="invalidTabList" [tabs]="tabList">

    <iw-tab-sasx5institution-general *ngIf="selectedTab?.title === 'general'"
                                     [formGroup]="formGroup"></iw-tab-sasx5institution-general>
    <iw-tab-sasx5institution-dialog *ngIf="selectedTab?.title === 'dialog'"
                                    [formGroup]="formGroup"></iw-tab-sasx5institution-dialog>

    <iw-tab-sasx-xml *ngIf="selectedTab?.title === 'xml'" [xmlContent]="xml"></iw-tab-sasx-xml>


</iw-tab>
