import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Glcentre} from '@sam-base/models/accounting/glcentre';
import {Observable} from 'rxjs';

import {RestClient} from '../rest-api/core';

@Injectable()
export class GlcentreService extends RestClient<any> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getGlcentreData(centre: string): Observable<Glcentre[]> {
        return this._http.get<Glcentre[]>(this._baseUrl + `glcentre/type/${centre}`);
    }
}
