<form [formGroup]="formGroup" class="row">
    <iw-textfield-compte #txtCompte [isDisabled]="isReadonly" [labelAlign]="'left'"
                         [label]="'comptesalaire' | translate" class="iw-input col-xs-6 padding-top-15"
                         formControlName="compteNap"></iw-textfield-compte>

    <iw-checkbox #Standardlogic1 [isDisabled]="isReadonly" [labelAlign]="'right'"
                 [label]="'repartitionAnalytique' | translate" class="iw-input col-xs-6" formControlName="anNap"
                 id="anNap" style="height: 56px"></iw-checkbox>

    <div class="col-xs-6"></div>
    <iw-checkbox #Standardlogic1 [isDisabled]="isReadonly" [labelAlign]="'right'"
                 [label]="'repartitionAnalytiqueObli' | translate" class="iw-input col-xs-6"
                 formControlName="cptaanobli"></iw-checkbox>
</form>
