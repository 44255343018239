import {Injectable} from '@angular/core';
import {GridProfile} from '@app/sam-base/core/grid-profile/models';
import {
    localGefacliviewProfile,
    localSaempviewStatusProfile,
    localSaemsviewStatusProfile,
    localSagsStatusProfile,
    localSapardedviewStatusProfile
} from '@app/sam-base/core/grid-profile/models/grid-profile-default';
import {AddLocalProfile} from '@app/sam-base/core/store/actions/grid-profiles.actions';
import {IwStoreService} from '@app/sam-base/core/store/iw-store.service';

/** Component developed in: https://samredesign.atlassian.net/browse/SAM-4290 */

@Injectable()
export class SalaryLocalProfileService {
    constructor(private readonly _store: IwStoreService) {
    }

    public getLocalProfiles() {
    }

    public setLocalProfiles() {
        const defaultSaempviewProf = localSaempviewStatusProfile;
        const defaultSaemsviewProf = localSaemsviewStatusProfile;
        const defaultSapardedviewProf = localSapardedviewStatusProfile;
        const defaultSagsProf = localSagsStatusProfile;
        const defaultGefacliviewProf = localGefacliviewProfile;

        this._store
            .dispatch(new AddLocalProfile([new GridProfile(defaultSaempviewProf),
                new GridProfile(defaultSaemsviewProf),
                new GridProfile(defaultSapardedviewProf),
                new GridProfile(defaultSagsProf),
                new GridProfile(defaultGefacliviewProf)]));
    }
}
