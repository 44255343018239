export enum CodeCna {
    automatique = 1, manuel = 2,
}

export enum CodePgm {
    automatique = 1, manuel = 2, automatique_mis = 3,
}

export enum ModeCantonImpot {
    sel_age = 1, manuel = 2, sel_cant_hab = 3,
}

export enum RapLibMode {
    no_rap = 1,
    client_semaine = 2,
    client_semaine_mission = 3,
    semaine_mission = 4,
    semaine = 5,
    semaine_date = 6,
    semaine_date_travail = 7,
    semaine_date_lundi = 8,
    semaine_norap = 9,
    semaine_norap_client = 10,
    norap_chantier = 11,
}

export enum ModeLpp {
    swissStaf = 2, lpp540 = 3, lpp_baloise = 4, lpp_pro_mensuel = 5, lpp_pro_13 = 6, lpp_aucun = 1,
}

export enum Salgenfreq {
    tous = 1, mois_per = 2, semaine = 3, calendaire = 4,
}

export enum OrdreDesElements {
    employe = 1, semaine = 2, rapport = 3,
}

export enum ModeChantier {
    chantier = 1, chantier_employe = 2,
}

export enum Mode {
    standard = 1, selon_texte = 2,
}
