import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('gepaieleview')
export class GepaieleView {
    @IwRestEntityId()
    @IwColumn({
        name: 'payeleId',
        index: 0
    })
    public payeleId?: string;

    @IwColumn({
        name: 'payCliId',
        index: 1
    })
    public payCliId?: string;

    @IwColumn({
        name: 'payId',
        index: 2
    })
    public payId?: string;

    @IwColumn({
        name: 'facNo',
        index: 3
    })
    public facNo?: string;

    @IwColumn({
        name: 'facId',
        index: 4
    })
    public facId?: string;

    @IwColumn({
        name: 'lettrage',
        index: 5
    })
    public lettrage?: string;

    @IwColumn({
        name: 'origine',
        index: 6
    })
    public origine?: string;

    @IwColumn({
        name: 'montant',
        index: 7
    })
    public montant?: number;

    @IwColumn({
        name: 'payCliId2',
        index: 8
    })
    public payCliId2?: string;

    @IwColumn({
        name: 'mpaId',
        index: 9
    })
    public mpaId?: string;

    @IwColumn({
        name: 'cliId',
        index: 10
    })
    public cliId?: string;

    @IwColumn({
        name: 'nom',
        index: 11
    })
    public nom?: string;

    @IwColumn({
        name: 'userId',
        index: 12
    })
    public userId?: string;

    @IwColumn({
        name: 'compte',
        index: 13
    })
    public compte?: string;
}
