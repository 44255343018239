export enum ConstrutionAdresse {
    contact_fin = 1, apres_nom = 2,
}

export enum SaiseRapports {
    verticale = 1, horizontale = 2,
}

export enum calcMode {
    sans_tenir_compte = 1, tenant_compte = 2,
}

export enum calcSalaire {
    salaire_global = 1, salaire_base = 2,
}

export enum calcLPP {
    desactive = 1, swiss_staffing = 2, vaudoise = 3, bloise = 4, pro = 5, pro_13 = 6,
}
