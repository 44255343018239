import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable, Subject} from 'rxjs';
import {RestClient} from '../rest-api/core';

export interface PpgriSubscrition {
    uuid: string;
    saved: boolean;
}


@Injectable()
export class PpgriService extends RestClient<any> {

    public ppgriSub: Subject<PpgriSubscrition> = new Subject();

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public delete(griId: string): Observable<void> {
        return this._http.delete<void>(this._baseUrl + `ppgri/delete/${griId}`);
    }
}
