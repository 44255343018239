import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {
    ParametresGenerauxFacturationComponent
} from '@modules/sam-main/salary/components/parametres-generaux-facturation/parametres-generaux-facturation.component';
import {Gepar} from '@sam-base/models/invoices';

export const GEPAR_DEFAULT_FORM: FormDescription<Gepar> = {
    form: ParametresGenerauxFacturationComponent, state: {
        ...buildDefaultState(Gepar),
        diagTitle: 'Facturation - Paramètres généraux',
        width: 600,
        diagShowSideMenu: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true
        },
        editMode: 'read'
    }
};
