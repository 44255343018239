import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppgrh} from '@app/sam-base/models/placement';

import {PpgrhConfigComponent} from '../components/ppgrh-config/ppgrh-config.component';

export const PPGRH_DEFAULT_FORM: FormDescription<Ppgrh> = {
    form: PpgrhConfigComponent, state: {
        ...buildDefaultState(Ppgrh), diagModal: true, diagTitle: 'ppgrh_form_title', width: 1000, isNew: true
    }
};
