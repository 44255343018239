import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';


@IwRestEntity('saisdef')
export class Saisdef {
    @IwRestEntityId() public canton?: string;
    public annee?: string;
    public isbaremes?: string;
    public isbarc3?: string;
    public isnbheures?: number;
    public isnbjours?: number;
    public isnordre?: string;
    public iscommper?: number;
    public modcalcimp?: number;
    public modanzdef?: number;
    public bardAuto?: number;
    public bardSeuil?: number;
    public ismd2Auto?: number;
    public ismd2Seui?: number;
    public ismd3Ext?: number;
    public isSdsxml?: number;
    public isSdsdis?: number;
    public cafClino?: string;
    public cafCaino?: string;
    public cafCainom?: string;
    public gsAllocs?: string;
    public gsAllocs2?: string;
    public gsAllocs3?: string;
    public ncsTxtaq?: string;
    public isdData?: string;
    public entcpt?: string;
    public subnum?: string;
}
