import {AfterViewInit, Component} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base/base-form.component';
import {GridProfile} from '@app/sam-base/core';
import {EntityNavigationService} from '@app/sam-base/core/services/entity-navigation.service';
import {IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Ppemp} from '@sam-base/models/placement';

import {PpempService} from '../../../services/ppemp.service';
import {EmployeeCandidateNew} from './ppemp-candidate-new.model';

@Component({
    templateUrl: './ppemp-candidate-new.component.html'
})
export class PpempCandidateNewComponent extends BaseFormComponent<EmployeeCandidateNew> implements AfterViewInit {

    public isLoading = false;

    public get profile(): GridProfile<Ppemp> | undefined {
        return this.getData('profile');
    }

    constructor(private _entityNavService: EntityNavigationService, private _store: IwStoreService, private _ppempService: PpempService) {
        super();
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.formGroup.controls['email'].clearValidators();
            this.formGroup.controls['natel'].clearValidators();
            this.formGroup.controls['email'].updateValueAndValidity();
            this.formGroup.controls['natel'].updateValueAndValidity();
        }, 0);
    }

    public closeDialog(): void {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public createCandidate() {
        this.isLoading = true;
        this._ppempService.createCandidate(this.getFormData() ?? {})
            .subscribe({
                next: (employee) => {
                    this._entityNavService.navigateToEntityForm(Ppemp, employee.empId ?? '', this.profile);
                    this.closeDialog();
                }, error: () => this.isLoading = false
            });
    }

    protected getValidationType() {
        return Ppemp;
    }

    protected getFormControlNames(): FormKeys<EmployeeCandidateNew> {
        return ['nom', 'prenom', 'email', 'natel', 'profession'];
    }

}
