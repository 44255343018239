<div class="row">
    <iw-busy [isBusy]="isLoading"></iw-busy>

    <div class="col-xs-10">

        <form [formGroup]="formGroup" class="row">
            <iw-textfield #apiKey [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'ij_apiKey' | translate"
                          class="iw-input col-xs-12" formControlName="IJSOCPWD"></iw-textfield>

            <iw-textfield #apiUserName [isDisabled]="isReadonly" [labelAlign]="'left'"
                          [label]="'ij_apiUserName' | translate" class="iw-input col-xs-12"
                          formControlName="IJSOCNAME"></iw-textfield>

            <iw-textfield #ijUrl [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'ij_ijUrl' | translate"
                          class="iw-input col-xs-12" formControlName="IJURL"></iw-textfield>

            <iw-checkbox #interitrack [isDisabled]="isReadonly" [label]="'ij_interitrack' | translate"
                         class="iw-input col-xs-10 margin-top-10" formControlName="IJPT"></iw-checkbox>

            <!-- formControlName IJSIG removed to show always unchecked for now -->
            <!-- requested on https://samredesign.atlassian.net/browse/SAM-6627?focusedCommentId=20237 -->
            <iw-checkbox #signRequest [isDisabled]="true" [label]="'ij_signRequest' | translate"
                         class="iw-input col-xs-10 margin-top-10"></iw-checkbox>

        </form>

    </div>

    <div class="col-xs-2" style="height: 140px;">
        <div class="col-xs-12 flex flex-v flex-evenly" style="height: 100%;">
            <iw-button (press)="modifyEntry()" [isDisabled]="!isReadonly" [text]="'sidemenu_edit' | translate"
                       id="sidemenu_edit"></iw-button>
            <iw-button (press)="saveEntry()" [isDisabled]="isReadonly" [text]="'sidemenu_save' | translate"
                       id="sidemenu_save"></iw-button>
            <iw-button (press)="cancelEditionMode()" [isDisabled]="isReadonly" [text]="'sidemenu_cancel' | translate"
                       id="sidemenu_cancel"></iw-button>
        </div>
    </div>

</div>


<div class="row flex flex-hr">
    <iw-button #Closebutton1 (press)="closeDialog()" [text]="'fermer' | translate"
               class="iw-button col-xs-2 end-xs"></iw-button>
</div>


