<div class="flex flex-v flex-evenly">
    <div class="row margin-top-10 flex flex-h flex-gap">
        <iw-button (press)="calculate()"
                   [text]="'calculer' | translate"
                   class="btn-block padding-top-15"></iw-button>
        <iw-button (press)="desattribuer()"
                   [isDisabled]="gepaihdr?.montant === gepaihdr?.solde"
                   [text]="'desatribuer' | translate"
                   class="btn-block padding-top-15"></iw-button>
        <iw-button (press)="desattribuerCli()"
                   [isDisabled]="!selectedGepaiele"
                   [text]="'desatribuer_cli' | translate"
                   class="btn-block padding-top-15"></iw-button>
        <iw-button (press)="attribuer()"
                   [isDisabled]="paiementSolde === undefined || paiementSolde === 0 || paiementSolde < 0"
                   [text]="'attribuer' | translate"
                   class="btn-block padding-top-15"></iw-button>
    </div>
</div>
