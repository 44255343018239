import {Component} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {EntityCreate, EntityDelete, EntityUpdate, IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {ComboboxItem, FormKeys} from '@app/sam-base/models';
import {Ppgrh, PpgrhtypeEnum} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Component({
    templateUrl: './ppgrh-config.component.html'
})
export class PpgrhConfigComponent extends BaseFormComponent<Ppgrh> {

    public type = Ppgrh;
    public isReadonly = true;
    public newEntryMode = false;

    public grhId = '';
    public selectedRow?: Ppgrh;
    public isSaving = false;
    public ppgrhtype = PpgrhtypeEnum;

    public get selectedData() {
        if (!this.selectedRow) {
            return undefined;
        }
        return {name: 'grhId', value: this.selectedRow.grhId};
    }

    public cboFact2gsOptions: ComboboxItem<string>[] = [];

    /** Dummy query created to bypass filter that requires some sort of params */
    public query: RestQueryParam<Ppgrh, string>[] = [{
        prop: 'grhId', operation: RestQueryOperation.NotEquals, value: '_'
    }];

    private readonly _restPpgrhClient: RestEntityClient<Ppgrh>;

    constructor(rest: RestApiService, private _store: IwStoreService, private _translate: TranslateService, private _toastService: ToastService) {
        super();
        this._restPpgrhClient = rest.getEntityClient(Ppgrh);
        this.cboFact2gsOptions = [{
            name: this._translate.instant('facteurGS'), value: '1'
        }, {name: this._translate.instant('facteurAppliquer'), value: '2'}];
    }

    /**
     * Function to get selected row
     *
     * @param event Ppgrh[]
     * @returns void
     */
    public getSelectedRow(event: Ppgrh[]) {
        if (this.isSaving) {
            // Prevents clearing of the selectedRow
            this.isSaving = false;
            return;
        }

        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }

        this.grhId = (event[0] && event[0].grhId) || '';
        this.selectedRow = event[0];
        this.fillFormData(<any>event[0]);
    }

    /**
     * Function to create new ppgrh entry
     *
     * @returns void
     */
    public createNewEntry() {
        this.selectedRow = {};
        this.isReadonly = false;
        this.formGroup.reset();
        this.newEntryMode = true;
    }

    /**
     * Function to save changes on existing or new entry
     *
     * @returns void
     */
    public saveEntry() {
        const formData = this.getFormData();

        const updatedData = this._restPpgrhClient
            .construct({...this.selectedRow, ...formData});
        this.isReadonly = true;
        this.clearForm();

        if (!updatedData || !updatedData.grhId) {
            this._toastService.error('need_to_fill_id');
            this.newEntryMode = false;
            return;
        }

        this.isSaving = true;

        if (this.newEntryMode) {
            this._restPpgrhClient.getById(updatedData.grhId)
                .pipe(catchError(err => of(err)))
                .subscribe((res) => {
                    if (res['status'] === 404) {
                        this._store.dispatch(new EntityCreate(updatedData));
                    } else {
                        this._toastService
                            .error(this._translate.instant('id_already_exits'));
                    }
                });

            this.newEntryMode = false;
            return;
        }
        this._store.dispatch(new EntityUpdate(updatedData));
        this.newEntryMode = false;
    }

    /**
     * Set fields editable
     *
     * @returns void
     */
    public modifyEntry() {
        if (this.selectedRow && this.selectedRow.grhId) {
            this.isReadonly = false;
            this.newEntryMode = false;
        }
    }

    /**
     * Function to remove entry
     *
     * @returns void
     */
    public removeEntry() {
        if (this.selectedRow && this.selectedRow.grhId) {
            this._store.dispatch(new EntityDelete(Ppgrh, this.grhId));
        }
    }

    /**
     * Cancel the edition mode
     *
     * @returns void
     */
    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.isReadonly = true;
        this.newEntryMode = false;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public getGSprovisions() {
        return ((this.getFormValue('prvGsId') ?? '') + ' / ' + (this.getFormValue('pprGsId') ?? ''));
    }

    protected getValidationType() {
        return Ppgrh;
    }

    protected getFormControlNames(): FormKeys<Ppgrh> {
        return ['grhId', 'grhName', 'grhType', 'gsId', 'compte', 'facteur', 'fact2Gs', 'shortlib', 'prvGsId', 'pprGsId'];
    }
}
