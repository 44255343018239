import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppagi} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-1114-aginter', templateUrl: './tab-1114-aginter.component.html'
})
export class Tab1114AginterComponent extends BasePartialFormComponent<Ppagi> {
    @Input() public isReadonly = true;

    public contribuitionsLPP = false;
    public allowances = false;

    constructor() {
        super();
    }

    public setContribuitions(event: boolean) {
        this.contribuitionsLPP = event;
    }

    public setAllowances(event: boolean) {
        this.allowances = event;
    }

    public setCalculatedValue1() {
        const v1: number | undefined = this.getFormValue('allenfnb');
        const v2: number | undefined = this.getFormValue('allenfpx');
        if (!v1 || !v2) {
            return 0.00;
        } else {
            return Number(v1) * Number(v2);
        }
    }

    public setCalculatedValue2() {
        const v1: number | undefined = this.getFormValue('allfornb');
        const v2: number | undefined = this.getFormValue('allforpx');
        if (!v1 || !v2) {
            return 0.00;
        } else {
            return Number(v1) * Number(v2);
        }
    }

    public setCalculatedValue3() {
        const v1: number | undefined = this.getFormValue('allnb');
        const v2: number | undefined = this.getFormValue('allpx');
        if (!v1 || !v2) {
            return 0.00;
        } else {
            return Number(v1) * Number(v2);
        }
    }
}
