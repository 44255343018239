import {buildDefaultState, FormDescription} from '@app/sam-base/core';
import {Canton} from '@app/sam-base/models';
import {CantonBaremeComponent} from '../components/canton-bareme/canton-bareme.component';

export const CANTON_BAREME: FormDescription<Canton> = {
    form: CantonBaremeComponent, state: {
        ...buildDefaultState(Canton),
        diagTitle: 'bareme_IS',
        diagShowSideMenu: false,
        diagShowStatus: true,
        width: 400,
        editMode: 'read'
    }
};
