import {Sags} from '@app/sam-base/models/salary';

export enum CboCctDedType {
    '**' = 1, 'RA' = 2, 'EX' = 3, '--' = 4,
}

export enum CboISBaremeOptions {
    'A0' = 1,
    'A1' = 2,
    'A2' = 3,
    'A3' = 4,
    'A4' = 5,
    'B0' = 6,
    'B1' = 7,
    'B2' = 8,
    'B3' = 9,
    'B4' = 10,
    'B5' = 11,
    'D' = 12,
}

export enum CboIsCalcmodeOptions {
    'selon_taux' = 1,
    'automatique_mensuel' = 2,
    'automatique_horaire' = 3,
    'automatique_annuel' = 4,
    'automatique_journalier' = 5,
}

export interface ElemSalaireOutput {
    empId: string;
    prBase: number;
    unite: string;
    texte: string;
    prTaux: number;
    base: number;
    montant: number;
    salIdCor: string;
    cptdebit: string;
    cptcredit: string;
    parent: string;
    gsId: string;
    taux: number;
    esId?: string;
    cctId?: string;
    cctDedtype?: string;
    isCanton?: string;
    isBareme?: string;
    isCalcmode?: number;
    isTaux?: number;
    dedCode?: string;
    tableNo: string;
    tableElemId?: string;
    sags: Sags;
    salId: string;
}
