<form [formGroup]="formGroup" class="row">
    <iw-sapaimode-dropdown #cboMpa1 [isDisabled]="isReadonly" [labelAlign]="'left'"
                           [label]="'paimode_especes' | translate" [payType]="[1]" [size]="'large'"
                           class="iw-input col-xs-12 padding-top-5" formControlName="mpaId1"></iw-sapaimode-dropdown>

    <iw-sapaimode-dropdown #cboMpa2 [isDisabled]="isReadonly" [labelAlign]="'left'"
                           [label]="'paimode_cheques' | translate" [payType]="[2]" [size]="'large'"
                           class="iw-input col-xs-12 padding-top-5" formControlName="mpaId2"></iw-sapaimode-dropdown>

    <iw-sapaimode-dropdown #cboMpa3 [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'paimode_DTA' | translate"
                           [payType]="[3, 5, 6]" [size]="'large'" class="iw-input col-xs-12 padding-top-5"
                           formControlName="mpaId3"></iw-sapaimode-dropdown>

    <iw-sapaimode-dropdown #cboMpa4 [isDisabled]="isReadonly" [labelAlign]="'left'"
                           [label]="'paimode_autre' | translate" [payType]="[4]" [size]="'large'"
                           class="iw-input col-xs-12 padding-top-5" formControlName="mpaId4"></iw-sapaimode-dropdown>

    <iw-sapaimode-dropdown #cboMpa8 [isDisabled]="isReadonly" [labelAlign]="'left'"
                           [label]="'paimode_bloque' | translate" [payType]="[8]" [size]="'large'"
                           class="iw-input col-xs-12 padding-top-5" formControlName="mpaId8"></iw-sapaimode-dropdown>

    <iw-sapaimode-dropdown #cboMpa9 [isDisabled]="isReadonly" [labelAlign]="'left'"
                           [label]="'paimode_report' | translate" [payType]="[9]" [size]="'large'"
                           class="iw-input col-xs-12 padding-top-5" formControlName="mpaId9"></iw-sapaimode-dropdown>

    <iw-checkbox #Chklpayifnotcalc [isDisabled]="isReadonly" [labelAlign]="'right'"
                 [label]="'lpayifnotcalc' | translate" class="iw-input col-xs-12 padding-top-20"
                 formControlName="lpayifnotcalc"></iw-checkbox>
</form>