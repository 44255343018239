<form [formGroup]="formGroup" class="row space-form">

    <div class="row col-xs-10 no-padding">

        <iw-textfield [isReadOnly]="true" [value]="salId" class="padding-10"></iw-textfield>

        <iw-table #lstAutoAco (selectedChange)="updateSelectionList($event)" [columns]="columns" [data]="autoAcoData"
                  [defaultSelected]="true" [isDisabled]="false" [isFilterEnable]="false"
                  [isGridColumnMenuEnable]="false" [isSortIconEnable]="false" [selectionMode]="'checkbox'"
                  class="iw-grid col-xs-12 height-200" style="max-height: 50vh; height: unset;"></iw-table>

    </div>


    <div class="row col-xs-2 no-padding">

        <iw-button #btnCreate (press)="generate()" [isDisabled]="isEnregistreDisabled()" [text]="'generer' | translate"
                   class="iw-button col-xs-10" style="margin-top: 40px;"></iw-button>

        <iw-button #Standardbutton4 (press)="closeDialog()" [text]="'fermer' | translate"
                   class="iw-button col-xs-10 flex-end"></iw-button>
    </div>

</form>
