import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@sam-base/core/rest-api/core';
import {SamUser} from '@sam-base/models/admin/sam-user';
import {SamUserData} from '@sam-base/models/admin/sam-user-data.model';
import {Observable} from 'rxjs';

@Injectable()
export class SamUserService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getAllSamUsers(): Observable<SamUser[]> {
        return this._http.get<SamUser[]>(`/api/sam-user`);
    }

    public getSamUser(userId: string): Observable<SamUser> {
        return this._http.get<SamUser>(`/api/sam-user/${userId}`);
    }

    public createSamUser(userData: SamUser): Observable<SamUser> {
        return this._http.post<SamUser>('/api/sam-user', userData);
    }

    public deleteSamUser(userId: string): Observable<void> {
        return this._http.delete<void>(`/api/sam-user/${userId}`);
    }

    public updateSamUser(userId: string, data: SamUser): Observable<SamUser> {
        return this._http.put<SamUser>(`/api/sam-user/${userId}`, data);
    }

    public updateSamUserData(formData: SamUserData): Observable<SamUser> {
        return this._http.put<SamUser>(`/api/sam-user/${formData.user?.id}/data`, formData);
    }
}
