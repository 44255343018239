import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Saparded} from '@app/sam-base/models/salary';

import {SalaryDeductionComponent} from '../components/salary-deduction/salaryDeduction.component';

export const SAPARDED_DEFAULT_FORM: FormDescription<Saparded> = {
    form: SalaryDeductionComponent, state: {
        ...buildDefaultState(Saparded),
        diagTitle: 'saparded_form_title',
        width: 600,
        diagShowSideMenu: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showPrint: false, showList: true, showDelete: true
        },
        editMode: 'read',
        useNavigation: true,
        patch: false
    }
};
