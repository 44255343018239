<form [formGroup]="formGroup"
      class="row">
    <div class="col-md-10 col-md-offset-1"
         style="align-items: end;">
        <iw-textfield [isDisabled]=true
                      [labelAlign]="'top'"
                      [ngClass]="getPeriodeClass()"
                      [value]="tvaPeriode"
                      class="iw-input col-xs-12 padding-top-15"
                      label="tva_period"
                      style="text-align-last: center;"></iw-textfield>

        <iw-agency-combo-box [labelAlign]="'top'"
                             [label]="'agence' | translate"
                             [size]="'small'"
                             class="iw-input col-xs-12"
                             formControlName="ageId"></iw-agency-combo-box>

        <iw-entity-textfield [allowSearch]="true"
                             [entity]="clientType"
                             [label]="'client' | translate"
                             [name]="['nom']"
                             [showIdField]="false"
                             class="iw-input col-xs-12"
                             formControlName="cliId"></iw-entity-textfield>

        <iw-enum-dropdown [defaultOption]="defaultOption"
                          [inputEnum]="facTypeEnum"
                          [labelAlign]="'top'"
                          [label]="'type' | translate"
                          [size]="'small'"
                          class="iw-input col-xs-12"
                          formControlName="facType"></iw-enum-dropdown>

        <iw-radio-list [radioOptions]="radioValues"
                       class="iw-input col-xs-12"
                       formControlName="facOrnc"></iw-radio-list>
    </div>
    <div class=" col-xs-8 col-xs-offset-2 padding-top-10"
         style="align-items: end;">
        <iw-button (press)="save()"
                   [isDisabled]="!formFullfilled()"
                   [text]="'enregistrer' | translate"
                   class="iw-button padding-top-15 margin-right-5"></iw-button>
        <iw-button (press)="destroyForm()"
                   [text]="'Annuler' | translate"
                   class="iw-button"></iw-button>
    </div>
</form>
