import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {ModalFormComponent} from '@app/sam-base/components';
import {SaempService} from '@app/sam-base/core/services/saemp.service';
import {ModalComponent} from '@app/sam-base/models';
import {IwGridColumn} from '@app/sam-base/models/components/grid-column.model';
import {HtmlElementStatus} from '@app/sam-base/models/html-element-status.model';
import {Js3, Recapissd4Options} from './recapissd4.model';


@Component({
    selector: 'iw-recapissd4',
    templateUrl: './recapissd4.component.html'
})
export class Recapissd4Component implements ModalComponent<void, Recapissd4Options> {

    public options?: Recapissd4Options;

    @Output() public pressOk: EventEmitter<void> = new EventEmitter();

    @Output() public pressCancel: EventEmitter<void> = new EventEmitter();

    public btnOk: HtmlElementStatus = {
        enabled: false,
        value: 'Ok',
        visible: false
    };

    public btnCancel: HtmlElementStatus = {
        enabled: false,
        value: 'Ok',
        visible: false
    };

    // Component info
    public iElement?: number;
    public selectedElement?: Js3[];
    public compte?: string;
    public editing = false;
    public loading = false;

    public tableData: Js3[] = [];
    public tableColumns: IwGridColumn<Js3>[] = [
        {
            prop: 'cid',
            name: 'gs_code',
            index: 0,
            width: 10
        },
        {
            prop: 'titre',
            name: 'titre',
            index: 1,
            width: 145
        },
        {
            prop: 'res1',
            name: 'january',
            index: 2,
            width: 10
        },
        {
            prop: 'res2',
            name: 'february',
            index: 3,
            width: 10
        },
        {
            prop: 'res3',
            name: 'march',
            index: 4,
            width: 10
        },
        {
            prop: 'res4',
            name: 'april',
            index: 5,
            width: 10
        },
        {
            prop: 'res5',
            name: 'may',
            index: 6,
            width: 10
        },
        {
            prop: 'res6',
            name: 'june',
            index: 7,
            width: 10
        },
        {
            prop: 'res7',
            name: 'july',
            index: 8,
            width: 10
        },
        {
            prop: 'res8',
            name: 'august',
            index: 9,
            width: 10
        },
        {
            prop: 'res9',
            name: 'september',
            index: 10,
            width: 10
        },
        {
            prop: 'res10',
            name: 'october',
            index: 11,
            width: 10
        },
        {
            prop: 'res11',
            name: 'november',
            index: 12,
            width: 10
        },
        {
            prop: 'res12',
            name: 'december',
            index: 13,
            width: 10
        },
        {
            prop: 'resCumul',
            name: 'total',
            index: 14,
            width: 10
        }];

    @ViewChild('modal') public modal?: ModalFormComponent;

    constructor(private _saempService: SaempService) {
    }

    public async setData(options: Recapissd4Options): Promise<void> {
        this.options = options;
        this.loadTableData();
    }

    public loadTableData() {
        if (!this.options) {
            return;
        }
        this.loading = true;
        this._saempService.getRecapissd4(this.options.empId ?? '', this.options.annee ?? '')
            .subscribe({
                next: data => {
                    this.tableData = data;
                    this.loading = false;
                    this.editing = false;
                    this.selectedElement = undefined;
                },
                error: () => this.loading = false
            });
    }

    public registerOk() {
        this.pressOk.emit();
    }

    public registerCancel() {
        this.modal?.onPressCancel();
        this.pressCancel.emit();
    }

}
