import {Component, OnInit} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {SortInfo} from '@app/sam-base/components';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {EntityCreate, EntityDelete, EntityUpdate, IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys, IwGridColumn} from '@app/sam-base/models';
import {Ppqua} from '@app/sam-base/models/placement';
import {ParametersService} from '@core/services/parameters.service';

@Component({
    templateUrl: './professions-config.component.html'
})
export class ProfessionsConfigComponent extends BaseFormComponent<Ppqua> implements OnInit {

    public type = Ppqua;
    public isReadonly = true;
    public newEntryMode = false;

    public quaId = '';
    public selectedRow?: Ppqua;
    public isSaving = false;
    public suvaPeActive = false;
    public columns: IwGridColumn<Ppqua>[] = [];

    public get selectedData() {
        if (!this.selectedRow) {
            return undefined;
        }
        return {name: 'quaId', value: this.selectedRow.quaId};
    }

    public sortProp?: keyof Ppqua;
    public sortPropDir?: 'asc' | 'desc';

    /** Dummy query created to bypass filter that requires some sort of params */
    public query: RestQueryParam<Ppqua, string>[] = [{
        prop: 'quaId', operation: RestQueryOperation.NotEquals, value: '_'
    }];

    private readonly _restPpquaClient: RestEntityClient<Ppqua>;

    constructor(rest: RestApiService, private _parametersService: ParametersService, private _store: IwStoreService) {
        super();
        this._restPpquaClient = rest.getEntityClient(Ppqua);
    }

    public ngOnInit(): void {
        this._parametersService.getParameterValue('suvaPeActivationDate')
            .subscribe(value => {
                this.suvaPeActive = !!value as boolean;
                this.initColumns(this.suvaPeActive);
            });
    }

    private initColumns(suvaPeActive: boolean): void {
        this.columns = [{
            prop: 'quaId', name: 'Id', index: 0
        }, {
            prop: 'qualif', name: 'profession', index: 1, width: 300
        }];
        if (suvaPeActive) {
            this.columns.push({
                prop: 'iscoDescriptionFr', name: 'Genre ISCO', index: 2, indexed: false
            }, {
                prop: 'pe', name: 'PE', index: 3, width: 20, indexed: false
            }, {
                prop: 'active', name: 'Active', type: 'boolean', index: 4
            });
        }
    }

    /**
     * Function to get selected row
     *
     * @param event Ppqua[]
     * @returns void
     */
    public getSelectedRow(event: Ppqua[]) {
        this.isReadonly = true;

        if (this.isSaving) {
            // Prevents clearing of the selectedRow
            this.isSaving = false;
            return;
        }

        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }

        this.quaId = (event[0] && event[0].quaId) || '';
        this.selectedRow = event[0];
        this.fillFormData(<any>event[0]);
    }

    /**
     * Function to create new ppqua entry
     *
     * @returns void
     */
    public createNewEntry() {
        this.selectedRow = undefined;
        this.isReadonly = false;
        this.formGroup.reset();
        this.newEntryMode = true;
    }

    /**
     * Function to save changes on existing or new entry
     *
     * @returns void
     */
    public saveEntry() {
        const formData = this.getFormData();

        const updatedData = this._restPpquaClient
            .construct({...this.selectedRow, ...formData});
        this.isReadonly = true;
        this.clearForm();

        this.isSaving = true;

        if (this.newEntryMode) {
            this.setSortByModif();
            updatedData.active = true;
            this._store.dispatch(new EntityCreate(updatedData));
            this.newEntryMode = false;

            return;
        }
        this._store.dispatch(new EntityUpdate(updatedData));
        this.newEntryMode = false;
    }

    /**
     * Set fields editable
     *
     * @returns void
     */
    public modifyEntry() {
        if (this.selectedRow && this.selectedRow.quaId) {
            this.isReadonly = false;
            this.newEntryMode = false;
        }
    }

    /**
     * Function to remove entry
     *
     * @returns void
     */
    public removeEntry() {
        if (this.selectedRow && this.selectedRow.quaId) {
            this._store.dispatch(new EntityDelete(Ppqua, this.quaId));
        }
    }

    /**
     * Cancel the edition mode
     *
     * @returns void
     */
    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.isReadonly = true;
        this.newEntryMode = false;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public setSortInfo(sortInfo: SortInfo<Ppqua>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    protected getValidationType() {
        return Ppqua;
    }

    protected getFormControlNames(): FormKeys<Ppqua> {
        return ['quaId', 'qualif', 'iscoCatCode', 'iscoDescriptionFr', 'pe'];
    }

    private setSortByModif() {
        // Force the update of the string reference
        this.sortProp = 'dateTrack';
        this.sortPropDir = 'desc';
    }
}
