import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    ElemSalaireOutput
} from '@app/modules/sam-main/salary/components/fiche-salaire/tab-fichesal-fiche/elementDeSalaire/elementdesalaire.models';
import {Saes} from '@app/sam-base/models/salary/saes';

import {SaesCPV2} from '@app/sam-base/models/salary/saesCPV2';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class SaesService extends RestClient<Saes> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getComboSalIdOptions(): Observable<string[]> {
        return this._http.get<string[]>(this._baseUrl + `saes/combosalid`);
    }

    public saveSaes(data: ElemSalaireOutput): Observable<Saes> {
        return this._http.post<Saes>(this._baseUrl + 'saes/saveSalary', data);
    }

    public saveSaesPayerProv(saes: Saes): Observable<Saes> {
        return this._http.post<Saes>(this._baseUrl + `saes/payerAProv`, saes);
    }


    public getSaesCpv2BySaesIdentification(ids: string[]): Observable<SaesCPV2[]> {
        return this._http.get<SaesCPV2[]>(this._baseUrl + `saes/cpv2/${ids}`);
    }

    public deleteSaes(id: string, empId: string, salId: string): Observable<void> {
        return this._http.delete<void>(this._baseUrl + `saes/${id}/${empId}/${salId}`);
    }

    public getAcompteInfo(empId: string, salId: string): Observable<{ label: string, amount: number }[]> {
        return this._http.get<{
            label: string,
            amount: number
        }[]>(this._baseUrl + `saes/acompteinfo/${empId}/${salId}`);
    }
}
