import {Injectable} from '@angular/core';
import {GridProfile} from '@app/sam-base/core/grid-profile/models';
import {
    LocalAdvSearchProfile,
    LocalPpacatestviewDefaultProfile,
    LocalPpacoDefaultProfile,
    LocalPpagiviewDefaultProfile,
    LocalPpcdeProfile,
    LocalPpcdfProfile,
    LocalPpclimisProfile,
    LocalPpcliProfile,
    LocalPpempProfile,
    LocalPpindDefaultProfile,
    LocalPpplfProfile,
    LocalPprapProfile
} from '@app/sam-base/core/grid-profile/models/grid-profile-default';
import {
    LocalPpcliProfileCategories,
    LocalPpcliProfileCCT,
    LocalPpcliProfileClientAdresses,
    LocalPpcliProfileClientContacts,
    LocalPpcliProfileResume,
    LocalPpcliProfileTelFaxEmail
} from '@app/sam-base/core/grid-profile/models/grid-profile-ppcli';
import {AddLocalProfile} from '@app/sam-base/core/store/actions/grid-profiles.actions';
import {IwStoreService} from '@app/sam-base/core/store/iw-store.service';

/** Component developed in: https://samredesign.atlassian.net/browse/SAM-4290 */

@Injectable()
export class LocalProfileService {
    constructor(private readonly _store: IwStoreService) {
    }

    public setLocalProfiles() {

        const localPpcliProfileClientContacts = LocalPpcliProfileClientContacts;
        const localPpcliProfileClientAdresses = LocalPpcliProfileClientAdresses;
        const localPpcliProfileTelFaxEmail = LocalPpcliProfileTelFaxEmail;
        const localPpcliProfileResume = LocalPpcliProfileResume;
        const localPpcliProfileCategories = LocalPpcliProfileCategories;
        const localPpcliProfileCCT = LocalPpcliProfileCCT;


        const localAdvSearchProfile = LocalAdvSearchProfile;

        const defaultPpempProf = LocalPpempProfile;
        const defaultPpcliProf = LocalPpcliProfile;
        const defaultPpclimisProf = LocalPpclimisProfile;
        const defaultPprapviewProf = LocalPprapProfile;
        const defaultPpcdeProf = LocalPpcdeProfile;
        const defaultPpcdfProf = LocalPpcdfProfile;
        const defaultPpacatestview = LocalPpacatestviewDefaultProfile;
        const defaultPpagiview = LocalPpagiviewDefaultProfile;
        const defaultPpaco = LocalPpacoDefaultProfile;
        const defaultPpind = LocalPpindDefaultProfile;
        const defaultPpplf = LocalPpplfProfile;

        this._store
            .dispatch(new AddLocalProfile([
                new GridProfile(defaultPpempProf),
                new GridProfile(defaultPpcliProf),
                new GridProfile(localPpcliProfileClientContacts),
                new GridProfile(localPpcliProfileClientAdresses),
                new GridProfile(localPpcliProfileTelFaxEmail),
                new GridProfile(localPpcliProfileResume),
                new GridProfile(localPpcliProfileCategories),
                new GridProfile(localPpcliProfileCCT),
                new GridProfile(defaultPpclimisProf),
                new GridProfile(defaultPprapviewProf),
                new GridProfile(defaultPpagiview),
                new GridProfile(defaultPpaco),
                new GridProfile(defaultPpind),
                new GridProfile(defaultPpplf),
                new GridProfile(defaultPpcdeProf),
                new GridProfile(defaultPpcdfProf),
                new GridProfile(localAdvSearchProfile),
                new GridProfile(defaultPpacatestview)]));
    }
}
