import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Ppcdf} from '@app/sam-base/models/placement';
import {CdefixeSideMenuComponent} from '../components/cdefixe/cdefixe-side-menu.component';
import {CdefixeComponent} from '../components/cdefixe/cdefixe.component';
import {CdfNewComponent} from '../components/cdefixe/cdfnew/cdfnew.component';

export const PPCDEFIXE_DEFAULT_FORM: FormDescription<Ppcdf> = {
    form: CdefixeComponent, sideMenu: CdefixeSideMenuComponent, state: {
        ...buildDefaultState(Ppcdf),
        diagTitle: 'combo_ppplf',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true, showClose: true, showEdit: true, showList: true, showPrint: true, showDelete: true
        },
        useNavigation: true,
        patch: false,
        width: 1000
    }
};

export const CDFNEW_DEFAULT_FORM: FormDescription<Ppcdf> = {
    form: CdfNewComponent, state: {
        ...buildDefaultState(Ppcdf), diagTitle: 'ppcdftempo_form_title', isNew: true, width: 460, destroyOnSave: true
    }
};

