import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {Ppheusaid} from '@app/sam-base/models/placement';
import {Observable} from 'rxjs';

import {Ppheusaid2Save} from '../components/ppheusaid2/ppheusaid2-save.model';

@Injectable()
export class PpheusaidService extends RestClient<Ppheusaid | Ppheusaid2Save> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    /**
     * Get data by rapport id
     *
     * @param rapId id
     */
    public getDataByRapId(rapId: string): Observable<Ppheusaid> {
        return this.GET<Ppheusaid>(undefined, `ppheusaid/rapport/${rapId}`);
    }

    /**
     * Get data by mission id
     *
     * @param misId id
     */
    public getDataByMisId(misId: string): Observable<Ppheusaid> {
        return this.GET<Ppheusaid>(undefined, `ppheusaid/mission/${misId}`);
    }

    /**
     * Confirm if the reportNo is unique or belongs to the same report
     *
     * @param rapId defaults to '0' in case the report is new
     */
    public isReportNoUnique(rapNo: string, rapId = '0'): Observable<boolean> {
        return this.GET<boolean>(undefined, `ppheusaid/rap-no/${rapNo}/rap-id/${rapId}/unique`);
    }

    /**
     * Save rapport data method
     *
     * @param rapport rapport data to save
     */
    public saveData(rapport: Ppheusaid2Save): Observable<Ppheusaid2Save> {
        return this.POST<Ppheusaid2Save>(rapport, undefined, `ppheusaid/rapport`);
    }

    /**
     * Recalculate total values of the report
     *
     * @param rapport rapport data to save
     */
    public recalculateReportTotals(rapport: Ppheusaid2Save): Observable<Ppheusaid2Save> {
        return this.PUT<Ppheusaid2Save>(rapport, undefined, `pprap/calculate-totals`);
    }
}

