<form [formGroup]="formGroup" class="row">

    <div class="row col-xs-12 no-padding">
        <div #cboShCalcModeLabel class="iw-label col-xs-3"
             title="{{'calculDuSalaire' | translate}}">{{ 'calculDuSalaire' | translate }}
        </div>
        <iw-ppshcalcmode-dropdown #cboShCalcMode (valueChange)="setShcalcmodeValue()" [isDisabled]="isReadonly"
                                  [noLabel]="true" class="col-xs-4 no-padding-right"
                                  formControlName="shcalcmode"></iw-ppshcalcmode-dropdown>

        <iw-textfield #txtShcalcmode [isDisabled]="true" [size]="'large'" [value]="shcalcmodeValue"
                      class="iw-input col-xs-1 no-padding-left"></iw-textfield>

        <iw-checkbox #jfNonPayes [isDisabled]="isReadonly" [label]="'jf_non_payes' | translate"
                     class="iw-checkbox col-xs-4" formControlName="jfNonPayes"></iw-checkbox>
    </div>

    <div class="row col-xs-12 no-padding margin-top-5">
        <div #cboshsupmodeLabel class="iw-label col-xs-3"
             title="{{'paiementHeuresSup' | translate}}">{{ 'paiementHeuresSup' | translate }}
        </div>
        <iw-enum-dropdown #cboshsupmode [inputEnum]="heursSupNames" [isDisabled]="isReadonly" [noLabel]="true"
                          class="col-xs-8 no-padding-right" formControlName="shsupmode"></iw-enum-dropdown>
    </div>

    <div class="row col-xs-12 no-padding margin-top-5">
        <iw-textfield #txtTx_vac_4 [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'txVac4' | translate"
                      class="iw-input col-xs-2" formControlName="txVac4" type="number"></iw-textfield>
        <div class="iw-single-label col-xs-1 padding-top-25">%</div>

        <iw-textfield #txtTx_vac_5 [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'txVac5' | translate"
                      class="iw-input col-xs-2" formControlName="txVac5" type="number"></iw-textfield>
        <div class="iw-single-label col-xs-1 padding-top-25">%</div>

        <iw-textfield #txtTx_13 [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'tx13E' | translate"
                      class="iw-input col-xs-2" formControlName="tx13" type="number"></iw-textfield>
        <div class="iw-single-label col-xs-1 padding-top-25">%</div>

        <iw-textfield #txtTx_JF [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'txJf' | translate"
                      class="iw-input col-xs-2" formControlName="txJf" type="number"></iw-textfield>
        <div class="iw-single-label col-xs-1 padding-top-25">%</div>
    </div>

    <div class="row col-xs-12 no-padding margin-top-5">
        <iw-checkbox #chkHeurepause [isDisabled]="isReadonly" [label]="'heuresPause' | translate"
                     class="iw-input col-xs-3" formControlName="heurepause"></iw-checkbox>

        <iw-textfield #txtTx_pause [isDisabled]="!checkTxPauseEnabled()" class="iw-input col-xs-3"
                      formControlName="txPause" type="number"></iw-textfield>
        <div class="col-xs-6"></div>
    </div>

    <div class="row col-xs-12 no-padding margin-top-5">
        <div #cboshsupmodeLabel class="iw-label col-xs-3"
             title="{{'decompteParDefaut' | translate}}">{{ 'decompteParDefaut' | translate }}
        </div>
        <iw-cctdectype-dropdown #cboTypedec (valueChange)="setTypedecValue()" [isDisabled]="isReadonly" [noLabel]="true"
                                class="iw-input col-xs-8 no-padding-right"
                                formControlName="typedec"></iw-cctdectype-dropdown>

        <iw-textfield #txtTypedec [isDisabled]="true" [value]="typedecValue"
                      class="iw-input col-xs-1 no-padding-left"></iw-textfield>
    </div>

</form>
