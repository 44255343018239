import {IwRestEntity} from '@app/sam-base/core/rest-api';
import {Saemp} from './saemp';

@IwRestEntity('saempc')
export class Saempc extends Saemp {
    public sdsI?: string;
    public sdsB?: string;
    public sdsD?: string;
    public sdsJ?: string;
    public sdsK?: string;
    public sdsL?: string;
    public sdsM?: string;
    public sdsN?: string;
    public sdsP?: string;
    public sdsC?: string;
    public sdsE?: string;
    public sdsH?: string;
    public attfisc?: boolean;
    public emcData?: string;
    public nbjVacini?: number;
    public ncsA?: boolean;
    public ncsB?: boolean;
    public ncsF?: boolean;
    public ncsG?: boolean;
    public ncsT23?: string;
    public ncsT3?: string;
    public ncsT4?: string;
    public ncsT71?: string;
    public ncsT72?: string;
    public ncs1311?: boolean;
    public ncsT1312?: string;
    public ncsT1323?: string;
    public ncsT141?: string;
    public ncsT142?: string;
    public ncsT15?: string;
    public txtJtrav?: string;
    public txtHtrav?: string;
    public txtFirstDay?: string;
    public txtLastDay?: string;
    public ipresence?: number;
    public isalaires?: number;
    public iforce?: number;
    public din1?: string;
    public din2?: string;
    public din3?: string;
    public din4?: string;
    public din5?: string;
    public din6?: string;
    public din7?: string;
    public din8?: string;
    public din9?: string;
    public din10?: string;
    public din11?: string;
    public din12?: string;
    public dout1?: string;
    public dout2?: string;
    public dout3?: string;
    public dout4?: string;
    public dout5?: string;
    public dout6?: string;
    public dout7?: string;
    public dout8?: string;
    public dout9?: string;
    public dout10?: string;
    public dout11?: string;
    public dout12?: string;
    public nap1?: number;
    public nap2?: number;
    public nap3?: number;
    public nap4?: number;
    public nap5?: number;
    public nap6?: number;
    public nap7?: number;
    public nap8?: number;
    public nap9?: number;
    public nap10?: number;
    public nap11?: number;
    public nap12?: number;
    public lmf1?: boolean;
    public lmf2?: boolean;
    public lmf3?: boolean;
    public lmf4?: boolean;
    public lmf5?: boolean;
    public lmf6?: boolean;
    public lmf7?: boolean;
    public lmf8?: boolean;
    public lmf9?: boolean;
    public lmf10?: boolean;
    public lmf11?: boolean;
    public lmf12?: boolean;
    public taxId?: string;
    public crossborderValidAsOf?: string;
    public remAVS?: string;
    public adresse1Oth?: string;
    public adresse2Oth?: string;
    public npaoth?: string;
    public lieuoth?: string;
    public paysoth?: string;
    public payadoth?: boolean;
    public payfromoth?: string;
    public paynomeoth?: string;
    public payprenomoth?: string;
    public payadroth?: string;
    public paynpaoth?: string;
    public paylieuoth?: string;
    public paypaysoth?: string;
    public monthContSalary?: number;
    public monthCont13?: number;
    public monthCont14?: number;
    public hourlyContPaidByHour?: number;
    public hourlyContPaidByLesson?: number;
    public hourlyContVac?: number;
    public hourlyContJf?: number;
    public hourlyCont13?: number;
    public noTimeContSalary?: number;
    public noTimeContVac?: number;
    public noTimeContJf?: number;
    public noTimeCont13?: number;
    public contactNcsId?: number;
}
