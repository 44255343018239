<form [formGroup]="formGroup" class="row">
    <iw-sagstype-dropdown #cboEx_gs_cot [isDisabled]="isReadonly" [labelAlign]="'left'" [label]="'type' | translate"
                          [size]="'small'" [types]="[]" class="iw-input col-xs-11" formControlName="gsCtype"
                          id="cboGsType"></iw-sagstype-dropdown>
    <iw-textfield #gsCtype [isDisabled]="true" [noLabel]="true" [value]="getFormValue('gsCtype')"
                  class="iw-textfield col-xs-1 flex-end" id="gsCtype"></iw-textfield>
    <div class="row col-xs-12 padding-top-10">
        <div class="row col-xs-6 separator-right">
            <iw-textfield #brut [decimals]="2" [isDisabled]="isReadonly || !enableBaseBrut" [label]="'brut' | translate"
                          [size]="'small'" class="col-xs-9 padding-bottom-10" formControlName="baseBrut"
                          labelAlign="left" type="number"></iw-textfield>

            <iw-textfield #m1 [decimals]="2" [isDisabled]="isReadonly || !enableM1" [label]="mj1Label" [size]="'small'"
                          class="col-xs-9" formControlName="baseMj1" labelAlign="left" type="number"></iw-textfield>
            <iw-textfield #m2 [decimals]="2" [isDisabled]="isReadonly || !enableM2" [label]="mj2Label" [size]="'small'"
                          class="col-xs-9" formControlName="baseMj2" labelAlign="left" type="number"></iw-textfield>
            <iw-textfield #m3 [decimals]="2" [isDisabled]="isReadonly || !enableM3" [label]="mj3Label" [size]="'small'"
                          class="col-xs-9" formControlName="baseMj3" labelAlign="left" type="number"></iw-textfield>
            <iw-textfield #m4 [decimals]="2" [isDisabled]="isReadonly || !enableM4" [label]="mj4Label" [size]="'small'"
                          class="col-xs-9 padding-bottom-10" formControlName="baseMj4" labelAlign="left"
                          type="number"></iw-textfield>

            <iw-textfield #avsac [decimals]="2" [isDisabled]="isReadonly || !enableAvsAcLaaPgmLppImpots"
                          [label]="'avsac' | translate" [size]="'small'" class="col-xs-9" formControlName="baseAvs"
                          labelAlign="left" type="number"></iw-textfield>
            <iw-checkbox #nb *ngIf="sapar?.allownb" [isDisabled]="isReadonly || !enableNb" [label]="'n_b' | translate"
                         class="iw-input col-xs-1" formControlName="avsacNb"></iw-checkbox>
            <iw-textfield #laa [decimals]="2" [isDisabled]="isReadonly || !enableAvsAcLaaPgmLppImpots"
                          [label]="'laa' | translate" [size]="'small'" class="col-xs-9" formControlName="baseCna"
                          labelAlign="left" type="number"></iw-textfield>
            <iw-textfield #laac [decimals]="2" [isDisabled]="isReadonly || !enableLaac" [label]="'laac' | translate"
                          [size]="'small'" class="col-xs-9" formControlName="baseLac" labelAlign="left"
                          type="number"></iw-textfield>
            <iw-textfield #pgm [decimals]="2" [isDisabled]="isReadonly || !enableAvsAcLaaPgmLppImpots"
                          [label]="'pgm' | translate" [size]="'small'" class="col-xs-9" formControlName="basePgm"
                          labelAlign="left" type="number"></iw-textfield>
            <iw-textfield #lpp [decimals]="2" [isDisabled]="isReadonly || !enableAvsAcLaaPgmLppImpots"
                          [label]="'lpp_gs' | translate" [size]="'small'" class="col-xs-9" formControlName="baseLpp"
                          labelAlign="left" type="number"></iw-textfield>
            <iw-textfield #impots [decimals]="2" [isDisabled]="isReadonly || !enableAvsAcLaaPgmLppImpots"
                          [label]="'impots' | translate" [size]="'small'" class="col-xs-9 padding-bottom-10"
                          formControlName="baseImp" labelAlign="left" type="number"></iw-textfield>

            <iw-textfield #mat [decimals]="2" [isDisabled]="isReadonly || !enableD1" [label]="cu1Label" [size]="'small'"
                          class="col-xs-9" formControlName="baseCu1" labelAlign="left" type="number"></iw-textfield>
            <iw-textfield #lpcFam [decimals]="2" [isDisabled]="isReadonly || !enableD2" [label]="cu2Label"
                          [size]="'small'" class="col-xs-9" formControlName="baseCu2" labelAlign="left"
                          type="number"></iw-textfield>
            <iw-textfield #laac [decimals]="2" [isDisabled]="isReadonly || !enableD3" [label]="cu3Label"
                          [size]="'small'" class="col-xs-9" formControlName="baseCu3" labelAlign="left"
                          type="number"></iw-textfield>
            <iw-textfield #***** [decimals]="2" [isDisabled]="isReadonly || !enableD4" [label]="cu4Label"
                          [size]="'small'" class="col-xs-9 padding-bottom-10" formControlName="baseCu4"
                          labelAlign="left" type="number"></iw-textfield>

            <iw-textfield #nonsoumis [decimals]="2" [isDisabled]="isReadonly || !enableNonSumis"
                          [label]="'nonsoumis' | translate" [size]="'small'" class="col-xs-9" formControlName="retInd"
                          labelAlign="left" type="number"></iw-textfield>
            <iw-checkbox #lppprev [isDisabled]="isReadonly" [label]="'lppprev' | translate" class="iw-input col-xs-12"
                         formControlName="lppprev"></iw-checkbox>
            <iw-textfield #lppfact [decimals]="0" [isDisabled]="isReadonly" [label]="'lppfact' | translate"
                          [maxValue]="13" [size]="'small'" class="col-xs-9" formControlName="lppfact" labelAlign="left"
                          type="number"></iw-textfield>
            <iw-checkbox #lppretro [isDisabled]="isReadonly" [label]="'lppretro' | translate" class="iw-input col-xs-12"
                         formControlName="lppretro"></iw-checkbox>
        </div>

        <div class="row col-xs-6">
            <iw-checkbox #roundIt [isDisabled]="isReadonly" [label]="'round_it' | translate" class="iw-input col-xs-12"
                         formControlName="roundIt"></iw-checkbox>
            <iw-checkbox #printIt *ngIf="showPrintIt" [isDisabled]="isReadonly" [label]="'print_it' | translate"
                         class="iw-input col-xs-12" formControlName="printIt"></iw-checkbox>
            <div class="col-xs-12 separator-top"></div>
            <iw-checkbox #salBase [isDisabled]="isReadonly || !enableSalBase" [label]="'sal_base' | translate"
                         class="iw-input col-xs-12" formControlName="salBase"></iw-checkbox>
            <iw-checkbox #salHor [isDisabled]="isReadonly || !enableSalHor" [label]="'sal_hor' | translate"
                         class="iw-input col-xs-12" formControlName="salHor"></iw-checkbox>
            <iw-checkbox #salHormnt [isDisabled]="isReadonly || !enableSalHorMnt" [label]="'sal_hormnt' | translate"
                         class="iw-input col-xs-12" formControlName="salHormnt"></iw-checkbox>
            <iw-checkbox #salHorlpp [isDisabled]="isReadonly || !enableSalHorLpp" [label]="'sal_horlpp' | translate"
                         class="iw-input col-xs-12" formControlName="salHorlpp"></iw-checkbox>
            <iw-checkbox #salLppmnt [isDisabled]="isReadonly || !enableSalLppmnt" [label]="'sal_lppmnt' | translate"
                         class="iw-input col-xs-12" formControlName="salLppmnt"></iw-checkbox>
            <iw-checkbox #salJour [isDisabled]="isReadonly || !enableSalJour" [label]="'sal_jour' | translate"
                         class="iw-input col-xs-12" formControlName="salJour"></iw-checkbox>
            <div class="col-xs-12 separator-top">
                <iw-enum-dropdown #corrmode *ngIf="showGscType" [inputEnum]="modeCorrectionOptions"
                                  [isDisabled]="isReadonly" [labelAlign]="'top'" [label]="'mode_correction' | translate"
                                  class="iw-input col-xs-12" formControlName="corrmode"></iw-enum-dropdown>
                <iw-textfield #cumuler [isDisabled]="isReadonly || !(getFormValue('gsCtype') === 'D')"
                              [labelAlign]="'top'" [label]="'cumuler' | translate" class="iw-textfield col-xs-12"
                              formControlName="cumformule"></iw-textfield>
                <iw-sags-dropdown #gsOpp [isDisabled]="isReadonly || !enableGsOppDropdown" [labelAlign]="'top'"
                                  [label]="'gs_opose' | translate" [types]="['1', '3', '9', 'S']"
                                  class="iw-input col-xs-12" formControlName="gsOpp" id="gsOpp"></iw-sags-dropdown>
                <iw-sags-dropdown #paimentProv *ngIf="showGsProv" [isDisabled]="isReadonly" [labelAlign]="'top'"
                                  [label]="'paiment_prov' | translate" [types]="[]" class="iw-input col-xs-12"
                                  formControlName="gsPaiPrv" id="paimentProv"></iw-sags-dropdown>
                <iw-sags-dropdown #reportProvision *ngIf="showGsProv" [isDisabled]="true" [labelAlign]="'top'"
                                  [label]="'report_provision' | translate" [types]="['E']" class="iw-input col-xs-12"
                                  formControlName="gsRepPrv" id="reportProvision"></iw-sags-dropdown>
            </div>

        </div>
    </div>

</form>
