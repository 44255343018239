<form [formGroup]="formGroup" class="row space-form">
    <iw-busy [isBusy]="isLoading"></iw-busy>
    <div class="row col-xs-12 no-padding">
        <iw-textfield #txtCliNom [labelAlign]="'left'" [label]="'password' | translate" [type]="'password'"
                      [value]="password" class="iw-input no-padding col-xs-9" formControlName="password"></iw-textfield>
        <iw-textfield #txtCliNom [labelAlign]="'left'" [label]="'confirm_password' | translate" [type]="'password'"
                      [value]="confirmPassword" class="iw-input no-padding col-xs-9"
                      formControlName="confirmPassword"></iw-textfield>

        <div class="row col-xs-12 no-padding-right" style="padding-left: 10%">
            <iw-button #Standardbutton2 (press)="destroyForm()" [isDisabled]="closeDisabled"
                       [text]="'fermer' | translate" class="iw-button col-xs-2 no-label no-padding-right"></iw-button>

            <iw-button #Standardbutton2 (press)="resetPassword()" [isDisabled]="!isFormFilled"
                       [text]="'reset' | translate" class="iw-button col-xs-2 no-label no-padding-right"></iw-button>
        </div>
    </div>
</form>

