<form [formGroup]="formGroup" class="row">
    <div class="row space-form col-xs-12">
        <iw-enum-dropdown [inputEnum]="emiGenreType" [isDisabled]="isReadonly" [labelAlign]="'top'"
                          [label]="'type' | translate" class="iw-input col-xs-5"
                          formControlName="emiGenre"></iw-enum-dropdown>
        <iw-dropdown [isDisabled]="true" [labelAlign]="'top'" [label]="'mois_correction' | translate"
                     [options]="optionSalIdCor" class="iw-input col-xs-3" formControlName="salIdCor"></iw-dropdown>
        <iw-enum-dropdown [inputEnum]="corType" [isDisabled]="true" [labelAlign]="'top'" [label]="'dpi_aci' | translate"
                          class="iw-input col-xs-2" formControlName="corType"></iw-enum-dropdown>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'' | translate"
                      class="iw-input col-xs-2 padding-top-10 no-label" formControlName="salId"></iw-textfield>
        <div class="col-xs-8"></div>
        <div class="col-xs-2 emiNumber">EMI#</div>
        <iw-textfield [isDisabled]="true" [labelAlign]="'top'" [label]="'' | translate"
                      class="iw-input col-xs-2 padding-top-10" formControlName="emiId"></iw-textfield>
    </div>
    <div class="row col-xs-12">
        <iw-enum-dropdown *ngIf="emiGenre === 'AVS'" [inputEnum]="aemaCodeAVS" [isDisabled]="isReadonly"
                          [labelAlign]="'top'" [label]="'ema_code' | translate" class="iw-input col-xs-6"
                          formControlName="emaCode"></iw-enum-dropdown>
        <iw-enum-dropdown *ngIf="emiGenre === 'CAF'" [inputEnum]="aemaCodeCAF" [isDisabled]="true" [labelAlign]="'top'"
                          [label]="'ema_code' | translate" class="iw-input col-xs-6"
                          formControlName="emaCode"></iw-enum-dropdown>
        <iw-date-picker [isDisabled]="!isWriteMode" [labelAlign]="'top'" [label]="'date' | translate" class="col-xs-2"
                        formControlName="emaDate"></iw-date-picker>
        <iw-canton-dropdown *ngIf="emiGenre === 'CAF'" [isDisabled]="isReadonly" [labelAlign]="'top'"
                            [label]="'canton_old' | translate" class="iw-input col-xs-3"
                            formControlName="cantonOld"></iw-canton-dropdown>


    </div>
    <div class="row col-xs-12">
        <iw-date-picker [isDisabled]="!isWriteMode" [labelAlign]="'top'" [label]="'duree_cotisation_from' | translate"
                        class="col-xs-4" formControlName="dureeCotisationFrom"></iw-date-picker>
        <iw-date-picker [isDisabled]="!isWriteMode" [labelAlign]="'top'" [label]="'duree_cotisation_to' | translate"
                        class="col-xs-4" formControlName="dureeCotisationTo"></iw-date-picker>

    </div>
</form>
