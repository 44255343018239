import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppagi} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-57-aginter', templateUrl: './tab-57-aginter.component.html'
})

export class Tab57AginterComponent extends BasePartialFormComponent<Ppagi> {

    @Input() public isReadonly = true;
    public laborAgreement = false;
    public extraHours = false;

    public setLaborAgreement(event: boolean) {
        this.laborAgreement = event;
    }

    public setExtraHours(event: boolean) {
        this.extraHours = event;
    }

}
