<p-toast #toast
         [key]="toastKey"
         [position]="position">
    <ng-template let-message
                 pTemplate="message">
        <div class="row">
            <div class="col-xs-1">
                <span class="pi pi-times-circle"
                      style="font-size: 2rem"></span>
            </div>
            <div class="row col-xs-10">
                <div *ngIf="!message?.isHtml"
                     class="col-xs-12">
                    <span>{{ message.detail }}</span>
                </div>
                <div *ngIf="message?.isHtml"
                     [innerHTML]="getSanitizeMessage(message)"
                     class="col-xs-12">
                </div>

                <div class="col-xs-12 padding-5">
                    <strong>{{ 'send_error_support' | translate }}</strong>
                </div>

                <div class="col-xs-1 flex-center">
                    <button (click)="mailTo(message.summary)"
                            class="p-toast-icon-close p-link">
                        <span class="pi pi-envelope"></span>
                    </button>
                </div>
                <div class="col-xs-9 col-xs-offset-1">
                    <div class="toast-error-box">
                        <div class="toast-message-error padding-5">
                            {{ message.summary }}
                        </div>
                    </div>
                </div>
                <div class="col-xs-1 flex-center">
                    <button (click)="copyErrorClipboard(message.summary)"
                            class="p-toast-icon-close p-link">
                        <span class="pi pi-clone"></span>
                    </button>
                </div>
            </div>
            <div class="col-xs-1">
                <button (click)="closeToast(message)"
                        class="p-toast-icon-close p-link">
                    <span class="pi pi-times"></span>
                </button>
            </div>
        </div>
    </ng-template>
</p-toast>
