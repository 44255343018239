<form [formGroup]="formGroup" class="row">

    <div class="row col-xs-12 no-padding">
        <iw-textfield #txtCli_id [isDisabled]="true" [ngClass]="getCdeClass()" class="iw-input col-xs-2 no-padding"
                      formControlName="cdeId"></iw-textfield>

        <iw-textfield #txtCliNom [isDisabled]="true" [value]="clientName"
                      class="iw-input no-padding label-name col-xs-9"></iw-textfield>

        <iw-button #cliBtn (press)="openClient()" class="iw-button col-xs-1" iconClass="fas fa-link"></iw-button>
    </div>

    <div class="row col-xs-3 no-padding">
        <iw-textfield #txtROProfession [isDisabled]="true" [value]="commandeProfession"
                      class="iw-input col-xs-12 no-padding label-profession"></iw-textfield>
    </div>
    <div class="row col-xs-9 no-padding">
        <iw-textfield #txtProfessionDescription [isDisabled]="true" [value]="getFormValue('qualifDescription')"
                      class="iw-input col-xs-12 no-padding label-profession"></iw-textfield>
    </div>
</form>

<iw-tab #tabs [(selectedTab)]="selectedTab" [invalidTabs]="invalidTabList" [tabs]="tabList">

    <iw-tab-ppcdetempo-general #tabGeneral *ngIf="selectedTab === tabList[0]" [entityId]="cdeId" [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-ppcdetempo-general>

    <iw-tab-ppcdetempo-autre #tabAutre *ngIf="selectedTab === tabList[1]" [entityId]="cdeId" [formGroup]="formGroup"
                             [isReadonly]="!isWriteMode"></iw-tab-ppcdetempo-autre>

    <iw-tab-ppcdetempo-descremarques #tabDesremarques *ngIf="selectedTab === tabList[2]" [formGroup]="formGroup"
                                     [isReadonly]="!isWriteMode"></iw-tab-ppcdetempo-descremarques>

    <iw-tab-ppcdetempo-match #tabMatching *ngIf="selectedTab === tabList[3]" [entityId]="cdeId" [formGroup]="formGroup"
                             [formId]="uuid" [isReadonly]="!isWriteMode"></iw-tab-ppcdetempo-match>

    <iw-tab-ppcde-missions *ngIf="selectedTab === tabList[4]" [entityId]="cdeId" [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"></iw-tab-ppcde-missions>

    <iw-tab-ppcdetempo-suivi #tabSuivi *ngIf="selectedTab === tabList[5]" [entityId]="cdeId"
                             [isReadonly]="!isWriteMode"></iw-tab-ppcdetempo-suivi>
</iw-tab>
