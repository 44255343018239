<form [formGroup]="formGroup"
      class="row">
    <iw-textfield #txtEmp_id
                  [isDisabled]="true"
                  [ngClass]="getEmpClass()"
                  [value]="empId"
                  class="iw-input col-xs-2 no-padding"
                  id="id_employee"></iw-textfield>

    <iw-textfield #txtEmpNom
                  [isDisabled]="true"
                  [value]="employeeName"
                  class="iw-input col-xs-6 no-padding label-name"></iw-textfield>

    <iw-textfield #txtROProfession
                  [isDisabled]="true"
                  [value]="employeeProfession"
                  class="iw-input col-xs-3 no-padding label-profession"></iw-textfield>
    <iw-button #empBtn
               (press)="openSaemp()"
               *iwAccessGroups="SALARY_USER_ROLES"
               [isDisabled]="false"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>
</form>

<iw-tab [(selectedTab)]="selectedTab"
        [disabledTabs]="disabledTabList"
        [invalidTabs]="invalidTabList"
        [tabs]="tabList">

    <iw-tab-ppemp-general *ngIf="selectedTab === tabList[0]"
                          [formGroup]="formGroup"
                          [isNew]="checkIfNew()"
                          [isReadonly]="!isWriteMode"></iw-tab-ppemp-general>

    <iw-tab-ppemp-situation *ngIf="selectedTab === tabList[1]"
                            [formGroup]="formGroup"
                            [isNew]="checkIfNew()"
                            [isReadonly]="!isWriteMode"
                            [isValid]="checkIfValid()"></iw-tab-ppemp-situation>

    <iw-tab-ppemp-experience *ngIf="selectedTab === tabList[2]"
                             [formGroup]="formGroup"
                             [isReadonly]="!isWriteMode"></iw-tab-ppemp-experience>

    <iw-tab-ppemp-matching *ngIf="selectedTab === tabList[3]"
                           [entityId]="empId"
                           [formGroup]="formGroup"
                           [formId]="uuid"
                           [isReadonly]="!isWriteMode"></iw-tab-ppemp-matching>

    <iw-tab-ppemp-notes *ngIf="selectedTab === tabList[4]"
                        [formGroup]="formGroup"
                        [isReadonly]="!isWriteMode"></iw-tab-ppemp-notes>

    <iw-tab-ppemp-langues *ngIf="selectedTab === tabList[5]"
                          [formGroup]="formGroup"
                          [isReadonly]="!isWriteMode"></iw-tab-ppemp-langues>

    <iw-tab-ppemp-disp-souhait *ngIf="selectedTab === tabList[6]"
                               [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-ppemp-disp-souhait>

    <iw-tab-ppemp-references *ngIf="selectedTab === tabList[7]"
                             [formGroup]="formGroup"
                             [isReadonly]="!isWriteMode"></iw-tab-ppemp-references>

    <iw-tab-ppemp-paiement *ngIf="selectedTab === tabList[8]"
                           [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"></iw-tab-ppemp-paiement>

    <iw-tab-ppemp-suivi *ngIf="selectedTab === tabList[9]"
                        [entityId]="empId"
                        [isReadonly]="!isWriteMode"></iw-tab-ppemp-suivi>

    <iw-tab-ppemp-docs *ngIf="selectedTab === tabList[10]"
                       [entityId]="empId"
                       [isReadonly]="!isWriteMode"></iw-tab-ppemp-docs>

</iw-tab>

<form [formGroup]="formGroup"
      class="row">
    <iw-textarea #edtNotes
                 [height]="40"
                 [isDisabled]="!isWriteMode"
                 class="iw-textarea col-xs-12"
                 formControlName="note"></iw-textarea>
</form>
