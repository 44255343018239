import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {ParametersService} from '@core/services/parameters.service';
import {environment} from '@root/environments/environment';
import {BaseStoreFormComponent} from '@sam-base/base';
import {EntityNavigationService, IwEventHubService, IwStoreService} from '@sam-base/core';
import {EncaissementEvents} from '@sam-base/core/events/actions/encaissement.actions';
import {EncaissementService} from '@sam-base/core/services/encaissement.service';
import {FormKeys, IwGridColumn, RowClickEvent} from '@sam-base/models';
import {Gecli, Gepar} from '@sam-base/models/invoices';
import {Bvr} from '@sam-base/models/invoices/bvrLoad';
import {EncaissementAutomatic} from '@sam-base/models/invoices/encaissement-automatic.model';
import {Gepaicliview} from '@sam-base/models/invoices/gepaicliview';
import {Gepaihdr} from '@sam-base/models/invoices/gepaihdr';
import {Subject, takeUntil} from 'rxjs';

@Component({
    templateUrl: './encaissement-automatic.component.html'
})
export class EncaissementAutomaticComponent extends BaseStoreFormComponent<EncaissementAutomatic> implements OnInit, OnDestroy {
    public gefachdr?: Gepaihdr;
    public gecli?: Gecli;
    public clientName?: string;
    public clientId?: string;
    public disabled = true;
    public columns = this.getColumns();
    public columnsEncaissement = this.getColumnsEncaissement();
    public cbvrList: Bvr[] = [];
    public encaissements: Gepaihdr[] = [];

    public selectedLines: Bvr[] = [];
    public selectedBvr?: Bvr;
    protected readonly _baseUrl: string;
    private subscriptions = new Subject();
    private gepar?: Gepar;

    constructor(store: IwStoreService,
                private _encaissementService: EncaissementService,
                private readonly _eventHub: IwEventHubService<EncaissementEvents>,
                private readonly _entityNavService: EntityNavigationService,
                private readonly _parameterService: ParametersService) {
        super(store);
        this._baseUrl = environment.backendURL;
    }

    public ngOnInit(): void {
        this._parameterService.getGepar().subscribe((gepar) => {
            this.gepar = gepar;
            this.setFormValue('mpaId', this.gepar.bvrMpaId);
            this.setFormValue('compteDebiteur', this.gepar.cptdeb);
            this.setFormValue('tauxEscompte1', this.gepar.txmaxesc1);
            this.setFormValue('tauxEscompte2', this.gepar.txmaxesc2);
            this.setFormValue('splitByDate', this.gepar.bvrbydate);
            this.setFormValue('allowManyClient', this.gepar.bvrnoclipl);

        });
        this._eventHub.forType(EncaissementEvents.imported)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(event => {
                this.encaissements = event.payload as Gepaihdr[];
            });
    }

    public ngOnDestroy(): void {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }


    public uploadDocument(files: File[]) {

        if (files && files.length && files[0].name) {
            const file: File = files[0];
            const formData: FormData = new FormData();
            formData.append('file', file);
            formData.append('mpaId', this.getFormValue('mpaId'));
            formData.append('allowManyCliBvr', this.getFormValue('allowManyClient'));
            this._encaissementService.loadCamt54File(formData).subscribe(cbvrLoad => {
                this.cbvrList = cbvrLoad.bvrList ?? [];
            })
        }
    }

    public onSelectBvrList(bvrList: Bvr[]) {
        this.setFormValue('bvrList', bvrList);
    }

    public onRowClicked(bvr: Bvr) {
        this.selectedBvr = bvr;
    }

    public openEncaissement($event: RowClickEvent<Gepaihdr>) {
        const payId = $event.row.payId;
        if (!payId) return;
        this._entityNavService
            .navigateToEntityForm(Gepaicliview, payId);

    }

    protected getFormControlNames(): FormKeys<EncaissementAutomatic> {
        return [
            [
                'mpaId',
                new UntypedFormControl('', [Validators.required])],
            'compteDebiteur',
            'tauxEscompte1',
            'tauxEscompte2',
            'splitByDate',
            'allowManyClient',
            'bvrList',
            'partial',
            'fileName'
        ];
    }

    private getColumns(): IwGridColumn<Bvr>[] {
        return [
            {
                prop: 'cliNom',
                name: 'cliNom',
                index: 1,
            },
            {
                prop: 'facNo',
                name: 'invoice_number',
                index: 2,
            },
            {
                prop: 'montant',
                name: 'montant',
                index: 3,
                type: 'mnt',
                align: 'right',
            },
            {
                prop: 'facSolde',
                name: 'solde',
                index: 4,
                type: 'mnt',
                align: 'right',
            },
            {
                prop: 'datePay',
                name: 'date_paiement',
                index: 5,
            },
            {
                prop: 'dateEcrit',
                name: 'date_posting',
                index: 6,
            },
            {
                prop: 'warning',
                name: 'warning',
                type: 'boolean',
                index: 7,
            },
            {
                prop: 'error',
                name: 'error',
                type: 'boolean',
                index: 8,
            },
            {
                prop: 'cliNomEncaissement',
                name: 'cliNomEncaissement',
                index: 9,
            }
        ];
    }

    private getColumnsEncaissement(): IwGridColumn<Gepaihdr>[] {
        return [
            {
                prop: 'payId',
                name: 'id',
                index: 0,
                width: 70
            },
            {
                prop: 'montant',
                name: 'montant',
                index: 1,
                type: 'mnt',
                align: 'right',
                width: 70
            },
            {
                prop: 'datePay',
                name: 'date_paiement',
                index: 2,
                width: 70
            },
        ];
    }
}
