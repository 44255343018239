<form *ngIf="formGroupReady"
      [formGroup]="formGroup"
      class="row">
    <iw-textfield (controlFocus)="!$event ? calculateTotal() : triggerReadyToCalculate()"
                  [isDisabled]="isReadonly || isSaise"
                  [label]="'tarifClient' | translate"
                  class="col-xs-7"
                  formControlName="clitarif"
                  labelAlign="left"
                  type="number"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  [value]="'heure' | translate"
                  class="col-xs-2"
                  id="observer"></iw-textfield>
    <iw-textfield [decimals]="3"
                  [isDisabled]="isReadonly"
                  class="col-xs-2 no-padding-right"
                  formControlName="coeff"
                  labelAlign="left"
                  type="number"></iw-textfield>
    <iw-button (press)="calculateCoeff()"
               [iconClass]="'fas fa-calculator'"
               [isDisabled]="calculerDisabled"
               [tooltip]="'calculate_coeff' | translate"
               class="col-xs-1 no-padding-left"></iw-button>
    <div class="space-block space-block-2"></div>
    <iw-textfield (controlFocus)="!$event ? calculateTotal() : triggerReadyToCalculate()"
                  [decimals]="3"
                  [isDisabled]="isReadonly"
                  [label]="'supplementCct' | translate"
                  class="col-xs-7"
                  formControlName="clitarifra"
                  labelAlign="left"
                  type="number"></iw-textfield>
    <iw-textfield #txtSupCCT
                  [isDisabled]="true"
                  class="col-xs-2"></iw-textfield>
    <iw-button (press)="onCalcCCT()"
               [isDisabled]="calculerDisabled"
               [text]="'appliquer' | translate"
               class="col-xs-3"></iw-button>
    <div class="space-block space-block-2"></div>
    <iw-textfield #txtTarifTotal
                  (controlFocus)="!$event ? onTarifTotalBlur() : ''"
                  [isDisabled]="isReadonly || !isSaise"
                  [label]="'tarifTotal' | translate"
                  class="col-xs-7"
                  labelAlign="left"
                  type="number"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  [value]="'heure' | translate"
                  class="col-xs-2"></iw-textfield>
    <iw-checkbox #chkSaise
                 (valueChange)="isSaise = $event"
                 [isDisabled]="isReadonly"
                 [label]="'saisie' | translate"
                 class="col-xs-3"></iw-checkbox>
    <div class="space-block space-block-2"></div>
    <iw-enum-dropdown [inputEnum]="cliSupModeEnum"
                      [isDisabled]="isReadonly"
                      [label]="'heuresSup' | translate"
                      class="col-xs-9"
                      formControlName="clitarifmd"
                      labelAlign="left"></iw-enum-dropdown>
    <iw-textfield *ngIf="showClitarifMod"
                  [isDisabled]="isReadonly"
                  class="col-xs-3"
                  formControlName="clitarmd6f"></iw-textfield>
</form>
