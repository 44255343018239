import {Component, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core/store';
import {UuidService} from '@app/sam-base/core/uuid/uuid.service';
import {FormKeys} from '@app/sam-base/models';
import {Ppcliatt} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';

enum Cpolitesse {
    monsieur = '1', mademoiselle = '2', madame = '3',
}

@Component({
    templateUrl: './ppcliatt.component.html'
})
export class PpcliattComponent extends BaseStoreFormComponent<Ppcliatt> implements OnInit {

    public get cliId() {
        return this.getData('cliId') || '';
    }

    public cpolitesse = Cpolitesse;

    constructor(store: IwStoreService, private readonly _uuidService: UuidService, private readonly _translate: TranslateService) {
        super(store);
    }

    public ngOnInit() {
        this.setFormValue('cliId', this.cliId);
    }

    public setNomComplet() {
        const politesse = this.getFormValue('cpolitesse');
        const politesseText = this.getPolitesseText(politesse) || '';
        const nom = this.getFormValue('nom') || '';
        const prenom = this.getFormValue('prenom') || '';
        const nomComplet = politesseText + ' ' + nom + ' ' + prenom;
        this.setFormValue('nomcomplet', nomComplet);
    }

    /**
     * So long story short,
     * some entities can not be created because someone
     * decided that by default we don't have auto ID
     * so us the super front, do the front ID thing for free (almost)
     * https://www.youtube.com/watch?v=pc0mxOXbWIU
     */
    public saveChanges() {
        if (this.isNew) {
            const newId = this._uuidService.generateEntityId();
            this.setFormValue('cliattId', newId);
        }

        setTimeout(() => {
            super.saveChanges();
        }, 500);
    }

    protected getValidationType() {
        return Ppcliatt;
    }

    protected validateFields(e: Ppcliatt): boolean {
        return super.validateFields(e);
    }

    protected getFormControlNames(): FormKeys<Ppcliatt> {
        return ['nomcomplet', 'cpolitesse', 'nom', 'prenom', 'fonction', 'dept', 'tel', 'fax', 'natel', 'email', 'dateanniv', 'nomailing', 'actif', 'remarques', 'cliId', 'cliattId'];
    }

    private getPolitesseText(v?: string) {
        if (!v) {
            return;
        }
        const text = Object.keys(Cpolitesse)
            .filter((k, x) => {
                if ((x + 1) === Number(v)) {
                    return k;
                }
            });
        return this._translate.instant('combo_' + text);
    }
}
