import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('saemc')
export class Saemc {
    @IwRestEntityId() public empId?: string;
    public annee?: string;
    public ncsA?: boolean;
    public ncsB?: boolean;
    public ncsF?: boolean;
    public ncsG?: boolean;
    public ncsT23?: string;
    public ncsT3?: string;
    public ncsT4?: string;
    public ncsT71?: string;
    public ncsT72?: string;
    public ncs1311?: boolean;
    public ncsP1311?: boolean;
    public ncsT1312?: string;
    public ncsP1312?: boolean;
    public ncsP1321?: boolean;
    public ncsP1322?: boolean;
    public ncsT1323?: string;
    public ncsP1323?: boolean;
    public ncsP133?: boolean;
    public ncsT141?: string;
    public ncsP141?: boolean;
    public ncsT142?: string;
    public ncsP142?: boolean;
    public ncsT15?: string;
    public ncsT151?: string;
    public ncsT152?: string;
    public ncsT153?: string;
    public ncsT154?: string;
    public ncsT155?: string;
    public pdf417data?: string;
    public attfisc?: boolean;
    public attfiscd?: string;
    public autoentr?: boolean;
    public abonpaye?: boolean;
    public indemnnf?: boolean;
    public transgrat?: boolean;
    public contrrep?: boolean;
    public repcant?: boolean;
    public droitspart?: boolean;
    public jtraveq?: number;
    public jtravnp?: number;
    public jvoyage?: number;
    public jtrav?: number;
    public htrav?: number;
    public htraveq?: number;
    public firstday?: string;
    public lastday?: string;
    public observ?: string;
    public ipresence?: number;
    public isalaires?: number;
    public iforce?: number;
    public lm1?: boolean;
    public lm2?: boolean;
    public lm3?: boolean;
    public lm4?: boolean;
    public lm5?: boolean;
    public lm6?: boolean;
    public lm7?: boolean;
    public lm8?: boolean;
    public lm9?: boolean;
    public lm10?: boolean;
    public lm11?: boolean;
    public lm12?: boolean;
    public lmf1?: boolean;
    public lmf2?: boolean;
    public lmf3?: boolean;
    public lmf4?: boolean;
    public lmf5?: boolean;
    public lmf6?: boolean;
    public lmf7?: boolean;
    public lmf8?: boolean;
    public lmf9?: boolean;
    public lmf10?: boolean;
    public lmf11?: boolean;
    public lmf12?: boolean;
    public lms1?: boolean;
    public lms2?: boolean;
    public lms3?: boolean;
    public lms4?: boolean;
    public lms5?: boolean;
    public lms6?: boolean;
    public lms7?: boolean;
    public lms8?: boolean;
    public lms9?: boolean;
    public lms10?: boolean;
    public lms11?: boolean;
    public lms12?: boolean;
    public din1?: string;
    public din2?: string;
    public din3?: string;
    public din4?: string;
    public din5?: string;
    public din6?: string;
    public din7?: string;
    public din8?: string;
    public din9?: string;
    public din10?: string;
    public din11?: string;
    public din12?: string;
    public dout1?: string;
    public dout2?: string;
    public dout3?: string;
    public dout4?: string;
    public dout5?: string;
    public dout6?: string;
    public dout7?: string;
    public dout8?: string;
    public dout9?: string;
    public dout10?: string;
    public dout11?: string;
    public dout12?: string;
    public nap1?: number;
    public nap2?: number;
    public nap3?: number;
    public nap4?: number;
    public nap5?: number;
    public nap6?: number;
    public nap7?: number;
    public nap8?: number;
    public nap9?: number;
    public nap10?: number;
    public nap11?: number;
    public nap12?: number;
    public nbjVacini?: number;
    public emcData?: string;
    public aciData?: string;
    public ncsData?: string;
    public sd4Data?: string;
    public sdsData?: string;
    public sdsA?: string;
    public sdsB?: string;
    public sdsC?: string;
    public sdsD?: string;
    public sdsE?: string;
    public sdsF?: string;
    public sdsG?: string;
    public sdsH?: string;
    public sdsI?: string;
    public sdsJ?: string;
    public sdsK?: string;
    public sdsL?: string;
    public sdsM?: string;
    public sdsN?: string;
    public sdsO?: string;
    public sdsP?: string;
    public sdsQ?: string;
    public sdsR?: string;
    public sdsS?: string;
    public sdsT?: string;
    public sdsU?: string;
    public sdsV?: string;
    public sdsW?: string;
    public sdsX?: string;
    public sdsY?: string;
    public sdsZ?: string;
    public txactivite?: number;
    public datein1st?: string;
    public dateoutlst?: string;
    public nosd4?: boolean;
    public xmlData?: string;
    public inactif?: boolean;
}
