import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Saeisa} from '@sam-base/models/salary/saeisa';
import {Observable} from 'rxjs';

import {RestClient} from '../rest-api/core';

@Injectable()
export class SaeisaService extends RestClient<any> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getSaeisaByYearAndEmpId(year: string, empId: string): Observable<Saeisa> {
        return this._http.get<Saeisa>(this._baseUrl + `saeisa/${year}/${empId}`);
    }
}
